import React, { useContext, useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { UserContext } from "../UserContext";
import { MapCenter } from "./MapCenter";
import axios from "axios";
import { AlertBar } from "./AlertBar";
import { useTranslation } from "react-i18next";

export const MapContent = ({
	edit,
	newCoordinates,
	locateAddress,
	passAddressNameFromCoordinates
}) => {
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	const defaultCenter = [61.49428, 23.745839];

	const [newLat, setNewLat] = useState("");
	const [newLon, setNewLon] = useState("");

	const [showAlert, setShowAlert] = useState(false);

	const locationCenter = [
		Object.prototype.hasOwnProperty.call(
			selectedLocation.coordinates,
			"lat"
		)
			? selectedLocation.coordinates.lat
			: 61.49428,
		Object.prototype.hasOwnProperty.call(
			selectedLocation.coordinates,
			"lon"
		)
			? selectedLocation.coordinates.lon
			: 23.745839
	];

	const icon = L.icon({
		iconRetinaUrl: iconRetina,
		iconUrl: iconMarker,
		shadowUrl: iconShadow,
		iconSize: [25, 45],
		iconAnchor: [15, 45]
	});

	const getNameFromCoordinates = (props) => {
		// console.log(props);
		if (props?.data?.length > 1) return; // not yet

		const address = props?.data?.address?.road
			? props?.data?.address?.road + " "
			: "";
		const addressNumber = props?.data?.address?.house_number
			? props?.data?.address?.house_number + ", "
			: "";

		// TODO: reorder these from hamlet to city? Could be useful to have city district name before city in some places like in Helsinki
		const areaName =
			props?.data?.address?.town ||
			props?.data?.address?.city ||
			props?.data?.address?.city_district ||
			props?.data?.address?.village ||
			props?.data?.address?.hamlet ||
			"";

		/* const county = props?.data?.address?.county
			? props?.data?.address?.county + ", "
			: ""; */

		const postCode = props?.data?.address?.postcode
			? props?.data?.address?.postcode + " "
			: "";

		if (props?.data?.length > 1)
			passAddressNameFromCoordinates(props?.data[0]);
		else
			passAddressNameFromCoordinates(
				address + addressNumber + postCode + areaName
			);
	};

	const getCoordinates = (props) => {
		// console.log(props);
		if (Object.prototype.hasOwnProperty.call(props, "data")) {
			getNameFromCoordinates(props);

			if (props.data[0] !== undefined) {
				setNewLat(props.data[0].lat);
				setNewLon(props.data[0].lon);

				newCoordinates({
					lat: props.data[0].lat,
					lon: props.data[0].lon
				});
			} else {
				return setShowAlert(true);
			}
		} else {
			setNewLat(props.lat);
			setNewLon(props.lon);

			newCoordinates(props);
		}
	};

	const fetchAddress = (refetch) => {
		const url = refetch
			? `https://nominatim.openstreetmap.org/reverse?lat=${newLat}&lon=${newLon}&format=json`
			: `https://nominatim.openstreetmap.org/search?format=json&limit=3&q=${locateAddress}`;

		axios.get(url).then((result) => {
			getCoordinates(result);

			if (result.data.length > 1)
				// hasMultiple(result.data.length);
				setShowAlert(true);
			else setShowAlert(false);
		});
	};

	useEffect(() => {
		if (newLat !== "" && newLon !== "") fetchAddress(true); // reverse search for address after moving marker
	}, [newLat, newLon]);

	// How to update map center on state change
	useEffect(() => {
		if (selectedLocation.coordinates.lat !== null) {
			setNewLat(selectedLocation.coordinates.lat);
			setNewLon(selectedLocation.coordinates.lon);
		}
	}, []);

	useEffect(() => {
		if (selectedLocation.coordinates.lat !== null) {
			setNewLat(selectedLocation.coordinates.lat);
			setNewLon(selectedLocation.coordinates.lon);
		}
	}, [selectedLocation]);

	useEffect(() => {
		if (!locateAddress) return;
		else fetchAddress();
	}, [locateAddress]);

	return (
		<>
			<MapContainer
				center={edit ? defaultCenter : locationCenter}
				zoom={5} // MapCenter will override this
				maxZoom={16}
				scrollWheelZoom={true}
				attributionControl={false}
			>
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
				{selectedLocation.coordinates !== undefined && (
					<>
						<MapCenter
							passNewLat={newLat}
							passNewLon={newLon}
							defaultCenter={defaultCenter}
						/>

						<Marker
							position={[newLat, newLon]}
							icon={icon}
							draggable={edit}
							autoPan={edit}
							eventHandlers={{
								moveend(e) {
									const { lat, lng } = e.target.getLatLng();
									getCoordinates({ lat, lon: lng });
								}
							}}
						/>
					</>
				)}

				{/* <Popup>{info.description}</Popup> */}
			</MapContainer>

			<AlertBar
				type="info"
				showAlert={showAlert}
				textContent={t("location.found_multiple_addresses_alert")}
			/>
		</>
	);
};

{
	/* <MyMarker
	newCoordinates={newCoordinates}
	edit={true}
	newMarker={newMarker}
	locationCenter={locationCenter}
	selectedLocation={selectedLocation}
/>;*/
}
