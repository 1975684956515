import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { ColorScheme } from "../../../../Themes/ColorScheme";

import { WeekdaySelect } from "../../../Common/WeekdaySelect";
import { TimeSlots } from "../../../Common/ReservationTimes";

import { ReactComponent as ArrowIcon } from "../../../../Assets/Icons/arrowBack.svg";
import { ReactComponent as UserIcon } from "../../../../Assets/Icons/ClientsIcon.svg";
import { ReactComponent as WalletIcon } from "../../../../Assets/Icons/WalletIcon.svg";
import { ReactComponent as DurationIcon } from "../../../../Assets/Icons/DurationIcon.svg";
import { ReactComponent as CalendarFromIcon } from "../../../../Assets/Icons/CalendarFromIcon.svg";
import { ReactComponent as CalendarToIcon } from "../../../../Assets/Icons/CalendarToIcon.svg";
import { ReactComponent as TimeStartIcon } from "../../../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as TimeEndIcon } from "../../../../Assets/Icons/TimeEndIcon.svg";

import { AlertBar } from "../../../Common/AlertBar";
import { RemoveButton } from "../Components/RemoveButton";
import { ConfirmAction } from "../../../Common/ConfirmAction";
import { TextFieldInput2 } from "../../../Common/TextFieldInput2";
import { TextfieldPicker } from "../../../Common/TextfieldPicker";
import { TextFieldSelect } from "../../../Common/TextFieldSelect";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const RecipeModal = ({
	data,
	handleClose,
	handleSave,
	showError,
	isNew,
	handleDelete
}) => {
	if (data === undefined) return null;

	const { t } = useTranslation();

	const [startDate, setStartDate] = useState(data?.valid_from || new Date());
	const [endDate, setEndDate] = useState(data?.valid_until || new Date());

	const [weekday, setWeekday] = useState(
		isNew
			? []
			: isNaN(data?.weekday)
				? []
				: data?.weekday === 0
					? 0
					: data.weekday
	); // if weekday is 0, set it to 0, otherwise set it to the weekday number or empty array which won't cause any issues with length checks
	const [minTime, setMinTime] = useState(data?.minimum_reservation || null);
	const [userLimit, setUserLimit] = useState(data?.user_limit || 1);
	const [reservationPrice, setReservationPrice] = useState(
		data?.vat_price || 0
	);
	const [startTime, setStartTime] = useState(data?.from_time || new Date());
	const [endTime, setEndTime] = useState(data?.to_time || new Date());

	const handleSaveClick = () => {
		// compress edited data into one nice object to pass to parent component
		handleSave({
			valid_from: new Date(startDate).toISOString(),
			valid_until: new Date(endDate).toISOString(),
			from_time: new Date(startTime).toISOString(),
			to_time: new Date(endTime).toISOString(),
			minimum_reservation: Number(minTime),
			user_limit: Number(userLimit),
			weekday: isNew ? weekday : Number(weekday),
			short_reservable_id: data?.short_reservable_id,
			vat_price: Number(reservationPrice)
		});
	};

	const handleSaveCheck = () => {
		if (minTime === "") return true;
		if (userLimit === "") return true;
		if (reservationPrice === "") return true;
		if (weekday.length === 0 || weekday === null) return true;
		return false;
	};

	// console.log(data);

	/* useEffect(() => {
		console.log("data", data);
		console.log("startDate", startDate);
		console.log("endDate", endDate);
		console.log("weekday", weekday);
		console.log("minTime", minTime);
		console.log("userLimit", userLimit);
		console.log("reservationPrice", reservationPrice);
		console.log("startTime", startTime);
		console.log("endTime", endTime);
	}, []); */

	return (
		<div
			style={{
				// width: "100%",
				display: "flex",
				flexDirection: "column",
				gap: "10px",
				padding: "30px",
				borderBox: "box-sizing",
				borderTop: "1px solid" + ColorScheme.tolotechTransparentWhite
			}}
		>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between"
				}}
			>
				<Typography variant="h5">
					{isNew
						? t("services.new_recipe")
						: t("services.edit_recipe")}
				</Typography>

				{!isNew && (
					<ConfirmAction
						handleConfirmAction={() => handleDelete(data.id)}
						hasChild={true}
					>
						<RemoveButton />
					</ConfirmAction>
				)}
			</div>

			<AlertBar
				showAlert
				type="info"
				textContent={t("services.recipe_info")}
			/>

			<div
				style={{
					width: "100%",
					// height: "60px",
					display: "flex",
					flexDirection: isDesktop ? "row" : "column",
					gap: "10px",
					alignItems: "center"
				}}
			>
				<TextfieldPicker
					valueChange={setStartDate}
					excludePast={false}
					label={t("services.valid_from")}
					fullDate={true}
					value={startDate}
					views={["year", "month", "day"]}
					hasProps={
						<CalendarFromIcon
							stroke={ColorScheme.defaultIconColor}
						/>
					}
				/>

				{isDesktop && (
					<div>
						<ArrowIcon
							stroke="black"
							style={{
								transform: "rotate(180deg)"
							}}
							height="28px"
						/>
					</div>
				)}

				<TextfieldPicker
					valueChange={setEndDate}
					excludePast={false}
					label={t("services.valid_until")}
					fullDate={true}
					hasProps={
						<CalendarToIcon stroke={ColorScheme.defaultIconColor} />
					}
					views={["year", "month", "day"]}
					value={endDate}
				/>
			</div>

			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: isDesktop ? "row" : "column",
					// minWidth: "calc(100% / 3)",
					gap: "10px",
					alignItems: "center",
					boxSizing: "border-box"
				}}
			>
				<TextfieldPicker
					valueChange={setStartTime}
					label={t("services.from_time")}
					excludePast={false}
					hasProps={
						<TimeStartIcon stroke={ColorScheme.defaultIconColor} />
					}
					value={startTime}
				/>

				{isDesktop && (
					<div>
						<ArrowIcon
							stroke="black"
							style={{
								transform: "rotate(180deg)"
							}}
							height="28px"
						/>
					</div>
				)}

				<TextfieldPicker
					valueChange={setEndTime}
					excludePast={false}
					limitValues={true}
					limitMin={startTime}
					label={t("services.to_time")}
					hasProps={
						<TimeEndIcon stroke={ColorScheme.defaultIconColor} />
					}
					value={endTime}
				/>
			</div>

			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					alignItems: "center"
				}}
			>
				<WeekdaySelect
					days={weekday}
					singleSelect={isNew ? false : true}
					weekdayClicked={(resp) => {
						if (isNew) {
							// If isNew is true, toggle the presence of resp in the weekday state
							setWeekday((prev) => {
								if (prev.includes(resp)) {
									// If resp is already in the array, exclude it
									return prev.filter((day) => day !== resp);
								} else {
									// If resp is not in the array, include it
									return [...prev, resp];
								}
							});
						} else {
							// If isNew is false, set the entire resp array as the weekday state
							setWeekday(resp);
						}
					}}
				/>
			</div>

			<div
				style={{
					display: "flex",
					alignItems: "baseline"
				}}
			>
				<TextFieldSelect
					options={TimeSlots}
					selected={minTime}
					label={t("services.reservation_duration_label")}
					hasProps={
						<DurationIcon stroke={ColorScheme.defaultIconColor} />
					}
					valueChange={setMinTime}
				/>
				{/* <TextField
					fullWidth
					select
					value={minTime}
					label={t("services.reservation_duration_label")}
					placeholder={t("services.reservation_duration_label")}
					onChange={(event) => {
						setMinTime(event.target.value);
					}}
					sx={theme.toloStyled.default_textfield}
					SelectProps={{
						MenuProps: {
							sx: { top: "15px" }
						}
					}}
					InputProps={{
						notched: false,
						disableUnderline: true,
						startAdornment: (
							<InputAdornment
								position="start"
								disablePointerEvents
							>
								<DurationIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							</InputAdornment>
						)
					}}
				>
					{TimeSlots.map((time) => (
						<MenuItem value={time.value} key={time.value}>
							{time.title}
						</MenuItem>
					))}
				</TextField> */}
			</div>

			<div
				style={{
					display: "flex",
					width: "100%",
					flexDirection: isDesktop ? "row" : "column",
					gap: isDesktop ? "20px" : "10px"
				}}
			>
				<TextFieldInput2
					key={data?.id}
					text={reservationPrice}
					title={t("reservations.price_label")}
					valueChange={setReservationPrice}
					onlyNumbers={true}
					hasProps={
						<WalletIcon
							stroke={ColorScheme.defaultIconColor}
							strokeWidth="2px"
							width={"28px"}
						/>
					} // t("common.currency_symbol")
				/>
				<TextFieldInput2
					key={data?.id}
					text={userLimit}
					title={t("reservations.customer_amount_label")}
					valueChange={setUserLimit}
					onlyNumbers={true}
					hasProps={
						<UserIcon stroke={ColorScheme.defaultIconColor} />
					} // t("services.max_users")
				/>
			</div>

			<div
				style={{
					overflow: "hidden",
					transition: "max-height 0.5s ease",
					height: "100%",
					maxHeight: showError[0] ? "200px" : "0", // should be enough for most error responses
					minHeight: "0"
				}}
			>
				<AlertBar
					showAlert={
						typeof showError === "string" ? showError : showError[0]
					}
					whiteSpacePreLine={true}
					newLine={true}
					type={"error"}
					textContent={
						typeof showError === "string" ? showError : showError[1]
					}
				/>
			</div>

			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "right",
					gap: "20px",

					cursor: "pointer"
				}}
			>
				<Button variant="buttonCancel" onClick={() => handleClose()}>
					<Typography>{t("buttons.cancel")}</Typography>
				</Button>

				<Button
					variant="buttonEnabled"
					onClick={() => handleSaveClick()}
					disabled={handleSaveCheck()}
				>
					<Typography>{t("buttons.save")}</Typography>
				</Button>
			</Box>
		</div>
	);
	/* return (
		<Box
			sx={{ padding: "20px", minWidth: "300px", boxSizing: "border-box" }}
		>
			<Typography
				sx={{ fontSize: "20px", color: "black", fontWeight: "700" }}
			>
				{data.user_name}
			</Typography>
			<Typography sx={{ fontSize: "16px", color: "grey" }}>
				{user.email}
			</Typography>
			<Box style={styles.inputContainer}>
				<TextField
					variant="filled"
					label={t("textfield_labels.description")}
					placeholder={t("textfield_labels.input_description")}
					sx={{
						border: "none",
						width: "100%",
						flex: 1
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							color: "black",
							backgroundColor: "transparent",
							borderRadius: "20px",
							border: "0px solid rgba(0,0,0,0)",
							fontSize: "15px"
						}
					}}
					InputLabelProps={{
						style: {
							fontSize: "14px",
							top: "3px"
						}
					}}
					value={description}
					onChange={(event) => {
						let inputValue = event.target.value;
						setDescription(inputValue);
					}}
				/>
			</Box>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginBottom: "20px",
					marginTop: "20px"
				}}
			>
				dsa
			</div>
			<Box
				sx={{
					flexDirection: "row",
					display: "flex",
					width: "100%",
					justifyContent: "space-between"
				}}
			>
				<Box
					onClick={() => closeDialog(false)}
					sx={{
						height: "40px",
						flex: 1,
						border: "1px solid " + ColorScheme.tolotechBlue,
						cursor: "pointer",
						borderRadius: "4px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<Typography sx={{ fontSize: "14px", color: "black" }}>
						{t("buttons.cancel")}
					</Typography>
				</Box>
				<div style={{ width: "20px" }}></div>
				<Box
					onClick={() => !loading && handleSave()}
					sx={{
						height: "40px",
						flex: 1,
						border: "1px solid " + ColorScheme.tolotechBlue,
						backgroundColor: ColorScheme.tolotechBlue,
						borderRadius: "4px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer"
					}}
				>
					{!loading && (
						<Typography sx={{ fontSize: "14px", color: "white" }}>
							{t("buttons.save")}
						</Typography>
					)}
					{loading && (
						<Spinner small color={ColorScheme.tolotechWhite} />
					)}
				</Box>
			</Box>
		</Box>
	); */
};
