import { MenuItem, TextField, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../Themes/Theme";
import TooltipWrapper from "./TooltipWrapper";
import { useTranslation } from "react-i18next";

export const TextFieldSelect = ({
	valueChange,
	selected,
	label,
	options,
	/* defaultSelect, */ // fix this if list header is needed
	removeIcon,
	helpText,
	hasProps
}) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const handleChange = (event) => {
		const value = event.target.value;
		valueChange(value);
	};

	return (
		<Box
			style={{ ...theme.toloStyled.toloTextfield, pointerEvents: "auto" }}
			onClick={() => setIsOpen(!isOpen)}
		>
			{!removeIcon && hasProps && (
				<Box style={theme.toloStyled.toloTextfield.icon}>
					<TooltipWrapper title={helpText}>{hasProps}</TooltipWrapper>
				</Box>
			)}

			<TextField
				select
				fullWidth
				notched={false}
				label={label}
				displayEmpty
				labelId="custom-select"
				value={selected}
				onChange={handleChange}
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onOpen={() => setIsOpen(true)}
				sx={{
					...theme.toloStyled.toloTextfield.select,
					"& .MuiSelect-icon": {
						top:
							selected === undefined
								? "15px"
								: label === undefined ||
									  label === null ||
									  label === ""
									? "15px"
									: "8px"
					}
				}}
				/* startAdornment={
				<div
					style={{
						display: removeIcon ? "none" : undefined,
						padding: "10px"
					}}
				>
					<InputAdornment position="start">{icon}</InputAdornment>
				</div>
			} */
				/* inputProps={{
					disableUnderline: true,
					style: {
						color: "black",
						backgroundColor: "red",
						borderRadius: "20px",
						border: "0px solid rgba(0,0,0,0)",
						fontSize: "15px",
						top: "20px"
					}
				}} */
				SelectProps={{
					style: {
						top:
							selected === "" ||
							selected === undefined ||
							selected === null
								? "5px"
								: label === undefined ||
									  label === null ||
									  label === ""
									? "0px"
									: "10px"
					}
				}}
				InputLabelProps={{
					style: {
						fontSize: "14px",
						top:
							selected === "" ||
							selected === undefined ||
							selected === null
								? "5px"
								: "20px",
						"& .Mui-active": {
							top: "20px"
						}
					}
				}}
			>
				{/* Doesn't work with textfield with select property, but works with mui select
				
				{defaultSelect && (
					<MenuItem value="placeholder" disabled>
						{label}
					</MenuItem>
				)} */}

				{options &&
					options.map((type) => (
						<MenuItem
							value={
								isNaN(selected) &&
								Number(selected) === Number(0) // fixes the issue with 0 being falsy and not being selected
									? 0
									: type?.value !== undefined &&
										  type?.value !== null
										? type?.value
										: type?.id !== undefined &&
											  type?.id !== null
											? type?.id
											: type?.type_id !== undefined &&
												  type?.type_id !== null
												? type?.type_id
												: type
							}
							key={type?.value || type?.id || type?.label || type}
						>
							<Typography fontSize="15px">
								{/* {type?.image && <img src={type?.image} alt={type?.label} />} */}
								{type?.label ||
									(type?.name_en !== undefined &&
										t(`reservables.${type?.name_en}`)) ||
									type?.title ||
									type?.value ||
									type?.name ||
									type}
							</Typography>
						</MenuItem>
					))}
			</TextField>
		</Box>
	);
};
