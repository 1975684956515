import { useEffect, useState } from "react";

function ScreenWidth(value) {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < (value || 768));
		};

		window.addEventListener("resize", handleResize);

		// Call handleResize initially to set the initial state
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [value]);

	return isMobile;
}

export default ScreenWidth;
