import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../ApiContext";
import { ConfirmAction } from "../Common/ConfirmAction";
import { UserContext } from "../UserContext";

export const CampaignUser = ({ data, handleRefresh, codeLimit }) => {
	const debug = false;

	const { selectedLocation } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const deleteUser = () => {
		ApiCall("DELETE", `client/campaign/${data.campaign_id}/users`, [
			data.user.email
		])
			.then(() => {
				handleRefresh();
			})
			.catch((e) => {
				if (debug) console.log(e);
			});
	};

	return (
		selectedLocation !== undefined && (
			<Stack
				sx={{
					backgroundColor: "white",
					flexDirection: "row",
					display: "flex",
					color: "black",
					paddingX: "30px",
					paddingY: "12px",
					borderRadius: "2px",
					marginBottom: "2px",
					alignItems: "center"
				}}
			>
				<Stack sx={{ flex: 1 }}>
					<Typography
						sx={{
							fontSize: "10px",
							fontWeight: "700"
						}}
					>
						{data?.user?.name}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 3 }}>
					<Typography
						sx={{
							fontSize: "10px",
							color: "rgba(0,0,0,0.4)",
							fontWeight: "700"
						}}
					>
						{data?.user?.email || t("warning.user_deleted")}
					</Typography>
				</Stack>

				<Stack sx={{ flex: 1.2 }}>
					<Typography
						sx={{
							fontSize: "10px",
							fontWeight: "700"
						}}
					>
						{codeLimit - data?.times_used}
					</Typography>
				</Stack>
				<Stack sx={{ flex: 0.2 }}>
					<ConfirmAction
						handleTitle={t(
							"campaign.remove_user_from_campaign_label"
						)}
						isIconButton={true}
						handleConfirmAction={deleteUser}
					/>
				</Stack>
			</Stack>
		)
	);
};
