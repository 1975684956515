import { Button, Popover, Typography } from "@mui/material";
import DeleteIcon from "../../Assets/Icons/DeleteIcon.svg";
import React, { useContext, useState } from "react";

import { ApiContext } from "../ApiContext";
import TooltipWrapper from "../Common/TooltipWrapper";
import { useTranslation } from "react-i18next";

export const RemoveUserButton = ({ email, text, refreshReservations }) => {
	const debug = false;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const getVariant = text ? "contained" : "";

	const { ApiCall } = useContext(ApiContext);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleUserRemove = () => {
		ApiCall("POST", "client/reservation/unbook", {
			email // TODO: Use array of emails and reservation ids
		})
			.then(() => {
				setAnchorEl(null);

				refreshReservations(false); // true jos halutaan drawer auki käyttäjäpoiston yhteydessä, vaatii käyttäjän manuaalisen piiloittamisen listalta. Parempi vaihtoehto olisi joukkovalinta ja sen poisto kerralla.
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};
	return (
		<>
			<Button
				aria-describedby={id}
				variant={getVariant}
				onClick={(e) => handleClick(e)}
			>
				{text ? (
					t("buttons.remove_reservation")
				) : (
					<TooltipWrapper
						title={t("reservation.remove_user_tooltip")}
					>
						<img style={{ height: "70%" }} src={DeleteIcon} />
					</TooltipWrapper>
				)}
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<Typography sx={{ p: 2 }}>
					<Button onClick={() => handleUserRemove()}>
						{t("buttons.confirm")}
					</Button>
					<Button onClick={() => handleClose()}>
						{t("buttons.cancel")}
					</Button>
				</Typography>
			</Popover>
		</>
	);
};
