import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UserContext } from "../../UserContext";
import { LocationCard } from "../List/LocationCard";
// import uuid from "react-uuid";
import { useHorizontalScroll } from "../../../Helpers/useSideScroll";
import { ApiContext } from "../../ApiContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { BackdropLoading } from "../../Common/BackdropLoading";

import { ReactComponent as ProfileIcon } from "../../../Assets/Icons/ProfileIcon.svg";

import { ReactComponent as ToolsIcon } from "../../../Assets/Icons/ToolsIcon.svg";

export const ListLocations = () => {
	const debug = false;

	const { handleTitle, user } = useContext(UserContext);

	const [locations, setLocations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [serverError, setServerError] = useState(false);
	const [noLocations, setNoLocations] = useState(false);

	const { ApiCall } = useContext(ApiContext);
	const ref = useHorizontalScroll();
	const { t } = useTranslation();

	const getLocations = (route) => {
		setIsLoading(true);

		ApiCall("GET", route, null, null)
			.then((res) => {
				if (res.length < 1) setNoLocations(true);
				else setNoLocations(false);
				setLocations(res);
			})
			.catch((err) => {
				setServerError(true);
				if (debug) console.log(err);
			});
	};

	useEffect(() => {
		handleTitle(t("common.locations"));
		setNoLocations(false);
		setServerError(false);
		if (locations.length < 1 && user.name !== "")
			getLocations("client/locations");
	}, []);

	useEffect(() => {
		// force loading animation to show up while fetching and processing data
		if (locations.length > 0 || noLocations || serverError)
			setIsLoading(false);
	}, [locations, noLocations, serverError]);

	return (
		<Box>
			<Stack
				spacing={2}
				sx={{ alignItems: isMobile ? "center" : undefined }}
			>
				{isLoading && <BackdropLoading />}
				{!isLoading && locations.length < 1 && (
					<>
						{serverError && (
							<Box textAlign="center" sx={{ padding: "10%" }}>
								<ToolsIcon stroke="white" />
								<div style={{ height: "25px" }} />
								<Typography>
									{t("location.no_proper_response")}
								</Typography>
							</Box>
						)}
						{noLocations && !serverError && (
							<Box textAlign="center" sx={{ padding: "10%" }}>
								<ProfileIcon stroke="white" />
								<div style={{ height: "25px" }} />
								<Typography>
									{t("location.no_locations_found")}
								</Typography>
							</Box>
						)}
					</>
				)}
				{locations.length < 4 && (
					<Box
						ref={ref}
						style={{
							flexDirection: !isMobile ? "row" : "column",
							display: "flex",
							overflowX: !isMobile ? "scroll" : "none",
							overflowY: !isMobile ? "none" : "scroll",
							flex: 1,
							padding: !isMobile && "20px",
							width: "100%"
						}}
					>
						{locations.map((location) => (
							<LocationCard
								isCard={locations.length < 4 && !isMobile}
								key={location.name}
								data={location}
								getLocations={getLocations}
							/>
						))}
					</Box>
				)}
				{locations.length > 3 && (
					<Box
						sx={{
							flexDirection: "column",
							display: "flex",
							overflowX: "none",
							overflowY: "scroll",
							flex: 1,
							gap: "15px",
							width: "100%"
						}}
					>
						{locations.map((location) => (
							<LocationCard
								/* isCard={locations.length < 4 && !isMobile} */
								key={location.name}
								data={location}
								getLocations={getLocations}
							/>
						))}
					</Box>
				)}
			</Stack>
		</Box>
	);
};
