import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import uuid from "react-uuid";
import { UserContext } from "../UserContext";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { Invitation } from "./Invitation";

export const ListNotifications = () => {
	const { handleTitle } = useContext(UserContext);
	const [invites, setInvites] = useState([]);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const handleInvites = () =>
		ApiCall("GET", "client/get_client_invites").then((res) => {
			setInvites(res);
		});

	useEffect(() => {
		handleTitle(t("common.messages"));
		handleInvites();
	}, []);

	return (
		<Box
			sx={{
				width: "100%"
			}}
		>
			{invites.length > 0 && (
				<Box
					sx={{
						width: "inherit",
						borderRadius: "4px",
						marginBottom: "40px"
					}}
				>
					<Typography
						sx={{
							marginBottom: "20px",
							fontSize: "18px",
							fontWeight: "700"
						}}
					>
						{t("invites.management_invitations")}
					</Typography>
					<Stack style={{ marginLeft: "0px" }}>
						<Stack style={{ marginBottom: "15px" }}>
							{invites.length === 0 && (
								<Typography style={{ marginBottom: "15px" }}>
									{t("invites.not_found")}
								</Typography>
							)}
							{invites.map((invite) => (
								<Invitation
									refresh={handleInvites}
									key={uuid()}
									id={invite.location_id}
									invite={invite}
								/>
							))}
						</Stack>
					</Stack>
				</Box>
			)}
		</Box>
	);
};
