import {
	Button,
	Stack,
	TextField,
	Typography,
	InputAdornment
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import passwordIcon from "../../Assets/Icons/PasswordFieldIcon.svg";
import { ApiContext } from "../ApiContext";
import { Spinner } from "../Common/Spinner";

export const ManagePassword = () => {
	function regex(type, value) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const phoneRegex = /^\+\d{1,}$/;
		const licenseRegex = /^[A-Z]{3}-[0-9]{3}$/;
		const nameRegex = /^[a-zA-Z\säöüÄÖÜß]*$/; // Modified regex to include umlaut characters

		switch (type) {
			case "password":
				if (value.length === 0) return false;
				return value.length <= 6;
			case "email":
				if (value.length === 0) return false;

				return !emailRegex.test(value);
			case "phone":
				if (value.length === 0) return false;

				return !phoneRegex.test(value);
			case "license_plate":
				if (value.length === 0) return false;

				return !licenseRegex.test(value);
			case "name":
				return !nameRegex.test(value);
			default:
				return false;
		}
	}

	const { ApiCall } = useContext(ApiContext);

	const [userData, setUserData] = useState({});

	const { t } = useTranslation();
	const [newPass, setNewPass] = useState("");
	const [loading, setLoading] = useState(true);

	function modifyUserData(type, value) {
		let tempUser = { ...userData };
		tempUser[type] = value;

		// console.log(tempUser);

		setUserData(tempUser);
	}

	const HandleUpdate = () => {
		ApiCall("PUT", `user/${userData.user_id}`, {
			email: userData.email,
			language: userData.language,
			name: userData.name,
			password: newPass,
			phone: userData.phone,
			license_plate: userData.license_plate
		})
			.then(() => {
				window.location.reload(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	function fetchUser() {
		setLoading(true);
		ApiCall("GET", "user", null)
			.then((response) => {
				console.log(response);
				setUserData(response);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		fetchUser();
	}, []);

	return (
		<Box sx={{ width: "100%" }}>
			{!loading ? (
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Typography>{t("login.modify_info")}</Typography>

					<TextField
						placeholder={t("login.name")}
						sx={{
							backgroundColor: "#FAFAFA",
							borderRadius: "5px",
							fontSize: "10px"
						}}
						id="name"
						variant="outlined"
						margin="dense"
						type="text"
						fullWidth={true}
						size="small"
						error={regex("name", userData.name)}
						value={userData.name}
						onChange={(e) => modifyUserData("name", e.target.value)}
						InputLabelProps={{ style: { fontSize: 40 } }}
					/>
					<TextField
						placeholder={t("login.phone")}
						sx={{
							backgroundColor: "#FAFAFA",
							borderRadius: "5px",
							fontSize: "10px"
						}}
						id="phone"
						variant="outlined"
						margin="dense"
						fullWidth={true}
						size="small"
						value={userData.phone}
						error={regex("phone", userData.phone)}
						onChange={(e) =>
							modifyUserData("phone", e.target.value)
						}
						InputLabelProps={{ style: { fontSize: 40 } }}
					/>
					<TextField
						placeholder={t("login.license_plate")}
						sx={{
							backgroundColor: "#FAFAFA",
							borderRadius: "5px",
							fontSize: "10px"
						}}
						id="license_plate"
						variant="outlined"
						margin="dense"
						fullWidth={true}
						error={regex("license_plate", userData.license_plate)}
						size="small"
						value={userData.license_plate}
						onChange={(e) =>
							modifyUserData("license_plate", e.target.value)
						}
						InputLabelProps={{ style: { fontSize: 40 } }}
					/>

					<TextField
						placeholder={t("login.new_password")}
						sx={{
							backgroundColor: "#FAFAFA",
							borderRadius: "5px",
							fontSize: "10px"
						}}
						id="password"
						variant="outlined"
						margin="dense"
						fullWidth={true}
						size="small"
						value={newPass}
						type="password"
						onChange={(e) => setNewPass(e.target.value)}
						InputLabelProps={{ style: { fontSize: 40 } }}
						error={regex("password", newPass)}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									disablePointerEvents
								>
									<img src={passwordIcon} />
								</InputAdornment>
							)
						}}
					/>
					<Button
						disabled={
							regex("password", newPass) ||
							regex("name", userData.name) ||
							regex("phone", userData.phone) ||
							regex("license_plate", userData.license_plate)
						}
						style={{ width: "100%" }}
						variant="contained"
						onClick={() => HandleUpdate()}
					>
						{t("buttons.save")}
					</Button>
				</Stack>
			) : (
				<Spinner />
			)}
		</Box>
	);
};
