/* eslint-disable indent */
import { Button, Stack, Typography, Collapse } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { ApiContext } from "../ApiContext";
import { ConfirmAction } from "../Common/ConfirmAction";
import { SaveAnimationButton } from "../Common/Inputs/SaveAnimationButton";
import { BookReservation } from "./Actions/BookReservation";
import { ModifyReservation } from "./Actions/ModifyReservation";
import { ReservationCard } from "./ReservationCard";
import { ReservationCardMobile } from "./ReservationCardMobile";
import { TextFieldInput2 } from "../Common/TextFieldInput2";

import { ReactComponent as UserGroupIcon } from "../../Assets/Icons/UserGroupIcon.svg";
import { ReactComponent as WalletIcon } from "../../Assets/Icons/WalletIcon.svg";
import { ReactComponent as TimeStartIcon } from "../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as TimeEndIcon } from "../../Assets/Icons/TimeEndIcon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { TextfieldPicker } from "../Common/TextfieldPicker";
import { useTranslation } from "react-i18next";

export const CalendarDrawer = ({
	content,
	closeDrawer,
	passRefreshReservation
}) => {
	const debug = false;

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const [showEdit, setShowEdit] = useState(false);
	const [newPrice, setNewPrice] = useState(0);
	const [newUserLimit, setNewUserLimit] = useState("");
	const [loadingButton, setLoadingButton] = useState(false);
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");

	// const [newArray, setNewArray] = useState([]);

	const checkIfSameDate = () =>
		// Compare dates without time
		content[0].start.toISOString().slice(0, 10) ===
		content[0].end.toISOString().slice(0, 10);

	const handleRefresh = (props) => {
		setLoadingButton(false);
		if (!props) closeDrawer();

		passRefreshReservation();
	};

	const handlePriceChange = (value) => {
		setNewPrice(value);
	};

	/* const handleUserCountChange = (value) => {
		setNewUserLimit(value);
	}; */

	const handleChanges = () => {
		setLoadingButton(true);
		// TODO

		const axiosBody = content
			.map((entry) => [
				{
					reservation_id: entry.id,
					to_time:
						content.length > 1
							? new Date(entry.end).toISOString()
							: new Date(endTime).toISOString(),
					from_time:
						content.length > 1
							? new Date(entry.start).toISOString()
							: new Date(startTime).toISOString(),
					...(newUserLimit !== "" && {
						user_limit: Number(newUserLimit)
					}),
					...(newPrice !== null && { vat_price: Number(newPrice) })
				}
			])
			.flat();

		ApiCall("PUT", "client/reservations", axiosBody)
			.then((res) => {
				if (debug) console.log(res);
				handleRefresh();
			})
			.catch((error) => {
				setLoadingButton(false);
				if (debug) console.log(error);
			});
	};

	const checkForContentType8 = () =>
		content.some((entry) =>
			entry.userReservations.some(
				(subEntry) => subEntry.content_type === 8
			)
		);

	const handleRemove = () => {
		const listEvents = content.map((entry) => entry.id);

		ApiCall("DELETE", "client/reservations", listEvents)
			.then((res) => {
				if (debug) console.log(res);
				handleRefresh();
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		// hinta ja käyttäjämäärä arvot ensimmäisen valitun mukaan
		if (content.length > 0) {
			const vatPrice = Number(content[0].vatPrice);
			setNewPrice(
				vatPrice % 1 === 0 ? vatPrice.toFixed(0) : vatPrice.toFixed(2)
			);
			setNewUserLimit(content[0].userLimit);
		} else {
			setStartTime(new Date());
			setEndTime(new Date());
		}

		if (startTime === "" && endTime === "") {
			setStartTime(new Date(content[0].start));
			setEndTime(new Date(content[0].end));
		}
	}, []);

	return (
		<Box
			sx={{
				padding: isMobile ? "5px" : undefined
			}}
		>
			{content.length < 1 ? (
				<></> // karvalakkiratkaisu
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px"
					}}
				>
					{/* 	<DrawerTitle
						location={selectedLocation.name}
						title={
							content[0].userReservations.length === 0
								? t("calendar.create_new_reservation")
								: t("calendar.modify_reservation")
						}
					/> */}
					{/* <Typography variant={"h6"}>
						{t("calendar.selected_reservations_label")}
					</Typography> */}

					<div>
						{/* <Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								py: "10px",
								px: "10px"
							}}
						>
							<Typography variant="form_bold" minWidth="60px">
								{t("calendar.date_label")}
							</Typography>
							<Typography variant="form_bold" minWidth="73px">
								{t("calendar.time_label")}
							</Typography>
							<Typography variant="form_bold">
								{t("calendar.price_label")}
							</Typography>
						</Stack>
 */}
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								boxSizing: "border-box",
								borderRadius: "4px",
								width: "100%",
								margin: "auto",
								border: "1px solid #F0F0F0",
								backgroundColor: "#FAFAFA"
							}}
						>
							{content.map((entry, index) => {
								const roundedNumber =
									entry.vatPrice %
										Number(entry.vatPrice).toFixed(0) ===
									0
										? Number(entry.vatPrice).toFixed(0)
										: entry.vatPrice;

								return isMobile ? (
									<ReservationCardMobile
										key={index}
										roundedNumber={roundedNumber}
										content={entry}
									/>
								) : (
									<ReservationCard
										key={index}
										first={index === 0}
										last={content.length - 1 === index}
										roundedNumber={roundedNumber}
										content={entry}
									/>
								);
							})}
						</Box>

						<Stack
							direction={isMobile ? "column" : "row"}
							justifyContent="space-between"
							sx={{
								width: "100%",
								paddingTop: "15px",
								gap: "20px"
							}}
						>
							{isDesktop && (
								<Typography
									variant="form_bold"
									sx={{
										paddingLeft: "10px",
										fontSize: "12px"
									}}
								>
									{content.length}{" "}
									{content.length === 1
										? t("calendar.single_reservation_label")
										: t(
												"calendar.multiple_reservation_label"
											)}{" "}
									{t("calendar.reservations_selected_label")}
								</Typography>
							)}
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								gap="20px"
							>
								<Button
									onClick={() => setShowEdit(!showEdit)}
									disabled={checkForContentType8()}
									sx={{ textTransform: "capitalize" }}
								>
									{t("buttons.edit")}
								</Button>

								<ConfirmAction
									/* buttonText={t("buttons.delete")} */
									isDisabled={content[0].users.length > 0}
									isIconButton={true}
									useTrashIcon={true}
									handleTitle={t(
										"calendar.delete_selected_reservations_tooltip"
									)}
									handleConfirmAction={handleRemove}
								/>
							</Stack>
						</Stack>
					</div>

					<Collapse in={showEdit}>
						<Stack spacing={1.5}>
							<Typography variant="form_bold" fontSize="16px">
								{t(
									"calendar.edit_selected_reservations_description"
								)}
							</Typography>

							<TextFieldInput2
								text={newPrice}
								title={t("calendar.price_label")}
								hasProps={
									<WalletIcon
										width="25px"
										height="25px"
										strokeWidth="2.5px"
										stroke={ColorScheme.defaultIconColor}
									/>
								}
								onlyNumbers={true}
								valueChange={handlePriceChange}
							/>

							<TextFieldInput2
								text={newUserLimit}
								title={t("calendar.form_user_amount_label")}
								hasProps={
									<UserGroupIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
								onlyNumbers={true}
								valueChange={setNewUserLimit}
							/>

							{content.length > 1 ? (
								<Typography
									variant="form_bold"
									textAlign="center"
								>
									{t(
										"calendar.prevent_changing_multiple_timeslots"
									)}
								</Typography>
							) : (
								<Stack
									direction={isDesktop ? "row" : "column"}
									spacing={2}
								>
									<TextfieldPicker
										value={startTime}
										label={t("calendar.date_start")}
										excludePast={false}
										valueChange={setStartTime}
										fullDate={!checkIfSameDate()}
										hasProps={
											<TimeStartIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										}
									/>

									<TextfieldPicker
										value={endTime}
										label={t("calendar.date_end")}
										limitValues={true}
										/* limitMin={startTime} */
										excludePast={false}
										fullDate={!checkIfSameDate()}
										valueChange={setEndTime}
										hasProps={
											<TimeEndIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										}
									/>
								</Stack>
							)}

							<Button
								variant="drawerFormAccept"
								disabled={
									newPrice === "" && newUserLimit === ""
								}
								onClick={() => handleChanges()}
							>
								<SaveAnimationButton
									loadingButton={loadingButton}
								/>
							</Button>
						</Stack>
					</Collapse>

					<BookReservation
						content={content}
						refreshReservations={handleRefresh}
					/>
					<Box
						sx={{
							/* border: "1px solid rgba(0,0,0,0.1)", */
							borderRadius: "4px",
							backgroundColor: "white"
						}}
					>
						<ModifyReservation
							content={content}
							refreshReservations={handleRefresh}
						/>
					</Box>
				</div>
			)}
		</Box>
	);
};
