import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { isMobile } from "react-device-detect";

const TooltipWrapper = ({ title, children, hide, ignoreStringRestriction }) => {
	if (!ignoreStringRestriction && typeof title !== "string") return children; // quick fix to prevent non-string content crashing the app
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [timer, setTimer] = useState(null);

	const defineDelay = 0; // in milliseconds
	const autoCloseDelay = 1000; // in milliseconds

	const handleMouseEnter = () => {
		setIsTooltipOpen(true);
	};

	const handleMouseLeave = () => {
		if (timer) clearTimeout(timer);

		const timeout = setTimeout(() => {
			// Set a timer to automatically close the tooltip after {autoCloseDelay} seconds
			const autoCloseTimer = setTimeout(() => {
				setIsTooltipOpen(false);
			}, autoCloseDelay);
			setTimer(autoCloseTimer);
		}, defineDelay);

		setTimer(timeout);
	};

	if (isMobile) return children;

	return (
		<Tooltip
			hidden={hide}
			title={
				!hide && <span style={{ whiteSpace: "pre-line" }}>{title}</span>
			}
			placement="top"
			arrow
			open={title !== undefined && isTooltipOpen} // Only show the tooltip if the title is defined
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			enterTouchDelay={0}
		>
			{children}
		</Tooltip>
	);
};

export default TooltipWrapper;
