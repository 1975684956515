import React from "react";
import { Typography } from "@mui/material";
import { SortingArrow } from "../../Common/IconComponents/SortingArrow";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const SortingOptions = ({ sort, setSort }) => {
	// Define more options if needed or comment out to remove, id has to match server response
	const { t } = useTranslation();
	const sortingOptions = [
		{ label: t("key.user_name"), id: "user_name" },
		{ label: t("key.user_description"), id: "description" },
		/* {
			label: isMobile ? t("key.user_email_mobile") : t("key.user_email"),
			id: "email"
		}, */
		{
			label: t("key.user_phone") + " /\n" + t("key.user_license_plate"),
			id: "phone"
		},
		{
			label: t("key.effective_time_duration"),
			id: "end" // we check and sort only by end date here. Need to improve this, if we want to sort by start and end date and then by time from single function, which may be a bit confusing to user without proper indicators which value is being sorted and how
		},
		{
			label: t("key.status"),
			id: "access_status"
		}
	];

	const sortingOptionsMobile = [
		{ label: t("key.user_name"), id: "user_name" },
		{ label: t("key.user_description"), id: "description" },
		{
			label: t("key.effective_time_duration"),
			id: "end" // we check and sort only by end date here. Need to improve this, if we want to sort by start and end date and then by time from single function, which may be a bit confusing to user without proper indicators which value is being sorted and how
		},
		{
			label: t("key.status"),
			id: "access_status"
		}
	];

	function changeSort(id) {
		if (sort.split(" ")[0] === id) {
			if (sort.split(" ")[1] === "ASC") {
				setSort(id + " DESC");
			} else {
				setSort(id + " ASC");
			}
		} else {
			setSort(id + " ASC");
		}
	}

	function isAscending() {
		return sort.split(" ")[1] === "ASC";
	}

	function isSorted(id) {
		return sort.split(" ")[0] === id;
	}

	return (
		<div
			style={{
				display: "flex",
				flexWrap: isMobile ? "wrap" : "nowrap",
				justifyContent: "space-between",
				/* width: "100%", */
				boxSizing: "border-box",
				backgroundColor: ColorScheme.tolotechBlue
			}}
		>
			{!isMobile &&
				sortingOptions.map((option) => (
					<div
						key={option.id}
						style={{
							display: "flex",
							width: isMobile ? "50%" : "calc(100% / 5)",
							padding: "15px",
							paddingLeft: "20px",
							borderRight:
								"1px solid" +
								ColorScheme.tolotechTransparentWhite,
							...(isMobile && {
								borderBottom:
									"1px solid" +
									ColorScheme.tolotechTransparentWhite
							}),
							alignItems: "center",
							cursor: "pointer",
							boxSizing: "border-box"
						}}
						onClick={() => changeSort(option.id)}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<Typography
								fontSize="13px"
								sx={{ wordBreak: "break-word" }}
							>
								{option.label}
							</Typography>
							{isSorted(option.id) ? (
								<div style={{ width: "16px", height: "19px" }}>
									<SortingArrow order={isAscending()} />
								</div>
							) : (
								<div style={{ width: "16px" }} />
							)}
						</div>
					</div>
				))}
			{isMobile &&
				sortingOptionsMobile.map((option) => (
					<div
						key={option.id}
						style={{
							display: "flex",
							width: isMobile ? "50%" : "calc(100% / 5)",
							padding: "15px",
							paddingLeft: "20px",
							borderRight:
								"1px solid" +
								ColorScheme.tolotechTransparentWhite,
							...(isMobile && {
								borderBottom:
									"1px solid" +
									ColorScheme.tolotechTransparentWhite
							}),
							alignItems: "center",
							cursor: "pointer",
							boxSizing: "border-box"
						}}
						onClick={() => changeSort(option.id)}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<Typography
								fontSize="13px"
								sx={{ wordBreak: "break-word" }}
							>
								{option.label}
							</Typography>
							{isSorted(option.id) ? (
								<div style={{ width: "16px", height: "19px" }}>
									<SortingArrow order={isAscending()} />
								</div>
							) : (
								<div style={{ width: "16px" }} />
							)}
						</div>
					</div>
				))}
		</div>
	);
};
