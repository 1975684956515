import React, { useContext, useEffect, useState } from "react";
import {
	Stack,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as Mobile } from "../Assets/Icons/MobileIcon.svg";
import { ReactComponent as PC } from "../Assets/Icons/PcIcon.svg";
import { ColorScheme } from "../Themes/ColorScheme";
import { useTranslation } from "react-i18next";
import { ApiContext } from "./ApiContext";
import { BackdropLoading } from "./Common/BackdropLoading";
import { UserContext } from "./UserContext";
import LandingAnimation from "../Helpers/LandingAnimation";

export const UserManualContent = () => {
	const [loading, setLoading] = useState(true);
	const [q, set_q] = useState([]);
	const { ApiCall } = useContext(ApiContext);
	const { handleTitle } = useContext(UserContext);

	const { t } = useTranslation();

	useEffect(() => {
		handleTitle(t("common.support"));
		setLoading(true);
		ApiCall("GET", "faq")
			.then((qs) => {
				set_q(qs);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	function linkRow(urlFin, urlEng) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center"
				}}
			>
				<Button
					sx={{
						backgroundColor: ColorScheme.tolotechDarkBlue,
						padding: "10px"
					}}
					href={urlFin}
					target="_blank"
				>
					<Typography
						textTransform={"capitalize"}
						color={ColorScheme.tolotechWhite}
					>
						{t("user_manuals.in_finnish")}
					</Typography>
				</Button>

				<Button
					sx={{
						backgroundColor: ColorScheme.tolotechDarkBlue,
						marginLeft: "5px",
						padding: "10px"
					}}
					href={urlEng}
					target="_blank"
				>
					<Typography
						textTransform={"capitalize"}
						color={ColorScheme.tolotechWhite}
					>
						{t("user_manuals.in_english")}
					</Typography>
				</Button>
			</Box>
		);
	}

	function titleRow(title, type) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center"
				}}
			>
				<Box
					sx={{
						backgroundColor:
							type !== 1
								? ColorScheme.tolotechBlue
								: ColorScheme.tolotechLightBlue,
						justifyContent: "center",
						alignItems: "center",
						display: "flex",
						marginRight: "20px",
						padding: "5px",
						borderRadius: "4px"
					}}
				>
					{type === 1 ? (
						<Mobile
							stroke={"white"}
							width={"25px"}
							height={"25px"}
							strokeWidth={"1.5px"}
						/>
					) : (
						<PC
							stroke={"white"}
							width={"25px"}
							height={"25px"}
							strokeWidth={"2px"}
						/>
					)}
				</Box>
				<Typography sx={{ marginTop: "2px", fontWeight: "700" }}>
					{title}
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			{loading ? (
				<BackdropLoading />
			) : (
				<LandingAnimation>
					<Stack>
						<Typography
							variant={"h5"}
							sx={{ marginBottom: "20px" }}
						>
							{t("user_manuals.user_manuals") + ":"}
						</Typography>
						<Stack>
							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.tologo_introduction"),
										1
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1mchmH5T8TDo0T0jOJloUtYyPxKN_JfD8/view?usp=share_link",
										"https://drive.google.com/file/d/1SXBzCx7EkiUKvM9gkfv0cekAymxNy6jd/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.tologo_access"),
										1
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/16Dzcgs67sT5vvCBnrXydcfCRdhGWOxcQ/view?usp=share_link",
										"https://drive.google.com/file/d/1ZAoMOmcu5wyhq-317a5s21Kl8Y8Mr8Gg/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.tologo_booking"),
										1
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1dhlfGaCp53cc7OWzHX9v-LwG1TpO4sud/view?usp=share_link",
										"https://drive.google.com/file/d/1R8loUVjEi75YZDaUNAbuedGjrvJD27-m/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.tologo_charging"),
										1
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1dqWnOVtYmOp6P_VWpipdL8IhPv3I9HV2/view?usp=share_link",
										"https://drive.google.com/file/d/1ojFv3lEGCwQ0OmWKWxb7mRoFKONu7t7R/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.admin_introduction"),
										2
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1SLBd9mGbdyAYpm8CxPPhoA7IWmaOpoUK/view?usp=share_link",
										"https://drive.google.com/file/d/1UEwyECuLKbutD9bKV3UY-DAA-bFdFCIJ/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.admin_key_management"),
										2
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1y3OHiqmLorcYz8jjbTNRgbKZPbPG3GSA/view?usp=share_link",
										"https://drive.google.com/file/d/1_8qilUyXvTcdy_qHDM6XqY6c31_50EDk/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.admin_access"),
										2
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1cg8fsBBE0yk3lMCuh4zORc37AYptxufY/view?usp=share_link",
										"https://drive.google.com/file/d/1zM0_sQLAY6NqL6ncx7Z40q-DreS_cjgm/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.admin_booking"),
										2
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1ZzMgjojOpOlk_2COo0H1AYNsSSKytCSR/view?usp=share_link",
										"https://drive.google.com/file/d/10l4FBr8R3mXmwXiuBIYGw9-7EGSCRQvN/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary>
									{titleRow(
										t("user_manuals.admin_charging"),
										2
									)}
								</AccordionSummary>
								<AccordionDetails>
									{linkRow(
										"https://drive.google.com/file/d/1Lh7YnSuBj7HQbugLz9OH1kCSBs83JGmW/view?usp=share_link",
										"https://drive.google.com/file/d/1waq0GNy0TsaJNQLxmU3qSgWyWE4E0FdD/view?usp=share_link"
									)}
								</AccordionDetails>
							</Accordion>
						</Stack>
						<Typography
							variant={"h5"}
							sx={{ marginBottom: "20px", marginTop: "50px" }}
						>
							{t("user_manuals.FAQ") + ":"}
						</Typography>
						<Stack>
							{q.map((question, index) => (
								<Accordion key={index}>
									<AccordionSummary>
										<Typography sx={{ fontWeight: "700" }}>
											{question.question_fi}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography variant="p" fontSize={14}>
											{question.answer_fi}
										</Typography>
									</AccordionDetails>
								</Accordion>
							))}
						</Stack>
					</Stack>
				</LandingAnimation>
			)}
		</Box>
	);
};
