import React, { useEffect, useState, useContext } from "react";
import {
	TextField,
	InputAdornment,
	Typography,
	Button,
	Stack,
	Grid,
	Box,
	CircularProgress
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import logoImage from "../../Assets/Images/Tolotech_logo_white_new.png";
import passwordIcon from "../../Assets/Icons/PasswordFieldIcon.svg";
import usernameIcon from "../../Assets/Icons/UsernameFieldIcon.svg";
// import backgroundImage from "../../Assets/Images/backgroundImage1.png";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const styles = {
	paperContainer: {
		/* backgroundImage: `url(${backgroundImage})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover", */
		background: "linear-gradient(to bottom, #3498db, #9b59b6)", // from blueish to purpleish
		minHeight: "100%",
		position: "fixed",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		boxShadow: "inset 0px 0px 0px 1500px rgba(6, 41, 67, 0.7)"
	}
};

export const UserDetails = () => {
	const debug = false;

	const [n, setName] = useState("");
	const [pw, setPassword] = useState("");
	const [cpw, setConfirmPassword] = useState("");
	const [user, setUser] = useState(null);
	const [settingName, setSettingName] = useState(false);

	const navigate = useNavigate();

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	useEffect(() => {
		ApiCall("GET", "user", null)
			.then((res) => {
				if (res.name !== "") navigate("/");
				setUser(res);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	}, []);

	const handleDetails = (event) => {
		setSettingName(true);
		let body = {};
		body.password = pw;
		body.name = n;

		event.preventDefault();
		ApiCall("PUT", `user/${user.user_id}`, body)
			.then(() => {
				navigate("/locations");
				setSettingName(false);
			})
			.catch(() => {
				setName("");
				setPassword("");
				setConfirmPassword("");
				setSettingName(false);
			});
	};

	return (
		<>
			<div style={styles.paperContainer} />

			<Box
				sx={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
					flexDirection: "column",
					overflow: "auto"
					// position: "absolute"
				}}
			>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					sx={{
						position: "fixed",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)", // Center horizontally and vertically
						marginLeft: "0px", // style override
						width: isMobile ? "85%" : "450px",
						maxWidth: "450px",
						// height: "max-content",
						padding: isMobile ? "20px" : "40px",
						border: "1px solid #717171",
						borderRadius: "14px",
						backgroundColor: "rgba(196, 196, 196, 0.05)",
						zIndex: 998
					}}
				>
					<Grid sx={{ padding: "25px" }}>
						<img
							src={logoImage}
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								height: "80px"
							}}
						/>
					</Grid>
					<form onSubmit={handleDetails} style={{ width: "100%" }}>
						<Typography
							align="center"
							style={{ margin: 30 }}
							color={"white"}
						>
							{t("login.new_user_guide")}
						</Typography>
						<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
							{t("login.user_real_name")}*
						</Typography>
						<TextField
							placeholder={t("login.user_real_name")}
							sx={{
								backgroundColor: "#FAFAFA",
								borderRadius: "5px",
								fontSize: "10px",
								marginBottom: "20px"
							}}
							id="name"
							variant="outlined"
							margin="dense"
							fullWidth={true}
							size="small"
							onChange={(e) => setName(e.target.value)}
							InputLabelProps={{ style: { fontSize: 40 } }}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										disablePointerEvents
									>
										<img src={usernameIcon} />
									</InputAdornment>
								)
							}}
						/>
						<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
							{t("login.user_password_label")}
						</Typography>
						<TextField
							placeholder={t("login.new_password")}
							sx={{
								backgroundColor: "#FAFAFA",
								borderRadius: "5px",
								fontSize: "10px"
							}}
							id="password"
							variant="outlined"
							margin="dense"
							type="password"
							autoComplete="new-password"
							fullWidth={true}
							size="small"
							onChange={(e) => setPassword(e.target.value)}
							InputLabelProps={{ style: { fontSize: 40 } }}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										disablePointerEvents
									>
										<img src={passwordIcon} />
									</InputAdornment>
								)
							}}
						/>
						<TextField
							placeholder={t("login.repeat_password")}
							sx={{
								backgroundColor: "#FAFAFA",
								borderRadius: "5px",
								fontSize: "10px"
							}}
							id="password2"
							variant="outlined"
							margin="dense"
							type="password"
							autoComplete="new-password"
							fullWidth={true}
							size="small"
							onChange={(e) => setConfirmPassword(e.target.value)}
							InputLabelProps={{ style: { fontSize: 40 } }}
							InputProps={{
								endAdornment: (
									<InputAdornment
										position="end"
										disablePointerEvents
									>
										<img src={passwordIcon} />
									</InputAdornment>
								)
							}}
						/>
						<Stack
							style={{
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							{!settingName ? (
								<Stack style={{ width: "100%" }}>
									<Button
										style={{ marginTop: 30 }}
										disabled={
											pw === "" || n === "" || pw !== cpw
												? true
												: false
										}
										type="submit"
										variant="contained"
										sx={{
											backgroundColor: "#296DAE",
											color: "#FFFFFF",
											fontSize: "10px",
											width: 1,
											borderRadius: "4px",
											height: "44px"
										}}
									>
										{t("buttons.ready")}
									</Button>
								</Stack>
							) : (
								<CircularProgress color="info" />
							)}
						</Stack>
					</form>
				</Grid>
			</Box>
		</>
	);
};
