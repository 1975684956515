/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import uuid from "react-uuid";

import { UserContext } from "../../UserContext";
import { ClientCard } from "./ClientCard";
import { ApiContext } from "../../ApiContext";
import { Spinner } from "../../Common/Spinner";

export const ClientSlideList = ({ locationId, triggerRefresh }) => {
	const debug = false;

	const [listUsers, setListUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [noManagers, setNoManagers] = useState(false);

	const { selectedLocation } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);

	const userRefresh = () => {
		ApiCall(
			"GET",
			`client/get_location_clients/${selectedLocation.location_id}`,
			null
		)
			.then((users) => {
				setListUsers(users);
				if (users.length === 0) setNoManagers(true);
			})
			.catch((error) => {
				if (debug) console.log(error);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (selectedLocation.location_id !== undefined) {
			setNoManagers(false);
			userRefresh();
		}
	}, [selectedLocation, triggerRefresh]);

	useEffect(() => {
		if (noManagers || listUsers.length > 0) setIsLoading(false);
	}, [listUsers, noManagers]);

	return (
		<Box sx={{ height: "fit-content" }}>
			{isLoading ? (
				<Spinner />
			) : (
				listUsers.map((user) => (
					<ClientCard
						key={uuid()}
						user={user}
						locationData={locationId}
						refresh={userRefresh}
					/>
				))
			)}
		</Box>
	);
};
