import { Button, IconButton, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../Assets/Icons/DeleteIcon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { Spinner } from "./Spinner";
import TooltipWrapper from "./TooltipWrapper";
import { ReactComponent as TrashIcon } from "../../Assets/Icons/TrashDeleteIcon.svg";

export const ConfirmAction = ({
	buttonText,
	buttonVariant,
	handleConfirmAction, // always required
	isIconButton,
	hasButton, // wip add only confirm popover to any custom button
	remoteAnchor,
	handleTitle, // if empty, tooltip wont render at all
	isDisabled,
	fontColor,
	isLoading,
	removePadding,
	hasChild, // can be wrapped to anything that only needs tooltip and/or confirm action
	children,
	fullWidth,
	hideTooltip,
	useTrashIcon
}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? "confirm-action-popover" : undefined;

	const handleClick = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleConfirm = (event) => {
		event.stopPropagation();
		event.preventDefault();
		handleConfirmAction();
		handleClose(event);
	};

	const handleClose = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setAnchorEl(null);
	};

	useEffect(() => {
		if (hasButton) setAnchorEl(remoteAnchor);
	}, []);

	return (
		<>
			{isIconButton && !hasButton && (
				<TooltipWrapper
					hide={hideTooltip}
					title={handleTitle}
					ignoreStringRestriction={true}
				>
					<IconButton
						onClick={(e) => handleClick(e)}
						sx={{ padding: removePadding && "0px" }}
					>
						{useTrashIcon ? (
							<TrashIcon
								stroke={ColorScheme.softRed}
								width={28}
								height={28}
							/>
						) : (
							<img
								style={{
									height: "20px"
								}}
								src={DeleteIcon}
							/>
						)}
					</IconButton>
				</TooltipWrapper>
			)}

			{!isIconButton && !hasButton && !hasChild && (
				<TooltipWrapper
					hide={hideTooltip}
					title={handleTitle}
					ignoreStringRestriction={true}
				>
					<Button
						style={{
							backgroundColor: isDisabled
								? ColorScheme.disabled
								: ColorScheme.tolotechBlue,
							color: isDisabled ? "#C6C6C6" : "white",
							...(buttonVariant === undefined && {
								paddingTop: "10px",
								paddingBottom: "10px",
								paddingLeft: "20px",
								paddingRight: "20px"
							}),
							height: "45px",
							width: fullWidth ? "100%" : "120px"
						}}
						disabled={isDisabled}
						aria-describedby={id}
						variant={buttonVariant}
						onClick={(e) => handleClick(e)}
					>
						{isLoading ? (
							<Spinner small />
						) : (
							<Typography textTransform={"capitalize"}>
								{buttonText}
							</Typography>
						)}
					</Button>
				</TooltipWrapper>
			)}

			{hasButton && (
				<TooltipWrapper
					hide={hideTooltip}
					title={handleTitle}
					ignoreStringRestriction={true}
				>
					<Button
						sx={{ color: fontColor, minWidth: "0px" }}
						onClick={(e) => handleClick(e)}
					>
						<Typography
							textTransform="capitalize"
							textAlign="right"
						>
							{buttonText}
						</Typography>
					</Button>
				</TooltipWrapper>
			)}

			{hasChild && (
				<TooltipWrapper
					hide={hideTooltip}
					title={handleTitle}
					ignoreStringRestriction={true}
				>
					<Button
						sx={{
							width: fullWidth ? "100%" : "auto",
							minWidth: "0px", // removes button default value (64px)
							padding: "0px" // also removes buttons default values
						}}
						onClick={(e) => handleClick(e)}
					>
						{children}
					</Button>
				</TooltipWrapper>
			)}

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={(event) => handleClose(event)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<Typography sx={{ p: 2 }}>
					<Button onClick={(event) => handleConfirm(event)}>
						{t("buttons.confirm")}
					</Button>
					<Button onClick={(event) => handleClose(event)}>
						{t("buttons.cancel")}
					</Button>
				</Typography>
			</Popover>
		</>
	);
};
