export const en_lang = {
	/* common: {
		locations: "Locations",
		doors: "Doors",
		calendar: "Calendar",
		customers: "Customers",
		courses: "Courses",
		campaigns: "Campaigns",
		messages: "Messages"
	},
	titles: {
		reservation_calendar: "",
		delete_selected_reservables: "",
		remove_user: "",
		remove_from_list: "",
		remove_user_campaign: "",
		remove_from_list_email: "",
		remove_timeslot: ""
	},
	warning: {
		device_not_supported:
			"ToloGo management web application doesn't have support for mobile devices yet."
	} */
};
