import { useTranslation } from "react-i18next";

export const weekdays = () => {
	const { t } = useTranslation();

	return [
		{
			short: t("dates.monday_short"),
			long: t("dates.monday_long"),
			weekday: 1
		},
		{
			short: t("dates.tuesday_short"),
			long: t("dates.tuesday_long"),
			weekday: 2
		},
		{
			short: t("dates.wednesday_short"),
			long: t("dates.wednesday_long"),
			weekday: 3
		},
		{
			short: t("dates.thursday_short"),
			long: t("dates.thursday_long"),
			weekday: 4
		},
		{
			short: t("dates.friday_short"),
			long: t("dates.friday_long"),
			weekday: 5
		},
		{
			short: t("dates.saturday_short"),
			long: t("dates.saturday_long"),
			weekday: 6
		},
		{
			short: t("dates.sunday_short"),
			long: t("dates.sunday_long"),
			weekday: 0
		}
	];
};
