import { Box, Button, Stack } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../Themes/ColorScheme";
import { ApiContext } from "../ApiContext";

import { TextFieldInput2 } from "../Common/TextFieldInput2";

import { ReactComponent as TimeToIcon } from "../../Assets/Icons/TimeEndIcon.svg";
import { ReactComponent as TimeFromIcon } from "../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as UserIcon } from "../../Assets/Icons/UserGroupIcon.svg";
import { ReactComponent as PriceIcon } from "../../Assets/Icons/WalletIcon.svg";
import { TextfieldPicker } from "../Common/TextfieldPicker";
/* import { differenceInMinutes, differenceInHours } from "date-fns"; */
import { Spinner } from "../Common/Spinner";

export const SelectedSlots = ({
	content,
	info,
	passRefreshReservation,
	closeDrawer
}) => {
	const debug = false;

	// console.log(content);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const [reservationPrice, setReservationPrice] = useState("");
	const [reservationLimit, setReservationLimit] = useState("");
	const [isAllDay, setIsAllDay] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [showLoader, setShowLoader] = useState(false);

	/* const [loadingButton, setLoadingButton] = useState(false); */

	/* const getDate = content !== undefined && new Date(content[0].start); */
	const getStart = content !== undefined && new Date(content[0].start);
	const getEnd = content !== undefined && new Date(content[0].end);

	/* const formatDate = content !== undefined && getDate.toLocaleDateString(); */
	const formatStart = content !== undefined && getStart.toLocaleTimeString();
	const formatEnd = content !== undefined && getEnd.toLocaleTimeString();

	/* const getTimeDiff =
		content !== undefined && Math.abs(getEnd - getStart) / 36e5; */

	/* const checkTime =
		content !== undefined && getTimeDiff === 1
			? differenceInMinutes(getEnd, getStart) + " minuuttia"
			: differenceInHours(getEnd, getStart) + " tuntia"; */

	const handleSave = () => {
		/* setLoadingButton(true); */

		ApiCall("POST", "client/reservations", [
			{
				from_time:
					startDate !== getStart
						? startDate
						: isAllDay
							? new Date(getStart).toISOString()
							: new Date(getStart).toISOString(),

				to_time:
					endDate !== getEnd
						? endDate
						: isAllDay
							? getEnd.endOf("day").subtract(1, "days").format()
							: new Date(getEnd).toISOString(),

				reservable_id: info.id,

				user_limit: Number(reservationLimit),
				vat_price: Number(reservationPrice)
			}
		])
			.then(() => {
				closeDrawer();
			})
			.then(() => {
				passRefreshReservation();
			})
			.catch((error) => {
				/* setLoadingButton(false); */
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		if (formatStart === formatEnd) setIsAllDay(true);
	}, [content]);

	useEffect(() => {
		setStartDate(getStart);
		setEndDate(getEnd);
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%"
				// padding: "30px",
				// margin: "20px"
				// border: "1px solid black",
				// borderRadius: "4px"
			}}
		>
			<TextFieldInput2
				title={t("reservations.price_label")}
				text={reservationPrice}
				hasProps={
					<PriceIcon
						stroke={ColorScheme.defaultIconColor}
						width={28}
						height={28}
						strokeWidth={2}
					/>
				}
				valueChange={setReservationPrice}
			/>
			<TextFieldInput2
				title={t("reservations.customer_amount_label")}
				text={reservationLimit}
				hasProps={
					<UserIcon
						stroke={ColorScheme.defaultIconColor}
						width={24}
						height={24}
					/>
				}
				valueChange={setReservationLimit}
			/>

			<TextfieldPicker
				fullDate={true}
				value={startDate}
				valueChange={setStartDate}
				/* removeIcon={!isDesktop} */
				label={t("time.start_time_label")}
				excludePast={false}
				hasProps={
					<TimeFromIcon stroke={ColorScheme.defaultIconColor} />
				}
			/>

			<TextfieldPicker
				fullDate={true}
				value={endDate}
				valueChange={setEndDate}
				/* removeIcon={!isDesktop} */
				excludePast={false}
				label={t("time.end_time_label")}
				hasProps={<TimeToIcon stroke={ColorScheme.defaultIconColor} />}
			/>

			<div style={{ flex: 1 }} />

			<Stack direction="row" justifyContent="end">
				<Box
					sx={{
						flexDirection: "row",
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
						gap: "10px"
					}}
				>
					<Button
						sx={{ width: "50%" }}
						variant="outlined"
						onClick={() => closeDrawer()}
					>
						{t("buttons.cancel")}
					</Button>

					<Button
						sx={{
							width: "50%",
							backgroundColor: showLoader ? "white" : "",
							border: showLoader
								? `1px solid ${ColorScheme.tolotechBlue}`
								: ""
						}}
						variant="drawerFormAccept"
						disabled={showLoader}
						onClick={() => {
							setShowLoader(true);
							handleSave();
						}}
					>
						{showLoader ? (
							<Spinner
								hasText={t("spinner_text.wait")}
								small={true}
								color={"black"}
								hasColor={ColorScheme.tolotechBlue}
							/>
						) : (
							t("buttons.save")
						)}
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};
