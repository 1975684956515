import { Button, Grid, Stack, Typography } from "@mui/material";

import React, { useState } from "react";

import { MuiDrawer } from "../Common/MuiDrawer";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import LandingAnimation from "../../Helpers/LandingAnimation";

export const CourseCard = ({ data, updateCardView }) => {
	const { t } = useTranslation();
	const [openDrawer, setOpenDrawer] = useState(false);
	const [drawerPath, setDrawerPath] = useState("");

	const handleClick = (ContentType) => {
		setDrawerPath(ContentType);
		setOpenDrawer(true);
	};

	const handleDrawerClose = () => {
		setOpenDrawer(false);
		updateCardView();
	};

	/* const handleButton = (event) => {
		event.stopPropagation();
		navigate(`/kurssit/${data.location_id}`);
	};*/

	return (
		<LandingAnimation>
			<div
				style={{
					width: isMobile
						? "100%"
						: undefined /* width fix for mobile view */
				}}
			>
				<Stack
					onClick={() => handleClick("AddCourse")}
					direction="row"
					sx={{
						width: isMobile ? undefined : "35vw",
						maxWidth: "400px",
						height: "250px",
						display: "flex",
						flexDirection: "row",
						backgroundColor: "#ffffff",
						borderRadius: "4px",
						alignItems: "center",
						justifyContent: "flex-end",
						/* marginRight: isMobile ? undefined : "20px",
						marginLeft: isMobile ? undefined : "20px", 
						marginBottom: "10px",*/
						color: "black",
						transition: ".3s",
						overflow: "hidden",
						"&:hover": {
							scale: "1.01"
						}
					}}
				>
					{!isMobile && (
						<div
							style={{
								height: "100%",
								width: "40%",
								resizeMode: "contain",
								overflow: "hidden",
								display: "flex"
							}}
						>
							{
								<div
									style={{
										height: "100%",
										width: "100%",
										resizeMode: "cover",
										overflow: "hidden"
									}}
								>
									<img
										style={{
											height: "100%",
											resizeMode: "contain"
										}}
										src={
											data.logo_image !== null
												? data.logo_image.image
												: ""
										}
									/>
								</div>
							}
						</div>
					)}
					<Grid
						style={{
							height: "100%",
							width: isMobile ? "100%" : "60%", // since there is no course image being used on mobile view, the width is set to 100%
							padding: "30px",
							paddingLeft: "30px",
							paddingRight: "30px",
							backgroundColor: "#FFFFFF",
							display: "flex",
							flexDirection: "column",
							zIndex: 1,
							justifyContent: "space-between"
						}}
					>
						<Stack
							justifyContent="space-between"
							alignItems="center"
							direction="row"
							sx={{
								width: "100%"
							}}
						>
							<Typography
								style={{
									fontSize: isMobile ? "16px" : "20px",
									fontWeight: "700"
								}}
							>
								{data.name}
							</Typography>

							<Typography
								style={{
									fontSize: isMobile ? "14px" : "16px",
									fontWeigth: "700",
									minWidth: "60px",
									color: ColorScheme.tolotechLightBlue,
									textAlign: "center"
								}}
							>
								{data.vat_price +
									" " +
									t("common.currency_symbol")}
							</Typography>
						</Stack>
						<Stack
							xs={2}
							style={{
								width: "100%",
								alignItems: "center",
								flexDirection: "row",
								justifyContent: "space-between",
								display: "flex"
							}}
						>
							<Typography
								style={{
									fontSize: "14px",
									fontWeight: "500",
									color: "#264166"
								}}
							>
								{data.contact_name}
							</Typography>
							<Typography
								style={{ fontSize: "12px", fontWeight: "700" }}
							>
								{data.type}
							</Typography>
						</Stack>
						<Grid
							item
							xs={2}
							sx={{
								flexDirection: "column",
								display: "flex",
								flexWrap: "wrap",
								marginTop: "15px"
							}}
						>
							<Typography style={{ fontSize: "10px" }}>
								{data.time}
							</Typography>
							<Typography style={{ fontSize: "10px" }}>
								{data.date}
							</Typography>
						</Grid>

						<Stack
							xs={2}
							sx={{
								flexDirection: "row",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between"
							}}
						>
							<div
								style={{
									width: "70%",
									height: "5px",
									backgroundColor: "#D3D3D3",
									borderRadius: "5px",
									marginRight: "20px"
								}}
							>
								<div
									style={{
										width: `${
											((data.user_limit -
												data.user_count) /
												data.user_limit) *
											100
										}%`,
										height: "5px",
										backgroundColor: "#0D862F",
										borderRadius: "5px"
									}}
								/>
							</div>
							<div style={{ width: "30%" }}>
								{data.user_limit === 0 ? (
									<Typography
										style={{
											fontSize: "12px",
											fontWeight: "700"
										}}
									>
										{t("courses.course_full_label")}
									</Typography>
								) : (
									<Typography
										style={{
											fontSize: "12px",
											fontWeight: "700",
											textAlign: "center"
										}}
									>
										{data.user_limit -
											data.user_count +
											" / " +
											data.user_limit}
									</Typography>
								)}
							</div>
						</Stack>
						<div
							style={{
								width: "100%",
								marginTop: "10px",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between"
							}}
						>
							<Button
								sx={{ width: "100%" }}
								/* fullWidth*/
								variant="contained"
								onClick={(event) => {
									event.stopPropagation();
									handleClick("ListCourseUsers");
								}}
							>
								{t("buttons.participants")}
							</Button>
						</div>
					</Grid>
				</Stack>
				<MuiDrawer
					open={openDrawer}
					handleDrawerClose={handleDrawerClose}
					data={data}
					contentType={drawerPath}
				/>
			</div>
		</LandingAnimation>
	);
};
