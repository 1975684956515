import {
	Box,
	Typography,
	Stack,
	Button,
	Collapse,
	Dialog
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext";
// import DeleteIcon from "../../Assets/Icons/DeleteIcon.svg";
import { Spinner } from "../Common/Spinner";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";

import { ConfirmAction } from "../Common/ConfirmAction";
import { isDesktop, isMobile } from "react-device-detect";
// import { modifySvg } from "../../Helpers/modifySvg";
import { ColorScheme } from "../../Themes/ColorScheme";

import SVG from "react-inlinesvg";

import { AlertBar } from "../Common/AlertBar";

import { format } from "date-fns";
import { ContinuousCreateDialog } from "./ContinuousCreateDialog";

function getWeekday(index) {
	switch (index) {
		case 0:
			return "Sunnuntai";
		case 1:
			return "Maanantai";
		case 2:
			return "Tiistai";
		case 3:
			return "Keskiviikko";
		case 4:
			return "Torstai";
		case 5:
			return "Perjantai";
		case 6:
			return "Lauantai";
		default:
			return "Unknown";
	}
}

export const ContinuousReservationItem = ({ reserv, isLast, passRefresh }) => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const [iconSvg, setIconsvg] = useState("");
	const [strokeColor, setStrokeColor] = useState("");

	useEffect(() => {
		setIconsvg(reserv.short_reservable?.service_class?.image || "");
		setStrokeColor(
			reserv.short_reservable?.service_class?.color || "black"
		);
	}, []);

	function removeContinuousReservation() {
		ApiCall("DELETE", `client/reservation/continuous/${reserv.id}`, null)
			.then(() => {
				passRefresh();
			})
			.catch(() => {
				passRefresh();
			});
	}

	return (
		<Box
			sx={{
				padding: "20px",
				display: "flex",
				flexDirection: isMobile ? "column" : "row",
				justifyContent: "space-between",
				borderBottom: !isLast ? "1px solid #E4E4E4" : "0px solid white"
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				sx={{
					display: "flex",
					alignItems: "center",
					// borderBottom: isMobile ? "1px solid #E4E4E4" : undefined,
					marginBottom: isMobile ? "10px" : undefined,
					paddingBottom: isMobile ? "10px" : undefined
				}}
			>
				{!isMobile && (
					<Box
						sx={{
							width: "70px",
							marginRight: "20px",
							px: "10px",
							alignItems: "center",
							display: "flex",
							flexDirection: "column",
							textAlign: "center"
						}}
					>
						<div>
							<SVG
								src={iconSvg}
								stroke={strokeColor}
								width="30"
								height="30"
							/>
						</div>
						<Typography
							sx={{
								marginTop: "5px",
								fontSize: "12px",
								fontWeight: "700",
								color: "rgba(0,0,0,0.5)"
							}}
						>
							{reserv.short_reservable.name}
						</Typography>
					</Box>
				)}
				<Box>
					<Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
						{reserv.user?.name || ""}
					</Typography>
					<Typography
						sx={{
							fontSize: "12px",
							fontWeight: "700",
							color: "rgba(0,0,0,0.5)"
						}}
					>
						{reserv.user?.email || ""}
					</Typography>
				</Box>

				{isMobile && (
					<ConfirmAction
						handleTitle={t("buttons.delete")}
						isIconButton={true}
						handleConfirmAction={removeContinuousReservation}
					/>
				)}
			</Stack>

			<Stack
				direction="row"
				justifyContent={isMobile && "space-between"}
				sx={{
					display: "flex",
					alignItems: "center"
				}}
			>
				<Box
					sx={{
						justifyContent: "center",
						display: "flex",
						flexDirection: "column",
						alignItems: isMobile ? "flex-start" : "flex-end",
						marginRight: "15px"
					}}
				>
					{isMobile && (
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: "600"
								// color: "rgba(0,0,0,0.5)"
							}}
						>
							{reserv.short_reservable.name}
						</Typography>
					)}

					<div
						style={{
							display: isMobile ? "flex" : "hidden",
							alignItems: "center",
							gap: "3px",
							textAlign: "right"
						}}
					>
						<Typography
							sx={{
								fontSize: isMobile ? "12px" : "14px",
								fontWeight: "700",
								color: isMobile && "rgba(0,0,0,0.5)",
								textAlign: !isMobile && "right",
								textTransform: "capitalize"
							}}
						>
							{getWeekday(reserv.weekday)}
						</Typography>

						<Typography
							sx={{
								fontSize: "12px",
								fontWeight: "700",
								color: "rgba(0,0,0,0.5)"
							}}
						>
							{format(new Date(reserv.from_time), "HH:mm") +
								" - " +
								format(new Date(reserv.to_time), "HH:mm")}
						</Typography>

						<Typography
							sx={{
								fontSize: "12px",
								fontWeight: "700",
								color: "rgba(0,0,0,0.5)"
							}}
						>
							{format(
								new Date(reserv.starting_date),
								"dd.MM.yyyy"
							) +
								" - " +
								format(
									new Date(reserv.expiration_date),
									"dd.MM.yyyy"
								)}
						</Typography>
					</div>
				</Box>
				{!isMobile && (
					<ConfirmAction
						handleTitle={t("buttons.delete")}
						isIconButton={true}
						handleConfirmAction={removeContinuousReservation}
					/>
				)}

				{/* <IconButton
					onClick={() => removeContinuousReservation()}
					sx={{ marginLeft: "10px" }}
				>
					<img
						style={{ height: "15px" }}
						src={DeleteIcon}
						title={t("buttons.delete")}
					/>
				</IconButton> */}
			</Stack>
		</Box>
	);
};

export const MemoizedContinuosItem = React.memo(ContinuousReservationItem);

export const ContinuousReservationList = ({ passRefresh }) => {
	const debug = false;

	const { selectedLocation } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const [continuousReservations, setContinuousReservations] = useState([]);
	const [loading, setLoading] = useState(true);
	const [openDialog, setOpenDialog] = useState(false);

	const [showError, setShowError] = useState(null);

	const loadContinuousReservations = () => {
		setLoading(true);

		ApiCall(
			"GET",
			`client/reservation/continuous/${selectedLocation.location_id}`,
			null
		)
			.then((door) => {
				setContinuousReservations(door);
				setShowError(null);
			})
			.catch((error) => {
				setShowError(error);
				if (debug) console.log(error);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		loadContinuousReservations();
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: "15px",
				paddingBottom: "20px"
			}}
		>
			{/* 	<DrawerTitle
				title={t("calendar.continuous_reservations_label")}
				location={selectedLocation.name}
			/> */}

			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				fullWidth={!isDesktop}
			>
				<ContinuousCreateDialog
					closeDialog={() => setOpenDialog(false)}
				/>
			</Dialog>

			<Box
				sx={{ width: "100%", display: "flex", justifyContent: "right" }}
			>
				<Button
					variant="smallAccept"
					sx={{ backgroundColor: ColorScheme.tolotechBlue }}
					onClick={() => setOpenDialog(true)}
				>
					{t("buttons.create_new")}
				</Button>
			</Box>

			{loading && <Spinner />}
			{!loading && continuousReservations.length === 0 && (
				<Typography
					sx={{
						fontSize: "14px",
						fontWeight: "700",
						color: "rgba(0,0,0,0.5)"
					}}
				>
					{t("calendar.continuous_reservations_not_found")}
				</Typography>
			)}
			{!loading && continuousReservations.length > 0 && (
				<Box
					sx={{
						border: "1px solid #E4E4E4",
						backgroundColor: "white",
						borderRadius: "4px"
					}}
				>
					{continuousReservations.map((reserv, i) => (
						<MemoizedContinuosItem
							passRefresh={passRefresh}
							isLast={i === continuousReservations.length - 1}
							key={reserv.id}
							reserv={reserv}
						/>
					))}
				</Box>
			)}

			<Collapse in={showError !== null}>
				<AlertBar showAlert textContent={showError} type="error" />
			</Collapse>
			<Box height={"100px"}></Box>
		</Box>
	);
};
