/* eslint-disable indent */
import { IconButton } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { IconSwitchComponent } from "../IconSwitchComponent";
import { useTranslation } from "react-i18next";

export const StyledLinkButton = ({
	current_path,
	path,
	title,
	logout,
	hasBadge,
	ignoreStyling,
	isPopover,
	togglePopover,
	handlePopoverContent,
	hasTooltip,
	isBlocked
}) => {
	const { t } = useTranslation();

	const deleteAllCookies = () => {
		let cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++)
			// let cookie = cookies[i];
			// let eqPos = cookie.indexOf("=");
			// let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie =
				"webapp-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
	};

	return (
		<>
			{isPopover ? (
				<IconButton
					sx={{
						color: "#FFFFFF",
						textDecoration: "none",
						width: isMobile ? "40px" : "80px",
						transition: ".3s all",
						zIndex: "0"
					}}
					onClick={(event) => {
						handlePopoverContent(path);
						togglePopover(event);
					}}
					id={title}
					aria-labelledby={title}
					button-key={title}
				>
					<IconSwitchComponent
						current_path={current_path}
						path={path}
						title={title}
						hasTooltip={hasTooltip}
						hasBadge={hasBadge}
						ignoreStyling={ignoreStyling}
					/>
				</IconButton>
			) : (
				<Link
					style={{
						color: "#FFFFFF",
						textDecoration: "none",
						width: "100%",
						transition: ".3s all"
					}}
					to={isBlocked ? undefined : `/${path}`}
					onClick={logout ? () => deleteAllCookies() : ""}
				>
					{isBlocked ? (
						<IconSwitchComponent
							path={path}
							isBlocked={isBlocked}
							hasTooltip
							title={t("location.billing_info_missing")}
							ignoreStyling
						/>
					) : (
						<IconSwitchComponent
							current_path={current_path}
							path={path}
							title={title}
							hasTooltip={hasTooltip}
							hasBadge={hasBadge}
							ignoreStyling={ignoreStyling}
						/>
					)}
				</Link>
			)}
		</>
	);
};
