import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../../ApiContext";
import { SnackContext } from "../../../SnackContext";
import { UserContext } from "../../../UserContext";
import { CustomizeButton } from "./Components/CustomizeButton";
import { FormCancel } from "./Components/FormCancel";
import { FormEdit } from "./Components/FormEdit";
import { FormSave } from "./Components/FormSave";

export const LocationSettings = ({ data, refreshData }) => {
	const debug = false;

	const { ApiCall } = useContext(ApiContext);
	const { showSnackMessage } = useContext(SnackContext);
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	const [editMode, setEditMode] = useState(false);
	const [withdrawalTime, setWithdrawalTime] = useState("");

	const cancelAction = () => {
		setEditMode(false);
		setWithdrawalTime(data?.withdrawal_time || "");
	};

	const handleSave = () => {
		ApiCall("PUT", `location/update/${data.location_id}`, {
			withdrawal_time: Number(withdrawalTime)
		})
			.then(() => {
				if (editMode) setEditMode(!editMode);
				refreshData(data.location_id);
				showSnackMessage("location_update");
			})
			.catch((error) => {
				if (debug) console.log(error);
				showSnackMessage("general", error.response.status);
			});
	};

	useEffect(() => {
		if (data.withdrawal_time !== undefined)
			setWithdrawalTime(data.withdrawal_time);
	}, [selectedLocation]);

	useEffect(() => {
		setEditMode(false);
	}, [selectedLocation]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				padding: "15px",
				boxSizing: "border-box",
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px",
				borderRadius: "4px"
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					rowGap: "15px"
				}}
			>
				<div
					style={{
						flexDirection: "row",
						display: "flex",
						flex: 1,
						justifyContent: "space-between",
						alignItems: editMode ? "flex-start" : "center"
					}}
				>
					<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
						{/* width to make sure views look the same */}
						<Typography variant="form_text_label">
							{t("location.withdrawal_time_label")}:
						</Typography>
					</div>

					<Stack
						spacing={1}
						sx={{
							width: "100%",
							alignItems: "flex-start"
						}}
					>
						{editMode ? (
							<FormEdit
								value={withdrawalTime}
								changeData={(value) => setWithdrawalTime(value)}
								isRequired={true}
								onlyNumeric={true}
								type={"tel"}
								centeredText
							/>
						) : (
							<Typography
							/* style={{
									textAlign: "center"
								}} */
							>
								{withdrawalTime}{" "}
								{t("location.withdrawal_time_unit")}
							</Typography>
						)}

						{editMode && (
							<Box
								sx={{
									width: "100%",
									display: "flex",
									justifyContent: "flex-end"
								}}
							>
								<FormSave handleSave={handleSave} />
								<FormCancel cancelAction={cancelAction} />
							</Box>
						)}
					</Stack>
					<div style={{ flex: editMode ? 0 : 1 }} />
				</div>
			</div>

			{!editMode && (
				<CustomizeButton
					changeEditMode={() => setEditMode(!editMode)}
				/>
			)}
		</Stack>
	);
};
