import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Dialog, Typography } from "@mui/material";

import { UserContext } from "../UserContext";
import { Spinner } from "../Common/Spinner";
import { ApiContext } from "../ApiContext";
// import { ColorScheme } from "../../Themes/ColorScheme";
import { PlaceholderSkeleton } from "../Common/PlaceholderSkeleton";
import { useTranslation } from "react-i18next";
import { isDesktop, isMobile } from "react-device-detect";
// import { modifySvg } from "../../Helpers/modifySvg";
import { ConfirmAction } from "../Common/ConfirmAction";
import SVG from "react-inlinesvg";
import { ColorScheme } from "../../Themes/ColorScheme";
import {
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	format
} from "date-fns";
import { ExcludedTimesNew } from "./ExcludedTimesNew";

export const ContinuousReservationItem = ({
	reserv,
	isLast,
	passRefresh,
	allReserv
}) => {
	const debug = false;

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const [iconSvg, setIconsvg] = useState("");
	const [strokeColor, setStrokeColor] = useState("black");

	useEffect(() => {
		const service_class = getNames;
		if (service_class !== undefined) {
			setIconsvg(service_class.service_class.image);
			setStrokeColor(service_class.service_class.color);
		}
	}, [allReserv]);

	const removeContinuousReservation = () => {
		ApiCall("DELETE", "client/reservations", [reserv.reservation_id])
			.then(() => {
				passRefresh();
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const getNames = allReserv.find(
		(entry) => entry.id === reserv.reservable_id
	);

	const checkDiff = differenceInDays(
		new Date(reserv.to_time),
		new Date(reserv.from_time)
	);

	const checkHourDifference = differenceInHours(
		new Date(reserv.to_time),
		new Date(reserv.from_time)
	);

	const checkMinuteDifference = differenceInMinutes(
		new Date(reserv.to_time),
		new Date(reserv.from_time)
	);

	const checkTime =
		checkDiff > 0
			? checkDiff + " " + t("calendar.reservation_time_days_label")
			: (checkHourDifference === 0
					? checkMinuteDifference
					: checkHourDifference) +
				" " +
				(checkHourDifference === 0
					? t("calendar.reservation_time_minutes_label")
					: t("calendar.reservation_time_hours_label"));

	const dateRange =
		checkDiff === 0
			? format(new Date(reserv.from_time), "dd.MM.yyyy")
			: format(new Date(reserv.from_time), "dd.MM.yyyy") +
				" - " +
				format(new Date(reserv.to_time), "dd.MM.yyyy");

	return (
		<Box
			sx={{
				padding: !isMobile && "20px",
				...(isMobile && {
					py: "20px",
					paddingLeft: "20px",
					paddingRight: "5px"
				}),
				display: "flex",
				justifyContent: "space-between",
				borderBottom: !isLast ? "1px solid #E4E4E4" : "0px solid white"
			}}
		>
			{allReserv.length === 0 && <PlaceholderSkeleton />}
			{allReserv.length > 0 && (
				<>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						{!isMobile && (
							<Box sx={{ marginRight: "20px" }}>
								<Box sx={{ marginRight: "20px" }}>
									<div>
										<SVG
											src={iconSvg}
											stroke={strokeColor}
											width="30"
											height="30"
										/>
									</div>
								</Box>
							</Box>
						)}
						<Box>
							<Typography
								sx={{ fontSize: "16px", fontWeight: "700" }}
							>
								{getNames.name}
							</Typography>
							<Typography
								sx={{
									fontSize: "12px",
									fontWeight: "700",
									color: "rgba(0,0,0,0.5)"
								}}
							>
								{dateRange}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							flexDirection: "row",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "20px"
						}}
					>
						<Box
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-end",
								paddingRight: "5px"
							}}
						>
							<Typography
								sx={{ fontSize: "16px", fontWeight: "700" }}
							>
								{checkTime}
							</Typography>
							<Typography
								sx={{
									fontSize: "12px",
									fontWeight: "700",
									color: "rgba(0,0,0,0.5)"
								}}
							>
								{format(new Date(reserv.from_time), "HH:mm") +
									"-" +
									format(new Date(reserv.to_time), "HH:mm")}
							</Typography>
						</Box>
						<ConfirmAction
							handleTitle={t("buttons.delete")}
							isIconButton={true}
							handleConfirmAction={removeContinuousReservation}
						/>
					</Box>
				</>
			)}
		</Box>
	);
};

export const ExcludedTimesList = () => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);

	const [excludedTimes, setExcludedTimes] = useState([]);
	const [reservableList, setReservableList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showError, setShowError] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);

	/* const switchTime = () => {
		if (
			format(endDate, "yyyy-MM-dd'T'HH:mm:ss") ===
			format(endDate, "yyyy-MM-dd'T'00:00:00")
		) {
			return format(endOfDay(endDate), "yyyy-MM-dd'T'HH:mm:ss");
		} else {
			return format(startOfMinute(endDate), "yyyy-MM-dd'T'HH:mm:ss");
		}
	}; */

	const fetchReservables = () => {
		ApiCall(
			"GET",
			`location/get_reservables/${selectedLocation.location_id}`
		)
			.then((res) => {
				setReservableList(res);
				showError(null);
			})
			.catch((e) => {
				setShowError(e);
			});
	};

	const loadExcludedTimes = () => {
		setLoading(true);

		ApiCall(
			"GET",
			`client/excluded_times/${selectedLocation.location_id}`,
			null
		)
			.then((door) => {
				setExcludedTimes(door);
				setLoading(false);
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchReservables();
		loadExcludedTimes();
	}, []);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			{/* <DrawerTitle
				title={t("calendar.space_reservations_label")}
				location={selectedLocation.name}
			/> */}

			{loading && <Spinner />}

			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				fullWidth={!isDesktop}
			>
				<ExcludedTimesNew
					reservableList={reservableList}
					closeDialog={() => setOpenDialog(false)}
				/>
			</Dialog>

			<Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
				<Button
					variant="smallAccept"
					sx={{ backgroundColor: ColorScheme.tolotechBlue }}
					onClick={() => setOpenDialog(true)}
				>
					{t("buttons.create_new")}
				</Button>
			</Box>

			{!loading && excludedTimes.length === 0 && (
				<Typography
					sx={{
						fontSize: "14px",
						fontWeight: "700",
						color: "rgba(0,0,0,0.5)"
					}}
				>
					{t("calendar.space_reservations_not_found")}
				</Typography>
			)}
			{!loading && excludedTimes.length > 0 && (
				<Box
					sx={{
						border: "1px solid #E4E4E4",
						backgroundColor: "white",
						borderRadius: "4px"
					}}
				>
					{excludedTimes.map((reserv, i) => (
						<ContinuousReservationItem
							passRefresh={loadExcludedTimes}
							isLast={i === excludedTimes.length - 1}
							key={reserv.id}
							reserv={reserv}
							allReserv={reservableList}
						/>
					))}
				</Box>
			)}
			<Box style={{ height: "100px" }} />
		</Box>
	);
};
