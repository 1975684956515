import React, { useContext, useEffect, useState } from "react";
import { IconButton, Stack, Typography, Box } from "@mui/material";
import CustomizeIcon from "../../../../Assets/Icons/CustomizeIcon.svg";
import { LocationImages } from "./LocationImages";
import { ApiContext } from "../../../ApiContext";
import { useTranslation } from "react-i18next";
import { FormSave } from "./Components/FormSave";
import { FormCancel } from "./Components/FormCancel";
/* import { ImageUpload } from "../../../Common/ImageUpload";*/
/* import { SnackContext } from "../../../SnackContext";*/
import { isMobile } from "react-device-detect";
import { UserContext } from "../../../UserContext";
import { AlertBar } from "../../../Common/AlertBar";

export const PhotoDetail = ({ data, refreshData, images }) => {
	const debug = false;

	const [editMode, setEditMode] = useState(false);
	const [descriptionText, setDescriptionText] = useState("");

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);

	const handleSave = () => {
		ApiCall("PUT", `location/update/${data.location_id}`, handleAxiosBody)
			.then(() => {
				setEditMode(!editMode);
				refreshData(data.location_id);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const handleAxiosBody = {
		description:
			data.description !== descriptionText
				? descriptionText
				: data.description
	};

	const cancelAction = () => {
		setDescriptionText(data.description);
		setEditMode(!editMode);
	};

	useEffect(() => {
		setDescriptionText(data.description);
		refreshData(data.location_id);
	}, []);

	useEffect(() => {
		setEditMode(false);
	}, [selectedLocation]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				padding: "15px",
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px",
				borderRadius: "4px"
				/* overflow: "hidden" */
			}}
		>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start"
				}}
			>
				<Stack spacing={3} sx={{ width: "100%" }}>
					{editMode && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<div />
							<AlertBar
								textContent={t("images.how_to_add_new_image")}
								type="info"
								showAlert
							/>
						</div>
					)}

					<div
						style={{
							flexDirection: "row",
							display: "flex",
							flex: 1,
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
							<Typography variant="form_text_label">
								{t("location.form_location_logo_label")}:
							</Typography>
						</div>
						<Stack
							justifyContent={"flex-end"}
							spacing={1}
							width={"100%"}
						>
							<LocationImages
								data={data}
								editMode={editMode}
								isLogo={true}
								refreshList={refreshData}
							/>
						</Stack>
					</div>

					<div
						style={{
							flexDirection: "row",
							display: "flex",
							flex: 1,
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
							<Typography variant="form_text_label">
								{t("location.form_location_cover_image_label")}:
							</Typography>
						</div>
						<Stack spacing={1} width={"100%"}>
							<LocationImages
								data={data}
								editMode={editMode}
								isBanner={true}
								images={images}
								refreshList={refreshData}
							/>
						</Stack>
					</div>
					<div
						style={{
							flexDirection: "row",
							display: "flex",
							flex: 1,
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
							<Typography variant="form_text_label">
								{t("location.form_location_other_images_label")}
								:
							</Typography>
						</div>
						<Stack spacing={1} sx={{ width: "100%" }}>
							<LocationImages
								data={data}
								editMode={editMode}
								images={images}
								refreshList={refreshData}
							/>
						</Stack>
					</div>

					{editMode && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<FormSave handleSave={handleSave} />
							<FormCancel cancelAction={cancelAction} />
						</Box>
					)}
				</Stack>
			</div>
			{!editMode && (
				<IconButton
					onClick={() => setEditMode(!editMode)}
					sx={{ marginLeft: "15px" }}
				>
					<img src={CustomizeIcon} />
				</IconButton>
			)}
		</Stack>
	);
};
