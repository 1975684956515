import { Box } from "@mui/material";
import React from "react";
import { isDesktop } from "react-device-detect";

import { MainpageContainer } from "./MainpageContainer";

export const Mainpage = () => (
	<Box
		sx={{
			backgroundColor: "#E5E5E5",
			minHeight: isDesktop ? "100vh" : "100dvh",
			overflow: "hidden"
		}}
	>
		<MainpageContainer />
	</Box>
);
