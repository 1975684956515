import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { ReactComponent as BurgerIcon } from "../../../Assets/Icons/BurgerIcon.svg";
import { IconButton, Stack, Typography } from "@mui/material";

// import TolotechLogo from "../../../Assets/Images/Tolotech_logo_white_new.png";
import TolotechLogoText from "../../../Assets/Images/tolotech_logo_white_new_text.png";
import { UserContext } from "../../UserContext";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { MenuItem } from "./Cards/MenuItem";
import { useTranslation } from "react-i18next";

export const MobileSidebarMenu = () => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);
	const [state, setState] = useState(false);
	const [hideContent, setHideContent] = useState(false);

	const MenuLabels = [
		{
			title: t("common.main_page"),
			perm_level: 2,
			link: "/overview",
			isBlocked: hideContent
		},
		{ title: t("common.locations"), perm_level: 0, link: "/locations" },
		{
			title: t("common.doors"),
			perm_level: 1,
			link: "/doors",
			isBlocked: hideContent
		},
		{
			title: t("common.charging"),
			perm_level: 7,
			link: "/charging",
			isBlocked: hideContent
		},
		{
			// lazy way to show this with charging
			title: t("common.customers"),
			perm_level: 7,
			link: "/customers",
			isBlocked: hideContent
		},
		{
			title: t("common.calendar"),
			perm_level: 2,
			link: "/calendar",
			isBlocked: hideContent
		},
		{
			title: t("common.customers"),
			perm_level: 2,
			link: "/customers",
			isBlocked: hideContent
		},
		{
			title: t("common.courses"),
			perm_level: 2,
			link: "/courses",
			isBlocked: hideContent
		},
		{
			title: t("common.campaigns"),
			perm_level: 2,
			link: "/campaigns",
			isBlocked: hideContent
		},
		{
			title: t("common.calendar"),
			perm_level: 8,
			link: "/residents",
			isBlocked: hideContent
		},
		{
			title: t("common.balances"),
			perm_level: 10,
			link: "/balances",
			isBlocked: hideContent
		},
		{
			title: t("common.support"),
			perm_level: 0,
			link: "/support"
		}
	];

	const perm2 =
		selectedLocation.location_permissions &&
		selectedLocation.location_permissions.some(
			(entry) => entry.permission_id === 2
		);
	const perm8 =
		selectedLocation.location_permissions &&
		selectedLocation.location_permissions.some(
			(entry) => entry.permission_id === 8
		);

	const perm7 =
		selectedLocation.location_permissions &&
		selectedLocation.location_permissions.some(
			(entry) => entry.permission_id === 7
		);

	const both = perm2 && perm8;

	// Filter out the "calendar" link object if both is true
	const filteredMenuLabels = both
		? MenuLabels.filter((item) => item.link !== "/calendar")
		: MenuLabels;

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		)
			return;

		setState(open);
	};

	const list = () => (
		<Box
			sx={{
				position: "relative",
				width: "70vw",
				minHeight: "100dvh",
				backgroundColor: ColorScheme.tolotechDarkBlue,
				padding: "20px",
				boxSizing: "border-box"
			}}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<Stack
				direction="row"
				justifyContent="end"
				alignItems="center"
				spacing={2}
				sx={{ padding: "15px", marginBottom: "30px" }}
			>
				<img src={TolotechLogoText} style={{ maxWidth: "70%" }} />
				<div style={{ flex: 1 }} />
			</Stack>

			{hideContent ? (
				<Box sx={{ border: "1px solid white" }}>
					<Typography
						sx={{
							fontSize: "12px",
							color: "white",
							textAlign: "center"
						}}
					>
						{t("location.billing_info_missing")}
					</Typography>
				</Box>
			) : undefined}

			{selectedLocation !== undefined &&
				selectedLocation.location_permissions !== undefined &&
				filteredMenuLabels.map((menu, index) => {
					if (
						perm2 &&
						perm7 &&
						menu.link === "/customers" &&
						menu.perm_level === 7
					)
						return; // hide extra customers link that has perm level 7

					if (
						menu.perm_level === 0 ||
						(selectedLocation.location_permissions !== undefined &&
							selectedLocation.location_permissions.find(
								(obj) =>
									menu.perm_level === 0 ||
									obj.permission_id === menu.perm_level
							) !== undefined)
					)
						return (
							<MenuItem key={index} index={index} menu={menu} />
						);
					else return;
				})}

			<Box
				spacing={1}
				alignItems="center"
				sx={{
					position: "absolute",
					bottom: 20,
					left: "20px",
					flexDirection: "row",
					display: "flex",
					alignItems: "flex-start"
				}}
			>
				<img src={TolotechLogoText} style={{ maxWidth: "60px" }} />
				<div style={{ width: "10px" }}></div>
				<Typography
					variant="caption"
					sx={{
						color: "white",
						marginTop: "-3px"
					}}
				>
					{" ©" + new Date().getFullYear()}
				</Typography>
			</Box>
		</Box>
	);

	useEffect(() => {
		// check billing info
		if (
			selectedLocation.billing?.business_id === undefined ||
			selectedLocation.billing?.customer_name === undefined ||
			selectedLocation.billing?.invoice_address === undefined ||
			selectedLocation.billing?.invoice_operator === undefined ||
			selectedLocation.billing?.contact_name === undefined ||
			selectedLocation.billing?.contact_email === undefined ||
			selectedLocation.billing?.business_id === null ||
			selectedLocation.billing?.customer_name === null ||
			selectedLocation.billing?.invoice_address === null ||
			selectedLocation.billing?.invoice_operator === null ||
			selectedLocation.billing?.contact_name === undefined ||
			selectedLocation.billing?.contact_email === undefined
		)
			setHideContent(true);
		else setHideContent(false); // corrected
		//
	}, [selectedLocation]);

	return (
		<div>
			<IconButton
				onClick={toggleDrawer(true)}
				sx={{
					position: "relative" /* "fixed" */,
					width: "60px",
					height: "100px"
				}}
			>
				<BurgerIcon stroke="white" />
			</IconButton>

			<Drawer open={state} onClose={toggleDrawer(false)}>
				{list()}
			</Drawer>
		</div>
	);
};
