import React, { useState } from "react";
import { UserBalanceSheet } from "./UserBalanceSheet";
import { UserBalanceLogs } from "./UserBalanceLogs";
import { UserOverview } from "./UserOverview";

import { Typography } from "@mui/material";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const BalanceDialog = ({
	data,
	showLogs,
	showBalance,
	handleClose,
	isMobile
}) => {
	const { t } = useTranslation();
	const [toggleMobileLogs, setToggleMobileLogs] = useState(false);

	const userBalance = data?.balances?.reduce(
		(acc, curr) => acc + curr.amount,
		0
	);

	const balanceExpires = data?.balances[0]?.expires_at
		? new Date(data.balances[0].expires_at).toISOString()
		: null;

	// calculate total balance from all types

	return (
		<div
			style={{
				boxSizing: "border-box",
				width: "100%",
				minWidth: isMobile ? undefined : "400px",
				maxWidth: isMobile ? undefined : "400px",
				minHeight: "400px"
			}}
		>
			<div
				style={{
					padding: "20px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					boxSizing: "border-box"
				}}
			>
				<UserOverview data={data} balance={userBalance} />
			</div>

			{/* <div style={{ width: "100%", borderTop: "1px solid #E0E0E0" }} /> */}

			{isMobile && (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						width: "100%",
						height: "40px",
						/* padding: "20px", */
						boxSizing: "border-box",
						textAlign: "center",
						border: "1px solid #E0E0E0",
						borderRadius: "4px",
						overflow: "hidden"
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%",
							width: "50%",
							backgroundColor:
								toggleMobileLogs && ColorScheme.tolotechBlue,
							color: toggleMobileLogs && "#fff",
							borderRadius: "4px",
							transform: "fadeIn",
							transition: "0.2s ease-in-out"
						}}
						onClick={() => setToggleMobileLogs(true)}
					>
						<Typography>{t("balances.balance_logs")}</Typography>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100%",
							width: "50%",
							backgroundColor:
								!toggleMobileLogs && ColorScheme.tolotechBlue,
							color: !toggleMobileLogs && "#fff",
							borderRadius: "4px",
							transform: "fadeIn",
							transition: "0.2s ease-in-out"
						}}
						onClick={() => setToggleMobileLogs(false)}
					>
						<Typography>{t("balances.balance_label")}</Typography>
					</div>
				</div>
			)}

			{isMobile && <div style={{ height: "40px" }} />}

			<div style={{ padding: isDesktop ? "20px" : 0, minHeight: "80px" }}>
				{showBalance || (isMobile && !toggleMobileLogs) ? (
					<UserBalanceSheet
						data={data}
						balance={userBalance}
						expires={balanceExpires}
						closeDialog={handleClose}
					/>
				) : null}
				{showLogs || (isMobile && toggleMobileLogs) ? (
					<UserBalanceLogs data={data} />
				) : null}
			</div>

			{/* <div>
				{data.balances
					.sort((a, b) => b.timestamp - a.timestamp)
					.map((order, i) => (
						<div
							key={order.id}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								padding: "20px",
								borderTop: i !== 0 && "1px solid #E0E0E0"
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center"
								}}
							>
								{getIcon(order.type)}
							</div>

							<Typography
								sx={{
									color: "black",
									width: "calc(100% / 4)"
								}}
							>
								{new Date(order.timestamp).toLocaleString()}
							</Typography>
							<Typography
								sx={{ color: "black", width: "calc(100% / 4)" }}
							>
								{order.description}
							</Typography>
							<Typography
								sx={{
									color: "black",
									width: "calc(100% / 4)",
									textAlign: "right"
								}}
							>
								{order.amount} e
							</Typography>
						</div>
					))}
			</div> */}
		</div>
	);
};
