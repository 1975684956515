import { Box, Dialog, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ApiContext } from "../ApiContext";
import { Spinner } from "../Common/Spinner";
import { ColorScheme } from "../../Themes/ColorScheme";

import { ClientOrderCard } from "./ClientOrderCard";
import { MuiDrawer } from "../Common/MuiDrawer";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

/* const styles = {
	inputContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		border: "0px",
		borderRadius: "4px",
		width: "100%",
		backgroundColor: "#F5F5F5",
		marginBottom: "20px",
		marginTop: "20px",
		alignSelf: "center",
		boxSizing: "border-box"
	}
}; */

export const ClientOrders = ({ user, dialogOpen, closeDialog, location }) => {
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const [loading, setLoading] = useState(false);

	const [orders, setOrders] = useState([]);

	const [selectedOrder, setSelectedOrder] = useState(null);

	const fetchOrderData = () => {
		setLoading(true);
		ApiCall(
			"GET",
			`client/get_orders/${location.location_id}?uid=${user.user_id} `
		)
			.then((res) => {
				setOrders(res);
				setLoading(false);
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	};

	const handleDrawerClose = () => {
		setSelectedOrder(null);
	};

	useEffect(() => {
		// console.log(dialogOpen);
		if (dialogOpen) {
			fetchOrderData();
		} else {
			setOrders([]);
		}
	}, [dialogOpen]);

	return (
		<div style={{ position: "relative" }}>
			<Dialog open={dialogOpen} onClose={() => closeDialog(null)}>
				<Box
					sx={{
						padding: "20px",
						minWidth: !isMobile ? "600px" : "300px",
						boxSizing: "border-box"
					}}
				>
					<Typography
						sx={{
							fontSize: "20px",
							color: "black",
							fontWeight: "700"
						}}
					>
						{user?.name}
					</Typography>
					<Typography sx={{ fontSize: "16px", color: "grey" }}>
						{user?.email}
					</Typography>
					<div style={{ height: "30px" }}></div>

					{loading && <Spinner></Spinner>}

					{orders.map((order, i) => (
						<ClientOrderCard
							key={i}
							closeDialog={closeDialog}
							setSelectedOrder={setSelectedOrder}
							data={order}
							user={user}
						/>
					))}
					<div style={{ height: "30px" }}></div>
					<Box
						sx={{
							flexDirection: "row",
							display: "flex",
							width: "100%",
							justifyContent: "space-between"
						}}
					>
						<Box
							onClick={() => closeDialog(null)}
							sx={{
								height: "40px",
								flex: 1,
								border: "1px solid " + ColorScheme.tolotechBlue,
								cursor: "pointer",
								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Typography
								sx={{ fontSize: "14px", color: "black" }}
							>
								{t("buttons.close")}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Dialog>
			<MuiDrawer
				open={selectedOrder !== null}
				handleDrawerClose={handleDrawerClose}
				contentType={"order"}
				data={selectedOrder}
			/>
		</div>
	);
};
