import React from "react";
import { isMobile } from "react-device-detect";

import { ReactComponent as CheckmarkIcon } from "../../Assets/Icons/Checkmark.svg";
import { ReactComponent as PendingIcon } from "../../Assets/Icons/Pending.svg";
import { ReactComponent as Ended } from "../../Assets/Icons/Ended.svg";
import { ReactComponent as EndingSoon } from "../../Assets/Icons/EndingSoon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

const howManyDays = 30; // set how many days before the end date the status should change to ending soon

function isWithinDays(date) {
	const today = new Date();
	const endDate = new Date(date);

	const difference = endDate.getTime() - today.getTime();
	const days = Math.ceil(difference / (1000 * 3600 * 24));

	return days <= howManyDays && days >= 0;
}

function isPast(date) {
	const today = new Date();
	const endDate = new Date(date);

	const difference = endDate.getTime() - today.getTime();
	const days = Math.ceil(difference / (1000 * 3600 * 24));

	/* console.log(today, endDate);
	console.log(days); */

	return days < 0;
}

function hasStarted(date) {
	const today = new Date();
	const startDate = new Date(date);

	const difference = startDate.getTime() - today.getTime();
	const days = Math.ceil(difference / (1000 * 3600 * 24));

	/* console.log(today, startDate);
	console.log(days); */
	return days <= 0;
}

export function getStatusProperty(status, property, restrictions) {
	const { t } = useTranslation();

	if (property === "main") {
		if (restrictions) return "#75A85D";
		else
			switch (status) {
				case 0:
					return "#75A85D";
				case 1:
					return "#ffbc70";
				case 2:
					return "#A85D5D";
				case 3:
					return "#516785";
				default:
					return "#516785";
			}
	} else if (property === "secondary") {
		if (restrictions) return "#DCE9D6";
		else
			switch (status) {
				case 0:
					return "#DCE9D6";
				case 1:
					return "#ffe9d1";
				case 2:
					return "#E9D6D6";
				case 3:
					return "#D4D9E0";
				default:
					return "#D4D9E0";
			}
	} else if (property === "icon") {
		if (restrictions)
			return (
				<CheckmarkIcon
					stroke={"#75A85D"}
					strokeWidth={2}
					width={"20px"}
					height={"20px"}
				/>
			);
		else
			switch (status) {
				case 0:
					return (
						<CheckmarkIcon
							stroke={"#75A85D"}
							strokeWidth={2}
							width={"20px"}
							height={"20px"}
						/>
					);
				case 1:
					return (
						<EndingSoon
							stroke={"#ffbc70"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
				case 2:
					return (
						<Ended
							stroke={"#A85D5D"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
				case 3:
					return (
						<PendingIcon
							stroke={"#516785"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
				default:
					return (
						<PendingIcon
							stroke={"#516785"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
			}
	} else if (property === "text") {
		if (restrictions) return t("key.ongoing");
		else
			switch (status) {
				case 0:
					return isMobile ? "" : t("key.ongoing");
				case 1:
					return isMobile ? "" : t("key.ends_soon");
				case 2:
					return isMobile ? "" : t("key.not_valid");
				case 3:
					return isMobile ? "" : t("key.pending");
				default:
					return isMobile ? "" : t("key.ongoing");
			}
		/* } else if (property === "door_users_granted") {
		if (status) {
			return "#DCE9D6";
		} else if (!status) {
			return "#A85D5D";
		} else return ColorScheme.lightGrey; */
	} else if (property === "door_users") {
		if (restrictions) return ColorScheme.bgGreen;
		/* if (status.granted) return "rgb(168, 93, 93)"; */ else if (
			status.granted &&
			status.access_ends_at &&
			isWithinDays(status.access_ends_at)
		) {
			return ColorScheme.endingSoon;
		} else if (isPast(status.access_ends_at)) {
			return "rgb(233, 214, 214)";
		} else if (hasStarted(status.access_starts_at)) {
			return ColorScheme.bgGreen;
		} else {
			return ColorScheme.pending;
		}
	} else if (property === "door_users_icon") {
		if (restrictions)
			return (
				<CheckmarkIcon
					stroke={ColorScheme.darkGreen}
					strokeWidth={2}
					width={"20px"}
					height={"20px"}
				/>
			);
		else if (status.access_ends_at && isWithinDays(status.access_ends_at)) {
			return (
				<EndingSoon
					stroke={ColorScheme.red}
					strokeWidth={2}
					width={"24px"}
					height={"24px"}
				/>
			);
		} else if (isPast(status.access_ends_at)) {
			return (
				<Ended
					stroke={"rgb(168, 93, 93)"}
					strokeWidth={2}
					width={"24px"}
					height={"24px"}
				/>
			);
		} else if (hasStarted(status.access_starts_at)) {
			return (
				<CheckmarkIcon
					stroke={ColorScheme.darkGreen}
					strokeWidth={2}
					width={"20px"}
					height={"20px"}
				/>
			);
		} else {
			return (
				<PendingIcon
					stroke={ColorScheme.pending_icon}
					strokeWidth={2}
					width={"24px"}
					height={"24px"}
				/>
			);
		}
	} else if (property === "door_users_text") {
		if (restrictions) return t("doors.access_right_valid");
		else if (status.access_ends_at && isWithinDays(status.access_ends_at)) {
			return t("doors.access_right_ending_soon");
		} else if (isPast(status.access_ends_at)) {
			return t("doors.access_right_has_ended");
		} else if (
			!isWithinDays(status.access_ends_at) &&
			!isPast(status.access_ends_at) &&
			hasStarted(status.access_starts_at)
		) {
			return t("doors.access_right_valid");
		} else {
			return t("doors.access_right_has_not_started");
		}
	} else if (property === "door_users_date") {
		if (restrictions) return null;
		else if (status.access_ends_at && isWithinDays(status.access_ends_at)) {
			const endDate = new Date(status.access_ends_at);
			const formattedEndDate = `${endDate.getDate()}.${
				endDate.getMonth() + 1
			}.${endDate.getFullYear()} ${String(endDate.getHours()).padStart(
				2,
				"0"
			)}:${String(endDate.getMinutes()).padStart(2, "0")}`;

			return "(" + formattedEndDate + ")";
		} else if (isPast(status.access_ends_at)) {
			const endDate = new Date(status.access_ends_at);
			const formattedEndDate = `${endDate.getDate()}.${
				endDate.getMonth() + 1
			}.${endDate.getFullYear()} ${String(endDate.getHours()).padStart(
				2,
				"0"
			)}:${String(endDate.getMinutes()).padStart(2, "0")}`;
			return "(" + formattedEndDate + ")";
		} else if (
			!isWithinDays(status.access_ends_at) &&
			!isPast(status.access_ends_at) &&
			hasStarted(status.access_starts_at)
		) {
			return null;
		} else {
			const startDate = new Date(status.access_starts_at);
			const formattedStartDate = `${startDate.getDate()}.${
				startDate.getMonth() + 1
			}.${startDate.getFullYear()} ${String(
				startDate.getHours()
			).padStart(2, "0")}:${String(startDate.getMinutes()).padStart(
				2,
				"0"
			)}`;

			return "(" + formattedStartDate + ")";
		}
	}
}
