import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
import { MapContent } from "../../../Common/MapContent";

import { ApiContext } from "../../../ApiContext";
import { UserContext } from "../../../UserContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { FormSave } from "./Components/FormSave";
import { FormCancel } from "./Components/FormCancel";
import { CustomizeButton } from "./Components/CustomizeButton";
import { FormEdit } from "./Components/FormEdit";

export const AddressDetail = ({ data, refreshData }) => {
	const debug = false;

	const [editMode, setEditMode] = useState(false);
	const [newCoordinates, setNewCoordinates] = useState([]);
	const [newAddress, setNewAddress] = useState("");
	const [findLocation, setFindLocation] = useState(false);

	const { selectedLocation } = useContext(UserContext);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const handleNewCoordinates = (props) => {
		setNewCoordinates(props);
	};

	const handleNewAddress = (props) => {
		setNewAddress(props);
	};

	const cancelAction = () => {
		setNewAddress(data.address);
		setNewCoordinates({
			lat: data.coordinates.lat.toString(),
			lon: data.coordinates.lon.toString()
		});
		setEditMode(!editMode);
	};

	const handleSave = () => {
		ApiCall("PUT", `location/update/${data.location_id}`, {
			address: newAddress,
			coordinates: {
				lat: newCoordinates.lat.toString(),
				lon: newCoordinates.lon.toString()
			}
		})
			.then(() => {
				setEditMode(!editMode);
				refreshData(data.location_id);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const resetData = () => {
		setNewAddress(data.address);
		if (
			data.coordinates.lat !== undefined &&
			data.coordinates.lon !== undefined
		)
			setNewCoordinates({
				lat: data.coordinates.lat.toString(),
				lon: data.coordinates.lon.toString()
			});
	};

	useEffect(() => {
		setEditMode(false);
		resetData();
	}, [selectedLocation]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				padding: "15px",
				py: "15px",
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px",
				borderRadius: "4px"
			}}
		>
			<div
				style={{
					flexDirection: /* isMobile ? "column" :  */ "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start",
					alignItems: editMode ? "baseline" : "start"
				}}
			>
				<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
					{/* width to make sure views look the same */}
					<Typography variant="form_text_label">
						{t("location.form_address_label")}:
					</Typography>
				</div>
				<Stack
					spacing={1}
					sx={{ width: /* isMobile ? "100%" : */ "100%" }}
				>
					{editMode ? (
						<>
							<Stack direction="row">
								<FormEdit
									value={newAddress}
									changeData={(text) => {
										if (findLocation)
											setFindLocation(false);
										setNewAddress(text);
									}}
									isRequired={true}
								/>
								<div style={{ flex: 1 }} />
								<Button
									disabled={newAddress === ""}
									onClick={() => setFindLocation(true)}
									sx={{ marginLeft: "10px" }}
								>
									{t("buttons.locate_address")}
								</Button>
							</Stack>
						</>
					) : (
						<Typography variant="form_text">
							{data.address}
						</Typography>
					)}

					<MapContent
						edit={editMode}
						locateAddress={findLocation && newAddress}
						newCoordinates={handleNewCoordinates}
						passAddressNameFromCoordinates={handleNewAddress}
					/>
					{editMode && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<FormSave
								check={newAddress === ""}
								handleSave={handleSave}
							/>
							<FormCancel cancelAction={cancelAction} />
						</Box>
					)}
				</Stack>
			</div>
			{!editMode && (
				<CustomizeButton
					changeEditMode={() => setEditMode(!editMode)}
				/>
			)}
		</Stack>
	);
};
