import React, { useContext, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { LocationForm } from "../Form/Location/LocationForm";
import { Spinner } from "../../Common/Spinner";
import { ApiContext } from "../../ApiContext";
import { useTranslation } from "react-i18next";
import LandingAnimation from "../../../Helpers/LandingAnimation";

export const LocationDetailsCard = () => {
	const debug = false;

	const [getLocationImages, setGetLocationImages] = useState([]);

	const location = useLocation();
	const navigate = useNavigate();
	const { handleTitle, selectedLocation } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const fetchLocationImages = () => {
		if (selectedLocation !== null && selectedLocation !== undefined)
			ApiCall(
				"GET",
				`location/get_images/${selectedLocation.location_id}`,
				null
			)
				.then((images) => {
					setGetLocationImages(images);

					/* setDebugging(true); */
				})
				.catch((error) => {
					if (debug) console.log(error);
				});
		else navigate("/");
	};

	useEffect(() => {
		if (
			Object.prototype.hasOwnProperty.call(
				selectedLocation,
				"location_id"
			) &&
			selectedLocation !== null &&
			selectedLocation !== undefined
		)
			fetchLocationImages();
	}, [selectedLocation, location]);

	useEffect(() => {
		handleTitle(t("common.locations"));
	}, []);

	return (
		<LandingAnimation>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center"
				}}
			>
				{selectedLocation !== null &&
				Object.prototype.hasOwnProperty.call(
					selectedLocation,
					"location_id"
				) &&
				getLocationImages !== null ? (
					<LocationForm
						locationData={selectedLocation}
						images={getLocationImages}
						fetchLocationImages={fetchLocationImages}
					/>
				) : (
					<Box
						sx={{
							height: "90vh",
							justifyContent: "center",
							alignItems: "center",
							display: "flex"
						}}
					>
						<Spinner />
					</Box>
				)}
			</Box>
		</LandingAnimation>
	);
};
