import { Collapse, Stack, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as ArrowDown } from "../../Assets/Icons/arrowDown.svg";
import { CampaignUser } from "./CampaignUser";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { format } from "date-fns";
import LandingAnimation from "../../Helpers/LandingAnimation";

// import PhoneIcon from "../../Assets/Icons/PhoneIcon.svg";

export const CampaignCard = ({
	data,
	triggerRefresh,
	openDrawer,
	passDrawerData
}) => {
	const { t } = useTranslation();
	const [checked, setChecked] = useState({}); // todo id identifier
	// const weekdays = ["Ma", "Ti", "Ke", "To", "Pe", "La", "Su"];
	const types = [
		t("campaign.types.other"),
		t("campaign.types.reservations"),
		t("campaign.types.products"),
		t("campaign.types.courses"),
		t("campaign.types.all")
	];

	const handleCollapse = (id) => {
		setChecked((prevState) => ({ ...prevState, [id]: !prevState[id] }));
	};

	const handleAdd = (event) => {
		event.stopPropagation();
		event.preventDefault();
		passDrawerData(data);
		openDrawer("AddUserCampaign");
	};

	const handleEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		openDrawer("campaignEdit");
		passDrawerData(data);
	};

	/* const returnWeekdayString = (num) => {
		let str = "";
		for (let i = 0; i < num.length; i++)
			if (i !== num.length - 1) str += weekdays[num[i]] + ", ";
			else str += weekdays[num[i]];
		return str;
	}; */

	const returnCampaignTypes = (num) => types[num - 1];

	return (
		<LandingAnimation>
			<Box
				onClick={() => handleCollapse()}
				style={{
					backgroundColor: checked[data.user_id]
						? "rgba(255, 255, 255, 0.95)"
						: "rgba(255, 255, 255, 1)",
					padding: 20,
					borderRadius: 4,
					// marginBottom: 8,
					cursor: "pointer"
				}}
			>
				<Stack>
					{isMobile ? (
						<Stack>
							<Stack
								direction="row"
								justifyContent="space-between"
							>
								<Stack sx={{ minWidth: "50%" }}>
									<Typography
										sx={{
											fontSize: "16px",
											fontWeight: "700",
											color: "rgb(32, 32, 32)"
										}}
									>
										{data.name}
									</Typography>
									<Typography
										sx={{
											color: "rgb(32, 32, 32)",
											fontSize: "12px"
										}}
									>
										{returnCampaignTypes(
											data.discount_type
										)}
									</Typography>
									{data.code.length > 0 && (
										<Typography
											sx={{
												color: "white",
												fontSize: "12px",
												backgroundColor:
													ColorScheme.tolotechLightBlue,
												borderRadius: "4px",
												padding: "4px",
												textAlign: "center"
											}}
										>
											{data.code}
										</Typography>
									)}
								</Stack>

								<Stack textAlign="end">
									<Typography
										sx={{
											color: "rgb(32, 32, 32)",
											fontSize: "12px"
										}}
									>
										{format(
											new Date(data.valid_from),
											"dd.MM.yyyy HH:mm"
										)}
									</Typography>

									<Typography
										sx={{
											color: "rgb(32, 32, 32)",
											fontSize: "12px"
										}}
									>
										{format(
											new Date(data.valid_to),
											"dd.MM.yyyy HH:mm"
										)}
									</Typography>
								</Stack>
							</Stack>

							<Typography color="black" marginTop="10px">
								{data.description}
							</Typography>
						</Stack>
					) : (
						<Stack
							direction="row"
							alignItems="center"
							color={"black"}
						>
							<Stack sx={{ flex: 2 }}>
								<Typography
									sx={{
										fontSize: "16px",
										fontWeight: "700",
										color: "rgb(32, 32, 32)"
									}}
								>
									{data.name}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 3.8 }}>
								<Typography
									sx={{
										color: "rgb(32, 32, 32)",
										fontSize: "12px"
									}}
								>
									{data.description}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 1 }}>
								<Typography
									sx={{
										color: "rgb(32, 32, 32)",
										fontSize: "12px"
									}}
								>
									{format(
										new Date(data.valid_from),
										"dd.MM.yyyy HH:mm"
									)}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 1 }}>
								<Typography
									sx={{
										color: "rgb(32, 32, 32)",
										fontSize: "12px"
									}}
								>
									{format(
										new Date(data.valid_to),
										"dd.MM.yyyy HH:mm"
									)}
								</Typography>
							</Stack>
							{/* <Stack sx={{ flex: 1 }}>
							<Typography
								sx={{
									color: "rgb(32, 32, 32)",
									fontSize: "12px",
									paddingRight: "20px"
								}}
							>
								returnWeekdayString(data.weekdays) 
							</Typography>
						</Stack> */}
							<Stack sx={{ flex: 1 }}>
								<Typography
									sx={{
										color: "rgb(32, 32, 32)",
										fontSize: "12px"
									}}
								>
									{returnCampaignTypes(data.discount_type)}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 1.5 }}>
								<Typography
									sx={{
										color: "rgb(32, 32, 32)",
										fontSize: "12px"
									}}
								>
									{data.usage_count}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 1.2 }}>
								<Typography
									sx={{
										color: "rgb(32, 32, 32)",
										fontSize: "12px"
									}}
								>
									{data.code}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 0.5 }}>
								<div
									style={{
										backgroundColor:
											new Date() >
												new Date(data.valid_to) ||
											new Date() <
												new Date(data.valid_from)
												? "gray"
												: "green",
										height: 12,
										width: 12,
										borderRadius: 99
									}}
								/>
							</Stack>
							<Stack sx={{ flex: 0.1 }}>
								<div
									style={{
										display: "inline-block", // prevents icon from moving when rotating
										transform: checked[data.user_id]
											? "rotate(180deg)"
											: "rotate(0deg)",
										transition: "transform 0.3s ease-in-out"
									}}
								>
									<ArrowDown
										stroke="black"
										height="12px"
										width="12px"
									/>
								</div>
							</Stack>
						</Stack>
					)}
				</Stack>

				<Collapse in={checked[data.user_id]}>
					<div
						style={{
							height: "1px",
							width: "100%",
							backgroundColor: "lightgray",
							marginTop: "20px"
						}}
					/>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						style={{
							width: "100%",
							marginTop: "20px",
							marginBottom: "20px"
						}}
					>
						<Box
							sx={{
								padding: "10px",
								backgroundColor: ColorScheme.tolotechBlue,
								borderRadius: "4px"
							}}
							onClick={(event) => handleEdit(event)}
						>
							<Typography>
								{t("campaign.edit_campaign_label")}
							</Typography>
						</Box>

						<Box
							sx={{
								padding: "10px",
								backgroundColor: ColorScheme.tolotechBlue,
								borderRadius: "4px",
								marginLeft: "25%"
							}}
							onClick={(e) => handleAdd(e)}
						>
							<Typography>{t("buttons.add_users")}</Typography>
						</Box>
					</Stack>

					{data.campaign_users.length > 0 && (
						<Stack
							sx={{
								flexDirection: "row",
								display: "flex",
								paddingX: "30px",
								paddingY: "10px",
								alignItems: "center"
							}}
						>
							<Stack sx={{ flex: 1 }}>
								<Typography
									sx={{
										fontSize: "10px",
										color: "rgba(0,0,0,0.9)",
										fontWeight: "700"
									}}
								>
									{t("campaign.name_label_short")}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 3 }}>
								<Typography
									sx={{
										fontSize: "10px",
										color: "rgba(0,0,0,0.9)",
										fontWeight: "700"
									}}
								>
									{t("campaign.user_email_label")}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 1.2 }}>
								<Typography
									sx={{
										fontSize: "10px",
										color: "rgba(0,0,0,0.9)",
										fontWeight: "700"
									}}
								>
									{t("campaign.campaign_uses_left_label")}
								</Typography>
							</Stack>
							<Stack sx={{ flex: 0.2 }}>
								<Typography
									sx={{
										fontSize: "10px",
										color: "rgba(0,0,0,0.9)",
										fontWeight: "700"
									}}
								>
									{t("campaign.campaign_delete_label")}
								</Typography>
							</Stack>
						</Stack>
					)}

					{data.campaign_users.map((user, index) => (
						<CampaignUser
							codeLimit={data.user_limit}
							key={user.user_id}
							data={user}
							index={index}
							handleRefresh={triggerRefresh}
						/>
					))}
				</Collapse>
			</Box>
		</LandingAnimation>
	);
};
