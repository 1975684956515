import { Button, Stack, Collapse } from "@mui/material";

import React, { useEffect, useState, useContext } from "react";

import { UserContext } from "../UserContext";

import { DOOR_TYPES } from "./DOOR_TYPES";

import { ApiContext } from "../ApiContext";
/* import { DoorUsage } from "./DoorUsage"; */
import { useTranslation } from "react-i18next";
import { Spinner } from "../Common/Spinner";

import { DoorUsageNew } from "./DoorUsageNew";
import { TextFieldInput2 } from "../Common/TextFieldInput2";
import { TextFieldSelect } from "../Common/TextFieldSelect";

import { ReactComponent as DoorNameIcon } from "../../Assets/Icons/DoorIcon_custom_stroke.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { AlertBar } from "../Common/AlertBar";

export const AddNewDoor = ({ content, handleDrawerClose }) => {
	const debug = false;

	const { selectedLocation } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const doorTypes = DOOR_TYPES();

	/* const dataUrl =
		content !== null &&
		content !== undefined &&
		useQRCode("tologo://request/" + content.code); */

	// console.log(content);

	const [doorName, setDoorName] = useState(content?.door_name || "");
	const [secondsOpen, setSecondsOpen] = useState(content?.seconds_open || "");
	const [selectedType, setSelectedType] = useState(content?.door_type || 1);
	const [openingHours, setOpeningHours] = useState(content?.opening_hours);
	const [showLoader, setShowLoader] = useState(false);
	const [error, setError] = useState("");

	const handleSave = () => {
		setShowLoader(true);

		if (content !== undefined)
			ApiCall("PUT", "door/update/" + content.door_id, {
				door_name: doorName,
				seconds_open: Number(secondsOpen),
				door_type: String(selectedType),
				opening_hours: openingHours
			})
				.then(() => {
					setError("");
					setShowLoader(false);
					handleDrawerClose();
				})
				.catch((error) => {
					setError(error);
					setShowLoader(false);
					if (debug) console.log(error);
				});
		else
			ApiCall(
				"POST",
				"location/add_door/" + selectedLocation.location_id,
				{
					door_name: doorName,
					seconds_open: Number(secondsOpen),
					door_type: String(selectedType)
					/* opening_hours: mapWeekdays */
				}
			)
				.then(() => {
					setError("");
					setShowLoader(false);
					handleDrawerClose();
				})
				.catch((error) => {
					setError(error);
					setShowLoader(false);
					if (debug) console.log(error);
				});
	};

	const handleSelectChange = (value) => {
		setSelectedType(value);
	};

	useEffect(() => {
		setError("");

		if (content !== undefined) {
			setDoorName(content.door_name);
			setSelectedType(content.door_type);
			setSecondsOpen(content.seconds_open);
			if (content.opening_hours !== undefined) {
				setOpeningHours(content.opening_hours);
			} else {
				setOpeningHours([]);
			}
		}
	}, []);

	// console.log(selectedType);

	return (
		<div
			style={{
				display: "flex",
				/* height: "100%", */ // removing this fixes overlapping elements issue
				flexDirection: "column",
				justifyContent: "space-between",
				gap: "20px"

				/* alignItems: "center" */
			}}
		>
			{/* {content !== undefined && content.code !== "" && (
				<Box
					sx={{
						backgroundColor: "white",
						marginBottom: "50px",
						padding: "20px",
						borderRadius: "4px",
						alignSelf: "center",
						mx: "auto",
						border: "5px solid #264166"
					}}
				>
					<Avatar
						sx={{
							borderRadius: "4px",
							height: "100px",
							width: "100px"
						}}
						src={dataUrl}
					/>
				</Box>
			)} */}

			{/* <DrawerTitle
				title={t("doors.edit_door_data")}
				location={selectedLocation.name}
			/> */}

			<TextFieldInput2
				title={t("doors.door_name")}
				fullWidth
				text={doorName}
				valueChange={setDoorName}
				hasProps={
					<DoorNameIcon stroke={ColorScheme.defaultIconColor} />
				}
			/>

			<Collapse in={true} collapsedSize={0}>
				<AlertBar
					type={"info"}
					standardVariant={true}
					textContent={t("doors.door_settings_info")}
					whiteSpacePreLine
					showAlert={true}
				/>
			</Collapse>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "stretch",
					width: "100%",
					gap: "20px",
					boxSizing: "border-box"
				}}
			>
				{/* <div style={{ width: "50%" }}>
					<TextFieldInput2
						title={t("doors.time_door_stay_open")}
						hasProps={
							<DoorOpenIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
						type="number"
						text={secondsOpen}
						valueChange={setSecondsOpen}
					/>
				</div> */}

				<div style={{ width: "100%" }}>
					<TextFieldSelect
						label={t("doors.door_types")}
						selected={selectedType}
						valueChange={handleSelectChange}
						options={doorTypes}
						hasProps={
							<DoorNameIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>
				</div>
			</div>

			<div>
				<Stack>
					<DoorUsageNew
						days={openingHours}
						setWeekdays={setOpeningHours}
					/>
				</Stack>
			</div>

			<Collapse in={error.length > 0} collapsedSize={0}>
				<AlertBar
					type={"error"}
					standardVariant={true}
					textContent={error}
					showAlert={true}
				/>
			</Collapse>

			<div style={{ flex: 1 }} />

			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					paddingBottom: "20px"
				}}
			>
				<Button
					sx={{
						border: "1px solid #1C568F",
						height: "46px",
						width: "50%"
					}}
					/* fullWidth*/
					variant="testings"
					onClick={() => handleDrawerClose()}
				>
					{t("buttons.cancel")}
				</Button>
				<Button
					sx={{ width: "50%" }}
					/* fullWidth*/
					variant="contained"
					onClick={() => handleSave()}
				>
					{showLoader ? (
						<Spinner hasText={t("spinner_text.wait")} />
					) : (
						t("buttons.save")
					)}
				</Button>
			</div>
		</div>
	);
};
