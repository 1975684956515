import { Button, Stack, Box } from "@mui/material";
import React, { useContext } from "react";
import { ApiContext } from "../../ApiContext";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";
import TooltipWrapper from "../../Common/TooltipWrapper";

export const HideLocation = ({ locationId, current, setHidden }) => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const handleHideLocation = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setHidden(!current);

		ApiCall("PUT", `location/update/${locationId}`, {
			is_hidden: !current
		});
	};

	return (
		<Stack direction="row" spacing={2}>
			<TooltipWrapper
				title={
					current
						? t("location.show_location_tooltip")
						: t("location.hide_location_tooltip")
				}
			>
				<Button
					onClick={(e) => handleHideLocation(e)}
					sx={{ height: "30px" }}
				>
					<Box
						sx={{
							backgroundColor: current
								? ColorScheme.red
								: ColorScheme.green,
							height: "15px",
							width: "15px",
							borderRadius: "30px"
						}}
					/>
				</Button>
			</TooltipWrapper>
			{/* <FormGroup>
				<FormControlLabel
					control={
						<Switch
							checked={checked}
							onClick={(event) => handleHideLocation(event)}
						/>
					}
				/>
			</FormGroup>*/}
		</Stack>
	);
};
