import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./Styles/OnOffSlider.css";
import { useTranslation } from "react-i18next";

const OnOffSlider = ({ status, action, textEnabled }) => {
	const { t } = useTranslation();

	const [showText, setShowText] = useState(false);
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	useEffect(() => {
		if (showText) {
			const timer = setTimeout(() => {
				setShowText(false);
			}, 3000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [showText]);

	useEffect(() => {
		if (isFirstLoad) {
			setIsFirstLoad(false);
			return;
		}

		if (!isFirstLoad) setShowText(true);
	}, [action]);

	return (
		<div
			className="on-off-switch"
			style={{ alignItems: "center", justifyContent: "center" }}
		>
			<div className="lower-box">
				<label className="switch">
					<input
						type="checkbox"
						checked={status}
						onChange={(e) => action(e)}
					/>
					<span className="slider" />
				</label>
			</div>

			<div style={{ height: "5px" }} />

			{textEnabled && (
				<div className="text-box">
					<CSSTransition
						in={showText}
						timeout={300}
						classNames="fade"
						unmountOnExit
						onExited={() => setShowText(false)}
					>
						<Typography
							className="fade-in-out"
							color="gray"
							lineHeight="14px"
						>
							{status
								? t("charging.available")
								: t("charging.offline")}
						</Typography>
					</CSSTransition>
				</div>
			)}
		</div>
	);
};

export default OnOffSlider;
