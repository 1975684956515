import { Box, Typography } from "@mui/material";
import React from "react";

export const Event = ({ props }) => (
	<Box
		sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			backgroundColor: "transparent",
			zIndex: 0
		}}
	>
		<Typography
			style={{
				color: "white",
				fontSize: "12px",
				fontWeight: "100"
			}}
		>
			{props.title}
		</Typography>
	</Box>
);
