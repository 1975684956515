import React, { useContext, useEffect, useState } from "react";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { Box, Typography } from "@mui/material";
import { ApiContext } from "../../ApiContext";
import { Spinner } from "../../Common/Spinner";
import moment from "moment";

import { SnackContext } from "../../SnackContext";
import { AlertBar } from "../../Common/AlertBar";
/* import Papa from "papaparse"; */
import ExampleFile from "../../../Assets/Other/esimerkki.csv";
import ExampleExcel from "../../../Assets/Other/esimerkki.xlsx";
import { read, utils } from "xlsx";
import { useTranslation } from "react-i18next";

const allowedExtensions = ["csv", "xls", "xlsx", "numbers"];

export const KeyManagementMassAddition = ({ data, handleRefresh }) => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const { showSnackMessage } = useContext(SnackContext);

	const [rows, setRows] = useState([]);
	const [invalidRows, setInvalidRows] = useState([]);
	const [file, setFile] = useState("");
	const [parsing, setParsing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [invalidFileType, setInvalidFileType] = useState(false);

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const validatePhone = (phone) => {
		const re = /^(\+?\d{1,3}[- ]?)?\d{6,12}$/;
		return re.test(phone);
	};

	const validateLicensePlate = (licensePlate) => {
		const re = /^[A-Z]{1,3}[0-9]{1,3}$/;
		return re.test(licensePlate);
	};

	const parseDate = (date) => {
		const formats = [
			"YYYY-MM-DD",
			"DD/MM/YYYY",
			"YYYY/MM/DD",
			"DD-MM-YYYY",
			"DD.MM.YYYY"
		];

		for (let i = 0; i < formats.length; i++) {
			const parsedDate = moment(date, formats[i], true);
			if (parsedDate.isValid()) {
				return parsedDate.toISOString();
			}
		}

		return null;
	};

	function validateRow(row) {
		// console.log(row.email);
		// Some identifier must be provided
		if (
			row.email === undefined &&
			row.phone === undefined &&
			row.license_plate === undefined
		) {
			return false;
		}
		// console.log(row.email);
		// Email is present but not valid
		if (row.email !== undefined && !validateEmail(row.email)) {
			return false;
		}
		// Phone is present but not valid
		if (row.phone !== undefined && !validatePhone(row.phone)) {
			return false;
		}
		// License plate is present but not valid
		if (
			row.license_plate !== undefined &&
			!validateLicensePlate(row.license_plate)
		) {
			return false;
		}
		// From date is present but not valid
		if (row.from !== undefined && parseDate(row.from) === undefined) {
			return false;
		}
		// To date is present but not valid
		if (row.to !== undefined && parseDate(row.to) === undefined) {
			return false;
		}

		return true;
	}

	const handleImport = () => {
		let reader = new FileReader();
		reader.onload = function () {
			let text = reader.result;
			// let node = document.getElementById('output');
			// node.innerText = text;
			// console.log(text);
			let workbook = read(text, { type: "buffer", raw: true });

			let sheet_name_list = workbook.SheetNames;
			let xlData = utils.sheet_to_json(
				workbook.Sheets[sheet_name_list[0]]
			);

			// console.log(xlData);

			let temp = [];
			let tempInvalid = [];
			for (let i = 0; i < xlData.length; i++) {
				let row = xlData[i];
				if (validateRow(row)) {
					row.from = row.from === "" ? null : parseDate(row.from);
					row.to = row.to === "" ? null : parseDate(row.to);
					row.reservable_id = data.id;

					// in case of numeric description, convert to string
					row.description = "" + row.description;

					temp.push(row);
				} else {
					tempInvalid.push(row);
				}
			}
			setRows(temp);
			setInvalidRows(tempInvalid);
			setParsing(false);
		};

		reader.readAsArrayBuffer(file);
	};

	/* const handleParse = async () => {
		if (file === "") return;

		Papa.parse(file, {
			delimiter: ";",
			header: true,
			skipEmptyLines: true,
			complete(results) {
				let temp = [];
				let tempInvalid = [];
				for (let i = 0; i < results.data.length; i++) {
					if (validateRow(results.data[i])) {
						results.data[i].from =
							results.data[i].from === ""
								? null
								: parseDate(results.data[i].from);
						results.data[i].to =
							results.data[i].to === ""
								? null
								: parseDate(results.data[i].to);
						results.data[i].reservable_id = data.id;
						temp.push(results.data[i]);
					} else {
						tempInvalid.push(results.data[i]);
					}
				}
				setRows(temp);
				setInvalidRows(tempInvalid);
				setParsing(false);
			}
		});
	}; */

	useEffect(() => {
		if (file !== "") {
			handleImport();
		}
	}, [file]);

	const handleFileChange = (e) => {
		setRows([]);
		setInvalidRows([]);

		setParsing(true);

		// Check if user has entered the file
		if (e.target.files.length) {
			const inputFile = e.target.files[0];

			// Check the file extensions, if it not
			// included in the allowed extensions
			// we show the error
			const splittedFile = inputFile?.name.split(".");
			const fileExtension = splittedFile[splittedFile.length - 1];
			// console.log(fileExtension);
			// console.log(inputFile);
			if (!allowedExtensions.includes(fileExtension)) {
				// console.log(fileExtension);
				setInvalidFileType(true);
				// setError("Please input a csv file");
				return;
			} else {
				setInvalidFileType(false);
			}
			// If input type is correct set the state
			setFile(inputFile);
		}
	};

	function getInvalidRowsText(rows) {
		let text = t("key.invalid_rows") + ": " + rows.length + "\n";

		for (let i = 0; i < rows.length; i++) {
			text = text + "\n" + rows[i].name + ". ";
		}
		return text;
	}

	const handleSave = () => {
		setLoading(true);
		ApiCall("POST", "client/residents", rows)
			.then(() => {
				handleRefresh();
				showSnackMessage("general", 200);
				setLoading(false);
				setRows([]);
				setFile("");
				setInvalidRows([]);
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		setRows([]);
		setInvalidRows([]);
		setFile("");
	}, [data]);

	return (
		<>
			<div
				style={{
					width: "100%",
					backgroundColor: "white",
					padding: "20px",
					boxSizing: "border-box"
				}}
			>
				<Typography sx={{ color: "black", fontWeight: "700" }}>
					{t("key.add_from_csv")}
				</Typography>
				<Box sx={{ flexDirection: "row", display: "flex" }}>
					<Typography
						sx={{
							color: ColorScheme.tolotechDarkBlue,
							fontSize: "14px",
							fontWeight: "400"
						}}
					>
						{t("key.download_example_file")}
					</Typography>
					<div style={{ width: "5px" }}></div>
					<a href={ExampleFile} target="_blank" rel="noreferrer">
						<Typography sx={{ fontSize: "14px" }}>
							{"csv"}
						</Typography>
					</a>
					{
						<Typography
							sx={{
								color: ColorScheme.tolotechDarkBlue,
								fontSize: "14px",
								fontWeight: "400"
							}}
						>
							{"/"}
						</Typography>
					}
					<a href={ExampleExcel} target="_blank" rel="noreferrer">
						<Typography sx={{ fontSize: "14px" }}>
							{"excel"}
						</Typography>
					</a>
				</Box>
				<div style={{ height: "10px" }}></div>
				{invalidFileType && (
					<AlertBar
						textContent={t("key.selected_file_not_csv")}
						type="error"
						showAlert={true}
						standardVariant={"standard"}
					></AlertBar>
				)}

				{file === "" && (
					<>
						<label
							htmlFor="csvInput"
							style={{ display: "block", width: "100%" }}
						>
							<Box
								sx={{
									width: "100%",
									height: "50px",
									backgroundColor: ColorScheme.tolotechBlue,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "4px",
									marginTop: "20px",
									cursor: "pointer"
								}}
							>
								<Typography sx={{ fontSize: "15px" }}>
									{t("key.upload_csv")}
								</Typography>
							</Box>
						</label>
						<input
							onChange={handleFileChange}
							id="csvInput"
							name="file"
							type="file"
							style={{
								display: "none"
							}}
						/>
					</>
				)}
				{file !== "" && (
					<>
						<Typography
							sx={{
								color: "black",
								fontWeight: "700",
								marginTop: "10px",
								fontSize: "14px"
							}}
						>
							{file.name}
						</Typography>
						<div style={{ height: "10px" }}></div>
						{parsing && (
							<Spinner
								small
								color={ColorScheme.tolotechBlue}
							></Spinner>
						)}
						{rows.length > 0 && (
							<AlertBar
								textContent={
									t("key.valid_rows") + ": " + rows.length
								}
								type="info"
								showAlert={true}
								standardVariant={"standard"}
							></AlertBar>
						)}
						{invalidRows.length > 0 && rows.length > 0 && (
							<AlertBar
								whiteSpacePreLine={true}
								textContent={getInvalidRowsText(invalidRows)}
								type="warning"
								showAlert={true}
								standardVariant={"standard"}
							></AlertBar>
						)}
						{rows.length === 0 && (
							<AlertBar
								textContent={t("key.no_valid_rows_in_file")}
								type="error"
								showAlert={true}
								standardVariant={"standard"}
							></AlertBar>
						)}
						{rows.length + data.user_count > data.user_limit && (
							<AlertBar
								textContent={t("key.no_space_for_all_users")}
								type="error"
								showAlert={true}
								standardVariant={"standard"}
							></AlertBar>
						)}
						<div
							style={{
								flexDirection: "row",
								display: "flex",
								justifyContent: "space-between"
							}}
						>
							<Box
								onClick={() => {
									setFile("");
									setRows([]);
									setInvalidRows([]);
								}}
								sx={{
									width: "100%",
									height: "50px",
									border:
										"1px solid " + ColorScheme.tolotechBlue,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "4px",
									marginTop: "20px",
									cursor: "pointer"
								}}
							>
								<Typography
									sx={{ fontSize: "15px", color: "black" }}
								>
									{t("buttons.cancel")}
								</Typography>
							</Box>
							<div style={{ width: "20px" }}></div>
							<Box
								onClick={() => {
									if (
										rows.length > 0 &&
										rows.length + data.user_count <=
											data.user_limit
									) {
										handleSave();
									}
								}}
								sx={{
									width: "100%",
									height: "50px",
									backgroundColor: ColorScheme.tolotechBlue,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderRadius: "4px",
									marginTop: "20px",
									cursor:
										rows.length > 0 &&
										rows.length + data.user_count <=
											data.user_limit &&
										"pointer",
									opacity:
										rows.length > 0 &&
										rows.length + data.user_count <=
											data.user_limit
											? 1
											: 0.5
								}}
							>
								{loading ? (
									<Spinner small color={"white"}></Spinner>
								) : (
									<Typography sx={{ fontSize: "15px" }}>
										{t("key.add_users")}
									</Typography>
								)}
							</Box>
						</div>
					</>
				)}
			</div>
		</>
	);
};
