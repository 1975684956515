/*

* Common permissions *
1 - Access (Doors)
2 - Reservation (Calendar, campaign and courses)
7 - Charging
8 - Resident (Long term "calendar" view)
10 - Balance

* Rest (there isn't any need to check these in webapp yet) *
3 - Device
4 - API
5 - Internal API
6 - No log

*/

import { useNavigate } from "react-router-dom";

export const permissionChecker = ({ selectedLocation, permission }) => {
	const navigate = useNavigate();

	// Check if billing data is present
	const isBillingDataPresent = !!(
		(
			selectedLocation?.billing?.business_id &&
			selectedLocation?.billing?.customer_name &&
			selectedLocation?.billing?.contact_email &&
			selectedLocation?.billing?.contact_name &&
			selectedLocation?.billing?.invoice_address &&
			selectedLocation?.billing?.invoice_operator
		) /* &&
		selectedLocation?.billing?.invoice_reference */
	);

	// If billing data is not present, navigate user to location detail page

	if (!isBillingDataPresent) {
		if (Object.keys(selectedLocation).length === 0) navigate("/locations");
		// since page reload clears all states, there is nothing to check at first render, so we will redirect user to location page to avoid undefined url paths etc.
		else navigate(`/locations/${selectedLocation.location_id}`); // This will override default redirect location

		return true; // Set the flag to prevent multiple navigations, returns true to parent which won't trigger default redirect
	}

	/* Not needed anymore
	
	
	// Check for permission_id 8 from selected locations permissions
	const hasPermissionId8 = selectedLocation?.location_permissions?.some(
		(obj) => obj.permission_id === 8
	);

	// If permission_id 8 is found, return true for it and false for other permissions
	if (hasPermissionId8)
		if (permission === 8) {
			return true;
		} else if (permission === 1) {
			// Unless permission 1 is found with permission 8
			const hasPermissionId1 =
				selectedLocation?.location_permissions?.some(
					(obj) => obj.permission_id === 1
				);
			return hasPermissionId1;
		} else {
			return false;
		} */

	// For all other permissions, return true or false based on whether the permission is found
	const hasPermission =
		selectedLocation?.location_permissions?.some(
			(obj) => obj.permission_id === permission
		) ?? false;

	return hasPermission;
};
