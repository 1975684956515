import { Button, Dialog, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CourseMeet } from "./CourseMeet";
import { ColorScheme } from "../../Themes/ColorScheme";
import { CreateMeet } from "./CreateMeet";

export const CourseMeetView = ({ oldData, passNewData }) => {
	const { t } = useTranslation();
	const [courseMeets, setCourseMeets] = useState([]);
	const [openDialog, setOpenDialog] = useState(false);
	/* const [drawerPath] = useState("courseMeet"); */

	/* const addMeet = () => {
		if (courseMeets !== null)
			setCourseMeets((courseMeets) => [...courseMeets, meetBody]);
		else setCourseMeets([meetBody]);
	}; */

	const addMeet = () => {
		setOpenDialog(true);
	};

	const handleNewMeet = (props) => {
		// Check if courseMeets is undefined or null
		if (courseMeets !== null && courseMeets !== undefined) {
			setCourseMeets((prevCourseMeets) => [
				...prevCourseMeets,
				{
					from_time: new Date(props.from_time).toISOString(),
					to_time: new Date(props.to_time).toISOString()
				}
			]);
		} else {
			// Initialize courseMeets with a new array containing the new meet data
			setCourseMeets([
				{
					from_time: new Date(props.from_time).toISOString(),
					to_time: new Date(props.to_time).toISOString()
				}
			]);
		}
	};

	const handleRemoveMeet = (id) => {
		const remove = courseMeets.filter((entry) => entry.id !== id);
		setCourseMeets(remove);
	};

	/* const handleNewData = (props) => {
		const findMeeting = courseMeets.map((entry) => ({
			...entry,
			from_time: props.from_time,
			to_time: props.to_time
		}));
		setCourseMeets(findMeeting);
	}; */

	const courseDates = courseMeets.map((event, index) => (
		<CourseMeet
			key={event.id}
			data={event}
			removeMeet={handleRemoveMeet}
			index={index}
		/>
	));

	useEffect(() => {
		passNewData(courseMeets);
	}, [courseMeets]);

	useEffect(() => {
		if (oldData.length > 0) setCourseMeets(oldData);
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<CreateMeet
					passNewCourseMeetBody={handleNewMeet}
					handleDialogClose={() => setOpenDialog(false)}
				/>
			</Dialog>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center"
				}}
			>
				<Typography fontWeight={600} fontSize={18}>
					Kurssiaikataulut
				</Typography>
				<Button
					variant="smallAccept"
					onClick={addMeet}
					sx={{ backgroundColor: ColorScheme.tolotechBlue }}
				>
					{t("buttons.add_new_course_time")}
				</Button>
			</div>
			<Stack
				sx={{
					width: "100%",
					border:
						courseDates.length === 0
							? "none"
							: "1px solid" + ColorScheme.textFieldBorder,
					borderRadius: "4px"
				}}
			>
				{courseDates}
			</Stack>
		</div>
	);
};
