import React, { useContext, useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { ApiContext } from "../ApiContext";
import { UserContext } from "../UserContext";
import { ColorScheme } from "../../Themes/ColorScheme";
import { CSSTransition } from "react-transition-group";
import "./calendar.css";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";

const Door = ({ door, index }) => {

	const { t } = useTranslation();

	const [show, setShow] = useState(false);
	const [copied, setCopied] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, index * 150);
	}, []);

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	return (
		<CSSTransition key={index}
			in={show}
			timeout={400}
			classNames="slide-from-bottom"
			unmountOnExit>
			<Tooltip title={!copied ? t("buttons.click_to_copy_phone") : t("buttons.copied")} placement="top">
				<Box onClick={() => {
					navigator.clipboard.writeText(door.doorphone || "");
					setCopied(true);
				}} style={{
					backgroundColor: ColorScheme.tolotechBlue,
					paddingLeft: 20,
					paddingRight: 20,
					paddingTop: 5,
					paddingBottom: 5,
					borderRadius: 100,
					marginRight: 10,
					cursor: "pointer",
					display: "inline-block"
				}}>
					<Typography style={{ fontSize: 12, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
						{door.door_name}
					</Typography>
					<Typography style={{ fontSize: 10, marginTop: -5, color: "rgba(255,255,255,0.7)", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
						{door.doorphone ? door.doorphone : "-"}
					</Typography>
				</Box>
			</Tooltip>
		</CSSTransition>
	);
};


export const DoorList = ({ selectedService }) => {
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);

	const { t } = useTranslation();

	const [doors, setDoors] = useState([]);

	const [parsedDoors, setParsedDoors] = useState([]);

	useEffect(() => {
		ApiCall("GET", `client/get_location_doors/${selectedLocation.location_id}`, null)
			.then(doors => {
				setDoors(doors);
			});
	}, []);

	useEffect(() => {
		setParsedDoors([]);
		if (selectedService == null) {
			return;
		}

		const commonDoors = doors.filter(door1 =>
			selectedService.service_doors.some(door2 => door1.door_id === door2.door_id)
		);

		setParsedDoors(commonDoors);
	}, [doors, selectedService]);

	return (
		<>
			<div
				className="list-fields-container"
				style={{
					display: "flex",
					flexDirection: "column",
					overflow: "visible",
					height: 100,
					alignItems: "flex-start"
				}}
			>
				<Typography style={{ fontSize: 14, color: "rgba(255,255,255,0.8)", marginBottom: 10 }}>
					{t("buttons.door_access_rights")}
				</Typography>
				<div style={{
					display: "flex",
					flexDirection: "row",
					overflow: "visible",
					alignItems: "flex-start"
				}}>
				{parsedDoors.map((door, index) => (
					<Door key={uuid()} door={door} index={index} />
				))}
				</div>
			</div>
		</>
	);
};
