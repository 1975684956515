import React from "react";
import { Box, Typography } from "@mui/material";

import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const ReservationCard = ({ content, roundedNumber, first, last }) => {
	const { t } = useTranslation();

	const checkIfSameDate = () => {
		const startDate = new Date(content.start);
		const endDate = new Date(content.end);

		const formatDate = (date) =>
			`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

		if (startDate.toDateString() === endDate.toDateString()) {
			return formatDate(startDate);
		} else {
			return `${formatDate(startDate)} - ${formatDate(endDate)}`;
		}
	};

	const noSeconds = (value) => {
		const convert = new Date(value).toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit"
		});

		return convert;
	};

	return (
		<Box
			sx={{
				maxWidth: "100%",
				flexDirection: "row",
				py: "20px",
				px: "40px",
				justifyContent: "space-between",
				display: "flex",
				border: "1px solid" + ColorScheme.lightGrey,
				borderTop: !first && "none",
				borderRadius: first
					? "4px 4px 0px 0px"
					: last
						? "0px 0px 4px 4px"
						: "0px"
			}}
		>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between"
				}}
			>
				<Typography sx={{ fontSize: "14px" }}>
					{checkIfSameDate()}
				</Typography>
				<Typography sx={{ fontSize: "14px" }}>
					{noSeconds(content.start)}-{noSeconds(content.end)}
				</Typography>
				<Typography sx={{ fontSize: "14px" }}>
					{roundedNumber} {t("common.currency_symbol")}
				</Typography>
			</div>
		</Box>
	);
};
