import { InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NumbersOnly } from "../../../../Common/Inputs/NumbersOnly";

export const FormEdit = ({
	title, // empty title value will hide typography
	value,
	type, // defaults to text if not provided. With "tel" displayed input keys are numeric in mobile view
	changeData,
	onlyNumeric,
	isMultiRow,
	rowAmount,
	isRequired,
	hasCharLimit,
	centeredText
}) => {
	const { t } = useTranslation();

	return (
		<>
			{title !== undefined && <Typography>{title}</Typography>}

			<TextField
				value={value || ""}
				fullWidth
				type={type}
				error={(value === "" || value === undefined) && isRequired}
				multiline={isMultiRow}
				rows={isMultiRow ? rowAmount : 1}
				onChange={(text) => {
					if (onlyNumeric && !NumbersOnly(text.target.value)) return;
					else changeData(text.target.value);
				}}
				InputProps={
					(hasCharLimit && { maxlength: 255 },
					(value === "" || value === undefined) &&
						isRequired && {
							endAdornment: (
								<InputAdornment
									position="end"
									disablePointerEvents
								>
									{t("warning.missing_data")}
								</InputAdornment>
							)
						})
				}
				inputProps={{
					style: {
						textAlign: centeredText && "center"
					}
				}}
				helperText={
					hasCharLimit &&
					`${value?.length || "0"} / 255 ${t(
						"location.form_character_count"
					)}`
				}
				FormHelperTextProps={
					hasCharLimit && {
						style: { color: "white" }
					}
				}
			/>
		</>
	);
};
