import React from "react";
import { ReactComponent as TrashDeleteIcon } from "../../../../Assets/Icons/TrashDeleteIcon.svg";
import { ColorScheme } from "../../../../Themes/ColorScheme";

export const RemoveButton = () => (
	<div
		style={{
			display: "flex",
			padding: "10px",
			alignItems: "center",
			transition: "0.3s",
			// backgroundColor: ColorScheme.tolotechBlue,
			borderRadius: "25px",
			cursor: "pointer"
		}}
		/* onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}} */
	>
		<TrashDeleteIcon
			// title={t("buttons.delete")}
			stroke={ColorScheme.darkRed}
			// fill="red"
			width="25px"
			height="25px"
		/>
	</div>
);
