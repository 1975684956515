import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";

import backgroundImage from "../Assets/Images/backgroundImage1.png";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./Common/Spinner";
import { UserContext } from "./UserContext";
import { ApiContext } from "./ApiContext";

export const LaunchInit = () => {
	const debug = false;

	const { setUser } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);

	const navigate = useNavigate();

	const styles = {
		paperContainer: {
			backgroundImage: `url(${backgroundImage})`,
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			minHeight: "100vh",
			position: "absolute",
			width: "100vw",
			top: "0",
			left: "0",
			boxShadow: "inset 0px 0px 0px 1500px rgba(6, 41, 67, 0.7)"
		}
	};

	useEffect(() => {
		ApiCall("get", "user", null, navigate)
			.then((res) => {
				setUser(res);
				if (res.name === "") navigate("/details");
				else navigate("/locations"); // navigate("/locations");
			})
			.catch((err) => {
				if (debug) console.log(err);
			});
	}, []);

	return (
		<>
			<div style={styles.paperContainer} />
			<Box
				sx={{
					height: "100vh",
					width: "100vw",
					zIndex: 999,
					position: "absolute",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					left: 0,
					top: 0
				}}
			>
				<Spinner />
			</Box>
		</>
	);
};
