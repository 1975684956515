import { Stack, Typography, Box, Badge } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { MuiDrawer } from "../Common/MuiDrawer";
import { ColorScheme } from "../../Themes/ColorScheme";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { UserContext } from "../UserContext";
import LogIcon from "../../Assets/Icons/Log.svg";
import QRIcon from "../../Assets/Icons/QR.svg";
import TooltipWrapper from "../Common/TooltipWrapper";

import { ReactComponent as NewUserIcon } from "../../Assets/Icons/UserIcon.svg";
import LandingAnimation from "../../Helpers/LandingAnimation";

// TODO: These should be fetched from api
/* const DOOR_TYPES = [
	{
		name_fi: "Ovi",
		name_en: "Door",
		id: 0,
		image: DoorIcon
	},
	{
		name_fi: "Nosto-ovi",
		name_en: "Garage door",
		id: 1,
		image: GarageDoorIcon
	},
	{
		name_fi: "Puomi",
		name_en: "Barrier",
		id: 2,
		image: DoorIcon
	}
]; */

export const DoorCard = ({ data, fetchDoors }) => {
	const debug = false;

	const [openDrawer, setOpenDrawer] = useState(false);
	const [drawerPath, setDrawerPath] = useState("");

	const [newRequests, setNewRequests] = useState(0);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);

	const handleClick = (e) => {
		setDrawerPath(e);
		setOpenDrawer(true);
	};

	const handleDrawerClose = () => {
		setOpenDrawer(!openDrawer);
		fetchDoors();
	};

	/* const handleButton = (event) => {
		event.stopPropagation();
		navigate(`/kurssit/${data.location_id}`);
	};*/

	/* const getDoorTypeImage = (type) => {
		let door = DOOR_TYPES.find((a) => a.id === parseInt(type));
		return door !== undefined ? door.image : null;
	}; */

	const fetchNewRequests = () => {
		ApiCall("GET", "client/access_requests")
			.then((response) => {
				const requestAmount = response.filter(
					(entry) => entry.door_id === data.door_id
				);

				setNewRequests(requestAmount.length);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		fetchNewRequests(); // won't update if drawer is open, but will after user closes it
	}, [data]);

	return (
		<LandingAnimation>
			<div>
				<Stack
					onClick={(event) => {
						event.stopPropagation();
						event.preventDefault();
						handleClick("AddDoor");
					}}
					direction="row"
					sx={{
						width: isMobile ? undefined : "350px",
						boxSizing: "border-box",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#ffffff",
						borderRadius: "4px",
						alignItems: "center",
						justifyContent: "space-between",
						/* marginBottom: "20px",
						marginRight: isMobile ? "5px" : "40px", */
						padding: "20px",
						color: "black",
						transition: ".3s",
						overflow: "hidden",
						cursor: "pointer",
						gap: "25px",
						"&:hover": {
							scale: "1.01"
						}
					}}
				>
					<div
						style={{
							flexDirection: "row",
							display: "flex",
							justifyContent: "space-between",
							width: isMobile ? "95%" : "100%",
							alignItems: "center"
						}}
					>
						<Box
							style={{
								height: "100%",
								flexDirection: "row",
								display: "flex",
								alignItems: "center",
								width: "100%",
								justifyContent: "space-between"
							}}
						>
							<Box
								sx={{
									flexDirection: "column",
									display: "flex"
								}}
							>
								<Typography
									style={{
										fontSize: "24px",
										fontWeight: "700"
									}}
								>
									{data.door_name}
								</Typography>
								<Typography
									style={{
										fontSize: "16px",
										fontWeight: "700",
										color: "grey"
									}}
								>
									{data?.doorphone || t("doors.no_doorphone")}
								</Typography>
							</Box>

							<TooltipWrapper
								ignoreStringRestriction={true}
								title={
									<>
										{data.online
											? t("doors.door_online")
											: t("doors.door_offline")}
									</>
								}
							>
								<Box
									sx={{
										height: "16px",
										width: "16px",
										borderRadius: "8px",
										background: data.online
											? ColorScheme.green
											: ColorScheme.red
									}}
								/>
							</TooltipWrapper>
						</Box>
					</div>

					{/* <div style={{ height: }} */}
					<div
						style={{
							// border: "1px solid rgba(0, 0, 0, 0.1)",
							backgroundColor: "rgba(0, 0, 0, 0.02)",
							// borderTop: "1px solid rgba(0, 0, 0, 0.05)",
							borderRadius: "4px",
							padding: "20px",
							// marginBottom: isMobile && "20px",
							width: isMobile ? "70dvw" : "85%",
							// marginTop: "10px",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
							// marginBottom: "5px"
						}}
					>
						<TooltipWrapper title={t("doors.show_userlist")}>
							<Box
								style={{
									flexDirection: "column",
									justifyContent: "center",
									display: "flex",
									alignItems: "center",
									cursor: "pointer"
								}}
								onClick={(event) => {
									event.stopPropagation();
									event.preventDefault();
									handleClick("ListDoorUsers");
								}}
							>
								{
									<Badge
										badgeContent={newRequests}
										color="error"
										anchorOrigin={{
											vertical: "top",
											horizontal: "left"
										}}
										sx={{ mx: "10px" }}
									/>
								}
								<NewUserIcon
									height={"28px"}
									width={"28px"}
									stroke={"black"}
									strokeWidth={"2px"}
								/>
								<Typography
									style={{
										fontSize: "12px",
										color: "grey",
										fontWeight: "500",
										marginTop: "5px"
									}}
								>
									{data.user_count}
								</Typography>
							</Box>
						</TooltipWrapper>
						{!selectedLocation.location_permissions.some(
							(check) => check.permission_id === 6
						) && (
							<TooltipWrapper title={t("logs.show_access_logs")}>
								<Box
									style={{
										flexDirection: "column",
										justifyContent: "center",
										display: "flex",
										alignItems: "center",
										cursor: "pointer"
									}}
									onClick={(event) => {
										event.stopPropagation();
										handleClick("doorLogs");
									}}
								>
									<img
										style={{ height: "30px" }}
										src={LogIcon}
									/>
									<Typography
										style={{
											fontSize: "12px",
											color: "grey",
											fontWeight: "500",
											marginTop: "5px"
										}}
									>
										{t("buttons.log_events")}
									</Typography>
								</Box>
							</TooltipWrapper>
						)}
						<Box
							style={{
								flexDirection: "column",
								justifyContent: "center",
								display: "flex",
								alignItems: "center"
							}}
						>
							<img style={{ height: "30px" }} src={QRIcon} />
							<Typography
								style={{
									fontSize: "12px",
									color: "grey",
									fontWeight: "500",
									marginTop: "5px"
								}}
							>
								{data.code}
							</Typography>
						</Box>
					</div>
				</Stack>

				<MuiDrawer
					open={openDrawer}
					handleDrawerClose={handleDrawerClose}
					content={data}
					contentType={drawerPath}
				/>
			</div>
		</LandingAnimation>
	);
};
