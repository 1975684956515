import { Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../UserContext";
import { AddressDetail } from "../Fields/AddressDetail";
import { ContactDetail } from "../Fields/ContactDetail";
import { TextDetail } from "../Fields/TextDetail";
/* import { TimeDetail } from "./Fields/TimeDetail"; */
import { ManageProductList } from "../Product/ManageProductList";
import { AdminDetail } from "../Fields/AdminDetail";
import { ApiContext } from "../../../ApiContext";
import { PhotoDetail } from "../Fields/PhotoDetail";
import { BillingDetail } from "../Fields/BillingDetail";
import { useTranslation } from "react-i18next";
import { WebBooking } from "../Fields/WebBooking";
import { isMobile } from "react-device-detect";
import { LocationSettings } from "../Fields/LocationSettings";
import { useNavigate } from "react-router-dom";
import { LocationsUsers } from "../Fields/LocationUsers";

export const LocationForm = ({ images, fetchLocationImages }) => {
	const debug = false;

	const { selectedLocation } = useContext(UserContext);
	const navigate = useNavigate();
	/*	const [locationData, setLocationData] = useState(null);*/

	const [reservableTypes, setReservableTypes] = useState([]);
	const [productList, setProductList] = useState([]);
	const [reservableList, setReservableList] = useState([]);

	const [loadingProducts, setLoadingProducts] = useState(true);
	const [loadingReservables, setLoadingReservables] = useState(true);

	const [noProducts, setNoProducts] = useState(false);
	const [noReservables, setNoReservables] = useState(false);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	/* console.log(location);
	console.log(info);*/

	const refreshPage = () => {
		navigate("/locations/" + selectedLocation.location_id);
	};

	const getReservables = () => {
		ApiCall(
			"GET",
			`client/reservables/${selectedLocation.location_id}`,
			/* `location/get_reservables/${selectedLocation.location_id}`, */
			null
		)
			.then((reservables) => {
				if (reservables.length === 0) setNoReservables(true);
				setReservableList(reservables);
			})
			.catch((error) => {
				if (debug) console.log(error);
			})
			.finally(setLoadingReservables(false));
	};

	const getProducts = () => {
		ApiCall(
			"GET",
			`location/get_products/${selectedLocation.location_id}`,
			null
		)
			.then((products) => {
				if (products.length === 0) setNoProducts(true);
				setProductList(products);
			})
			.catch((error) => {
				if (debug) console.log(error);
			})
			.finally(setLoadingProducts(false));
	};

	const getPurchaseableTypes = () => {
		ApiCall("GET", "client/get_purchaseable_types", null)
			.then((types) => {
				setReservableTypes(types);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		setLoadingReservables(true);
		setLoadingProducts(true);

		setReservableList([]);
		setReservableTypes([]);
		setProductList([]);

		setNoProducts(false);
		setNoReservables(false);

		if (selectedLocation !== undefined && selectedLocation !== null) {
			getProducts();
			getReservables();
			getPurchaseableTypes();
		}
	}, [selectedLocation]);

	/*
	useEffect(() => {
		images.map((image) => {
			if (image.image_type === 1) {
				setBannerImage(image.image);
				setUseDefaultBanner(false);
			}
		});

		if (Object.prototype.hasOwnProperty.call(info, "billing"))
			setShowBilling(true);
	}, [images]);

	*/

	return (
		<Box sx={{ width: "100%" }}>
			<Stack
				direction={isMobile ? "column" : "row"}
				justifyContent={"space-between"}
				/* alignItems={"center"} */
			>
				<Stack
					sx={{
						width: isMobile ? "100%" : "48%"
					}}
					spacing={2}
				>
					<TextDetail
						data={selectedLocation}
						refreshData={refreshPage}
						images={images}
					/>
					<Divider />

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined && (
							<>
								<WebBooking
									data={selectedLocation}
									refreshData={refreshPage}
								/>
								<Divider />
							</>
						)}

					{selectedLocation.location_permissions !== undefined &&
						(selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined ||
							selectedLocation.location_permissions.find(
								(obj) => obj.permission_id === 8
							) === undefined) && (
							<>
								<LocationSettings
									data={selectedLocation}
									refreshData={refreshPage}
								/>

								<Divider />
							</>
						)}

					<AddressDetail
						data={selectedLocation}
						refreshData={refreshPage}
					/>
					<Divider />
					<ContactDetail
						data={selectedLocation}
						refreshData={refreshPage}
					/>
					<Divider />
					<BillingDetail
						data={selectedLocation}
						refreshData={refreshPage}
					/>

					<Divider />
					{/* <TimeDetail data={switchSource} />
					<Divider /> */}
					<AdminDetail data={selectedLocation} />

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined && (
							<>
								<Divider />
								<LocationsUsers />
							</>
						)}
					<Divider />
					<PhotoDetail
						data={selectedLocation}
						images={images}
						refreshData={fetchLocationImages}
					/>
				</Stack>

				{(selectedLocation.location_permissions.find(
					(obj) => obj.permission_id === 2
				) !== undefined ||
					selectedLocation.location_permissions.find(
						(obj) => obj.permission_id === 8
					) !== undefined) && (
					<Stack sx={{ width: isMobile ? 1 : "48%" }}>
						<Box
							sx={{
								width: "100%"
							}}
						>
							<Typography
								style={{
									fontSize: "25px",
									fontWeight: "700"
								}}
							>
								{t("location.reservable_space_label")}
							</Typography>

							<ManageProductList
								info={selectedLocation}
								mode={1}
								noData={noReservables}
								allServices={reservableTypes}
								allReservables={reservableList}
								allProducts={productList}
								fieldRefresh={getReservables}
								isLoading={loadingReservables}
							/>
						</Box>

						{selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined && (
							<Box
								sx={{
									width: "100%"
								}}
								style={{ marginTop: "30px" }}
							>
								<Typography
									style={{
										fontSize: "25px",
										fontWeight: "700"
									}}
								>
									{t("location.additional_reservables_label")}
								</Typography>
								<ManageProductList
									info={selectedLocation}
									mode={2} // todo
									noData={noProducts}
									allServices={reservableTypes}
									allReservables={reservableList}
									allProducts={productList}
									fieldRefresh={getProducts}
									isLoading={loadingProducts}
									getProducts={getProducts}
								/>
							</Box>
						)}
					</Stack>
				)}
			</Stack>
		</Box>
	);
};
