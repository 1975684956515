import React from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { DayTimeSelect } from "../../Common/DayTimeSelect";
import { useTranslation } from "react-i18next";

export const ManageTimes = ({
	handleEnd,
	handleStart,
	handleWeekdays,
	handleSave
}) => {
	const { t } = useTranslation();
	return (
		<Box sx={{ py: "15px" }}>
			<Stack spacing={1}>
				<Divider />

				<Typography>{t("doors.add_new_time")}</Typography>

				<DayTimeSelect
					passEnd={handleEnd}
					passStart={handleStart}
					handleWeekdays={handleWeekdays}
				/>
				<Button variant="contained" onClick={() => handleSave()}>
					{t("buttons.save")}
				</Button>

				<Divider />
			</Stack>
		</Box>
	);
};
