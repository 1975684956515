import React from "react";
import { Button, Collapse, Stack, Typography } from "@mui/material";
import { AlertBar } from "./AlertBar";
import { ColorScheme } from "../../Themes/ColorScheme";

import { ReactComponent as PhoneIcon } from "../../Assets/Icons/PenIcon.svg";
import { ReactComponent as EmailIcon } from "../../Assets/Icons/EnvelopeIcon.svg";
import { TextFieldInput2 } from "./TextFieldInput2";
import { useTranslation } from "react-i18next";

export const UserAdd = ({
	contact,
	handleContactChange,
	handleAnotherUser,
	placeholder,
	onlyEmail,
	/* disableInput, */
	alertType = "info", // default alert type
	showError,
	errorMessage,
	userExists,
	showAddMore = true // default true, always shown unless set to false from parent. Easier this way than add new prop to every instance of UserAdd.
}) => {
	const { t } = useTranslation();

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/; // x@y.z format
		return re.test(email);
	};

	const validateUserInput = (id) => {
		const internationalRegex = /^\+(?:[0-9]?){1,4}[0-9]{9,14}(?:x.+)?$/; // intl phone numbers with required country code
		const finnishRegex =
			/^(?:\+358|0)(?:\s|-)?\d{1,3}(?:\s|-)?\d{4,}(?:\s|-)?\d{0,2}$/; // finnish numbers with and without country code prefix

		const phoneIsValid =
			internationalRegex.test(id) || finnishRegex.test(id);

		const emailIsValid = validateEmail(id);

		const allDigitsAreSame = /^(\d)\1+$/.test(id); // prevent lazy inputs like 0000000

		return (phoneIsValid || emailIsValid) && !allDigitsAreSame;
	};

	const checkValidity = (id) =>
		onlyEmail ? !validateEmail(id) : !validateUserInput(id);

	return (
		<Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					justifyContent: "space-between",
					gap: "5px"
				}}
			>
				<TextFieldInput2
					text={contact}
					valueChange={handleContactChange}
					title={placeholder}
					hasProps={
						onlyEmail ? (
							<EmailIcon stroke={ColorScheme.defaultIconColor} />
						) : (
							<PhoneIcon stroke={ColorScheme.defaultIconColor} />
						)
					}
				/>
				{/* <TextField
					disabled={disableInput}
					fullWidth
					sx={theme.toloStyled.default_textfield}
					value={contact}
					onChange={handleContactChange}
					label={placeholder}
					placeholder={t("common.textfield_placeholder_text")}
					InputProps={{
						notched: false,
						startAdornment: (
							<InputAdornment position="start">
								{onlyEmail ? (
									<EmailIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								) : (
									<PhoneIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								)}
							</InputAdornment>
						)
					}}
					onKeyDown={(e) => {
						if (checkValidity(contact)) return; // prevent enter key from triggering handleAnotherUser if input is invalid
						if (e.key === "Enter" && showAddMore)
							handleAnotherUser();
					}}
				/>
 */}

				<Collapse in={showError} collapsedSize={true}>
					<AlertBar
						showAlert={showError}
						type={alertType}
						standardVariant={true}
						textContent={errorMessage}
					/>
				</Collapse>
			</div>

			{showAddMore && (
				<Button
					variant="outlined"
					disabled={userExists ? true : checkValidity(contact)}
					onClick={handleAnotherUser}
					sx={{
						width: "200px",
						height: "76px"
					}}
				>
					<Typography>{t("buttons.add_another_user")}</Typography>
				</Button>
			)}

			{/* <QuestionTooltip title="doorAddUser" /> */}
		</Stack>
	);
};
