import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { TextFieldInput2 } from "../Common/TextFieldInput2";
import { TextfieldPicker } from "../Common/TextfieldPicker";
import { WeekdaySelect } from "../Common/WeekdaySelect";
import { Button, Collapse, FormControlLabel, Typography } from "@mui/material";
import TooltipWrapper from "../Common/TooltipWrapper";
import { MultiChip } from "../Common/MultiChip";
import { AlertBar } from "../Common/AlertBar";
import { ColorScheme } from "../../Themes/ColorScheme";

import { ReactComponent as EmailIcon } from "../../Assets/Icons/EnvelopeIcon.svg";
import { ReactComponent as StartDateIcon } from "../../Assets/Icons/CalendarFromIcon.svg";
import { ReactComponent as EndDateIcon } from "../../Assets/Icons/CalendarToIcon.svg";
import { ReactComponent as StartTimeIcon } from "../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as EndTimeIcon } from "../../Assets/Icons/TimeEndIcon.svg";
import { ReactComponent as PriceIcon } from "../../Assets/Icons/WalletIcon.svg";
import { ApiContext } from "../ApiContext";
import { UserContext } from "../UserContext";
import OnOffSlider from "../Common/Inputs/OnOffSlider";
import { Spinner } from "../Common/Spinner";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const ContinuousCreateDialog = ({ closeDialog }) => {
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());
	const [weekdays, setWeekdays] = useState([]);
	const [price, setPrice] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [reservables, setReservables] = useState([]);
	const [reservableList, setReservableList] = useState([]);
	const [isLumpSum, setIsLumpSum] = useState(true);
	const [loadingButton, setLoadingButton] = useState(false);
	const [showError, setShowError] = useState(null);
	const [disableButton, setDisableButton] = useState(false);

	const validDateTime = (date) => !isNaN(Date.parse(date));

	const checkTime = (start, end) => {
		if (
			new Date(start).getFullYear() < new Date().getFullYear() ||
			new Date(end).getFullYear() < new Date().getFullYear()
		)
			return true;

		if (start === "" || end === "") return true;
		if (start > end) return true;
		else return false;
	};

	const handleStartDateChange = (newValue) => {
		// console.log(newValue);
		const date = new Date(newValue); // temp workaround to fix date changing to previous date from selected
		const hourOffset = new Date(date.setHours(12)).toISOString();

		if (validDateTime(hourOffset)) {
			// console.log("date", date);
			setStartDate(hourOffset);
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	};

	const handleEndDateChange = (newValue) => {
		const date = new Date(newValue);

		if (validDateTime(date)) {
			// console.log("date", date);
			setEndDate(date);
			setDisableButton(false);
		} else setDisableButton(true);
	};

	const handleStartTimeChange = (newValue) => {
		const date = new Date(newValue);
		if (validDateTime(date)) {
			// console.log(date);
			setStartTime(date);
			setDisableButton(false);
		} else setDisableButton(true);
	};

	const handleEndTimeChange = (newValue) => {
		const date = new Date(newValue);

		if (validDateTime(date)) {
			// console.log(date);
			setEndTime(date);
			setDisableButton(false);
		} else setDisableButton(true);
	};

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const handleReservables = (e) => {
		setReservables(e);
	};

	const handleSubmit = () => {
		ApiCall("POST", "client/reservation/continuous", {
			continuous_reservation: {
				from_time: startTime,
				to_time: endTime,
				reservable_id: reservables[0].id,
				starting_date: startDate,
				expiration_date: endDate,
				weekday: Number(weekdays),
				vat_price: Number(price)
			},
			email: userEmail,
			one_order: isLumpSum
		})
			.then(() => {
				handleCancel();
				/* loadContinuousReservations();
				passRefresh(); */
				setShowError(null);
			})
			.catch((e) => {
				setShowError(e);
				setLoadingButton(false);
			});
	};

	const handleCancel = () => {
		setStartDate("");
		setEndDate("");
		setStartTime("");
		setEndTime("");
		setIsLumpSum(false);
		setPrice("");
		setWeekdays("");
		setReservables([]);
		setUserEmail("");
		setShowError(null);
		setLoadingButton(false);
		closeDialog();
	};

	const checkStates = () => {
		if (
			startDate === "" ||
			!validDateTime(startDate) ||
			endDate === "" ||
			!validDateTime(endDate) ||
			checkTime(startDate, endDate) ||
			startTime === "" ||
			!validDateTime(startTime) ||
			endTime === "" ||
			!validDateTime(endTime) ||
			checkTime(startTime, endTime) ||
			price === "" ||
			weekdays === "" ||
			reservables.length === 0 ||
			!validateEmail(userEmail)
		)
			return true;
		else return false;
	};

	const fetchReservables = () => {
		ApiCall(
			"GET",
			`location/get_reservables/${selectedLocation.location_id}`
		).then((res) => {
			setReservableList(res);
		});
	};

	useEffect(() => {
		checkStates();
	}, [
		startDate,
		endDate,
		startTime,
		endTime,
		price,
		weekdays,
		reservables,
		userEmail
	]);

	useEffect(() => {
		fetchReservables();
	}, []);

	return (
		<Stack spacing={1} sx={{ padding: "20px", gap: "5px" }}>
			<Typography>
				{t("calendar.create_new_continuous_reservation_label")}
			</Typography>
			<TextFieldInput2
				text={userEmail}
				valueChange={setUserEmail}
				title={t("textfield_labels.email")}
				hasProps={<EmailIcon stroke={ColorScheme.defaultIconColor} />}
			/>

			<AlertBar
				textContent={t("calendar.continuous_reservation_helper_text")}
				type="info"
				showAlert
			/>

			{/* <div style={theme.formStyles.formLayout}>
                <Typography variant="form_bold">
                    {t("textfield_labels.email")}
                </Typography>
                <TextFieldInput
                    valueChange={(text) => setUserEmail(text)}
                    styled={true}
                />
            </div> */}
			<div
				style={{
					display: "flex",
					flexDirection: isDesktop ? "row" : "column",
					gap: "20px"
				}}
			>
				<TextfieldPicker
					noTime={true}
					label={t("calendar.start_date_label")}
					value={startDate}
					valueChange={handleStartDateChange}
					hasProps={
						<StartDateIcon stroke={ColorScheme.defaultIconColor} />
					}
				/>

				<TextfieldPicker
					noTime={true}
					label={t("calendar.end_date_label")}
					value={endDate}
					limitValues={true}
					limitMin={startDate}
					valueChange={handleEndDateChange}
					hasProps={
						<EndDateIcon stroke={ColorScheme.defaultIconColor} />
					}
				/>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: isDesktop ? "row" : "column",
					gap: "20px"
				}}
			>
				<TextfieldPicker
					fullDate={false}
					label={t("calendar.start_time_label")}
					value={startTime}
					excludePast={false}
					valueChange={handleStartTimeChange}
					hasProps={
						<StartTimeIcon stroke={ColorScheme.defaultIconColor} />
					}
				/>

				<TextfieldPicker
					fullDate={false}
					label={t("calendar.end_time_label")}
					excludePast={false}
					limitValues={true}
					limitMin={startTime}
					value={endTime}
					valueChange={handleEndTimeChange}
					hasProps={
						<EndTimeIcon stroke={ColorScheme.defaultIconColor} />
					}
				/>
			</div>

			<div
				style={{
					paddingBottom: "5px"
				}}
			>
				<WeekdaySelect
					singleSelect
					days={weekdays}
					weekdayClicked={setWeekdays}
				/>
			</div>
			{/* 	<TimeSelectWeekdays
                singleSelect={true}
                passWeekdays={(values) => setWeekdays(values)}
            /> */}
			<div
				style={{
					display: "flex",
					flexDirection: isDesktop ? "row" : "column",
					gap: "20px",
					alignItems: "center",
					justifyContent: "space-between"
				}}
			>
				<div style={{ width: "100%" }}>
					<TextFieldInput2
						title={t("textfield_labels.price")}
						endAdornment={t("common.currency_symbol")}
						valueChange={setPrice}
						hasProps={
							<PriceIcon
								stroke={ColorScheme.defaultIconColor}
								strokeWidth={3}
								height={24}
							/>
						}
					/>
				</div>

				<FormControlLabel
					style={{
						width: isDesktop ? undefined : "100%",
						/* gap: "10px", */
						display: "flex",
						flexDirection: isDesktop ? "column" : "row-reverse",
						justifyContent: isDesktop ? "center" : "space-between",
						marginLeft: 0, // overrides default margin in FormControlLabel
						marginRight: 0 // same here
						/* alignSelf: "self-end" */
					}}
					value="bottom"
					control={
						<TooltipWrapper
							hide={!isDesktop}
							ignoreStringRestriction={true}
							title={
								<>
									{t("calendar.is_lump_sum_tooltip")}
									<br />
									<br />
									{t("calendar.is_lump_sum_default_tooltip")}
								</>
							}
						>
							<OnOffSlider
								status={isLumpSum}
								action={() => setIsLumpSum(!isLumpSum)}
							/>
							<Typography sx={{ fontSize: "12px" }}>
								{t("calendar.lump_sum_label")}
							</Typography>
						</TooltipWrapper>
					}
				/>
			</div>

			{!isDesktop && (
				<AlertBar
					textContent={
						t("calendar.is_lump_sum_tooltip") +
						" " +
						t("calendar.is_lump_sum_default_tooltip")
					}
					type="info"
					showAlert={true}
					standardVariant={"standard"}
				/>
			)}
			{/* <Typography>{t("calendar.select_reservables_label")}</Typography> */}
			<MultiChip
				singleSelect={true}
				data={reservableList}
				passSelectedData={handleReservables}
				isReservable={true}
				getLabel={t("reservables.Facility")}
			/>

			<Collapse in={showError !== null}>
				<AlertBar
					textContent={t("services.new_pricing_model_failed_alert")}
					type="error"
					showAlert={showError}
					standardVariant={"standard"}
				/>
			</Collapse>

			<Box
				sx={{
					flexDirection: "row",
					display: "flex",
					width: "100%",
					justifyContent: "space-between",
					gap: "10px"
				}}
			>
				<Button
					sx={{ width: "50%" }}
					variant="outlined"
					onClick={() => handleCancel()}
				>
					{t("buttons.cancel")}
				</Button>

				<Button
					sx={{
						width: "50%",
						backgroundColor: loadingButton ? "white" : "",
						border: loadingButton
							? `1px solid ${ColorScheme.tolotechBlue}`
							: ""
					}}
					variant="drawerFormAccept"
					disabled={checkStates() || loadingButton || disableButton}
					onClick={() => {
						setLoadingButton(true);
						handleSubmit();
					}}
				>
					{loadingButton ? (
						<Spinner
							hasText={t("spinner_text.wait")}
							small={true}
							color={"black"}
							hasColor={ColorScheme.tolotechBlue}
						/>
					) : (
						t("buttons.save")
					)}
				</Button>
			</Box>
		</Stack>
	);
};
