import { Stack, TextField, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { theme } from "../../Themes/Theme";
import { TimeSelectWeekdays } from "./TimeSelectWeekdays";
import { ReactComponent as ArrowIcon } from "../../Assets/Icons/arrowBack.svg";
import { useTranslation } from "react-i18next";

export const DayTimeSelect = ({
	passStart,
	passEnd,
	noWeekdays,
	editData,
	handleWeekdays,
	simpleSelectOnly,
	isClosed,
	doorData,
	campaignData,
	currentReservable,
	isVertical, // for better door time adjustment alignment
	passUserData,
	newStyling,
	dateStyle,
	hideLabels,
	lessSpacing,
	addSeparator,
	centered,
	smallLabel,
	fullDate
}) => {
	const { t } = useTranslation();

	const startOfDay = new Date();
	const endOfDay = new Date();

	startOfDay.setHours(0, 0, 0, 0); // set default time to start of the day
	endOfDay.setHours(23, 59, 59, 0); // set default time to end of the day

	const [startTime, setStartTime] = useState(startOfDay);
	const [endTime, setEndTime] = useState(endOfDay);
	const [reservationTime, setReservationTime] = useState("");

	const user = passUserData;

	// lähimpään 30/60 moment().add( moment().minute() > 30 && 1 , 'hours').minutes( moment().minute() <= 30 ? 30 : 0).format("HH:mm")

	useEffect(() => {
		passStart(startTime);
		passEnd(endTime);
	}, [startTime, endTime]);

	useEffect(() => {
		if (currentReservable !== undefined) {
			setStartTime(currentReservable.startTime);
			setEndTime(currentReservable.endTime);
		}

		if (campaignData !== undefined) {
			setStartTime(campaignData.from_time);
			setEndTime(campaignData.to_time);
		}

		if (noWeekdays && editData !== undefined) {
			setStartTime(editData.from_time);
			setEndTime(editData.to_time);
			setReservationTime(editData.minimum_reservation);
		}

		if (doorData !== undefined) {
			setStartTime(
				doorData.start_time !== null ? doorData.start_time : startOfDay
			);
			setEndTime(
				doorData.end_time !== null ? doorData.end_time : endOfDay
			);
		}

		/* if (startTime === "") setStartTime(editData.from_time);
		if (endTime === "") setEndTime(editData.to_time);*/
		if (reservationTime === "") setReservationTime(0); // Changed to 0 because this component is now only used at door accesses and there is no need to define minimum time
	}, []);

	useEffect(() => {
		if (user?.start_time !== null && user?.start_time !== undefined)
			setStartTime(user.start_time);
		if (user?.end_time !== null && user?.end_time !== undefined)
			setEndTime(user.end_time);
	}, []);

	return (
		<Stack spacing={1}>
			<Stack
				direction={isVertical ? "column" : "row"}
				spacing={lessSpacing ? 1 : 2}
				alignItems={(isVertical || centered) && "center"}
			>
				<div style={{ ...theme.formStyles.formLayout, width: "100%" }}>
					{hideLabels ? undefined : (
						<Typography variant="form_bold">
							{t("textfield_labels.start_time")}
						</Typography>
					)}

					<TimePicker
						ampm={false}
						/* label={t("textfield_labels.start_time")} */
						openTo="hours"
						label={
							smallLabel
								? t("textfield_labels.start_time")
								: undefined
						}
						disabled={isClosed}
						/* minTime={moment().startOf("day")}*/
						maxTime={moment(endTime).subtract(1, "minutes")}
						minutesStep={1}
						value={startTime}
						onChange={(newTime) =>
							setStartTime(moment(newTime).seconds(0).format())
						}
						format={fullDate ? "DD.MM.yyyy HH:mm" : "HH:mm"}
						renderInput={(params) => (
							<TextField
								sx={theme.toloStyled.toloTextfield.pickers}
								/* style={{ width: "50%" }} */
								{...params}
								InputLabelProps={{
									style: {
										fontSize: "14px",
										top: "24px",
										left: "37px"
									}
								}}
								error={false}
								/* helperText={
								startTime === endTime &&
								t("doors.exact_times_notify")
							} */
							/>
						)}
						slotProps={{
							inputAdornment: {
								position: "start"
							},

							textField: {
								InputProps: {
									notched: false,
									disableUnderline: true
								}
							}
						}}
					/>
				</div>

				{addSeparator && (
					<ArrowIcon
						stroke="black"
						style={{
							transform: "rotate(180deg)",
							width: "80px",
							height: "80px"
							/* paddingLeft: "5px",
							paddingRight: "5px" */
						}}
					/>
				)}

				<div style={{ ...theme.formStyles.formLayout, width: "100%" }}>
					{hideLabels ? undefined : (
						<Typography variant="form_bold">
							{t("recipes.from_time")}
						</Typography>
					)}
					<TimePicker
						ampm={false}
						/* 	label={t("textfield_labels.end_time")} // TODO */
						// todo pass minimum reservation time*/
						minTime={moment(startTime).add(
							reservationTime,
							"minutes"
						)}
						label={smallLabel ? t("recipes.to_time") : undefined}
						minutesStep={1}
						disabled={isClosed || startTime === ""}
						value={endTime}
						onChange={(newTime) =>
							setEndTime(moment(newTime).seconds(0).format())
						}
						format={fullDate ? "DD.MM.yyyy HH:mm" : "HH:mm"}
						renderInput={(params) => (
							<TextField
								sx={
									newStyling
										? {
												...theme.formStyles.formInput
											}
										: dateStyle
											? {
													...theme.formStyles
														.course_meet_datetime_fields
												}
											: {
													...theme.toloStyled
														.default_textfield
												}
								}
								/* 	style={{ width: "50%" }} */
								{...params}
								error={startTime !== "" && endTime === ""}
							/>
						)}
						slotProps={{
							inputAdornment: {
								position: "start"
							},

							textField: {
								InputProps: {
									notched: false,
									disableUnderline: true
								}
							}
						}}
					/>
				</div>
			</Stack>

			{!noWeekdays && !simpleSelectOnly && (
				<TimeSelectWeekdays
					noWeekdays={noWeekdays}
					passWeekdays={handleWeekdays}
					checkWeekdays={user?.weekdays}
				/>
			)}
		</Stack>
	);
};
