import { Box, Collapse, Typography } from "@mui/material";
import moment from "moment";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { ApiContext } from "../../ApiContext";

import { Locale } from "../../Calendar/Config/Locale";
import { Spinner } from "../../Common/Spinner";
import TimeAgo from "../../../Helpers/GetTimeAgoForDate";
import TooltipWrapper from "../../Common/TooltipWrapper";
import FormatDateTime from "../../../Helpers/FormatDateTime";
import { isMobile } from "react-device-detect";

function DoorLog({ entry, isLast }) {
	const [icon, setIcon] = useState("");
	const [mobileCollapse, setMobileCollapse] = useState(false); // instead of tooltip, we use collapse element for mobile view

	useEffect(() => {
		setIcon(entry.icon);
	}, []);

	return (
		<React.Fragment>
			<Box
				sx={{
					maxWidth: "100%",
					flexDirection: "row",
					padding: "15px",
					justifyContent: "space-between",
					display: "flex",
					alignItems: "center"
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center"
					}}
				>
					<img
						style={{
							height: "30px",
							width: "22px",
							marginRight: "15px"
						}}
						src={`data:image/svg+xml;utf8,${icon}`}
					/>
					<div>
						<Typography
							style={{
								fontSize: "20px",
								fontWeight: "regular"
							}}
						>
							{entry?.name || "Poistettu käyttäjä"}
						</Typography>
						<Typography
							style={{
								fontSize: 12,
								fontWeight: "400",
								color: "grey"
							}}
						>
							{entry.email}
						</Typography>
					</div>
				</Box>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "10px"
					}}
					onClick={() =>
						isMobile ? setMobileCollapse(!mobileCollapse) : null
					}
				>
					{!entry.device_status && !isMobile && (
						<TooltipWrapper title={entry.device_message}>
							<div
								style={{
									display: "flex",
									position: "relative",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
									color: "#A85D5D"
								}}
							>
								&#9432;
							</div>
						</TooltipWrapper>
					)}

					<TooltipWrapper
						ignoreStringRestriction={true}
						title={<>{FormatDateTime(new Date(entry.date))}</>}
					>
						<Box
							sx={{
								backgroundColor: entry.device_status
									? "#DCE9D6"
									: "#E9D6D6",
								px: "14px",
								py: "5px",
								display: "flex",
								borderRadius: "20px",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<Typography
								sx={{
									color: entry.device_status
										? "#75A85D"
										: "#A85D5D",
									fontSize: "14px",
									fontWeight: "thin"
								}}
							>
								{TimeAgo(new Date(entry.date))}
							</Typography>
						</Box>
					</TooltipWrapper>
				</div>
			</Box>

			{isMobile && (
				<Collapse in={mobileCollapse}>
					<div
						style={{
							padding: "10px 53px",
							backgroundColor: ColorScheme.tolotechWhite
						}}
					>
						<Typography>
							{FormatDateTime(new Date(entry.date))}
						</Typography>
						{entry.device_message !== "" && (
							<Typography>{entry.device_message}</Typography>
						)}
					</div>
				</Collapse>
			)}

			{!isLast && (
				<div
					style={{
						height: "1px",
						width: "100%",
						backgroundColor: "#F0F0F0"
					}}
				/>
			)}
		</React.Fragment>
	);
}

export const LocationDoorLogs = ({ doorData }) => {
	const debug = false;

	console.log("doorData", doorData);

	const [doorDetails, setDoorDetails] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadMore, setLoadMore] = useState(false);
	const [page, setPage] = useState(1);

	const noMore = doorDetails.length === (page - 1) * 20;

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	moment.updateLocale("fi", Locale);

	function LoadNextPage() {
		setLoadMore(true);
		ApiCall("GET", `client/logs?door_id=${doorData.door_id}&page=${page}`)
			.then((door) => {
				if (door.length > 0) {
					setDoorDetails([...doorDetails, ...door]);
					setPage(page + 1);
					setLoadMore(false);
				}
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoading(false);
			});
	}

	useEffect(() => {
		setLoading(true);

		ApiCall("GET", `client/logs?door_id=${doorData.door_id}`)
			.then((door) => {
				setDoorDetails(door);
				setLoading(false);
				setPage(page + 1);
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoading(false);
			});
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			{/* <DrawerTitle
				title={t("doors.show_door_logs")}
				location={selectedLocation.name}
			/> */}
			{/* <Typography
				sx={{ fontSize: "20px", color: ColorScheme.tolotechBlue }}
			>
				{doorData?.door_name}
			</Typography> */}
			{loading && <Spinner />}
			{!loading && doorDetails.length === 0 && (
				<Typography
					sx={{
						fontSize: "20px",
						fontWeight: "normal",
						color: "rgba(0,0,0,0.5)",
						marginTop: "20px"
					}}
				>
					{t("logs.not_found")}
				</Typography>
			)}
			{!loading && doorDetails.length > 0 && (
				<Box>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							boxSizing: "border-box",
							borderRadius: "4px",
							width: "100%",
							margin: "auto",
							border: "1px solid #F0F0F0",
							backgroundColor: "#FAFAFA"
						}}
					>
						{doorDetails.map((entry, index) => (
							<DoorLog
								entry={entry}
								index={index}
								isLast={index === doorDetails.length - 1}
								key={index}
							/>
						))}
					</Box>
					<Box
						onClick={() => {
							if (noMore) LoadNextPage();
						}}
						sx={{
							width: "100%",
							height: 44,
							backgroundColor: noMore
								? ColorScheme.tolotechBlue
								: "transparent",
							borderRadius: "4px",
							marginTop: "20px",
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							cursor: "pointer",
							"&:hover": {
								backgroundColor:
									noMore && ColorScheme.tolotechDarkBlue
							}
						}}
					>
						{loadMore ? (
							<Spinner small />
						) : (
							<Typography
								sx={{
									fontSize: "16px",
									color: noMore ? "white" : "black"
								}}
							>
								{noMore ? t("buttons.load_more") : ""}
							</Typography>
						)}
					</Box>
					<div style={{ height: "25px" }} />
				</Box>
			)}
		</div>
	);
};
