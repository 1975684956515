import i18n from "../Localization/i18n"; // Import the i18n instance

// Array of options for different types of reservables.
// Each option object has a label and a value.

export const ReservableTypeOptions = [
	{ label: i18n.t("buttons.default_selection"), value: 999 },
	{ label: i18n.t("buttons.reservable_space"), value: 0 },
	{ label: i18n.t("buttons.key_management"), value: 3 }
];
