import { CardMedia, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
/* import Zoom from "@mui/material/Zoom";*/
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../../ApiContext";
import { ConfirmAction } from "../../../Common/ConfirmAction";
import { ImageUpload } from "../../../Common/ImageUpload";

export const LocationImages = ({
	data,
	editMode,
	isLogo,
	isBanner,
	images,
	refreshList
}) => {
	const debug = false;

	const [imageFile, setImageFile] = useState([]);
	const [showConfirm, setShowConfirm] = useState(false);
	const [anchor, setAnchor] = useState("");
	const [checkIndex, setCheckIndex] = useState("");
	const [selectedImage, setSelectedImage] = useState("");

	const [showOverlay, setShowOverlay] = useState(null);
	/*	const [iconText, setIconText] = useState(false);*/

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const confirmRemove = (imageID) => {
		ApiCall("DELETE", `location/remove_image/${imageID || selectedImage}`)
			.then(() => {
				refreshList(data.location_id);
				clearStates();
			})
			.catch((error) => {
				if (debug) console.log(error);
				clearStates();
			});
	};

	const handleRemove = (event) => {
		setAnchor(event.currentTarget);
		setShowConfirm(true);
	};

	const clearStates = () => {
		setAnchor("");
		setCheckIndex("");
		setSelectedImage("");
		setShowOverlay(null);
	};

	useEffect(() => {
		const getImages = (id) => {
			if (images.length !== 0)
				return images.filter((type) => type.image_type === id);
			else return [];
		};

		if (isLogo)
			setImageFile(data.logo_image); // logo
		else if (isBanner)
			setImageFile(getImages(2)); // banner
		else setImageFile(getImages(4)); // other
	}, [data, images]);

	return (
		<div
			style={{ width: "100%", minWidth: "160px", textAlign: "end" }}
			onMouseEnter={() => clearStates()}
		>
			{!editMode && imageFile !== null && isLogo && (
				<img
					style={{
						width: "220px",
						objectFit: "contain",
						borderRadius: "4px"
					}}
					src={imageFile !== null && imageFile.image}
				/>
			)}

			{!editMode && imageFile !== null && !isLogo && (
				<Grid
					container
					direction="row"
					spacing={0.2}
					alignSelf="center"
					justifyContent="flex-end"
				>
					{!isLogo && isBanner
						? imageFile
								.filter((images) => images.image_type === 2)
								.map((image, i) => (
									<Grid
										item
										key={i}
										sx={{
											width: "100%",
											height: "250px",
											paddingLeft: "5px !important"
										}}
									>
										<img
											style={{
												width: "100%",
												height: "100%",
												objectFit: "cover",
												borderRadius: "4px"
											}}
											src={image !== null && image.image}
										/>
									</Grid>
								))
						: imageFile
								.filter((images) => images.image_type === 4)
								.map((image, index) => (
									<Grid
										xs={6}
										item
										key={index}
										sx={{
											position: "relative"
										}}
										onMouseLeave={() => clearStates()}
										onClick={(event) => {
											handleRemove(event);
											setCheckIndex(index);
											setSelectedImage(image.id);
										}}
									>
										<CardMedia
											onMouseOver={() =>
												setShowOverlay(index)
											}
											component="img"
											image={
												image !== null && image.image
											}
											alt=""
											sx={{
												position: "relative",
												border: "0px solid transparent",
												height: "140px",
												borderRadius: "4px"
											}}
										/>

										{showOverlay === index && (
											<Box
												sx={{
													position: "absolute",
													textAlign: "center",
													bottom: 0,
													left: 0,
													width: "100%",
													height: "100%",
													lineHeight: "100px",
													border: "2px red solid",
													backgroundColor:
														"rgba(0, 0, 0, 0.54)"
													/* "&:hover": { // ripple effekti mobiilinäkymään korvaamaan perus :hover
														background:
															"transparent radial-gradient(circle, transparent 1%, red 1%) center/15000%",
														"&>a": {
															color: "green"
														}
													},
													"&:active": {
														backgroundColor: "red",
														backgroundSize: "100%",
														transition:
															"background 0s"
													} */
												}}
											>
												<Typography
													sx={{
														transform:
															"translateY(150%)" // todo toimii, mutta onko tähän parempaa ratkaisua?
													}}
												>
													{t(
														"location.form_location_delete_image_label"
													)}
													?
												</Typography>
											</Box>
										)}

										{showConfirm &&
											checkIndex === index && (
												<ConfirmAction
													handleTitle={t(
														"location.form_location_delete_image_label"
													)}
													hasButton={true}
													handleConfirmAction={
														confirmRemove
													}
													remoteAnchor={anchor}
												/>
											)}
									</Grid>
								))}
				</Grid>
			)}

			{!editMode && imageFile === null && (
				<div
					style={{
						height: "70px",
						width: "70px",
						marginRight: "30px",
						resizeMode: "contain",
						borderRadius: "4px",
						backgroundColor: "rgba(40,40,40,0.5)"
					}}
				/>
			)}

			{editMode && (
				<ImageUpload
					imageType={isLogo ? "logo" : isBanner ? "banner" : "other"}
					LocationId={data.location_id}
					oldImage={imageFile !== null && imageFile} // todo
					removeImage={confirmRemove}
				/>
			)}
		</div>
	);
};
