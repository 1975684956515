import React from "react";
import { ReactComponent as DeleteIcon } from "../../../Assets/Icons/TrashDeleteIcon.svg";
import { ColorScheme } from "../../../Themes/ColorScheme";

export const DeleteButton = () => (
	/* const [hovered, setHovered] = useState(false);
	 */
	<div
		style={{
			display: "flex",
			height: "36px",
			width: "36px",
			borderRadius: "4px",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer"
		}}
		/* onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)} */
	>
		<DeleteIcon
			width="24px"
			height="24px"
			stroke={ColorScheme.red}
			strokeWidth="1.5px"
		/>
	</div>
);
