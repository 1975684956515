import { ReactComponent as LogIcon } from "../../../Assets/Icons/Log.svg";
import { Typography } from "@mui/material";
import React, { useState } from "react";
import { ColorScheme } from "../../../Themes/ColorScheme";
import TooltipWrapper from "../../Common/TooltipWrapper";
import { useTranslation } from "react-i18next";

export const ChargerLogs = ({
	handleClick,
	strokeColor,
	strokeWidth,
	width,
	height
}) => {
	const { t } = useTranslation();
	const [hover, setHover] = useState(false);

	const iconStyle = {
		position: "relative",
		zIndex: 2,
		transition: "color 0.3s ease, transform 0.3s ease"
	};

	if (hover) {
		iconStyle.backgroundColor = ColorScheme.lighterGrey;
		iconStyle.transform = "scale(1.1)";
	}

	return (
		<div
			style={{
				textAlign: "center",
				position: "relative",
				cursor: "pointer",
				width: "25%"
			}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={(event) => {
				event.stopPropagation();
				handleClick("chargerLogs");
			}}
		>
			<TooltipWrapper title={t("charging.charger_logs_description")}>
				<LogIcon
					stroke={strokeColor}
					width={width}
					height={height}
					strokeWidth={strokeWidth / 2}
					style={iconStyle}
				/>
			</TooltipWrapper>
			<Typography
				style={{
					fontSize: "12px",
					fontWeight: "400",
					color: "grey"
				}}
			>
				{t("buttons.log_events")}
			</Typography>
		</div>
	);
};
