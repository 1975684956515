import React from "react";
import { ReactComponent as ArrowDownIcon } from "../../../Assets/Icons/ArrowDownIcon.svg";

export const SortingArrow = ({ order }) => (
	<ArrowDownIcon
		stroke="black"
		style={{
			marginLeft: "5px",
			transition: "all 0.3s ease",
			transform: `rotate(${order ? 0 : "0.5turn"})`
		}}
	/>
);
