export default function timeDifferenceAsString(date1, date2) {
	const timeDifference = Math.abs(date2 - date1);
	const hours = Math.floor(timeDifference / 3600000); // 1 hour = 3600000 milliseconds
	const minutes = Math.floor((timeDifference % 3600000) / 60000); // 1 minute = 60000 milliseconds

	let result = "";

	if (hours > 0) result += `${hours} h`;

	if (minutes > 0) {
		if (result.length > 0) result += " ";

		result += `${minutes} min`;
	}

	if (result.length === 0) result = "0 min";

	return result;
}
