import {
	Link,
	Stack,
	Typography,
	Switch,
	Box,
	MenuItem,
	TextField
} from "@mui/material";

import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../../ApiContext";
import { SnackContext } from "../../../SnackContext";
import { UserContext } from "../../../UserContext";
import TooltipWrapper from "../../../Common/TooltipWrapper";
import { FormEdit } from "./Components/FormEdit";
import { CustomizeButton } from "./Components/CustomizeButton";
import { FormSave } from "./Components/FormSave";
import { FormCancel } from "./Components/FormCancel";
import { ReactComponent as ArrowDropDownIcon } from "../../../../Assets/Icons/ArrowDownIcon.svg";

export const WebBooking = ({ data, refreshData }) => {
	const [linkCopy, setLinkCopy] = useState(false);
	const [classValue, setClassValue] = useState(0);

	const { ApiCall } = useContext(ApiContext);
	const { showSnackMessage } = useContext(SnackContext);
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	const [editMode, setEditMode] = useState(false);

	const [customerEmailMessage, setCustomerEmailMessage] = useState(
		data.customer_email_message
	);

	const [checked, setChecked] = useState(data.notify_managers);

	const handleLinkCopy = () => {
		navigator.clipboard.writeText(
			"https://booking.tologo.fi/location/" +
				data.location_id +
				(classValue !== 0 ? `?class=${classValue}` : "")
		);

		setLinkCopy(true);
	};

	const handleSave = () => {
		ApiCall("PUT", `location/update/${data.location_id}`, {
			notify_managers: checked,
			customer_email_message: customerEmailMessage
		})
			.then(() => {
				refreshData(data.location_id);
				setEditMode(false);
				showSnackMessage("location_update");
			})
			.catch((error) => {
				// console.log(error);
				showSnackMessage("general", error.response.status);
			});
	};

	const cancelAction = () => {
		setCustomerEmailMessage(data.customer_email_message);
		setChecked(data.notify_managers);
		setEditMode(!editMode);
	};

	const handleCheckboxChange = () => {
		setChecked(!checked);
	};

	useEffect(() => {
		setEditMode(false);
		setChecked(data.notify_managers);
		setCustomerEmailMessage(data.customer_email_message);
	}, [selectedLocation]);

	return (
		<Stack
			direction="row"
			width="100%"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				px: "15px",
				py: "15px",
				boxSizing: "border-box",
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px"
			}}
		>
			<Stack sx={{ width: "100%" }}>
				{!editMode && (
					<Stack
						direction={"row"}
						width={"100%"}
						alignItems={"center"}
						justifyContent={"space-between"}
					>
						<Typography variant="form_text_label">
							{t("location.web_booking_label")}{" "}
						</Typography>

						<TooltipWrapper
							title={
								linkCopy
									? t(
											"location.web_booking_link_copied_tooltip"
										)
									: t(
											"location.copy_web_booking_link_tooltip"
										)
							}
						>
							<Link
								sx={{ cursor: "pointer" }}
								onClick={() => handleLinkCopy()}
								target="_blank"
								rel="noopener"
							>
								<Typography variant="form_text" color="white">
									{t("location.web_booking_link_text")}
									{data.location_id}
									{classValue !== 0
										? `?class=${classValue}`
										: ""}
								</Typography>
							</Link>
						</TooltipWrapper>
					</Stack>
				)}

				<div style={{ height: "20px" }} />

				<Stack
					direction={"row"}
					width={"100%"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<div style={{ width: "100%" }}>
						<Typography variant="form_text_label">
							{t("location.web_booking_direct_class_link")}
						</Typography>
					</div>

					<div
						style={{
							width: "100%",
							/* width: "44%", */
							margin: "auto"
						}}
					>
						<TextField
							select
							value={classValue}
							onChange={(event) =>
								setClassValue(event.target.value)
							}
							variant="standard"
							fullWidth
							sx={{
								color: "white",
								textAlign: "right",
								"& .MuiInput-input": { color: "white" } // Change the color of the text
							}}
							SelectProps={{
								IconComponent: (props) => (
									<ArrowDropDownIcon
										{...props}
										style={{ color: "white" }}
									/>
								) // Move IconComponent inside SelectProps
							}}
							InputProps={{ disableUnderline: true }}
						>
							<MenuItem value={0}>Ei valittu</MenuItem>
							{data.service_classes.map((item) => (
								<MenuItem key={item.id} value={item.id}>
									{item.name_fi}
								</MenuItem>
							))}
						</TextField>
					</div>
				</Stack>

				<div style={{ height: "20px" }} />

				<Stack
					direction={"row"}
					width={"100%"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<div
						style={{
							width: "100%"
						}}
					>
						{/* width to make sure views look the same */}
						<Typography variant="form_text_label">
							{t(
								"location.send_notification_on_new_reservations"
							)}
							:
						</Typography>
					</div>
					<div
						style={{
							// width: "50%",
							margin: "auto"
						}}
					>
						<Switch
							checked={checked}
							onChange={
								editMode ? handleCheckboxChange : () => {}
							}
							name="group"
						/>
					</div>
				</Stack>
				<Stack
					direction={"row"}
					width={"100%"}
					alignItems={"flex-start"}
					justifyContent={"space-between"}
					sx={{ marginTop: "50px" }}
				>
					{!editMode && (
						<div
							style={{
								width: "100%"
							}}
						>
							<Typography variant="form_text_label">
								{t("location.customer_email_message_label")}:
							</Typography>
						</div>
					)}
					<Stack
						style={{
							width: "100%",
							display: !editMode && "flex",
							flexDirection: !editMode && "row",
							justifyContent: !editMode && "flex-end"
						}}
					>
						<Typography variant="form_text_desc">
							{editMode ? (
								<FormEdit
									title={t(
										"location.form_customer_email_message_label"
									)}
									value={customerEmailMessage}
									type={"text"}
									isMultiRow={true}
									hasCharLimit={true}
									changeData={(value) =>
										setCustomerEmailMessage(value)
									}
								/>
							) : customerEmailMessage === "" ? (
								t("location.customer_email_message_empty")
							) : (
								customerEmailMessage
							)}
						</Typography>
					</Stack>
				</Stack>
				{editMode && (
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "flex-end"
						}}
					>
						<FormSave handleSave={handleSave} />
						<FormCancel cancelAction={cancelAction} />
					</Box>
				)}
			</Stack>
			{!editMode && (
				<CustomizeButton
					changeEditMode={() => setEditMode(!editMode)}
				/>
			)}
		</Stack>
	);
};
