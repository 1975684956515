// hardcoded user limits for the user amount selection

export const KeyUserAmount = [
	{ value: 50 },
	{ value: 100 },
	{ value: 150 },
	{ value: 200 },
	{ value: 400 },
	{ value: 600 },
	{ value: 800 },
	{ value: 1000 },
	{ value: 10000 }
];
