/* eslint-disable indent */
import React, { createContext, /* useEffect */ useState } from "react";
// import { useLocation } from "react-router-dom";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	// const navigate = useLocation();
	const [selectedLocation, setSelectedLocation] = useState({});
	const [title, setTitle] = useState("");
	const [user, setUser] = useState({});
	const [userLanguage, setUserLanguage] = useState("");

	const handleTitle = (title) => {
		setTitle(title);
	};

	const changeLanguage = (lang) => {
		setUserLanguage(lang);
	};

	/* useEffect(() => {
		if (selectedLocation.name === undefined) {
			navigate(`/${selectedLocation.id}`);
			console.log("Location changed " + selectedLocation.name);
		}
		console.log(selectedLocation);
	}, [selectedLocation]); */

	return (
		<UserContext.Provider
			value={{
				title,
				handleTitle,
				selectedLocation,
				setSelectedLocation,
				user,
				setUser,
				changeLanguage,
				userLanguage
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
