import { Box, Grid, Stack, Typography } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import uuid from "react-uuid";
import { ColorScheme } from "../../Themes/ColorScheme";
import { UserContext } from "../UserContext";
import { ApiContext } from "../ApiContext";
import { ReactComponent as CheckmarkIcon } from "../../Assets/Icons/Checkmark.svg";
import { ReactComponent as CrossIcon } from "../../Assets/Icons/Cross.svg";
import { useTranslation } from "react-i18next";

export const Invitation = ({ id, invite, refresh }) => {
	const debug = false;

	const [location, setLocation] = useState();

	const { ApiCall } = useContext(ApiContext);

	useState(() => {
		ApiCall("GET", `location/${id}`, null).then((res) => setLocation(res));
	}, []);

	const accept = () => {
		ApiCall("PUT", "door/update_access", {
			access_ends_at: new Date(),
			granted: "true",
			id: invite.id + "",
			start_time: new Date(),
			end_time: new Date(),
			weekdays: new Date(),
			access_starts_at: new Date(),
			restrict_times: "false"
		})
			.then(() => refresh())

			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const decline = () => {
		ApiCall("PUT", "door/update_access", {
			access_ends_at: new Date(),
			granted: "false",
			id: invite.id + "",
			start_time: new Date(),
			end_time: new Date(),
			weekdays: new Date(),
			access_starts_at: new Date(),
			restrict_times: "false"
		})
			.then(() => refresh())
			.catch((error) => console.log(error));
	};

	return (
		<Grid
			item
			textAlign="left"
			sx={{
				color: "#000000",
				borderRadius: "4px",
				flexDirection: "row",
				display: "flex",
				justifyContent: "space-between",
				border: "1px solid " + ColorScheme.tolotechDarkBlue,
				padding: "10px",
				marginBottom: "10px",
				alignItems: "center"
			}}
		>
			<Stack sx={{ flex: 1 }}>
				<Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
					{invite.name}
				</Typography>
				<Typography sx={{ fontSize: "10px", color: "#48526B" }}>
					{invite.email}
				</Typography>
				<Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
					{location !== undefined && location.name + ", "}
					{invite.door}
				</Typography>
			</Stack>

			<Box
				sx={{
					alignItems: "flex-end",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<Stack
					style={{ justifyContent: "space-between", display: "flex" }}
					direction="row"
				>
					<Box
						onClick={() => accept(invite.manager_id)}
						sx={{
							width: "25px",
							height: "25px",
							borderRadius: "4px",
							backgroundColor: ColorScheme.tolotechBlue,
							border: "1px solid" + ColorScheme.tolotechBlue,
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							marginRight: "5px",
							cursor: "pointer"
						}}
					>
						<CheckmarkIcon stroke="white" />
						{/* fixes invisible icon */}
						{/* <img
							style={{
								height: "15px",
								width: "15px"
							}}
							src={CheckmarkIcon}
						/> */}
					</Box>
					<Box
						onClick={() => decline(invite.manager_id)}
						style={{
							width: "25px",
							height: "25px",
							borderRadius: "4px",
							backgroundColor: "transparent",
							border: "1px solid" + ColorScheme.tolotechBlue,
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							cursor: "pointer"
						}}
					>
						<CrossIcon stroke="black" />
						{/* <img style={{ height: "15px" }} src={CrossIcon} /> */}
					</Box>
				</Stack>
			</Box>
		</Grid>
	);
};

export const ListRequests = () => {
	const { handleTitle } = useContext(UserContext);
	const [invites, setInvites] = useState([]);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const refresh = () => {
		ApiCall("GET", "client/access_requests").then((res) => setInvites(res));
	};

	useEffect(() => {
		handleTitle(t("common.messages"));
		refresh();
	}, []);

	return (
		<Box
			sx={{
				width: "100%"
			}}
		>
			{invites.length > 0 && (
				<Box
					sx={{
						width: "inherit",
						borderRadius: "4px",
						marginBottom: "40px"
					}}
				>
					<Typography
						sx={{
							marginBottom: "20px",
							fontSize: "18px",
							fontWeight: "700"
						}}
					>
						{t(invites.access_right_request)}
					</Typography>
					<Stack style={{ marginLeft: "0px" }}>
						<Stack style={{ marginBottom: "15px" }}>
							{invites.length === 0 && (
								<Typography
									style={{
										fontSize: "14px",
										marginBottom: "15px"
									}}
								>
									{t(invites.requests_not_found)}
								</Typography>
							)}
							{invites.map((invite) => (
								<Invitation
									refresh={refresh}
									key={uuid()}
									id={invite.location_id}
									invite={invite}
								/>
							))}
						</Stack>
					</Stack>
				</Box>
			)}
		</Box>
	);
};
