import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ApiContext } from "../../ApiContext";
import { UserContext } from "../../UserContext";
import { useTranslation } from "react-i18next";
import { StyledLinkButton } from "./StyledLinkButton";

import TolotechLogo from "../../../Assets/Images/tolotech_logo_white_new_text.png";

export const SidebarMenu = () => {
	const debug = false;

	const { selectedLocation } = useContext(UserContext);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const location = useLocation();

	const [newNotification, setNewNotification] = useState(false);
	const [hideContent, setHideContent] = useState(false);

	useEffect(() => {
		// check billing info
		if (
			selectedLocation.billing?.business_id === undefined ||
			selectedLocation.billing?.customer_name === undefined ||
			selectedLocation.billing?.invoice_address === undefined ||
			selectedLocation.billing?.invoice_operator === undefined ||
			selectedLocation.billing?.contact_name === undefined ||
			selectedLocation.billing?.contact_email === undefined ||
			selectedLocation.billing?.business_id === null ||
			selectedLocation.billing?.customer_name === null ||
			selectedLocation.billing?.invoice_address === null ||
			selectedLocation.billing?.invoice_operator === null ||
			selectedLocation.billing?.contact_name === undefined ||
			selectedLocation.billing?.contact_email === undefined
		)
			setHideContent(true);
		else setHideContent(false);
		//

		setNewNotification(false);

		ApiCall("GET", "client/access_requests")
			.then((response) => {
				if (response.length > 0)
					response.map((entry) => {
						if (entry.location_id === selectedLocation.location_id)
							setNewNotification(true);
					});
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	}, [selectedLocation]);

	// console.log(selectedLocation.location_permissions);

	return (
		<div
			style={{
				height: "100vh",
				width: "180px",
				alignItems: "center",
				justifyContent: "center",
				display: "flex"
			}}
		>
			<Box
				sx={{
					height: "95%",
					width: "80%",
					left: "50px",
					top: "5%",
					backgroundColor: "#264166",
					color: "#FFFFFF",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
					borderRadius: "8px",
					alignItems: "center",
					overflowY: "scroll",
					scrollbarWidth: "none"
				}}
			>
				<Typography
					style={{
						fontSize: 24,
						textAlign: "center",
						paddingTop: "35px",
						fontWeight: "600"
					}}
				>
					{"ToloGo"}
				</Typography>

				<Grid
					container
					sx={{
						margin: "auto",
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
						flex: 1,
						py: 5
					}}
				>
					{/* Location stats */}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="overview"
								title={t("common.main_page")}
								isBlocked={hideContent}
							/>
						)}
					<StyledLinkButton
						current_path={location.pathname.split("/")[1]}
						path="locations"
						title={t("common.locations")}
					/>
					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 1
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="doors"
								title={t("common.doors")}
								hasBadge={newNotification}
								isBlocked={hideContent}
							/>
						)}

					{/* Charging */}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 7 // 7
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="charging"
								title={t("common.charging")}
								isBlocked={hideContent}
							/>
						)}

					{/* Key management, if location has both 2 and 8 permissions we will only show this and not both menu links */}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 8
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path={"residents"}
								title={t("common.calendar")}
								isBlocked={hideContent}
							/>
						)}

					{/* Reservable spaces / calendar */}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 8
						) === undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path={"calendar"}
								title={t("common.calendar")}
								isBlocked={hideContent}
							/>
						)}

					{/* Customers */}

					{selectedLocation.location_permissions !== undefined &&
						(selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined ||
							selectedLocation.location_permissions.find(
								(obj) => obj.permission_id === 7
							) !== undefined) && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="customers"
								title={t("common.customers")}
								isBlocked={hideContent}
							/>
						)}

					{/* Courses */}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="courses"
								title={t("common.courses")}
								isBlocked={hideContent}
							/>
						)}

					{/* Campaigns */}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="campaigns"
								title={t("common.campaigns")}
								isBlocked={hideContent}
							/>
						)}

					{selectedLocation.location_permissions !== undefined &&
						selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 10
						) !== undefined && (
							<StyledLinkButton
								current_path={location.pathname.split("/")[1]}
								path="balances"
								title={t("common.balances")}
								isBlocked={hideContent}
							/>
						)}

					<StyledLinkButton
						current_path={location.pathname.split("/")[1]}
						path="support"
						title={t("common.support")}
					/>

					<div style={{ flex: 1 }} />

					{/*	<StyledLinkButton
						current_path={location.pathname.split("/")[1]}
						path="contact"
						title={t("common.contact")}
					/> */}

					<img
						src={TolotechLogo}
						style={{
							maxWidth: "100px",
							paddingTop: "25px"
						}}
					/>
					<Typography variant="caption">
						{"© " + new Date().getFullYear()}
					</Typography>
				</Grid>
			</Box>
		</div>
	);
};
