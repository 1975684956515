import React from "react";
import { UserCard } from "./UserCard";
import { Stack, Typography } from "@mui/material";
import { AlertBar } from "./AlertBar";
import { UserEdit } from "./UserEdit";
import { useTranslation } from "react-i18next";

export const UserListHandler = ({
	showList,
	showFailed,
	userList,
	listFailed,
	removeUser,
	editFailedList,
	removeFailedUser,
	toggleAlert = true // Default true to render always render alert bar, pass false from parent if you want to disable it
}) => {
	const { t } = useTranslation();

	const errorText = (
		<>
			<Typography fontWeight={900}>
				{t("campaign.failed_entries")}
			</Typography>
			<Typography fontSize={"12px"}>
				{t("campaign.failed_entries_help")}
			</Typography>
		</>
	);

	return (
		<div>
			{showFailed && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						justifyContent: "left"
					}}
				>
					<AlertBar
						textContent={errorText}
						showAlert={toggleAlert}
						type="info"
					/>
				</div>
			)}

			{/* {userList.length > 0 && (
				<div style={{ marginTop: "20px" }}>
					<Typography>
						{t("courses.following_users_will_be_added")}
					</Typography>
				</div>
			)} */}

			{showList &&
				userList.map((entry, index) => (
					<UserCard
						key={index}
						user={entry}
						index={index}
						users={userList}
						handleRemove={removeUser}
					/>
				))}

			{showFailed && (
				<Stack
					sx={{ width: "100%" }}
					direction="column"
					justifyContent="space-between"
				>
					{listFailed.map((entry, index) => (
						<UserEdit
							key={index}
							entry={entry}
							index={index}
							removeFailedUser={removeFailedUser}
							editFailedList={editFailedList}
						/>
					))}
				</Stack>
			)}
		</div>
	);
};
