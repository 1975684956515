import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";

import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";

import { UserCard } from "../Common/UserCard";
import { UserAdd } from "../Common/UserAdd";

import { UserListHandler } from "../Common/UserListHandler";

export const CourseUserList = ({ data }) => {
	const debug = false;

	const [users, setUsers] = useState([]);
	const [userEmail, setUserEmail] = useState("");
	const [emailList, setEmailList] = useState([]);
	/* const [showLoader, setShowLoader] = useState(false); */
	const [showWarning, setShowWarning] = useState(false);
	const [showError, setShowError] = useState(false);
	/* const [isLoading, setIsLoading] = useState(true); */

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const getUsers = () => {
		/* setIsLoading(true); */
		ApiCall("GET", `client/course/${data.id}/users`)
			.then((res) => {
				if (debug) console.log(res);
				/* setShowLoader(false); */
				setUsers(res);
				/* setIsLoading(false); */
			})
			.catch((error) => {
				setShowError(true);
				if (debug) console.log(error);
				/* setShowLoader(false); */
			});
	};

	const handleSave = () => {
		/* setShowLoader(true); */

		if (emailList.length > 0) {
			const promises = emailList.map((entry) =>
				ApiCall("POST", `client/course/${data.id}/user`, {
					/* campaign_id: 0,*/
					course_id: data.id,
					email: entry
				})
					.then(() => {
						setEmailList(
							emailList.filter((email) => email !== entry)
						);
					})
					.catch((error) => {
						setShowError(true);
						if (debug) console.log(error);
					})
			);

			Promise.all(promises)
				.then(() => {
					getUsers();
				})
				.catch((error) => {
					if (debug) console.log(error);
				});
		} else {
			ApiCall("POST", `client/course/${data.id}/user`, {
				/* campaign_id: 0,*/
				course_id: data.id,
				email: userEmail
			})
				.then(() => {
					getUsers();
				})
				.catch((error) => {
					/* setShowLoader(false); */
					setShowError(true);
					if (debug) console.log(error);
				});
		}
	};

	/* const addAnotherUser = () => {
		setEmailList((prevState) => [...prevState, userEmail]);
		setUserEmail("");
	}; */

	const removeUserEmail = (user) => {
		const filtered = emailList.filter((entry) => entry !== user);

		setEmailList(filtered);
	};

	const handleRemove = (email) => {
		/* setShowLoader(true); */

		ApiCall("DELETE", `client/course/${data.id}/user`, {
			email
		})
			.then(() => {
				/* setShowLoader(false); */
				getUsers();
			})
			.catch((error) => {
				/* setShowLoader(false); */
				if (debug) console.log(error);
			});
	};

	const checkDuplicates = (text) => {
		const check = users.find((entry) => entry.email === text);

		setShowWarning(check);
	};

	/* const checkDisabled = () => emailList.length === 0; */

	const editFailedList = (index, newValue) => {
		setEmailList((prevList) => {
			const updatedList = [...prevList];
			updatedList[index] = newValue;
			return updatedList;
		});
	};

	const handleChange = (event) => {
		checkDuplicates(event);
		setUserEmail(event);
	};

	useEffect(() => {
		if (emailList.length === 0) setShowError(false);
	}, [emailList]);

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<Box>
			<Stack spacing={2}>
				{/* <DrawerTitle
					location={selectedLocation.name}
					title={t("courses.course_add_new_user_label")}
				/> */}

				{/* <Typography variant="h5">{data.name}</Typography> */}

				<div style={{ width: "100%" }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}}
					>
						<UserAdd
							contact={userEmail}
							placeholder={t("location.form_email_label")}
							onlyEmail={true}
							handleContactChange={handleChange}
							handleAnotherUser={handleSave}
							userExists={showWarning}
							showError={showWarning}
							errorMessage={t("courses.found_registered_email")}
							/* showError={showError} */
						/>

						<UserListHandler
							showList={true}
							showFailed={showError}
							userList={!showError ? emailList : []}
							listFailed={showError ? emailList : []}
							removeUser={removeUserEmail}
							removeFailedUser={removeUserEmail}
							editFailedList={editFailedList}
						/>
						{/* {emailList.map((entry, index) => (
							<div
								key={index}
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									gap: "5px",
									marginLeft: "5px"
								}}
							>
								<TextField
									size="small"
									fullWidth
									disabled={!showError}
									value={entry}
									variant="standard"
									sx={{
										borderRadius: "4px"
										/* border: showError
											? "1px solid red"
											: undefined 
									}}
									onChange={(event) => {
										if (showError) {
											// handle onChange only if showError is true
											const updatedEmailList = [
												...emailList
											];
											updatedEmailList[index] =
												event.target.value;
											setEmailList(updatedEmailList);
										}
									}}
								/>

								<ConfirmAction
									handleTitle={t(
										"courses.course_remove_user_from_list"
									)}
									isIconButton={true}
									handleConfirmAction={() =>
										removeUserEmail(entry)
									}
								/>
							</div>
						))} */}

						{/* {isLoading ? (
							<div style={{ marginTop: "20px" }}>
								<Spinner />
							</div>
						) : (
							emailList.length > 0 && (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										gap: "1px",
										marginTop: "20px",
										marginBottom: "30px"
									}}
								>
									<div style={{ flex: 1 }} />
									<Button
										variant={
											isMobile ? undefined : "contained"
										}
										disabled={
											emailList.length === 0 ||
											userEmail !== ""
										}
										onClick={() => addUser()}
										sx={
											isMobile
												? {
														backgroundColor:
															ColorScheme.tolotechBlue,
														color: "white"
												  }
												: undefined
										}
									>
										<Typography>
											<SaveAnimationButton
												loadingButton={showLoader}
												textContent="buttons.save_users"
											/>
										</Typography>
									</Button>
								</div>
							)
						)} */}

						{/* <Button
							variant="contained"
							sx={{ width: "100%" }}
							disabled={checkDisabled()}
							onClick={handleSave}
						>
							<SaveAnimationButton
								loadingButton={isLoading}
								textContent="buttons.save_users"
							/>
						</Button> */}
					</div>
				</div>

				<div style={{ height: "20px" }} />

				{users.length > 0 && (
					<Typography variant="form_bold" fontSize={"16px"}>
						{t("courses.course_users_registered")}
					</Typography>
				)}

				{users.length > 0 && (
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							boxSizing: "border-box",
							borderRadius: "4px",
							width: "100%",
							margin: "auto",
							border: "1px solid #F0F0F0",
							backgroundColor: "#FAFAFA",
							marginTop: "20px"
						}}
					>
						{users.map((user, index) => (
							<>
								<UserCard
									index={index}
									user={user}
									users={users}
									handleRemove={handleRemove}
								/>
								{index !== users.length - 1 && (
									<div
										style={{
											height: "1px",
											width: "100%",
											backgroundColor: "#F0F0F0"
										}}
									></div>
								)}
							</>
						))}
					</Box>
				)}
			</Stack>
		</Box>
	);
};
