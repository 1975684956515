export const ColorScheme = {
	bg: "#1E2D43",
	tolotechBlue: "#1C568F",
	tolotechDarkBlue: "#264166",
	tolotechLightBlue: "#6281AB",
	tolotechWhite: "rgba(255,255,255,1)",
	tolotechTransparentWhite: "#FFFFFF33",
	tolotechGray: "rgba(255,255,255,0.5)",
	disabled: "#dddddd",
	green: "#60CBB1",
	brightGreen: "#00ff00",
	darkGreen: "#508A35",
	bgGreen: "#DCE9D6",
	red: "#C22020",
	darkRed: "#C53939",
	purple: "#995CB6",
	softRed: "#CD8B8B",
	softBlue: "#8BCDCD",
	transparentBlue: "#1E3452",
	yellow: "#FFBD33",
	tolotechAlt: "#48526B",
	lightGrey: "#D9D9D9",
	lighterGrey: "#D9D9D933",
	brightGrey: "#7D8DA3",

	endingSoon: "#FFE4AD",
	pending: "#d4d9e0",
	pending_icon: "#516785",

	drawerTitle: "#296DAE",

	// Main colors for buttons, more in theme.js
	buttonEnabled: "#296DAE",
	buttonDisabled: "#296DAE40",
	buttonCancel: "#FFFFFF",
	buttonCancelOutline: "#296DAE",
	buttonPressed: "#264166",

	// Main colors for textField
	textFieldBackground: "#FAFAFA",
	textFieldBorder: "#D9D9D9",
	textFieldPlaceholder: "#C6C6C6",
	textFieldInputText: "#1E1E1E",
	textFieldActiveBorder: "#296DAE",

	// icon colors
	defaultIconColor: "rgba(0,0,0,0.54)"
};
