import { Button, Collapse, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ColorScheme } from "../../Themes/ColorScheme";

import { DatePicker } from "@mui/x-date-pickers";
import { ApiContext } from "../ApiContext";
import { isDesktop } from "react-device-detect";
import { AlertBar } from "../Common/AlertBar";
import { ConfirmAction } from "../Common/ConfirmAction";
import { useTranslation } from "react-i18next";

export const UserBalanceSheet = ({ data, balance, expires, closeDialog }) => {
	/* const numberButtons = [0.5, 1, 2, 5, 10, 20, 50, 100]; */

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const [expirationDate, setExpirationDate] = useState(new Date(expires));
	const [disableSave, setDisableSave] = useState(true);
	const currentBalance = balance || 0;
	const [addBalance, setaAdBalance] = useState(0);

	const [showError, setShowError] = useState("");

	const handleBalanceAccountDelete = () => {
		ApiCall("DELETE", `client/balance/${data?.balances[0]?.id}`)
			.then(() => {
				// console.log(res);
				setShowError("");
				closeDialog();
			})
			.catch((err) => setShowError(err));
	};

	const changeaddBalance = (event) => {
		setaAdBalance(event.target.value);
	};

	const handleKeyPress = (event) => {
		const inputChar = String.fromCharCode(event.charCode);
		const isNumber = /^\d+$/.test(inputChar);
		const hasDecimal = event.target.value.includes(".");

		// Allow only numbers and a maximum of 2 decimals
		if (!isNumber || (inputChar === "." && hasDecimal)) {
			event.preventDefault();
		}
	};

	const handleDateChange = (date) => {
		setExpirationDate(date);
	};

	const body = {
		amount: currentBalance + Number(addBalance),
		email: data?.email,
		type: 5,
		expires_at: expirationDate.toISOString()
	};

	const saveChanges = () => {
		// console.log("Tallennetaan saldoa");

		ApiCall("PUT", `client/balance/${data?.balances[0]?.id}`, body)
			.then(() => {
				setShowError("");
				closeDialog();
			})
			.catch((err) => setShowError(err));
	};

	useEffect(() => {
		if (new Date(expirationDate).getTime() !== new Date(expires).getTime())
			return setDisableSave(false);
		else if (addBalance !== 0 && addBalance !== "" && addBalance !== "-")
			return setDisableSave(false);
		else return setDisableSave(true);
	}, [expirationDate, addBalance]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				gap: "20px",
				rowGap: "20px"
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "20px"
				}}
			>
				<div style={{ /* flex: 1, */ width: "50%" }}>
					<Typography
						sx={{
							color: "black",
							fontSize: "14px",
							fontWeight: "300"
						}}
					>
						{t("balances.adjust_expiration_date")}
					</Typography>
				</div>

				<div
					style={{
						width: "50%",
						justifyContent: "end",
						display: "flex"
					}}
				>
					<DatePicker
						value={expirationDate}
						/* slots={{openPickerIcon: CalendarIcon}} */

						onChange={handleDateChange}
						disablePast={true}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={t(
									"balances.input_expiration_date"
								)}
								/* size="small" */

								sx={{
									textAlign: "center",
									width: "100%",
									"& fieldset": {
										border: isDesktop
											? "none"
											: "1px solid #ccc"
									},
									"& .MuiOutlinedInput-input": {
										textAlign: isDesktop ? "end" : "center",
										position: "relative",
										right: isDesktop ? "15px" : "0px"
									},
									"& .MuiInputBase-root": {
										paddingRight: "5px"
									},
									"& .MuiFormControl-root": {
										width: "100%"
									}
								}}
							/>
						)}
					/>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: isDesktop ? "baseline" : "center",
					height: "70px"
				}}
			>
				<div style={{ /* flex: 1 */ width: "50%" }}>
					<Typography
						sx={{
							color: "black",
							fontSize: "14px",
							fontWeight: "300"
						}}
					>
						{t("balances.adjust_balance")}
					</Typography>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						// flexWrap: "wrap",
						width: /* "calc(4 * 37px)" */ "47%",
						borderRadius: "4px",
						overflow: "hidden",
						gap: "10px",
						alignItems: isDesktop ? "baseline" : "center"
					}}
				>
					<div
						style={{
							display: "flex",
							width: "100%",
							flexDirection: "column",
							justifyContent: "center"
							/* alignItems: "center" */
						}}
					>
						<TextField
							variant={"outlined"}
							/* size="small" */
							fullWidth
							placeholder={t("balances.input_balance_to_adjust")}
							onChange={changeaddBalance}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									event.preventDefault();
									handleKeyPress(event);
								}
							}}
							type="tel"
							inputProps={{
								maxLength: 8, // You can adjust this based on your requirements
								pattern: "[0-9]*,",
								inputMode: "numeric",
								style: { textAlign: "left" }
							}}
						/>

						<div style={{ paddingLeft: "10px" }}>
							<Typography
								display={
									!isNaN(addBalance) &&
									(addBalance === 0 ||
										addBalance === "" ||
										addBalance === "-")
										? "none"
										: "block"
								}
								color={
									addBalance < 0
										? ColorScheme.red
										: ColorScheme.green
								}
								sx={{
									fontSize: "18px",
									fontWeight: "300",
									marginTop: "5px"
								}}
							>
								{/* {Math.sign(addBalance) === 1 ? "+" : ""} */}
								{!isNaN(Number() + Number(addBalance)) &&
									Number(currentBalance) +
										Number(addBalance) +
										"€"}
							</Typography>
						</div>
					</div>
					{/* <div
						style={{
					
							borderRadius: "25px",
							width: "25px",
							height: "25px",
							minWidth: "25px",
							minHeight: "25px",
							display: "flex",
							justifyContent: "center",
							alignItems: "end",
							fontWeight: "800",
							fontSize: "20px",
							color: "#fff",
							backgroundColor:
								addBalance === 0
									? ColorScheme.disabled
									: ColorScheme.tolotechBlue,
							cursor: "pointer",
							userSelect: "none"
						}}
						onClick={() =>
							setcurrentBalance(
								currentBalance + Number(addBalance)
							)
						}
					>
						
						&#65291;
					</div> */}
				</div>
			</div>

			<div
				style={{
					borderBottom: "1px solid #ccc"
				}}
			/>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					boxSizing: "border-box",
					gap: "20px"
				}}
			>
				<div
					style={{
						width: "50%"
					}}
				>
					<Typography
						sx={{
							color: "black",
							fontSize: "14px",
							fontWeight: "300"
						}}
					>
						{t("balances.delete_balance")}
					</Typography>
				</div>

				<div
					style={{
						width: "10%", // ugly fix
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end"
					}}
				>
					<ConfirmAction
						fullWidth
						hasChild
						handleConfirmAction={handleBalanceAccountDelete}
						isIconButton={true}
						useTrashIcon={true}
					/>
				</div>
			</div>

			<Collapse in={showError !== ""}>
				<AlertBar
					textContent={showError}
					showAlert={showError !== ""}
					type="error"
				/>
			</Collapse>

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					width: "100%",
					justifyContent: "space-between",
					gap: "20px"
				}}
			>
				<Button
					variant="outlined"
					sx={{ width: "100%" }}
					onClick={() => closeDialog()}
				>
					{t("buttons.cancel")}
				</Button>
				<Button
					variant="contained"
					disabled={disableSave}
					sx={{ width: "100%" }}
					onClick={saveChanges}
				>
					{t("buttons.save")}
				</Button>
			</div>
		</div>
	);
};
