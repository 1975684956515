import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Typography
} from "@mui/material";
// import moment from "moment";
import { isDesktop, isMobile } from "react-device-detect";
import { startOfMonth } from "date-fns";
import { ColorScheme } from "../../../Themes/ColorScheme";
import ScreenWidth from "../../../Helpers/ScreenWidth";
import { useTranslation } from "react-i18next";

import { DateRangePicker } from "../../Common/DateRangePicker";

export const ReportWidget = ({
	title,
	buttonText,
	handleData,
	hasTaxSelection,
	hasSalesSelection,
	isDetailed
}) => {
	const { t } = useTranslation();

	const [begin, setBegin] = useState("");
	const [end, setEnd] = useState("");
	const [taxValue, setTaxValue] = useState("");
	const [saleValue, setSaleValue] = useState("");

	const taxValues = [10, 14, 24, 25.5];
	const stringsForSale = [
		{ type: 2, name: t("report.export_report_label_reservations") },
		{ type: 3, name: t("report.export_report_label_products") },
		{ type: 4, name: t("report.export_report_label_courses") },
		{ type: 5, name: t("report.export_report_label_charging") }
	];

	const handleClick = () => {
		handleData({
			begin,
			end,
			...(hasTaxSelection && { taxValue }),
			...(hasSalesSelection && { type: saleValue }),
			...(isDetailed && { isDetailed })
		});
	};

	const handleChange = (props) => {
		if (hasTaxSelection) setTaxValue(props.target.value);
		if (hasSalesSelection) setSaleValue(props.target.value);
	};

	const handleDates = (dates) => {
		setBegin(dates[0]);
		setEnd(dates[1]);
	};

	useEffect(() => {
		if (begin === "") setBegin(startOfMonth(new Date()));
		if (end === "") setEnd(new Date());
		if (taxValue === "") setTaxValue(24);
		if (saleValue === "") setSaleValue(2);
	}, []);

	return (
		<Card
			elevation={0}
			sx={{
				width: "100%",
				minWidth: isMobile ? "100px" : undefined,
				/* maxWidth: !isMobile ? "250px" : undefined, */
				padding: "25px",
				borderRadius: "4px",
				backgroundColor: ColorScheme.tolotechBlue,
				boxSizing: "border-box",
				marginTop: ScreenWidth(1525) ? "10px" : undefined,
				overflow: "visible"
			}}
		>
			<Stack
				direction={isMobile || ScreenWidth(1525) ? "column" : "row"}
				justifyContent="space-between"
				alignItems={
					!isMobile && ScreenWidth(1525) ? "normal" : "center"
				}
				spacing={
					isMobile ||
						hasTaxSelection ||
						hasSalesSelection ||
						ScreenWidth(1525)
						? 1
						: 4
				}
				sx={{ width: "100%", height: "100%" }}
			>
				<Typography
					sx={{ minWidth: !isMobile && "125px", color: "white" }}
				>
					{title}
				</Typography>

				<Stack
					direction={isMobile || ScreenWidth(1525) ? "column" : "row"}
					spacing={2}
					sx={{
						boxSizing: "border-box",
						width: isMobile ? "100%" : undefined,
						height: "100%"
					}}
				>
					{(hasTaxSelection || hasSalesSelection) && (
						<Select
							size="small"
							value={hasTaxSelection ? taxValue : saleValue}
							onChange={handleChange}
							fullWidth={isMobile}
							sx={{
								maxHeight: "56px",
								minWidth:
									!isMobile && hasTaxSelection
										? "82px"
										: "160px",
								textAlign: "center"
							}}
						>
							{hasTaxSelection &&
								taxValues.map((entry, index) => (
									<MenuItem key={index} value={entry}>
										<ListItemText
											style={{
												textAlign: "center",
												paddingLeft: isDesktop
													? undefined
													: "25px" // counter arrow width
											}}
										>
											{entry.toString().replace(".", ",")} %
										</ListItemText>
									</MenuItem>
								))}

							{hasSalesSelection &&
								stringsForSale.map((entry, index) => (
									<MenuItem key={index} value={entry.type}>
										<ListItemText
											style={{ textAlign: "center" }}
										>
											{entry.name}
										</ListItemText>
									</MenuItem>
								))}
						</Select>
					)}

					<DateRangePicker handleDateData={handleDates} />

					<Button
						variant="contained"
						sx={{
							backgroundColor: "#4796E3",
							minWidth:
								isMobile || ScreenWidth(1525)
									? "100%"
									: "120px",
							maxWidth:
								isMobile || ScreenWidth(1525)
									? undefined
									: "120px",
							width: ScreenWidth(1525) ? "100%" : undefined,
							height: "56px",
							boxSizing: "border-box"
						}}
						disabled={
							new Date(end).getTime() < new Date(begin).getTime()
						}
						onClick={() => handleClick()}
					>
						{buttonText}
					</Button>
				</Stack>
			</Stack>
		</Card>
	);
};
