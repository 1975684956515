import React from "react";
import "../Themes/LandingAnimation.css"; // Assuming you have a CSS file named LandingAnimation.css
import { isDesktop } from "react-device-detect";

class LandingAnimation extends React.Component {
	render() {
		return (
			<div
				className="landing-animation"
				style={{ width: isDesktop ? undefined : "100%" }}
			>
				{this.props.children}
			</div>
		);
	}
}

export default LandingAnimation;
