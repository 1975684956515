import { CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useContext } from "react";

import { SubmitButton } from "./SubmitButton";
import { ApiContext } from "../ApiContext";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ValidationForm = ({ userEmail, switchComponent }) => {
	const [validationId, setValidationId] = useState("");
	const [validating, setValidating] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	function deleteAllCookies() {
		let cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++)
			// let cookie = cookies[i];
			// let eqPos = cookie.indexOf("=");
			// let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie =
				"webapp-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
	}

	const handleSuccessfulLogin = async (response) => {
		await deleteAllCookies();
		document.cookie = "webapp-token=" + response.access_token + "; path=/;";
		navigate("/"); // triggers launchInit.js
	};

	const { ApiCall } = useContext(ApiContext);

	const validateNewAccount = (e) => {
		setValidating(true);
		e.preventDefault();

		ApiCall("POST", "code_login", {
			email: userEmail,
			code: validationId
		})
			.then((response) => {
				switchComponent("");
				handleSuccessfulLogin(response);
				setValidating(false);
			})
			.catch(() => {
				setValidationId("");
				setValidating(false);
			});
	};

	const validateCode = (code) => {
		const isValid = code.length === 4 && /^\d+$/.test(code);
		return isValid;
	};

	return (
		<form onSubmit={validateNewAccount} style={{ width: "100%" }}>
			<Stack spacing={3}>
				<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
					{t("login.account_activation_label")}
				</Typography>

				<TextField
					placeholder={t("login.account_validation_code")}
					sx={{
						backgroundColor: "#FAFAFA",
						borderRadius: "5px"
					}}
					id="validation"
					variant="outlined"
					margin="dense"
					fullWidth={true}
					size="small"
					value={validationId}
					onChange={(e) => setValidationId(e.target.value)}
				/>

				<Stack
					style={{ justifyContent: "center", alignItems: "center" }}
				>
					{!validating ? (
						<Stack style={{ width: "100%" }}>
							<SubmitButton
								text={t("buttons.send")}
								disabled={!validateCode(validationId)}
							/>
						</Stack>
					) : (
						<CircularProgress color="info" />
					)}
				</Stack>
			</Stack>
		</form>
	);
};
