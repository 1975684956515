import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext } from "react";
import { theme } from "../../Themes/Theme";
import { MultiChip } from "../Common/MultiChip";
import { PlaceholderSkeleton } from "../Common/PlaceholderSkeleton";
import { TextfieldPicker } from "../Common/TextfieldPicker";

import { ReactComponent as CalendarStartIcon } from "../../Assets/Icons/CalendarFromIcon.svg";
import { ReactComponent as CalendarEndIcon } from "../../Assets/Icons/CalendarToIcon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop } from "react-device-detect";
import { Spinner } from "../Common/Spinner";
import { ApiContext } from "../ApiContext";
import { AlertBar } from "../Common/AlertBar";
import { useTranslation } from "react-i18next";

export const ExcludedTimesNew = ({ reservableList, closeDialog }) => {
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const [reservableIds, setReservableIds] = React.useState([]);
	const [startDate, setStartDate] = React.useState(new Date());
	const [endDate, setEndDate] = React.useState(new Date());
	const [loadingButton, setLoadingButton] = React.useState(false);

	const handleSelectedData = (props) => {
		setReservableIds(props);
	};

	const handleSave = () => {
		ApiCall(
			"POST",
			"client/excluded_times/add",
			reservableIds.map((entry) => ({
				from_time: new Date(startDate).toISOString(),
				short_reservable_id: entry.id,
				to_time: new Date(endDate).toISOString()
			}))
		)
			.then(() => {
				// passRefresh();
				/* loadExcludedTimes(); */
			})
			.catch(() => {
				// console.log(error);
			})
			.finally(() => setLoadingButton(false));
	};

	return (
		<Stack
			spacing={2}
			sx={{
				borderRadius: "7px",
				minWidth: "300px",
				padding: "20px",
				boxSizing: "border-box"
			}}
		>
			{/* <Typography>
            {t("calendar.create_competition_day_label")}
        </Typography> */}

			<div style={theme.formStyles.formLayout}>
				<Typography variant="form_bold">
					{t("calendar.create_competition_day_label")}
				</Typography>

				{reservableList.length > 0 ? (
					<MultiChip
						data={reservableList}
						passSelectedData={handleSelectedData}
						isReservable={true}
						getLabel={t("reservables.Facilities")}
					/>
				) : (
					<PlaceholderSkeleton variant="text" />
				)}
			</div>

			<div style={theme.formStyles.formLayout}>
				<AlertBar
					textContent={t("calendar.exclude_reservation_helper_text")}
					type="info"
					showAlert
				/>

				<Stack direction={isDesktop ? "row" : "column"} spacing={2}>
					<TextfieldPicker
						value={startDate}
						valueChange={setStartDate}
						excludePast={false}
						fullDate={true}
						/* removeIcon={!isDesktop} */
						label={t("calendar.begin")}
						hasProps={
							<CalendarStartIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>

					<TextfieldPicker
						value={endDate}
						valueChange={setEndDate}
						excludePast={false}
						fullDate={true}
						/* removeIcon={!isDesktop} */
						label={t("calendar.end")}
						hasProps={
							<CalendarEndIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>
				</Stack>
			</div>

			<AlertBar
				textContent={t("calendar.exclude_reservation_helper_text")}
				type="info"
				showAlert
			/>

			<Box
				sx={{
					flexDirection: "row",
					display: "flex",
					width: "100%",
					justifyContent: "space-between",
					gap: "20px"
				}}
			>
				<Button
					sx={{ width: "50%" }}
					variant="outlined"
					onClick={() => closeDialog()}
				>
					{t("buttons.cancel")}
				</Button>

				<Button
					sx={{
						width: "50%",
						backgroundColor: loadingButton ? "white" : "",
						border: loadingButton
							? `1px solid ${ColorScheme.tolotechBlue}`
							: ""
					}}
					variant="drawerFormAccept"
					disabled={
						endDate === "" ||
						reservableIds.length === 0 ||
						loadingButton
					}
					onClick={() => {
						setLoadingButton(true);
						handleSave();
					}}
				>
					{loadingButton ? (
						<Spinner
							hasText={t("spinner_text.wait")}
							small={true}
							color={"black"}
							hasColor={ColorScheme.tolotechBlue}
						/>
					) : (
						t("buttons.save")
					)}
				</Button>
			</Box>
		</Stack>
	);
};
