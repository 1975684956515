import DoorIcon from "../../Assets/Icons/DoorBasic.svg";
import GarageDoorIcon from "../../Assets/Icons/GarageDoorIcon.svg";
import { useTranslation } from "react-i18next";

// TODO: These should be fetched from api
export const DOOR_TYPES = () => {
	const { t } = useTranslation();

	return [
		{
			id: "0",
			name: t("door_types.door"),
			image: DoorIcon
		},
		{
			id: "1",
			name: t("door_types.garage_door"),
			image: GarageDoorIcon
		},
		{
			id: "2",
			name: t("door_types.barrier"),
			image: GarageDoorIcon
		}
	];
};
