import { createTheme } from "@mui/material";
import { isMobile } from "react-device-detect";
import { ColorScheme } from "./ColorScheme";

// Google fonts api
const fontUrl =
	"https://fonts.gstatic.com/s/sofiasans/v16/Yq6R-LCVXSLy9uPBwlATrOF6kjouQb4.woff2"; // sofia-sans latin font url, if fonts are messed, check this url and update if needed
const fontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900]; // font weights to load
const fontPromises = [];

fontWeights.forEach((weight) => {
	const font = new FontFace("Sofia Sans", `url(${fontUrl}) format('woff2')`, {
		weight: `${weight}`
	});
	fontPromises.push(font.load());
});

Promise.all(fontPromises)
	.then((loadedFonts) => {
		loadedFonts.forEach((loadedFont) => {
			document.fonts.add(loadedFont);
		});
		document.body.style.fontFamily = "'Sofia Sans', sans-serif";
	})
	.catch((error) => {
		console.log("Error loading fonts:", error);
	});

//

const commonStyles = {
	backgroundColor: ColorScheme.tolotechTransparentWhite,
	borderColor: ColorScheme.lighterGrey,
	color: "black",
	borderWidth: "2px",
	borderRadius: "4px",
	cursor: "pointer"
};

const hoverStyle = {
	"&:hover": {
		borderColor: ColorScheme.lightGrey
		// Add other hover styles here
	},
	"&.Mui-focused": {
		borderColor: ColorScheme.lightGrey
		// Add other focus styles here
	},
	"&.Mui-focused fieldset": {
		borderColor: ColorScheme.lightGrey
	},
	"&:hover fieldset": {
		borderColor: ColorScheme.lightGrey
	}
};

const fieldsetStyle = {
	"& fieldset": {
		borderColor: ColorScheme.textFieldBorder
	},
	"&:hover fieldset": {
		borderColor: ColorScheme.textFieldActiveBorder
	},
	"&.Mui-focused fieldset": {
		borderColor: ColorScheme.textFieldActiveBorder
	}
};

export const theme = createTheme({
	root: {
		"&::-webkit-scrollbar": {
			display: "none",
			"-ms-overflow-style": "none" /* IE and Edge */,
			"scrollbar-width": "none" /* Firefox */,
			width: "0px"
		}
		/* "&::-webkit-scrollbar-track": {
			boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "darkgrey",
			outline: "1px solid slategrey"
		}*/
	},
	palette: {
		action: { hover: "#FFFFFF", selected: "#FFFFFF" }
	},
	typography: {
		fontFamily: ["Sofia Sans"],
		locationCard: {
			// variantti ID
			color: "#000000"
		}
	},

	formStyles: {
		formLayout: {
			display: "flex",
			flexDirection: "column",
			gap: "10px"
		},

		formInput: {
			...commonStyles,
			...hoverStyle,

			input: {
				...commonStyles
			},

			"& .MuiInputLabel-root": {
				...hoverStyle
			},

			"& .MuiOutlinedInput-notchedOutline": {
				...commonStyles,
				...hoverStyle
			},

			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.lightGrey
			},

			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.lightGrey
			},

			"& .MuiSelect-standard": {
				...commonStyles,
				...hoverStyle
			},

			"& .MuiSelect-root": {
				...commonStyles,
				...hoverStyle
			},

			"& .MuiSelect-select": {
				...commonStyles,
				...hoverStyle
			},

			"& .MuiInputBase-root": {
				...commonStyles,
				...hoverStyle
			},

			"& .MuiInputBase-input": {
				...commonStyles,
				...hoverStyle
			},

			"& .MuiTextField-root": {
				...commonStyles,
				...hoverStyle
			},

			"& .MuiOutlinedInput-root": {
				...commonStyles,
				...hoverStyle
			},
			"& .MuiOutlinedInput-input": {
				...commonStyles,
				...hoverStyle
			}
		},

		course_meet_datetime_fields: {
			"& label": {
				color: "transparent",
				transition: "color 0.2s ease-in-out"
			},
			"&:hover label": {
				color: "grey"
			},

			"& label.Mui-focused": {
				color: "black"
			},

			"& input": {
				color: "black",
				textAlign: "center"
			},

			"& .MuiInput-underline:after": {
				borderBottomColor: "white"
			},

			"& .MuiIconButton-root": {
				color: ColorScheme.lightGrey,
				"&:hover": {
					color: ColorScheme.tolotechBlue
				}
			},

			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					border: "none",
					backgroundColor: "transparent"
				},
				"&:hover fieldset": {
					border: "none",
					backgroundColor: "transparent",
					color: "grey"
				},
				"&.Mui-focused fieldset": {
					border: "none",
					backgroundColor: "transparent"
				}
			}
		},

		multiChips: {
			color: ColorScheme.tolotechWhite,
			backgroundColor: ColorScheme.tolotechBlue
		},

		label_with_help: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			width: "calc(100% - 8px)"
		},

		question_mark: {
			display: "flex",
			minWidth: "24px",
			minHeight: "24px",
			alignItems: "center",
			cursor: "pointer"
		},

		collapse_bar: {
			display: "flex",
			flexDirection: "row",
			width: "calc(100% - 15px)",
			borderBottom: `1px solid ${ColorScheme.lighterGrey}`,
			justifyContent: "space-between",
			paddingBottom: "5px",
			cursor: "pointer"
		},

		collapse_bar_arrow: {
			width: "24px",
			height: "24px",
			transition: "transform 0.3s ease"
		}
	},

	serviceTimings: {
		parent: {
			display: "flex",
			flexDirection: isMobile ? "column" : "row",
			gap: "20px",
			alignItems: isMobile ? "stretch" : "center",
			paddingTop: "15px"
		},
		child: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between"
		},
		child_gap: {
			display: "flex",
			flexDirection: "column",
			gap: "10px"
		}
	},

	toloStyled: {
		default_textfield: {
			input: {
				backgroundColor: ColorScheme.textFieldBackground,
				borderColor: ColorScheme.textFieldBorder,
				color: "black",
				borderWidth: "2px",
				borderRadius: "4px",
				cursor: "pointer",
				position: "relative",
				top: "5px",
				left: "5px",
				overflow: "hidden"
				// ... other input styles
			},
			"& .MuiSelect-select": {
				position: "relative",
				top: "5px",
				cursor: "pointer",
				minWidth: "100%"
			},
			"& .Mui-focused": {
				// active border color
				borderColor: ColorScheme.textFieldActiveBorder,
				borderWidth: "1px"
				// color: ColorScheme.textFieldPlaceholder
			},
			"& .Mui-focused .MuiInputLabel-root": {
				color: ColorScheme.textFieldPlaceholder
			},
			"& .MuiInputBase-input": {
				overflow: "hidden",
				textOverflow: "ellipsis"
			},
			"& .MuiInputLabel-root": {
				fontSize: "14px",
				/* color: ColorScheme.textFieldPlaceholder, */
				position: "absolute",
				top: "15px",
				left: "65px",
				transition: "all 0.2s ease-in-out",
				transform: "scale(0.75)",
				PointerEvent: "none"
				// ... other label styles
			},
			"& .MuiOutlinedInput-root": {
				...fieldsetStyle,
				backgroundColor: ColorScheme.textFieldBackground,
				paddingTop: "10px",
				paddingBottom: "10px",
				paddingLeft: "25px",
				paddingRight: "25px"
			},
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.textFieldBorder
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.textFieldActiveBorder
			},
			"&:hover .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.textFieldActiveBorder
			},
			"& .MuiInputAdornment-root": {
				color: ColorScheme.textFieldPlaceholder,
				backgroundColor: ColorScheme.textFieldBackground,
				paddingLeft: "0px",
				minWidth: "27px"
				// ... other adornment styles
			}
		},
		default_select: {
			input: {
				minHeight: "76px",
				backgroundColor: ColorScheme.textFieldBackground,
				borderColor: ColorScheme.textFieldBorder,
				boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
				color: "black",
				borderWidth: "0px",
				borderRadius: "4px",
				cursor: "pointer",
				/* position: "relative",
				top: "5px", */
				overflow: "hidden",
				// ... other input styles
				"& fieldset": {
					borderColor: "red"
				},
				"& fieldset focused": {
					borderColor: ColorScheme.textFieldActiveBorder
				},
				"& fieldset:hover": {
					borderColor: ColorScheme.textFieldActiveBorder
				},
				"&.Mui-focused fieldset": {
					borderColor: ColorScheme.textFieldActiveBorder
				}
			},
			label: {
				color: ColorScheme.textFieldPlaceholder,
				/* position: "absolute",
				top: "15px",
				left: "60px", */
				transition: "all 0.2s ease-in-out",
				/* transform: "translate(0, 0) scale(1)", */
				PointerEvent: "none"

				// ... other label styles
			},
			adornment: {
				color: ColorScheme.textFieldPlaceholder,
				backgroundColor: ColorScheme.textFieldBackground,
				paddingLeft: "0px",
				minWidth: "27px"
				// ... other adornment styles
			},
			"& .MuiSelect-root": {
				// Remove notch
				borderBottom: "none",
				padding: "20px"
			},
			"& .MuiInputLabel-root": {
				// Adjust label position
				transform: "translate(14px, 8px) scale(1)"
			},
			"& .MuiInputBase-root .MuiOutlinedInput-root": {
				// Adjust input base styles
				paddingTop: "12px", // Adjust as needed
				backgroundColor: ColorScheme.textFieldBackground
			},
			fieldset: {
				// Adjust outline styles
				borderColor: ColorScheme.textFieldBorder,
				boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)"
			},
			"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.textFieldBorder
			},
			"&.Mui-active .MuiOutlinedInput-notchedOutline": {
				borderColor: ColorScheme.textFieldBorder
			}
		},
		toloTextfield: {
			// saved from local component level styling to here⁄
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			borderRadius: "4px",
			width: "100%",
			height: "100%",
			minHeight: "75px",
			maxHeight: "75px",
			backgroundColor: ColorScheme.textFieldBackground,
			marginBottom: "10px", // todo fix this, remove (10px) and use gap property in parent
			alignSelf: "center",
			boxSizing: "border-box",
			boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
			border: "1px solid " + ColorScheme.textFieldBorder,
			overflow: "hidden",
			icon: {
				height: "40px",
				minHeight: "40px",
				maxHeight: "40px",
				width: "40px",
				minWidth: "40px",
				maxWidth: "40px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				boxSizing: "border-box",
				marginLeft: "20px",
				cursor: "pointer"
			},
			inputHeader: {
				// not in use
				fontSize: "12px",
				color: ColorScheme.tolotechDarkBlue,
				fontWeight: "700",
				marginTop: 0
			},

			select: {
				// not in use
				minHeight: "50px",
				backgroundColor: ColorScheme.textFieldBackground,
				height: undefined,
				"& .MuiOutlinedInput-root": {
					position: "relative",
					// top: "7px",
					backgroundColor: "inherit"
				},

				"fieldset.MuiOutlinedInput-notchedOutline": {
					border: "none"
				},
				"& .MuiFormLabel-root": {
					top: "0px",
					transition: "all 0.2s ease-in-out" // slower animation transition
				},
				"&.Mui-focused": {
					color: "black",
					top: "2px"
				},
				"& .MuiInputLabel-root.Mui-focused": {
					/* color: "black", */
					top: "10px"
				},
				"& .Mui-active": {
					color: "black"
				},
				"& .MuiSelect-icon": {
					/* top: "5px", */
					transition: "all 0.2s ease-in-out" // slower animation transition
				}
			},

			pickers: {
				width: "100%",
				height: "100%",
				backgroundColor: ColorScheme.textFieldBackground,
				minHeight: "75px",
				"& .MuiInputBase-root": {
					// should cover TextField with select property
					minHeight: "55px",
					backgroundColor: ColorScheme.textFieldBackground,
					"fieldset.MuiOutlinedInput-notchedOutline": {
						border: "none"
					}
				},
				"& .MuiOutlinedInput-root": {
					minHeight: "75px",
					backgroundColor: "inherit",
					position: "relative",
					top: "7px"
				},
				"& .MuiOutlinedInput-notchedOutline": {
					border: "none"
				},
				fieldset: {
					border: "none"
				},
				label: {
					fontSize: "14px",
					top: "28px"
				}
			},

			"& .MuiInputBaseRoot": {
				// should cover TextField with select property
				minHeight: "55px",
				backgroundColor: ColorScheme.textFieldBackground,
				"fieldset.MuiOutlinedInput-notchedOutline": {
					border: "none"
				}
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: 0
			}
		}
	},

	components: {
		/* MuiDesktopTimePicker: {
			defaultProps: {
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock
				}
			}
		},
		MuiDesktopDateTimePicker: {
			defaultProps: {
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock
				}
			}
		}, */
		/*
		MuiPickersCalendarHeader: {},
		MuiPickersDay: {},
		MuiPickersMonth:{},
		MuiDayCalendar: {},	
		MuiDateCalendar: {
			styleOverrides: {
				root: {
					color: "red",
					borderRadius: 2,
					borderWidth: 1,
					borderColor: "#2196f3",
					border: "1px solid",
					backgroundColor: "#0d47a1"
				}
			}
		}, */

		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: "16px",
					fontWeight: "400",
					color: "black",
					padding: "8px",
					backgroundColor: ColorScheme.tolotechWhite,
					boxShadow: "1px 2px 36px -2px rgba(0,0,0,0.49)"
				},
				arrow: {
					bottom: "1px",
					color: ColorScheme.tolotechDarkBlue,
					"&::before": {
						backgroundColor: ColorScheme.tolotechWhite
					}
				}
			}
		},

		MuiTextField: {
			styleOverrides: {
				root: {
					// komponentissa määritelty className: searchBar
					"&.searchBar": {
						width: "50%",
						alignSelf: "center",
						color: "white",

						"& label": {
							color: "white"
						},
						"& input": {
							color: "white"
						},
						"&:hover label": {
							fontWeight: 700
						},
						"& .MuiInput-underline:before": {
							borderBottomColor: "gray"
						},
						"&:hover .MuiInput-underline:before": {
							borderBottomColor: "gray"
						},
						"&:not(hover) .MuiInput-underline:before": {
							borderBottomColor: "gray"
						},
						"& label.Mui-focused": {
							color: "white"
						},
						"& .MuiInput-underline:after": {
							borderBottomColor: "white"
						},
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "white",
								backgroundColor: ColorScheme.tolotechDarkBlue
							},
							"&:hover fieldset": {
								borderColor: "white",
								borderWidth: 2,
								backgroundColor: ColorScheme.tolotechLightBlue
							},
							"&.Mui-focused fieldset": {
								borderColor: "white",
								backgroundColor: ColorScheme.tolotechLightBlue
							}
						},
						"& .MuiOutlinedInput-input + fieldset": {
							backgroundColor: "none",
							color: "red"
						}
					}
				},

				formInput: {
					overrides: {
						MuiTextField: {
							root: {
								backgroundColor: "red",
								borderRadius: "6px",
								border: "1px solid #EDEDED10"
								// Add any other styles you want to apply to the input field
							}
						}
					},
					// testing, move to theme file when ready
					backgroundColor: "red" /* "#F5F5F5", */,
					borderRadius: "6px",
					border: "1px solid #EDEDED10"
				}
			}
		},

		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: ["Sofia Sans"]
					/* fontWeight: "400",
					fontStyle: "normal" */
				},
				defaultBlack: {
					fontSize: "24px",
					color: "#000000",
					fontWeight: "700"
				},
				time: {
					fontSize: "12px",
					color: "#000000",
					fontWeight: "700"
				},
				form_bold: {
					fontWeight: "600",
					fontSize: "14px",
					lineHeight: "12px"
				},
				form_bold_big: {
					fontWeight: "600",
					fontSize: "24px",
					lineHeight: "20px"
				},
				form_text_label: {
					fontWeight: "600",
					fontSize: "12px",
					lineHeight: "12px"
				},
				form_text_desc: {
					fontWeight: "400",
					fontSize: "16px",
					lineHeight: "20px"
				},
				form_text: {
					fontWeight: "400",
					fontSize: "16px",
					lineHeight: "16px"
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				/* root: {
					"& input[type=number]": {
						MozAppearance: "textfield"
					},
					"& input[type=number]::-webkit-outer-spin-button": {
						WebkitAppearance: "none",
						margin: 0
					},
					"& input[type=number]::-webkit-inner-spin-button": {
						WebkitAppearance: "none",
						margin: 0
					}
				},*/
				colorPrimary: {
					backgroundColor: "#FFFFFF"
				}
			}
		},
		MuiStack: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: "#ECEFF2",
					color: "#000000"
				}
			}
		},
		modal: {
			"&:focus": {
				border: "none"
			}
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					color: "#000000"
				}
			}
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderStyle: "dashed",
					borderColor: "rgba(255, 255, 255, 0.2)"
				},
				locationForm: {
					borderStyle: "solid",
					borderColor: ColorScheme.tolotechDarkBlue
				}
			}
		},
		MuiGrid: {
			styleOverrides: {
				item: {},
				smallPreviews: {
					// todo not working
					backgroundColor: "#fff",
					height: "150px",
					opacity: "50%"
				}
			}
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					width: 42,
					height: 26,
					padding: 0,
					"& .MuiSwitch-switchBase": {
						padding: 0,
						margin: 2,
						transitionDuration: "300ms",
						"&.Mui-checked": {
							transform: "translateX(16px)",
							color: "#fff",
							"& + .MuiSwitch-track": {
								backgroundColor: ColorScheme.tolotechBlue,
								opacity: 1,
								border: 0
							},
							"&.Mui-disabled + .MuiSwitch-track": {
								opacity: 0.5
							}
						},
						"&.Mui-focusVisible .MuiSwitch-thumb": {
							color: "#33cf4d",
							border: "6px solid #fff"
						},
						"&.Mui-disabled .MuiSwitch-thumb": {
							color: ColorScheme.tolotechDarkBlue
						},
						"&.Mui-disabled + .MuiSwitch-track": {
							opacity: 0.3
						}
					},
					"& .MuiSwitch-thumb": {
						boxSizing: "border-box",
						width: 22,
						height: 22
					},
					"& .MuiSwitch-track": {
						borderRadius: 26 / 2,
						backgroundColor: "grey",
						opacity: 1,
						transition:
							(["background-color"],
							{
								duration: 500
							})
					}
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				buttonEnabled: {
					backgroundColor: ColorScheme.buttonEnabled,
					fontSize: "16px",
					color: "white",
					width: "100%",
					height: "60px",
					paddingTop: "15px",
					paddingBottom: "15px",
					paddingLeft: "40px",
					paddingRight: "40px",
					textTransform: "none",
					"&:hover": {
						backgroundColor: ColorScheme.tolotechBlue
					},
					"&:pressed": {
						backgroundColor: ColorScheme.buttonPressed
					}
				},
				buttonEnabled_small: {
					backgroundColor: ColorScheme.tolotechBlue,
					fontSize: "14px",
					color: "white",
					width: "100%",
					maxWidth: "100px",
					borderRadius: "8px",
					padding: "5px",
					textTransform: "none",
					"&:hover": {
						backgroundColor: ColorScheme.tolotechDarkBlue
					},
					"&:pressed": {
						backgroundColor: ColorScheme.buttonPressed
					}
				},
				buttonDisabled: {
					backgroundColor: ColorScheme.buttonDisabled,
					color: "white",
					width: "100%",
					fontSize: "16px",
					paddingTop: "15px",
					paddingBottom: "15px",
					paddingLeft: "40px",
					paddingRight: "40px",
					textTransform: "none"
				},
				buttonCancel: {
					backgroundColor: ColorScheme.buttonCancel,
					fontSize: "16px",
					color: "black",
					width: "100%",
					border: "1px solid " + ColorScheme.buttonCancelOutline,
					paddingTop: "15px",
					paddingBottom: "15px",
					paddingLeft: "40px",
					paddingRight: "40px",
					textTransform: "none",
					"&:pressed": {
						backgroundColor: ColorScheme.buttonPressed
					}
				},
				buttonCancel_2: {
					backgroundColor: ColorScheme.buttonCancel,
					border: "1px solid " + ColorScheme.buttonCancelOutline,
					fontSize: "16px",
					color: "black",
					width: "100%",
					height: "54px",
					paddingTop: "15px",
					paddingBottom: "15px",
					paddingLeft: "40px",
					paddingRight: "40px",
					textTransform: "none",
					"&:hover": {
						backgroundColor: ColorScheme.lighterGrey
					},
					"&:pressed": {
						backgroundColor: ColorScheme.buttonPressed
					}
				},

				contained: {
					backgroundColor: ColorScheme.tolotechBlue,
					height: "46px",
					width: "231px", // todo ei mielellään kiinteitä pikseliarvoja
					"&:hover": {
						backgroundColor: ColorScheme.tolotechDarkBlue
					},
					fontSize: "12px",
					textTransform: "none"
				},
				contained2: {
					backgroundColor: ColorScheme.tolotechBlue,
					borderRadius: "4px",
					height: "40px",
					width: "180px", // todo ei mielellään kiinteitä pikseliarvoja
					transition: "background-color 0.3s ease-in-out",
					textTransform: "capitalize",
					"&:hover": {
						backgroundColor: ColorScheme.tolotechDarkBlue
					},
					"&.Mui-disabled": {
						opacity: 0.5,
						cursor: "pointer",
						color: "white"
					},
					color: "white",
					fontSize: "14px"
				},
				outlined: {
					color: "#1C568F",
					border: "1px solid #1C568F",
					fontSize: "12px",
					textTransform: "none"
				},
				testings: {
					right: "10px",
					px: "35px",
					textTransform: "none",
					backgroundColor: "#FFFFFF"
				},
				logoUpload: {
					color: "#000000",
					backgroundColor: ColorScheme.lighterGrey,
					border: "1px solid" + ColorScheme.textFieldBorder,
					borderRadius: "4px",
					minWidth: "40px"
				},
				bannerUpload: {
					color: ColorScheme.lighterGrey,
					backgroundColor: ColorScheme.lighterGrey,
					border: "1px solid" + ColorScheme.textFieldBorder,
					borderRadius: "4px",
					width: "100%",
					height: "200px",
					"&:hover": {
						borderColor: ColorScheme.textFieldActiveBorder
					}
				},
				productUpload: {},
				deleteButton: {
					color: ColorScheme.tolotechWhite,
					backgroundColor: ColorScheme.tolotechBlue,
					paddingTop: "25px",
					paddingBottom: "25px",
					paddingLeft: "40px",
					paddingRight: "40px",
					height: "5%",
					width: "5%", // todo ei mielellään kiinteitä pikseliarvoja
					"&:hover": {
						backgroundColor: ColorScheme.red
					},
					fontSize: "12px",
					textTransform: "none"
				},
				deleteButtonMobile: {
					color: ColorScheme.tolotechWhite,
					backgroundColor: ColorScheme.tolotechBlue,
					padding: "25px",
					"&:hover": {
						backgroundColor: ColorScheme.red
					},
					fontSize: "12px",
					textTransform: "none"
				},
				drawerFormAccept: {
					backgroundColor: ColorScheme.tolotechBlue,
					color: ColorScheme.tolotechWhite,
					height: "46px",
					"&:hover": {
						backgroundColor: ColorScheme.tolotechDarkBlue
					},
					fontSize: "12px",
					textTransform: "none",
					"&.Mui-disabled": {
						opacity: 0.5,
						cursor: "pointer",
						color: "white"
					}
				},

				drawerFormReject: {
					border: "1px solid #1C568F",
					height: "46px",
					"&:hover": {
						backgroundColor: ColorScheme.tolotechDarkBlue
					},

					fontSize: "12px",
					textTransform: "none"
				},
				drawerFormCornerClose: {
					border: "1px solid #1C568F",
					borderRadius: "25px",
					height: "46px",
					width: "26px",
					"&:hover": {
						backgroundColor: ColorScheme.tolotechDarkBlue
					},

					fontSize: "12px",
					textTransform: "none"
				},
				smallAccept: {
					display: "flex",
					backgroundColor: ColorScheme.buttonEnabled,
					color: ColorScheme.tolotechWhite,
					height: "30px",
					fontSize: "14px",
					border: "1px solid transparent",

					"&:hover": {
						backgroundColor: ColorScheme.buttonPressed
						// color: ColorScheme.tolotechDarkBlue,
						// border: `1px solid ${ColorScheme.tolotechDarkBlue}`
					},
					alignItems: "center",
					textTransform: "none"
				},
				smallReject: {
					backgroundColor: ColorScheme.tolotechWhite,
					color: ColorScheme.tolotechBlue,
					height: "16px",
					"&:hover": {
						color: ColorScheme.tolotechWhite,
						backgroundColor: ColorScheme.tolotechDarkBlue
					},
					fontSize: "10px",
					textTransform: "none"
				}
			}
		}
	}
});
