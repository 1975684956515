import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { MuiDrawer } from "../../../Common/MuiDrawer";

export const LocationsUsers = () => {
	const { t } = useTranslation();

	const [openDrawer, setOpenDrawer] = useState(false);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				padding: "15px",
				borderRadius: "4px"
			}}
		>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start",
					alignItems: "flex-start"
					/* gap: "5%" */
				}}
			>
				<div
					style={{
						width: "100%",
						maxWidth: isMobile ? "100px" : "150px"
					}}
				>
					<Typography variant="form_text_label">
						{t("location.form_location_users_label")}:
					</Typography>
				</div>

				<Stack spacing={1} style={{ width: "100%" }}>
					<Button
						variant="drawerFormAccept"
						onClick={() => setOpenDrawer(!openDrawer)}
						sx={{ width: "100%" }}
					>
						{t("buttons.open")}
					</Button>
					<Typography fontSize={11}>
						{t("location.form_user_list_description")}
					</Typography>

					<div style={{ flex: 1 }} />
				</Stack>
			</div>

			<MuiDrawer
				open={openDrawer}
				contentType={"listLocationUsers"}
				handleDrawerClose={() => setOpenDrawer(!openDrawer)}
			/>
		</Stack>
	);
};
