import React, { useEffect, useState, useContext } from "react";
import { Stack } from "@mui/material";
import { TopContent } from "./TopContent";
import { ActiveCampaigns } from "./ActiveCampaigns";
import { MuiDrawer } from "../Common/MuiDrawer";
import { ApiContext } from "../ApiContext";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";
import { BackdropLoading } from "../Common/BackdropLoading";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useNavigate } from "react-router-dom";

export const CampaignContent = () => {
	const debug = false;
	const navigate = useNavigate();

	const [drawerPath, setDrawerPath] = useState("");
	const [openDrawer, setOpenDrawer] = useState(false);
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation, handleTitle } = useContext(UserContext);
	const [campaigns, setCampaigns] = useState([]);
	const [drawerData, setDrawerData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noCampaigns, setNoCampaigns] = useState(false);
	const [hasMounted, setHasMounted] = useState(false);

	const hasPermission = permissionChecker({
		selectedLocation,
		permission: 2
	});

	if (!hasPermission) navigate("/locations");

	const handleDrawerPath = (text) => {
		setDrawerPath(text);
		setOpenDrawer(true);
	};

	const handleDrawerData = (props) => {
		setDrawerData(props);
	};

	const handleDrawerClose = () => {
		setOpenDrawer(!openDrawer);
		setDrawerData([]); // Clear drawer data when closing so it doesn't pass old data to new drawer
	};

	const fetchCampaigns = () => {
		if (openDrawer) setOpenDrawer(!openDrawer);
		setLoading(true);
		ApiCall(
			"GET",
			`client/campaigns?location=${selectedLocation.location_id}`,
			null
		)
			.then((response) => {
				setCampaigns(response);
				if (response.length === 0) setNoCampaigns(true);
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		// fetchCampaigns();
		handleTitle(t("common.campaigns"));
		setHasMounted(true);
	}, []);

	useEffect(() => {
		if (campaigns.length > 0 || noCampaigns) setLoading(false);
	}, [campaigns, noCampaigns]);

	useEffect(() => {
		if (hasMounted && selectedLocation.location_id !== undefined) {
			setNoCampaigns(false);
			fetchCampaigns();
		}
	}, [selectedLocation, hasMounted]);

	return (
		<Stack spacing={2}>
			<TopContent getDrawerPath={handleDrawerPath} />
			{loading ? (
				<BackdropLoading />
			) : (
				<ActiveCampaigns
					data={campaigns}
					passRefresh={fetchCampaigns}
					openDrawer={handleDrawerPath}
					passDrawerData={handleDrawerData}
				/>
			)}
			<MuiDrawer
				open={openDrawer}
				handleDrawerClose={handleDrawerClose}
				contentType={drawerPath}
				passRefresh={fetchCampaigns}
				data={drawerData}
			/>
		</Stack>
	);
};
