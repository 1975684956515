import {
	Box,
	FormControl,
	MenuItem,
	Select,
	Stack,
	Typography
} from "@mui/material";

import React, { useEffect, useState, useContext } from "react";
import { ColorScheme } from "../../Themes/ColorScheme";
// import { ColorScheme } from "../../../Themes/ColorScheme";

import { ApiContext } from "../ApiContext";

import { ReactComponent as DotIcon } from "../../Assets/Icons/DotIcon.svg";
import { useTranslation } from "react-i18next";
import { AlertBar } from "../Common/AlertBar";

import { Spinner } from "../../Components/Common/Spinner";

import { format } from "date-fns";

export const OrderDrawerContent = ({ data }) => {
	const debug = false;
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const [orderStatus, setOrderStatus] = useState(0);
	const [showError, setShowError] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const getRefundStatus = () => {
		if (data.order_succesful && data.refund_status !== 1) return 0;
		else if (data.refund_status === 1) return 2;
		else if (data.refund_status !== 1 && !data.order_succesful) return 1;
		else return 0;
	};

	const getStatuses = () => {
		if (orderStatus === 0)
			return [
				{
					status: 0,
					title: t("orders.paid_label"),
					color: "#208636"
				},
				{
					status: 2,
					title: t("orders.cancel_purchase_label"),
					color: "#B8B8B8"
				}
			];
		else if (orderStatus === 1)
			return [
				{
					status: 1,
					title: t("orders.unpaid_label"),
					color: "#BE3535"
				},
				{
					status: 0,
					title: t("orders.mark_as_paid_label"),
					color: "#208636"
				},
				{
					status: 2,
					title: t("orders.cancel_purchase_label"),
					color: "#B8B8B8"
				}
			];
		else if (orderStatus === 2)
			return [
				{
					status: 2,
					title: t("orders.successful_cancel_label"),
					color: "#B8B8B8"
				}
			];
		else return [];
	};

	const handleSelectChange = (event) => {
		event.stopPropagation();
		setShowLoading(true);

		if (orderStatus !== 0 && event.target.value === 0)
			ApiCall("POST", "payment/cash_payment/" + data.order_id, null)
				.then(() => {
					setOrderStatus(0);
				})
				.finally(() => setShowLoading(false));
		else if (
			(orderStatus === 0 || orderStatus === 1) &&
			event.target.value === 2
		)
			ApiCall("POST", "payment/refund/" + data.order_id, null)
				.then(() => {
					setOrderStatus(2);
				})
				.catch((error) => {
					if (debug) console.log(error);
					setShowError(true);
				})
				.finally(() => setShowLoading(false));
	};

	useEffect(() => {
		setOrderStatus(getRefundStatus());
	}, []);

	return (
		<Box>
			<div>
				{/* 	<DrawerTitle
					title={t("orders.order_contents_label")}
					location={selectedLocation.name}
				/> */}

				{data !== null && (
					<Box>
						<div style={{ height: "40px" }} />

						<Typography
							sx={{
								fontSize: "12px",
								// marginTop: "50px",
								color: ColorScheme.tolotechAlt
							}}
						>
							{format(
								new Date(data.created_at),
								"dd.MM.yyyy HH:mm:ss"
							)}
						</Typography>
						<Typography
							sx={{
								fontSize: "14px",
								marginTop: "50px",
								marginBottom: "20px",
								color: ColorScheme.tolotechAlt
							}}
						>
							{t("orders.product_row_label")}
						</Typography>
						{data.order_contents.map((content, i) => (
							<Box key={i}>
								<Box
									sx={{
										my: "20px",
										flexDirection: "row",
										display: "flex",
										width: "100%",
										justifyContent: "space-between",
										alignItems: "center"
									}}
								>
									<Box>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: "700"
											}}
										>
											{content.name}
										</Typography>
									</Box>
									<Box
										sx={{
											alignItems: "flex-end",
											display: "flex",
											flexDirection: "column",
											width: "35%"
										}}
									>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: "700"
											}}
										>
											{content.vat_price.toFixed(2) +
												" " +
												t("common.currency_symbol")}
										</Typography>
										<Typography
											sx={{
												fontSize: "12px",
												color: "rgba(0,0,0,0.2)"
											}}
										>
											{"(" +
												content.vat_percentage +
												" %)"}
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										height: "1px",
										width: "100%",
										backgroundColor:
											ColorScheme.tolotechAlt,
										opacity: 0.2
									}}
								></Box>
							</Box>
						))}
						<Box
							sx={{
								marginTop: "50px",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								display: "flex"
							}}
						>
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: "700"
								}}
							>
								{t("orders.total_label")}
							</Typography>
							<Typography
								sx={{
									fontSize: "20px",
									fontWeight: "700"
								}}
							>
								{data.total_vat_price.toFixed(2) +
									" " +
									t("common.currency_symbol")}
							</Typography>
						</Box>
						<FormControl sx={{ width: "100%", marginTop: "50px" }}>
							<Select
								labelid="select-type-label"
								id="select-type"
								value={orderStatus}
								onChange={(event) => handleSelectChange(event)}
								sx={{
									backgroundColor: "#F5F5F5",
									borderRadius: "6px",
									border: "1px solid #EDEDED"
								}}
							>
								{getStatuses().map((type) => (
									<MenuItem
										value={type.status}
										key={type.status}
										sx={{
											padding: "25px",
											fontSize: "20px",
											"&.Mui-selected, &.Mui-selected:hover":
												{
													backgroundColor:
														ColorScheme.tolotechDarkBlue,
													color: ColorScheme.tolotechWhite
												},
											"&.Mui-selected:hover": {
												backgroundColor:
													ColorScheme.tolotechDarkBlue,
												color: ColorScheme.tolotechWhite
											},
											"&.Mui-selected": {
												backgroundColor:
													ColorScheme.green,
												color: "black"
											},
											"&:hover": {
												backgroundColor:
													ColorScheme.tolotechDarkBlue,
												color: ColorScheme.tolotechWhite
											}
										}}
									>
										<Stack
											direction="row"
											alignItems="center"
										>
											{showLoading ? (
												<Spinner small />
											) : (
												<DotIcon
													fill={type.color}
													style={{
														marginRight: "50px"
													}}
												/>
											)}
											<div style={{ flex: 1 }} />
											{showLoading ? "" : type.title}
											<div style={{ flex: 1 }} />
										</Stack>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				)}
				<AlertBar
					textContent={t("orders.purchase_refund_unsuccessful_alert")}
					type="warning"
					showAlert={showError}
				/>
				<div style={{ height: "50px" }} />
			</div>
		</Box>
	);
};
