import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import React, { useEffect, useState, useContext } from "react";
import { AddNewUser } from "./AddNewUser";
import { ListDoorUsers } from "./ListDoorUsers";

import { ColorScheme } from "../../../Themes/ColorScheme";
import { ApiContext } from "../../ApiContext";
import { useTranslation } from "react-i18next";
import { SearchBar } from "./SearchBar";

export const DoorUserContent = ({ info }) => {
	const debug = false;

	const [switchView, setSwitchView] = useState(false);
	const [requests, setRequests] = useState([]);
	const [acceptingNew, setAcceptingNew] = useState(false);
	const [selectedRequest, setSelectedRequest] = useState(null);
	const [visible, setVisible] = useState(true);
	const [searchString, setSearchString] = useState("");

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const fetchNewRequests = () => {
		ApiCall("GET", `client/access_requests/${info.door_id}`)
			.then((reqs) => {
				setRequests(reqs);
				if (reqs.length > 0) setVisible(true);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const AccessRequest = ({
		request,
		switchView,
		setSwitchView,
		setAcceptingNew,
		setSelectedRequest
	}) => {
		function handleAccept() {
			setVisible(false);
			setSelectedRequest(request);
			setAcceptingNew(true);
			setSwitchView(!switchView);
		}

		function handleDecline() {
			setVisible(false);
			setAcceptingNew(false);

			ApiCall("PUT", "door/update_access", {
				access_ends_at: new Date(),
				granted: "false",
				id: request.id + "",
				start_time: null,
				end_time: null,
				weekdays: null,
				access_starts_at: new Date(),
				restrict_times: "false"
			})
				.then(() => fetchNewRequests()) // todo
				.catch((error) => {
					if (debug) console.log(error);
				});
		}

		{
			return (
				visible && (
					<div
						style={{
							border: "1px solid" + ColorScheme.textFieldBorder,
							borderRadius: "4px",
							backgroundColor: ColorScheme.textFieldBackground,
							padding: "20px"
						}}
					>
						<Stack
							direction={"row"}
							style={{ justifyContent: "space-between" }}
						>
							<Stack>
								<Typography
									fontSize={"16px"}
									fontWeight={"700"}
								>
									{request.name}
								</Typography>
								<Typography fontSize={"12px"}>
									{request.email}
								</Typography>
							</Stack>
							<Stack direction={"row"}>
								<Button
									onClick={() => handleAccept()}
									style={{
										backgroundColor:
											ColorScheme.tolotechBlue
									}}
								>
									<Typography
										color={ColorScheme.tolotechWhite}
										textTransform={"capitalize"}
									>
										{t("buttons.accept")}
									</Typography>
								</Button>
								<Button
									onClick={() => handleDecline()}
									style={{
										backgroundColor:
											ColorScheme.tolotechBlue,
										marginLeft: "15px"
									}}
								>
									<Typography
										color={ColorScheme.tolotechWhite}
										textTransform={"capitalize"}
									>
										{t("buttons.reject")}
									</Typography>
								</Button>
							</Stack>
						</Stack>
					</div>
				)
			);
		}
	};

	const handleClear = () => {
		setSearchString("");
	};

	const handleViewChange = () => {
		setSwitchView(!switchView);
		setSearchString("");
	};

	const handleSearch = (event) => {
		setSearchString(event.target.value);
	};

	useEffect(() => {
		fetchNewRequests();
	}, []);

	useEffect(() => {
		if (!switchView && !acceptingNew) fetchNewRequests();
	}, [switchView, acceptingNew]);

	return (
		<Box sx={{ height: "100%" }}>
			<Stack sx={{ height: "100%", gap: "20px" }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{/* <Typography variant="h5">{info.door_name}</Typography> */}
					{/* <DrawerTitle
						location={selectedLocation.name}
						title={t("doors.door_users_list")}
					/> */}

					<div style={{ flex: 1 }} />
					<Button
						variant="smallAccept"
						onClick={() => {
							setSwitchView(!switchView);
							if (switchView) setAcceptingNew(false);
						}}
						sx={{
							width: "150px",
							fontSize: "13px",
							backgroundColor: ColorScheme.tolotechBlue
						}}
					>
						{switchView
							? t("buttons.cancel")
							: t("buttons.create_new_access_right")}
					</Button>
				</Stack>

				{switchView ? (
					<AddNewUser
						info={info}
						changeView={handleViewChange}
						acceptingNew={acceptingNew}
						selectedRequest={selectedRequest && selectedRequest}
					/>
				) : (
					<Stack>
						<Stack
							display={requests.length > 0 ? "flex" : "none"}
							spacing={1}
							style={{ marginTop: "20px", marginBottom: "20px" }}
						>
							{requests.length > 0 && (
								<>
									<Typography
										variant="h6"
										style={{ marginBottom: "10px" }}
									>
										{t("doors.door_access_request_label")}
									</Typography>
									{requests.map((request, i) => (
										<AccessRequest
											setSelectedRequest={
												setSelectedRequest
											}
											key={i}
											request={request}
											setSwitchView={setSwitchView}
											switchView={switchView}
											setAcceptingNew={setAcceptingNew}
										/>
									))}
								</>
							)}
						</Stack>

						<SearchBar
							searchString={searchString}
							handleSearch={handleSearch}
							handleClear={handleClear}
						/>

						<ListDoorUsers info={info} search={searchString} />
					</Stack>
				)}
			</Stack>
		</Box>
	);
};
