import { Divider, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../ApiContext";
import { PopoverBase } from "../../Common/PopoverBase";

import { UserContext } from "../../UserContext";
import { StyledLinkButton } from "../SidebarMenu/StyledLinkButton";
import { LocationSelector } from "./LocationSelector";

// import { ReactComponent as UndoIcon } from "../../../Assets/Icons/UndoIcon.svg";
// import { Link, matchPath } from "react-router-dom";

export const TopBar = () => {
	const debug = false;

	const { user, setUser, title /* selectedLocation */ } =
		useContext(UserContext);
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const [invites, setInvites] = useState(false);
	const [openPopover, setOpenPopover] = useState(null);
	const [popoverContent, setPopoverContent] = useState(null);

	const handleClose = () => {
		setOpenPopover(null);
	};

	const handlePopover = (props) => {
		setOpenPopover(props.currentTarget);
	};

	const handlePopoverContent = (props) => {
		setPopoverContent(props);
	};

	const open = Boolean(openPopover);
	const id = open ? "popover-menu" : undefined;

	useEffect(() => {
		const getToken = document.cookie.split("webapp-token=")[1];

		// prevent fetching user data if token is undefined
		if (getToken === undefined) {
			return;
		}

		ApiCall("GET", "client/get_client_invites")
			.then((res) => {
				if (res.length > 0) setInvites(true);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});

		if (user.name === undefined)
			ApiCall("GET", "user")
				.then((res) => {
					setUser(res);
				})
				.catch((err) => {
					if (debug) console.log(err);
				});
	}, []);

	useEffect(() => {
		ApiCall("GET", "client/access_requests").then((res) => {
			if (res.length > 0) setInvites(true);
		});
	}, []);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			spacing={4}
			sx={{
				width: 1,
				height: "fit-content",
				padding: "5px",
				paddingTop: isDesktop ? "30px" : "8px",
				paddingBottom: "5px",
				borderBottom: "1px solid rgba(255,255,255,0.2)"
			}}
		>
			<Stack
				direction="column"
				alignItems="flex-start"
				justifyContent="space-between"
			>
				<Stack direction="row" alignItems="center" spacing={2}>
					<Typography
						style={{ fontSize: isMobile ? "20px" : "30px" }}
					>
						{title}
					</Typography>

					{/* {match === null && (
						<Link to={`/locations/${selectedLocation.location_id}`}>
							<Stack
								direction="row"
								spacing={1}
								alignItems="center"
							>
								{!isMobile && (
									<Typography fontSize="13px" color="white">
										{t("menu.return_back")}
									</Typography>
								)}
								<div style={{ minWidth: "20px" }}>
									<UndoIcon
										width="20px"
										height="20px"
										stroke="white"
									/>
								</div>
							</Stack>
						</Link>
					)} */}
				</Stack>

				<LocationSelector />
			</Stack>

			<Stack
				direction="row"
				spacing={isMobile ? 2 : 0.5}
				alignItems="center"
			>
				{/* <Stack alignItems="flex-end"> */}
				{!isMobile && (
					<Stack>
						<Typography
							style={{
								marginRight: "20px",
								fontSize: "16px",
								fontWeight: "700"
							}}
						>
							{user.name}
						</Typography>

						<Typography
							style={{
								marginRight: "20px",
								fontSize: "12px",
								color: "rgb(200,200,200)"
							}}
						>
							{user.email}
						</Typography>
					</Stack>
				)}
				{/* <Stack direction="row" alignItems="center" spacing={0.5}> */}
				<StyledLinkButton
					current_path={location.pathname.split("/")[1]}
					path="settings"
					title={t("common.settings")}
					ignoreStyling={true}
					isPopover={true}
					hasTooltip={true}
					togglePopover={handlePopover}
					handlePopoverContent={handlePopoverContent}
				/>

				{!isMobile && (
					<Divider
						orientation="vertical"
						sx={{
							backgroundColor: "rgb(255, 255, 255, 0.14)",
							width: "1px",
							height: "50px",
							border: "0px solid rgb(255, 255, 255, 0.14)"
						}}
					/>
				)}

				<StyledLinkButton
					current_path={location.pathname.split("/")[1]}
					path="messages"
					title={t("common.messages")}
					hasBadge={invites}
					hasTooltip={true}
					ignoreStyling={true}
					isPopover={true}
					togglePopover={handlePopover}
					handlePopoverContent={handlePopoverContent}
				/>
				{/* </Stack> */}
				{/* </Stack> */}
			</Stack>

			{/* <Stack spacing={1.5} direction="row" alignItems="center">
				<Stack direction="column" alignItems="flex-end">
					<Typography
						style={{
							marginRight: "20px",
							fontSize: "16px",
							fontWeight: "700"
						}}
					>
						{user.name}
					</Typography>
					<Typography
						style={{
							marginRight: "20px",
							fontSize: "12px",
							color: "rgb(200,200,200)"
						}}
					>
						{user.email}
					</Typography>
				</Stack>
				<StyledLinkButton
					current_path={location.pathname.split("/")[1]}
					path="settings"
					title={t("common.settings")}
					ignoreStyling={true}
					isPopover={true}
					hasTooltip={true}
					togglePopover={handlePopover}
					handlePopoverContent={handlePopoverContent}
				/>
				{!isMobile && (
					<Divider
						orientation="vertical"
						sx={{
							backgroundColor: "rgb(255, 255, 255, 0.14)",
							width: "1px",
							height: "50px",
							border: "0px solid rgb(255, 255, 255, 0.14)"
						}}
					/>
				)}
				<StyledLinkButton
					current_path={location.pathname.split("/")[1]}
					path="messages"
					title={t("common.messages")}
					hasBadge={invites}
					hasTooltip={true}
					ignoreStyling={true}
					isPopover={true}
					togglePopover={handlePopover}
					handlePopoverContent={handlePopoverContent}
				/>
			</Stack> */}
			<PopoverBase
				id={id}
				open={open}
				content={popoverContent}
				handleClose={handleClose}
				anchorEl={openPopover}
			/>
		</Stack>
	);
};
