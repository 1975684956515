import {
	Box,
	Button,
	InputAdornment,
	MenuItem,
	TextField,
	Typography
} from "@mui/material";
import React, { useState } from "react";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { WeekdaySelect } from "../../../Common/WeekdaySelect";
import { TimeSlots } from "../../../Common/ReservationTimes";
import { TextFieldInput } from "../../../Common/TextFieldInput";

import { ReactComponent as UserIcon } from "../../../../Assets/Icons/ClientsIcon.svg";
import { ReactComponent as WalletIcon } from "../../../../Assets/Icons/WalletIcon.svg";
import { ReactComponent as DurationIcon } from "../../../../Assets/Icons/DurationIcon.svg";
import { ReactComponent as CalendarFromIcon } from "../../../../Assets/Icons/CalendarFromIcon.svg";
import { ReactComponent as CalendarToIcon } from "../../../../Assets/Icons/CalendarToIcon.svg";
import { ReactComponent as TimeStartIcon } from "../../../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as TimeEndIcon } from "../../../../Assets/Icons/TimeEndIcon.svg";
import { theme } from "../../../../Themes/Theme";
import { AlertBar } from "../../../Common/AlertBar";
import { TextfieldPicker } from "../../../Common/TextfieldPicker";
import { useTranslation } from "react-i18next";

export const RecipeModalMobile = ({
	data,
	handleClose,
	handleSave,
	showError
}) => {
	if (data === undefined) return null;

	const { t } = useTranslation();

	const [startDate, setStartDate] = useState(data?.valid_from || new Date());
	const [endDate, setEndDate] = useState(data?.valid_until || new Date());
	const [weekday, setWeekday] = useState(data?.weekday || null);
	const [minTime, setMinTime] = useState(data?.minimum_reservation || null);
	const [userLimit, setUserLimit] = useState(data?.user_limit || null);
	const [reservationPrice, setReservationPrice] = useState(
		data?.vat_price || null
	);
	const [startTime, setStartTime] = useState(data?.from_time || new Date());
	const [endTime, setEndTime] = useState(data?.to_time || new Date());

	const handleSaveClick = () => {
		// compress edited data into one nice object to pass to parent component
		handleSave({
			valid_from: new Date(startDate),
			valid_until: new Date(endDate),
			from_time: new Date(startTime),
			to_time: new Date(endTime),
			minimum_reservation: Number(minTime),
			user_limit: Number(userLimit),
			weekday: Number(weekday),
			short_reservable_id: data.short_reservable_id,
			vat_price: Number(reservationPrice)
		});
	};

	return (
		<>
			<div
				style={{
					// width: "100%",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					maxWidth: "450px",
					padding: "30px",
					borderBox: "box-sizing",
					borderTop:
						"1px solid" + ColorScheme.tolotechTransparentWhite
				}}
			>
				<div
					style={{
						width: "100%",
						// height: "60px",
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						alignItems: "center"
					}}
				>
					<TextfieldPicker
						valueChange={setStartDate}
						value={startDate}
						label={t("recipes.valid_from")}
						fullDate={false}
						noTime={true}
						hasProps={
							<CalendarFromIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>
					{/* <DatePicker
						onChange={(date) => {
							const newDate = new Date(date);
							setStartDate(newDate);
						}}
						label={t("recipes.valid_from")}
						value={startDate}
						views={["year", "month", "day"]}
						format="DD.MM.yyyy"
						renderInput={(params) => (
							<TextField
								fullWidth
								{...params}
								sx={theme.toloStyled.default_textfield}
								InputProps={{
									notched: false,
									disableUnderline: true,
									startAdornment: (
										<InputAdornment
											position="start"
											disablePointerEvents
										>
											<CalendarFromIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										</InputAdornment>
									)
								}}
							/>
						)}
						slotProps={{
							textField: {
								InputProps: {
									notched: false,
									disableUnderline: true
								}
							},

							inputAdornment: { position: "start" }
						}}
					/> */}

					<TextfieldPicker
						valueChange={setEndDate}
						value={endDate}
						label={t("recipes.valid_until")}
						fullDate={false}
						noTime={true}
						hasProps={
							<CalendarToIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>
					{/* <DatePicker
						onChange={(date) => {
							const newDate = new Date(date);
							console.log(newDate);
							setEndDate(newDate);
						}}
						label={t("recipes.valid_until")}
						format="DD.MM.yyyy"
						views={["year", "month", "day"]}
						value={endDate}
						renderInput={(params) => (
							<TextField
								fullWidth
								{...params}
								sx={theme.toloStyled.default_textfield}
								InputProps={{
									notched: false,
									disableUnderline: true,
									startAdornment: (
										<InputAdornment
											position="start"
											disablePointerEvents
										>
											<CalendarToIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										</InputAdornment>
									)
								}}
							/>
						)}
						slotProps={{
							textField: {
								InputProps: {
									notched: false,
									disableUnderline: true
								}
							},

							inputAdornment: { position: "start" }
						}}
					/> */}
				</div>

				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						// minWidth: "calc(100% / 3)",
						gap: "10px",
						alignItems: "center",
						boxSizing: "border-box"
					}}
				>
					<TextfieldPicker
						valueChange={setStartTime}
						value={startTime}
						label={t("recipes.from_time")}
						fullDate={false}
						noTime={false}
						hasProps={
							<TimeStartIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>
					{/* <TimePicker
						onChange={(date) => {
							const newDate = new Date(date);
							setStartTime(newDate);
						}}
						label={t("recipes.from_time")}
						value={startTime}
						ampm={false}
						renderInput={(params) => (
							<TextField
								fullWidth
								sx={theme.toloStyled.default_textfield}
								{...params}
								InputProps={{
									notched: false,
									disableUnderline: true,
									startAdornment: (
										<InputAdornment
											position="start"
											disablePointerEvents
										>
											<TimeStartIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										</InputAdornment>
									)
								}}
							/>
						)}
						slotProps={{
							textField: {
								InputProps: {
									notched: false,
									disableUnderline: true
								}
							},

							inputAdornment: { position: "start" }
						}}
					/> */}

					<TextfieldPicker
						valueChange={setEndTime}
						value={endTime}
						label={t("recipes.to_time")}
						fullDate={false}
						noTime={false}
						hasProps={
							<TimeEndIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
					/>

					{/* <TimePicker
						onChange={(date) => {
							const newDate = new Date(date);
							setEndTime(newDate);
						}}
						label={t("recipes.to_time")}
						value={endTime}
						ampm={false}
						renderInput={(params) => (
							<TextField
								sx={theme.toloStyled.default_textfield}
								fullWidth
								{...params}
								InputProps={{
									notched: false,
									disableUnderline: true,
									startAdornment: (
										<InputAdornment
											position="start"
											disablePointerEvents
										>
											<TimeEndIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										</InputAdornment>
									)
								}}
							/>
						)}
						slotProps={{
							textField: {
								InputProps: {
									notched: false,
									disableUnderline: true
								}
							},

							inputAdornment: { position: "start" }
						}}
					/> */}
				</div>

				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						alignItems: "center"
					}}
				>
					<WeekdaySelect
						days={weekday}
						singleSelect={true}
						weekdayClicked={(resp) => {
							// console.log(resp);
							setWeekday(resp);
						}}
					/>
				</div>

				<div
					style={{
						display: "flex",
						gap: "10px",
						alignItems: "baseline"
					}}
				>
					<TextField
						onClick={() => {
							console.log("clicked");
						}}
						fullWidth
						select
						value={minTime}
						label={t("services.reservation_duration_label")}
						placeholder={t("services.reservation_duration_label")}
						onChange={(event) => {
							// console.log(event);
							setMinTime(event.target.value);
						}}
						sx={theme.toloStyled.default_textfield}
						SelectProps={{
							MenuProps: {
								sx: { top: "15px" }
							}
						}}
						InputProps={{
							notched: false,
							disableUnderline: true,
							startAdornment: (
								<InputAdornment
									position="start"
									disablePointerEvents
								>
									<DurationIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								</InputAdornment>
							)
						}}
					>
						{TimeSlots.map((time) => (
							<MenuItem value={time.value} key={time.value}>
								{time.title}
							</MenuItem>
						))}
					</TextField>
				</div>

				<div
					style={{
						display: "flex",
						width: "100%",
						flexDirection: "column",
						gap: "10px"
					}}
				>
					<TextFieldInput
						key={data.id}
						text={reservationPrice}
						title={t("reservations.price_label")}
						valueChange={setReservationPrice}
						hasProps={
							<WalletIcon
								stroke={ColorScheme.defaultIconColor}
								strokeWidth="3px"
							/>
						} // t("common.currency_symbol")
						inputType={"tel"}
						newStyle={true}
					/>
					<TextFieldInput
						key={data.id}
						text={userLimit}
						title={t("reservations.customer_amount_label")}
						valueChange={setUserLimit}
						hasProps={
							<UserIcon stroke={ColorScheme.defaultIconColor} />
						} // t("services.max_users")
						inputType={"tel"}
						newStyle={true}
					/>
				</div>
				<div
					style={{
						overflow: "hidden",
						transition: "max-height 0.5s ease",
						height: "100%",
						maxHeight: showError[0] ? "200px" : "0", // should be enough for most error responses
						minHeight: "0"
					}}
				>
					<AlertBar
						showAlert={showError[0]}
						whiteSpacePreLine={true}
						newLine={true}
						type={"error"}
						textContent={showError[1]}
					/>
				</div>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "right",
						gap: "20px",

						cursor: "pointer"
					}}
				>
					<Button
						variant="buttonCancel"
						onClick={() => handleClose()}
					>
						<Typography>{t("buttons.cancel")}</Typography>
					</Button>

					<Button variant="buttonEnabled" onClick={handleSaveClick}>
						<Typography>{t("buttons.save")}</Typography>
					</Button>
				</Box>
			</div>
		</>
	);
	/* return (
		<Box
			sx={{ padding: "20px", minWidth: "300px", boxSizing: "border-box" }}
		>
			<Typography
				sx={{ fontSize: "20px", color: "black", fontWeight: "700" }}
			>
				{data.user_name}
			</Typography>
			<Typography sx={{ fontSize: "16px", color: "grey" }}>
				{user.email}
			</Typography>
			<Box style={styles.inputContainer}>
				<TextField
					variant="filled"
					label={t("textfield_labels.description")}
					placeholder={t("textfield_labels.input_description")}
					sx={{
						border: "none",
						width: "100%",
						flex: 1
					}}
					InputProps={{
						disableUnderline: true,
						style: {
							color: "black",
							backgroundColor: "transparent",
							borderRadius: "20px",
							border: "0px solid rgba(0,0,0,0)",
							fontSize: "15px"
						}
					}}
					InputLabelProps={{
						style: {
							fontSize: "14px",
							top: "3px"
						}
					}}
					value={description}
					onChange={(event) => {
						let inputValue = event.target.value;
						setDescription(inputValue);
					}}
				/>
			</Box>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginBottom: "20px",
					marginTop: "20px"
				}}
			>
				dsa
			</div>
			<Box
				sx={{
					flexDirection: "row",
					display: "flex",
					width: "100%",
					justifyContent: "space-between"
				}}
			>
				<Box
					onClick={() => closeDialog(false)}
					sx={{
						height: "40px",
						flex: 1,
						border: "1px solid " + ColorScheme.tolotechBlue,
						cursor: "pointer",
						borderRadius: "4px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<Typography sx={{ fontSize: "14px", color: "black" }}>
						{t("buttons.cancel")}
					</Typography>
				</Box>
				<div style={{ width: "20px" }}></div>
				<Box
					onClick={() => !loading && handleSave()}
					sx={{
						height: "40px",
						flex: 1,
						border: "1px solid " + ColorScheme.tolotechBlue,
						backgroundColor: ColorScheme.tolotechBlue,
						borderRadius: "4px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer"
					}}
				>
					{!loading && (
						<Typography sx={{ fontSize: "14px", color: "white" }}>
							{t("buttons.save")}
						</Typography>
					)}
					{loading && (
						<Spinner small color={ColorScheme.tolotechWhite} />
					)}
				</Box>
			</Box>
		</Box>
	); */
};
