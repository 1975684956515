import React, { useContext } from "react";
import { Snackbar, Alert } from "@mui/material";
import { SnackContext } from "../SnackContext";

export const SnackBarAlert = () => {
	const { openSnack, setOpenSnack, getSeverity, snackDisplay, snackDelay } =
		useContext(SnackContext);

	return (
		/* Severity levels: error, warning, info, success */
		<>
			<Snackbar
				open={openSnack}
				autoHideDuration={snackDelay}
				onClose={() => setOpenSnack(false)}
			>
				<Alert
					onClose={() => setOpenSnack(false)}
					severity={getSeverity}
				>
					{snackDisplay}
				</Alert>
			</Snackbar>
		</>
	);
};
