import {
	Box,
	TextField,
	Typography,
	Button,
	Select,
	MenuItem
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ApiContext } from "../ApiContext";
import { Spinner } from "../Common/Spinner";
import { ColorScheme } from "../../Themes/ColorScheme";

import { ReactComponent as Arrow } from "../../Assets/Icons/arrowDown.svg";
import { UserContext } from "../UserContext";
import { ClientSortingOptions } from "./ClientSortingOptions";
import { ClientCard } from "./ClientCard";
import { ClientOrders } from "./ClientOrders";
import LandingAnimation from "../../Helpers/LandingAnimation";
import { isMobile, isDesktop } from "react-device-detect";
import { downloadCsvFromJson } from "../../Helpers/JsonToCsvDownload";
import { downloadExcelFromJson } from "../../Helpers/JsonToExcelDownload";
import { useTranslation } from "react-i18next";

const ONE_PAGE_LIMTI = 20;

const styles = {
	button: {
		height: "40px",
		width: "40px",
		backgroundColor: "#F1F1F1",
		marginTop: "20px",
		borderRadius: "4px",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer"
	}
};

export const ClientContent = () => {
	const { t } = useTranslation();

	const { selectedLocation, handleTitle } = useContext(UserContext);

	const { ApiCall } = useContext(ApiContext);

	const [search, setSearch] = useState("");

	const [page, setPage] = useState(1);
	const [showNextPageButton, setShowNextPageButton] = useState(false);
	const [showPreviousPageButton, setShowPreviousPageButton] = useState(false);

	const [loading, setLoading] = useState(true);
	const [content, setContent] = useState([]);
	const [boxHeight, setBoxHeight] = useState(80);

	const [readyToFetch, setReadyToFetch] = useState(false);

	const [sort, setSort] = useState("name");

	const [descending, setDescending] = useState(false);

	const [dialogUser, setDialogUser] = useState(null);

	const [loadingUserCsv, setLoadingUserCsv] = useState(false);
	const [fileType, setFileType] = useState("csv");

	function fetchClientData() {
		setContent([]);
		ApiCall(
			"GET",
			`client/get_customers?location=${selectedLocation.location_id}&search=${search}&page=${page}&sort=${sort}&desc=${descending}`
		)
			.then((res) => {
				let temp = [...content];
				res.map((entry) => {
					temp.push(entry);
				});
				if (res.length >= ONE_PAGE_LIMTI) {
					setShowNextPageButton(true);
				} else {
					setShowNextPageButton(false);
				}
				setContent(temp);
				setReadyToFetch(false);
				setLoading(false);
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	}

	function downloadCustomerList() {
		setLoadingUserCsv(true);
		ApiCall(
			"GET",
			`client/get_customers?location=${selectedLocation.location_id}&sort=email&desc=false`
		)
			.then((res) => {
				const filteredData = res.map(
					({ name, email, phone, order_count }) => ({
						name,
						email,
						phone,
						order_count
					})
				);
				let headers = [
					t("clients.username"),
					t("clients.user_email"),
					t("clients.user_phone"),
					t("clients.user_orders")
				];

				if (fileType === "excel") {
					downloadExcelFromJson(
						headers,
						filteredData,
						t("common.customers") + "_" + selectedLocation.name
					);
				} else if (fileType === "csv") {
					downloadCsvFromJson(
						headers,
						filteredData,
						t("common.customers") + "_" + selectedLocation.name
					);
				}

				setLoadingUserCsv(false);
			})
			.catch((error) => {
				console.log(error);
				setLoadingUserCsv(false);
			});
	}

	useEffect(() => {
		if (readyToFetch) {
			fetchClientData();
		}
	}, [readyToFetch]);

	const handleChange = (text) => {
		const onlyLowerCase = text.target.value.toLowerCase();
		setSearch(onlyLowerCase);
	};

	useEffect(() => {
		if (page == null) {
			setPage(1);
			return;
		}
		setPage(null);
	}, [search, selectedLocation, sort, descending]);

	useEffect(() => {
		handleTitle(t("orders.clients_label"));
	}, []);

	useEffect(() => {
		if (page === null) {
			setPage(1);
			return;
		}
		if (page > 1) {
			setShowPreviousPageButton(true);
		} else {
			setShowPreviousPageButton(false);
		}
		setLoading(true);
		setContent([]);
		const delay = 500;
		const delayDebounceFn = setTimeout(() => {
			setReadyToFetch(true);
		}, delay);

		return () => clearTimeout(delayDebounceFn);
	}, [page]);

	useEffect(() => {
		const boxHeightT =
			81 * content.length +
			(content.length === 0 ? 100 : 2) +
			(loading ||
			showNextPageButton ||
			showPreviousPageButton ||
			content.length === 0
				? 83
				: 0);
		setBoxHeight(boxHeightT);
	}, [loading, content]);

	return (
		<LandingAnimation>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: isMobile ? "center" : "end",
					marginBottom: "20px	"
					// width: "100%"
				}}
			>
				<Select
					value={fileType}
					onChange={(event) => setFileType(event.target.value)}
					sx={{
						width: isMobile ? "100%" : "120px",
						height: "46px",
						backgroundColor: ColorScheme.bg,
						border: "1px solid " + ColorScheme.tolotechWhite,
						marginRight: isDesktop && "10px",
						color: "white",
						"& .MuiSvgIcon-root": {
							color: "white"
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: "transparent"
						},
						marginBottom: isMobile && "20px",
						cursor: "pointer"
					}}
				>
					<MenuItem value="csv">CSV</MenuItem>
					<MenuItem value="excel">Excel</MenuItem>
				</Select>
				<Button
					disabled={loadingUserCsv}
					variant="contained"
					onClick={() => downloadCustomerList()}
					sx={{
						height: "46px",
						width: isMobile ? "100%" : "200px",
						/* py: "30px",
						px: "35px", */
						textTransform: "none",
						backgroundColor: ColorScheme.tolotechBlue,
						"&:hover": {
							backgroundColor: ColorScheme.tolotechDarkBlue
						}
					}}
				>
					{loadingUserCsv && (
						<Spinner small color={ColorScheme.tolotechWhite} />
					)}
					{!loadingUserCsv && (
						<Typography style={{ fontSize: "12px" }}>
							{t("buttons.load_client_csv")}
						</Typography>
					)}
				</Button>
			</div>
			<div style={{ position: "relative" }}>
				<TextField
					fullWidth
					variant="filled"
					label={t("textfield_labels.search")}
					InputLabelProps={{ style: { color: "grey" } }}
					inputProps={{
						style: {
							color: "black",
							backgroundColor: "rgb(240,240,240)",
							borderRadius: "4px 4px 0px 0px",
							border: "0px"
						}
					}}
					onChange={handleChange}
				/>

				<ClientSortingOptions
					sort={sort}
					setSort={setSort}
					descending={descending}
					setDescending={setDescending}
				/>

				<Box
					sx={{
						height: boxHeight + "px",
						backgroundColor: "white",
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						borderBottomLeftRadius: "4px",
						borderBottomRightRadius: "4px",
						width: "100%",
						transition: "all .2s ease-out",
						overflow: "hidden",
						flexDirection: "column"
					}}
				>
					{content.length === 0 && !loading && !search && (
						<div style={{ flex: 1.5 }} />
					)}

					{content.length === 0 && !loading && (
						<Typography sx={{ color: "black" }}>
							{search !== ""
								? t("clients.no_users_found")
								: page === 1
									? t("clients.no_users_yet")
									: t("clients.no_more_users")}
						</Typography>
					)}
					<div
						style={{
							backgroundColor: ColorScheme.tolotechWhite,
							borderBottomLeftRadius: "4px",
							borderBottomRightRadius: "4px",
							top: "100px",
							width: "100%"
						}}
					>
						{content.map((entry, i) => (
							<ClientCard
								setDialogUser={setDialogUser}
								key={i}
								user={entry}
								location={selectedLocation}
								isLast={i === content.length - 1}
							/>
						))}
					</div>
					{loading && (
						<Spinner small color={ColorScheme.tolotechBlue} />
					)}
					{content.length === 0 && !loading && !search && (
						<div style={{ flex: 1 }} />
					)}
					{(showNextPageButton || showPreviousPageButton) &&
						!loading && (
							<div
								style={{
									minHeight: "83px",
									flexDirection: "row",
									display: "flex",
									justifyContent: "space-around"
								}}
							>
								{
									<Box
										onClick={() =>
											showPreviousPageButton &&
											setPage(page - 1)
										}
										sx={[
											styles.button,
											{
												opacity: showPreviousPageButton
													? 1
													: 0.5
											}
										]}
									>
										<div style={{ rotate: "90deg" }}>
											<Arrow stroke={"blue"}></Arrow>
										</div>
									</Box>
								}
								<div
									style={{
										width: "100px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center"
									}}
								>
									<Typography sx={{ color: "black" }}>
										{t("clients.page") + " " + page}
									</Typography>
								</div>
								{
									<Box
										onClick={() =>
											showNextPageButton &&
											setPage(page + 1)
										}
										sx={[
											styles.button,
											{
												opacity: showNextPageButton
													? 1
													: 0.5
											}
										]}
									>
										<div style={{ rotate: "270deg" }}>
											<Arrow stroke={"blue"}></Arrow>
										</div>
									</Box>
								}
							</div>
						)}
				</Box>
				<ClientOrders
					dialogOpen={dialogUser !== null}
					closeDialog={setDialogUser}
					user={dialogUser}
					handleRefresh={() => setReadyToFetch(true)}
					location={selectedLocation}
				/>
			</div>
		</LandingAnimation>
	);
};
