import React, { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { ColorScheme } from "../../Themes/ColorScheme";
import { Checkbox, InputAdornment, Typography } from "@mui/material";
import { theme } from "../../Themes/Theme";
import { ReactComponent as DoorAccessIcon } from "../../Assets/Icons/DoorIcon_custom_stroke.svg";
import { ReactComponent as EquipmentIcon } from "../../Assets/Icons/ToolsIcon.svg";

const ITEM_HEIGHT = "auto";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

export const MultiChip = ({
	data,
	passSelectedData,
	current,
	isReservable,
	isEquipment,
	singleSelect,
	getLabel
}) => {
	const [dataNames, setDataNames] = useState([]);
	const [showSelect, setShowSelect] = useState(false);

	const filterTypes = data.filter((entry) => entry.type !== 3); // Hide type 3 services from door/equipment dropdown select

	const handleChange = (event) => {
		const {
			target: { value }
		} = event;

		setDataNames(typeof value === "string" ? value.split(",") : value);
	};

	const getDataNames = () => {
		const tempArray = [];

		if (isReservable && current !== undefined && current.length !== 0)
			current.forEach((reservable) => {
				filterTypes.forEach((entry) => {
					if (
						reservable.id === entry.id &&
						!tempArray.includes(entry.id)
					)
						tempArray.push(entry.id);
				});
			});
		else if (isEquipment)
			current?.service_devices?.forEach((entry) => {
				filterTypes.forEach((doors) => {
					if (
						entry.door_id === doors.door_id &&
						!tempArray.includes(doors.door_name)
					)
						tempArray.push(doors.door_name);
				});
			});
		else if (
			!isReservable &&
			Object.prototype.hasOwnProperty.call(current, "service_doors")
		)
			current.service_doors.forEach((entry) => {
				filterTypes.forEach((doors) => {
					if (
						entry.door_id === doors.door_id &&
						!tempArray.includes(doors.door_name)
					)
						tempArray.push(doors.door_name);
				});
			});

		if (tempArray.length > 0) setDataNames(tempArray);

		setShowSelect(true);
	};

	const selectedIds = useMemo(() => {
		if (dataNames.length === 0) return [];

		const tempArray = [];

		data.forEach((saveData) => {
			dataNames.forEach((entry) => {
				if (entry === (isReservable ? saveData.id : saveData.door_name))
					tempArray.push(
						Object.prototype.hasOwnProperty.call(
							saveData,
							"door_id"
						)
							? {
									id: saveData.door_id,
									name: saveData.door_name
								}
							: {
									id: saveData.id,
									name: saveData.name
								}
					);
			});
		});

		return tempArray;
	}, [dataNames, isReservable, data]);

	useEffect(() => {
		if (selectedIds.length === dataNames.length)
			passSelectedData(selectedIds);
	}, [selectedIds]);

	useEffect(() => {
		getDataNames();
	}, [data]);

	return (
		showSelect && (
			<FormControl sx={{ width: "100%" }}>
				{/* <InputLabel shrink htmlFor="MultiChipSelect">
						{isEquipment
							? undefined
							: t("courses.course_used_reservables")}
					</InputLabel> */}

				<Select
					labelId="MultiChipSelect"
					id="MultiChipSelect"
					multiple
					displayEmpty
					value={dataNames}
					onChange={handleChange}
					sx={{
						...theme.toloStyled.default_select,

						/* border: "solid 1px" + ColorScheme.textFieldBorder, */
						paddingLeft: "18px",
						backgroundColor: ColorScheme.textFieldBackground, // find a way to make this work from theme file. StyleOverride in components for MuiOulinedInputs is overwritting primary color with white color, won't change it yet because it's unknown how it will affect other components
						minHeight: "76px" // same for this one, because its not working properly from default_select
					}}
					input={
						<OutlinedInput
							notched={!isEquipment}
							// label={t("courses.course_used_reservables")}
							id="SelectChips"
							startAdornment={
								<div
									style={{
										padding: "10px",
										marginRight: "5px"
									}}
								>
									<InputAdornment position="start">
										{isEquipment ? (
											<EquipmentIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										) : (
											<DoorAccessIcon
												stroke={
													ColorScheme.defaultIconColor
												}
											/>
										)}
									</InputAdornment>
								</div>
							}
						/>
					}
					renderValue={(selected) => (
						<Box
							sx={{
								display: "flex",
								flexWrap: "wrap",
								gap: "10px",
								alignItems: "center"
							}}
						>
							<Typography
								fontWeight={400}
								fontSize={14}
								color={"rgba(0, 0, 0, 0.6)"}
							>
								{getLabel}
							</Typography>

							{selected.map((value, index) => {
								const chipLabel = data.find(
									(entry) => entry.id === value
								);

								return (
									<Chip
										key={index}
										label={
											isReservable
												? chipLabel.name
												: value
										}
										sx={theme.formStyles.multiChips}
									/>
								);
							})}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					<MenuItem value="" disabled>
						<Typography>{getLabel}</Typography>
					</MenuItem>

					{filterTypes.map((entry, index) => (
						<MenuItem
							key={index}
							disabled={
								singleSelect &&
								selectedIds.length === 1 &&
								entry.id !== selectedIds[0].id
							}
							value={isReservable ? entry.id : entry.door_name}
						>
							<Checkbox
								size="small"
								checked={
									dataNames !== undefined &&
									dataNames.indexOf(
										isReservable
											? entry.id
											: entry.door_name
									) > -1
								}
							/>
							{isReservable ? entry.name : entry.door_name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		)
	);
};
