import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const TimeSelectWeekdays = ({
	noWeekdays,
	passWeekdays,
	singleSelect,
	checkWeekdays
}) => {
	const { t } = useTranslation();

	const [testings, setTestings] = useState([]);
	const [disableRest, setDisableRest] = useState(false);
	const [weekdays, setWeekdays] = useState({
		su: false,
		ma: false,
		ti: false,
		ke: false,
		to: false,
		pe: false,
		la: false
	});

	const { ma, ti, ke, to, pe, la, su } = weekdays;

	const handleCheck = (event) => {
		const { name, checked } = event.target;

		setWeekdays((prevWeekdays) => ({
			...prevWeekdays,
			[name]: checked
		}));
	};

	const handleWeekdays = () => {
		// changed how checked days are processed, since we need to automatically process previous entries when editing
		// now we map all instead check one-by-one

		const updatedTestings = Object.values(weekdays)
			.map((day, index) => (day ? index : -1))
			.filter((index) => index !== -1);

		setTestings(updatedTestings);
	};

	useEffect(() => {
		if (!noWeekdays) handleWeekdays();
	}, [weekdays]);

	useEffect(() => {
		if (!noWeekdays) passWeekdays(testings.join(""));

		if (singleSelect && testings.length === 1) setDisableRest(true);
		if (singleSelect && testings.length === 0) setDisableRest(false);
	}, [testings]);

	useEffect(() => {
		// this receives existing entries as a string ("0123456"), which will be splitted to singular numbers and then looped over with date names marking all matches as true.
		if (checkWeekdays && typeof checkWeekdays === "string") {
			const weekdayIndices = checkWeekdays.split("").map(Number);

			const updatedWeekdays = { ...weekdays };

			weekdayIndices.forEach((weekdayIndex) => {
				const weekdayName = Object.keys(weekdays)[weekdayIndex];
				if (updatedWeekdays[weekdayName] !== undefined)
					updatedWeekdays[weekdayName] = true;
			});

			setWeekdays(updatedWeekdays);
		}
	}, [checkWeekdays]);

	return (
		<FormGroup
			row
			sx={{ justifyContent: "space-around" }}
			style={{ width: "100%" }}
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={ma}
						disabled={disableRest && !weekdays.ma}
						onChange={(day) => handleCheck(day)}
						name="ma"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.monday_short")}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={ti}
						disabled={disableRest && !weekdays.ti}
						onChange={(day) => handleCheck(day)}
						name="ti"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.tuesday_short")}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={ke}
						disabled={disableRest && !weekdays.ke}
						onChange={(day) => handleCheck(day)}
						name="ke"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.wednesday_short")}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={to}
						disabled={disableRest && !weekdays.to}
						onChange={(day) => handleCheck(day)}
						name="to"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.thursday_short")}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={pe}
						disabled={disableRest && !weekdays.pe}
						onChange={(day) => handleCheck(day)}
						name="pe"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.friday_short")}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={la}
						disabled={disableRest && !weekdays.la}
						onChange={(day) => handleCheck(day)}
						name="la"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.saturday_short")}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={su}
						disabled={disableRest && !weekdays.su}
						onChange={(day) => handleCheck(day)}
						name="su"
					/>
				}
				label={
					<Typography variant="form_bold">
						{t("dates.sunday_short")}
					</Typography>
				}
			/>
		</FormGroup>
	);
};
