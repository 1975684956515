import React from "react";
import { Grid, Typography, Stack, Badge } from "@mui/material";

import { ColorScheme } from "../../Themes/ColorScheme";

import { ReactComponent as MainIcon } from "../../Assets/Icons/MainpageIcon.svg";
import { ReactComponent as PinIcon } from "../../Assets/Icons/PinIcon.svg";
import { ReactComponent as CalendarIcon } from "../../Assets/Icons/CalendarIcon.svg";
import { ReactComponent as ClientsIcon } from "../../Assets/Icons/ClientsIcon.svg";
import { ReactComponent as MessagesIcon } from "../../Assets/Icons/MessagesIcon.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/Icons/LogoutIcon.svg";
import { ReactComponent as SettingsIcon } from "../../Assets/Icons/SettingsIcon.svg";
import { ReactComponent as DoorIcon } from "../../Assets/Icons/DoorIcon.svg";
import { ReactComponent as CampaignIcon } from "../../Assets/Icons/CampaignIcon.svg";
import { ReactComponent as ContactIcon } from "../../Assets/Icons/ContactIcon.svg";
import { ReactComponent as ElectricIcon } from "../../Assets/Icons/ElectricIcon.svg";
import { ReactComponent as ManualIcon } from "../../Assets/Icons/manuals.svg";
import { ReactComponent as StatIcon } from "../../Assets/Icons/MainIcon.svg";
import { ReactComponent as BalanceIcon } from "../../Assets/Icons/MoneybagIcon.svg";
import TooltipWrapper from "../Common/TooltipWrapper";

export const IconSwitchComponent = ({
	path,
	current_path,
	hasBadge,
	title,
	ignoreStyling,
	hasTooltip,
	isBlocked
}) => {
	const iconStrokeColor = ColorScheme.tolotechWhite;
	const width = 24;
	const height = 24;
	const strokeWidth = 1.5;

	const iconSwitch = () => {
		switch (path) {
			case "locations":
				return (
					<PinIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "calendar":
				return (
					<CalendarIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "customers":
				return (
					<ClientsIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "messages":
				return (
					<MessagesIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "login":
				return (
					<LogoutIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "settings":
				return (
					<SettingsIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "doors":
				return (
					<DoorIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "campaigns":
				return (
					<CampaignIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "contact":
				return (
					<ContactIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "charging":
				return (
					<ElectricIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "overview":
				return (
					<StatIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "residents":
				return (
					<ClientsIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "support":
				return (
					<ManualIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "balances":
				return (
					<BalanceIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			default:
				return (
					<MainIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
		}
	};

	return (
		<Stack
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				paddingTop: "20px",
				paddingBottom: "20px",
				transition: ".3s",
				scale: current_path === path ? "1.05" : "1",
				opacity: isBlocked ? "0.25" : undefined,
				background: isBlocked
					? "#bdbdbd"
					: !ignoreStyling &&
						current_path === path &&
						"-webkit-gradient(linear, left top, right top, from(#345480), color-stop(70%, #264166))"
			}}
		>
			<Grid item xs={!ignoreStyling ? 12 : 2}>
				<TooltipWrapper title={hasTooltip ? title : undefined}>
					<Badge color="error" variant="dot" invisible={!hasBadge}>
						{iconSwitch()}
					</Badge>
				</TooltipWrapper>
			</Grid>
			{!ignoreStyling && (
				<Grid item xs={6}>
					<Typography style={{ fontSize: 10, marginTop: "8px" }}>
						{title}
					</Typography>
				</Grid>
			)}
		</Stack>
	);
};
