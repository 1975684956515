import {
	Stack,
	TextField,
	Typography,
	Button,
	CircularProgress
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../ApiContext";
import { SubmitButton } from "./SubmitButton";

export const RegisterForm = ({ switchComponent, passUserEmail }) => {
	const [userEmail, setUserEmail] = useState("");
	const [registering, setRegistering] = useState(false);

	const [exists, setExists] = useState(false);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const registerNewAccount = (e) => {
		setRegistering(true);
		e.preventDefault();

		ApiCall("POST", "user", {
			email: userEmail
		})
			.then(() => {
				switchComponent("validation");
				passUserEmail(userEmail);
				setRegistering(false);
			})
			.catch((e) => {
				setRegistering(false);

				if (e === "email verified, reset password") setExists(true);
				// replaces register button with quick switch to login recovery
				else setUserEmail("");
			});
	};

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	useEffect(() => {
		setExists(false);
	}, [userEmail]); // hide error message and revert button functionality if email is altered

	return (
		<form onSubmit={registerNewAccount} style={{ width: "100%" }}>
			<Stack spacing={3}>
				<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
					{t("login.email_label")}*
				</Typography>

				<TextField
					placeholder={t("login.insert_email")}
					sx={{
						backgroundColor: "#FAFAFA",
						borderRadius: "5px"
					}}
					id="user_email"
					variant="outlined"
					margin="dense"
					fullWidth={true}
					size="small"
					error={exists} // error highlights textfield if user tries to register with email which is already used
					onChange={(e) => setUserEmail(e.target.value)}
				/>

				{exists /* renders error message so user doesn't have to guess why his registeration attempt was rejected */ && (
					<Typography fontSize="12px" color={"red"}>
						{t("login.email_in_use")}
					</Typography>
				)}

				<Stack
					style={{ justifyContent: "center", alignItems: "center" }}
				>
					{!registering ? (
						<Stack style={{ width: "100%" }} spacing={3}>
							{exists ? (
								<Button
									variant="standard"
									onClick={() => switchComponent("forgot")}
									sx={{
										backgroundColor:
											"rgba(255, 255, 255, 0.2)",
										color: "#FFFFFF",
										fontSize: "10px",
										width: 1,
										borderRadius: "4px",
										height: "44px"
									}}
								>
									{t("buttons.forgot_account_details")}
								</Button>
							) : (
								<SubmitButton
									text={t("buttons.register_account")}
									disabled={!validateEmail(userEmail)}
								/>
							)}
							<Button
								variant="standard"
								onClick={() => switchComponent("login")}
								sx={{
									backgroundColor: "rgba(255, 255, 255, 0.2)",
									color: "#FFFFFF",
									fontSize: "10px",
									width: 1,
									borderRadius: "4px",
									height: "44px"
								}}
							>
								{t("buttons.return_to_login")}
							</Button>
						</Stack>
					) : (
						<CircularProgress color="info" />
					)}
				</Stack>
			</Stack>
		</form>
	);
};
