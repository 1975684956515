import { Grid, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";

export const ProductRowTitles = ({ field }) => {
	const { t } = useTranslation();

	return (
		<Grid container key={uuid()}>
			<ListItem>
				<Grid item xs={4}>
					<ListItemText
						primary={
							field
								? t("product.product_space_label")
								: t("product.product_name_label")
						}
						primaryTypographyProps={{
							fontWeight: "700",
							color: "#FFFFFF"
						}}
						sx={{ minWidth: "35%" }}
					/>
				</Grid>

				<Grid item xs={4}>
					<ListItemText
						primary={field ? t("product.product_time_label") : ""}
						primaryTypographyProps={{
							fontWeight: "700",
							textAlign: "center",
							color: "#FFFFFF"
						}}
					/>
				</Grid>

				<Grid item sx={{ textAlign: "center" }} xs={4}>
					<ListItemText primary={""} />
				</Grid>
			</ListItem>
		</Grid>
	);
};
