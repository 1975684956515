import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ReservationCardMobile = ({ content, roundedNumber }) => {
	const { t } = useTranslation();

	const checkIfSameDate = () => {
		const startDate = new Date(content.start);
		const endDate = new Date(content.end);

		const formatDate = (date) =>
			`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

		if (startDate.toDateString() === endDate.toDateString()) {
			return formatDate(startDate);
		} else {
			return `${formatDate(startDate)} - ${formatDate(endDate)}`;
		}
	};

	const noSeconds = (value) => {
		const convert = new Date(value).toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit"
		});

		return convert;
	};

	return (
		<Box
			key={content.id}
			sx={{
				width: "inherit",
				height: "inherit"
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				spacing={2}
				sx={{
					padding: "10px",
					/* alignItems: "flex-end", */
					marginRight: "10px",
					alignItems: "center"
				}}
			>
				<Typography sx={{ fontSize: "11px" }}>
					{checkIfSameDate()}
				</Typography>
				<Typography sx={{ fontSize: "11px" }}>
					{noSeconds(content.start)}-{noSeconds(content.end)}
				</Typography>
				<Typography sx={{ fontSize: "11px" }}>
					{roundedNumber} {t("common.currency_symbol")}
				</Typography>
			</Stack>
		</Box>
	);
};
