import { Grid, Box, Stack, Typography } from "@mui/material";
import React from "react";
import uuid from "react-uuid";

import { RemoveUserButton } from "../RemoveUserButton";

export const ModifyReservation = ({ content, refreshReservations }) => (
	<>
		{content.users !== undefined && (
			<Stack sx={{ width: "100%" }} direction="row" spacing={2}>
				<Grid
					container
					sx={{
						width: "100%"
					}}
					justifyContent="space-between"
				>
					{content.users.map((name) => (
						<Box
							key={uuid()}
							sx={{
								padding: "15px",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<Stack>
								<Grid item>
									<Typography
										sx={{
											fontWeight: "700",
											fontSize: "16px"
										}}
									>
										{name.name}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										sx={{
											fontWeight: "700",
											fontSize: "14px",
											color: "rgba(0,0,0,0.5)"
										}}
									>
										{name.email}
									</Typography>
								</Grid>
							</Stack>
							<Grid
								xs={4}
								item
								sx={{
									display: "flex",
									justifyContent: "flex-end"
								}}
							>
								<RemoveUserButton
									text={true}
									locationId={content.id}
									email={name.email}
									refreshReservations={refreshReservations}
								/>
							</Grid>
						</Box>
					))}
				</Grid>
			</Stack>
		)}
	</>
);
