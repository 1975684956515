/* eslint-disable indent */
import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

// import { SnackBook } from "./Common/SnackBook";

export const SnackContext = createContext();

export const SnackProvider = ({ children }) => {
	const debug = false;

	const { t } = useTranslation();
	const [openSnack, setOpenSnack] = useState(false);
	const [getSeverity, setGetSeverity] = useState(null);
	const [snackMessage, setSnackMessage] = useState("");
	const [snackDisplay, setSnackDisplay] = useState("");

	const snackDelay = 3000;

	const handleSnackClose = (event, reason) => {
		if (reason === "clickaway") return;
	};

	const showSnackMessage = (url, status) => {
		if (debug) console.log(url, typeof status);

		if (status === undefined) return; // catch invalid calls to prevent error snack rendering for no reason

		const translationKey = `server_messages.${url}.${status}`;
		const isTranslationExists = translationKey !== t(translationKey);
		const isSuccessful = status >= 200 && status < 300;

		const messageKey = isTranslationExists
			? translationKey
			: isSuccessful
				? "server_messages.default_success"
				: "server_messages.default";

		setSnackDisplay(t(messageKey));
		setGetSeverity(isSuccessful ? "success" : "error");
		setOpenSnack(true);
	};

	// console.log(snackPath);

	return (
		<SnackContext.Provider
			value={{
				openSnack,
				setOpenSnack,
				getSeverity,
				setGetSeverity,
				snackMessage,
				setSnackMessage,
				handleSnackClose,
				snackDisplay,
				snackDelay,
				showSnackMessage
			}}
		>
			{children}
		</SnackContext.Provider>
	);
};
