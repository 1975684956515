import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Collapse, Typography } from "@mui/material";
import { ApiContext } from "../../../ApiContext";
import { useTranslation } from "react-i18next";
import { ConfirmAction } from "../../../Common/ConfirmAction";

import { UserContext } from "../../../UserContext";
import { Spinner } from "../../../Common/Spinner";

import { UserAdd } from "../../../Common/UserAdd";
import { MassAddForm } from "./MassAddForm";
import { ColorScheme } from "../../../../Themes/ColorScheme";

import ExampleFile from "../../../../Assets/Other/esimerkki.csv";
import ExampleExcel from "../../../../Assets/Other/esimerkki.xlsx";

export const LocationUsersList = () => {
	const debug = false;

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);

	const [users, setUsers] = useState([]);
	const [userEmail, setUserEmail] = useState("");

	const [showLoader, setShowLoader] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [userExists, setUserExists] = useState(false);
	const [emailInvalid, setEmailInvalid] = useState(false);

	const [isSingular, setIsSingular] = useState(true);
	const [isAnimating, setIsAnimating] = useState(false);
	/* const [selectedButton, setSelectedButton] = useState(true); */

	const [showError, setShowError] = useState(false);

	/* const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	}; */

	useEffect(() => {
		// Reset states
		setUsers([]);
		setUserEmail("");
		setShowLoader(false);
		setShowWarning(false);
		setUserExists(false);
		setEmailInvalid(false);
	}, [isSingular]);

	/* const switchContent = () => {
		setSelectedButton(!selectedButton);
		setIsAnimating(true);
	}; */

	useEffect(() => {
		if (isAnimating) {
			const contentElement = document.getElementById("content");
			const checkHeight = setInterval(() => {
				if (contentElement.style.maxHeight === "0px") {
					setIsSingular(!isSingular);
					setIsAnimating(false);
					clearInterval(checkHeight);
				}
			}, 750);
		}
	}, [isAnimating]);

	const getUsers = () => {
		ApiCall("GET", `client/location/${selectedLocation.location_id}/users`)
			.then((res) => {
				if (debug) console.log(res);
				setShowLoader(false);
				setUsers(res);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const addUser = () => {
		setShowLoader(true);
		let list = [];
		list.push(userEmail);
		setShowLoader(true);

		ApiCall(
			"POST",
			`client/location/${selectedLocation.location_id}/users`,
			list
		)
			.then((res) => {
				if (res.invalid.length > 0) {
					setShowError(true);
					setEmailInvalid(true);
				} else {
					setShowError(false);
					setEmailInvalid(false);
					setUserEmail("");
				}

				/* if (res.length > 0 || res.message === "no valid users to add") {
					setUserEmail(res[0]);
					setEmailInvalid(true);
					setShowWarning(true);
				} else {
					setUserEmail("");
					setUserExists(false);
					setEmailInvalid(false);
					setShowWarning(false);
				} */
				getUsers();
			})
			.catch((error) => {
				if (debug) console.log(error);
				getUsers();
			});
	};

	const handleRemove = (email) => {
		setShowLoader(true);

		ApiCall(
			"DELETE",
			`client/location/${selectedLocation.location_id}/users`,
			[email]
		)
			.then(() => {
				setShowLoader(false);
				getUsers();
			})
			.catch((error) => {
				setShowLoader(false);
				if (debug) console.log(error);
			});
	};

	const checkDuplicates = (text) => {
		const check = users.find((entry) => entry.email === text);
		setUserExists(check);
		setShowWarning(check);
	};

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					/* border: "1px solid" + ColorScheme.lightGrey, */
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.07)",
					borderRadius: "4px",
					overflow: "hidden",
					padding: "20px"
				}}
			>
				<div>
					<div>
						<Typography fontWeight={"700"}>
							{t("location.add_from_csv")}
						</Typography>
					</div>
					<div style={{ display: "flex", gap: "5px" }}>
						<Typography fontWeight={"400"} fontSize={"14px"}>
							{t("location.add_from_csv_description")}
						</Typography>
						<a href={ExampleFile} target="_blank" rel="noreferrer">
							<Typography sx={{ fontSize: "14px" }}>
								{"csv"}
							</Typography>
						</a>
						{
							<Typography
								sx={{
									color: ColorScheme.tolotechDarkBlue,
									fontSize: "14px",
									fontWeight: "400"
								}}
							>
								{"/"}
							</Typography>
						}
						<a href={ExampleExcel} target="_blank" rel="noreferrer">
							<Typography sx={{ fontSize: "14px" }}>
								{"excel"}
							</Typography>
						</a>
					</div>
				</div>

				<MassAddForm updateUserList={getUsers} />
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					/* border: "1px solid" + ColorScheme.lightGrey, */
					boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.07)",
					borderRadius: "4px",
					overflow: "hidden",
					padding: "20px"
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px"
					}}
				>
					<Typography fontWeight={700}>
						Lisää yksittäinen käyttäjä
					</Typography>
					<UserAdd
						onlyEmail={true}
						placeholder={t("location.form_email_label")}
						contact={userEmail}
						handleContactChange={(text) => {
							checkDuplicates(text);
							setUserEmail(text);
						}}
						handleAnotherUser={() => {
							addUser();
						}}
						showError={showWarning}
						errorMessage={
							emailInvalid
								? t("location.email_not_valid")
								: t("location.found_registered_email")
						}
						userExists={userExists}
					/>
					<div hidden={!isSingular || !showError}>
						<Collapse in={isSingular && showError}>
							<Alert severity="error">
								{t(
									"location.add_from_list_invalid_email_single"
								)}
							</Alert>
						</Collapse>
					</div>
				</div>
			</div>

			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					boxSizing: "border-box",
					borderRadius: "4px",
					width: "100%",
					margin: "auto",
					border:
						users.length === 0 ? undefined : "1px solid #F0F0F0",
					backgroundColor: "#FAFAFA"
				}}
			>
				{users.map((user, index) => (
					<React.Fragment key={index}>
						<Box
							sx={{
								maxWidth: "100%",
								flexDirection: "row",
								padding: "15px",
								// px: "40px",
								justifyContent: "space-between",
								display: "flex"
							}}
						>
							<div>
								<Typography
									style={{
										fontSize: 16,
										fontWeight: "700"
									}}
								>
									{user.name}
								</Typography>
								<Typography
									style={{
										fontSize: 12,
										fontWeight: "400",
										color: "grey"
									}}
								>
									{user.email}
								</Typography>
							</div>
							<ConfirmAction
								handleTitle={t("buttons.remove_from_list")}
								isIconButton={true}
								handleConfirmAction={() =>
									handleRemove(user.email)
								}
							/>
						</Box>
						{index !== users.length - 1 && (
							<div
								style={{
									height: "1px",
									width: "100%",
									backgroundColor: "#E4E4E4"
								}}
							/>
						)}
					</React.Fragment>
				))}
			</Box>
			{showLoader && <Spinner />}
		</Box>
	);
};
