/**
 * Handles server messages by converting the given property into a converted string,
 * fetching the translated message using the converted string, and returning the translated message.
 * If there is no translation available, it returns a default message with the original server error response message.
 *
 * @param {string} prop - The property to be handled.
 * @returns {string} - The translated message or default message with the original server error response message.
 */

import { useTranslation } from "react-i18next";

function useHandleServerMessage() {
	const { t } = useTranslation();

	return function handleServerMessage(prop) {
		console.log(prop);
		let convertedProp;
		// Convert spaces into underscores
		if (typeof prop === "string" && prop !== undefined) {
			convertedProp = prop.replace(/ /g, "_");
		} else {
			convertedProp = "undefined";
			console.error("prop is not a string:", prop);
		}
		console.log(convertedProp);

		// Fetch translated message using the converted string
		const translatedMessage = t(`server_error_responses.${convertedProp}`);
		// console.log(translatedMessage);

		// Return the translated message
		if (translatedMessage === `server_error_responses.${convertedProp}`)
			// if there is no translation, so default message with original server error response message
			return `${t("server_error_responses.default")} (${prop})`;
		return translatedMessage;
	};
}

export default useHandleServerMessage;
