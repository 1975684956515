import { Box, Collapse, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../Themes/ColorScheme";
import OnOffSlider from "../Common/Inputs/OnOffSlider";
import { TextfieldPicker } from "../Common/TextfieldPicker";

import { ReactComponent as RestrictionStartIcon } from "../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as RestrictionEndIcon } from "../../Assets/Icons/TimeEndIcon.svg";
import { ReactComponent as ArrowIcon } from "../../Assets/Icons/arrowBack.svg";
import { weekdays } from "../../Datafields/WeekdayList";
import ScreenWidth from "../../Helpers/ScreenWidth";
import { isDesktop } from "react-device-detect";

export const DoorUsageNew = ({ setWeekdays, days }) => {
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());

	const [restriction, setRestriction] = useState(false);

	const [initDone, setInitDone] = useState(false);

	const { t } = useTranslation();
	const weekdaysList = weekdays();
	const checkWidth = ScreenWidth();

	const handleStart = (e) => {
		setStartTime(e);
	};

	const handleEnd = (e) => {
		setEndTime(e);
	};

	const switchRestriction = (e) => {
		e.stopPropagation();
		setRestriction(!restriction);
	};

	function weekdayClicked(selectedDay) {
		if (
			days !== undefined &&
			days.length > 0 &&
			days.some((d) => d.weekday === selectedDay.weekday)
		) {
			let temp = days.filter(
				(day) => day.weekday !== selectedDay.weekday
			);
			setWeekdays(temp);
		} else {
			let temp = [...days];
			let tempDay = {
				open_full: false,
				weekday: selectedDay.weekday,
				start_time: startTime,
				end_time: endTime
			};
			temp.push(tempDay);
			setWeekdays(temp);
		}
	}

	useEffect(() => {
		if (initDone && !restriction) {
			let tempDays = [];
			for (let i = 0; i < weekdays.length; i++)
				tempDays.push({
					weekday: i,
					start_time: "2020-01-01T00:00:00.000Z",
					end_time: "2020-01-01T00:00:00.000Z",
					open_full: true
				});
			setWeekdays(tempDays);
		}
	}, [restriction]);

	useEffect(() => {
		if (initDone) {
			let tempDays = [...days];
			for (let i = 0; i < tempDays.length; i++) {
				tempDays[i].opens = startTime;
				tempDays[i].closes = endTime;
				tempDays[i].open_full = false;
			}
		}
	}, [startTime, endTime]);

	useEffect(() => {
		// old code wasn't working correctly with updated libraries when user selected a new day, it was resetting start and end time to null
		// previous code was causing massive setState loop, which is now fixed

		if (days && days.length > 0) {
			const hasNonFullOpenDays = days.some((day) => !day.open_full);

			if (hasNonFullOpenDays) {
				const firstNonFullOpenDay = days.find((day) => !day.open_full);
				setStartTime(new Date(firstNonFullOpenDay.opens));
				setEndTime(new Date(firstNonFullOpenDay.closes));
				setRestriction(true);
			} else {
				setRestriction(false);
			}

			if (!initDone) {
				setInitDone(true);
			}
		} else {
			if (days && days.length === 0) {
				setWeekdays([]);
			}

			if (!initDone) {
				setInitDone(true);
			}
		}
	}, [days]);

	/* 	// Calculate end of the month
	const calculateEndOfMonth = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth();
		const lastDay = new Date(year, month + 1, 1); // Change the last day to the first day of the next month
		return lastDay; // Returns a value which includes all the days of the current month
	};

	useEffect(() => {
		setEndTime(calculateEndOfMonth());
	}, []); */

	return (
		<>
			<Box
				sx={{
					flexDirection: "row",
					display: "flex",
					alignItems: "center",
					width: "100%",
					justifyContent: "space-between"
				}}
			>
				<Typography>{t("doors.restrict_opening_hours")}</Typography>
				<OnOffSlider
					labels
					textEnabled={false}
					status={restriction}
					action={switchRestriction}
				/>
			</Box>
			{initDone && (
				<Collapse in={restriction}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}}
					>
						{/* <AlertBar
							showAlert={true}
							type="info"
							standardVariant
							whiteSpacePreLine
							textContent={t(
								"doors.access_right_restricted_by_time"
							)}
						/> */}

						<div
							style={{
								display: "inherit",
								gap: "20px",
								flexDirection: isDesktop ? "row" : "column"
							}}
						>
							<TextfieldPicker
								value={startTime}
								isTimeRange={true}
								limitValues={true}
								fullDate={false}
								/* limitMax={endTime} */
								excludePast={false}
								noTime={false}
								label={t("textfield_labels.start_time")}
								valueChange={handleStart}
								hasProps={
									<RestrictionStartIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							{!checkWidth && (
								<ArrowIcon
									stroke="black"
									style={{
										transform: "rotate(180deg)",
										width: "80px",
										height: "80px"
									}}
								/>
							)}

							<TextfieldPicker
								value={endTime}
								isTimeRange={true}
								limitValues={true}
								fullDate={false}
								excludePast={false}
								/* limitMin={startTime} */
								noTime={false}
								label={t("textfield_labels.end_time")}
								valueChange={handleEnd}
								hasProps={
									<RestrictionEndIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</div>

						{/* <DayTimeSelect
							simpleSelectOnly={true}
							passStart={handleStart}
							passEnd={handleEnd}
							addSeparator={true}
							doorData={{
								end_time: endTime,
								start_time: startTime
							}}
							isVertical={false}
							dateStyle={false}
							hideLabels={true}
							smallLabel={true}
							fullDate={false}
						/> */}

						{/* <AlertBar
							showAlert={true}
							type="info"
							standardVariant
							whiteSpacePreLine
							textContent={t(
								"doors.access_right_restricted_weekdays"
							)}
						/> */}

						<Box
							sx={{
								flexDirection: "row",
								display: "flex",
								alignItems: "center",
								width: "100%",
								justifyContent: "space-between"
								/* my: "20px" */
							}}
						>
							{weekdaysList.map((day) => (
								<Box
									key={day.weekday}
									onClick={() => weekdayClicked(day)}
									style={{
										height: 40,
										width: 40,
										backgroundColor:
											days !== undefined &&
											days.some(
												(d) => d.weekday === day.weekday
											)
												? ColorScheme.tolotechBlue
												: "",
										border:
											"1px solid " +
											ColorScheme.tolotechBlue,
										borderRadius: 4,
										alignItems: "center",
										justifyContent: "center",
										display: "flex",
										cursor: "pointer"
									}}
								>
									<Typography
										sx={{
											fontSize: "16px",
											fontWeight: "700",
											color:
												days !== undefined &&
												days.some(
													(d) =>
														d.weekday ===
														day.weekday
												)
													? ColorScheme.tolotechWhite
													: ColorScheme.tolotechBlue
										}}
									>
										{day.short}
									</Typography>
								</Box>
							))}
						</Box>
					</div>
				</Collapse>
			)}
		</>
	);
};
