/* eslint-disable indent */
import { Avatar, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { SnackContext } from "../SnackContext";
import ImageUploadIcon from "../../Assets/Icons/ImageUpload.svg";
import { ApiContext } from "../ApiContext";
import Resizer from "react-image-file-resizer";
import { useTranslation } from "react-i18next";
import TooltipWrapper from "./TooltipWrapper";

export const ImageUpload = ({
	imageType,
	LocationId,
	oldImage,
	isCourseLogo,
	passCourseImage,
	removeImage
}) => {
	const debug = false;
	const [newImage, setNewImage] = useState(false);
	const [images, setImages] = useState([]);
	const [maxNumber, setMaxNumber] = useState("");
	const [imageTypeNum, setImageTypeNum] = useState("");

	const { setOpenSnack, setGetSeverity, setSnackMessage } =
		useContext(SnackContext);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	function urltoFile(url, filename, mimeType) {
		return fetch(url)
			.then((res) => res.arrayBuffer())
			.then((buf) => new File([buf], filename, { type: mimeType }));
	}

	const handleDelete = (e, image) => {
		// remove image file from imageList
		e.stopPropagation();
		removeImage(image.id);
	};

	const resizeFile = (file, size, type) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				size,
				size,
				type,
				100,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const onChange = (imageList) => {
		setImages(imageList);
		setNewImage(true);

		if (isCourseLogo) handleCourseImage(imageList);
		else handleUpload(imageList);
	};

	const handleUpload = (imageList) => {
		urltoFile(
			imageList[0].data_url,
			imageList[0].file.type + "",
			imageList[0].file.type + ""
		).then((res) => {
			let size =
				imageTypeNum === 1
					? 100
					: imageTypeNum === 2
						? 1000
						: imageTypeNum === 4
							? 600
							: imageTypeNum === 5
								? 300
								: imageTypeNum === 6
									? 300
									: 500;
			let type = imageList[0].file.type.substring(6).toUpperCase();
			resizeFile(res, size, type).then((res) => {
				const imageData = {
					image_type: imageTypeNum,
					object_id: LocationId,
					image: res
				};

				ApiCall("POST", "location/add_images", imageData).catch(
					(error) => {
						if (debug) console.log(error);
					}
				);
			});
		});
	};

	const handleCourseImage = (imageList) => {
		urltoFile(
			imageList[0].data_url,
			imageList[0].file.type + "",
			imageList[0].file.type + ""
		).then((res) => {
			let size = 300;
			let type = imageList[0].file.type.substring(6).toUpperCase();
			resizeFile(res, size, type).then((res) => {
				passCourseImage(res);
			});
		});
	};

	const imageSwitch = () => {
		switch (imageType) {
			case "logo":
				return oldImage.image;

			case "course":
				return oldImage;

			case "product":
				return oldImage;

			case "banner":
				return oldImage[0].image;

			case "other":
				return oldImage[0].image;

			default:
				return ImageUploadIcon;
		}
	};

	useEffect(() => {
		switch (imageType) {
			case "other":
				setMaxNumber(6);
				setImageTypeNum(4);

				break;
			case "banner":
				setMaxNumber(1);
				setImageTypeNum(2);

				break;
			case "logo":
				setMaxNumber(1);
				setImageTypeNum(1);

				break;
			case "course":
				setMaxNumber(1);
				setImageTypeNum(6);

				break;
			case "product":
				setMaxNumber(1);
				setImageTypeNum(5);

				break;

			default:
				break;
		}
	}, []);

	useEffect(() => {
		setNewImage(false);
		setImages([]);
	}, [LocationId]);

	return (
		<ImageUploading
			/* multiple={imageType === "other"}*/
			value={images}
			onChange={onChange}
			maxNumber={maxNumber}
			dataURLKey="data_url"
			acceptType={["jpg", "jpeg", "png"]}
		>
			{({
				imageList,
				onImageUpload,
				/* onImageRemoveAll,*/
				onImageUpdate,
				/* onImageRemove,*/
				isDragging,
				dragProps,
				errors
			}) => {
				useEffect(() => {
					setGetSeverity("error");

					if (errors !== null) {
						if (errors.maxFileSize) setSnackMessage("maxFileSize");
						if (errors.acceptType) setSnackMessage("acceptType");
						if (errors.maxNumber) setSnackMessage("maxNumber");
						if (errors.resolution) setSnackMessage("resolution");

						setOpenSnack(true);
					}
				}, [errors]);

				return (
					<div className="upload__image-wrapper">
						<Stack
							direction="column"
							justifyContent="space-between"
							alignItems="flex-start"
						>
							<Button
								variant={
									imageType === "logo"
										? "logoUpload"
										: "bannerUpload"
								}
								style={
									(isDragging ? { color: "red" } : undefined,
									{
										padding: "20px",
										height: "100%",
										width: "100%"
									})
								}
								onClick={
									newImage
										? () => onImageUpdate()
										: () => onImageUpload()
								}
								{...dragProps}
							>
								{imageList !== undefined &&
									imageList.map((image, index) => (
										<Avatar
											key={index}
											sx={{
												width: imageType
													? "100px"
													: "fit-content",
												height: "100%",
												borderRadius: 0,
												objectFit: "cover"
											}}
											src={image.data_url}
											alt=""
										/>
									))}

								{imageList.length === 0 &&
									oldImage.length === 0 && (
										<Typography
											color={"black"}
											fontWeight="600"
											textTransform={"none"} // required, otherwise text will be all uppercase
										>
											{t("images.add_new_image_guide")}
										</Typography>
									)}

								{imageType !== "other" &&
									images.length < maxNumber &&
									oldImage.length !== 0 && (
										<Avatar
											sx={{
												width: "200px",
												height: "100%",
												borderRadius: 0,
												objectFit: "contain"
											}}
											src={imageSwitch()}
											alt=""
										/>
									)}

								{imageType === "other" && (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											flexWrap: "wrap",
											justifyContent: "center"
										}}
									>
										{oldImage.map((img, index) => (
											<div
												key={index}
												style={{
													position: "relative",
													width: "100px",
													height: "100px",
													margin: "5px"
												}}
											>
												<img
													src={img.image}
													style={{
														width: "100%",
														height: "100%",
														objectFit: "cover"
													}}
												/>
												<TooltipWrapper
													title={t(
														"location.form_location_delete_image_label"
													)}
												>
													<button
														style={{
															position:
																"absolute",
															top: 0,
															right: 0,
															background:
																"rgba(255, 255, 255, 0.7)",
															border: "none",
															fontSize: "1em",
															padding: "2px 5px",
															cursor: "pointer"
														}}
														onClick={(e) =>
															handleDelete(e, img)
														}
													>
														&#10005;
													</button>
												</TooltipWrapper>
											</div>
										))}
									</div>
								)}
							</Button>
						</Stack>
					</div>
				);
			}}
		</ImageUploading>
	);
};
