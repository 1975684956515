import { Dialog, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext, useState } from "react";
import { RecipeModal } from "./RecipeModal";
import { isDesktop } from "react-device-detect";
import { RecipeModalMobile } from "./RecipeModalMobile";
import { ApiContext } from "../../../ApiContext";

export const ReservationRecipeCard = ({ data, status, isProduct, refresh }) => {
	const { ApiCall } = useContext(ApiContext);
	const [hovered, setHovered] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [showError, setShowError] = useState([false, ""]); // [show, message]

	const handleModalClose = () => {
		setOpenModal(false);
		setShowError([false, ""]);
		refresh();
	};

	const successfulSave = () => {
		setOpenModal(false);
		setShowError([false, ""]);
		refresh();
		// refreshList();
	};

	const axiosPath =
		isProduct === 1
			? `client/reservable_time/update/${data.id}`
			: `client/product/${data.id}`;

	const handleSave = (props) => {
		// console.log(props);
		ApiCall("PUT", axiosPath, {
			valid_from: props.valid_from,
			valid_until: props.valid_until,
			from_time: props.from_time,
			to_time: props.to_time,
			minimum_reservation: props.minimum_reservation,
			user_limit: props.user_limit,
			weekday: props.weekday,
			short_reservable_id: props.short_reservable_id,
			vat_price: props.vat_price
		})
			.then(() => {
				successfulSave();
			})
			.catch((error) => {
				console.log(error);

				setShowError([true, error]);
			});
	};

	const handleDelete = (id) => {
		ApiCall("DELETE", `client/reservable_time/remove/${id}`, null)
			.then(() => handleModalClose())
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<Dialog open={openModal} onClose={handleModalClose}>
				{isDesktop ? (
					<RecipeModal
						data={data}
						handleClose={handleModalClose}
						handleSave={handleSave}
						showError={showError}
						handleDelete={handleDelete}
					/>
				) : (
					<RecipeModalMobile
						data={data}
						handleClose={handleModalClose}
						handleSave={handleSave}
						showError={showError}
						handleDelete={handleDelete}
					/>
				)}
			</Dialog>

			<Stack
				onMouseOver={() => {
					setHovered(true);
				}}
				onMouseOut={() => {
					setHovered(false);
				}}
				direction="row"
				style={{
					backgroundColor: status.statusCode[0]?.primary,
					opacity: hovered ? 0.9 : 1, // hover effect
					translate: "all 0.5s ease",
					/* padding: "10px", */
					alignItems: "center",
					display: "flex",
					justifyContent: "space-between",
					padding: "20px",
					cursor: "pointer"
				}}
				onClick={(e) => {
					e.stopPropagation();
					setOpenModal(true);
				}}
			>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Typography
						sx={{
							fontSize: "18px",
							fontWeight: "600",
							color: "black"
						}}
					>
						{moment(data.from_time).format("HH:mm")}-
						{moment(data.to_time).format("HH:mm")}
					</Typography>
					<Typography
						sx={{
							fontSize: "16px",
							color: "#969696",
							fontWeight: "600"
						}}
					>
						{status.statusCode[1]}
					</Typography>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						backgroundColor: status.statusCode[0]?.secondary,
						border: "1px solid " + status.statusCode[0]?.secondary,
						borderRadius: "25px",
						paddingRight: "25px",
						paddingLeft: "25px",
						paddingTop: "10px",
						paddingBottom: "10px",
						gap: "10px"
					}}
				>
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "600",
							color: "white"
						}}
					>
						{data.minimum_reservation} min
					</Typography>
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "600",
							color: "white"
						}}
					>
						{data.vat_price} €
					</Typography>
				</div>
			</Stack>
		</div>
	);
};
