import { useTranslation } from "react-i18next";

export const Locale = () => {
	const { t } = useTranslation();

	const DateNames = {
		months: [
			t("dates.january_long"),
			t("dates.february_long"),
			t("dates.march_long"),
			t("dates.april_long"),
			t("dates.may_long"),
			t("dates.june_long"),
			t("dates.july_long"),
			t("dates.august_long"),
			t("dates.september_long"),
			t("dates.october_long"),
			t("dates.november_long"),
			t("dates.december_long")
		],
		monthsShort: [
			t("dates.january_short"),
			t("dates.february_short"),
			t("dates.march_short"),
			t("dates.april_short"),
			t("dates.may_short"),
			t("dates.june_short"),
			t("dates.july_short"),
			t("dates.august_short"),
			t("dates.september_short"),
			t("dates.october_short"),
			t("dates.november_short"),
			t("dates.december_short")
		],
		weekdays: [
			t("dates.sunday_long"),
			t("dates.monday_long"),
			t("dates.tuesday_long"),
			t("dates.wednesday_long"),
			t("dates.thursday_long"),
			t("dates.friday_long"),
			t("dates.saturday_long")
		],
		weekdaysShort: [
			t("calendar.sunday_short"),
			t("calendar.monday_short"),
			t("calendar.tuesday_short"),
			t("calendar.wednesday_short"),
			t("calendar.thursday_short"),
			t("calendar.friday_short"),
			t("calendar.saturday_short")
		],
		longDateFormat: {
			LT: "HH:mm",
			LTS: "HH:mm:ss",
			L: "DD/MM/YYYY",
			LL: "D MMMM YYYY",
			LLL: "D MMMM YYYY HH:mm",
			LLLL: "dddd D MMMM YYYY HH:mm"
		},
		week: {
			dow: 1,
			doy: 1
		}
	};

	const CalendarButtons = {
		allDay: t("calendar.whole_day"),
		next: t("calendar.next_day"),
		previous: t("calendar.previous_day"),
		today: t("calendar.today"),
		month: t("calendar.month"),
		week: t("calendar.week"),
		day: t("calendar.day"),
		agenda: t("calendar.agenda"),
		date: t("calendar.date"),
		time: t("calendar.time"),
		event: t("calendar.event"),
		tomorrow: t("calendar.tomorrow"),
		yesterday: t("calendar.yesterday"),
		showMore: (total) => `+${total} ${t("calendar.show_more")}`
	};

	const TimeFormat = {
		timeGutterFormat: (date, culture, localizer) =>
			localizer.format(date, "HH:mm", culture),
		eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
			localizer.format(start, "HH:mm", culture) +
			" - " +
			localizer.format(end, "HH:mm", culture),
		agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
			localizer.format(start, "HH:mm", culture) +
			" - " +
			localizer.format(end, "HH:mm", culture),
		dayFormat: "ddd DD"
	};

	return { DateNames, CalendarButtons, TimeFormat };
};
