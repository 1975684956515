import React, { useContext, useEffect, useState } from "react";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { ReactComponent as MailIcon } from "../../../Assets/Icons/EnvelopeIcon.svg";
import { ReactComponent as PhoneIcon } from "../../../Assets/Icons/OldPhoneIcon.svg";
import { ReactComponent as UserIcon } from "../../../Assets/Icons/ProfileIcon.svg";
import {
	Box,
	Collapse,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import { ApiContext } from "../../ApiContext";
import { Spinner } from "../../Common/Spinner";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { ReactComponent as CarIcon } from "../../../Assets/Icons/CarIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../Assets/Icons/UserIcon.svg";
import { areaCodesArray } from "../../../Helpers/AreaCodes";
import OnOffSlider from "../../Common/Inputs/OnOffSlider";

import ToloGoBall from "../../../Assets/Images/Tolotech_logo_white_new.png";
import { SnackContext } from "../../SnackContext";
import { AlertBar } from "../../Common/AlertBar";
import { useTranslation } from "react-i18next";

const styles = {
	iconContainer: {
		height: "20px",
		minHeight: "20px",
		maxHeight: "20px",
		width: "20px",
		minWidth: "20px",
		maxWidth: "20px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		boxSizing: "border-box",
		marginLeft: "10px"
	},
	inputContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: "4px",
		width: "100%",
		backgroundColor: "#F5F5F5",
		marginBottom: "10px",
		alignSelf: "center",
		boxSizing: "border-box",
		border: "1px solid #D9D9D9"
	},
	inputHeader: {
		fontSize: "12px",
		color: ColorScheme.tolotechDarkBlue,
		fontWeight: "700",
		marginTop: 0
	}
};

export const KeyAddAccess = ({ data, handleRefresh, users }) => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const { showSnackMessage } = useContext(SnackContext);
	const [selectedMode, setSelectedMode] = useState("email");
	const [email, setEmail] = useState("");
	const [areaNumber, setAreaNumber] = useState("+358");
	const [phone, setPhone] = useState("");
	const [licensePlate, setLicensePlate] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	/* const [showError, setShowError] = useState(false); */
	const [selectedStartTime, setSelectedStartTime] = useState(new Date());
	const [selectedEndTime, setSelectedEndTime] = useState(new Date());
	const [openStartTime, setOpenStartTime] = useState(false);
	const [openEndTime, setOpenEndTime] = useState(false);
	const [timeRestrictions, setTimeRestrictions] = useState(false);

	const [changingTo, setChangingTo] = useState(null);

	const handleModeChange = (mode) => {
		resetValues();
		setChangingTo(mode);
	};

	const handleAreaChange = (event) => {
		setAreaNumber(event.target.value);
	};

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const validatePhone = (phone) => {
		if (phone.length > 0) {
			if (phone.charAt(0) === "0") {
				return false;
			}
		}

		const re = /^\d{6,}$/;
		return re.test(phone);
	};

	const switchTimeRestrictions = (e) => {
		e.stopPropagation();

		setTimeRestrictions(!timeRestrictions);
	};

	const validateLicensePlate = (licensePlate) => {
		const re = /^[A-Z0-9]{1,3}(-?[A-Z0-9]{1,3})?$/;
		return re.test(licensePlate);
	};

	const validateEntry = () => {
		if (selectedMode === "email") return validateEmail(email);
		else {
			if (phone === "" && licensePlate === "") {
				return false;
			}
		}
		return true;
	};

	const isDuplicate = (value) => {
		if (value.trim() === "")
			// Handle empty value case here, return false or handle accordingly
			return false;

		// Rest of your duplicate checking logic
		const check = users?.some((user) =>
			selectedMode === "email"
				? user.email === value
				: user.phone === value
		);

		return check;
	};

	const handleClick = () => {
		if (validateEntry()) handleSave();
	};

	function isDisabled() {
		if (selectedMode === "email") {
			if (email === "") {
				return t("textfield_labels.add_email");
			}
			if (validateEmail(email) === false) {
				return t("textfield_labels.email_invalid");
			}
		} else if (selectedMode === "phone") {
			if (phone === "" && licensePlate === "") {
				return t("textfield_labels.add_phone_or_license_plate");
			}
			if (phone !== "" && validatePhone(phone) === false) {
				return t("textfield_labels.phone_number_invalid");
			}
			if (
				licensePlate !== "" &&
				validateLicensePlate(licensePlate) === false
			) {
				return t("textfield_labels.license_plate_invalid");
			}
			if (name === "") {
				return t("textfield_labels.add_name");
			}
		}
		return "";
	}

	const handleSave = () => {
		setIsLoading(true);
		ApiCall("POST", "client/residents", [
			{
				email,
				phone: phone !== "" ? areaNumber + phone : "",
				license_plate: licensePlate.replace(/-/g, ""), // Remove "-" characters from license plate
				description,
				name,
				reservable_id: data.id,
				...(selectedStartTime !== null &&
					timeRestrictions && { from: selectedStartTime }),
				...(selectedEndTime !== null &&
					timeRestrictions && { to: selectedEndTime })
			}
		])
			.then(() => {
				resetValues();
				handleRefresh();

				showSnackMessage("general", 200);
			})
			.catch(() => {
				/* setShowError(true); */
				// console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	function resetValues() {
		setEmail("");
		setDescription("");
		setLicensePlate("");
		setName("");
		setPhone("");
		setSelectedStartTime(new Date());
		setSelectedEndTime(new Date());
	}

	useEffect(() => {
		if (changingTo !== null) {
			setTimeout(() => {
				setSelectedMode(changingTo);
				setChangingTo(null);
			}, 300);
		}
	}, [changingTo]);

	return (
		<>
			<div style={{ width: "100%", backgroundColor: "white" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						cursor: "pointer"
					}}
				>
					{["email", "phone"].map((mode) => (
						<div
							key={mode}
							style={{
								display: "flex",
								flexDirection: "row",
								width: "50%",
								padding: "15px",
								alignItems: "center",
								backgroundColor: ColorScheme.tolotechWhite
							}}
							onClick={() => handleModeChange(mode)}
						>
							<div
								style={{
									borderRadius: "4px",
									backgroundColor:
										mode === "email"
											? ColorScheme.tolotechBlue
											: ColorScheme.green,
									padding: "4px",
									display: "flex",
									opacity: selectedMode === mode ? 1 : 0.7
								}}
							>
								{mode === "email" ? (
									<img
										style={{
											width: "16px",
											height: "16px"
										}}
										src={ToloGoBall}
									></img>
								) : (
									<PhoneIcon
										strokeWidth={2}
										stroke={ColorScheme.tolotechWhite}
										height={"16px"}
										width={"16px"}
									/>
								)}
							</div>
							<Typography
								fontSize="16px"
								sx={{
									width: "100%",
									textAlign: "center",
									color:
										selectedMode === mode ? "black" : "grey"
								}}
							>
								{mode === "email"
									? t("textfield_labels.tologo_user")
									: t("textfield_labels.other_user")}
							</Typography>
						</div>
					))}
				</div>
				{/* Indicator bar */}
				<div
					style={{
						flexDirection: "row",
						display: "flex",
						width: "100%",
						justifyContent: "flex-start"
					}}
				>
					<div
						style={{
							width:
								changingTo === "email" ||
								(changingTo === null &&
									selectedMode === "email")
									? "0px"
									: "50%",
							transition: "all .15s ease-out"
						}}
					></div>
					<div
						style={{
							width: "50%",
							height: "4px",
							backgroundColor: ColorScheme.tolotechBlue
						}}
					></div>
				</div>
				<Collapse in={changingTo === null}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							borderTop: `1px solid ${ColorScheme.lightGrey}`,
							padding: "20px"
						}}
					>
						{
							<AlertBar
								textContent={
									selectedMode === "email"
										? t("key_management.add_tologo_user")
										: t(
												"key_management.add_non_tologo_user"
											)
								}
								type="info"
								showAlert={true}
								standardVariant={"standard"}
							/>
						}
						{1 + data.user_count > data.user_limit && (
							<AlertBar
								textContent={t("key.no_space_for_new_users")}
								type="error"
								showAlert={true}
								standardVariant={"standard"}
							></AlertBar>
						)}
						{1 + data.user_count <= data.user_limit &&
							selectedMode === "email" && (
								<>
									<Box style={styles.inputContainer}>
										<Box style={styles.iconContainer}>
											<MailIcon
												stroke={
													ColorScheme.tolotechDarkBlue
												}
												strokeWidth="1.7px"
											/>
										</Box>
										<TextField
											on
											variant="filled"
											label={t("textfield_labels.email")}
											placeholder={t(
												"textfield_labels.input_email"
											)}
											sx={{
												border: "none",
												width: "100%",
												flex: 1
											}}
											InputProps={{
												disableUnderline: true,
												style: {
													color: "black",
													backgroundColor:
														"transparent",
													borderRadius: "20px",
													border: "0px solid rgba(0,0,0,0)",
													fontSize: "15px"
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: "14px",
													top: "3px"
												}
											}}
											value={email}
											onChange={(event) => {
												let inputValue =
													event.target.value;
												setEmail(inputValue);
											}}
										/>
									</Box>
									<Box style={styles.inputContainer}>
										<Box style={styles.iconContainer}>
											<UserIcon
												stroke={
													ColorScheme.tolotechDarkBlue
												}
												strokeWidth="1.7px"
											/>
										</Box>
										<TextField
											variant="filled"
											label={t(
												"textfield_labels.description"
											)}
											placeholder={t(
												"textfield_labels.input_description"
											)}
											sx={{
												width: "100%"
											}}
											InputProps={{
												disableUnderline: true,
												style: {
													color: "black",
													backgroundColor:
														"transparent",
													borderRadius: "0px",
													fontSize: "15px"
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: "14px",
													top: "3px"
												}
											}}
											value={description}
											onChange={(text) =>
												setDescription(
													text.target.value
												)
											}
										/>
									</Box>
								</>
							)}

						{1 + data.user_count <= data.user_limit &&
							selectedMode === "phone" && (
								<>
									<Box style={styles.inputContainer}>
										<Box style={styles.iconContainer}>
											<ProfileIcon
												stroke={
													ColorScheme.tolotechDarkBlue
												}
												strokeWidth="2px"
											/>
										</Box>
										<TextField
											label={t("textfield_labels.name")}
											variant="filled"
											placeholder={t(
												"textfield_labels.input_name"
											)}
											sx={{
												border: "none",
												width: "100%"
											}}
											InputProps={{
												disableUnderline: true,
												style: {
													color: "black",
													backgroundColor:
														"transparent",
													borderRadius: "20px",
													border: "0px solid rgba(0,0,0,0)",
													fontSize: "15px"
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: "14px",
													top: "3px"
												}
											}}
											value={name}
											onChange={(event) => {
												let inputValue =
													event.target.value;
												setName(inputValue);
											}}
										/>
									</Box>

									<Box style={styles.inputContainer}>
										<Box style={styles.iconContainer}>
											<PhoneIcon
												stroke={
													ColorScheme.tolotechDarkBlue
												}
												strokeWidth="1.7px"
											/>
										</Box>
										<Select
											value={areaNumber}
											label={
												areaNumber ? "" : "Suuntanumero"
											}
											onChange={handleAreaChange}
											sx={{
												height: "30px",
												marginLeft: "10px"
											}}
											renderValue={() => areaNumber}
										>
											{areaCodesArray.map((areaCode) => (
												<MenuItem
													key={areaCode.code}
													value={areaCode.code}
												>
													{areaCode.country +
														" " +
														areaCode.code}
												</MenuItem>
											))}
										</Select>
										<TextField
											label={t("textfield_labels.phone")}
											variant="filled"
											placeholder={t(
												"textfield_labels.input_phone"
											)}
											notched={false}
											sx={{
												border: "none",
												width: "100%"
											}}
											InputProps={{
												disableUnderline: true,
												style: {
													color: "black",
													backgroundColor:
														"transparent",
													borderRadius: "20px",
													border: "0px solid rgba(0,0,0,0)",
													fontSize: "15px"
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: "14px",
													top: "3px"
												}
											}}
											value={phone}
											onChange={(event) => {
												let inputValue =
													event.target.value;
												setPhone(inputValue);
											}}
										/>
									</Box>

									<Box style={styles.inputContainer}>
										<Box style={styles.iconContainer}>
											<CarIcon
												stroke={
													ColorScheme.tolotechDarkBlue
												}
												strokeWidth="1.7px"
											/>
										</Box>
										<TextField
											label={t(
												"textfield_labels.license_plate"
											)}
											variant="filled"
											placeholder={t(
												"textfield_labels.input_license_plate"
											)}
											sx={{
												border: "none",
												width: "100%"
											}}
											InputProps={{
												disableUnderline: true,
												style: {
													color: "black",
													backgroundColor:
														"transparent",
													borderRadius: "20px",
													border: "0px solid rgba(0,0,0,0)",
													fontSize: "15px"
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: "14px",
													top: "3px"
												}
											}}
											value={licensePlate}
											onChange={(event) => {
												let inputValue =
													event.target.value;
												setLicensePlate(inputValue);
											}}
										/>
									</Box>

									<Box style={styles.inputContainer}>
										<Box style={styles.iconContainer}>
											<UserIcon
												stroke={
													ColorScheme.tolotechDarkBlue
												}
												strokeWidth="1.7px"
											/>
										</Box>
										<TextField
											label={t(
												"textfield_labels.description"
											)}
											variant="filled"
											placeholder={t(
												"textfield_labels.input_description"
											)}
											sx={{
												width: "100%"
												/* px: "15px", */
											}}
											InputProps={{
												disableUnderline: true,
												style: {
													color: "black",
													backgroundColor:
														"transparent",
													borderRadius: "0px",
													fontSize: "15px"
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: "14px",
													top: "3px"
												}
											}}
											value={description}
											onChange={(text) =>
												setDescription(
													text.target.value
												)
											}
										/>
									</Box>
								</>
							)}
						{1 + data.user_count <= data.user_limit && (
							<>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
										backgroundColor: "white"
									}}
								>
									<Typography
										variant="form_bold"
										fontSize={14}
										color={"black"}
									>
										{t("key.optional_time_restrictions")}
									</Typography>

									<OnOffSlider
										labels
										textEnabled={false}
										status={timeRestrictions}
										action={switchTimeRestrictions}
									/>
								</div>
								{
									<Collapse in={timeRestrictions}>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												marginBottom: "20px"
											}}
										>
											<div
												onClick={() => {
													if (!openStartTime)
														setOpenStartTime(true);
												}}
											>
												<DatePicker
													open={openStartTime}
													onClose={() =>
														setOpenStartTime(false)
													}
													value={
														new Date(
															selectedStartTime
														)
													}
													onChange={(newValue) => {
														const timezoneRemoval =
															moment(
																newValue
															).utc(true);
														setSelectedStartTime(
															timezoneRemoval
														);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															/* onClick={() => setOpenStartTime(true)}
											onTouchEnd={() => setOpenStartTime(true)} */
															fullWidth
															sx={{
																".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
																	{
																		border: "1px solid #D9D9D9"
																	}
															}}
															inputProps={{
																...params.inputProps,
																style: {
																	textAlign:
																		"center",
																	cursor: "pointer"
																},
																placeholder:
																	t(
																		"textfield_labels.starts"
																	) + "..."
															}}
															InputProps={{
																disableUnderline: true,
																style: {
																	color: "black",
																	backgroundColor:
																		"#F5F5F5",
																	borderRadius:
																		"4px"
																}
															}}
														/>
													)}
												/>
											</div>
											<div
												style={{ width: "10px" }}
											></div>
											<Typography sx={{ color: "black" }}>
												{" - "}
											</Typography>
											<div
												style={{ width: "10px" }}
											></div>

											<div
												onClick={() => {
													if (!openEndTime)
														setOpenEndTime(true);
												}}
											>
												<DatePicker
													open={openEndTime}
													onClose={() =>
														setOpenEndTime(false)
													}
													onClick={() =>
														setOpenEndTime(true)
													}
													value={
														new Date(
															selectedEndTime
														)
													}
													onChange={(newValue) => {
														const timezoneRemoval =
															moment(
																newValue
															).utc(true);

														setSelectedEndTime(
															timezoneRemoval
														);
													}}
													minTime={selectedStartTime}
													renderInput={(params) => (
														<TextField
															{...params}
															/* onClick={() => setOpenEndTime(true)}
											onTouchEnd={() => setOpenEndTime(true)} */
															fullWidth
															sx={{
																".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
																	{
																		border: "1px solid #D9D9D9"
																	}
															}}
															inputProps={{
																...params.inputProps,
																style: {
																	textAlign:
																		"center",
																	cursor: "pointer"
																},
																placeholder:
																	t(
																		"textfield_labels.ends"
																	) + "..."
															}}
															InputProps={{
																disableUnderline: true,
																style: {
																	color: "black",
																	backgroundColor:
																		"#F5F5F5",
																	borderRadius:
																		"4px"
																}
															}}
														/>
													)}
												/>
											</div>
										</div>
									</Collapse>
								}
								<div
									style={{
										width: "100%",
										height: "50px",
										boxSizing: "border-box",
										display: "flex",
										alignItems: "center",
										justifyContent: "center"
									}}
								>
									<div
										style={{
											width: "100%",
											height: "100%",
											textAlign: "center",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											borderRadius: "4px",
											transition:
												"background-color 0.3s ease-in-out",
											backgroundColor:
												ColorScheme.tolotechBlue,
											opacity:
												isDisabled() !== "" ? 0.5 : 1,
											cursor:
												isDisabled() !== ""
													? "cursor"
													: "pointer"
										}}
										onClick={
											isDisabled() !== ""
												? undefined
												: handleClick
										}
									>
										{isLoading ? (
											<Spinner color={"white"} small />
										) : isDuplicate(email) ? (
											<Typography>
												{selectedMode === "email"
													? t(
															"textfield_labels.email_in_use"
														)
													: t(
															"textfield_labels.phone_in_use"
														)}
											</Typography>
										) : (
											<Typography
												sx={{
													color:
														isDisabled() !== ""
															? "white"
															: "white"
												}}
											>
												{isDisabled() !== ""
													? isDisabled()
													: t("buttons.save")}
											</Typography>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</Collapse>
			</div>
		</>
	);
};
