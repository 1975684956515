import React, { useEffect, useState } from "react";
import { theme } from "../../Themes/Theme";
import { Box, ClickAwayListener, TextField } from "@mui/material";
import TooltipWrapper from "./TooltipWrapper";
import {
	DesktopDatePicker,
	MobileDateTimePicker,
	renderTimeViewClock
} from "@mui/x-date-pickers";

import { isDesktop } from "react-device-detect";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const TextfieldPicker = ({
	removeIcon, // remove icon from the textfield, keeps content alignment correct
	isDisabled, // control textfield disable state from parent component
	helpText, // text to show in the tooltip
	hasProps, // icon to show in the textfield
	fullDate, // set true if you want to show date and time
	value, // value to pass to the picker
	limitValues, // set true if you want to limit the time selection
	limitMax, // max value for the picker
	limitMin, // min value for the picker
	excludePast = true, // set false if you want to allow past dates (to be default)
	valueChange, // function to pass the value to the parent component
	label, // label for the textfield
	noTime, // if true, only shows datepicker options
	setCustomStep = 1, // 1 = 1min, 5 = 5min, 10 = 10min ...
	openTo = fullDate ? "year" : "hours", // "year" | "month" | "day" | "hours" | "minutes" | "seconds"
	// use "hours" to open the picker to the hour selection, if dates are needed, use year, month or day.
	removeBorder = false // remove border from the textfield
	// noEndTime
}) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [warning, setWarning] = useState(false);
	const [isSame, setIsSame] = useState(false);
	const [justClosed, setJustClosed] = useState(false); // used to prevent immediate reopening of the picker after last value is accepted
	/* const [previousValue, setPreviousValue] = useState(""); */

	const maxValue = limitMax !== undefined;
	const minValue = limitMin !== undefined;

	// max date for 32bit systems,
	// create new variable prop if max date needs to be changed even more, like to 2 weeks or 2 years from now.
	const minDate = new Date("2020-01-01");
	const maxDate = new Date("2038-01-01");

	const renderViews = fullDate
		? // order of the array matters, first value is the first view rendered
			["year", "month", "day", "hours", "minutes"]
		: noTime
			? ["year", "month", "day"]
			: ["hours", "minutes"];

	/* const handleCancel = (value) => {
		// console.log("handleClose", value, previousValue);
		if (value) valueChange(previousValue); // rollback to previous value if user cancels
		setJustClosed(true);
		setOpen(false);
	}; */

	const handleAccept = (value) => {
		valueChange(value);
		setJustClosed(true);
		setOpen(false);
	};

	const handleChange = (value) => {
		const dateObject = new Date(value);
		dateObject.setSeconds(0); // always set seconds to 0 to avoid issues with times having wildly different seconds between 0 and 59

		if (!isNaN(dateObject)) {
			setWarning(false);
			valueChange(dateObject);
		} else {
			setWarning(true);
		}
	};

	const handleDialogOpen = () => {
		if (!open && !justClosed) setOpen(true);
	};

	useEffect(() => {
		if (
			limitMax &&
			new Date(limitMax).getTime() === new Date(value).getTime()
		) {
			/* console.log(
				"same",
				new Date(limitMax).getTime(),
				new Date(value).getTime()
			); */
			setIsSame(true);
		} else setIsSame(false);
	}, [limitMax, limitMin]);

	/* 	useEffect(() => {
		setPreviousValue(value);
	}); */

	useEffect(() => {
		if (justClosed) {
			// forced delay to avoid immediate reopening of the picker
			setTimeout(() => {
				setJustClosed(false);
			}, 500);
		}
	}, [justClosed]);

	return (
		<ClickAwayListener
			onClickAway={() => setOpen(false)}
			mouseEvent="onMouseDown"
		>
			<Box
				style={{
					...theme.toloStyled.toloTextfield,
					borderColor: removeBorder
						? "transparent"
						: ColorScheme.textFieldBorder,
					cursor: "pointer"
				}}
				onClick={handleDialogOpen}
			>
				{!removeIcon && (
					<Box style={theme.toloStyled.toloTextfield.icon}>
						<TooltipWrapper title={helpText}>
							{hasProps}
						</TooltipWrapper>
					</Box>
				)}

				{isDesktop ? (
					<DesktopDatePicker // use DesktopDatePicker for desktop and MobileDateTimePicker for mobile, fixes a lot of problems with the picker functionality
						ampm={false} // for 24h clock
						open={open}
						autoFocus={true}
						autoOk={true}
						/* disableOpenPicker={true} */

						closeOnSelect
						onClose={() => setOpen(false)}
						onChange={handleChange}
						onAccept={handleAccept}
						/* onCancel={handleClose} */
						slots={{
							openPickerIcon: () => null,
							openPickerButton: () => null,
							TextField: TextField
							/* actionBar: TextFieldActionBar */
						}}
						slotProps={{
							tabs: {
								hidden: fullDate ? false : true
							},

							textField: {
								format: fullDate
									? "dd.MM.yyyy HH:mm"
									: noTime
										? "dd.MM.yyyy"
										: "HH:mm",
								error: warning,
								/* helperText: warning ? "Invalid time" : "" */
								disableIgnoringDatePartForTimeValidation:
									!fullDate && !noTime,
								InputLabelProps:
									theme.toloStyled.toloTextfield.pickers
										.label,
								sx: theme.toloStyled.toloTextfield.pickers,

								placeholder: "testi"
							},
							/* toolbar: {
							hidden: false // show toolbar on all or use fullDate to hide it
						} */
							actionBar: {
								// fix onAccept and onCancel to working condition and this component is ready to use
								isTime: !fullDate && !noTime,
								/* onAccept: handleAccept,
							onCancel: handleCancel, */
								hidden: false
								/* actions: ["today", "cancel", "accept"] */
							}
						}}
						localeText={{
							toolbarTitle: fullDate
								? t("time.input_date_time")
								: noTime
									? t("time.input_date")
									: t("time.input_time")
						}}
						disablePast={excludePast} // disables past dates
						openTo={openTo}
						label={label}
						views={renderViews}
						viewRenderers={{
							hours: renderTimeViewClock,
							minutes: renderTimeViewClock,
							seconds: renderTimeViewClock
						}}
						showToolbar
						disabled={isDisabled}
						minTime={
							limitValues && !isSame && minValue ? limitMin : null
						}
						maxTime={
							limitValues && !isSame && maxValue ? limitMax : null
						}
						minutesStep={setCustomStep}
						value={new Date(value)}
						maxDate={maxDate}
						minDate={
							limitValues && limitMin !== undefined
								? limitMin
								: minDate
						}
						format={fullDate ? "DD - MM - YYYY HH:mm" : "HH:mm"}
					/>
				) : (
					<MobileDateTimePicker
						value={new Date(value)}
						ampm={false} // for 24h clock
						disabled={isDisabled}
						disablePast={excludePast} // disables past dates
						label={label}
						openTo={openTo}
						onChange={handleChange}
						views={renderViews}
						format={fullDate ? "DD - MM - YYYY HH:mm" : "HH:mm"}
						localeText={{
							toolbarTitle: fullDate
								? t("time.input_date_time")
								: noTime
									? t("time.input_date")
									: t("time.input_time")
						}}
						minTime={
							limitValues && !isSame && minValue ? limitMin : null
						}
						maxTime={
							limitValues && !isSame && maxValue ? limitMax : null
						}
						minutesStep={setCustomStep}
						slotProps={{
							tabs: {
								hidden: fullDate ? false : true
							},

							textField: {
								format: fullDate
									? "dd.MM.yyyy HH:mm"
									: noTime
										? "dd.MM.yyyy"
										: "HH:mm",
								error: warning,
								/* helperText: warning ? "Invalid time" : "" */
								disableIgnoringDatePartForTimeValidation:
									!fullDate && !noTime,
								InputLabelProps:
									theme.toloStyled.toloTextfield.pickers
										.label,
								sx: theme.toloStyled.toloTextfield.pickers,

								placeholder: ""
							},
							/* toolbar: {
						hidden: false // show toolbar on all or use fullDate to hide it
					} */
							actionBar: {
								// fix onAccept and onCancel to working condition and this component is ready to use
								isTime: !fullDate && !noTime,
								/* onAccept: handleAccept,
						onCancel: handleCancel, */
								hidden: true
								/* actions: ["today", "cancel", "accept"] */
							}
						}}
					/>
				)}
			</Box>
		</ClickAwayListener>
	);
};
