import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../UserContext";
import { ReportContainer } from "./ReportContainer";
import { SalesWidget } from "./Widgets/SalesWidget";
import { UsageWidget } from "./Widgets/UsageWidget";
import { isDesktop, isMobile } from "react-device-detect";
import { PlaceholderSkeleton } from "../Common/PlaceholderSkeleton";
import { BackdropLoading } from "../Common/BackdropLoading";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../ApiContext";
import LandingAnimation from "../../Helpers/LandingAnimation";
import { useTranslation } from "react-i18next";

export const OverviewContainer = () => {
	const debug = false;

	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation, handleTitle } = useContext(UserContext);
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [rawData, setRawData] = useState([]);
	const [showChart, setShowChart] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showReportContainer, setShowReportContainer] = useState(false);
	const [hideStats, setHideStats] = useState(false);

	const [uniqueClasses, setUniqueClasses] = useState([]);

	const currentYear = new Date().getFullYear();

	const contentTypes = [1, 2];

	const hasPermission = permissionChecker({
		selectedLocation,
		permission: 2
	});

	if (!hasPermission) navigate("/locations");

	useEffect(() => {
		setIsLoading(true);

		if (selectedLocation.location_id === undefined) return;

		setShowChart(false);
		setHideStats(false);

		ApiCall(
			"GET",

			`client/statistics/${selectedLocation.location_id}?type=reservation&content=${contentTypes}&from=${currentYear}-01-01&to=${currentYear}-12-31`, // todo + reservable="kentän id"

			null
		)
			.then((res) => {
				if (debug) console.log(res);

				if (res.length === 0) setHideStats(true);

				/* const uniqueClasses = [
					...new Set(res.map((item) => item.class))
				]; */
				/* const numberOfUniqueClasses = uniqueClasses.length; */

				setUniqueClasses([res[0].class]); // for increased widget count use uniqueClasses
				if (debug) console.log(res[0].class);

				/* const uniqueClasses = [
					...new Set(res.map((item) => item.class))
				]; */
				/* const numberOfUniqueClasses = uniqueClasses.length; */

				setUniqueClasses([res[0].class]); // for increased widget count use uniqueClasses
				setRawData(res);
				setShowChart(true);
			})
			.catch((err) => {
				if (debug) console.log(err);
				setIsLoading(false); // failsafe for unexpected behaviour, widgets won't show up, but report container will
			})
			.finally(() => setShowReportContainer(true));
	}, [selectedLocation]);

	useEffect(() => {
		handleTitle(t("common.main_page"));
	}, []);

	useEffect(() => {
		if (/* rawData.length > 0 && */ showChart) setIsLoading(false);
	}, [rawData, showChart]);

	return (
		<Box
			sx={{
				display: "flex",
				rowGap: "50px",
				flexDirection: "column",
				justifyContent: "space-evenly",
				marginBottom: "50px",
				alignItems: isMobile ? "center" : undefined
			}}
		>
			{isLoading && <BackdropLoading />}

			{showChart ? (
				<LandingAnimation>
					<Stack sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{
								minWidth: "125px",
								paddingBottom: "15px"
							}}
						>
							{t("report.reservation_label")}
						</Typography>

						<SalesWidget data={rawData} />
					</Stack>
				</LandingAnimation>
			) : hideStats ? null : (
				<PlaceholderSkeleton isLarge={true} showLoading={true} />
			)}

			<Stack
				direction={isMobile ? "column" : "row"}
				flexWrap={isMobile ? "wrap" : "nowrap"}
				gap="80px"
				justifyContent="space-between"
				width={"100%"}
				height={isDesktop && "100%"}
				/* overflow={"hidden"} */
			>
				{!hideStats && showChart && (
					<Stack
						flexWrap="wrap"
						direction={isMobile ? "column" : "row"}
						justifyContent="space-between"
						sx={{
							maxWidth: "calc(100% - 860px)",
							minWidth: isDesktop ? "400px" : "100%",
							flexGrow: 1,
							height: "100%"
						}}
					>
						{uniqueClasses.map((entry, index) => (
							<LandingAnimation key={index}>
								<UsageWidget reservable={entry} />
							</LandingAnimation>
						))}
					</Stack>
				)}

				{showReportContainer && (
					<LandingAnimation>
						<Stack sx={{ height: "100%", display: "flex" }}>
							<ReportContainer />
						</Stack>
					</LandingAnimation>
				)}
			</Stack>
		</Box>
	);
};
