import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as MainIcon } from "../../../../Assets/Icons/MainpageIcon.svg";
import { ReactComponent as PinIcon } from "../../../../Assets/Icons/PinIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../../Assets/Icons/CalendarIcon.svg";
import { ReactComponent as ClientsIcon } from "../../../../Assets/Icons/ClientsIcon.svg";
import { ReactComponent as MessagesIcon } from "../../../../Assets/Icons/MessagesIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../../Assets/Icons/LogoutIcon.svg";
import { ReactComponent as SettingsIcon } from "../../../../Assets/Icons/SettingsIcon.svg";
import { ReactComponent as DoorIcon } from "../../../../Assets/Icons/DoorIcon.svg";
import { ReactComponent as CampaignIcon } from "../../../../Assets/Icons/CampaignIcon.svg";
import { ReactComponent as ContactIcon } from "../../../../Assets/Icons/ContactIcon.svg";
import { ReactComponent as ElectricIcon } from "../../../../Assets/Icons/ElectricIcon.svg";
import { ReactComponent as OverviewIcon } from "../../../../Assets/Icons/MainIcon.svg";
import { ReactComponent as BalanceIcon } from "../../../../Assets/Icons/MoneybagIcon.svg";
import { ReactComponent as ManualIcon } from "../../../../Assets/Icons/manuals.svg";
import { ColorScheme } from "../../../../Themes/ColorScheme";

export const MenuItem = ({ index, menu }) => {
	const iconStrokeColor = ColorScheme.tolotechWhite;

	const width = 24;
	const height = 24;
	const strokeWidth = 1.5;

	const iconSwitch = (path) => {
		const sliced = path.slice(1);

		switch (sliced) {
			case "locations":
				return (
					<PinIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "calendar":
				return (
					<CalendarIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "customers":
				return (
					<ClientsIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "messages":
				return (
					<MessagesIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "login":
				return (
					<LogoutIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "settings":
				return (
					<SettingsIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "doors":
				return (
					<DoorIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "campaigns":
				return (
					<CampaignIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "contact":
				return (
					<ContactIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "charging":
				return (
					<ElectricIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "balances":
				return (
					<BalanceIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "support":
				return (
					<ManualIcon
						stroke={iconStrokeColor}
						width={width}
						height={height}
						strokeWidth={strokeWidth}
					/>
				);
			case "overview":
				return <OverviewIcon stroke={iconStrokeColor} />;
			case "residents":
				return <ClientsIcon stroke={iconStrokeColor} />;
			default:
				return <MainIcon stroke={iconStrokeColor} />;
		}
	};

	return (
		<Link
			key={index}
			style={{
				textDecoration: "none",
				width: "100%",
				transition: ".3s all",
				opacity: menu.isBlocked ? "0.25" : undefined,
				background: menu.isBlocked ? "#bdbdbd" : undefined
			}}
			to={menu.isBlocked ? undefined : menu.link}
		>
			<Stack
				direction="row"
				spacing={4}
				padding={1.2}
				justifyContent="space-between"
			>
				{iconSwitch(menu.link)}
				<Typography
					sx={{
						color: "white",
						textDecoration: menu.isBlocked
							? "line-through"
							: undefined
					}}
				>
					{menu.title}
				</Typography>

				<div style={{ flex: 1 }} />
			</Stack>
		</Link>
	);
};
