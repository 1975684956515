import { Button } from "@mui/material";
import React from "react";

export const SubmitButton = ({ text, disabled }) => (
	<Button
		disabled={disabled}
		type="submit"
		variant="contained"
		sx={{
			backgroundColor: "#296DAE",
			color: "#FFFFFF",
			fontSize: "10px",
			width: 1,
			borderRadius: "4px",
			height: "44px"
		}}
	>
		{text}
	</Button>
);
