import React from "react";
import { TextFieldInput2 } from "../../../Common/TextFieldInput2";
import { ReactComponent as TimeIcon } from "../../../../Assets/Icons/TimerIcon.svg";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import TooltipWrapper from "../../../Common/TooltipWrapper";

import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const ServiceTimings = ({
	oldData,
	passCronTiming,
	passDelay,
	passAdvance
}) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				flexDirection: isDesktop ? "row" : "column",
				gap: isDesktop ? "20px" : "0px"
			}}
		>
			<TextFieldInput2
				text={oldData?.cron_timing}
				title={t("doors.cron_timing_label")}
				onlyNumbers={"tel"}
				maxNumberValue={90}
				valueChange={passCronTiming}
				/* removeIcon={screenWidth} */
				hasProps={
					<TooltipWrapper title={t("doors.cron_timing")}>
						<TimeIcon stroke={ColorScheme.defaultIconColor} />
					</TooltipWrapper>
				}
			/>

			<TextFieldInput2
				text={oldData?.access_advance}
				title={t("doors.access_advance_label")}
				onlyNumbers={"tel"}
				valueChange={passAdvance}
				/* removeIcon={screenWidth} */
				hasProps={
					<TooltipWrapper title={t("doors.access_advance")}>
						<TimeIcon stroke={ColorScheme.defaultIconColor} />
					</TooltipWrapper>
				}
			/>

			<TextFieldInput2
				text={oldData?.access_delay}
				title={t("doors.access_delay_label")}
				onlyNumbers={"tel"}
				valueChange={passDelay}
				/* removeIcon={screenWidth} */
				hasProps={
					<TooltipWrapper title={t("doors.access_delay")}>
						<TimeIcon stroke={ColorScheme.defaultIconColor} />
					</TooltipWrapper>
				}
			/>
		</div>
	);
};
