import { Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../UserContext";

export const PlaceholderSkeleton = ({
	variant,
	isLarge,
	isEmpty,
	showLoading
}) => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		if (!showLoading && isEmpty) setShowMessage(true);
	}, [isEmpty, showLoading]);

	useEffect(() => {
		setShowMessage(false);
	}, [selectedLocation]);

	return (
		<>
			{isLarge ? (
				<>
					{showMessage ? (
						<Typography>
							{t("warning.nothing_was_found")}
						</Typography>
					) : (
						<>
							<Skeleton height="50px" animation="pulse" />
							<Skeleton height="10px" animation="wave" />
							<Skeleton height="50px" animation="pulse" />
						</>
					)}
				</>
			) : (
				<Skeleton
					variant={variant}
					animation="wave"
					width="150px"
					height="25px"
				/>
			)}
		</>
	);
};
