import { Button, Typography } from "@mui/material";

import React, { useEffect, useState, useContext } from "react";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import OnOffSlider from "../Common/Inputs/OnOffSlider";
import { ColorScheme } from "../../Themes/ColorScheme";
import { ReactComponent as ChargerDevice } from "../../Assets/Icons/ChargerIcon.svg";
import { ReactComponent as PowerIcon } from "../../Assets/Icons/PowerIcon.svg";
import { ReactComponent as TaxIcon } from "../../Assets/Icons/PercentIcon.svg";
import { ReactComponent as WalletIcon } from "../../Assets/Icons/WalletIcon.svg";
import { isDesktop, isMobile } from "react-device-detect";

import { TextFieldInput2 } from "../Common/TextFieldInput2";
import { TextFieldSelect } from "../Common/TextFieldSelect";
import { TaxPercentages } from "../../Datafields/TaxPercentages";

export const ChargerForm = ({ data, handleDrawerClose, fetchChargers }) => {
	const debug = false;

	const { ApiCall } = useContext(ApiContext);

	const { t } = useTranslation();

	// console.log(data);

	const chargerName = String(data.id).padStart(4, "0");
	const connectorId = data?.connector_id;
	const chargerId = data?.charge_point_id;
	const maxPower = data?.max_power;
	const typeName = data?.type.name;
	const chargingPrice = data?.base_vat_price;
	const chargingTax = data?.vat_percentage || 0;
	const chargerStatus = data?.connector_status;
	const chargerInUse = data?.in_use;
	const svgIcon = data?.type.icon;

	const strokeColor = "#323232";
	const iconHeight = 24;
	const iconWidth = 24;
	const strokeWidth = 1.25;

	const [price, setPrice] = useState(0);
	const [tax, setTax] = useState(null);

	/* const [chargerRename, setChargerRename] = useState(chargerId); */

	const switchAvailability = (e) => {
		e.stopPropagation();

		ApiCall("PUT", "client/charger/toggle", {
			id: data.id,
			in_use: !data.in_use
		})
			.then((res) => {
				fetchChargers();
				if (debug) console.log(res);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const handleSave = () => {
		/* if (chargerId !== chargerRename)
			ApiCall("PUT", "client/charger/", {
				charge_point_id: chargerRename
			})
				.then((res) => {
					if (debug) console.log(res);
				})
				.catch((error) => {
					if (debug) console.log(error);
				}); */

		ApiCall("PUT", "client/charger/connector", {
			connector_id: connectorId,
			charge_point_id: chargerId,
			base_vat_price: Number(price),
			vat_percentage: tax
		})
			.then((res) => {
				if (debug) console.log(res);
				handleDrawerClose();
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		if (debug) console.log(data);
		if (chargingTax !== undefined && chargingTax !== null)
			setTax(chargingTax);
		if (chargingPrice !== undefined && chargingPrice !== null)
			setPrice(chargingPrice);
	}, []);

	return (
		<div
			style={{
				display: "flex",
				gap: "2px",
				flexDirection: "column",
				width: "100%",
				height: "100%"
			}}
		>
			<div
				style={{
					display: "flex"
				}}
			>
				<div style={{ flex: 1 }}>
					{/* <DrawerTitle
						location={selectedLocation.name}
						title={t("charging.charger_settings_label")}
					/> */}

					<Typography
						style={{
							fontSize: "26px",
							fontWeight: "bold",
							lineHeight: "40px",
							color: ColorScheme.tolotechBlue
						}}
					>
						{chargerName}
					</Typography>
					<Typography
						style={{
							fontSize: "18px",
							fontWeight: "normal",
							lineHeight: "10px", // equal to fontSize to reduce the gap between row lines
							color: "#969696"
						}}
					>
						{/* {t("charging." + String(chargerStatus).toLowerCase())} */}
						{chargerStatus}
					</Typography>
				</div>

				<OnOffSlider
					labels
					textEnabled={true}
					status={chargerInUse}
					action={switchAvailability}
				/>
			</div>

			<div style={{ height: "25px" }} />

			{/* <Typography sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
				Laitetiedot
			</Typography> */}

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					boxSizing: "border-box",
					borderRadius: "4px",
					padding: "20px",
					width: "100%",
					margin: "auto",
					border: "1px solid #F0F0F0",
					backgroundColor: "#FAFAFA"
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: isMobile ? undefined : "space-between",
						gap: "20px",
						width: "100%"
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "25px"
						}}
					>
						<ChargerDevice
							strokeWidth={strokeWidth}
							stroke={strokeColor}
							width={iconWidth}
							height={iconHeight}
						/>
						<Typography fontSize="18px">
							{data.charge_point_id}
						</Typography>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "25px"
						}}
					>
						<PowerIcon
							strokeWidth={strokeWidth}
							stroke={strokeColor}
							width={iconWidth}
							height={iconHeight}
						/>
						<Typography fontSize="18px">
							{maxPower} {t("charging.kw_short")}
						</Typography>
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "25px"
						}}
					>
						<img
							width={iconWidth}
							alt="icon"
							src={`data:image/svg+xml;base64,${btoa(
								/* Ignore deprecated warning, only affects NodeJS */
								svgIcon
							)}`}
						/>

						{/* <Typography>{t("charging.charger_type")}</Typography> */}
						<Typography fontSize="18px">{typeName}</Typography>
					</div>
				</div>

				{/* <div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						padding: "20px",
						textAlign: "center",
						width: "50%"
					}}
				>
					<Typography>
						{t("charging.connector")} {data.connector_id}
					</Typography>
				</div> */}
			</div>

			<div style={{ height: "25px" }} />

			{/* <Typography sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}>
				Laiteasetukset
			</Typography> */}

			{/* <div style={theme.formStyles.formLayout}>
				<Typography variant="form_bold">
					{t("charging.charger_rename")}
				</Typography>
				<TextField
					sx={theme.formStyles.formInput}
					fullWidth
					value={chargerRename}
					onChange={(text) => setChargerRename(text.target.value)}
				/>
			</div>

			<div style={{ height: "15px" }} /> */}

			<div
				style={{
					display: "flex",
					flexDirection: "row",
					gap: "20px",
					boxSizing: "border-box"
				}}
			>
				{/* <Typography variant="form_bold">
						{t("charging.charger_pricing")}
					</Typography>

					<TextField
						type="tel"
						fullWidth
						value={price}
						onChange={(text) => setPrice(text.target.value)}
						sx={theme.formStyles.formInput}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{t("charging.price_label")}
								</InputAdornment>
							),
							inputProps: {
								style: {
									textAlign: "right"
								}
							}
						}}
					/> */}
				<TextFieldInput2
					title={t("charging.charger_pricing")}
					text={price}
					valueChange={setPrice}
					onlyNumbers={true}
					hasProps={
						<WalletIcon
							stroke={ColorScheme.defaultIconColor}
							height={26}
							strokeWidth={2}
						/>
					}
					removeIcon={isDesktop ? false : true}
					endAdornment={t("charging.price_label")}
				/>

				{/* 	<Typography variant="form_bold">
						{t("charging.charger_tax")}
					</Typography>

					<TaxPercent
						current={tax || ""}
						handleChange={(value) => setTax(value)}
						hideLabel
						alignRight="right"
					/> */}
				<TextFieldSelect
					options={TaxPercentages}
					selected={String(tax)}
					label={t("charging.charger_tax")}
					valueChange={setTax}
					removeIcon={isDesktop ? false : true}
					hasProps={<TaxIcon stroke={ColorScheme.defaultIconColor} />}
				/>
			</div>

			<div style={{ flex: 1 }} />

			<div
				style={{
					display: "flex",
					width: "100%",
					margin: "auto",
					gap: "20px"
				}}
			>
				<Button
					sx={{
						border: "1px solid #1C568F",
						height: "46px",
						width: "50%",
						right: 0
					}}
					variant="testings"
					onClick={() => handleDrawerClose()}
				>
					<Typography>{t("buttons.cancel")}</Typography>
				</Button>
				<Button
					sx={{ width: "50%" }}
					variant="contained"
					onClick={() => handleSave()}
				>
					<Typography>{t("buttons.save")}</Typography>
				</Button>
			</div>
		</div>
	);
};
