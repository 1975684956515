import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Collapse, Typography } from "@mui/material";
import { RecipeCard } from "./RecipeCard";
/* import { ReactComponent as Arrow } from "../../../../Assets/Icons/ExpandArrowDownIcon.svg"; */
import { ReactComponent as Arrow } from "../../../../Assets/Icons/ExpandArrowDownIcon.svg";
import { ApiContext } from "../../../ApiContext";
import { useTranslation } from "react-i18next";
// import TooltipWrapper from "../../../Common/TooltipWrapper";
import { ColorScheme } from "../../../../Themes/ColorScheme";

export const CurrentRecipes = ({ locationId, recipeId, isProduct }) => {
	const debug = false;

	const [recipeList, setRecipeList] = useState([]);
	const [showList, setShowList] = useState(false);
	const [readyToMap, setReadyToMap] = useState(false);
	const [showWarning, setShowWarning] = useState({
		0: [null, ""],
		1: [null, ""],
		2: [null, ""],
		3: [null, ""],
		4: [null, ""],
		5: [null, ""],
		6: [null, ""]
	});
	const [open, setOpen] = useState({
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
		6: false
	});

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const handleWarning = (value) => {
		// Update the state with the new values
		setShowWarning(value);
	};

	const [weekdays, setWeekdays] = useState([
		{ name: t("dates.monday_long"), date: 1, data: [] },
		{ name: t("dates.tuesday_long"), date: 2, data: [] },
		{ name: t("dates.wednesday_long"), date: 3, data: [] },
		{ name: t("dates.thursday_long"), date: 4, data: [] },
		{ name: t("dates.friday_long"), date: 5, data: [] },
		{ name: t("dates.saturday_long"), date: 6, data: [] },
		{ name: t("dates.sunday_long"), date: 0, data: [] }
	]);

	const handleOpen = (id) => {
		setOpen((prevOpen) => ({
			...prevOpen,
			[id]: !prevOpen[id]
		}));
	};

	const formContentArray = () => {
		if (recipeList.length !== 0) {
			const updatedWeekdays = weekdays.map((dates) => {
				const matchingRecipes = recipeList.filter(
					(recipe) => recipe.weekday === dates.date
				);
				return {
					...dates,
					data: matchingRecipes
				};
			});

			setWeekdays(updatedWeekdays);
			setShowList(true);
		}
	};

	const axiosPath =
		isProduct === 1
			? `client/short_reservable_times/${recipeId}`
			: `location/get_products/${locationId}`;

	const handleFetch = () => {
		if (
			(isProduct === 1 && recipeId !== undefined) ||
			(isProduct === 2 && locationId !== undefined)
		) {
			setReadyToMap(false);

			ApiCall("GET", axiosPath, null)
				.then((e) => {
					setRecipeList(e);
				})
				.catch((error) => {
					if (debug) console.log(error);
				})
				.finally(() => {
					setReadyToMap(true);
				});
		}
	};

	const handleRecipeUpdate = () => {
		handleFetch();
	};

	useEffect(() => {
		handleFetch();
	}, [recipeId, locationId]);

	useEffect(() => {
		if (readyToMap) {
			formContentArray();
		}
	}, [readyToMap]);

	return (
		<>
			{isProduct === 1 && (
				<div style={{ display: "flex", flexDirection: "column" }}>
					{showList && recipeList.length !== 0 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								border: "1px solid " + ColorScheme.lightGrey,
								borderRadius: "4px",
								overflow: "hidden",
								minHeight: "100%"
							}}
						>
							{weekdays.map((dates, iteration) => {
								if (dates.data.length === 0) return;
								return (
									<div
										key={dates.date}
										style={{
											borderTop:
												iteration < 0 &&
												"1px solid " +
													ColorScheme.lightGrey,
											backgroundColor: "#F5F5F5",
											cursor: "pointer"
										}}
										onClick={() => handleOpen(dates.date)}
									>
										<div
											style={{
												display: "flex",
												padding: "20px",
												justifyContent: "space-between",
												alignItems: "center"
											}}
										>
											<Typography
												fontWeight="600"
												fontSize="20px"
												color={"black"}
											>
												{dates.name}
											</Typography>

											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													gap: "20px"
												}}
											>
												<div
													style={{
														display:
															!showWarning[
																dates.date
															][0] > 0 && "none",
														width: "10px",
														height: "10px",
														backgroundColor:
															showWarning[
																dates.date
															][1].secondary,
														borderRadius: "50%"
													}}
												/>

												<div
													style={{
														transform: open[
															dates.date
														]
															? "rotate(0deg)"
															: "rotate(-90deg)",
														transition:
															"all 0.3s ease"
													}}
												>
													<Arrow
														stroke="black"
														height="50px"
														width="50px"
													/>
												</div>
											</div>
										</div>

										<Collapse in={open[dates.date]}>
											{dates.data.length > 0 &&
												dates.data.map((recipe) => (
													<RecipeCard
														isProduct={isProduct}
														key={recipe.id}
														data={recipe}
														refreshList={() =>
															handleFetch()
														}
														showWarning={
															handleWarning
														}
														passRefresh={
															handleRecipeUpdate
														}
														hasActiveRecipes={
															showWarning
														}
													/>
												))}
										</Collapse>
									</div>
								);
							})}
						</div>
					) : !readyToMap ? (
						<div
							style={{
								width: "100%",
								display: "none" /* "flex" */, // change to flex to show loading
								flexWrap: "wrap",
								alignContent: "center",
								justifyContent: "center"
							}}
						>
							<CircularProgress />
						</div>
					) : (
						<Typography>
							{isProduct === 1
								? t(
										"location.form_recipe_reservables_not_found"
									)
								: t("location.form_recipe_products_not_found")}
						</Typography>
					)}
				</div>
			)}
		</>
	);
};
