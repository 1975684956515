import React from "react";
import { useTranslation } from "react-i18next";

import { TextFieldInput2 } from "../../../Common/TextFieldInput2";
import { ReactComponent as AmountIcon } from "../../../../Assets/Icons/AmountIcon.svg";
import { ColorScheme } from "../../../../Themes/ColorScheme";

export const UsageLimit = ({ passUsageLimit, handleChange }) => {
	const { t } = useTranslation();

	return (
		<TextFieldInput2
			onlyNumbers={"tel"}
			text={passUsageLimit}
			title={t("product.usage_limit_label")}
			valueChange={handleChange}
			hasProps={
				<AmountIcon stroke={ColorScheme.defaultIconColor} width={28} />
			}
		/>
	);
};
