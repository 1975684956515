/* eslint-disable indent */
import React, { createContext, useContext } from "react";

import axios from "axios";
import { BASE_URL } from "../env";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackContext } from "./SnackContext";
import useHandleServerMessage from "../Helpers/HandleServerMessage";

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
	const debug = false; // näytä konsoliviestit + poistaa pakollisen uloskirjautumisen unauthin takia
	const navigate = useNavigate();
	const location = useLocation();
	const handleServerMessage = useHandleServerMessage(); // fixes invalid hook call breaking rules for hooks...

	const { showSnackMessage } = useContext(SnackContext);

	const ApiCall = (method, url, body) =>
		new Promise((resolve, reject) => {
			let token = document.cookie.split("webapp-token=")[1];

			if (
				token === undefined &&
				url !== "user" &&
				url !== "login" &&
				url !== "user/reset_password" &&
				url !== "code_login"
			) {
				navigate("/login");
				return;
			} // Jos tokenia ei ole, estetään kaikki muut pyynnöt paitsi sisäänkirjautuminen, salasanan palautus ja user vanhan tokenin varmistamista varten

			if (debug) console.log(url);
			if (debug) console.log(token);
			if (token !== undefined) token = token.split(";")[0];
			if (debug) console.log("Fetching: " + BASE_URL + url);

			const splitted_url = url.split("/");

			let route = "";

			for (let i = 0; i < splitted_url.length; i++) {
				let isnum = /^\d+$/.test(splitted_url[i]);
				if (!isnum)
					route = route + (i !== 0 ? "_" : "") + splitted_url[i];
			}

			if (debug) console.log("______api_______");
			if (debug) console.log(body);
			if (debug) console.log(route);
			if (debug) console.log(url);

			axios({
				method,
				url: BASE_URL + url,

				data: body,
				headers: {
					Authorization: "Bearer " + token
				}
			})
				.then((res) => {
					if (debug) console.log(res);
					resolve(res.data);
					if (method !== "GET") showSnackMessage(route, res.status);
				})
				.catch((err) => {
					if (debug) console.log(err);
					if (location.pathname === "/login") return reject(err); // don't show snackbar messages on login screen

					if (err.response === undefined) {
						console.log("response undefined");
						showSnackMessage("general", 0);
						reject(err);
						return;
					}
					if (
						debug &&
						err.response.data !== undefined &&
						err.response.data !== null
					)
						console.log(err.response.data.error);
					if (debug) console.log(method);

					document.cookie = "";

					if (method !== "GET")
						showSnackMessage(route, err.response.status);
					else showSnackMessage("general", err.response.status);
					// erase cookies and route to login if status code is 401
					// navigate("/login");
					if (
						err.response.status === 401 &&
						navigate !== null &&
						!debug
					) {
						document.cookie =
							"webapp-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
						navigate("/login");
						showSnackMessage("general", err.response.status);
						return; // Don't reject the promise, should prevent browser error overlay from showing
					}

					// Checks for error message from server and returns translation if found
					const errorMessage = handleServerMessage(
						err.response.data.error
					);

					reject(errorMessage);
				});
		});

	return (
		<ApiContext.Provider
			value={{
				ApiCall
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};
