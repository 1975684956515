import React from "react";
import { Route, Routes } from "react-router";
import { ListLocations } from "./Locations/List/ListLocations";
/* import { CalendarComponent } from "./Calendar/CalendarComponent"; */

import { ClientContent } from "./Clients/ClientContent";
import { SettingContent } from "./Settings/SettingContent";
import { MessageContent } from "./Messages/MessageContent";
import { LocationDetailsCard } from "./Locations/List/LocationDetailCard";
// import { LocationDetailForm } from "./Locations/Form/Location/LocationDetailForm";
import { CalendarContent } from "./Calendar/CalendarContent";
import { ListCourses } from "./Courses/ListCourses";
import { ListDoors } from "./Doors/ListDoors";
import { AdminLogin } from "./Login/AdminLogin";
import { LaunchInit } from "./LaunchInit";
import { UserDetails } from "./Login/UserDetails";
import { CampaignContent } from "./Campaign/CampaignContent";
import { ContactContainer } from "./Contact/ContactContainer";
import { ChargingContent } from "./Charging/ChargingContent";
import { OverviewContainer } from "./Overview/OverviewContent";
import { UserManualContent } from "./UserManuals";
import { BalanceContent } from "./Balances/BalanceContent";

export const Router = () => (
	<Routes>
		<Route path="/locations/:id" element={<LocationDetailsCard />} />
		{/* <Route
                path="/locations/new"
                element={<LocationDetailForm info={""} newLocation={true} />}
            />*/}
		<Route path="/locations" element={<ListLocations />} />
		<Route path="/doors" element={<ListDoors />} />
		<Route path="/calendar" element={<CalendarContent />} />
		<Route path="/campaigns" element={<CampaignContent />} />
		<Route path="/customers" element={<ClientContent />} />
		<Route path="/messages" element={<MessageContent />} />
		<Route path="/courses" element={<ListCourses />} />
		<Route path="/settings" element={<SettingContent />} />

		<Route path="/login" element={<AdminLogin />} />

		<Route path="/details" element={<UserDetails />} />

		<Route path="/contact" element={<ContactContainer />} />

		<Route path="/charging" element={<ChargingContent />} />

		<Route path="/overview" element={<OverviewContainer />} />
		<Route path="/residents" element={<CalendarContent />} />
		<Route path="/support" element={<UserManualContent />} />

		<Route path="/balances" element={<BalanceContent />} />

		<Route path="/" element={<LaunchInit />} />
		{/* <Route path="*" element={<Navigate replace to="/locations" />} />*/}
	</Routes>
);
