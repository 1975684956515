import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UserContext } from "../UserContext";
import { DoorCard } from "./DoorCard";
import { MuiDrawer } from "../Common/MuiDrawer";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { BackdropLoading } from "../Common/BackdropLoading";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useNavigate } from "react-router-dom";
import { PlaceholderSkeleton } from "../Common/PlaceholderSkeleton";

export const ListDoors = () => {
	const { handleTitle, selectedLocation } = useContext(UserContext);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [drawerPath /* setDrawerPath */] = useState("");
	const [doors, setDoors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noDoors, setNoDoors] = useState(false);

	const hasPermission = permissionChecker({
		selectedLocation,
		permission: 1
	});

	if (!hasPermission) navigate("/locations");

	const fetchDoors = () => {
		setLoading(true);
		if (
			(selectedLocation.location_id !== null &&
				selectedLocation.location_id) !== undefined
		)
			ApiCall(
				"GET",
				"client/get_location_doors/" + selectedLocation.location_id,
				null
			)
				.then((res) => {
					setDoors(res);
					if (res.length === 0) setNoDoors(true);
				})
				.catch(setLoading(false));
	};

	const handleDrawerClose = () => {
		setOpenDrawer(false);
		fetchDoors();
	};

	useEffect(() => {
		setLoading(true);
		setNoDoors(false);
		setDoors([]);
		fetchDoors();
	}, [selectedLocation]);

	useEffect(() => {
		if (doors.length > 0 || noDoors) setLoading(false);
	}, [doors, noDoors]);

	useEffect(() => {
		handleTitle(t("common.doors"));
	}, []);

	return (
		<Box>
			<Stack sx={{ alignItems: isMobile ? "center" : undefined }}>
				{loading && <BackdropLoading />}
				{!loading && doors.length === 0 && (
					<Typography
						sx={{
							fontSize: "20px",
							fontWeight: "700",
							color: "rgba(255,255,255,0.5)",
							textAlign: isMobile ? "center" : undefined
						}}
					>
						{noDoors ? (
							t("doors.not_found")
						) : (
							<PlaceholderSkeleton isLarge showLoading />
						)}
					</Typography>
				)}
				{!loading && doors.length > 0 && (
					<div
						style={{
							width: isMobile ? undefined : "100%",
							flexDirection: isMobile ? "column" : "row",

							justifyContent: isMobile ? "center" : undefined,
							display: "flex",
							flexWrap: "wrap",
							flex: 1,
							gap: "15px"
							// padding: isMobile ? "0px" : "20px"
						}}
					>
						{doors.map((door, index) => (
							<DoorCard
								fetchDoors={fetchDoors}
								key={index}
								data={door}
							/>
						))}
					</div>
				)}
			</Stack>
			<MuiDrawer
				open={openDrawer}
				handleDrawerClose={handleDrawerClose}
				/* content={data}
				 locationData={locationData} */
				contentType={drawerPath}
			/>
		</Box>
	);
};
