import { Grid, TextField, Typography, Box } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ApiContext } from "../../ApiContext";
import { UsersMobile } from "./Cards/UsersMobile";
import { theme } from "../../../Themes/Theme";
import { UserAdd } from "../../Common/UserAdd";
import { UserCard } from "../../Common/UserCard";
import { useTranslation } from "react-i18next";

export const BookReservation = ({ content, refreshReservations }) => {
	const debug = false;
	const { t } = useTranslation();

	const [textValue, setTextValue] = useState("");
	const [isConstant, setIsConstant] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	/* 	const [loadingButton, setLoadingButton] = useState(false); */
	const [showError, setShowError] = useState(false);
	const [selectDate, setSelectDate] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const { ApiCall } = useContext(ApiContext);

	/* const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	}; */

	const axiosRoute = isConstant
		? "client/reservation/continuous"
		: "client/reservation/book";

	const handleAxiosBody = () => {
		const mapBody = (email) =>
			content
				.map((event) => [
					{
						email,
						...(!isConstant && {
							reservation_id: event.id
						}),
						...(isConstant && {
							continuous_reservation: {
								expiration_date: moment(selectDate)
									.endOf("day")
									.format(),
								from_time: moment(event.start).format(),
								reservable_id: event.serviceId,
								starting_date: moment(event.start).format(),
								to_time: moment(event.end).format(),
								weekday: moment(event.start).day()
							}
						})
					}
				])
				.flat(Infinity);

		return isConstant ? mapBody(textValue).shift() : mapBody(textValue);
	};

	const handleUserRemove = (email, id) => {
		setIsLoading(true);

		ApiCall("POST", "client/reservation/unbook", [
			{
				email,
				reservation_id: id
			}
		])
			.then(() => {
				setIsLoading(false);
				refreshReservations();
				setErrorMessage("");
			})
			.catch((e) => {
				setIsLoading(false);
				if (debug) console.log(e);
				setErrorMessage(e);
			});
	};

	const postEmailList = () => {
		setIsLoading(true);

		ApiCall("POST", axiosRoute, handleAxiosBody())
			.then(() => {
				setIsLoading(false);
				refreshReservations();
				setErrorMessage("");
				setTextValue("");
			})
			.catch((error) => {
				setIsLoading(false);
				setShowError(true);
				if (debug) console.log(error);
				setErrorMessage(error);
			});
	};

	useEffect(() => {
		if (content.length > 1) setIsConstant(false);
		if (
			content.length > 0 &&
			content[0].userReservations.length > 0 &&
			content[0].userReservations[0].content_type === 2
		)
			setIsConstant(true);
	}, [isConstant, content]);

	useEffect(() => {
		setShowError(false);
	}, [textValue]);

	return (
		<Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			<Grid container>
				<Grid item xs={8} textAlign="right">
					{isConstant && content[0].userReservations.length === 0 && (
						<MobileDatePicker
							label={t("input_labels.to_date")}
							minDate={moment()}
							views={["year", "month", "day"]}
							openTo="month"
							value={selectDate}
							onChange={(date) => {
								setSelectDate(date);
							}}
							renderInput={(params) => <TextField {...params} />}
						/>
					)}
				</Grid>
			</Grid>

			{content.length > 0 && content[0].userLimit !== 0 && (
				<div style={theme.formStyles.formLayout}>
					<Typography variant="form_bold" fontSize="16px">
						{t("calendar.add_new_user")}
					</Typography>

					<UserAdd
						contact={textValue}
						onlyEmail={true} // block phone numbers
						placeholder={
							content[0].userLimit ===
							content[0].userReservations.length
								? t("calendar.reservation_is_full")
								: t("input_labels.user_input_email")
						}
						disableInput={
							(content[0].userReservations.length > 0 &&
								content[0].userReservations[0].content_type ===
									2 &&
								content[0].users.length === 1) ||
							content[0].userLimit ===
								content[0].userReservations.length
						}
						showError={showError}
						handleContactChange={setTextValue}
						handleAnotherUser={postEmailList}
						errorMessage={errorMessage}
					/>
				</div>
			)}

			{content.length > 0 &&
				content[0].users !== undefined &&
				content[0].users !== "" &&
				content[0].users.length > 0 && (
					<Typography>
						{t("calendar.reservation_user_list_label") +
							" " +
							(content[0].userLimit > 1 ? content[0].title : "")}
					</Typography>
				)}

			<Box
				style={{
					// backgroundColor: ColorScheme.lightGrey,
					borderRadius: "4px"
					// overflow: "hidden"
				}}
			>
				{content.map((slot) =>
					slot.userReservations.map((user, index) => (
						<Box
							key={user.user.user_id}
							sx={{
								// padding: "20px",
								marginTop: "0px",
								borderWidth: "1px",
								borderRadius: "0px"
							}}
						>
							{isMobile ? (
								<UsersMobile
									slot={slot}
									user={user}
									handleUserRemove={handleUserRemove}
									isLoading={isLoading}
								/>
							) : (
								<UserCard
									user={user}
									index={index}
									handleRemove={handleUserRemove}
									slot={slot}
								/>
							)}
						</Box>
					))
				)}
			</Box>
		</Box>
	);
};
