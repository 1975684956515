import React, { useContext, useEffect, useRef } from "react";
import { useHorizontalScroll } from "../../../Helpers/useSideScroll";
import { UserContext } from "../../UserContext";
import "./ListGroupingSlider.css";
import { ListItemCard } from "./ListItemCard";
// tf?
/* const handlePass = (entry, passSelectedService) => {
	passSelectedService({
		sid: entry.id,
		type: entry.type,
		open_hours: entry.open_hours,
		location_id: entry.location_id,
		service_doors: entry.service_doors,
		user_limit: entry.user_limit,
		user_count: entry.user_count,
	});
}; */

export const ListGroupingSlider = ({
	passSelectedService,
	selectedService,
	reservables
}) => {
	const widthCheck = useRef();
	const scrollRef = useHorizontalScroll();

	const { selectedLocation } = useContext(UserContext);

	const handleClick = (entry) => {
		if (!entry) return; // Prevent unnecessary state updates
		passSelectedService(entry);
	};

	useEffect(() => {
		if (selectedService === null && reservables.length > 0) {
			passSelectedService(reservables[0]);
		}
	}, [reservables]);

	useEffect(() => {
		if (
			selectedService !== null &&
			selectedService.location_id !== selectedLocation.location_id
		) {
			passSelectedService(null);
		}
	}, [selectedLocation]);

	const fields = reservables.map((field, index) => (
		<ListItemCard
			isHighlighted={field.id === selectedService?.id}
			key={field.id}
			field={field}
			selected={selectedService?.id || 0}
			handleClick={handleClick}
			totalFields={reservables.length}
			isLast={index === reservables.length - 1}
		/>
	));

	return (
		<div className="list-fields-container-wrapper">
			<div
				className="list-fields-container"
				ref={(element) => {
					widthCheck.current = element;
					scrollRef.current = element; // Assign the same ref to scrollRef
				}}
			>
				{fields}
			</div>
		</div>
	);
};
