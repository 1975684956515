import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import uuid from "react-uuid";

import { Card, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DayNames } from "../../Common/DayNames";
import { ManageTimes } from "./ManageTimes";
import { ConfirmRemove } from "../../Common/ConfirmRemove";
import { ApiContext } from "../../ApiContext";
import { useTranslation } from "react-i18next";

export const DoorTimeTable = ({ content }) => {
	const debug = false;

	const [removeEntry, setRemoveEntry] = useState(false);

	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	const [weekdays, setWeekdays] = useState([]);
	const [prevWeekdays, setPrevWeekdays] = useState([]);
	const [filteredList, setFilteredList] = useState([]);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const handleTestings = (days) => {
		setWeekdays(days.split(""));
	};

	const handleStart = (time) => {
		setStartTime(time);
	};

	const handleEnd = (time) => {
		setEndTime(time);
	};

	const removeAccess = async (prop) => {
		Promise.all(content.opening_hours.filter((entry) => entry.ID !== prop))
			.then(setRemoveEntry(true))
			.then((list) => {
				setFilteredList(list);
			});
	};

	const oldDays = prevWeekdays.map((oldDay) => ({
		closes: oldDay.closes,
		door_id: oldDay.door_id,
		open_full: oldDay.open_full,
		opens: oldDay.opens,
		weekday: oldDay.weekday
	}));

	const newDays = weekdays.map((day) => ({
		closes: endTime,
		door_id: content.door_id,
		open_full: true,
		opens: startTime,
		weekday: Number(day)
	}));

	const combinedDays = oldDays.concat(newDays);

	const handleSave = () => {
		ApiCall("PUT", `door/update/${content.door_id}`, {
			door_name: content.door_name,
			opening_hours: removeEntry ? filteredList : combinedDays // todo miksei toimi? Lähettää vanhat arvot vaikka käyttää pelkkää filteredListiä
		})
			.then(() => {
				console.log("");
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		setPrevWeekdays(content.opening_hours);
	}, []);

	useEffect(() => {
		if (removeEntry === true) handleSave();
	}, [filteredList]);

	return (
		<Box sx={{ padding: "5%" }}>
			<Stack direction="row" justifyContent="space-between">
				<Stack>
					<Typography>{content.door_name}</Typography>
					<Typography>{t("doors.location_name")}</Typography>
					<Typography>{content.doorphone}</Typography>
				</Stack>
				<Stack>
					<Typography align="right">
						{content.online
							? t("doors.location_online")
							: t("doors.location_offline")}
					</Typography>
					<Typography>
						{moment(content.last_offline).format("lll")}
					</Typography>
				</Stack>
			</Stack>
			<ManageTimes
				handleEnd={handleEnd}
				handleStart={handleStart}
				handleTestings={handleTestings}
				handleSave={handleSave}
			/>
			<Card sx={{ padding: "5px" }}>
				{content.opening_hours.map((open) => (
					<Paper
						key={uuid()}
						sx={{ padding: "2px", marginBottom: "2px" }}
					>
						<Stack direction="row" justifyContent="space-between">
							<Typography sx={{ width: "25%" }}>
								{DayNames(open.weekday)}
							</Typography>
							<Typography align="right">
								{moment(open.opens).format("HH:mm")} -
								{moment(open.closes).format("HH:mm")}
							</Typography>
							<ConfirmRemove
								doorId={open.ID}
								handleRemove={removeAccess}
							/>
						</Stack>
					</Paper>
				))}
			</Card>
		</Box>
	);
};
