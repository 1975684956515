import { Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { ClientSlideList } from "../../Clients/ClientSlideList";
import { InviteForm } from "./InviteForm";

export const AdminDetail = ({ data }) => {
	const { t } = useTranslation();
	const [passRefresh, setPassRefresh] = useState(false);

	const handleRefresh = () => {
		setPassRefresh((passRefresh) => !passRefresh);
	};

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				padding: "15px",
				borderRadius: "4px"
			}}
		>
			<div
				style={{
					flexDirection: isMobile ? "column" : "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start",
					alignItems: "baseline",
					gap: isMobile ? "15px" : undefined
				}}
			>
				<div style={{ width: "100%", maxWidth: "150px" }}>
					<Typography variant="form_text_label">
						{t("location.form_location_admins_label")}:
					</Typography>
				</div>

				<Stack sx={{ width: isMobile ? 1 : "100%", minWidth: "175px" }}>
					{data.location_permissions !== undefined &&
						(data.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined ||
							data.location_permissions.find(
								(obj) => obj.permission_id === 7
							) !== undefined) && (
							<>
								<InviteForm
									locationId={data.location_id}
									refresh={handleRefresh}
								/>
								<Divider />
							</>
						)}
					<ClientSlideList
						locationId={data.location_id}
						triggerRefresh={passRefresh}
					/>
				</Stack>
			</div>
		</Stack>
	);
};
