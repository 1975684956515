import {
	Collapse,
	Stack,
	Typography,
	Box /* , ImageList, ImageListItem*/
} from "@mui/material";
import React, { useState, useEffect } from "react";

import { ClientOrderCard } from "./ClientOrderCard";
import { isMobile } from "react-device-detect";
import { Spinner } from "../Common/Spinner";
import { ColorScheme } from "../../Themes/ColorScheme";

import "./Transitions.css";
import { useTranslation } from "react-i18next";
/* import { SortingArrow } from "../Common/IconComponents/SortingArrow"; */

// import PhoneIcon from "../../Assets/Icons/PhoneIcon.svg";

export const ClientCard = ({ user, isLast, setDialogUser }) => {
	const { t } = useTranslation();

	const [cardOpen /* setCardOpen */] = useState(false); // todo id identifier
	const [orders /* setOrders */] = useState([]);
	const [successful, setSuccessful] = useState(true);
	/* const [sorting, setSorting] = useState("");
	const [sortingOrder, setSortingOrder] = useState(true); */
	const [, /* selectedOrder */ setSelectedOrder] = useState(null);
	const [isLoading /* setIsLoading */] = useState(false);

	/* const DelayedRenderItem = ({ user, user, index }) => (
		<CSSTransition
			classNames={"blur-to-clear"}
			timeout={700}
			unmountOnExit
			in={true}
		>
			<ClientOrderCard
				user={user}
				user={user}
				index={index}
				handleOrder={handleSelectOrder}
			/>
		</CSSTransition>
	); */
	/* const sortOrders = orders.sort((a, b) =>
		(sortingOrder ? a : b)[sorting].localeCompare(
			(!sortingOrder ? a : b)[sorting]
		)
	); */

	/* const handleDrawerClose = () => {
		setToggleOpen(false);
	};
 */
	const handleSelectOrder = (props) => {
		setSelectedOrder(props);
		/* setToggleOpen(true); */
	};

	/* const compare = (a, b) => {
		if (a.updated_at > b.updated_at) return -1;

		if (a.updated_at < b.updated_at) return 1;

		return 0;
	}; */

	/* const fetchOrders = () => {
		setIsLoading(true);

		ApiCall(
			"GET",
			`client/get_orders/${location.location_id}?uid=${user.user_id} `
		)
			.then((res) => {
				res.sort(compare);
				setOrders(res);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}; */

	/* 	useEffect(() => {
		fetchOrders();
	}, [location, toggleOpen]); */

	useEffect(() => {
		// console.log(user);
		if (user.has_unpaid_orders) setSuccessful(false);
	}, []);

	/* useEffect(() => {
		if (orders.length > 0) setIsLoading(false);
	}, [orders]);
 */
	/* useEffect(() => {
		setSorting("order_id"); // default sort
	}, []); */

	// console.log(user);

	return (
		<Box
			onClick={() => setDialogUser(user)}
			sx={{
				/* minHeight: isMobile && "50px", */
				backgroundColor: cardOpen
					? "rgba(255, 255, 255, 0.95)"
					: "rgba(255, 255, 255, 1)",
				height: "80px",
				cursor: "pointer",
				borderBottom: !isLast && "1px solid #D9D9D9",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				":hover": {
					backgroundColor: "#FAFAFA"
				}
			}}
		>
			<Box
				sx={{
					width: !isMobile ? "calc(100% / 4)" : "calc(100% / 2)",
					px: "20px",
					boxSizing: "border-box"
				}}
			>
				<Typography
					sx={{
						fontSize: isMobile ? "12px" : "16px",
						fontWeight: "700",
						color: "rgb(32, 32, 32)"
					}}
				>
					{user.name}
				</Typography>
				{isMobile && (
					<Typography sx={{ fontSize: 10, color: "grey" }}>
						{user?.email}
					</Typography>
				)}
			</Box>

			{!isMobile ? (
				<>
					<Box
						sx={{
							width: "calc(100% / 4)",
							px: "20px",
							boxSizing: "border-box"
						}}
					>
						<Typography
							sx={{
								color: "rgb(32, 32, 32)",
								fontSize: "12px",
								wordWrap: "break-word",
								wordBreak: "break-all"
							}}
						>
							{user.email.split("@")[0]}
							<Box
								component="span"
								sx={{ display: "inline-block" }}
							>
								{user.email !== "" &&
									"@" + user.email.split("@")[1]}
							</Box>
						</Typography>
					</Box>
				</>
			) : (
				<></>
			)}

			{!isMobile && (
				<Box
					sx={{
						width: "calc(100% / 4)",
						px: "20px",
						boxSizing: "border-box"
					}}
				>
					<Typography
						sx={{
							color: "rgb(32, 32, 32)",
							fontSize: "12px"
						}}
					>
						{user.order_count === 1
							? +user.order_count +
								" " +
								t("orders.single_purchase_label")
							: user.order_count +
								" " +
								t("orders.multiple_purchases_label")}
					</Typography>
				</Box>
			)}
			<Box
				sx={{
					width: isMobile ? "calc(100% / 2)" : "calc(100% / 4)",
					px: "20px",
					boxSizing: "border-box",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end"
				}}
			>
				{isMobile && (
					<Typography
						sx={{
							color: "rgb(32, 32, 32)",
							fontSize: "12px",
							marginRight: "20px"
						}}
					>
						{user.order_count === 1
							? +user.order_count +
								" " +
								t("orders.single_purchase_label")
							: user.order_count +
								" " +
								t("orders.multiple_purchases_label")}
					</Typography>
				)}
				<div
					style={{
						float: "right",
						backgroundColor: successful ? "green" : "#DC5151",
						height: 12,
						width: 12,
						borderRadius: 99
					}}
				/>
			</Box>

			<Collapse
				in={cardOpen}
				timeout={500}
				/* sx={{ display: !cardOpen && "none" }} */
				/* collapsedSize={orders.length} */
				easing
			>
				<div
					style={{
						// divider bar
						height: "1px",
						width: "100%",
						backgroundColor: "lightgray",
						marginTop: "20px",
						display: !cardOpen && "none"
					}}
				/>
				{/* {isLoading && (
					<div style={{ padding: "20px" }}>
						<Spinner
							hasText={t("spinner_text.loading")}
							hasColor={ColorScheme.tolotechBlue}
						/>
					</div>
				)} */}
				<div style={{ display: !cardOpen && "none" }}>
					<Stack
						sx={{
							flexDirection: "row",
							display: "flex",
							paddingX: isMobile ? "10px" : "30px",
							paddingY: "10px",
							alignItems: "center",
							border: "1px solid transparent"
						}}
					>
						<Stack sx={{ flex: 1 }}>
							<Typography
								sx={{
									fontSize: "11px",
									color: "rgba(0,0,0,0.9)",
									fontWeight: "700"
									//  minWidth: "72px"
								}}
							>
								Ostoaika
							</Typography>
						</Stack>

						{!isMobile ? (
							<>
								<Stack
									sx={{ flex: 0.5 }}
									/* onClick={() => handleSort("order_id")} */
								>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										ID
									</Typography>
									{/* {sorting === "order_id" && (
							<SortingArrow order={sortingOrder} />
						)} */}
								</Stack>

								<Stack sx={{ flex: 1.5 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.name_label")}
									</Typography>
								</Stack>
								<Stack sx={{ flex: 2 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.reservation_type_label")}
									</Typography>
								</Stack>
								<Stack sx={{ flex: 1.5 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.reservation_date_label")}
									</Typography>
								</Stack>
								<Stack sx={{ flex: 1.5 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t(
											"orders.reservation_timerange_label"
										)}
									</Typography>
								</Stack>
								<Stack sx={{ flex: 1.5 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.total_label")}
									</Typography>
								</Stack>
								<Stack sx={{ flex: 1, alignItems: "center" }}>
									<Typography
										sx={{
											// marginLeft: "15px",
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700",
											width: "90px",
											textAlign: "center"
										}}
									>
										{t("orders.purchase_state_label")}
									</Typography>
								</Stack>
							</>
						) : (
							<>
								<Stack sx={{ flex: 1.5 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.name_label")}
									</Typography>

									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.reservation_type_label")}
									</Typography>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.reservation_date_label")}
									</Typography>
								</Stack>

								<Stack sx={{ flex: 1 }}>
									<Typography
										sx={{
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700"
										}}
									>
										{t("orders.total_label")}
									</Typography>
								</Stack>
								<Stack sx={{ flex: 1 }}>
									<Typography
										sx={{
											// marginLeft: "15px",
											fontSize: "11px",
											color: "rgba(0,0,0,0.9)",
											fontWeight: "700",
											textAlign: "center",
											width: "90px"
										}}
									>
										{t("orders.purchase_state_label")}
									</Typography>
								</Stack>
							</>
						)}
					</Stack>
				</div>

				{!isLoading && cardOpen ? (
					orders.map((order, index) => (
						<ClientOrderCard
							key={order.order_id}
							data={order}
							user={user}
							index={index}
							handleOrder={handleSelectOrder}
						/>
					))
				) : (
					<div
						style={{
							display: !cardOpen && "none",
							padding: "20px"
						}}
					>
						<Spinner
							hasText={t("spinner_text.loading")}
							hasColor={ColorScheme.tolotechBlue}
						/>
					</div>
				)}
			</Collapse>
		</Box>
	);
};

/* /* <DelayedRenderItem
							key={order.order_id}
							user={order}
							user={user}
							index={index}
							handleOrder={handleSelectOrder}
						/> */
