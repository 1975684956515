import React from "react";
import { Alert, createTheme } from "@mui/material";

export const theme = createTheme({
	".MuiAlert-message": {
		width: "100%"
	}
});

/**
 * AlertBar component displays an alert message with customizable options.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.textContent - The content of the alert message.
 * @param {boolean} props.showAlert - Determines whether to show the alert or not.
 * @param {string} props.type - The type of the alert (e.g., "success", "error", "warning").
 * @param {boolean} props.standardVariant - Determines whether to use the standard variant or filled variant for the alert.
 * @param {string} props.strongEnding - The content to be displayed as a strong ending in the alert.
 * @param {function} props.onCloseAction - The action to be performed when the alert is closed.
 * @param {boolean} props.whiteSpacePreLine - Determines whether to use pre-line white space or normal white space.
 * @param {boolean} props.newLine - Determines whether to add a space between alert content.
 * @returns {JSX.Element} The rendered AlertBar component.
 */
export const AlertBar = ({
	textContent,
	showAlert,
	type,
	standardVariant,
	strongEnding,
	onCloseAction,
	whiteSpacePreLine,
	newLine
}) => (
	<div style={{ width: "100%" }}>
		{showAlert && (
			<Alert
				onClose={onCloseAction ? onCloseAction : null}
				severity={type}
				variant={standardVariant ? "standard" : "filled"}
				color={type}
				sx={{
					mb: 2,
					whiteSpace: whiteSpacePreLine ? "pre-line" : "normal"
				}}
			>
				{textContent}
				{newLine && "\n"}
				{strongEnding && <strong>{strongEnding}</strong>}
			</Alert>
		)}
	</div>
);
