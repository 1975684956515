import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ColorScheme } from "../../../Themes/ColorScheme";
import "./ListGroupingSlider.css";
import { useTranslation } from "react-i18next";

export const ListItemCard = ({
	field,
	selected,
	handleClick,
	isHighlighted
}) => {
	const { t } = useTranslation();

	const itemRef = useRef(null);
	const [hover, setHover] = useState(false);

	useEffect(() => {
		if (isHighlighted && itemRef.current)
			itemRef.current.scrollIntoView({
				behavior: "smooth",
				inline: "center",
				block: "nearest"
			});
	}, [isHighlighted]);

	return (
		<div
			ref={itemRef}
			className={`list-field ${selected === field.id ? "selected" : ""}`}
			style={{
				boxSizing: "border-box",
				backgroundColor:
					hover && selected !== field.id && "rgba(255,255,255,0.2)",
				width: 1,
				maxWidth: "350px",
				minWidth: `calc(${field.name.length}ch + 100px)`,
				whiteSpace: "nowrap",
				/* overflow: "hidden", */
				justifyContent: "flex-start",
				position: "relative",
				height: "100%",
				flexGrow: 1,
				borderBottom:
					selected === field.id &&
					"5px solid " + (field.service_class?.color || "#296dae")
			}}
			onClick={() => handleClick(field)}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<div
				style={{
					height: "10px",
					minWidth: "10px",
					backgroundColor: field.service_class?.color || "#296dae",
					marginRight: "20px",
					borderRadius: "2px",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					display: "flex"
				}}
			></div>
			<div>
				<Typography
					sx={{
						display: "flex",
						// maxWidth: "250px",
						wordWrap: "break-word",
						textAlign: "center",
						whiteSpace: "nowrap",
						fontSize: "16px"
					}}
				>
					{field.name}
				</Typography>
				<Typography
					sx={{
						display: "flex",
						// maxWidth: "250px",
						wordWrap: "break-word",
						textAlign: "center",
						whiteSpace: "nowrap",
						fontSize: "12px",
						marginTop: "-5px",
						color: "#7D8DA3"
					}}
				>
					{field?.service_class?.name_fi || t("common.residents")}
				</Typography>
			</div>
			<div
				style={{
					width: "20px",
					height: "100px",
					backgroundColor: "red",
					right: 0,
					position: "absolute",
					background:
						"linear-gradient(to right, transparent, " +
						ColorScheme.bg +
						")"
				}}
			></div>
		</div>
	);
};
