import React, { useEffect, useState } from "react";
import { ReservationRecipeCard } from "./ReservationRecipeCard";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const RecipeCard = ({ isProduct, data, showWarning, passRefresh }) => {
	const successColor = { primary: "#DCE9D6", secondary: "#75A85D" };
	const expiredColor = { primary: "#E9D6D6", secondary: "#A85D5D" };
	const expiringSoonColor = { primary: "#FFE9D1", secondary: "#FFBC70" };
	const cardStatusColor = ColorScheme.lightGrey;
	const [statusCode, setStatusCode] = useState(cardStatusColor);
	const [statusText, setStatusText] = useState("");

	const { t } = useTranslation();

	const changeStatus = (color, text) => {
		setStatusCode(color);
		setStatusText(text);
	};

	const getStatus = (status) => {
		const currentDate = new Date();
		const statusDate = new Date(status);

		const timeDifference = statusDate.getTime() - currentDate.getTime();
		const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

		if (statusDate.getFullYear() === 2038) {
			changeStatus([
				successColor,
				t("location.form_recipe_valid_indefinitely")
			]);

			showWarning((prevState) => ({
				...prevState,
				[data.weekday]: [0, successColor]
			}));
		} else if (daysDifference > 30 && statusDate.getFullYear() !== 2038) {
			changeStatus([successColor, ""]);
			showWarning((prevState) => ({
				...prevState,
				[data.weekday]: [0, successColor]
			}));
		} else if (currentDate > statusDate) {
			// expired
			changeStatus([expiredColor, t("location.form_recipe_expired")]);
			showWarning((prevState) => {
				if (
					// check if there is any active recipes (0) before updating the state
					prevState[data.weekday] &&
					prevState[data.weekday][0] === 0
				) {
					return { ...prevState };
				} else
					return {
						...prevState,
						[data.weekday]: [2, expiredColor]
					};
			});
		} else if (daysDifference <= 30 && daysDifference > 0) {
			changeStatus([
				expiringSoonColor,
				t("location.form_recipe_valid_until") +
					" " +
					statusDate.getDate() +
					"." +
					(statusDate.getMonth() + 1) +
					"."
			]);
			showWarning((prevState) => {
				// Check if current state already has expiredColor

				if (
					prevState[data.weekday] &&
					prevState[data.weekday][1].primary === expiredColor.primary
				) {
					return prevState;
				}

				return {
					...prevState,
					[data.weekday]: [1, expiringSoonColor]
				};
			});
		} else {
			// Default case if none of the conditions are met
			changeStatus([ColorScheme.lightGrey, ""]); // Return empty string or add error message
		}
	};

	useEffect(() => {
		getStatus(data.valid_until);
	}, []);

	{
		return (
			isProduct === 1 && (
				<ReservationRecipeCard
					status={{ statusCode, statusText }}
					data={data}
					isProduct={isProduct}
					refresh={passRefresh}
				/>
			)
		);
	}
};
