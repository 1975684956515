import React, { useContext, useEffect, useState } from "react";
import { Button, Collapse, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ApiContext } from "../../ApiContext";
import { AlertBar } from "../../Common/AlertBar";
import { SaveAnimationButton } from "../../Common/Inputs/SaveAnimationButton";
import { UserAdd } from "../../Common/UserAdd";
import { UserListHandler } from "../../Common/UserListHandler";
import { WeekdaySelect } from "../../Common/WeekdaySelect";
import OnOffSlider from "../../Common/Inputs/OnOffSlider";
import { TextfieldPicker } from "../../Common/TextfieldPicker";

import { ReactComponent as RestrictionStartIcon } from "../../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as RestrictionEndIcon } from "../../../Assets/Icons/TimeEndIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../Assets/Icons/arrowBack.svg";
import { ColorScheme } from "../../../Themes/ColorScheme";
import ScreenWidth from "../../../Helpers/ScreenWidth";
import { startOfDay } from "date-fns";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const AddNewUser = ({
	info,
	changeView,
	acceptingNew,
	selectedRequest
}) => {
	const debug = false;

	const [userID, setUserID] = useState("");
	const [getStart, setGetStart] = useState(null);
	const [getEnd, setGetEnd] = useState(null);
	const [getWeekdays, setGetWeekdays] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [useLimits, setUseLimits] = useState(false);
	const [emailList, setEmailList] = useState([]);
	const [failedList] = useState([]);
	const [showList, setShowList] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loadingButton, setLoadingButton] = useState(false);

	const weekdaysString = getWeekdays.sort().join("");

	// Create a blank date (start of the day)
	const blankDate = startOfDay(new Date());

	// Create finalDate by formatting blankDate
	const finalDate = blankDate.toISOString();

	const lastDate = new Date(2038, 0, 1, 0, 0, 0); // 2038-01-01T00:00:00.000Z

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const checkWidth = ScreenWidth(550); // 550px is the breakpoint when full date value is starting to overflow with icon

	const handleSuccess = () => {
		setLoadingButton(false);
		setUserID("");
		setGetStart("");
		setGetEnd("");
		setGetWeekdays([]);
		setStartDate("");
		setEndDate("");

		changeView();
	};

	const handleReject = (error) => {
		setLoadingButton(false);
		// todo temp solution

		setShowAlert(true);
		setErrorMessage(error);
		/* const errorString = error.response.data.error.toString();

		if (error.response.status === 500 && errorString.startsWith("550"))
			setShowAlert(true);
 */
		if (debug) console.log(error);
	};

	const handleStart = (time) => {
		// add if statement for checking if time is valid and compare it to end time and modify it if needed (if end time is before start time)
		setGetStart(time);
	};

	const handleEnd = (time) => {
		setGetEnd(time);
	};

	const handleStartDate = (date) => {
		// console.log(date);
		setStartDate(date);
	};

	const handleEndDate = (date) => {
		// console.log(date);
		setEndDate(date);
	};

	const handleWeekdays = (day) => {
		setGetWeekdays((prev) => {
			if (prev.includes(day)) {
				return prev.filter((days) => days !== day);
			} else {
				return [...prev, day];
			}
		});
	};

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/; // x@y.z format
		return re.test(email);
	};

	const validateUserInput = (id) => {
		const internationalRegex = /^\+(?:[0-9]?){1,4}[0-9]{9,14}(?:x.+)?$/; // intl phone numbers with required country code
		const finnishRegex =
			/^(?:\+358|0)(?:\s|-)?\d{1,3}(?:\s|-)?\d{4,}(?:\s|-)?\d{0,2}$/; // finnish numbers with and without country code prefix

		const phoneIsValid =
			internationalRegex.test(id) || finnishRegex.test(id);
		const emailIsValid = validateEmail(id);

		const allDigitsAreSame = /^(\d)\1+$/.test(id); // prevent lazy inputs like 0000000

		return (phoneIsValid || emailIsValid) && !allDigitsAreSame;
	};

	const handleChange = () => {
		setUseLimits(!useLimits);
	};

	const addAnotherUser = () => {
		setEmailList((prevState) => [...prevState, userID]);
		setShowList(true);
		setUserID("");
	};

	const handleNewUser = (id) => {
		if (id === "") return;

		/* console.log(
			"handleNewUser",
			startDate,
			endDate,
			weekdaysString,
			getStart,
			getEnd,
			useLimits,
			blankDate
		); */

		setLoadingButton(true);

		ApiCall("POST", "client/add_door_permission", {
			location_id: Number(info.location_id),
			door_id: Number(info.door_id),
			access_starts_at: useLimits ? startDate : finalDate, // removed startOf("day") to allow users to define own starting time
			access_ends_at: useLimits ? endDate : lastDate, // removed endOf("day") to allow users to define own starting time
			weekdays: useLimits ? weekdaysString : "0123456",
			start_time: useLimits ? getStart : finalDate,
			end_time: useLimits ? getEnd : finalDate,
			restrict_times: useLimits, // todo oma valinta (switch?)
			email: validateEmail(id) ? id : undefined,
			phone: !validateEmail(id) ? id : undefined
		})
			.then(() => {
				handleSuccess();
			})
			.catch((error) => {
				handleReject(error);
			});
	};

	const handleUpdateUser = (selectedRequest) => {
		setLoadingButton(true);

		ApiCall("PUT", "door/update_access", {
			access_ends_at: useLimits ? endDate : lastDate,
			granted: true + "",
			id: selectedRequest.id + "",
			location_id: selectedRequest.location_id + "",
			door_id: Number(selectedRequest.door_id) + "",
			start_time: useLimits ? getStart : finalDate,
			end_time: useLimits ? getEnd : blankDate,
			weekdays: useLimits ? weekdaysString : "0123456",
			access_starts_at: useLimits ? startDate : finalDate,
			restrict_times: useLimits + ""
		})
			.then((response) => handleSuccess(response))
			.catch((error) => handleReject(error));
	};

	const addUser = () => {
		handleNewUser(userID);
	};

	const checkDisabled = () => {
		if (validateUserInput(userID)) return false;
		else return true;
	};

	const handleContactChange = (id) => {
		if (showAlert) {
			setShowAlert(false);
			setErrorMessage("");
		}
		setUserID(id);
	};

	useEffect(() => {
		// Function to convert a date to ISO string after setting hours, minutes, seconds, and milliseconds to 0
		const toMidnightISOString = (date) =>
			new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString();

		const defaultDate = toMidnightISOString(new Date());
		const defaultEndDate = toMidnightISOString(new Date("2038-01-01"));

		if (getStart === null) setGetStart(defaultDate);
		if (getEnd === null) setGetEnd(defaultDate);
		if (startDate === null) setStartDate(defaultDate);
		if (endDate === null) setEndDate(defaultEndDate);

		if (acceptingNew) {
			handleContactChange(selectedRequest.email); // Set the email to the input field if accepting new user request
		}
	}, []); // Include dependencies if needed

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%"
			}}
		>
			<Stack alignItems="center" gap="20px" paddingBottom="20px">
				<UserAdd
					contact={userID}
					handleContactChange={handleContactChange}
					handleAnotherUser={addAnotherUser}
					placeholder={t("location.form_add_door_access")}
					showAddMore={false}
				/>

				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between"
					}}
				>
					<Typography>{t("doors.restrict_access_rights")}</Typography>
					<OnOffSlider status={useLimits} action={handleChange} />
				</div>

				<Collapse
					in={useLimits}
					collapsedSize={0}
					sx={{ width: "100%" }}
				>
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							gap: "20px"
						}}
					>
						<Collapse in={true} collapsedSize={0}>
							<AlertBar
								type="info"
								textContent={t(
									"doors.access_right_restriction_duration"
								)}
								whiteSpacePreLine
								standardVariant
								showAlert
							/>
						</Collapse>

						<div
							style={{
								display: "inherit",
								gap: "20px",
								flexDirection: isDesktop ? "row" : "column"
							}}
						>
							<TextfieldPicker
								value={startDate}
								limitValues={false}
								limitMin={blankDate}
								limitMax={endDate}
								/* removeIcon={checkWidth} */
								label={t("textfield_labels.start_date")}
								excludePast={false}
								fullDate={true}
								valueChange={handleStartDate}
								hasProps={
									<RestrictionStartIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							{!checkWidth && (
								<ArrowIcon
									stroke="black"
									style={{
										transform: "rotate(180deg)",
										width: "80px",
										height: "80px"
									}}
								/>
							)}

							<TextfieldPicker
								value={endDate}
								limitValues={true}
								/* limitMin={startDate} */
								excludePast={false}
								/* removeIcon={checkWidth} */
								label={t("textfield_labels.end_date")}
								fullDate={true}
								valueChange={handleEndDate}
								hasProps={
									<RestrictionEndIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</div>

						<Collapse in={true} collapsedSize={0}>
							<AlertBar
								type="info"
								textContent={t(
									"doors.access_right_restricted_by_time"
								)}
								whiteSpacePreLine
								standardVariant
								showAlert
							/>
						</Collapse>

						<div
							style={{
								display: "inherit",
								gap: "20px",
								flexDirection: isDesktop ? "row" : "column"
							}}
						>
							<TextfieldPicker
								value={getStart}
								limitValues={true}
								limitMin={blankDate}
								limitMax={getEnd}
								excludePast={false}
								noTime={false}
								/* removeIcon={checkWidth} */
								label={t("recipes.from_time")}
								valueChange={handleStart}
								hasProps={
									<RestrictionStartIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							{!checkWidth && (
								<ArrowIcon
									stroke="black"
									style={{
										transform: "rotate(180deg)",
										width: "80px",
										height: "80px"
									}}
								/>
							)}

							<TextfieldPicker
								value={getEnd}
								limitValues={true}
								limitMin={getStart}
								noTime={false}
								excludePast={false}
								/* removeIcon={checkWidth} */
								label={t("recipes.to_time")}
								valueChange={handleEnd}
								hasProps={
									<RestrictionEndIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</div>

						<Collapse in={true} collapsedSize={0}>
							<AlertBar
								type="info"
								textContent={t(
									"doors.access_right_restricted_weekdays"
								)}
								whiteSpacePreLine
								standardVariant
								showAlert
							/>
						</Collapse>

						<WeekdaySelect
							days={String(weekdaysString).split("")}
							weekdayClicked={handleWeekdays}
							singleSelect={false}
						/>
					</div>
				</Collapse>

				<AlertBar
					textContent={errorMessage}
					showAlert={showAlert}
					type="error"
					whiteSpacePreLine
					newLine
				/>

				{showList && (
					<div
						style={{
							width: "100%"
						}}
					>
						<Typography variant="form_bold">
							{t("doors.new_users_to_add")}
						</Typography>

						<UserListHandler
							showList={!showAlert}
							showFailed={showAlert}
							userList={emailList}
							listFailed={failedList}
							/* removeUser={removeUserID}
							editFailedList={editFailedList}
							removeFailedUser={removeFailedUser} */
							toggleAlert={false}
						/>
					</div>
				)}

				<div style={{ flex: 1 }} />

				<div style={{ marginTop: "auto", width: "100%" }}>
					<Button
						variant="drawerFormAccept"
						fullWidth
						disabled={checkDisabled()}
						onClick={() =>
							acceptingNew
								? handleUpdateUser(selectedRequest)
								: addUser()
						}
						sx={{
							backgroundColor: checkDisabled()
								? ColorScheme.disabledButton
								: undefined,
							border: "1px solid #E4E4E4"
						}}
					>
						<SaveAnimationButton loadingButton={loadingButton} />
					</Button>
				</div>
			</Stack>
		</Box>
	);
};
