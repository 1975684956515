import React, { useState, useContext } from "react";

import {
	Grid,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	Button,
	CircularProgress,
	Collapse
} from "@mui/material";
import { SubmitButton } from "./SubmitButton";
import { useNavigate } from "react-router-dom";

import passwordIcon from "../../Assets/Icons/PasswordFieldIcon.svg";
import usernameIcon from "../../Assets/Icons/UsernameFieldIcon.svg";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { AlertBar } from "../Common/AlertBar";

export const LoginFormField = ({ switchComponent }) => {
	const [username, setUsername] = useState("");
	const [userPass, setUserPass] = useState("");

	const [alert, showAlert] = useState(false);
	const navigate = useNavigate();

	const [logging, setLogging] = useState(false);
	const { t } = useTranslation();

	const { ApiCall } = useContext(ApiContext);

	function deleteAllCookies() {
		let cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++)
			// let cookie = cookies[i];
			// let eqPos = cookie.indexOf("=");
			// let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie =
				"webapp-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
	}

	const handleSuccessfullLogin = async (response) => {
		await deleteAllCookies();
		document.cookie = "webapp-token=" + response.access_token + "; path=/;";
		navigate("/");
	};

	/* ToDo: Kirjautuminen omaan komponenttiinsa */

	const handleLogin = (event) => {
		setLogging(true);
		event.preventDefault();

		ApiCall("POST", "login", {
			email: username,
			password: userPass
		})
			.then((response) => {
				handleSuccessfullLogin(response);
				setLogging(false);
				showAlert(false);
			})
			.catch(() => {
				setLogging(false);
				showAlert(true);
			});
	};

	return (
		<>
			<form
				onSubmit={handleLogin}
				style={{ width: "100%" }}
				data-testid="login-form"
			>
				<Grid item xs={12} data-testid="app-name">
					<Typography
						variant="h5"
						sx={{
							color: "#FFFFFF",
							fontSize: "10px",
							textTransform: "uppercase"
						}}
					>
						{t("common.app_name")}
					</Typography>
				</Grid>
				<br />
				<Grid item xs={12} data-testid="login-title">
					<Typography variant="h5" sx={{ color: "#FFFFFF" }}>
						{t("login.login_title")}
					</Typography>
				</Grid>
				<br />
				<Grid item xs={12} data-testid="user-name-login">
					<Typography
						sx={{
							color: "#FFFFFF",
							fontSize: "12px",
							marginTop: "5px"
						}}
					>
						{t("login.user_name_label")}
					</Typography>
					<TextField
						placeholder="Syötä käyttäjätunnus"
						sx={{
							backgroundColor: "#FAFAFA",
							borderRadius: "5px",
							fontSize: "10px"
						}}
						id="username-id"
						data-testid="username-input"
						variant="outlined"
						margin="dense"
						autoComplete="username"
						fullWidth={true}
						size="small"
						onChange={(e) => setUsername(e.target.value)}
						InputLabelProps={{ style: { fontSize: 40 } }}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									disablePointerEvents
								>
									<img src={usernameIcon} />
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography
						data-testid="user-password-login"
						sx={{
							color: "#FFFFFF",
							fontSize: "12px",
							marginTop: "5px"
						}}
					>
						{t("login.user_password_label")}
					</Typography>
					<TextField
						placeholder={t("login.user_password")}
						sx={{
							backgroundColor: "#FAFAFA",
							borderRadius: "4px",
							fontSize: "8px"
						}}
						id="password-id"
						data-testid="password-input"
						type="password"
						variant="outlined"
						autoComplete="current-password"
						margin="dense"
						fullWidth={true}
						size="small"
						onChange={(e) => setUserPass(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									disablePointerEvents
								>
									<img src={passwordIcon} />
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<br />

				<Collapse data-testid="alert-bar-collapse" in={alert}>
					<AlertBar
						data-testid="alert-bar"
						type={"error"}
						standardVariant={true}
						textContent={t("login.login_failed")}
						showAlert={true}
					/>
					<div style={{ height: "5px" }} />
				</Collapse>

				<Stack
					data-testid="login-button-stack"
					style={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%"
					}}
				>
					{!logging ? (
						<Grid md={12} item style={{ width: "100%" }}>
							<Stack direction="row" spacing={2}>
								<Button
									data-testid="register-button"
									variant="standard"
									onClick={() =>
										switchComponent("registration")
									}
									sx={{
										backgroundColor:
											"rgba(255, 255, 255, 0.2)",
										color: "#FFFFFF",
										fontSize: "10px",
										width: 1,
										borderRadius: "4px",
										height: "44px"
									}}
								>
									<Typography
										sx={{ fontSize: "10px" }}
										textTransform={"capitalize"}
									>
										{t("buttons.register")}
									</Typography>
								</Button>
								<SubmitButton
									data-testid="login-button"
									text={t("buttons.login")}
								/>
							</Stack>
						</Grid>
					) : (
						<CircularProgress
							color={"info"}
							data-testid="loading-circle"
						/>
					)}
				</Stack>
			</form>
			<div
				style={{
					width: "100%",
					paddingTop: "30px",
					display: "flex",
					justifyContent: "flex-end"
				}}
			>
				<Button
					onClick={() => switchComponent("forgot")}
					data-testid="forgot-password-button"
				>
					<Typography
						textTransform={"capitalize"}
						sx={{
							color: "#FFFFFF",
							fontSize: "10px",
							textDecoration: "underline"
						}}
					>
						{t("buttons.forgot_account_details")}
					</Typography>
				</Button>
			</div>
		</>
	);
};
