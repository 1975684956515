import React from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { theme } from "../../Themes/Theme";
import { NumbersOnly } from "./Inputs/NumbersOnly";
import TooltipWrapper from "./TooltipWrapper";

export const TextFieldInput2 = ({
	text, // selected value
	valueChange, // function to change value (onChange)
	title, // if input is empty this will be the placeholder, if not it will be the label above the input
	rowAmount, // if defined, input will be multiline. <<--- needs to be refactored to be boolean instead of value
	hasProps, // icon or other element for input field
	hasCharLimit, // if defined (number), input will have a character limit
	charCount, // if defined (boolean), input will show character count
	isRequired, // if defined (boolean), input will be required
	checkValue, // if defined (boolean), input will be checked for validity
	onlyNumbers,
	defaultText,
	helpText,
	removeIcon,
	endAdornment,
	onlyEmail,
	handleManualAction,
	validateManualAction,
	enableManualAction,
	maxNumberValue
}) => {
	const handleInputChange = (input) => {
		const value = input.target.value;

		if (onlyNumbers && !NumbersOnly(value)) return;
		else valueChange(value);
	};

	const validateEmail = (email) => {
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
		return emailRegex.test(email);
	};

	const validateUserInput = (id) => {
		const internationalRegex = /^\+(?:[0-9]?){1,4}[0-9]{9,14}(?:x.+)?$/; // intl phone numbers with required country code
		const finnishRegex =
			/^(?:\+358|0)(?:\s|-)?\d{1,3}(?:\s|-)?\d{4,}(?:\s|-)?\d{0,2}$/; // finnish numbers with and without country code prefix

		const phoneIsValid =
			internationalRegex.test(id) || finnishRegex.test(id);

		const emailIsValid = validateEmail(id);

		const allDigitsAreSame = /^(\d)\1+$/.test(id); // prevent lazy inputs like 0000000

		return (phoneIsValid || emailIsValid) && !allDigitsAreSame;
	};

	const checkValidity = (id) =>
		onlyEmail ? !validateEmail(id) : !validateUserInput(id);

	return (
		<Box
			style={{
				...theme.toloStyled.toloTextfield,

				...(rowAmount !== undefined && {
					/* minHeight: "100px", // "calc(28px * 2) */
					/* minHeight: `calc(5px * ${text.length / 7.6})`, */
					maxHeight: undefined, // let parent box dynamically handle height as needed in multirow mode
					position: "relative"
				})
			}}
		>
			{!removeIcon && (
				<Box
					style={{
						...theme.toloStyled.toloTextfield.icon,
						...(rowAmount !== undefined && {
							// aligns icon to top if multiline
							// ToDo: fix scaling (especially for 10+ rows)
							display: "flex",
							flexWrap: "wrap",
							/* minHeight: "100%",
							height: "100%",  `calc(2px * ${text.length / 8})`, 
							maxHeight: "100%", */
							alignItems: "normal",
							/* position: "fixed", */
							height: undefined,
							minHeight: undefined,
							position: "absolute",
							top: "30px"
							/* left: "10px", */
						})
					}}
				>
					<TooltipWrapper title={helpText}>{hasProps}</TooltipWrapper>
				</Box>
			)}

			<TextField
				/* fullWidth */

				variant="filled"
				label={title}
				placeholder={defaultText}
				multiline={rowAmount !== undefined ? true : false}
				/* rows={rowAmount !== undefined && rowAmount} */
				error={checkValue || maxNumberValue < Number(text)}
				required={isRequired}
				type={onlyNumbers === "tel" ? "tel" : "text"}
				helperText={
					charCount &&
					hasCharLimit !== undefined &&
					`${text?.length || "0"} / ${hasCharLimit}`
				}
				inputProps={hasCharLimit && { maxLength: hasCharLimit }}
				sx={{
					width: "100%",
					/* height: `${rowAmount !== undefined ? `calc(28px * ${rowAmount})` : "auto"}`, */
					alignItems: "end", // aligns helpertext to right if it exists
					...(rowAmount !== undefined && {
						position: "relative",
						marginLeft: "62px",
						marginTop: "10px"
					})
				}}
				InputProps={{
					disableUnderline: true,
					style: {
						width: "100%",
						color: "black",
						backgroundColor: "transparent",
						borderRadius: "20px",
						border: "0px solid rgba(0,0,0,0)",
						fontSize: "15px"
					},
					endAdornment: (
						<InputAdornment
							position="end"
							sx={{ paddingRight: "5px" }}
						>
							<Typography fontSize={14} color={"black"}>
								{endAdornment}
							</Typography>
						</InputAdornment>
					)
				}}
				InputLabelProps={{
					style: {
						fontSize: "14px",
						top: /* rowAmount !== undefined ? "5px" : */ "3px"
					}
				}}
				value={text}
				onChange={handleInputChange}
				onKeyDown={(e) => {
					if (enableManualAction) return; // prevent enter key from triggering handleAnotherUser if input is invalid
					if (validateManualAction && e.key === "Enter")
						if (checkValidity(e.target.value)) handleManualAction();
				}}
			/>
		</Box>
	);
};
