// Simple file to hold the tax percentages for the different tax types
// Add new tax types here if needed and they will be available in the dropdowns

export const TaxPercentages = [
	{ value: 0, label: "0 %" },
	{ value: 10, label: "10 %" },
	{ value: 14, label: "14 %" },
	{ value: 24, label: "24 %" },
	{ value: 25.5, label: "25,5 %" }
];
