import { ThemeProvider } from "@emotion/react";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { App } from "./App";

import i18n from "./Localization/i18n";

import "./index.css";
import { theme } from "./Themes/Theme";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CookiesProvider>
				<I18nextProvider i18n={i18n}>
					<App />
				</I18nextProvider>
			</CookiesProvider>
		</ThemeProvider>
	</React.StrictMode>
);
