import { Box, Typography } from "@mui/material";
import React from "react";
import { ConfirmAction } from "./ConfirmAction";
import { ColorScheme } from "../../Themes/ColorScheme";
import { PinCodeBox } from "./PinCodeBox";
import { useTranslation } from "react-i18next";

export const UserCard = ({ user, index, users, handleRemove, slot }) => {
	const { t } = useTranslation();

	const emailVariables =
		user?.user?.email ?? user?.email ?? user ?? undefined;

	const nameVariables =
		user?.user?.name ??
		user?.name ??
		(user !== emailVariables ? user : undefined) ??
		undefined;

	const additionalInfo = slot?.id ?? null;

	const usersLength = users?.length || 0;

	return (
		<>
			<Box
				sx={{
					maxWidth: "100%",
					flexDirection: "row",
					py: "20px",
					px: "40px",
					justifyContent: "space-between",
					display: "flex",
					border: "1px solid" + ColorScheme.lightGrey,
					borderTop:
						index !== 0
							? "none"
							: "1px solid" + ColorScheme.lightGrey,
					alignItems: "center",
					borderRadius:
						index === 0 && usersLength > 1
							? "4px 4px 0px 0px"
							: usersLength === 1
								? "4px"
								: usersLength === index + 1
									? "0px 0px 4px 4px"
									: undefined
				}}
			>
				<div>
					<Typography
						style={{
							fontSize: 16,
							fontWeight: "700"
						}}
					>
						{nameVariables}
					</Typography>
					<Typography
						style={{
							fontSize: nameVariables === undefined ? 16 : 12,
							fontWeight:
								nameVariables !== undefined ? "700" : "400",
							color:
								nameVariables !== undefined ? "grey" : "black"
						}}
					>
						{emailVariables}
					</Typography>
				</div>

				{user.reservation_accesses !== null &&
					user.reservation_accesses !== undefined &&
					user.reservation_accesses.length > 0 &&
					user.reservation_accesses[0].pin_code !== undefined &&
					user.reservation_accesses[0]?.pin_code !== null && (
						<PinCodeBox
							pinCode={
								user.reservation_accesses[0].door_access
									.pin_code
							}
						/>
					)}

				<ConfirmAction
					handleTitle={t("buttons.remove_from_list")}
					isIconButton={true}
					handleConfirmAction={() => {
						handleRemove(
							emailVariables,
							additionalInfo !== null && additionalInfo
						);
					}}
				/>
			</Box>

			{/* {index !== users.length - 1 && (
                <div
                    style={{
                        height: "1px",
                        width: "100%",
                        backgroundColor: "#E4E4E4"
                    }}
                />
            )} */}
		</>
	);
};
