export function downloadCsvFromJson(headers, jsonData, fileName) {
	let csv = headers.join(",") + "\n";

	jsonData.forEach((row) => {
		csv += Object.values(row).join(",") + "\n";
	});

	const blob = new Blob([csv], { type: "text/csv" });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = fileName + ".csv";
	a.click();
	window.URL.revokeObjectURL(url);
}
