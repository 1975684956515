import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { theme } from "../../Themes/Theme";
import { NumbersOnly } from "./Inputs/NumbersOnly";

export const TextFieldInput = ({
	text,
	valueChange,
	title,
	isMultiline,
	hasProps,
	size,
	hasCharLimit,
	charCount,
	isRequired,
	checkValue,
	inputType,
	styled,
	defaultText,
	newStyle
}) => (
	<TextField
		/* variant="toloTextField" */
		key={title}
		type={inputType}
		fullWidth
		size={size}
		label={title}
		value={text}
		error={checkValue}
		required={isRequired}
		helperText={charCount && text !== undefined && `${text.length} / 255`}
		multiline={isMultiline}
		rows={isMultiline && 3}
		placeholder={defaultText && defaultText}
		sx={
			newStyle
				? theme.toloStyled.default_textfield
				: styled
					? theme.formStyles.formInput
					: undefined
		}
		onChange={(input) => {
			if (inputType === "tel" && !NumbersOnly(input.target.value)) return;
			else valueChange(input.target.value);
		}}
		inputProps={
			hasCharLimit !== undefined && {
				maxLength: hasCharLimit
			}
		}
		InputProps={
			newStyle
				? {
						notched: false,
						disableUnderline: true,
						startAdornment: hasProps !== undefined && (
							<InputAdornment
								position="start"
								disablePointerEvents
							>
								{hasProps}
							</InputAdornment>
						)
					}
				: hasProps !== undefined
					? {
							startAdornment: (
								<InputAdornment
									position="start"
									disablePointerEvents
									style={{ minWidth: "24px" }}
								>
									{hasProps}
								</InputAdornment>
							)
						}
					: undefined // You can omit this line if you want to leave InputProps undefined when no conditions are met
		}
	/>
);
