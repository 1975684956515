/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import {
	Box,
	Button,
	Collapse,
	Dialog,
	Stack,
	Typography
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../ApiContext";
import { Spinner } from "../../Common/Spinner";
import { CurrentRecipes } from "./Fields/CurrentRecipes";
import { ServiceDoors } from "./Location/ServiceDoors";
// import { SelectType } from "./SelectType";

import { ReactComponent as NameIcon } from "../../../Assets/Icons/BurgerIcon.svg";
import { ReactComponent as TypeIcon } from "../../../Assets/Icons/MainIcon.svg";
import { ReactComponent as TaxIcon } from "../../../Assets/Icons/PercentIcon.svg";
import { ReactComponent as UserIcon } from "../../../Assets/Icons/UserIcon.svg";
import { ReactComponent as PenIcon } from "../../../Assets/Icons/PenIcon.svg";
import { ColorScheme } from "../../../Themes/ColorScheme";
import TooltipWrapper from "../../Common/TooltipWrapper";
import { ConfirmAction } from "../../Common/ConfirmAction";
import { ReservableTypeOptions } from "../../../Datafields/ReservableTypeOptions";
import { TextFieldInput2 } from "../../Common/TextFieldInput2";
import { TextFieldSelect } from "../../Common/TextFieldSelect";
import { ServiceTimings } from "./Location/ServiceTimings";
import { TaxPercentages } from "../../../Datafields/TaxPercentages";
import { RemoveButton } from "./Components/RemoveButton";
import { RecipeModal } from "./Fields/RecipeModal";
import { ImageUpload } from "../../Common/ImageUpload";
import { KeyUserAmount } from "../../../Datafields/UserLimits";
import { ProductPrice } from "./Product/ProductPrice2";
import { AlertBar } from "../../Common/AlertBar";
// import { isMobile } from "react-device-detect";

export const ModifyService = ({
	info,
	data,
	content,
	oldService,
	handleDrawerClose,
	fieldRefresh,
	services
}) => {
	const debug = true;

	const ProductId = data.id;
	const [selectType, setSelectType] = useState("");
	const [textValue, setTextValue] = useState("");
	const [showContent, setShowContent] = useState(false);
	const [productDescription, setProductDescription] = useState("");

	const [productLimit, setProductLimit] = useState("");
	const [productPrice, setProductPrice] = useState("");
	const [productAmount, setProductAmount] = useState("");
	const [timeAmount, setTimeAmount] = useState("");
	const [balanceAmount, setBalanceAmount] = useState(0);

	const [propType, setPropType] = useState(0);

	const [loading, setLoading] = useState(false);
	const [allowSave, setAllowSave] = useState(false);
	const [productImage, setProductImage] = useState("");
	/* const [doorData, setDoorData] = useState([]);*/
	const [testData, setTestData] = useState([]);
	const [equipData, setEquipData] = useState([]);
	const [vatPercentage, setVatPercentage] = useState("");

	const [accessDelay, setAccessDelay] = useState(data?.access_delay || 15);
	const [accessAdvance, setAccessAdvance] = useState(
		data?.access_advance || 15
	);
	const [cronTiming, setCronTiming] = useState(data?.cron_timing || 14);
	const [loadingButton, setLoadingButton] = useState(false);
	const [userLimit, setUserLimit] = useState(data?.user_limit || 50);

	const [toggleContent, setToggleContent] = useState(data?.type || 999);
	const [showToggle, setShowToggle] = useState(false);

	const [openModal, setOpenModal] = useState(false);

	const [phase, setPhase] = useState(0);
	const [error, setError] = useState([]);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const handleModalOpen = (event) => {
		event.preventDefault();
		setOpenModal(true);
	};

	const handleModalClose = () => {
		setOpenModal(false);
		setError([]);
		setLoading(false);
	};

	const handleCronTiming = (value) => {
		setCronTiming(value);
	};

	const handlePassDelay = (value) => {
		setAccessDelay(value);
	};

	const handlePassAdvance = (value) => {
		setAccessAdvance(value);
	};

	const handleContentChange = (value) => {
		// better form clear and reset when changing type
		setTestData([]);
		setEquipData([]);

		setToggleContent(value);
	};

	const handleProductImage = (props) => {
		setProductImage(props);
	};

	const handleDoorData = (props) => {
		setTestData(props);
	};

	const handleEquipData = (props) => {
		setEquipData(props);
	};

	const saveProductData = (prop) => {
		/* if (prop.type !== null) setProductType(prop.type);*/
		if (prop.amount !== null) setProductAmount(prop.amount);
		if (prop.limit !== null) setProductLimit(prop.limit);
		if (prop.price !== null) setProductPrice(prop.price);
		if (prop.timeAmount !== null) setTimeAmount(prop.timeAmount);
		if (prop.vatAmount !== null) setVatPercentage(prop.vatAmount);
		if (prop.balanceAmount !== null) setBalanceAmount(prop.balanceAmount);
		setPropType(prop.type);
	};

	const Reservable = {
		name: textValue,
		access_delay:
			toggleContent === 3
				? 0
				: accessDelay === ""
					? data.access_delay
					: Number(accessDelay),
		access_advance:
			toggleContent === 3
				? 0
				: accessAdvance === ""
					? data.access_advance
					: Number(accessAdvance),
		cron_timing:
			toggleContent === 3
				? 0
				: cronTiming === ""
					? data.cron_timing
					: Number(cronTiming),
		service_class_id: Number(selectType),
		vat_percentage: Number(vatPercentage),
		type: toggleContent,
		...(toggleContent === 3 && {
			user_limit: userLimit
		}),
		/* type: 3, workaround solution */
		...(equipData.length > 0 && {
			service_devices: equipData.map((entry) => ({
				advance: 5,
				delay: 5, // todo
				door_id: entry.id
				/* reservable_id: 0 */
			}))
		}),
		...(!oldService && { location_id: info.location_id }),
		...(testData !== undefined &&
			testData.length > 0 && {
				service_doors: testData.map((door) => ({
					door_id: door.id
				}))
			})
	};

	const Product = {
		name: textValue,
		service_class_id: selectType,
		description: productDescription,
		...(timeAmount > 0 && {
			duration: Number(Number(timeAmount).toFixed(0))
		}), // with one its a string, with 2 its a number ... wtf
		max_amount: Number(productAmount),
		image: {
			image: productImage,
			image_type: 5
		},
		...(productLimit > 0 && { usage_count: Number(productLimit) }),
		/* used_at: "string",*/
		vat_percentage: Number(vatPercentage),
		vat_price: Number(productPrice),
		location_id: info.location_id,
		...(balanceAmount > 0 && { balance_amount: Number(balanceAmount) }),
		...(testData !== undefined &&
			testData.length > 0 && {
				service_doors: testData.map((door) => ({
					door_id: door.id
				}))
			})
	};

	const switchOnClick = () => {
		if (!oldService) handleSave();
		else handleUpdate();
	};

	const SwitchPutRoute =
		content === 1
			? `client/update_reservable/${data.id}`
			: `client/product/${data.id}`;

	const SwitchPostRoute =
		content === 1 ? "client/new_reservable" : "client/product"; // todo

	const SwitchBody = content === 1 ? Reservable : Product;

	const handleNewRecipeSave = (props) => {
		// setIsLoading(true);

		setLoading(true);

		const mapDays = props.weekday.map((day) => ({
			from_time: props.from_time,
			valid_from: props.valid_from,
			valid_until: props.valid_until,
			minimum_reservation: props.minimum_reservation,
			short_reservable_id: Number(data.id),
			to_time: props.to_time,
			user_limit: Number(props.user_limit),
			vat_price: Number(props.vat_price),
			weekday: Number(day)
		}));

		const flattenedArray = mapDays.flat(); // todo vaihda .push()

		ApiCall("POST", "client/reservable_time/add", flattenedArray)
			.then(() => {
				handleModalClose();

				/* handleRefresh();
				setIsSuccess(true);
				setShowAlertBar(true);
				setIsLoading(false); */
				/* setShowAlertBar(false); */
			})
			.catch((error) => {
				setError(error);
				setLoading(false); // show existing recipes
				/* if (debug) console.log(error);
				setIsSuccess(false);
				setShowAlertBar(true);
				setIsLoading(false); */
				/* setShowAlertBar(true); */
			});
	};

	const handleSave = () => {
		setLoadingButton(true);
		ApiCall("POST", SwitchPostRoute, SwitchBody)
			.then((res) => {
				if (debug) console.log(res);
				setLoadingButton(false);
				setAllowSave(false);
				setShowContent(true);
				fieldRefresh();
				handleDrawerClose();
			})
			.catch((e) => {
				if (debug) console.log(e);
				setLoadingButton(false);
				setError(e);
			});
	};

	const handleUpdate = () => {
		setLoadingButton(true);
		ApiCall("PUT", SwitchPutRoute, SwitchBody)
			.then(() => {
				/* setNewServiceId(service.data.id);*/
				setLoadingButton(false);
				setAllowSave(false);
				setShowContent(true);
				handleDrawerClose();
				fieldRefresh();
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoadingButton(false);
				setError(error);
			});
	};

	const handleRemove = () => {
		ApiCall("DELETE", `client/product/${ProductId}`, null)
			.then(() => {
				handleDrawerClose();
			})
			.catch((error) => {
				if (debug) console.log(error);
				setError(error);
			});
	};

	/* const titleText = () => {
		if (oldService && content === 1)
			return t("services.edit_reservable_space");
		else if (oldService && content === 2)
			return t("services.edit_additional_reservable");
		else if (!oldService && content === 1)
			return t("services.add_reservable_space");
		else if (!oldService && content === 2)
			return t("services.add_additional_reservable");
		else return ""; // TODO if this triggers close drawer to force user to try again?
	}; */

	const perm8 = info?.location_permissions.some(
		(perm) => perm.permission_id === 8
	);
	const perm2 = info?.location_permissions.some(
		(perm) => perm.permission_id === 2
	);

	const checkLocationPermissions = () => {
		if (perm8 && perm2) {
			setShowToggle(true);
		} else {
			setShowToggle(false);
			if (perm8 && !perm2) setToggleContent(3);
			else if (!perm8 && perm2) setToggleContent(0);
		}

		if (oldService) setToggleContent(data?.type);
	};

	useEffect(() => {
		setPhase(0);
		if (vatPercentage === "") setVatPercentage(data?.vat_percentage);
		if (selectType === "") setSelectType(data?.service_class_id);
		if (textValue === "") setTextValue(data?.name);
		if (productDescription === "") setProductDescription(data?.description);
		if (
			Object.prototype.hasOwnProperty.call(data, "image") &&
			productImage === ""
		)
			setProductImage(data?.image?.image);

		checkLocationPermissions();
	}, []);

	useEffect(() => {
		// Update state after user has stopped typing
		// Add your logic here
		if (toggleContent === 999 && textValue === undefined) setPhase(0);
		if (
			(toggleContent !== 999 && textValue === undefined) ||
			(content === 2 && textValue === undefined)
		)
			setPhase(1);
		if (
			(toggleContent !== 999 && textValue !== undefined) ||
			(content === 2 && textValue !== undefined)
		)
			setPhase(2);
		if (
			(toggleContent !== 999 &&
				textValue !== undefined &&
				selectType !== undefined) ||
			(content === 2 &&
				textValue !== undefined &&
				textValue !== "" &&
				selectType !== undefined)
		)
			setPhase(3);
		if (
			(toggleContent !== 999 &&
				toggleContent !== 3 &&
				selectType !== undefined &&
				vatPercentage !== undefined) ||
			(toggleContent === 3 && textValue !== undefined) ||
			(content === 2 &&
				textValue !== undefined &&
				textValue !== "" &&
				selectType !== undefined &&
				vatPercentage !== "")
		)
			setPhase(4);
	}, [textValue, selectType, toggleContent, phase, vatPercentage]);

	useEffect(() => {
		if (toggleContent === 3) {
			if (textValue !== "" && textValue !== undefined) setAllowSave(true);
			else setAllowSave(false);
		} else {
			if (
				textValue !== "" &&
				textValue !== undefined &&
				vatPercentage !== "" &&
				vatPercentage !== undefined &&
				Number(cronTiming) <= 90
			) {
				setAllowSave(true);
			} else setAllowSave(false);
		}
	}, [textValue, vatPercentage, cronTiming]);

	return (
		<Box
			sx={{
				// padding: "5%",
				backgroundColor: "#FFFFFF",
				width: "100%",
				// height: "100%", caused cancel/save buttons to travel above other content
				margin: "auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				/* gap: "20px", */
				height: "100%"
				/* paddingBottom: "20px" */
			}}
		>
			<Dialog open={openModal} onClose={() => setOpenModal(false)}>
				<RecipeModal
					data={null}
					showError={error}
					handleClose={handleModalClose}
					handleSave={handleNewRecipeSave}
					isNew={true}
				/>
			</Dialog>

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="baseline"
				paddingBottom={"20px"}
			>
				<div />

				{content === 2 && data.length !== 0 && (
					<ConfirmAction
						hasChild
						handleTitle={t("buttons.delete")}
						handleConfirmAction={() => handleRemove()}
					>
						<RemoveButton />
					</ConfirmAction>
				)}
			</Stack>

			<div style={{ height: "40px" }} />

			{showToggle && content === 1 && !oldService && (
				<div>
					{/* <Typography variant="h6">
						{t("services.service_type")}
					</Typography> */}

					<AlertBar
						type={"info"}
						standardVariant={true}
						textContent={t("services.service_type_info")}
						showAlert={true}
					/>

					<TextFieldSelect
						valueChange={handleContentChange}
						selected={toggleContent}
						hasProps={
							<TypeIcon stroke={ColorScheme.defaultIconColor} />
						}
						label={t("location.reservable_type_select")}
						options={ReservableTypeOptions}
					/>
					{/* <Collapse
						in={!oldService && phase < 1}
						collapsedSize={true}
						sx={{ display: oldService && "none" }}
					>
						<AlertBar
							type={"info"}
							standardVariant={true}
							textContent={t("services.service_type_info")}
							showAlert={true}
						/>
					</Collapse> */}
				</div>
			)}

			{(toggleContent !== 999 || content === 2) && (
				<Collapse in={phase > 0} collapsedSize={true}>
					<div
						style={{
							display: "flex",
							flexDirection: "column"
							/* gap: "10px" */
							/* border: "1px solid " + ColorScheme.textFieldBorder,
							borderRadius: "4px" */
						}}
					>
						{/* <Typography variant="h6">
							{content === 1
								? t("services.service_form_label")
								: t("product.product_form_label")}
						</Typography> */}

						<Collapse
							in={!oldService && phase > 0}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={
									content === 1
										? toggleContent === 3
											? t(
													"key_management.new_key_management"
												)
											: t("services.service_basic_info")
										: t("product.product_basic_info")
								}
								showAlert={true}
							/>
						</Collapse>

						<TextFieldInput2
							text={textValue}
							title={
								content === 1
									? t("services.reservable_space_name_label")
									: t(
											"services.additional_reservable_name_label"
										)
							}
							valueChange={setTextValue}
							defaultText={t(
								"services.textfield_placeholder_text"
							)}
							hasProps={
								<NameIcon
									stroke={ColorScheme.defaultIconColor}
									strokeWidth="3px"
								/>
							}
						/>

						{toggleContent !== 3 && (
							<TextFieldSelect
								selected={selectType}
								valueChange={setSelectType}
								label={t("services.select_sport")}
								defaultSelect={true}
								options={services}
								hasProps={
									<NameIcon
										stroke={ColorScheme.defaultIconColor}
										strokeWidth="3px"
									/>
								}
							/>
						)}
					</div>
				</Collapse>
			)}

			{toggleContent !== 999 && toggleContent === 3 && (
				<Collapse in={phase > 2} collapsedSize={true}>
					<div
						style={{
							display: "flex",
							flexDirection: "column"
						}}
					>
						<Collapse
							in={!oldService && phase > 3}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={t(
									"services.service_key_amount_info"
								)}
								showAlert={true}
							/>
						</Collapse>

						<TextFieldSelect
							valueChange={setUserLimit}
							hasProps={
								<UserIcon
									stroke={ColorScheme.defaultIconColor}
									strokeWidth="2px"
								/>
							}
							defaultSelect={true}
							selected={userLimit}
							label={t("key.user_limit")}
							options={KeyUserAmount}
						/>
					</div>
				</Collapse>
			)}

			{toggleContent !== 999 && toggleContent !== 3 && content !== 2 && (
				<Collapse in={phase > 2} collapsedSize={true}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							/* gap: "10px", */
							width: "100%",
							boxSizing: "border-box"
						}}
					>
						<Typography
							variant="h6"
							sx={{ paddingTop: "10px", paddingBottom: "10px" }}
						>
							{t("services.additional_settings")}
						</Typography>

						<Collapse
							in={!oldService && phase > 2}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={t("services.service_timings_info")}
								showAlert={true}
							/>
						</Collapse>

						<ServiceTimings
							oldData={{
								access_delay: accessDelay,
								access_advance: accessAdvance,
								cron_timing: cronTiming
							}}
							passCronTiming={handleCronTiming}
							passDelay={handlePassDelay}
							passAdvance={handlePassAdvance}
						/>

						<TextFieldSelect
							valueChange={setVatPercentage}
							selected={vatPercentage}
							hasProps={
								<TaxIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							}
							label={t("services.recipe_tax_percentage")}
							options={TaxPercentages}
							defaultSelect={true}
						/>

						{/* <Collapse
							in={!oldService && phase > 2}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={t("services.service_tax_info")}
								showAlert={true}
							/>
						</Collapse> */}
						<div style={{ height: "100px" }} />
					</div>
				</Collapse>
			)}

			{content === 2 && (
				<Collapse in={phase > 2} collapsedSize={true}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							paddingTop: "10px"
						}}
					>
						{/* 	<Typography variant="h6">
							{t("product.product_description")}
						</Typography> */}

						<Collapse
							in={!oldService && phase > 0}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={t(
									"product.product_description_info"
								)}
								showAlert={true}
							/>
						</Collapse>

						<TextFieldInput2
							rowAmount={6}
							title={t("product.product_description")}
							text={productDescription}
							charCount={true}
							hasCharLimit={255}
							hasProps={
								<PenIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							}
							valueChange={setProductDescription}
						/>

						<div style={{ height: "20px" }} />

						<Typography variant="h6">
							{t("product.product_image")}
						</Typography>
						<TooltipWrapper
							title={
								productImage.length !== 0 &&
								t("images.change_image_label")
							}
						>
							<div>
								<ImageUpload
									imageType={"product"}
									LocationId={location}
									isCourseLogo={true}
									oldImage={productImage}
									passCourseImage={handleProductImage}
								/>
							</div>
						</TooltipWrapper>

						{/* <Collapse
							in={!oldService && phase > 0}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={t("product.product_image_info")}
								showAlert={true}
							/>
						</Collapse>
 */}

						<div style={{ height: "20px" }} />
						<Typography variant="h6">
							{t("product.product_pricing_details")}
						</Typography>

						<Collapse
							in={!oldService && phase > 0}
							collapsedSize={true}
							sx={{ display: oldService && "none" }}
						>
							<AlertBar
								type={"info"}
								standardVariant={true}
								textContent={t("product.product_pricing_info")}
								showAlert={true}
							/>
						</Collapse>

						<ProductPrice
							data={data}
							isConsumable={true}
							handleProductData={saveProductData}
						/>
					</div>
				</Collapse>
			)}

			{(toggleContent !== 999 || content === 2) &&
				textValue !== undefined && (
					<Collapse in={phase > 3} collapsedSize={true}>
						{(propType === 2 || propType === 0) && (
							<Collapse
								in={
									!oldService &&
									(phase > 3 || (content === 2 && phase > 2))
								}
								collapsedSize={true}
								sx={{ display: oldService && "none" }}
							>
								<AlertBar
									type={"info"}
									standardVariant={true}
									textContent={
										toggleContent === 3
											? t(
													"services.service_key_access_rights_info"
												)
											: content !== 2
												? t(
														"product.product_access_rights_info"
													)
												: t(
														"product.product_access_rights_for_products_info"
													)
									}
									showAlert={true}
								/>
							</Collapse>
						)}

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "10px"
							}}
						>
							{/* <Typography variant="h6">
								{t("product.product_access_rights")}
							</Typography> */}

							{(propType === 2 || propType === 0) && (
								<ServiceDoors
									location={info.location_id}
									oldData={data}
									currentVat={vatPercentage}
									passSelectedData={handleDoorData}
									passAdvance={(value) =>
										setAccessAdvance(value)
									}
									passDelay={(value) => setAccessDelay(value)}
									passCronTiming={(value) =>
										setCronTiming(value)
									}
									handleVatChange={(value) =>
										setVatPercentage(value)
									}
									hideSelects={content}
								/>
							)}
							{(toggleContent !== 999 || content !== 2) &&
								toggleContent !== 3 &&
								propType === 0 && (
									<ServiceDoors
										location={info.location_id}
										oldData={data}
										passSelectedData={handleEquipData}
										isEquipment={true}
									/>
								)}
						</div>
					</Collapse>
				)}

			{content === 1 && <div style={{ minHeight: "50px" }} />}

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					/* height: "100%", */
					borderRadius: "4px",

					// border: "1px solid " + ColorScheme.textFieldBorder,
					// color: "white",
					// overflow: "hidden",
					// padding: "20px",
					// justifyContent: "center",
					// alignItems: "center",
					boxSizing: "border-box",
					cursor: "pointer",
					gap: "20px"
				}}
			>
				{data.length !== 0 &&
					toggleContent !== 999 &&
					content === 1 &&
					toggleContent !== 3 && (
						<>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
									// padding: "5px",
									boxSizing: "border-box",
									cursor: "default"
								}}
							>
								<Typography color={"black"} fontSize={"20px"}>
									{t("location.recipe_table_label")}
								</Typography>
								{/* <div style={{ width: "150px" }}> */}
								<Button
									variant="buttonEnabled_small"
									onClick={handleModalOpen}
								>
									{t("location.create_new_recipe")}
								</Button>
								{/* </div> */}
							</div>

							{!loading && (
								<div
									style={{ width: "100%", minHeight: "100%" }}
								>
									<Collapse
										in={true}
										timeout={{
											appear: 500,
											enter: 500,
											exit: 500
										}}
										collapsedSize={0}
									>
										<CurrentRecipes
											locationId={info.location_id}
											recipeId={data.id}
											isProduct={content}
										/>
									</Collapse>
								</div>
							)}
						</>
					)}
			</div>

			<div
				style={{
					flex: 1,
					overflow: "auto"
				}}
			/>

			<Collapse in={error.length > 0} collapsedSize={true}>
				<AlertBar
					type={"warning"}
					standardVariant={true}
					textContent={error}
					showAlert={true}
				/>
				{/* <div sx={{ height: "100px" }}></div> */}
			</Collapse>

			<div style={{ flex: 1 }} />

			<Stack
				spacing={2}
				direction="row-reverse"
				sx={{
					minWidth: "100%",
					paddingBottom: "20px",
					paddingTop: "20px"
				}} // for reasons this stack isnt respecting parent styling and with own paddingBottom it works
			>
				<Button
					disabled={!allowSave}
					variant="buttonEnabled"
					onClick={switchOnClick}
				>
					{loadingButton ? (
						<Spinner small={true} />
					) : !showContent && !oldService ? (
						t("buttons.create_new")
					) : (
						t("buttons.save_changes")
					)}
				</Button>

				<Button
					variant="buttonCancel"
					onClick={() => handleDrawerClose()}
				>
					{t("buttons.cancel")}
				</Button>
			</Stack>
		</Box>
	);
};
