import React from "react";
import { Stack, Typography } from "@mui/material";
import { ConfirmAction } from "../Common/ConfirmAction";
import { ReactComponent as TrashIcon } from "../../Assets/Icons/TrashDeleteIcon.svg";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop } from "react-device-detect";

export const CourseMeet = ({ data, removeMeet, index }) => {
	const { t } = useTranslation();
	const saveDate = new Date(data.from_time);
	const startDate = new Date(data.from_time);
	const endDate = new Date(data.to_time);

	return (
		<Stack
			direction="row"
			/* spacing={isMobile ? 0.5 : 0} */
			sx={{
				borderTop:
					index !== 0 ? "1px solid" + ColorScheme.lightGrey : "none",
				backgroundColor: ColorScheme.lighterGrey,
				alignItems: "center",
				boxSizing: "border-box",
				height: isDesktop ? "64px" : undefined,
				py: "10px"
			}}
		>
			<div
				style={{
					width: "calc(100% - 100px)",
					display: "flex",
					justifyContent: isDesktop ? "left" : "center",
					padding: isDesktop
						? "20px"
						: "5px" /* border: "1px solid" + ColorScheme.lightGrey */
				}}
			>
				<Typography fontWeight={700}>
					{format(saveDate, "dd.MM.yyyy")}
				</Typography>
			</div>

			<div
				style={{
					width: "calc(100% - 100px)",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					borderLeft: isDesktop
						? "1px solid" + ColorScheme.lightGrey
						: undefined,
					borderRight: isDesktop
						? "1px solid" + ColorScheme.lightGrey
						: undefined,
					padding: isDesktop ? "20px" : "5px"
				}}
			>
				<Typography fontWeight={700}>
					{format(startDate, "HH:mm")}
				</Typography>
				<Typography fontWeight={700}>-</Typography>
				<Typography fontWeight={700}>
					{format(endDate, "HH:mm")}
				</Typography>
			</div>

			<div
				style={{
					width: "100px",
					display: "flex",
					justifyContent: "center",
					padding: isDesktop ? "20px" : "5px"
				}}
			>
				<ConfirmAction
					handleTitle={t("courses.course_remove_meet_time")}
					handleConfirmAction={() => removeMeet(data.id)}
					hasChild
				>
					<TrashIcon
						stroke={ColorScheme.red}
						height={"28px"}
						width={28}
					/>
				</ConfirmAction>
			</div>
		</Stack>
	);
};
