import {
	Button,
	ClickAwayListener,
	Collapse,
	Stack,
	Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import { TextfieldPicker } from "../../../Common/TextfieldPicker";
import ScreenWidth from "../../../../Helpers/ScreenWidth";

import { ReactComponent as ArrowIcon } from "../../../../Assets/Icons/arrowBack.svg";
import { ReactComponent as RestrictionStartIcon } from "../../../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as RestrictionEndIcon } from "../../../../Assets/Icons/TimeEndIcon.svg";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { WeekdaySelect } from "../../../Common/WeekdaySelect";
import { AlertBar } from "../../../Common/AlertBar";
import { Spinner } from "../../../Common/Spinner";
import { ApiContext } from "../../../ApiContext";
import { getStatusProperty } from "../../../Common/GetStatusProperty";
import { UserContext } from "../../../UserContext";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const DoorUserEdit = ({ data, handleClick }) => {
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);
	const handleWidthCheck = (width) => ScreenWidth(width);

	const [startDate, setStartDate] = useState(data.access_starts_at);
	const [endDate, setEndDate] = useState(data.access_ends_at);
	const [startTime, setStartTime] = useState(data.start_time);
	const [endTime, setEndTime] = useState(data.access_ends_at);
	const [selectedWeekdays, setSelectedWeekdays] = useState(data.weekdays);
	const [loading, setLoading] = useState(false);
	const [restrictions, setRestrictions] = useState(data.restrict_times);
	const [error, setError] = useState("");

	/* const restricted = data.restrict_times; */

	const handleStartDate = (value) => {
		// console.log(value);
		setStartDate(value);
	};

	const handleEndDate = (value) => {
		// console.log(value);
		setEndDate(value);
	};

	const handleStart = (value) => {
		// console.log(value);
		setStartTime(value);
	};

	const handleEnd = (value) => {
		// console.log(value);
		setEndTime(value);
	};

	const handleWeekdays = (day) => {
		setSelectedWeekdays((prevState) => {
			// convert string into array
			const convertToArray = String(prevState).split("");

			// check if day is already in array
			const dayIndex = convertToArray.indexOf(String(day));

			if (dayIndex !== -1) {
				// if day is in array, remove it
				convertToArray.splice(dayIndex, 1);
			} else {
				// if day is not in array, add it
				convertToArray.push(String(day));
			}

			return convertToArray.join(""); // convert array back to string
		});
	};

	const handleRestriction = () => {
		setRestrictions(!restrictions);
	};

	const handleSave = () => {
		setLoading(true);

		// console.log(data);

		ApiCall("PUT", "door/update_access", {
			location_id: Number(selectedLocation.location_id),
			door_id: data.door_id,
			id: String(data.id),
			granted: String(true), // always true or it will get removed
			access_starts_at: startDate,
			access_ends_at: endDate,
			weekdays: selectedWeekdays,
			start_time: startTime,
			end_time: endTime,
			restrict_times: String(restrictions),
			email: data?.email || "", // if null/undefined we will return empty strings
			phone: data?.phone || "" //
		})
			.then(() => {
				setError("");
				handleClick();
			})
			.catch((error) => {
				setError(error);
				// console.log(error);
			})
			.finally(setLoading(false));
	};

	return (
		<ClickAwayListener onClickAway={handleClick}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					padding: "20px"
				}}
			>
				<div
					style={{
						display: "inherit",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					<Typography
						variant="form_bold"
						fontSize={24}
						height={"100%"}
						lineHeight={!isDesktop && 1}
					>
						{t("doors.edit_access_right")}
					</Typography>

					<Button
						variant="smallAccept"
						onClick={handleRestriction}
						sx={{ minHeight: !isDesktop && "50px" }}
					>
						{restrictions
							? t("buttons.remove_time_restriction")
							: t("buttons.add_time_restriction")}
					</Button>
					{/* <QuestionTooltip title="door_user_access_right_guide" /> */}
				</div>

				<Collapse in={!restrictions} collapsedSize={0}>
					<AlertBar
						type="info"
						textContent={t("doors.door_user_access_right_guide")}
						whiteSpacePreLine
						standardVariant
						showAlert
					/>
				</Collapse>

				<Collapse in={restrictions} collapsedSize={0}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
							transition: "display 0.8s"
						}}
					>
						<Collapse in={true} collapsedSize={0}>
							<AlertBar
								type="info"
								textContent={t(
									"doors.access_right_restriction_duration"
								)}
								whiteSpacePreLine
								standardVariant
								showAlert
							/>
						</Collapse>

						<div
							style={{
								display: "inherit",
								flexDirection: isDesktop ? "row" : "column",
								gap: "20px"
							}}
						>
							<TextfieldPicker
								value={startDate}
								/* limitValues={false}
							limitMax={endDate} */
								excludePast={false}
								/* removeIcon={handleWidthCheck(1645)} */
								label={t("textfield_labels.start_date")}
								fullDate={true}
								valueChange={handleStartDate}
								hasProps={
									<RestrictionStartIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							{!handleWidthCheck(470) && (
								<ArrowIcon
									stroke="black"
									style={{
										transform: "rotate(180deg)",
										width: "80px",
										height: "80px"
									}}
								/>
							)}

							<TextfieldPicker
								value={endDate}
								/* limitValues={true}
							limitMin={startDate} */
								excludePast={false}
								/* removeIcon={handleWidthCheck(1645)} */
								label={t("textfield_labels.end_date")}
								fullDate={true}
								valueChange={handleEndDate}
								hasProps={
									<RestrictionEndIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</div>

						<Collapse in={true} collapsedSize={0}>
							<AlertBar
								type="info"
								textContent={t(
									"doors.access_right_restricted_by_time"
								)}
								whiteSpacePreLine
								standardVariant
								showAlert
							/>
						</Collapse>

						<div
							style={{
								display: "inherit",
								flexDirection: isDesktop ? "row" : "column",
								gap: "20px"
							}}
						>
							<TextfieldPicker
								value={startTime}
								isTimeRange={true}
								limitValues={true}
								limitMax={endTime}
								/* removeIcon={handleWidthCheck(1645)} */
								label={t("recipes.from_time")}
								fullDate={false}
								valueChange={handleStart}
								excludePast={false}
								hasProps={
									<RestrictionStartIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							{!handleWidthCheck(470) && (
								<ArrowIcon
									stroke="black"
									style={{
										transform: "rotate(180deg)",
										width: "80px",
										height: "80px"
									}}
								/>
							)}

							<TextfieldPicker
								value={endTime}
								isTimeRange={true}
								limitValues={true}
								limitMin={startTime}
								/* removeIcon={handleWidthCheck(1645)} */
								label={t("recipes.to_time")}
								fullDate={false}
								valueChange={handleEnd}
								excludePast={false}
								hasProps={
									<RestrictionEndIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</div>

						<Collapse in={true} collapsedSize={0}>
							<AlertBar
								type="info"
								textContent={t(
									"doors.access_right_restricted_weekdays"
								)}
								whiteSpacePreLine
								standardVariant
								showAlert
							/>
						</Collapse>

						<WeekdaySelect
							days={String(selectedWeekdays).split("")}
							weekdayClicked={handleWeekdays}
							singleSelect={false}
						/>
					</div>
				</Collapse>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "20px",
						padding: "20px",
						border: "1px solid rgba(0,0,0,0.1)",
						borderRadius: "8px",
						alignItems: "center"
					}}
				>
					{getStatusProperty(data, "door_users_icon", !restrictions)}
					<Typography>
						{getStatusProperty(
							data,
							"door_users_text",
							!restrictions
						)}{" "}
						{getStatusProperty(
							data,
							"door_users_date",
							!restrictions
						)}
					</Typography>
				</div>

				<Collapse in={error.length > 0} collapsedSize={0}>
					<AlertBar
						type="error"
						textContent={error}
						whiteSpacePreLine
						standardVariant
						showAlert
					/>
				</Collapse>

				<Stack
					sx={{
						width: "100%",
						alignItems: "end",
						paddingTop: "15px",
						flexDirection: "row",
						justifyContent: "right",
						gap: "10px"
					}}
				>
					<Button variant="buttonCancel_2" onClick={handleClick}>
						{t("buttons.cancel")}
					</Button>

					<Button
						variant="buttonEnabled"
						onClick={handleSave}
						sx={{ height: "54px" }}
						/* disabled={handleSaveCheck()} */
					>
						{loading ? (
							<Spinner small />
						) : (
							<Typography sx={{ textAlign: "center" }}>
								{t("buttons.save")}
							</Typography>
						)}
					</Button>
				</Stack>
			</div>
		</ClickAwayListener>
	);
};
