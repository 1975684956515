import React from "react";
import { ColorScheme } from "../../Themes/ColorScheme";
import { Box, TextField } from "@mui/material";
import { ConfirmAction } from "./ConfirmAction";
import { useTranslation } from "react-i18next";

export const UserEdit = ({
	entry,
	index,
	removeFailedUser,
	editFailedList
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "space-between",
				py: "20px",
				px: "40px",
				border: "1px solid" + ColorScheme.lightGrey
			}}
		>
			<TextField
				size="small"
				variant="standard"
				value={entry}
				error={true}
				sx={{ width: "90%" }}
				onChange={(event) => editFailedList(index, event.target.value)}
			/>

			<ConfirmAction
				isIconButton={true}
				handleTitle={t("campaign.remove_user_from_list_label")}
				handleConfirmAction={() => {
					removeFailedUser(entry);
				}}
			/>
		</Box>
	);
};
