import { Box, Typography } from "@mui/material";
import React from "react";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const PinCodeBox = ({ pinCode }) => {
	const { t } = useTranslation();

	return (
		<Box
			style={{
				backgroundColor: ColorScheme.tolotechBlue,
				borderRadius: "4px"
			}}
		>
			<Typography
				style={{
					fontSize: 14,
					fontWeight: "bold",
					color: ColorScheme.tolotechWhite
				}}
			>
				{t("doors.pin_code_label") + ": " + pinCode}
			</Typography>
		</Box>
	);
};
