import React, { useState, useRef, useEffect } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import default styles
import "./Styles/Calendar.css"; // Override default styles

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { theme } from "../../Themes/Theme";

import { ReactComponent as CalendarIcon } from "../../Assets/Icons/CalendarTwoLinesIcon.svg";

export const DateRangePicker = ({ handleDateData }) => {
	const startDate = new Date();
	startDate.setDate(1); // Set the start date to the first day of the current month

	const [dateRange, setDateRange] = useState([startDate, new Date()]);
	const [isCalendarOpen, setIsCalendarOpen] = useState(false);
	const inputRef = useRef(null);
	const calendarRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (
				inputRef.current &&
				calendarRef.current &&
				!inputRef.current.contains(e.target) &&
				!calendarRef.current.contains(e.target)
			) {
				setIsCalendarOpen(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const handleDateChange = (value) => {
		setDateRange(value.length === 1 ? [value[0], value[0]] : value);

		setTimeout(() => {
			handleDateData(value); // Pass the selected date range to the parent component
			setIsCalendarOpen(false); // Close the calendar after selecting a date range
		}, 400); // Slight delay before closing the calendar to allow the calendar to render the selected date range
	};

	const toggleCalendar = (e) => {
		if (
			e.target === inputRef.current ||
			e.target === inputRef.current.children[0] ||
			e.target === inputRef.current.children[1]
		) {
			setIsCalendarOpen((prev) => !prev); // Toggle calendar visibility
			if (!isCalendarOpen) {
				inputRef.current.focus(); // Focus the input field when opening the calendar
			}
		}
	};

	const getCalendarPosition = () => {
		const inputRect = inputRef.current.getBoundingClientRect();
		const windowHeight = window.innerHeight;

		const maxTopSpace = inputRect.top;
		const maxBottomSpace = windowHeight - inputRect.bottom;

		// Check if calendarRef.current is null before accessing its properties
		const calendarHeight = calendarRef.current
			? calendarRef.current.clientHeight
			: 0;

		if (maxBottomSpace >= maxTopSpace || calendarHeight >= maxTopSpace) {
			// Anchor the calendar below the input box if there is enough space below or if the calendar height exceeds the space above
			return {
				top: `${inputRect.height}px`,
				bottom: "auto"
			};
		} else {
			// Anchor the calendar above the input box if there is more space above
			return {
				top: "auto",
				bottom: `${inputRect.height}px`
			};
		}
	};

	return (
		<Box
			onClick={toggleCalendar}
			ref={inputRef}
			sx={{
				...theme.toloStyled.toloTextfield,
				minHeight: "20px",
				minWidth: "250px",
				px: "15px",
				position: "relative", // Ensure relative positioning for absolute positioning of the calendar
				overflow: "visible",
				justifyContent: "center",
				gap: "20px",
				cursor: "pointer"
			}}
		>
			<Typography
				readOnly // Prevent manual input
				sx={{ cursor: "pointer" }}
			>
				{dateRange[0].toLocaleDateString()} -{" "}
				{dateRange[1].toLocaleDateString()}
			</Typography>

			<CalendarIcon stroke="black" strokeWidth={1.5} />

			{isCalendarOpen && (
				<div
					className="calendar-container"
					ref={calendarRef}
					style={{
						position: "absolute", // Set absolute positioning for the calendar container
						zIndex: 999,
						...getCalendarPosition(), // Calculate the calendar position dynamically
						left: 0
					}}
				>
					<ReactCalendar
						onTouchStart={(e) => e.stopPropagation()}
						onChange={handleDateChange}
						value={dateRange}
						selectRange
						next2Label={null}
						prev2Label={null}
						className={"tolotech-calendar"}
					/>
				</div>
			)}
		</Box>
	);
};
