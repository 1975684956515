import React, { useContext, useEffect, useState } from "react";
import { Grid, Stack, Typography, Box } from "@mui/material";

import { ApiContext } from "../../../ApiContext";
import { UserContext } from "../../../UserContext";
import { useTranslation } from "react-i18next";
import { FormEdit } from "./Components/FormEdit";
import { FormSave } from "./Components/FormSave";
import { FormCancel } from "./Components/FormCancel";
import { CustomizeButton } from "./Components/CustomizeButton";
import { isMobile } from "react-device-detect";

export const TextDetail = ({ data, refreshData /* images*/ }) => {
	const debug = false;

	const [editMode, setEditMode] = useState(false);
	const [descriptionText, setDescriptionText] = useState("");
	const [nameText, setNameText] = useState("");

	const { selectedLocation } = useContext(UserContext);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const handleSave = () => {
		ApiCall("PUT", `location/update/${data.location_id}`, handleAxiosBody)
			.then(() => {
				setEditMode(!editMode);
				refreshData(data.location_id);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const handleAxiosBody = {
		description:
			data.description !== descriptionText
				? descriptionText
				: data.description,
		name: data.name !== nameText ? nameText : data.name
	};

	const cancelAction = () => {
		setDescriptionText(data.description);
		setNameText(data.name);
		setEditMode(!editMode);
	};

	/* 	useEffect(() => {
		setDescriptionText(data.description);
		setNameText(data.name);
	}, []); */

	useEffect(() => {
		setEditMode(false);
		setDescriptionText(data.description);
		setNameText(data.name);
	}, [selectedLocation]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				/* width: isMobile ? "95dvw" : "100%", */
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px",
				borderRadius: "4px",
				px: "15px",
				paddingBottom: "15px"
				/* paddingRight: "20px" */
			}}
		>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start"
				}}
			>
				<Stack
					spacing={3}
					sx={{
						width: "100%"
					}}
				>
					<Stack spacing={1} direction="row">
						<Stack
							spacing={2}
							sx={{
								width: "100%"
							}}
						>
							<Typography
								sx={{
									fontSize: isMobile ? 30 : 40,
									marginBottom: "5px",
									fontWeight: "700"
								}}
							>
								{editMode ? (
									<FormEdit
										title={t(
											"location.form_location_name_label"
										)}
										value={nameText}
										isRequired={true}
										changeData={(value) =>
											setNameText(value)
										}
									/>
								) : (
									nameText
								)}
							</Typography>
							<Typography variant="form_text_desc">
								{editMode ? (
									<FormEdit
										title={t(
											"location.form_location_description_label"
										)}
										value={descriptionText}
										type={"text"}
										isMultiRow={true}
										hasCharLimit={true}
										changeData={(value) =>
											setDescriptionText(value)
										}
									/>
								) : (
									descriptionText
								)}
							</Typography>
						</Stack>
					</Stack>

					<Grid container spacing={1}>
						{Object.prototype.hasOwnProperty.call(
							data,
							"location_permissions"
						) &&
							data.location_permissions.map(
								(permission, index) => (
									<Grid
										sx={{
											width: "max-content",
											height: "max-content",

											padding: "5px",

											px: "15px",
											backgroundColor:
												permission.permission.color,
											marginTop: "10px",
											borderRadius: "21px",
											marginRight: "10px",
											alignItems: "center"
										}}
										key={index}
									>
										<Typography
											style={{
												fontSize: "12px",
												fontWeight: "400",
												lineHeight: "14px",
												color: "white",
												textAlign: "center"
											}}
										>
											{permission.permission.name_fi}
										</Typography>
									</Grid>
								)
							)}
					</Grid>

					{editMode && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<FormSave
								check={nameText === ""}
								handleSave={handleSave}
							/>
							<FormCancel cancelAction={cancelAction} />
						</Box>
					)}
				</Stack>
			</div>
			{!editMode && (
				<CustomizeButton
					changeEditMode={() => setEditMode(!editMode)}
				/>
			)}
		</Stack>
	);
};
