import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	Grid,
	IconButton,
	List,
	ListItem,
	Typography
} from "@mui/material";
import CustomizeIcon from "../../../../Assets/Icons/CustomizeIcon.svg";
import uuid from "react-uuid";
// import { ProductRowTitles } from "./ProductRowTitles";
import { MuiDrawer } from "../../../Common/MuiDrawer";
import moment from "moment";
// import { PlaceholderSkeleton } from "../../../Common/PlaceholderSkeleton";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../UserContext";
import { PlaceholderSkeleton } from "../../../Common/PlaceholderSkeleton";
import { ProductRowTitles } from "./ProductRowTitles";

export const ManageProductList = ({
	mode,
	info,
	allProducts,
	allReservables,
	allServices,
	fieldRefresh,
	isLoading,
	getProducts,
	noData
}) => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);

	const [openDrawer, setOpenDrawer] = useState(false);
	const [enableModify, setEnableModify] = useState(false);
	const [serviceData, setServiceData] = useState([]);
	const [mapArray, setMapArray] = useState([]);

	/* const hasPermission8 = info.location_permissions.some(
		(permission) => permission.permission_id === 8
	);

	const hasPermission2 = info.location_permissions.some(
		(permission) => permission.permission_id === 2
	); */

	const closeDrawer = () => {
		if (mode === 2) getProducts();
		setServiceData([]);
		setOpenDrawer(false);
	};

	useEffect(() => {
		/* let filteredProducts = []; */

		/* if (hasPermission2 && hasPermission8 && mode === 1)
			filteredProducts = allReservables;
		else if (hasPermission8 && !hasPermission2 && mode === 1)
			filteredProducts = allReservables.filter(
				(reservable) => reservable.type === 3
			);
		else if (!hasPermission8 && hasPermission2 && mode === 1)
			filteredProducts = allReservables.filter(
				(reservable) => reservable.type !== 3
			);
		else filteredProducts = mode === 1 ? allReservables : allProducts; */

		const filteredProducts = mode === 1 ? allReservables : allProducts;

		setMapArray(filteredProducts);
	}, [allReservables, allProducts, mode, isLoading, noData]);

	useEffect(() => {
		setMapArray([]);
	}, [selectedLocation]);

	return (
		<List sx={{ width: "100%" }}>
			{mapArray.length > 0 && <ProductRowTitles field={mode === 1} />}
			{mapArray.length > 0 && mapArray !== undefined ? (
				mapArray.map((product) => {
					const logoImage =
						mode === 2 &&
						Object.prototype.hasOwnProperty.call(product, "image")
							? product.image.image
							: "";

					return (
						<React.Fragment key={product.id}>
							<Grid container>
								<ListItem
									sx={{
										padding: "20px",
										backgroundColor: "#FFFFFF",
										borderRadius: "4px",
										my: "5px",
										borderLeft:
											mode === 1
												? `4px solid ${product.service_class?.color}`
												: ""
									}}
								>
									{mode !== 1 && logoImage !== "" && (
										<img
											src={logoImage}
											width="34px"
											height="34px"
											style={{
												paddingRight: "15px"
											}}
										/>
									)}
									<Grid item xs={4}>
										<Typography
											style={{
												fontSize: "14px",
												fontWeight: "700",
												color: "rgb(40,40,40)"
											}}
										>
											{product.name}
										</Typography>
										<Typography
											style={{
												fontSize: "12px",
												fontWeight: "300"
											}}
										>
											{mode === 1
												? product.service_class?.name_fi
												: ""}
										</Typography>
									</Grid>

									{product.type !== 3 ? (
										<Grid
											item
											sx={{ textAlign: "center" }}
											xs={4}
										>
											<Typography
												style={{
													fontSize: "12px",
													fontWeight: "700"
												}}
											>
												{mode === 1
													? moment(
															product.open_hours
																?.from_time
														).format("HH:mm") ===
														moment(
															product.open_hours
																?.to_time
														).format("HH:mm")
														? t(
																"services.reservables_not_found"
															)
														: `${moment(
																product
																	.open_hours
																	?.from_time
															).format(
																"HH:mm"
															)} - ${moment(
																product
																	.open_hours
																	?.to_time
															).format("HH:mm")}`
													: `${
															product.vat_price.toFixed(
																2
															) +
															" " +
															t(
																"common.currency_symbol"
															)
														}`}
											</Typography>
										</Grid>
									) : (
										<Grid item xs={4} />
									)}

									<Grid
										item
										sx={{ textAlign: "right" }}
										xs={4}
									>
										<IconButton
											onClick={() => {
												setEnableModify(true);
												setOpenDrawer((open) => !open);
												setServiceData(product);
											}}
										>
											<img
												src={CustomizeIcon}
												alt="Customize Icon"
											/>
										</IconButton>
									</Grid>
								</ListItem>
							</Grid>
						</React.Fragment>
					);
				})
			) : (
				<PlaceholderSkeleton
					isLarge={true}
					isEmpty={noData}
					showLoading={isLoading}
				/>
			)}

			<br />
			<Button
				sx={{ width: "100%" }}
				key={uuid()}
				variant="contained"
				onClick={() => {
					setEnableModify(false);
					setOpenDrawer((openDrawer) => !openDrawer);
				}}
			>
				{mode === 1
					? t("buttons.add_new_reservable")
					: t("buttons.add_additional_reservable")}
			</Button>

			<MuiDrawer
				open={openDrawer}
				handleDrawerClose={closeDrawer}
				contentType="locationServices"
				content={mode}
				data={serviceData}
				info={info}
				services={allServices}
				modifyService={enableModify}
				passFieldRefresh={fieldRefresh}
			/>
		</List>
	);
};
