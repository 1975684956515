import React, { useState } from "react";
import { ReactComponent as ChargingStationIcon } from "../../Assets/Icons/ChargerIcon.svg";

import { isMobile } from "react-device-detect";
import { Typography } from "@mui/material";
import { ColorScheme } from "../../Themes/ColorScheme";
import { MuiDrawer } from "../Common/MuiDrawer";
import { ChargerIcon } from "./Components/ChargerIcon";
import { WalletIcon } from "./Components/WalletIcon";
import { PowerOutput } from "./Components/PowerOutput";
import { ChargerLogs } from "./Components/ChargerLogs";
import LandingAnimation from "../../Helpers/LandingAnimation";

export const ChargerCard2 = ({ data, fetchChargers }) => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const [drawerPath, setDrawerPath] = useState("");

	const [hover, setHover] = useState(false);

	const cardStyle = {
		minWidth: isMobile ? undefined : "300px",
		width: isMobile ? undefined : "200px",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#ffffff",
		borderRadius: "4px",
		alignItems: "center",
		justifyContent: "space-between",
		cursor: "pointer",
		padding: "20px",
		color: "black",
		overflow: "hidden",
		transition: "color 0.3s ease, transform 0.3s ease"
	};

	// if (hover) cardStyle.backgroundColor = "#F9F9F9";

	if (hover) cardStyle.transform = "scale(1.01)";

	const chargerName = String(data.id).padStart(4, "0");
	const maxPower = data?.max_power ?? "--";
	const basePrice = data?.base_vat_price ?? "--";

	const svgIcon = data?.type.icon;

	const strokeColorStation = "white";
	const strokeWidthStation = 1.5;
	const heightStation = 26;
	const widthStation = 26;

	const strokeColor = "black";
	// const fillColor = "black";
	const strokeWidth = 3;
	// const chargerIconMultiplier = 3.5;
	const height = 30;
	const width = 30;

	const getStatusColor = (status) => {
		switch (status) {
			case "Available":
				return ColorScheme.darkGreen;

			case "Preparing":
				return ColorScheme.yellow;

			default:
				return ColorScheme.disabled;
		}
	};

	const handleClick = (e) => {
		setDrawerPath(e);
		setOpenDrawer(true);
	};

	const handleDrawerClose = () => {
		setOpenDrawer(!openDrawer);
		fetchChargers();
	};

	return (
		<LandingAnimation>
			<div>
				<MuiDrawer
					open={openDrawer}
					handleDrawerClose={handleDrawerClose}
					content={data}
					contentType={drawerPath}
					passRefresh={fetchChargers}
				/>
				<div
					onClick={(event) => {
						event.stopPropagation();
						handleClick("AddCharger");
					}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					direction="row"
					style={cardStyle}
				>
					<div
						style={{
							flexDirection: "row",
							display: "flex",
							justifyContent: "space-between",
							width: isMobile ? "80dvw" : "100%"
						}}
					>
						<div
							style={{
								width: "100%",
								height: "100%",
								flexDirection: "row",
								display: "flex",
								alignItems: "center"
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									width: "100%",
									// marginLeft: "10px",
									// marginTop: isMobile && "20px",
									// marginBottom: "20px",
									alignItems: "center",
									justifyContent: "space-between"
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center"
									}}
								>
									<div
										style={{
											width: "40px",
											height: "40px",
											borderRadius: "4px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: getStatusColor(
												data.connector_status
											),
											border:
												"4px solid" +
												getStatusColor(
													data.connector_status
												)
											/* boxShadow:
												"0px 2px 6px rgba(0, 0, 0, 0.1)" */
										}}
									>
										<ChargingStationIcon
											stroke={strokeColorStation} // change by status
											width={widthStation}
											heigth={heightStation}
											strokeWidth={strokeWidthStation}
										/>
									</div>
									<div
										style={{
											flexDirection: "column",
											display: "flex",
											marginLeft: "15px",
											flex: 1
										}}
									>
										<Typography
											style={{
												fontSize: "24px",
												fontWeight: "700"
											}}
										>
											{chargerName}
										</Typography>
										<Typography
											style={{
												fontSize: "16px",
												fontWeight: "700",
												color: ColorScheme.brightGrey,
												marginTop: "-10px",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												overflow: "hidden",
												flex: 1
											}}
										>
											{
												data?.charge_point
													?.charge_point_name
											}
										</Typography>
									</div>
								</div>
								<div>
									<Typography fontSize="14px" color="grey">
										{data.connector_status}
									</Typography>
								</div>
							</div>
						</div>
					</div>

					<div
						style={{
							height: "20px"
						}}
					/>

					{/* <div
					style={{
						height: "1px",
						width: "95%",
						background:
							"linear-gradient(90deg, hsl(0, 4%, 80%), hsl(0, 0%, 30%), hsl(0, 4%, 80%))",
						opacity: "0.1"
					}}
				/> */}

					<div
						style={{
							// border: "1px solid rgba(0, 0, 0, 0.1)",
							backgroundColor: "rgba(0, 0, 0, 0.02)",
							// borderTop: "1px solid rgba(0, 0, 0, 0.05)",
							borderRadius: "4px",
							padding: "20px",
							// gap: "20px",
							alignItems: "baseline",
							// marginBottom: isMobile && "20px",
							width: isMobile ? "70dvw" : "85%",
							// marginTop: "10px",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
							// marginBottom: "5px"
						}}
					>
						<ChargerIcon
							data={data}
							svgIcon={svgIcon}
							strokeColor={strokeColor}
							strokeWidth={strokeWidth}
							iconWidth={width}
							iconHeight={height}
						/>

						<WalletIcon
							basePrice={basePrice}
							iconWidth={width}
							iconHeigth={height}
							strokeColor={strokeColor}
							strokeWidth={strokeWidth}
						/>

						<PowerOutput
							maxPower={maxPower}
							width={width}
							heigth={height}
							strokeColor={strokeColor}
							strokeWidth={strokeWidth}
						/>

						<ChargerLogs
							handleClick={handleClick}
							strokeColor={strokeColor}
							strokeWidth={strokeWidth}
							width={width}
							height={height}
						/>
					</div>
				</div>
			</div>
		</LandingAnimation>
	);
};
