import React, { useContext, useEffect, useState } from "react";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Collapse,
	Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { read, utils } from "xlsx";
import Papa from "papaparse";
import { ApiContext } from "../../../ApiContext";
import { SnackContext } from "../../../SnackContext";
import { ColorScheme } from "../../../../Themes/ColorScheme";
/* import { ReactComponent as MassUplodIcon } from "../../../../Assets/Icons/GroupIcon.svg"; */
// import { ReactComponent as ArrowIcon } from "../../../../Assets/Icons/arrowDown.svg";
import "./Styles/MassAddForm.css";
import { UserContext } from "../../../UserContext";
import { CSSTransition, SwitchTransition } from "react-transition-group";

/* const allowedExtensions = ["csv", "xls", "xlsx"]; */

export const MassAddForm = ({ updateUserList }) => {
	const { ApiCall } = useContext(ApiContext);
	const { showSnackMessage } = useContext(SnackContext);
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	/* const fileInput = useRef(null); */

	const [file, setFile] = useState(null);
	/* const [open, setOpen] = useState(false); */
	const [parsedData, setParsedData] = useState([]);
	const [showDetails, setShowDetails] = useState(false);

	// const [showUnknowns, setShowUnknowns] = useState(false);
	const [foundEmails, setFoundEmails] = useState([]);

	const [loading, setLoading] = useState(false);
	const [listErrors, setListErrors] = useState(false);
	const [usersAdded, setUsersAdded] = useState(false);

	const handleFileInputClick = (e) => {
		// console.log("clicked", fileInput, e);
		/* const file = fileInput.current.files[0]; */
		setFile(e.target.files[0]);

		/* setFile(prevFile => !prevFile); */
	};

	useEffect(() => {
		if (file) handleSheetImport();
	}, [file]);

	const sendEmails = () => {
		setLoading(true);

		// Send a POST request with the formatted rows as the request body
		ApiCall(
			"POST",
			`client/location/${selectedLocation.location_id}/users`,
			foundEmails
		)
			.then((res) => {
				// Show a snack message and refresh the data if the request is successful
				showSnackMessage("location_users", 201);

				if (res.invalid.length > 0) setListErrors(res.invalid);
				else setListErrors([]);

				setUsersAdded(true);
				setFile(null);

				updateUserList();

				console.log(listErrors);
			})
			.catch((error) => {
				// Show a snack message with the response status if the request fails
				if (error.response) {
					showSnackMessage("location_users", error.response.status);
				}
			})
			.finally(() => setLoading(false));
	};

	const clearForm = () => {
		// Clears all data
		setFile(null);
		setParsedData([]);
		setShowDetails(false);
		setFoundEmails([]);
		setLoading(false);
		setListErrors(false);
		setUsersAdded(false);
	};

	const handleSheetImport = async () => {
		// Create a new FileReader object
		const reader = new FileReader();

		// Set the onload event handler of the FileReader
		reader.onload = (e) => {
			// Get the loaded data
			const data = e.target.result;

			// Parse the loaded data as a workbook
			const workbook = read(data, { type: "binary" });

			// Get the first sheet from the workbook
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];

			// Convert the first sheet to CSV format
			const csv = utils.sheet_to_csv(worksheet);

			// Parse the CSV data into a JavaScript object
			const results = Papa.parse(csv, { header: true });
			const rows = results.data;

			// Initialize rowNumber
			let rowNumber = 1;

			// Map over each row of the parsed data and extract the email address
			const formattedRows = rows.map((row) => {
				// The delimiters are a space, a comma, and a semicolon
				const emailRegex =
					/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/;
				let email = null;

				// Iterate over each property in the row
				for (const property in row) {
					// Check if the row has the property
					if (Object.prototype.hasOwnProperty.call(row, property)) {
						// Try to find a match for the regular expression in the property value
						const match = row[property].match(emailRegex);
						// If a match is found, set email to the matched string and break the loop
						if (match) {
							email = match[0];
							break;
						}
					}
				}

				// Increment rowNumber
				rowNumber++;

				// Return an object with the found email
				return { email, rowNumber };
			});

			// Filter out rows where email is null
			/*  .filter(row => row.email !== null); */

			setParsedData(formattedRows);

			const emails = formattedRows
				.filter((row) => row.email && row.email.trim() !== "")
				.map((row) => row.email);

			setFoundEmails(emails);
		};

		// Start the process of reading the file
		reader.readAsBinaryString(file);
	};

	useEffect(() => {
		if (parsedData.length > 0) {
			setShowDetails(true);
		}
	}, [parsedData]);

	const uploadField = () => (
		<>
			<label
				htmlFor="csvInput"
				style={{ display: "block", width: "100%" }}
			>
				<Box
					sx={{
						width: "100%",
						height: "50px",
						backgroundColor: ColorScheme.tolotechBlue,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "4px",
						marginTop: "20px",
						cursor: "pointer",
						color: "white"
					}}
				>
					<Typography sx={{ fontSize: "15px" }}>
						{t("key.upload_csv")}
					</Typography>
				</Box>
			</label>
			<input
				onChange={handleFileInputClick}
				id="csvInput"
				name="file"
				type="file"
				style={{
					display: "none"
				}}
			/>
		</>
	);

	const importedFile = () => (
		<Collapse in={showDetails} hidden={!showDetails} sx={{ width: "100%" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "20px",
					paddingTop: "20px"
				}}
			>
				<div>
					<Typography fontWeight={"700"} fontSize={"14px"}>
						{/* {t("location.add_from_list_file_name")} */}
						{file.name}
					</Typography>
					{/* <Typography>
						{t("location.add_from_list_file_size")}
						{Math.round(file.size / 1024)}{" "}
						{t("location.add_from_list_file_size_unit")}
					</Typography> */}
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px"
					}}
				>
					{/* {parsedData.length > 0 && (
						<Alert severity="info">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									gap: "20px"
								}}
							>
								<Typography minWidth="150px">
									{t(
										"location.add_from_list_file_rows_recognized"
									)}
								</Typography>

								<Typography>{parsedData.length}</Typography>
							</div>
						</Alert>
					)} */}

					{parsedData.filter((row) => row.email === null).length >
						0 && (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "20px"
							}}
						>
							<Alert severity="info">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "5px"
									}}
								>
									<Typography>
										{t(
											"location.add_from_list_file_amount_to_be_added"
										)}
									</Typography>

									<Typography>
										{parsedData.length -
											parsedData.filter(
												(row) => row.email === null
											).length}
									</Typography>
								</div>
							</Alert>

							<Alert severity="warning">
								<Typography>
									{t("location.add_from_list_unrecognized")}
									{
										parsedData.filter(
											(row) => row.email === null
										).length
									}
								</Typography>
								{/* <div
									onClick={() =>
										setShowUnknowns(!showUnknowns)
									}
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "10px",
										alignItems: "center",
										paddingTop: "20px"
									}}
								>
									{t(
										"location.add_from_list_file_rows_not_recognized_show"
									)}
									<ArrowIcon
										stroke="black"
										style={{
											transform: showUnknowns
												? "rotate(180deg)"
												: "rotate(0deg)",
											transition:
												"transform 0.3s ease-in-out"
										}}
									/>
									
								</div>
								*/}
								{/* 
								<Collapse in={showUnknowns}>
									<div
										style={{
											borderRadius: "4px",
											padding: "10px"
										}}
									>
										{parsedData
											.filter((row) => row.email === null)
											.map((row, index) => (
												<Typography key={index}>
													{t(
														"location.add_from_list_file_rows_not_recognized_row"
													)}{" "}
													{row.rowNumber}
												</Typography>
											))}
									</div>
								</Collapse> */}
							</Alert>
						</div>
					)}
				</div>

				{/* <Collapse in={listErrors.length > 0}>
					<Alert severity="error">
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "5px"
							}}
						>
							<Typography>
								{t("location.add_from_list_invalid_emails")}
							</Typography>
							{listErrors &&
								listErrors.map((user, index) => (
									<Typography key={index}>{user}</Typography>
								))}
						</div>
					</Alert>
				</Collapse> */}

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "20px"
					}}
				>
					{
						<Button
							variant="outlined"
							onClick={() => clearForm()}
							sx={{ width: "100%" }}
						>
							{t("buttons.cancel")}
						</Button>
					}
					<Button
						variant="contained"
						onClick={() => sendEmails()}
						sx={{ width: "100%" }}
						disabled={loading}
					>
						{loading ? (
							<CircularProgress />
						) : usersAdded ? (
							t("buttons.save")
						) : (
							t("buttons.save")
						)}
					</Button>
				</div>
			</div>
		</Collapse>
	);

	return (
		<SwitchTransition>
			<CSSTransition
				key={file ? "importedFile" : "uploadField"}
				addEndListener={(node, done) =>
					node.addEventListener("transitionend", done, false)
				}
				classNames="fade"
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px"
					}}
				>
					{!file && uploadField()}
					{file && importedFile()}
				</div>
			</CSSTransition>
		</SwitchTransition>
	);
};
