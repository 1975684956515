import React, { useContext, useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UserContext } from "../UserContext";
import { ColorScheme } from "../../Themes/ColorScheme";
import { CourseCard } from "./CourseCard";
import { MuiDrawer } from "../Common/MuiDrawer";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { BackdropLoading } from "../Common/BackdropLoading";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import LandingAnimation from "../../Helpers/LandingAnimation";

export const ListCourses = () => {
	const debug = false;
	const { handleTitle, selectedLocation } = useContext(UserContext);

	const [courses, setCourses] = useState([]);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [openDrawer, setOpenDrawer] = useState(false);
	const [loading, setLoading] = useState(true);
	const [noCourses, setNoCourses] = useState(false);

	const hasPermission = permissionChecker({
		selectedLocation,
		permission: 2
	});

	if (!hasPermission) navigate("/locations");

	const handleClick = () => {
		setOpenDrawer(true);
	};

	const handleDrawerClose = () => {
		fetchCourses();
		setOpenDrawer(false);
	};

	const fetchCourses = () => {
		setLoading(true);
		ApiCall("GET", "location/courses/" + selectedLocation.location_id)
			.then((res) => {
				setCourses(res);
				if (res.length === 0) setNoCourses(true);
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		handleTitle(t("courses.title"));

		setOpenDrawer(false);
		setLoading(true);
		setNoCourses(false);
		if (
			Object.prototype.hasOwnProperty.call(
				selectedLocation,
				"location_id"
			)
		)
			fetchCourses();
	}, [selectedLocation]);

	useEffect(() => {
		if (noCourses || courses.length > 0) setLoading(false);
	}, [noCourses, courses]);

	return (
		<Box>
			<Stack>
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: isMobile ? "center" : "end"
						// width: "100%"
					}}
				>
					<LandingAnimation>
						<Button
							variant="contained"
							onClick={() => handleClick()}
							sx={{
								height: "46px",
								width: isMobile ? "100%" : "200px",
								/* py: "30px",
								px: "35px", */
								textTransform: "none",
								backgroundColor: ColorScheme.tolotechBlue,
								"&:hover": {
									backgroundColor:
										ColorScheme.tolotechDarkBlue
								}
							}}
						>
							<Typography style={{ fontSize: "12px" }}>
								{t("buttons.add_course")}
							</Typography>
						</Button>
					</LandingAnimation>
				</div>

				<div
					style={{
						display: !loading && courses.length === 0 && "none",
						height: isMobile ? "15px" : "35px"
					}}
				/>
				{loading && <BackdropLoading />}
				<div
					style={{
						flexDirection: "row",
						display: "flex",
						flexWrap: "wrap",
						flex: 1,
						// paddingLeft: "20px",
						gap: "20px",
						width: "100%"
					}}
				>
					{courses.length === 0 && !loading && (
						<Typography
							sx={{
								fontWeight: "700",
								fontSize: "14px",
								color: "rgba(255,255,255,0.5)",
								paddingLeft: "5px"
							}}
						>
							{t("courses.courses_not_found")}
						</Typography>
					)}

					{courses.map((course) => (
						<CourseCard
							key={course.id}
							data={course}
							updateCardView={fetchCourses}
						/>
					))}
				</div>
			</Stack>

			<MuiDrawer
				open={openDrawer}
				handleDrawerClose={handleDrawerClose}
				location={selectedLocation.location_id}
				contentType={"AddCourse"}
				handleCourseUpdate={fetchCourses}
			/>
		</Box>
	);
};
