import React from "react";
import { Backdrop } from "@mui/material";
import Lottie from "lottie-react";

export const BackdropLoading = () => (
	<Backdrop
		sx={{
			backgroundColor: "rgba(0,0,0,0)",
			zIndex: (theme) => theme.zIndex.drawer + 1
		}}
		open={true}
	>
		<Lottie
			style={{ height: "100px", width: "100px" }}
			animationData={require("../../Assets/Other/loading.json")}
		/>
	</Backdrop>
);
