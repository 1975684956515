import { Box, Typography } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../Themes/ColorScheme";
import { ApiContext } from "../ApiContext";

import { Spinner } from "../Common/Spinner";
// import { isMobile } from "react-device-detect";
// import { LogsMobile } from "./LogsMobile";
import { LogsDesktop } from "./LogsDesktop";

export const ChargerLogs = ({ data }) => {
	const debug = false;

	const [firstLoad, setFirstLoad] = useState(true);
	const [logDetails, setLogDetails] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadMore, setLoadMore] = useState(false);
	const [page, setPage] = useState(1);

	const noMore = logDetails.length === (page - 1) * 15;

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	function LoadNextPage() {
		setLoadMore(true);

		ApiCall("GET", `client/charger/transactions/${data.id}?page=${page}`)
			.then((logs) => {
				if (debug) console.log(logs);
				if (firstLoad && logs.length === 0) setLogDetails(logs);
				if (logs.length > 0) {
					setLogDetails([...logDetails, ...logs]);
					setPage(page + 1);
				}
			})
			.catch((error) => {
				if (debug) console.log(error);
			})
			.finally(() => {
				setLoading(false);
				setLoadMore(false);
				setFirstLoad(false);
			});
	}

	useEffect(() => {
		setLoading(true);
		setFirstLoad(true);

		LoadNextPage();
	}, []);

	return (
		<>
			{/* <DrawerTitle
				title={t("charging.charger_logs_label")}
				location={selectedLocation.name}
			/> */}
			<Typography
				style={{
					fontSize: "28px",
					fontWeight: "bold",
					lineHeight: "40px",
					color: ColorScheme.tolotechBlue
				}}
			>
				{String(data.id).padStart(4, "0")}
			</Typography>
			{loading && <Spinner />}
			{!loading && logDetails.length === 0 && (
				<Typography
					sx={{
						fontSize: "20px",
						fontWeight: "normal",
						color: "rgba(0,0,0,0.5)",
						marginTop: "20px"
					}}
				>
					{t("logs.not_found")}
				</Typography>
			)}
			{!loading && logDetails.length > 0 && (
				<Box sx={{ marginTop: "40px", paddingBottom: "20px" }}>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							boxSizing: "border-box",
							borderRadius: "4px",
							width: "100%",
							margin: "auto",
							border: "1px solid #F0F0F0",
							backgroundColor: "#FAFAFA"
						}}
					>
						{logDetails.map((entry, index) => (
							<React.Fragment key={index}>
								<LogsDesktop data={entry} />
								{index !== logDetails.length - 1 && (
									<div
										style={{
											height: "1px",
											width: "100%",
											backgroundColor: "#F0F0F0"
										}}
									/>
								)}
							</React.Fragment>
						))}
					</Box>
					<Box
						onClick={() => {
							if (noMore) LoadNextPage();
						}}
						sx={{
							width: "100%",
							height: 44,
							backgroundColor: noMore
								? ColorScheme.tolotechBlue
								: "transparent",
							borderRadius: "4px",
							marginTop: "20px",
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							"&:hover": {
								backgroundColor:
									noMore && ColorScheme.tolotechDarkBlue
							}
						}}
					>
						{loadMore ? (
							<Spinner />
						) : (
							<Typography
								sx={{
									fontSize: "16px",
									color: noMore ? "white" : "black"
								}}
							>
								{noMore
									? t("buttons.load_more")
									: t("charging.no_more_charger_logs")}
							</Typography>
						)}
					</Box>
				</Box>
			)}
		</>
	);
};
