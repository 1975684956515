import { Box, Typography } from "@mui/material";
import React from "react";
import { ColorScheme } from "../../../Themes/ColorScheme";

export const Header = (props) => {
	// Had to disable calendar animation for header text slide because new service selector component outside the calendar broke it and it needs complete rework

	/* 	const [ref, inView] = useInView({
		triggerOnce: false,
		threshold: 1,
		rootMargin: "0px 0px"
	}); */

	const isToday = (date) => {
		const today = new Date();
		const someDate = new Date(date);
		return (
			someDate.getDate() === today.getDate() &&
			someDate.getMonth() === today.getMonth() &&
			someDate.getFullYear() === today.getFullYear()
		);
	};

	const highlightToday = !isToday(props.date)
		? ColorScheme.tolotechDarkBlue
		: "white";

	return (
		<>
			{/* <Box sx={{ height: "135px" }} /> */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-end",
					backgroundColor:
						/* !inView
						? "transparent"
						:  */ isToday(props.date) && ColorScheme.tolotechDarkBlue,
					padding: "10px",
					paddingTop: "0px", // remove top padding to reduce extra padding stacking
					borderRadius: "8px",
					marginBottom: "40px",
					minWidth: "55px", // prevents element resizing when text content lenght changes
					// position: !inView && "fixed",
					// top: "100px",
					// marginLeft: !inView && "-20px",
					zIndex: 0
					// transform: !inView && "translate(0vh, 2vh)",
					// transition: !inView && "transform .5s ease-out 0.5s",
					// transformOrigin: "bottom center"
				}}
			>
				<Typography
					style={{
						color: /* !inView ? "lightgray" : */ highlightToday,
						fontSize: "14px",
						fontWeight: "200"
					}}
				>
					{new Date(props.date).getDate() +
						"." +
						(new Date(props.date).getMonth() + 1)}
				</Typography>

				<Typography
					style={{
						color: /* !inView ? "lightgray" : */ highlightToday,
						fontSize: "36px",
						fontWeight: "700"
					}}
				>
					{props.label.split(" ")[1].slice(0, 1).toUpperCase() +
						props.label.split(" ")[1].slice(1, 2)}
				</Typography>
			</Box>
			{/* <div ref={ref} /> */}
		</>
	);
};
