import { Box, Dialog, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ApiContext } from "../../ApiContext";
import { Spinner } from "../../Common/Spinner";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { isDesktop } from "react-device-detect";

import { ReactComponent as CalendarIcon } from "../../../Assets/Icons/CalendarIcon_custom_stroke.svg";
import { ReactComponent as ArrowIcon } from "../../../Assets/Icons/arrowSide.svg";
import { ReactComponent as DescriptionIcon } from "../../../Assets/Icons/CustomizeIcon.svg";
import { TextfieldPicker } from "../../Common/TextfieldPicker";
import { TextFieldInput2 } from "../../Common/TextFieldInput2";
import { AlertBar } from "../../Common/AlertBar";
import { useTranslation } from "react-i18next";

export const ModifyDialog = ({
	user,
	handleRefresh,
	dialogOpen,
	closeDialog,
	reservable
}) => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);

	const [loading, setLoading] = useState(false);

	const [description, setDescription] = useState("");

	useEffect(() => {
		setStartTime(new Date(user.from));
		setEndTime(
			new Date(user.end).getFullYear() === 2038
				? null
				: new Date(user.end)
		);
		setDescription(user.description);
	}, [user]);

	const handleSave = () => {
		setLoading(true);
		ApiCall("PUT", "client/residents", [
			{
				user_id: user.user_id,
				reservable_id: reservable.id,
				...(startTime !== null && { from: startTime }),
				...(endTime !== null && { to: endTime }),
				description
			}
		])
			.then(() => {
				closeDialog(false);
				handleRefresh();
				/* showSnackMessage("general", 200); */
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	};

	// console.log("start", startTime, "end", endTime);

	return (
		<div style={{ position: "relative" }}>
			<Dialog open={dialogOpen} onClose={() => closeDialog(false)}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: "20px",
						minWidth: "300px",
						boxSizing: "border-box",
						gap: "20px"
					}}
				>
					{user.user_name !== "" && (
						<Typography
							sx={{
								fontSize: "20px",
								color: "black",
								fontWeight: "700"
							}}
						>
							{user.user_name}
						</Typography>
					)}

					{user.email !== "" && (
						<Typography sx={{ fontSize: "16px", color: "grey" }}>
							{user.email}
						</Typography>
					)}

					{user.user_name === "" &&
						user.email === "" &&
						user.phone !== "" && (
							<Typography
								sx={{ fontSize: "16px", color: "grey" }}
							>
								{user.phone}
							</Typography>
						)}

					{/* <TextField
							variant="filled"
							label={t("textfield_labels.description")}
							placeholder={t(
								"textfield_labels.input_description"
							)}
							sx={{
								border: "none",
								width: "100%",
								flex: 1
							}}
							InputProps={{
								disableUnderline: true,
								style: {
									color: "black",
									backgroundColor: "transparent",
									borderRadius: "20px",
									border: "0px solid rgba(0,0,0,0)",
									fontSize: "15px"
								}
							}}
							InputLabelProps={{
								style: {
									fontSize: "14px",
									top: "3px"
								}
							}}
							value={description}
							onChange={(event) => {
								let inputValue = event.target.value;
								setDescription(inputValue);
							}}
						/> */}
					<TextFieldInput2
						text={description}
						title={t("textfield_labels.description")}
						hasProps={
							<DescriptionIcon
								stroke={ColorScheme.defaultIconColor}
							/>
						}
						valueChange={(event) => {
							let inputValue = event;
							setDescription(inputValue);
						}}
					/>

					<div
						style={{
							display: "flex",
							alignItems: "center",
							/* marginBottom: "20px",
							marginTop: "20px", */
							gap: "20px",
							flexDirection: isDesktop ? "row" : "column"
						}}
					>
						<TextfieldPicker
							value={startTime}
							noTime={false}
							label={t("textfield_labels.starts")}
							fullDate={true}
							excludePast={false}
							valueChange={(value) => {
								const date = new Date(value).toISOString();

								setStartTime(date);
							}}
							hasProps={
								<CalendarIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							}
						/>
						{/* <DatePicker
							open={openStartTime}
							onClose={() => setOpenStartTime(false)}
							value={startTime}
							onChange={(newValue) => {
								console.log(
									new Date(newValue),
									newValue,
									moment(newValue).utc(true)
								);

								setStartTime(new Date(newValue));
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									onClick={() => setOpenStartTime(true)}
									onTouchEnd={() => setOpenStartTime(true)}
									fullWidth
									sx={{
										".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
											{
												border: "1px solid #D9D9D9"
											}
									}}
									inputProps={{
										...params.inputProps,
										style: {
											textAlign: "center",
											cursor: "pointer"
										},
										placeholder:
											t("textfield_labels.starts") + "..."
									}}
									InputProps={{
										disableUnderline: true,
										style: {
											color: "black",
											backgroundColor: "#F5F5F5",
											borderRadius: "4px"
										}
									}}
								/>
							)}
						/> */}
						{isDesktop && <div style={{ width: "10px" }} />}
						{isDesktop && (
							<div
								style={{
									display: "inline-block",
									transform: "rotate(180deg)"
								}}
							>
								<ArrowIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							</div>
						)}
						{isDesktop && <div style={{ width: "10px" }} />}

						<TextfieldPicker
							value={endTime || ""}
							noEndTime={true}
							noTime={false}
							label={t("textfield_labels.ends")}
							fullDate={true}
							excludePast={false}
							valueChange={(value) => {
								const date = new Date(value).toISOString();

								setEndTime(date);
							}}
							hasProps={
								<CalendarIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							}
						/>
						{/* <DatePicker
							open={openEndTime}
							onClose={() => setOpenEndTime(false)}
							value={endTime}
							onChange={(newValue) => {
								setEndTime(new Date(newValue));
							}}
							minTime={startTime}
							renderInput={(params) => (
								<TextField
									{...params}
									onClick={() => setOpenEndTime(true)}
									onTouchEnd={() => setOpenEndTime(true)}
									fullWidth
									sx={{
										".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
											{
												border: "1px solid #D9D9D9"
											}
									}}
									inputProps={{
										...params.inputProps,
										style: {
											textAlign: "center",
											cursor: "pointer"
										},
										placeholder:
											t("textfield_labels.ends") + "..."
									}}
									InputProps={{
										disableUnderline: true,
										style: {
											color: "black",
											backgroundColor: "#F5F5F5",
											borderRadius: "4px"
										}
									}}
								/>
							)}
						/> */}
					</div>

					<AlertBar
						showAlert
						textContent={t(
							"key_management.key_management_end_time"
						)}
						type="info"
					/>

					<Box
						sx={{
							flexDirection: "row",
							display: "flex",
							width: "100%",
							justifyContent: "space-between"
						}}
					>
						<Box
							onClick={() => closeDialog(false)}
							sx={{
								height: "40px",
								flex: 1,
								border: "1px solid " + ColorScheme.tolotechBlue,
								cursor: "pointer",
								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Typography
								sx={{ fontSize: "14px", color: "black" }}
							>
								{t("buttons.cancel")}
							</Typography>
						</Box>
						<div style={{ width: "20px" }}></div>
						<Box
							onClick={() => !loading && handleSave()}
							sx={{
								height: "40px",
								flex: 1,
								border: "1px solid " + ColorScheme.tolotechBlue,
								backgroundColor: ColorScheme.tolotechBlue,
								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								cursor: "pointer"
							}}
						>
							{!loading && (
								<Typography
									sx={{ fontSize: "14px", color: "white" }}
								>
									{t("buttons.save")}
								</Typography>
							)}
							{loading && (
								<Spinner
									small
									color={ColorScheme.tolotechWhite}
								/>
							)}
						</Box>
					</Box>
				</Box>
			</Dialog>
		</div>
	);
};
