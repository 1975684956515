import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../Themes/ColorScheme";
import { ApiContext } from "../ApiContext";

export const ListMessages = () => {
	const debug = false;

	const [notifications, setNotifications] = useState([]);
	const [selectedPage, setSelectedPage] = useState(1);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	function parseMessage(message) {
		// Split the message into words
		const words = message.split(" ");

		// Map through the words, turning any word that matches the URL pattern into a link
		return words
			.map((word, index) => {
				const urlPattern =
					/(https?:\/\/[^\s]+)|(www\.[^\s]+)|((?:[a-z]+\.)?[a-z0-9]+\.[a-z]+(?:\.[a-z]+)?)|(https?:\/\/[^\s]+:\d+)|(https?:\/\/[^\s]+\/[^\s]*)|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
				const match = urlPattern.exec(word);
				if (match) {
					return (
						<a
							key={index}
							href={
								match[0].startsWith("http")
									? match[0]
									: `http://${match[0]}`
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							{match[0]}
						</a>
					);
				} else {
					return word;
				}
			})
			.reduce(
				(prev, current, index) =>
					// Add a space after each word, except the last one
					index < words.length - 1
						? [...prev, current, " "]
						: [...prev, current],
				[]
			);
	}

	function loadMore() {
		let notifications_temp = notifications;

		ApiCall("GET", "user/notifications?page=" + selectedPage, null)
			.then((res) => {
				res.map((notif) => {
					const check = notifications_temp.find(
						(value) =>
							value.notification_id === notif.notification_id
					);

					if (!check) return notifications_temp.push(notif);
				});
				setNotifications(notifications_temp);
				if (res.length !== 0) setSelectedPage(selectedPage + 1);
			})
			.catch((err) => {
				if (debug) console.log(err);
			});
	}

	useEffect(() => {
		loadMore();
	}, []);

	function getCreatedDateDaysAgo(date) {
		const oneDay = 24 * 60 * 60;

		const now = new Date().getTime();
		const notif_date = new Date(date).getTime();

		const diffSeconds = Math.floor((now - notif_date) / 1000);

		const diffMinutes = Math.round(diffSeconds / 60);
		const diffHours = Math.round(diffSeconds / 3600);

		const diffDays = Math.round(diffSeconds / oneDay);

		if (diffMinutes < 60) return diffMinutes + " " + t("time.minutes_ago");

		if (diffHours < 24) return diffHours + " " + t("time.hours_ago");

		if (diffDays === 0) return t("time.today");
		else if (diffDays === 1) return t("time.yesterday");
		else return diffDays + " " + t("time.days_ago");
	}

	return (
		<Box
			sx={{
				width: "100%"
			}}
		>
			<Box
				sx={{
					width: "inherit",
					borderRadius: "4px",
					marginBottom: "100px"
				}}
			>
				{/* <DrawerTitle
					title={t("common.messages")}
					location={null}
					isSmall={true}
				/> */}
				<div style={{ height: "20px" }} />
				{/* <Typography
					sx={{
						marginBottom: "20px",
						fontSize: "18px",
						fontWeight: "700"
					}}
				>
					{t("common.messages")}
				</Typography> */}

				{notifications.map((notification, index) => (
					<React.Fragment key={index}>
						<Grid
							item
							xs={12}
							sx={{
								border: "1px solid " + ColorScheme.lightGrey,
								color: "#000000",
								my: "5px",
								padding: "10px",
								borderRadius: "4px",
								width: "250px", // USSR solution
								"&:hover": {
									boxShadow:
										"0px 0px 10px 0px rgba(0,0,0,0.08)"
								}
							}}
						>
							{notification.location !== null && (
								<Typography
									sx={{
										fontWeight: "700",
										fontSize: "12px"
									}}
								>
									{location.name}
								</Typography>
							)}
							<Stack
								direction="row"
								style={{
									alignItems: "center",
									justifyContent: "space-between",
									borderBottom:
										"1px solid" + ColorScheme.lightGrey
								}}
							>
								<Stack direction="row">
									<Typography
										sx={{
											fontWeight: "700",
											fontSize: "12px"
										}}
									>
										{notification.title}
									</Typography>
								</Stack>
								<Typography
									sx={{
										fontSize: "8px",
										color: "rgb(180,180,180)"
									}}
								>
									{getCreatedDateDaysAgo(
										notification.created_at
									)}
								</Typography>
							</Stack>
							<Typography
								sx={{
									padding: "5px",
									fontSize: "10px",
									color: "rgb(180,180,180)"
								}}
							>
								{parseMessage(notification.message)}
							</Typography>
						</Grid>
						<Box
							sx={{
								width: "100%",
								height: "1px",
								backgroundColor: "rgba(255,255,255,0.2)",
								alignSelf: "center"
							}}
						/>
					</React.Fragment>
				))}
				<Stack
					style={{
						width: "100%",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Button
						onClick={() => loadMore()}
						style={{
							backgroundColor: ColorScheme.tolotechBlue,
							marginTop: "20px",
							width: "100%"
							// marginBottom: "20px"
						}}
					>
						<Typography
							color={ColorScheme.tolotechWhite}
							textTransform={"capitalize"}
						>
							{t("buttons.load_more")}
							{/* Todo piiloita, jos notifications.length+1 on sama kuin renderöityjen viestien määrä. */}
						</Typography>
					</Button>
				</Stack>
			</Box>
		</Box>
	);
};
