import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UserContext } from "../UserContext";

import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { ChargerCard2 } from "./ChargerCard2";
import { BackdropLoading } from "../Common/BackdropLoading";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useNavigate } from "react-router-dom";

export const ChargingContent = () => {
	const debug = false;
	const { handleTitle, selectedLocation } = useContext(UserContext);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [chargers, setChargers] = useState([]);
	const [noChargers, setNoChargers] = useState(false);
	const [loading, setLoading] = useState(true);

	const hasPermission = permissionChecker({
		selectedLocation,
		permission: 7
	});

	if (!hasPermission) navigate("/locations");

	const fetchChargers = () => {
		setLoading(true);
		if (
			(selectedLocation.location_id !== null &&
				selectedLocation.location_id) !== undefined
		)
			ApiCall(
				"GET",
				"client/location/chargers/" + selectedLocation.location_id, // todo switch to proper route
				null
			)
				.then((res) => {
					if (debug) console.log(res);
					setChargers(res);
					if (res.length === 0) setNoChargers(true);
				})
				.catch((res) => {
					if (debug) console.log(res);
					setLoading(false);
				});
	};

	useEffect(() => {
		if (noChargers || chargers.length > 0) {
			setNoChargers(false);
			setLoading(false);
		}
	}, [noChargers, chargers]);

	useEffect(() => {
		fetchChargers();
	}, [selectedLocation]);

	useEffect(() => {
		handleTitle(t("common.charging"));
	}, []);

	return (
		<Box>
			<Stack>
				{!loading && chargers.length === 0 && (
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "700",
							color: "rgba(255,255,255,0.5)",
							textAlign: isMobile && "center",
							paddingLeft: "5px"
						}}
					>
						{t("charging.not_found")}
					</Typography>
				)}

				<div
					style={{
						width: isMobile ? undefined : "100%",
						flexDirection: isMobile ? "column" : "row",
						display: "flex",
						flexWrap: "wrap",
						flex: 1,

						// padding: isMobile ? undefined : "20px",
						gap: "15px"

						// justifyContent: isMobile ? "center" : undefined
					}}
				>
					{chargers.map((charger, index) => (
						<ChargerCard2
							fetchChargers={fetchChargers}
							key={index}
							data={charger}
						/>
						/* <ChargerCard
							fetchChargers={fetchChargers}
							key={index}
							data={charger}
						/> */
					))}
				</div>

				{loading && <BackdropLoading />}
			</Stack>
		</Box>
	);
};
