import { Avatar, Grid, Stack, Typography, Box } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";

import ImageUploadIcon from "../../../Assets/Icons/ImageUpload.svg";
import { HideLocation } from "./HideLocation";
import { isDesktop, isMobile } from "react-device-detect";
import { ApiContext } from "../../ApiContext";
import LandingAnimation from "../../../Helpers/LandingAnimation";

export const LocationCard = ({ data, isCard, getLocations }) => {
	const debug = false;
	const navigate = useNavigate();

	const { setSelectedLocation } = useContext(UserContext);

	const { ApiCall } = useContext(ApiContext);
	const [banner, setBanner] = useState(null);
	const [hidden, setHidden] = useState(false);

	/* const handleButton = (event) => {
		event.stopPropagation();
		setSelectedLocation(data);
		navigate("/calendar");
	}; */

	const fetchLocationImages = () => {
		ApiCall("GET", `location/get_images/${data.location_id}`, null)
			.then((images) => {
				const findBanner = images.find(
					(image) => image.image_type === 2
				);

				if (findBanner) setBanner(findBanner.image);
				else setBanner(data.logo_image.image);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	/* useEffect(() => {
		ApiCall("GET", `location/${data.location_id}`)
			.then((loc) => {
				setHidden(loc.is_hidden);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});

		if (banner === null)
			// todo test
			ApiCall("GET", `location/get_images/${data.location_id}?type=2`)
				.then((images) => {
					if (images.length > 0) setBanner(images[0].image);
				})
				.catch((error) => {
					if (debug) console.log(error);
				});
	}, []);*/

	const hideLocation = (value) => {
		setHidden(value);
		getLocations("client/locations");
	};

	useEffect(() => {
		setHidden(data.is_hidden);

		if (isCard) fetchLocationImages();
		else setBanner(data.logo_image.image);
	}, []);

	return (
		<LandingAnimation>
			{isCard ? (
				<Stack
					direction="row"
					onClick={() => {
						{
							setSelectedLocation(data);

							navigate(`/locations/${data.location_id}`);
						}
					}}
					sx={{
						minWidth: isMobile ? "95%" : "400px",
						maxWidth: "400px",
						height: `calc((100vh - ${
							!isMobile && banner !== null ? "100px" : "300px"
						})*0.8)`,
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#ffffff",
						borderRadius: "4px",
						alignItems: "center",
						justifyContent: "flex-end",
						marginRight: "20px",
						marginLeft: "20px",
						color: "black",
						transition: ".3s",
						overflow: "hidden",
						// border: "1px solid red",
						"&:hover": {
							scale: "1.01"
						}
					}}
				>
					<div
						style={{
							width: "400px",
							height: "50%",
							maxHeight: "50%",
							overflow: "hidden"
						}}
					>
						<Avatar
							style={{
								width: "100%",
								height: "100%",
								borderRadius: 0,
								objectFit: "cover"
							}}
							src={banner !== null ? banner : ImageUploadIcon}
						/>
					</div>
					<Grid
						sx={{
							height: "50%",
							minHeight: "35vh",
							width: isMobile ? "100dvw" : "100%",
							py: "40px",
							px: isMobile ? "40px" : "30px",
							backgroundColor: "#FFFFFF",
							display: "flex",
							flexDirection: "column",
							zIndex: 1,
							justifyContent: "space-between"
						}}
					>
						<Grid item>
							<Stack
								direction="row"
								justifyContent="space-between"
							>
								<Typography
									style={{
										fontSize: "18px",
										fontWeight: "700"
									}}
								>
									{data.name}
								</Typography>

								<HideLocation
									locationId={data.location_id}
									current={hidden}
									setHidden={setHidden}
								/>
							</Stack>

							<Typography
								style={{
									fontSize: "12px",
									fontWeight: "500",
									color: "#264166"
								}}
							>
								{data.address}
							</Typography>
							<Grid
								item
								sx={{
									flexDirection: "row",
									display: "flex",
									flexWrap: "wrap",
									marginTop: "15px"
								}}
							>
								{Object.prototype.hasOwnProperty.call(
									data,
									"location_permissions"
								) &&
									isDesktop &&
									data.location_permissions.map(
										(permission, index) => (
											<Grid
												sx={{
													width: "max-content",
													height: "max-content",

													padding: "5px",

													px: "15px",
													backgroundColor:
														permission.permission
															.color,
													marginTop: "10px",
													borderRadius: "21px",
													marginRight: "10px",
													alignItems: "center"
												}}
												key={index}
											>
												<Typography
													style={{
														fontSize: "12px",
														fontWeight: "400",
														lineHeight: "14px",
														color: "white",
														textAlign: "center"
													}}
												>
													{
														permission.permission
															.name_fi
													}
												</Typography>
											</Grid>
										)
									)}
							</Grid>
						</Grid>
						<Box sx={{ flex: 1 }}></Box>
					</Grid>
				</Stack>
			) : (
				<Stack
					onClick={() => {
						{
							setSelectedLocation(data);

							navigate(`/locations/${data.location_id}`);
						}
					}}
					sx={{
						// maxWidth: "90%",
						display: "flex",
						flexDirection: "row",
						backgroundColor: "#ffffff",
						borderRadius: "4px",
						alignItems: "center",
						justifyContent: "flex-end",
						padding: "20px",
						boxSizing: "border-box",
						color: "black",
						transition: ".3s",
						overflow: "hidden",
						// border: "1px solid red",
						"&:hover": {
							scale: "0.99" // shrink instead of scaling up making rounded borders push out of parent div
						}
					}}
				>
					<div
						style={{
							minWidth: isDesktop ? "100px" : "60px",
							maxWidth: isDesktop ? "100px" : "60px",
							maxHeight: isDesktop ? "100px" : "60px",
							minHeight: isDesktop ? "100px" : "60px",
							overflow: "hidden",
							background: "transparent",
							borderRadius: "4px"
						}}
					>
						<Avatar
							style={{
								width: "100%",
								height: "100%",
								borderRadius: 0,
								objectFit: "cover"
							}}
							src={banner !== null ? banner : ImageUploadIcon}
						/>
					</div>
					<Grid
						sx={{
							flex: 1,
							px: "30px",
							backgroundColor: "#FFFFFF",
							display: "flex",
							flexDirection: "column",
							zIndex: 1,
							justifyContent: "space-between"
						}}
					>
						<Grid>
							<Stack
								direction="row"
								justifyContent="space-between"
							>
								<Typography
									style={{
										fontSize: isMobile ? "14px" : "20px",
										fontWeight: "700"
									}}
								>
									{data.name}
								</Typography>
							</Stack>

							<Typography
								style={{
									fontSize: "12px",
									fontWeight: "400",
									color: "grey"
								}}
							>
								{data.address}
							</Typography>
							<Stack
								xs={2}
								direction="row"
								sx={{
									flexWrap: "wrap"
								}}
							>
								{Object.prototype.hasOwnProperty.call(
									data,
									"location_permissions"
								) &&
									isDesktop &&
									data.location_permissions.map(
										(permission, index) => (
											<Grid
												sx={{
													width: "max-content",
													height: "max-content",

													padding: "5px",

													px: "15px",
													backgroundColor:
														permission.permission
															.color,
													marginTop: "10px",
													borderRadius: "21px",
													marginRight: "10px",
													alignItems: "center"
												}}
												key={index}
											>
												<Typography
													style={{
														fontSize: "12px",
														fontWeight: "400",
														lineHeight: "14px",
														color: "white",
														textAlign: "center"
													}}
												>
													{
														permission.permission
															.name_fi
													}
												</Typography>
											</Grid>
										)
									)}
							</Stack>
						</Grid>
					</Grid>

					<Box sx={{ flex: isDesktop ? 1 : 0 }} />
					<HideLocation
						locationId={data.location_id}
						current={hidden}
						setHidden={hideLocation}
					/>
				</Stack>
			)}
		</LandingAnimation>
	);
};
