import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

export const Spinner = ({ small, hasText, hasColor, color }) => (
	<Stack
		alignItems="center"
		justifyContent="center"
		spacing={4}
		direction="row"
	>
		<CircularProgress
			size={small && "25px"}
			color={small ? "info" : "secondary"}
			style={{ color: color && color }}
		/>
		{hasText && (
			<Typography fontSize={"12px"} color={hasColor || "white"}>
				{hasText}
			</Typography>
		)}
	</Stack>
);
