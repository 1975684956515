import {
	Box,
	Button,
	Collapse,
	Dialog,
	TextField,
	Typography
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { ApiContext } from "../ApiContext";
import { Spinner } from "../Common/Spinner";
import { ColorScheme } from "../../Themes/ColorScheme";
import { MuiDrawer } from "../Common/MuiDrawer";

import { ReactComponent as Arrow } from "../../Assets/Icons/arrowDown.svg";
import { ReactComponent as LogIcon } from "../../Assets/Icons/Log.svg";
import { ReactComponent as SettingsIcon } from "../../Assets/Icons/SettingsIcon.svg";

import { UserContext } from "../UserContext";
import { BalanceSortingOptions } from "./BalanceSortingOptions";
import { BalanceDialog } from "./BalanceDialog";
import { parseTimestamp } from "../../Helpers/parseTimestamp";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import LandingAnimation from "../../Helpers/LandingAnimation";
import { useTranslation } from "react-i18next";

const ONE_PAGE_LIMIT = 20;

const styles = {
	button: {
		height: "40px",
		width: "40px",
		backgroundColor: "#F1F1F1",
		marginTop: "20px",
		borderRadius: "4px",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer"
	}
};

export const BalanceContent = () => {
	const { selectedLocation, handleTitle } = useContext(UserContext);

	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasPermission = permissionChecker({
		selectedLocation,
		permission: 10
	});

	if (!hasPermission) navigate("/locations");

	const { ApiCall } = useContext(ApiContext);

	const [search, setSearch] = useState("");

	const [page, setPage] = useState(1);
	const [showNextPageButton, setShowNextPageButton] = useState(false);
	const [showPreviousPageButton, setShowPreviousPageButton] = useState(false);

	const [loading, setLoading] = useState(true);
	const [content, setContent] = useState([]);
	const [boxHeight, setBoxHeight] = useState(85);

	const [readyToFetch, setReadyToFetch] = useState(false);

	const [sort, setSort] = useState("name");

	const [descending, setDescending] = useState(false);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);

	const [showBalance, setShowBalance] = useState(false);
	const [showLogs, setShowLogs] = useState(false);

	const [openMobile, setOpenMobile] = useState(false);

	const settingsWidth = "130px";
	const widthSetting = isDesktop ? "calc(100% / 4)" : "calc(100% / 3)";

	const handleNewBalance = () => {
		setOpenMobile(false); // not like this, refactor this to a better solution
		setOpenDrawer(true);
	};

	const handleDrawerClose = () => {
		setOpenDrawer(false);
		setSelectedUser(null);
		setContent([]);
		setLoading(true);
		fetchClients();
	};

	const handleDialogClose = () => {
		setDialogOpen(false);
		setShowBalance(false);
		setShowLogs(false);
		setSelectedUser(null);
		setContent([]);
		setLoading(true);
		fetchClients();
	};

	const handleCustomerBalance = (entry) => {
		setShowBalance(true);
		setShowLogs(false);
		setSelectedUser(entry);
		setDialogOpen(true);
	};

	const showCustomerLogs = (entry) => {
		setShowLogs(true);
		setShowBalance(false);
		setSelectedUser(entry);
		setDialogOpen(true);
	};

	const handleChange = (text) => {
		const onlyLowerCase = text.target.value.toLowerCase();
		setSearch(onlyLowerCase);
	};

	const handleMobileView = (entry) => {
		setOpenMobile(true);
		setOpenDrawer(true);
		setSelectedUser(entry);
	};

	const getDaysToExpiration = (date) => {
		const today = new Date();
		const expirationDate = new Date(date);
		const differenceInTime = expirationDate.getTime() - today.getTime();
		const differenceInDays = differenceInTime / (1000 * 3600 * 24);
		return Math.ceil(differenceInDays);
	};

	const parseNearestExpiration = (balances) => {
		// Parse the nearest expiration date from the balances array

		let dateArray = [];

		balances.forEach((balance) => {
			/* if (balance.location_id === selectedLocation.location_id) { */ // This is not needed, because the server already returns only the balances from the selected location
			dateArray.push(balance.expires_at);
			/* } */
		});

		dateArray.sort((a, b) => new Date(a) - new Date(b));

		return parseTimestamp(dateArray[0], 2);
	};

	const calculateBalances = (balances) =>
		// Calculate the total balance of a user, based on the balances array from the server
		balances.reduce((acc, curr) => {
			acc += curr.amount;
			return Number(acc).toFixed(2);
		}, 0);

	const fetchClients = () => {
		ApiCall(
			"GET",
			`client/balances/${selectedLocation.location_id}?sort=${sort} ${
				descending ? "DESC" : "ASC"
			}&page=${page}&search=${search}`
		)
			.then((res) => {
				/* let temp = [...content];
				const uniqueUserIds = new Set(
					temp.map((entry) => entry.user_id)
				); // Use Set to store unique user_id values

				console.log(uniqueUserIds, temp, "-->", res);
				res.forEach((entry) => {
					if (!uniqueUserIds.has(entry.user_id)) {
						// Check if user_id already exists in the Set
						temp.push(entry);
						uniqueUserIds.add(entry.user_id); // Add user_id to the Set
					} else {
						// Update existing entry with the same user_id
						const existingEntryIndex = temp.findIndex(
							(item) => item.user_id === entry.user_id
						);
						temp[existingEntryIndex] = entry;
					}
				}); */

				if (res.length >= ONE_PAGE_LIMIT) {
					setShowNextPageButton(true);
				} else {
					setShowNextPageButton(false);
				}
				setContent(res);
				setReadyToFetch(false);
				setLoading(false);
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (page == null) {
			setPage(1);
			return;
		}
		setPage(null);
	}, [search, selectedLocation, sort, descending]);

	useEffect(() => {
		if (readyToFetch) {
			fetchClients();
		}
	}, [readyToFetch]);

	useEffect(() => {
		handleTitle(t("balances.clients_label"));
	}, []);

	useEffect(() => {
		if (page == null) {
			setPage(1);
			return;
		}
		setPage(null);
	}, [search, sort]);

	useEffect(() => {
		if (page === null) {
			setPage(1);
			return;
		}
		if (page > 1) {
			setShowPreviousPageButton(true);
		} else {
			setShowPreviousPageButton(false);
		}
		setLoading(true);
		setContent([]);
		const delay = 500;
		const delayDebounceFn = setTimeout(() => {
			setReadyToFetch(true);
		}, delay);

		return () => clearTimeout(delayDebounceFn);
	}, [page]);

	useEffect(() => {
		const boxHeightT =
			(isDesktop ? 63 : 120) * content.length +
			2 +
			(loading ||
			/* showNextPageButton ||
					showPreviousPageButton || */ // removed because required height for empty page messages was too much for user cards
			content.length === 0
				? 180
				: 0);

		setBoxHeight(boxHeightT);
	}, [loading, content]);

	return (
		<LandingAnimation>
			<div
				style={{
					position: "relative",
					display: "flex",
					flexDirection: "column",
					gap: "20px"
				}}
			>
				<MuiDrawer
					open={openDrawer}
					contentType={
						openMobile ? "user_balance_mobile" : "new_balance"
					}
					data={selectedUser}
					handleDrawerClose={handleDrawerClose}
				/>
				<Dialog open={dialogOpen} onClose={handleDialogClose}>
					<BalanceDialog
						data={selectedUser}
						showLogs={showLogs}
						showBalance={showBalance}
						isMobile={openMobile}
						handleClose={handleDialogClose}
					/>
				</Dialog>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "end"
					}}
				>
					<Button variant="contained" onClick={handleNewBalance}>
						{t("balances.create_new_balance")}
					</Button>
				</div>

				<div>
					<TextField
						fullWidth
						variant="filled"
						label={t("textfield_labels.search")}
						InputLabelProps={{ style: { color: "grey" } }}
						inputProps={{
							style: {
								color: "black",
								backgroundColor: "rgb(240,240,240)",
								borderRadius: "4px 4px 0px 0px",
								border: "0px"
							}
						}}
						onChange={handleChange}
					/>

					<BalanceSortingOptions
						sort={sort}
						setSort={setSort}
						descending={descending}
						setDescending={setDescending}
					/>

					<Box
						sx={{
							height: boxHeight + "px",
							backgroundColor: "white",
							alignItems: "center",
							justifyContent: "center",
							display: "flex",
							borderBottomLeftRadius: "4px",
							borderBottomRightRadius: "4px",
							/* width: "100%", */
							transition: "all .2s ease-out",
							/* overflow: "hidden", */
							flexDirection: "column",
							/* padding: "10px", */
							boxSizing: "border-box",
							overflow: "hidden"
						}}
					>
						{content.length === 0 && !loading && (
							<div
								style={{
									height: "20px",
									padding: "40px"
								}}
							>
								<Typography sx={{ color: "black" }}>
									{search === ""
										? t("balances.no_users_yet")
										: t("balances.no_users_found")}
								</Typography>
							</div>
						)}

						{loading && (
							<div
								style={{
									height: "20px",
									padding: "40px"
								}}
							>
								<Spinner
									small
									color={ColorScheme.tolotechBlue}
								/>
							</div>
						)}

						{content.map((entry, i) => (
							<Collapse
								collapsedSize={
									loading ? 0 : isDesktop ? "0px" : "120px"
								}
								in={true}
								sx={{ width: "100%" }}
								key={entry.user_id}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										/* 	width: "100%", */
										/* height: boxHeight, */
										alignItems: "center",
										minHeight: isDesktop
											? undefined
											: "120px",
										/* cursor: "pointer", */
										boxSizing: "border-box",
										borderTop:
											i !== 0
												? "1px solid #E0E0E0"
												: "none"
									}}
									onClick={() =>
										!isDesktop
											? handleMobileView(entry)
											: undefined
									}
								>
									{isDesktop ? (
										<div
											style={{
												width: widthSetting,
												padding: "20px",
												boxSizing: "border-box"
											}}
										>
											<Typography
												sx={{
													color: "black"
												}}
											>
												{entry.name}
											</Typography>
										</div>
									) : (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												width: widthSetting,
												paddingLeft: "20px"
											}}
										>
											<Typography
												sx={{
													color: "black",
													fontSize: "16px"
												}}
											>
												{entry.name}
											</Typography>
											<Typography
												sx={{
													color: "black",
													fontSize:
														!isDesktop && "14px",
													fontWeight:
														entry.name === ""
															? "400"
															: "300"
												}}
											>
												{
													String(entry.email).split(
														"@"
													)[0]
												}
											</Typography>

											<Typography
												sx={{
													color: "black",
													fontSize:
														!isDesktop && "14px",
													fontWeight: "300"
												}}
											>
												@
												{
													String(entry.email).split(
														"@"
													)[1]
												}
											</Typography>
										</div>
									)}

									{isDesktop && (
										<div
											style={{
												width: widthSetting,
												padding: "20px",
												display: "flex",
												flexDirection: "row",
												flexWrap: "wrap",
												boxSizing: "border-box",
												overflow: "hidden"
											}}
										>
											<Typography
												sx={{
													color: "black"
												}}
											>
												{
													String(entry.email).split(
														"@"
													)[0]
												}
											</Typography>

											<Typography
												sx={{
													color: "black"
												}}
											>
												@
												{
													String(entry.email).split(
														"@"
													)[1]
												}
											</Typography>
										</div>
									)}

									<div
										style={{
											width: widthSetting,
											padding: "20px",
											boxSizing: "border-box",
											textAlign: isDesktop
												? undefined
												: "right"
										}}
									>
										<Typography
											sx={{
												color: "black",
												whiteSpace: "nowrap"
											}}
										>
											{calculateBalances(entry.balances)}{" "}
											€
										</Typography>
									</div>

									<div
										style={{
											width: widthSetting,
											padding: "20px",
											boxSizing: "border-box"
										}}
									>
										<Typography
											sx={{
												textAlign: isDesktop
													? undefined
													: "right",
												color:
													getDaysToExpiration(
														entry.balances[0]
															.expires_at
													) < 30
														? getDaysToExpiration(
																entry
																	.balances[0]
																	.expires_at
															) <= 0
															? ColorScheme.red
															: ColorScheme.yellow
														: "black" // TODO: multiple balances?
											}}
										>
											{parseNearestExpiration(
												entry.balances
											)}
										</Typography>
									</div>

									{isDesktop && (
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												width: settingsWidth,
												/* padding: "20px", */
												justifyContent: "center",
												gap: "10px"
											}}
										>
											{/* <div
            style={{
                paddingRight: "40px",
                display: "flex",
                flexDirection: "row",
                gap: "10px"
            }}
        > */}
											<LogIcon
												stroke="black"
												onClick={() =>
													showCustomerLogs(entry)
												}
												style={{ cursor: "pointer" }}
											/>
											<SettingsIcon
												stroke="black"
												onClick={() =>
													handleCustomerBalance(entry)
												}
												style={{ cursor: "pointer" }}
											/>
											{/* </div> */}
										</div>
									)}
								</div>
							</Collapse>
						))}

						{(showNextPageButton || showPreviousPageButton) &&
							!loading && (
								<div
									style={{
										minHeight: "83px",
										flexDirection: "row",
										display: "flex",
										justifyContent: "space-around"
									}}
								>
									{
										<Box
											onClick={() =>
												showPreviousPageButton &&
												setPage(page - 1)
											}
											sx={[
												styles.button,
												{
													opacity:
														showPreviousPageButton
															? 1
															: 0.5
												}
											]}
										>
											<div style={{ rotate: "90deg" }}>
												<Arrow stroke={"blue"} />
											</div>
										</Box>
									}
									<div
										style={{
											width: "100px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<Typography sx={{ color: "black" }}>
											{t("common.page") + " " + page}
										</Typography>
									</div>
									{
										<Box
											onClick={() =>
												showNextPageButton &&
												setPage(page + 1)
											}
											sx={[
												styles.button,
												{
													opacity: showNextPageButton
														? 1
														: 0.5
												}
											]}
										>
											<div style={{ rotate: "270deg" }}>
												<Arrow stroke={"blue"} />
											</div>
										</Box>
									}
								</div>
							)}
					</Box>
				</div>
			</div>
		</LandingAnimation>
	);
};
