import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { ReactComponent as RemoveSearchString } from "../../../Assets/Icons/Cross.svg";
import { ReactComponent as SearchIcon } from "../../../Assets/Icons/SeachGlassIcon.svg";
import { theme } from "../../../Themes/Theme";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const SearchBar = ({ searchString, handleSearch, handleClear }) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between"
			}}
		>
			<TextField
				size="small"
				value={searchString}
				/* label={t("textfield_labels.search")} */
				placeholder={t("textfield_labels.search")}
				sx={{
					...theme.toloStyled.default_textfield,
					input: {
						...theme.toloStyled.default_textfield.input,
						top: "0px" // override default textfield input top padding
					},
					width: "100%"
				}}
				onChange={handleSearch}
				InputProps={{
					notched: false,
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon stroke={ColorScheme.defaultIconColor} />
						</InputAdornment>
					),

					endAdornment: searchString !== "" && (
						<InputAdornment position="end">
							<IconButton onClick={handleClear}>
								<RemoveSearchString />
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
		</div>
	);
};
