import { Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";

const contacts = [
	{
		name: "Huolto",
		email: "huolto@tolotech.fi",
		number: "044-7770711"
	},
	{
		name: "Myynti",
		email: "xxx",
		number: "001"
	}
];

export const ContactInfo = () => {
	const { t } = useTranslation();
	return (
		<Grid item xs={6}>
			<Stack spacing={2}>
				<Typography>{t("contact.contact_label")}</Typography>

				{contacts.map((entry) => (
					<Card
						key={uuid()}
						sx={{
							margin: "10px",
							width: "50%",
							textAlign: "center"
						}}
					>
						<Typography>{entry.name}</Typography>
						<Typography>{entry.email}</Typography>
						<Typography>{entry.number}</Typography>
					</Card>
				))}
			</Stack>
		</Grid>
	);
};
