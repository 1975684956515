import React, { useContext, useEffect, useState } from "react";
import {
	Dialog,
	DialogContent,
	CircularProgress,
	Typography
} from "@mui/material";

import { ColorScheme } from "../../../../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../../../../ApiContext";
import { AlertBar } from "../../../../Common/AlertBar";

const BillingInformationDialog = ({
	open,
	setOpen,
	customerName,
	businessId,
	passBillingData
}) => {
	const { ApiCall } = useContext(ApiContext);

	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState("");

	const [foundData, setFoundData] = useState(null);

	const { t } = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	async function fetchData() {
		setFetching(true);
		ApiCall("GET", `client/get_invoicing_data/${businessId}`)
			.then((response) => {
				setFetching(false);
				setFoundData(response);
			})
			.catch((error) => {
				if (customerName != "") {
					ApiCall("GET", `client/get_invoicing_data/${customerName}`)
						.then((response) => {
							console.log(response);
							setFetching(false);
							setFoundData(response);
						})
						.catch((error) => {
							setFetching(false);
							setError(error);
						});
				} else {
					setFetching(false);
					setError(error);
				}
			});
	}

	useEffect(() => {
		if (open) {
			fetchData();
		} else {
			setFoundData(null);
			setFetching(false);
			setError("");
		}
	}, [open]);

	return (
		<div>
			<Dialog open={open} onClose={handleClose}>
				<div style={{ width: "90vw", maxWidth: "500px" }}>
					<DialogContent>
						{fetching && <CircularProgress />}
						{!fetching && foundData != null && (
							<div>
								<AlertBar
									type={"info"}
									standardVariant={true}
									textContent={t(
										"billing.billing_data_fetched"
									)}
									showAlert={true}
									whiteSpacePreLine={true}
								/>
								<Typography>
									{t("billing.form_client_name_label") +
										": " +
										foundData.customer_name}
								</Typography>
								<Typography>
									{t("billing.form_business_code_label") +
										": " +
										foundData.business_id}
								</Typography>
								<Typography>
									{t(
										"billing.form_einvoicing_operator_label"
									) +
										": " +
										foundData.invoice_operator}
								</Typography>
								<Typography>
									{t(
										"billing.form_einvoicing_address_label"
									) +
										": " +
										foundData.invoice_address}
								</Typography>
							</div>
						)}
						{!fetching && error != "" && (
							<AlertBar
								type={"error"}
								standardVariant={true}
								textContent={error}
								showAlert={true}
							/>
						)}
						{!fetching && (
							<div
								style={{
									flexDirection: "row",
									display: "flex",
									justifyContent: "space-between",
									height: "50px",
									marginTop: "20px"
								}}
							>
								<div
									onClick={() => setOpen(false)}
									style={{
										width: "48%",
										height: "100%",
										borderRadius: "4px",
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
										border:
											"1px solid " +
											ColorScheme.tolotechBlue,
										cursor: "pointer"
									}}
								>
									<Typography
										style={{
											fontSize: "18px"
										}}
									>
										{t("buttons.cancel")}
									</Typography>
								</div>
								<div
									style={{
										width: "48%",
										height: "100%",
										borderRadius: "4px",
										justifyContent: "center",
										alignItems: "center",
										display: "flex",
										backgroundColor:
											ColorScheme.tolotechBlue,
										cursor: "pointer",
										opacity: error != "" && 0.5
									}}
									onClick={() =>
										error == "" &&
										passBillingData(foundData)
									}
								>
									<Typography
										style={{
											fontSize: "18px",
											color: "white"
										}}
									>
										{t("buttons.confirm")}
									</Typography>
								</div>
							</div>
						)}
					</DialogContent>
				</div>
				{/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose} color="primary">
                        Save
                    </Button>
                </DialogActions>
    */}
			</Dialog>
		</div>
	);
};

export default BillingInformationDialog;
