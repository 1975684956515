import React, { useContext, useEffect, useState } from "react";
import { Router } from "../Router";
import { TopBar } from "./TopBar/TopBar";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";
import { UserContext } from "../UserContext";
import { isMobile } from "react-device-detect";
import { MobileSidebarMenu } from "./SidebarMenu/MobileSidebarMenu";
import { Grid } from "@mui/material";
import { matchPath, useNavigate } from "react-router-dom";

export const MainpageContainer = () => {
	const navigate = useNavigate();
	const { user, selectedLocation } = useContext(UserContext);
	const isEmpty = Object.keys(user).length === 0;
	const [hideElements, setHideElements] = useState(false);

	const match = matchPath(
		{
			path: "/details",
			exact: true,
			strict: true
		},
		location.pathname
	);

	useEffect(() => {
		// console.log("täää", location, match, match?.pathname);
		if (match?.pathname === location.pathname) setHideElements(true);
		else setHideElements(false);
	}, [location]);

	useEffect(() => {
		if (
			// prevent navigation loop for new user
			selectedLocation.length > 0 &&
			// first check if location billing is missing.
			(selectedLocation.billing === undefined ||
				selectedLocation.billing?.business_id === undefined ||
				selectedLocation.billing?.customer_name === undefined ||
				selectedLocation.billing?.invoice_address === undefined ||
				selectedLocation.billing?.invoice_operator === undefined ||
				selectedLocation.billing === null ||
				selectedLocation.billing?.business_id === null ||
				selectedLocation.billing?.customer_name === null ||
				selectedLocation.billing?.invoice_address === null ||
				selectedLocation.billing?.invoice_operator === null)
		)
			if (
				// if billing is missing then check url path to block navigation via url and redirect to location page

				location.pathname !==
					`/locations/${selectedLocation.location_id}` &&
				location.pathname !== "/locations"
			)
				navigate("/");
			else return;
	}, [selectedLocation]);

	return (
		<Grid
			container
			sx={{
				display: "grid",
				gridTemplateRows: "120px repeat(1,1fr)",
				gridTemplateColumns: "170px repeat(1,1fr)",
				gap: "0px 0px",
				/* gridTemplateAreas: `'nav topbar topbar'
   			 						'nav main main'
									'nav main main'`, */
				/* height: "100%", */
				backgroundColor: "#1E2D43",
				color: "white",
				minHeight: "inherit",
				width: isMobile ? "100dvw" : "100vw",
				height: isMobile ? "100%" : "100vh"
			}}
		>
			{!isMobile && (
				<Grid
					item
					sx={{
						display: hideElements && "none",
						position: "fixed",
						gridArea: "1/1/3/2"
						/* gridArea: "nav",
						gridRowStart: "1",
						gridColumnStart: "2",
						gridRowEnd: "1",
						gridColumnEnd: "3" */
					}}
				>
					{!isEmpty && <SidebarMenu />}
				</Grid>
			)}

			<Grid
				item
				sx={{
					display: hideElements ? "none" : "flex",
					gridArea: isMobile ? "1/1/1/2" : "1/2/2/2",
					/* gridAutoFlow: "row",
					gridArea: "topbar", */
					width: isMobile ? "95dvw" : "100%",
					/* maxHeight: "4vh" */
					px: "4%"
					// left: isMobile ? "3dvw" : "5%"
					/* maxHeight: isDesktop ? "70%" : "100dwh" */

					/* ToDo: fix height for mobile view */
				}}
			>
				{isMobile && <MobileSidebarMenu />}

				<TopBar title={"Test"} />
			</Grid>

			<Grid
				item
				sx={{
					gridArea: hideElements
						? "auto"
						: isMobile
							? "2/1/3/3"
							: "2/2/3/3",
					/* position: "absolute", */
					/* gridArea: "main",
					gridRowStart: "2",
					gridColumnStart: "2",
					gridRowEnd: "2",
					gridColumnEnd: "4", */
					width: isMobile ? "100dvw" : hideElements ? "95%" : "95%",
					height: isMobile ? "100dvh" : "calc(100vh - 120px)", // space reserved for footer, currently not in use, but keep it to push more empty space between last content and end
					overflow: "scroll",
					scrollbarWidth: "none",
					padding: !isMobile ? "6%" : "4%",
					paddingBottom: isMobile ? "200px" : "6%"
				}}
			>
				<Router />
			</Grid>
		</Grid>
	);
};
