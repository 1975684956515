import React, { useContext } from "react";
import { Stack } from "@mui/material";
// import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import autoTable from "jspdf-autotable";

import { ApiContext } from "../ApiContext";
import { UserContext } from "../UserContext";
import { ReportWidget } from "./Widgets/ReportWidget";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop, isMobile } from "react-device-detect";
import { format, startOfDay, endOfDay } from "date-fns";
import ScreenWidth from "../../Helpers/ScreenWidth";
import { useTranslation } from "react-i18next";

export const ReportContainer = () => {
	const debug = false;
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	const generatePDF = (prop, res) => {
		const doc = new jsPDF({
			orientation: "p",
			unit: "mm",
			format: "a4",
			putOnlyUsedFonts: true,
			floatPrecision: "smart"
		});

		const filtered =
			prop.taxValue !== undefined
				? res.filter(
						(values) => values.vat_percentage === prop.taxValue
					)
				: res;

		const detailedBody = (type = "all") => {
			let filteredBody = filtered;
			if (type === "cash") {
				filteredBody = filtered.filter(
					(entry) =>
						(entry.order_stamp === "" ||
							entry.payment_method === "cash") &&
						entry.order_succesful === true &&
						entry.order_complete === true &&
						entry.refund_status === 0
				);
			} else if (type === "paytrail") {
				filteredBody = filtered.filter(
					(entry) =>
						entry.order_stamp !== "" &&
						entry.payment_method !== "cash" &&
						entry.order_succesful === true &&
						entry.order_complete === true &&
						entry.refund_status === 0
				);
			} else {
				filteredBody = filtered.filter(
					(entry) =>
						entry.order_succesful === true &&
						entry.order_complete === true &&
						entry.refund_status === 0
				);
			}
			let results = filteredBody.map((entry) =>
				entry.order_contents.map((sale) => [
					format(new Date(entry.created_at), "dd.MM.yyyy. HH:mm:ss"),
					sale.name,
					entry.payment_method === "" ? "cash" : entry.payment_method,
					sale.vat_percentage,
					sale.vat_price.toFixed(2) +
						" " +
						t("common.currency_symbol"),
					(sale.vat_price / (1 + sale.vat_percentage / 100)).toFixed(
						2
					) +
						" " +
						t("common.currency_symbol")
				])
			);
			console.log(results.length);
			return results;
		};

		const defaultBody = (type = "all") => {
			let filteredBody = filtered;
			if (type === "cash") {
				filteredBody = filtered.filter(
					(entry) => entry.cash_payment === true
				);
			} else if (type === "paytrail") {
				filteredBody = filtered.filter(
					(entry) => entry.cash_payment === false
				);
			}

			return filteredBody.map((entry) => [
				entry.row_name,
				entry.sales,
				entry.vat_percentage + "%",
				entry.total_vat_price.toFixed(2) +
					" " +
					t("common.currency_symbol"),
				entry.total_vatless_price.toFixed(2) +
					" " +
					t("common.currency_symbol")
			]);
		};

		function getDefaultBodyLength(type = "all") {
			let body = defaultBody(type);
			console.log(body);

			let total = 0;
			for (let i = 0; i < body.length; i++) {
				total += body[i][1];
			}
			return total;
		}

		const defaultHead = [
			t("report.pdf_row_title_product"),
			t("report.pdf_row_title_sale"),
			t("report.pdf_row_title_tax_acronym"),
			t("report.pdf_row_title_total"),
			t("report.pdf_row_title_total_without_tax")
		];

		const detailedHead = [
			t("report.pdf_row_title_time"),
			t("report.pdf_row_title_product"),
			t("report.pdf_row_title_payment_method"),
			t("report.pdf_row_title_tax_acronym"),
			t("report.pdf_row_title_price_with_tax"),
			t("report.pdf_row_title_price_without_tax")
		];

		const string = {
			head: [prop.isDetailed ? detailedHead : defaultHead],
			body: prop.isDetailed ? detailedBody().flat() : defaultBody(),
			footer: [
				[
					"",
					{
						content: t("report.pdf_row_title_total_paytrail"),
						styles: { halign: "right" }
					}
				]
			],
			...(!prop.isDetailed && {
				totals: filtered.map((entry) => [
					entry.total_vat_price.toFixed(2),
					entry.total_vatless_price.toFixed(2)
				])
			})
		};

		const stringCash = {
			head: [prop.isDetailed ? detailedHead : defaultHead],
			body: prop.isDetailed ? detailedBody().flat() : defaultBody(),
			footer: [
				[
					"",
					{
						content: t("report.pdf_row_title_total_cash"),
						styles: { halign: "right" }
					}
				]
			],
			...(!prop.isDetailed && {
				totals: filtered.map((entry) => [
					entry.total_vat_price.toFixed(2),
					entry.total_vatless_price.toFixed(2)
				])
			})
		};

		let totalPagesExp = "empty_string";

		doc.autoTable({
			head: string.head,
			showHead: "everyPage",
			body: string.body,
			startY: 50,
			margin: { top: 50, bottom: 50 },
			theme: "striped",
			didParseCell: (data) => {
				// jspdf-autotable doesn't have same functionality for headStyles as it does for columnStyles, so this is the way.
				if (
					prop.isDetailed &&
					data.cell &&
					data.cell.section === "head"
				)
					switch (data.cell.raw) {
						case t("report.pdf_row_title_time"):
						case t("report.pdf_row_title_product"):
							data.cell.styles.halign = "left";
							break;
						default:
							data.cell.styles.halign = "center";
							break;
					}
			},
			headStyles: {
				fillColor: ColorScheme.tolotechBlue,
				textColor: "white"
			},
			rowPageBreak: "avoid",
			columnStyles: {
				0: {
					cellWidth: prop.isDetailed ? 40 : 35
				},
				1: {
					cellWidth: prop.isDetailed ? 70 : 15,
					halign: !prop.isDetailed && "center"
				},
				2: {
					halign: "center"
				},
				3: {
					halign: "center"
				},
				4: {
					halign: "center"
				},
				5: {
					halign: "center"
				},
				6: {
					halign: "center"
				}
			},

			didDrawPage: (data) => {
				// Header
				// Checks for location logo image and verifies that it is png filetype

				const checkImage =
					selectedLocation.logo_image.image.substring(0, 14) ===
					"data:image/png";

				if (
					selectedLocation.logo_image.image !== undefined &&
					checkImage
				)
					doc.addImage(
						selectedLocation.logo_image.image,
						"png",
						data.settings.margin.left,
						10,
						10,
						10
					);

				doc.text(
					selectedLocation.name,
					data.settings.margin.left + 15,
					15
				);

				doc.setFontSize(10);
				doc.text(
					t("report.pdf_content_title"),
					data.settings.margin.left + 15,
					20
				);

				doc.text(
					`${format(prop.begin, "dd.MM.yyyy")} - ${format(
						prop.end,
						"dd.MM.yyyy"
					)}`,
					data.settings.margin.left + 15,
					25
				);

				doc.text(
					`${format(new Date(), "dd.MM.yyyy HH:mm:ss")}`,
					doc.getPageWidth() / 1.3,
					15
				);

				let str =
					t("report.page_indicator") +
					doc.internal.getNumberOfPages() +
					t("report.page_separator") +
					totalPagesExp;

				doc.setFontSize(10);

				let pageSize = doc.internal.pageSize;
				let pageHeight = pageSize.height
					? pageSize.height
					: pageSize.getHeight();

				doc.text(str, data.settings.margin.left, pageHeight - 10);
			}
		});

		// text after main content

		let finalY = doc.previousAutoTable.finalY;

		function countTotal(name, cash = false) {
			let filteredEntries = filtered;
			filteredEntries = filtered.filter(
				(entry) => entry.cash_payment === cash
			);
			return filteredEntries
				.map((entry) => entry[name])
				.reduce((a, b) => Number(a) + Number(b), 0)
				.toFixed(2);
		}

		function countTotalDetailed(name, cash = false, includeVat = true) {
			let filteredEntries = filtered;
			if (cash) {
				filteredEntries = filtered.filter(
					(entry) =>
						(entry.order_stamp === "" ||
							entry.payment_method === "cash") &&
						entry.order_succesful === true &&
						entry.order_complete === true &&
						entry.refund_status === 0
				);
			} else {
				filteredEntries = filtered.filter(
					(entry) =>
						entry.order_stamp !== "" &&
						entry.payment_method !== "cash" &&
						entry.order_succesful === true &&
						entry.order_complete === true &&
						entry.refund_status === 0
				);
			}
			let result = 0;
			if (includeVat) {
				result = filteredEntries
					.map((entry) => entry[name])
					.reduce((a, b) => Number(a) + Number(b), 0)
					.toFixed(2);
			} else {
				let res = 0;
				filteredEntries.forEach((entry) => {
					entry.order_contents.forEach((sale) => {
						res += sale.vat_price / (1 + sale.vat_percentage / 100);
					});
				});
				result = res.toFixed(2);
			}
			return result;
		}

		doc.autoTable({
			head: stringCash.footer,
			body: [
				[
					t("report.pdf_row_title_sum"),
					!prop.isDetailed
						? countTotal("total_vat_price", true) +
							" " +
							t("common.currency_symbol")
						: countTotalDetailed("total_vat_price", true) +
							" " +
							t("common.currency_symbol")
				],
				!prop.isDetailed
					? [
							t("report.pdf_row_title_total_without_tax"),
							countTotal("total_vatless_price", true) +
								" " +
								t("common.currency_symbol")
						]
					: [
							t("report.pdf_row_title_total_without_tax"),
							countTotalDetailed("total_vat_price", true, false) +
								" " +
								t("common.currency_symbol")
						],
				!prop.isDetailed
					? [t("report.sales_amount"), getDefaultBodyLength("cash")]
					: [
							t("report.sales_amount"),
							detailedBody("cash").flat().length
						]
			],
			tableWidth: "auto",
			cellWidth: "wrap",
			columnStyles: {
				0: {
					cellWidth: 25
				},
				1: {
					cellWidth: 30,
					halign: "right"
				}
			},
			startY: finalY + 15,
			margin: { left: doc.internal.pageSize.width / 1.5 },
			theme: "grid",
			headStyles: {
				fillColor: ColorScheme.tolotechBlue,
				textColor: "white"
			}
		});

		doc.autoTable({
			head: string.footer,
			body: [
				[
					t("report.pdf_row_title_sum"),
					!prop.isDetailed
						? countTotal("total_vat_price") +
							" " +
							t("common.currency_symbol")
						: countTotalDetailed("total_vat_price") +
							" " +
							t("common.currency_symbol")
				],
				!prop.isDetailed
					? [
							t("report.pdf_row_title_total_without_tax"),
							countTotal("total_vatless_price") +
								" " +
								t("common.currency_symbol")
						]
					: [
							t("report.pdf_row_title_total_without_tax"),
							countTotalDetailed(
								"total_vat_price",
								false,
								false
							) +
								" " +
								t("common.currency_symbol")
						],
				!prop.isDetailed
					? [
							t("report.sales_amount"),
							getDefaultBodyLength("paytrail")
						]
					: [
							t("report.sales_amount"),
							detailedBody("paytrail").flat().length
						]
			],
			tableWidth: "auto",
			cellWidth: "wrap",
			columnStyles: {
				0: {
					cellWidth: 25
				},
				1: {
					cellWidth: 30,
					halign: "right"
				}
			},
			startY: finalY + 15,
			margin: { left: doc.internal.pageSize.width / 2.7 },
			theme: "grid",
			headStyles: {
				fillColor: ColorScheme.tolotechBlue,
				textColor: "white"
			}
		});

		doc.putTotalPages(totalPagesExp);

		function convertUmlauts(string) {
			string = string.replace(/[äå]/g, "a");
			string = string.replace(/[ÄÅ]/g, "A");
			string = string.replace(/ö/g, "o");
			string = string.replace(/Ö/g, "O");
			string = string.replace(/ /g, "_");

			return string;
		}

		const fileName =
			prop.taxValue !== undefined
				? `${convertUmlauts(selectedLocation.name)}-${t(
						"report.pdf_exported_file_name"
					)}_${prop.taxValue}alv`
				: `${convertUmlauts(selectedLocation.name)}-${t(
						"report.pdf_exported_file_name"
					)}`;

		doc.save(`${fileName}_${format(new Date(), "dd-MM-yyyy")}.pdf`);
	};

	const handleTaxReport = (prop) => {
		ApiCall(
			"GET",
			`client/statistics/${
				selectedLocation.location_id
			}/vats?from=${format(
				startOfDay(prop.begin),
				"yyyy-MM-dd"
			)}&to=${format(endOfDay(prop.end), "yyyy-MM-dd")}`
		)
			.then((res) => generatePDF(prop, res))
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const handleSalesReport = (prop) => {
		ApiCall(
			"GET",
			`client/statistics/${
				selectedLocation.location_id
			}/vats?from=${format(
				startOfDay(prop.begin),
				"yyyy-MM-dd"
			)}&to=${format(endOfDay(prop.end), "yyyy-MM-dd")}`
		)
			.then((res) => {
				if (debug) console.log(res);
				generatePDF(prop, res);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const handleTypeSalesReport = (prop) => {
		ApiCall(
			"GET",
			`client/statistics/${
				selectedLocation.location_id
			}/vats?from=${format(
				startOfDay(prop.begin),
				"yyyy-MM-dd"
			)}&to=${format(endOfDay(prop.end), "yyyy-MM-dd")}&type=${prop.type}`
		)
			.then((res) => {
				if (debug) console.log(res);
				generatePDF(prop, res);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const handleDaySaleReport = (prop) => {
		ApiCall(
			"GET",
			`client/get_orders/${selectedLocation.location_id}?from=${format(
				startOfDay(prop.begin),
				"yyyy-MM-dd"
			)}&to=${format(endOfDay(prop.end), "yyyy-MM-dd")}&type=${prop.type}`
		)
			.then((res) => {
				generatePDF(prop, res);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	return (
		<Stack
			spacing={2}
			flexDirection={!isMobile && ScreenWidth(1525) ? "row" : "column"}
			flexWrap={!isMobile && ScreenWidth(1525) ? "wrap" : "nowrap"}
			gap={"20px"}
			sx={{
				width: isMobile ? "100%" : undefined,
				"& > *": {
					marginTop: "0px !important"
				},
				display: "flex",
				height: "100%",
				justifyContent:
					isDesktop && ScreenWidth(1525)
						? "flex-end"
						: "space-between"
				// gap: "15px"
			}}
		>
			{/* <Typography variant="h5">{t("report.quick_functions")}</Typography> */}

			<ReportWidget
				hasTaxSelection={true}
				title={t("report.tax_separation")}
				buttonText={t("buttons.create_report")}
				handleData={handleTaxReport}
			/>

			<ReportWidget
				title={t("report.sales_report")}
				buttonText={t("buttons.create_report")}
				handleData={handleSalesReport}
			/>

			<ReportWidget
				hasSalesSelection={true}
				title={t("report.sales_separation")}
				buttonText={t("buttons.create_report")}
				handleData={handleTypeSalesReport}
			/>

			<ReportWidget
				isDetailed={true}
				title={t("report.e_sales_report")}
				buttonText={t("buttons.create_report")}
				handleData={handleDaySaleReport}
			/>
		</Stack>
	);
};
