import React, { useContext, useEffect, useState } from "react";
import { Button, Collapse, Stack, Typography } from "@mui/material";

import { ReactComponent as CampaignNameIcon } from "../../Assets/Icons/CampaignIcon1.svg";
import { ReactComponent as CampaignDescriptionIcon } from "../../Assets/Icons/PenIcon.svg";
import { ReactComponent as CampaignCodeIcon } from "../../Assets/Icons/BarcodeIcon.svg";
import { ReactComponent as CampaignAmountIcon } from "../../Assets/Icons/SaleIcon.svg";
import { ReactComponent as CampaignTargetIcon } from "../../Assets/Icons/SeachGlassIcon.svg";
import { ReactComponent as CampaignUseLimitIcon } from "../../Assets/Icons/PriceArrowsIcon.svg";
import { ReactComponent as CampaignUserLimitIcon } from "../../Assets/Icons/UserBasketIcon.svg";
import { ReactComponent as CampaignStartIcon } from "../../Assets/Icons/CalendarFromIcon.svg";
import { ReactComponent as CampaignEndIcon } from "../../Assets/Icons/CalendarToIcon.svg";
import { ReactComponent as TrashIcon } from "../../Assets/Icons/TrashDeleteIcon.svg";

import { ApiContext } from "../ApiContext";
import { UserContext } from "../UserContext";
import { Box } from "@mui/system";
import { DiscountTypes } from "../Common/DiscountTypes";
import { useTranslation } from "react-i18next";
import { SaveAnimationButton } from "../Common/Inputs/SaveAnimationButton";
import OnOffSlider from "../Common/Inputs/OnOffSlider";
import { TextFieldInput2 } from "../Common/TextFieldInput2";
import { TextFieldSelect } from "../Common/TextFieldSelect";
import { TextfieldPicker } from "../Common/TextfieldPicker";
import { ColorScheme } from "../../Themes/ColorScheme";
import "./Animations/DiscountSelector.css";
import { AlertBar } from "../Common/AlertBar";
import { isDesktop } from "react-device-detect";
import { ConfirmAction } from "../Common/ConfirmAction";

export const CreateCampaign = ({
	triggerRefresh,
	campaignData, // is false if no data is passed
	handleDrawerClose
}) => {
	const [campaignName, setCampaignName] = useState("");
	const [campaignDescription, setCampaignDescription] = useState("");
	const [manualCode, setManualCode] = useState("");
	const [discountType, setDiscountType] = useState("");
	const [discountAmount, setDiscountAmount] = useState("");
	const [discountIncluded, setDiscountIncluded] = useState(5);
	const [characters, setCharacters] = useState("");
	const [codeLength, setCodeLength] = useState("");
	const [codePrefix, setCodePrefix] = useState("");
	const [campaignLimit, setCampaignLimit] = useState("");
	const [campaignUserLimit, setCampaignUserLimit] = useState("");
	const [campaignStartTime, setCampaignStartTime] = useState("");
	const [campaignEndTime, setCampaignEndTime] = useState("");
	const [campaignDays, setCampaignDays] = useState("");
	const [apiMethod, setApiMethod] = useState("");
	const [apiPath, setApiPath] = useState("");
	const [loadingButton, setLoadingButton] = useState(false);
	const [showError, setShowError] = useState(null);

	const [phase, setPhase] = useState(0);

	const [disableOnUpcomingReservations, setDisableOnUpcomingReservations] =
		useState(false);

	// const [showAdvanced, setShowAdvanced] = useState(false);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);

	const handleCodeChange = (value) => {
		setManualCode(value);
	};

	const handleAmountChange = (value) => {
		setDiscountAmount(value);
	};

	const handleCampaignName = (value) => {
		setCampaignName(value);
	};

	const handleCampaignDescription = (value) => {
		setCampaignDescription(value);
	};

	const handleTypeChange = (value) => {
		if (value !== null) {
			setDiscountType(value);
		}
	};

	/* const toggleAdvancedSettings = () => {
		setShowAdvanced(!showAdvanced);
	}; */

	const handleStart = (time) => {
		setCampaignStartTime(time);
	};

	const handleEnd = (time) => {
		setCampaignEndTime(time);
	};

	const handleCampaignLimit = (value) => {
		setCampaignLimit(value);
	};

	const handleCampaignUserLimit = (value) => {
		setCampaignUserLimit(value);
	};

	const handleCodeGenerate = () => {
		let result = "";

		const charactersLength = characters.length;
		for (let i = 0; i < codeLength; i++)
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);

		const text = codePrefix + result;

		setManualCode(text);
	};

	const handleCampaign = () => {
		setLoadingButton(true);
		ApiCall(apiMethod, apiPath, {
			name: campaignName,
			description: campaignDescription,
			code: manualCode,
			discount_euros:
				discountType === "fixed" ? Number(discountAmount) : 0,
			discount_percent:
				discountType === "percent" ? Number(discountAmount) : 0,
			discount_type: discountIncluded,

			// item_id: 0,
			location_id: selectedLocation.location_id,
			reservable_from: campaignStartTime,
			reservable_to: campaignEndTime,
			from_time: campaignStartTime,
			to_time: campaignEndTime,
			usage_count: Number(campaignLimit),
			user_limit: Number(campaignUserLimit),
			valid_from: campaignStartTime,
			valid_to: campaignEndTime,
			weekdays: campaignDays,
			disable_on_upcoming_reservations: disableOnUpcomingReservations
		})
			.then(() => {
				setLoadingButton(false);
				setShowError(null);
				triggerRefresh();
			})
			.catch((e) => {
				setLoadingButton(false);
				setShowError(e);
				// console.log(e);
			});
	};

	const handleChange = (value) => {
		setDiscountIncluded(value);
	};

	const switchDisableOnUpcomingReservations = (e) => {
		e.stopPropagation();

		setDisableOnUpcomingReservations(!disableOnUpcomingReservations);
	};

	const checkMissingData = () =>
		campaignName === "" ||
		campaignDescription === "" ||
		manualCode === "" ||
		discountAmount === "" ||
		discountType === "" ||
		discountIncluded === "" ||
		campaignLimit === "" ||
		campaignUserLimit === "" ||
		campaignStartTime === "" ||
		campaignEndTime === "";

	const handleDelete = () => {
		ApiCall("DELETE", `client/remove_campaign/${campaignData.id}`, null)
			.then(() => triggerRefresh())
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		setPhase(0);

		if (!campaignData) {
			setApiMethod("POST");
			setApiPath("client/new_campaign");
		} else {
			setApiMethod("PUT");
			setApiPath(`client/update_campaign/${campaignData.id}`);
		}

		if (campaignData !== undefined) {
			if (campaignData !== false) setPhase(5);

			setCampaignName(campaignData.name);
			setCampaignDescription(campaignData.description);
			setManualCode(campaignData.code);
			setDiscountIncluded(campaignData.discount_type);
			setCampaignLimit(campaignData.usage_count);
			setCampaignUserLimit(campaignData.user_limit);

			setCampaignStartTime(new Date(campaignData.from_time));
			setCampaignEndTime(new Date(campaignData.to_time));

			setCampaignDays(campaignData.weekdays);
			setDisableOnUpcomingReservations(
				campaignData.disable_on_upcoming_reservations
			);

			if (
				campaignData.discount_euros === 0 &&
				campaignData.discount_percent > 0
			) {
				setDiscountType("percent");
				setDiscountAmount(campaignData.discount_percent);
			} else if (
				campaignData.discount_euros > 0 &&
				campaignData.discount_percent === 0
			) {
				setDiscountType("fixed");
				setDiscountAmount(campaignData.discount_euros);
			}
		}

		if (!campaignData) {
			// no campaign data, set default datetime values
			setCampaignStartTime(new Date()); // current time
			setCampaignEndTime(calculateEndOfMonth()); // end of the month (midnight of the first day of the next month)
		}

		if (characters === "" && codeLength === "" && codePrefix === "") {
			setCharacters("1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ");
			setCodeLength(6);
			setCodePrefix("");
		}

		if (!campaignData && discountType === "") setDiscountType("percent");
	}, []);

	// Calculate end of the month
	const calculateEndOfMonth = () => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth();
		const lastDay = new Date(year, month + 1, 1); // Change the last day to the first day of the next month
		return lastDay; // Returns a value which includes all the days of the current month, so when time goes past 23:59:59, it will correctly end the campaign
	};

	useEffect(() => {
		// Check for input validation or data availability
		if (
			campaignName &&
			campaignDescription &&
			manualCode &&
			discountAmount &&
			discountType &&
			discountIncluded &&
			campaignLimit &&
			campaignUserLimit >= 0
		) {
			setPhase(5); // All required data is available
		} else if (
			campaignName &&
			campaignDescription &&
			manualCode &&
			discountAmount &&
			campaignLimit &&
			campaignUserLimit >= 0
		) {
			setPhase(4);
		} else if (
			campaignDescription &&
			manualCode &&
			discountAmount &&
			discountType
		) {
			setPhase(3);
		} else if (campaignDescription && manualCode && discountAmount) {
			setPhase(2);
		} else if (campaignName) {
			setPhase(1);
		} else {
			setPhase(0);
		}
	}, [
		campaignName,
		campaignDescription,
		manualCode,
		discountAmount,
		discountType,
		discountIncluded,
		campaignLimit,
		campaignUserLimit,
		campaignStartTime,
		campaignEndTime,
		disableOnUpcomingReservations
	]);

	return (
		<Box
			sx={{
				display: "flex",
				/* height: "100%", */
				width: "100%",
				margin: "auto",
				backgroundColor: "white"
			}}
		>
			<div style={{ width: "100%" }}>
				<div
					style={{
						display: "flex",
						width: "100%",
						justifyContent: "flex-end",
						paddingBottom: "20px"
					}}
				>
					{campaignData && (
						<ConfirmAction
							hasChild={true}
							handleTitle={t("campaign.delete_campaign")}
							handleConfirmAction={() => handleDelete()}
						>
							<TrashIcon
								stroke={ColorScheme.red}
								height={"28px"}
								width={"28px"}
							/>
						</ConfirmAction>
					)}
				</div>
				<Stack sx={{ width: "100%", gap: "10px" /* height: "100%" */ }}>
					<Collapse in={phase >= 0} collapsedSize={true}>
						<TextFieldInput2
							text={campaignName}
							valueChange={handleCampaignName}
							title={t("campaign.name_label")}
							hasProps={
								<CampaignNameIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							}
						/>
					</Collapse>

					<Collapse in={phase >= 1} collapsedSize={true}>
						<div
							style={{
								height: "100%",
								paddingBottom:
									"5px" /* temp workaround to create more gap between fields */
							}}
						>
							<TextFieldInput2
								text={campaignDescription}
								valueChange={handleCampaignDescription}
								title={t("campaign.description_label")}
								hasProps={
									<CampaignDescriptionIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
								rowAmount={6}
								charCount={true}
								hasCharLimit={500}
							/>
						</div>
					</Collapse>
					<div style={{ height: "0px" }} />
					<Collapse in={phase >= 1} collapsedSize={true}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
								gap: "10px"
							}}
						>
							<TextFieldInput2
								text={manualCode}
								valueChange={handleCodeChange}
								title={t("campaign.create_campaign_code_label")}
								endAdornment={
									<Button
										onClick={() => handleCodeGenerate()}
									>
										{t("buttons.create")}
									</Button>
								}
								hasProps={
									<CampaignCodeIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							<TextFieldInput2
								text={discountAmount}
								onlyNumbers={true}
								checkValue={
									discountType === "percent"
										? discountAmount > 100
										: undefined
								}
								valueChange={handleAmountChange}
								title={t(
									"campaign.amount_and_discount_type_label"
								)}
								hasProps={
									<CampaignAmountIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
								endAdornment={
									<div className="discount-selector">
										<button
											onClick={() =>
												handleTypeChange("percent")
											}
											className={
												discountType === "percent"
													? "active"
													: ""
											}
										>
											<Typography>%</Typography>
										</button>
										<button
											onClick={() =>
												handleTypeChange("fixed")
											}
											className={
												discountType === "fixed"
													? "active"
													: ""
											}
										>
											<Typography>€</Typography>
										</button>
									</div>
								}
							/>
						</div>
					</Collapse>

					<Collapse in={phase >= 2} collapsedSize={true}>
						<TextFieldSelect
							selected={discountIncluded}
							valueChange={handleChange}
							options={DiscountTypes}
							label={t("campaign.campaign_target_label")}
							hasProps={
								<CampaignTargetIcon
									stroke={ColorScheme.defaultIconColor}
								/>
							}
						/>
					</Collapse>

					<Collapse in={phase >= 3} collapsedSize={true}>
						<Stack
							direction={isDesktop ? "row" : "column"}
							spacing={isDesktop ? 2 : 1}
						>
							<TextFieldInput2
								text={campaignLimit}
								valueChange={handleCampaignLimit}
								title={t("campaign.campaign_use_limit_label")}
								hasProps={
									<CampaignUseLimitIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							<TextFieldInput2
								text={campaignUserLimit}
								valueChange={handleCampaignUserLimit}
								title={t("campaign.campaign_user_limit_label")}
								hasProps={
									<CampaignUserLimitIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</Stack>
					</Collapse>

					<Collapse in={phase >= 4} collapsedSize={true}>
						<Stack
							direction={isDesktop ? "row" : "column"}
							spacing={2}
						>
							<TextfieldPicker
								value={campaignStartTime}
								valueChange={handleStart}
								fullDate={true}
								excludePast={false}
								label={t("campaign.campaign_start_time_label")}
								hasProps={
									<CampaignStartIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>

							<TextfieldPicker
								value={campaignEndTime}
								valueChange={handleEnd}
								fullDate={true}
								excludePast={false}
								label={t("campaign.campaign_end_time_label")}
								hasProps={
									<CampaignEndIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</Stack>

						<Stack
							direction="row"
							spacing={2}
							alignItems={"center"}
							justifyContent={"space-between"}
						>
							<Typography variant="form_bold">
								{t("campaign.disable_on_upcoming_reservations")}
							</Typography>
							<OnOffSlider
								labels
								textEnabled={false}
								status={disableOnUpcomingReservations}
								action={switchDisableOnUpcomingReservations}
							/>
						</Stack>
					</Collapse>

					<Collapse in={showError !== null}>
						<AlertBar
							showAlert={true}
							textContent={showError}
							type="error"
						/>
					</Collapse>

					<div style={{ flex: 1, height: "100%" }} />
					<Stack
						spacing={2}
						direction="row"
						sx={{ paddingBottom: "40px" }}
					>
						<Button
							variant="outlined"
							onClick={() => handleDrawerClose()}
							fullWidth
							sx={{ fontSize: "12px" }}
						>
							{t("buttons.cancel")}
						</Button>

						<Button
							variant="drawerFormAccept"
							disabled={checkMissingData()}
							onClick={() => handleCampaign()}
							fullWidth
						>
							<SaveAnimationButton
								loadingButton={loadingButton}
							/>
						</Button>
					</Stack>
				</Stack>
			</div>
		</Box>
	);
};
