import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { ConfirmAction } from "../../../Common/ConfirmAction";
import { Spinner } from "../../../Common/Spinner";
import { useTranslation } from "react-i18next";

export const UsersMobile = ({ slot, user, handleUserRemove, isLoading }) => {
	const { t } = useTranslation();

	return (
		<Stack direction="row" justifyContent="space-between">
			{user.reservation_accesses !== null && (
				/* user.reservation_accesses.length > 0 && */ <>
					<Stack sx={{ width: "100%" }}>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography
								style={{
									fontSize: "20px",
									fontWeight: "bold"
								}}
							>
								{user.user.name}
							</Typography>

							{!isLoading ? (
								<ConfirmAction
									isIconButton={true}
									handleTitle={t("buttons.remove_from_list")}
									handleConfirmAction={() =>
										handleUserRemove(
											user.user.email,
											slot.id
										)
									}
								/>
							) : (
								<Spinner />
							)}
						</Stack>

						<Typography
							style={{
								fontSize: "16px",
								fontWeight: "100"
							}}
						>
							{user.user.email}
						</Typography>

						{user.reservation_accesses !== null &&
							user.reservation_accesses.length > 0 &&
							user.reservation_accesses[0].pin_code !==
								undefined &&
							user.reservation_accesses[0].pin_code !== null && (
								<Stack
									justifyContent="end"
									alignItems="flex-end"
								>
									<Box
										style={{
											width: "fit-content",
											backgroundColor:
												ColorScheme.tolotechBlue,
											borderRadius: "4px"
										}}
									>
										<Typography
											style={{
												fontSize: 14,
												fontWeight: "bold",
												color: ColorScheme.tolotechWhite
											}}
										>
											{t("doors.pin_code_label") +
												": " +
												user.reservation_accesses[0]
													.door_access.pin_code}
										</Typography>
									</Box>
								</Stack>
							)}
					</Stack>
				</>
			)}
		</Stack>
	);
};
