import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import ListIcon from "../../../Assets/Icons/ListIcon.svg";

import { MuiDrawer } from "../../Common/MuiDrawer";
// import { ListGrouping } from "../Services/ListGrouping";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import moment from "moment";
// import ListGroupingSlider from "../Services/ListGroupingSlider";

export const Toolbar = ({
	props,
	/* passSelectedService,
	sendSelectedService, */
	drawerOpen,
	passRefreshReservation
	/* reservables */
}) => {
	const { t } = useTranslation();
	const [showContinuous, setShowContinuous] = useState(false);
	const [showExcludedTimes, setShowExcludedTimes] = useState(false);

	const mobileDate = moment(props.date).format("ddd D.M.");

	const closeDrawer = () => {
		setShowContinuous(false);
		setShowExcludedTimes(false);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				my: isMobile ? undefined : "20px",
				width: "100%",
				justifyContent: "space-between",
				gap: "40px"
			}}
		>
			{/* {!drawerOpen && (
				<div>
					<ListGroupingSlider
						passSelectedService={passSelectedService}
						oldData={sendSelectedService}
						reservables={reservables}
					/>
					{/* <ListGrouping
							passSelectedService={passSelectedService}
							oldData={sendSelectedService}
							reservables={reservables}
						/> 
				</div>
			)} */}

			<Stack
				direction={isMobile ? "column-reverse" : "row"}
				width="100%"
				justifyContent="space-between"
				alignItems={isMobile ? undefined : "center"}
				gap="15px"
			>
				{/* <Box
					style={{
						display: "flex",
						...(isMobile && {
							flexDirection: "column",
							height: "7px",
							alignItems: "center",
							flexWrap: "wrap"
						})
					}}
				/> */}

				<Box
					style={{
						flexDirection: "row",
						display: "flex",
						justifyContent: "flex-start",
						maxWidth: "500px",
						gap: isMobile ? "5px" : "10px"
					}}
				>
					<button
						style={{
							height: isMobile ? "35px" : "48px",
							width: drawerOpen ? "100%" : "160px",
							paddingLeft:
								drawerOpen && isMobile
									? "5px"
									: isMobile
										? "35px"
										: "40px",
							paddingRight:
								drawerOpen && isMobile
									? "5px"
									: isMobile
										? "35px"
										: "40px",
							backgroundColor: "#296DAE",
							borderWidth: 0,
							borderRadius: "6px",
							cursor: "pointer",
							transition: "background-color 0.3s ease-in-out"
						}}
						type="button"
						onClick={() => props.onNavigate("PREV")}
						onMouseEnter={(e) => {
							e.target.style.backgroundColor = "#3d7cc4";
						}}
						onMouseLeave={(e) => {
							e.target.style.backgroundColor = "#296DAE";
						}}
					>
						<Typography
							sx={{
								fontSize: isMobile ? "10px" : "12px",
								color: "white"
							}}
						>
							{isMobile
								? t("calendar.previous_day")
								: t("calendar.previous_week")}
						</Typography>
					</button>
					<button
						style={{
							height: isMobile ? "35px" : "48px",
							width: drawerOpen ? "100%" : "160px",
							paddingLeft:
								drawerOpen && isMobile
									? "5px"
									: isMobile
										? "35px"
										: "40px",
							paddingRight:
								drawerOpen && isMobile
									? "5px"
									: isMobile
										? "35px"
										: "40px",
							backgroundColor: "#296DAE",
							borderWidth: 0,
							borderRadius: "6px",
							cursor: "pointer",
							transition: "background-color 0.3s ease-in-out"
						}}
						type="button"
						onClick={() => props.onNavigate("TODAY")}
						onMouseEnter={(e) => {
							e.target.style.backgroundColor = "#3d7cc4";
						}}
						onMouseLeave={(e) => {
							e.target.style.backgroundColor = "#296DAE";
						}}
					>
						<Typography
							sx={{
								fontSize: isMobile ? "10px" : "12px",
								color: "white"
							}}
						>
							{t("calendar.today")}
						</Typography>
					</button>
					<button
						style={{
							height: isMobile ? "35px" : "48px",
							width: drawerOpen ? "100%" : "160px",
							paddingLeft:
								drawerOpen && isMobile
									? "5px"
									: isMobile
										? "35px"
										: "40px",
							paddingRight:
								drawerOpen && isMobile
									? "5px"
									: isMobile
										? "35px"
										: "40px",
							backgroundColor: "#296DAE",
							borderWidth: 0,
							borderRadius: "6px",
							cursor: "pointer",
							transition: "background-color 0.3s ease-in-out"
						}}
						type="button"
						onClick={() => props.onNavigate("NEXT")}
						onMouseEnter={(e) => {
							e.target.style.backgroundColor = "#3d7cc4";
						}}
						onMouseLeave={(e) => {
							e.target.style.backgroundColor = "#296DAE";
						}}
					>
						<Typography
							sx={{
								fontSize: isMobile ? "10px" : "12px",
								color: "white"
							}}
						>
							{isMobile
								? t("calendar.next_day")
								: t("calendar.next_week")}
						</Typography>
					</button>
				</Box>

				{/* 	<Box style={{ display: "flex" }} /> */}

				{!drawerOpen && (
					<Box
						sx={{
							width: isMobile ? "100%" : "50%",
							display: "flex",
							flexDirection: "row",
							borderRadius: "4px",
							/* overflow: "scroll", */
							justifyContent: isMobile
								? "space-between"
								: "flex-end",
							alignItems: "center",
							alignSelf: "flex-end",
							boxSizing: "border-box",
							gap: "10px"
						}}
					>
						<button
							style={{
								height: 48,
								width: "150px",
								backgroundColor: "#296DAE",
								justifyContent: "center",
								alignSelf: "flex-end",
								border: "0px solid white",
								borderRadius: "6px",
								paddingLeft: "20px",
								paddingRight: "20px",
								color: "white",
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								cursor: "pointer",
								transition: "background-color 0.3s ease-in-out"
							}}
							onClick={() => setShowContinuous(true)}
							onMouseEnter={(e) => {
								e.target.style.backgroundColor = "#3d7cc4";
							}}
							onMouseLeave={(e) => {
								e.target.style.backgroundColor = "#296DAE";
							}}
						>
							<img
								src={ListIcon}
								style={{ marginRight: "15px", height: "18px" }}
							/>
							<Typography fontSize="12px">
								{t("calendar.continuous_reservations_label")}
							</Typography>
						</button>
						<button
							style={{
								height: 48,
								width: "150px",
								alignItems: "center",
								justifyContent: "center",
								backgroundColor: "#296DAE",
								alignSelf: "flex-end",
								border: "0px solid white",
								borderRadius: "6px",
								paddingLeft: "20px",
								paddingRight: "20px",
								color: "white",
								display: "flex",
								flexDirection: "row",
								cursor: "pointer",
								transition: "background-color 0.3s ease-in-out"
							}}
							onClick={() => setShowExcludedTimes(true)}
							onMouseEnter={(e) => {
								e.target.style.backgroundColor = "#3d7cc4";
							}}
							onMouseLeave={(e) => {
								e.target.style.backgroundColor = "#296DAE";
							}}
						>
							<img
								src={ListIcon}
								style={{ marginRight: "15px", height: "18px" }}
							/>
							<Typography fontSize="12px">
								{t("calendar.space_reservations_label")}
							</Typography>
						</button>
					</Box>
				)}
			</Stack>

			{isMobile && (
				<Typography
					sx={{
						color: "white",
						textAlign: "center"
					}}
				>
					{mobileDate.charAt(0).toUpperCase() + mobileDate.slice(1)}
				</Typography>
			)}

			<MuiDrawer
				open={showContinuous || showExcludedTimes}
				handleDrawerClose={closeDrawer}
				passRefreshReservation={passRefreshReservation}
				contentType={
					showExcludedTimes
						? "ExcludedTimes"
						: "continuousReservations"
				}
			/>
		</Box>
	);
};
