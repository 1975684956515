import React, { useState, useEffect, useRef } from "react";
import { Typography, Box } from "@mui/material";
import TooltipWrapper from "../../../Common/TooltipWrapper";

export const TextComponent = ({ fontSize, color, fontWeight, text }) => {
	// overflow tooltip not in use yet wip

	const textRef = useRef(null);
	const [isOverflown, setIsOverflown] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (
				textRef.current &&
				textRef.current.clientWidth &&
				textRef.current.scrollWidth &&
				textRef.current.scrollWidth > textRef.current.clientWidth &&
				!isOverflown
			)
				setIsOverflown(true);
			else if (
				textRef.current &&
				textRef.current.clientWidth &&
				textRef.current.scrollWidth &&
				textRef.current.scrollWidth <= textRef.current.clientWidth &&
				isOverflown
			)
				setIsOverflown(false);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [isOverflown]);

	return (
		<Box ref={textRef} sx={{ overflow: "hidden" }}>
			<TooltipWrapper title={isOverflown ? text : undefined}>
				<Typography
					sx={{
						fontSize,
						color,
						fontWeight
					}}
				>
					{text}
				</Typography>
			</TooltipWrapper>
		</Box>
	);
};
