import React from "react";
import CustomizeIcon from "../../../../../Assets/Icons/CustomizeIcon.svg";

export const CustomizeButton = ({ changeEditMode }) => (
	<div style={{ display: "flex", minHeight: "100%", alignItems: "center" }}>
		<div
			onClick={() => changeEditMode()}
			style={{
				marginLeft: "15px",
				justifyContent: "center",
				display: "flex",
				cursor: "pointer",
				maxHeight: "24px",
				maxWidth: "24px"
			}}
		>
			<img src={CustomizeIcon} />
		</div>
	</div>
);
