import React from "react";
import { SnackBarAlert } from "./Common/SnackBarAlert";
import { Mainpage } from "./Main/Mainpage";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fi } from "date-fns/locale";

export const AppContainer = () => (
	<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
		<SnackBarAlert />
		<Mainpage />
	</LocalizationProvider>
);
