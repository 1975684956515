import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../ApiContext";
import { ReactComponent as BalanceUpIcon } from "../../Assets/Icons/BalanceUpIcon.svg";
import { ReactComponent as BalanceDownIcon } from "../../Assets/Icons/BalanceDownIcon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const UserBalanceLogs = ({ data }) => {
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const [userLogs, setUserLogs] = useState([]);
	const [fetching, setFetching] = useState(false);

	const checkValue = (value) => Math.sign(value) === 1;

	const fetchUserLogs = () => {
		setFetching(true);

		// /client/balance/transactions/${balance_id}
		ApiCall("GET", `client/balance/transactions/${data?.balances[0]?.id}`)
			.then((res) => {
				setUserLogs(res);
				setFetching(false);
			})
			.catch(() => {
				// console.log(err);
				setFetching(false);
			});
	};

	useEffect(() => {
		if (userLogs.length === 0 && !fetching) fetchUserLogs();
	}, []);

	return fetching ? (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				alignItems: "center",
				gap: "20px"
			}}
		>
			<CircularProgress />
			<Typography>{t("balances.loading_balances")}</Typography>
		</div>
	) : (
		userLogs
			.sort(
				(a, b) =>
					new Date(b.created_at).getTime() -
					new Date(a.created_at).getTime()
			)
			.map((log, i) => (
				<div
					key={log.id}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						borderTop: i !== 0 && "1px solid #E0E0E0",
						paddingTop: "5px",
						paddingBottom: userLogs.length - 1 === i && "40px"
					}}
				>
					<div style={{ width: "40px" }}>
						{checkValue(log.amount) ? (
							<BalanceUpIcon stroke={ColorScheme.green} />
						) : (
							<BalanceDownIcon stroke={ColorScheme.red} />
						)}
					</div>

					<div style={{ width: "calc(100% / 2 - 15px)" }}>
						<Typography>
							{new Date(log.created_at).toLocaleString()}
						</Typography>
					</div>

					<div
						style={{
							width: "calc(100% / 2 - 40px)",
							textAlign: "right"
						}}
					>
						<Typography fontWeight="700">
							{checkValue(log.amount) ? "+" : ""}
							{log.amount.toFixed(2)} €
						</Typography>
					</div>
				</div>
			))
	);
};
