import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../Spinner";
import { Typography } from "@mui/material";

export const SaveAnimationButton = ({ loadingButton, textContent }) => {
	const { t } = useTranslation();

	return loadingButton ? (
		<Spinner small={true} />
	) : textContent !== undefined ? (
		<Typography fontSize={"12px"}>{t(textContent)}</Typography>
	) : (
		<Typography fontSize={"12px"}>{t("buttons.save")}</Typography>
	);
};
