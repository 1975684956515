import { Drawer, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
/* import { isMobile } from "react-device-detect";
import { ApiContext } from "../ApiContext"; */
import { CalendarDrawer } from "../Calendar/CalendarDrawer";
import { ContinuousReservationList } from "../Calendar/ContinuousReservationList";
import { ExcludedTimesList } from "../Calendar/ExcludedTimesList";
import { SelectedSlots } from "../Calendar/SelectedSlots";
import { AddUserCampaign } from "../Campaign/AddUserCampaign";
import { CreateCampaign } from "../Campaign/CreateCampaign";
import { ChargerForm } from "../Charging/ChargerForm";
import { ChargerLogs } from "../Charging/ChargerLogs";
import { OrderDrawerContent } from "../Clients/OrderDrawerContent";
/* import { CalendarDrawerContent } from "../Calendar/CalendarDrawerContent";*/
import { AddCourse } from "../Courses/AddCourse";
import { CourseUserList } from "../Courses/CourseUserList";
import { AddNewDoor } from "../Doors/AddNewDoor";
import { AddNewUser } from "../Locations/Doors/AddNewUser";
import { DoorTimeTable } from "../Locations/Doors/DoorTimeTable";
import { DoorUserContent } from "../Locations/Doors/DoorUserContent";
import { LocationUsersList } from "../Locations/Form/Location/LocationUsersList";
import { ModifyService } from "../Locations/Form/ModifyService2";
import { LocationDoorLogs } from "../Locations/List/LocationDoorLogs";
import { UserContext } from "../UserContext";
import { CloseButton } from "./CloseButton";
import ScreenWidth from "../../Helpers/ScreenWidth";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop } from "react-device-detect";
import { NewBalance } from "../Balances/NewBalance";

import { BalanceDialog } from "../Balances/BalanceDialog";
import { useTranslation } from "react-i18next";

export const MuiDrawer = ({
	open,
	handleDrawerClose,
	contentType,
	content,
	data,
	location,
	modifyService,
	services,
	info,
	handleCourseUpdate,
	passFieldRefresh,
	passRefreshReservation, // check and rework all props,
	passRefresh
}) => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);
	/* const [isLoading, setIsLoading] = useState(false); */
	const [drawerName, setDrawerName] = useState("");

	const isMobile = ScreenWidth();

	/* const width = isMobile ? "100%" : "calc(100vw - 65%)"; */

	const checkContentType =
		contentType === "calendar" ? "persistent" : "temporary";

	const handleClose = (event) => {
		event?.stopPropagation();
		event?.stopPropagation();

		if (
			contentType === "continuousReservations" ||
			contentType === "ExcludedTimes"
		)
			passRefreshReservation();

		handleDrawerClose(); // Use event.stopPropagation() in other component to prevent event bubbling
	};

	const titleText = () => {
		switch (contentType) {
			case "new_balance":
				return t("balances.create_new_balance");
			case "calendar":
				return t("calendar.modify_reservation");
			case "selectedSlots":
				return t("reservations.create_new_reservation_label");
			case "doorTimetable":
				return t("doors.timetable");
			case "listLocationUsers":
				return t("location.form_location_users_label");
			case "doorLogs":
				return t("doors.show_door_logs");
			case "chargerLogs":
				return t("charging.charger_logs_label");
			case "locationServices":
				if (modifyService && data.type === 0 && content === 1)
					return t("services.edit_reservable_space");
				else if (modifyService && data.type === 3)
					return t("services.edit_key_management_space");
				else if (!modifyService && data.length === 0 && content === 1)
					return t("services.add_reservable_space");
				else if (
					modifyService &&
					data.type !== 0 &&
					data.type !== 3 &&
					content === 2
				)
					return t("services.edit_additional_reservable");
				else if (
					!modifyService &&
					data.type !== 0 &&
					data.type !== 3 &&
					content === 2
				)
					return t("services.add_additional_reservable");
				break;
			case "ListDoorUsers":
				return t("doors.door_users_list");
			case "AddDoorUser":
				return t("doors.add_user");
			case "AddCourse":
				return data === undefined
					? t("courses.create_new_course_label")
					: t("courses.edit_course_label");
			case "AddDoor":
				return t("doors.edit_door_data");
			case "AddCharger":
				return t("charging.charger_settings_label");
			case "ListCourseUsers":
				return t("courses.course_add_new_user_label");
			case "continuousReservations":
				return t("calendar.continuous_reservations_label");
			case "ExcludedTimes":
				return t("calendar.space_reservations_label");
			case "NewCampaign":
				return t("campaign.create_campaign_label");
			case "campaignEdit":
				return t("campaign.edit_campaign_label");
			case "AddUserCampaign":
				return t("campaign.add_new_user_to_campaign_label");
			case "order":
				return t("orders.order_contents_label");
			default:
				if (modifyService && content === 1)
					return t("services.edit_reservable_space");
				else if (modifyService && content === 2)
					return t("services.edit_additional_reservable");
				else if (!modifyService && content === 1)
					return t("services.add_reservable_space");
				else if (!modifyService && content === 2)
					return t("services.add_additional_reservable");
				else return ""; // TODO if this triggers close drawer to force user to try again?
		}
	};

	/* const handleReservationRemove = () => {
		setIsLoading(true);
		const route = `client/remove_reservable/${data.id}`;

		ApiCall("POST", route, null)
			.then(() => {
				handleDrawerClose();
				passFieldRefresh();
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setIsLoading(false);
			});
	}; */

	/* console.log(
		content,
		data,
		location,
		modifyService,
		services,
		info,
		handleCourseUpdate,
		passFieldRefresh,
		passRefreshReservation, // check and rework all props,
		passRefresh
	);
 */
	useEffect(() => {
		if (open) handleDrawerClose();
	}, [selectedLocation]);

	useEffect(() => {
		setDrawerName(
			data?.name ||
				content?.door_name ||
				content?.charge_point_id ||
				info?.name ||
				selectedLocation?.name ||
				""
		);
	}, [data, content, selectedLocation]);

	return (
		<Drawer
			open={open}
			anchor="right"
			onClose={(event) => handleClose(event)}
			variant={checkContentType}
			slotProps={{
				backdrop: {
					invisible: true
				}
			}}
			PaperProps={{
				sx: {
					/* width, */
					maxWidth: isMobile ? "100%" : "600px",
					width: "600px",
					flexShrink: 0,
					backgroundColor: "white",
					overflowY: "auto",
					boxSizing: "border-box",
					padding: "25px"
				}
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				<CloseButton closeDrawer={handleClose} />
				{/* contentType === "locationServices" &&
					Object.keys(data).length > 0 && ( // checks that reservable space exists before render
						<ConfirmAction
							buttonText={t("buttons.remove_reservable_space")}
							handleConfirmAction={handleReservationRemove}
							isLoading={isLoading}
						/>
					)
		*/}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "end"
					}}
				>
					<Typography
						sx={{
							fontSize: "30px",
							color: ColorScheme.drawerTitle,
							fontWeight: "600",
							textAlign: isDesktop ? undefined : "right"
						}}
					>
						{titleText()}
					</Typography>
					<Typography
						sx={{
							fontSize: "20px",
							color: "black",
							fontWeight: "600",
							lineHeight: "1"
						}}
					>
						{drawerName}
					</Typography>
				</div>
			</Stack>

			<div style={{ minHeight: "35px" }} />

			<div
				style={{
					// display: "table",
					// height: "100%",
					// border: "1px solid rgba(0, 0, 0, 0.07)",
					borderRadius: "4px",
					// margin: "20px",
					// padding: "50px",
					backgroundColor: "white",
					height: "100%"
				}}
			>
				<div style={{ height: "100%", width: "100%" }}>
					{contentType === "selectedSlots" && (
						<SelectedSlots
							content={content}
							closeDrawer={handleDrawerClose}
							info={info}
							passRefreshReservation={passRefreshReservation}
						/>
					)}
					{contentType === "calendar" && (
						<CalendarDrawer
							closeDrawer={handleDrawerClose}
							content={content}
							passRefreshReservation={passRefreshReservation}
						/>
					)}
					{contentType === "doorTimetable" && (
						<DoorTimeTable content={content} />
					)}
					{contentType === "listLocationUsers" && (
						<LocationUsersList />
					)}
					{contentType === "doorLogs" && (
						<LocationDoorLogs doorData={content} />
					)}
					{contentType === "chargerLogs" && (
						<ChargerLogs data={content} />
					)}
					{contentType === "locationServices" && (
						<ModifyService
							info={info}
							data={data}
							oldService={modifyService}
							content={content}
							services={services}
							handleDrawerClose={handleDrawerClose}
							fieldRefresh={passFieldRefresh}
						/>
					)}
					{contentType === "ListDoorUsers" && (
						<DoorUserContent info={content} />
					)}
					{contentType === "AddDoorUser" && (
						<AddNewUser info={content} />
					)}
					{contentType === "AddCourse" && (
						<AddCourse
							handleDrawerClose={handleDrawerClose}
							location={location}
							data={data}
							handleCourseViewUpdate={handleCourseUpdate}
						/>
					)}
					{contentType === "AddDoor" && (
						<AddNewDoor
							handleDrawerClose={handleDrawerClose}
							content={content}
						/>
					)}
					{contentType === "AddCharger" && (
						<ChargerForm
							handleDrawerClose={handleDrawerClose}
							data={content}
							fetchChargers={passRefresh}
						/>
					)}
					{contentType === "ListCourseUsers" && (
						<CourseUserList
							handleDrawerClose={handleDrawerClose}
							data={data}
						/>
					)}
					{contentType === "continuousReservations" && (
						<ContinuousReservationList
							handleDrawerClose={handleDrawerClose}
							data={data}
							passRefresh={passRefreshReservation}
						/>
					)}
					{contentType === "ExcludedTimes" && (
						<ExcludedTimesList
							handleDrawerClose={handleDrawerClose}
							data={data}
						/>
					)}
					{(contentType === "NewCampaign" ||
						contentType === "campaignEdit") && (
						<CreateCampaign
							handleDrawerClose={handleDrawerClose}
							triggerRefresh={passRefresh}
							campaignData={
								contentType === "campaignEdit" && data
							}
						/>
					)}
					{contentType === "AddUserCampaign" && (
						<AddUserCampaign
							data={data}
							handleRefresh={passRefresh}
						/>
					)}
					{contentType === "order" && (
						<OrderDrawerContent
							data={data}
							handleDrawerClose={handleDrawerClose}
						/>
					)}
					{contentType === "new_balance" && (
						<NewBalance handleDrawerClose={handleDrawerClose} />
					)}

					{contentType === "user_balance_mobile" && (
						<BalanceDialog
							data={data}
							isMobile
							handleClose={handleClose}
						/>
					)}
				</div>
			</div>
		</Drawer>
	);
};
