import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { ReactComponent as TimeIcon } from "../../../../Assets/Icons/TimerIcon.svg";

import { TextFieldInput2 } from "../../../Common/TextFieldInput2";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { UnitSelect } from "../../../Common/ReservationTimes";
import { useTranslation } from "react-i18next";

/**
 * Represents a component for selecting a time limit.
 * @param {Object} props - The component props.
 * @param {number} props.passDuration - The initial time duration in minutes.
 * @param {Function} props.passTimeData - The callback function to pass the selected time duration.
 * @returns {JSX.Element} The rendered component.
 */

export const TimeLimit = ({ passDuration, passTimeData }) => {
	const { t } = useTranslation();

	const [timeAmount, setTimeAmount] = useState(0);
	const [timeSelect, setTimeSelect] = useState(1);

	const GetMinutes = () => {
		// For the timeAmount value to be converted back and forth correctly
		switch (timeSelect) {
			case 1:
				return 1;
			case 2:
				return 60;
			case 3:
				return 1440;
			case 4:
				return 10080;
			case 5:
				return 43830;
			case 6:
				return 525960;
			default:
				break;
		}
	};

	useEffect(() => {
		let tempTimeAmount = (timeAmount || passDuration) / GetMinutes();

		// Check if the number has multiple decimals
		if (tempTimeAmount % 1 !== 0) {
			tempTimeAmount = tempTimeAmount.toFixed(2);
		}

		// Check if the number has two trailing zeros after the decimal point
		if (tempTimeAmount.toString().endsWith(".00")) {
			tempTimeAmount = parseInt(tempTimeAmount);
		}

		setTimeAmount(tempTimeAmount); // returns either even number without decimals or number with two decimals instead of multiple decimals.
	}, [timeSelect]);

	useEffect(() => {
		passTimeData(Number(timeAmount * GetMinutes()).toFixed(2));
	}, [timeAmount, timeSelect]);

	useEffect(() => {
		// timeAmount was not defined on the first render

		const selectTimeUnit = () => {
			const time = timeAmount || passDuration;

			if (time % (365 * 24 * 60) === 0) {
				// 1 year or more
				return 6;
			} else if (time % (30 * 24 * 60) === 0) {
				// less than 1 year
				return 5;
			} else if (time % (7 * 24 * 60) === 0) {
				// less than 1 month
				return 4;
			} else if (time % (24 * 60) === 0) {
				// less than 1 week
				return 3;
			} else if (time % (3 * 60) === 0) {
				// less than 3 hours
				return 1;
			} else if (time % 60 === 0) {
				// less than 1 day
				return 2;
			} else {
				return 1;
			}
		};

		setTimeSelect(selectTimeUnit()); // Convert to your timeSelect values
		setTimeAmount(passDuration || 0);
	}, []);

	return (
		<TextFieldInput2
			fullWidth
			title={t("product.time_limit_label")}
			text={timeAmount}
			onlyNumbers={true}
			hasProps={<TimeIcon stroke={ColorScheme.defaultIconColor} />}
			valueChange={setTimeAmount}
			endAdornment={
				<Select value={timeSelect} style={{ minWidth: "120px" }}>
					{UnitSelect.map((time) => (
						<MenuItem
							key={time.value}
							value={time.value}
							onClick={() => setTimeSelect(time.value)}
						>
							{time.title}
						</MenuItem>
					))}
				</Select>
			}
		/>
	);
};
