import {
	Card,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import React, { useContext, useEffect, useState } from "react";

import { ApiContext } from "../../ApiContext";
import { UserContext } from "../../UserContext";
import { isMobile } from "react-device-detect";
import { ColorScheme } from "../../../Themes/ColorScheme";
import { Spinner } from "../../Common/Spinner";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip);
// ChartJS.overrides.doughnut.plugins.legend.display = false;

export const UsageWidget = ({ reservable }) => {
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	const [range, setRange] = useState("");
	const [field, setField] = useState("");
	const [fieldID, setFieldID] = useState("");

	const [isClass, setIsClass] = useState(true);
	const [showChart, setShowChart] = useState(false);
	const [showEmpty, setShowEmpty] = useState(false);
	const [isLoading /* setIsLoading */] = useState(false);
	const [filteredData, setFilteredData] = useState([]);

	const [reservableClasses, setReservableClasses] = useState([]);
	const [reservableFields, setReservableFields] = useState([]);
	const [reservables, setReservables] = useState([]);

	const dateRanges = [
		{ label: t("report.usage_chart_menu_today"), value: "day" },
		/* { label: "Tällä viikolla", value: "week" }, */
		{ label: t("report.usage_chart_menu_current_month"), value: "month" },
		{ label: t("report.usage_chart_menu_current_year"), value: "year" }
	];

	const parseReservableFields = () => {
		const formatFields = reservables.map((entry) => ({
			name: entry.name,
			id: entry.id,
			service_class_id: entry.service_class_id,
			name_en: entry.service_class.name_en
		}));

		setReservableFields(formatFields);
	};

	const parseReservableClasses = () => {
		const uniqueClasses = reservables
			.filter(
				(entry, index, self) =>
					index ===
					self.findIndex(
						(t) => t.service_class_id === entry.service_class_id
					)
			)
			.sort((a, b) => a.service_class.name_en - b.service_class.name_en) // wont work right if translated after sorting
			.map((entry) => ({
				name: entry.service_class.name_en,
				service_class_id: entry.service_class_id
			}));

		setReservableClasses(uniqueClasses);
		parseReservableFields();
	};

	const mapRanges = dateRanges.map((entry, index) => (
		<MenuItem
			key={index}
			value={entry.value}
			onClick={() => {
				setRange(entry.value);
			}}
		>
			{entry.label}
		</MenuItem>
	));

	const totalReservations = filteredData.reduce(
		(total, value) => total + value.reservations,
		0
	);

	const maxReservations = filteredData.reduce(
		(total, value) => total + value.reservable_times,
		0
	);

	const reservedMinutes = filteredData.reduce(
		(total, value) => total + value.reserved_minutes,
		0
	);

	const unreservedMinutes = filteredData.reduce(
		(total, value) => total + value.unreserved_minutes,
		0
	);

	const data = {
		labels: [
			t("report.usage_chart_reserved_label"),
			t("report.usage_chart_reservable_label")
		],
		datasets: [
			{
				data:
					filteredData === null
						? [0, 100 - 0]
						: [
								totalReservations,
								maxReservations - totalReservations
							],
				reservations:
					filteredData === null ||
					isNaN(
						reservedMinutes / (reservedMinutes + unreservedMinutes)
					)
						? "0.0"
						: reservedMinutes /
							(reservedMinutes + unreservedMinutes),
				label: "%",
				backgroundColor: ["#4796E3", "#1C568F"],
				borderColor: ["#4796E3", "#1C568F"],
				borderWidth: "2"
			}
		]
	};

	const options = {
		cutout: "85%",
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				titleFont: {
					size: 28
				},
				bodyFont: {
					size: 24
				}
			}
		}
	};

	const textInDonut = {
		id: "textInDonut",
		beforeDatasetsDraw(chart /* args, pluginOptions */) {
			const { ctx, data } = chart;

			ctx.save();
			ctx.font = "900 2em Sofia Sans";
			ctx.fillStyle = "black";
			ctx.textAlign = "center";
			ctx.textBaseline = "middle";
			ctx.fillText(
				(data.datasets[0].reservations * 100).toFixed(1) +
					data.datasets[0].label,
				chart.getDatasetMeta(0).data[0].x + 5,
				chart.getDatasetMeta(0).data[0].y + 5
			);
		}
	};

	const handleClick = (props) => {
		setField(props[0]);
		setFieldID(props[1]);
	};

	const fetchReservableData = () => {
		ApiCall(
			"GET",
			`location/get_reservables/${selectedLocation.location_id}`,
			null
		)
			.then((res) => {
				setReservables(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchData = () => {
		if (range === "" || fieldID === "") return;

		setShowChart(false);

		const currentDate = new Date().getDay().toString().padStart(2, "0"); // with leading zero
		/* const currentWeek = moment().week(); */
		const currentMonth = (new Date().getMonth() + 1)
			.toString()
			.padStart(2, "0"); // with leading zero
		const currentYear = new Date().getFullYear();

		ApiCall(
			"GET",
			`client/statistics/${
				selectedLocation.location_id
			}?type=reservation&${
				isClass ? "class" : "reservable"
			}=${fieldID}&from=${currentYear}-${
				range === "month" || range === "day" ? currentMonth : "01"
			}-${range === "day" ? currentDate : "01"}&to=${currentYear}-${
				range === "month" || range === "day" ? currentMonth : "12"
			}-${range === "day" ? currentDate : "31"}`, // todo
			null
		)
			.then((res) => {
				setFilteredData(res);

				if (res.length === 0) {
					setShowEmpty(true);
				} else {
					setShowEmpty(false);
				}

				setShowChart(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		// lets set default select

		if (reservableClasses.length > 0) {
			const number = reservableClasses.findIndex(
				(entry) => entry?.name === reservable
			);

			setField(reservableClasses[number]?.name);
			setFieldID(reservableClasses[number]?.service_class_id);
		}
	}, [reservableFields]);

	useEffect(() => {
		if (range === "") setRange("month");
	}, [mapRanges]);

	useEffect(() => {
		if (reservables.length > 0) parseReservableClasses();
	}, [reservables]);

	useEffect(() => {
		fetchData();
	}, [range, fieldID]);

	useEffect(() => {
		fetchReservableData();
	}, []);

	return (
		<Card
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				/* justifyContent: "space-between", */
				height: "370px",
				width: "100%",
				/* maxHeight: "300px", */
				minHeight: "420px",
				minWidth: isMobile ? undefined : "430px",
				maxWidth: isMobile ? "100%" : "430px",
				padding: "25px",
				borderRadius: "4px",
				boxSizing: "border-box"
			}}
		>
			<Stack
				direction="row"
				justifyContent="space-between"
				gap="20px"
				width="100%"
			>
				<Select
					variant="standard"
					value={fieldID}
					disableUnderline
					sx={{
						width: "50%",
						minWidth: "100px",
						"& .MuiSvgIcon-root": {
							right: "unset",
							left: "7px"
						}
					}}
					renderValue={(value) => {
						// find the name of the selected class or field instead of id number
						const IDtoName = (
							reservableClasses.find(
								(entry) => entry.service_class_id === value
							) ||
							reservableFields.find((entry) => entry.id === value)
						)?.name;

						return (
							<>
								{isLoading ? (
									<Skeleton
										variant="text"
										animation="wave"
										height="24px"
										width="64px"
									/>
								) : (
									<div
										style={{
											position: "relative",
											left: "30px"
										}}
									>
										{isClass
											? t(`reservables.${IDtoName}`)
											: IDtoName}
									</div>
								)}
							</>
						);
					}}
				>
					{reservableClasses.map((entry) => {
						const classMenuItem = (
							<MenuItem
								key={entry.name}
								value={entry.service_class_id}
								selected={isClass && entry.name === field}
								onClick={() => {
									setIsClass(true);
									handleClick([
										entry.name,
										entry.service_class_id
									]);
								}}
								sx={{
									fontWeight: "700",
									":hover": {
										backgroundColor:
											ColorScheme.tolotechLightBlue
									}
								}}
							>
								{t(`reservables.${entry.name}`)}
							</MenuItem>
						);

						const fieldMenuItems = reservableFields
							.filter(
								(field) =>
									field.service_class_id ===
									entry.service_class_id
							)
							.map((field) => (
								<MenuItem
									key={field.id}
									value={field.id}
									sx={{
										marginLeft: "10px",
										fontWeight: "300",
										fontSize: "14px",
										":hover": {
											backgroundColor:
												ColorScheme.tolotechLightBlue
										}
									}}
									selected={!isClass && field.id === fieldID}
									onClick={() => {
										setIsClass(false);
										handleClick([field.name, field.id]);
									}}
								>
									{field.name}
								</MenuItem>
							));

						return [classMenuItem, ...fieldMenuItems];
					})}
				</Select>

				<Select
					variant="standard"
					disableUnderline
					value={range}
					sx={{
						width: "50%",
						minWidth: "100px",
						textAlign: "right"
					}}
				>
					{mapRanges}
				</Select>
			</Stack>

			<div style={{ flex: 1 }} />

			{showChart ? (
				<Stack
					alignItems="center"
					justifyContent="space-between"
					sx={{ height: "100%", flex: 1 }}
				>
					{showEmpty ? (
						<Typography textAlign="center">
							{t("report.no_reservables_found")}
						</Typography>
					) : (
						<Doughnut
							data={data}
							plugins={[textInDonut]}
							options={options}
							style={{
								maxHeight: "250px",
								maxWidth: "250px",
								padding: "25px"
							}}
						/>
					)}

					<Typography
						variant="h6"
						fontWeight="bold"
						textAlign="center"
					>
						{t("report.usage_chart_reservation_total_begin")}
						{totalReservations}
						{t("report.usage_chart_reservation_total_end")}
					</Typography>
				</Stack>
			) : (
				<>
					<div
						style={{
							display: "flex",
							height: "100%",
							justifyContent: "center",
							alignContent: "center"
						}}
					>
						<Spinner />
					</div>
					<div style={{ flex: 1 }} />
				</>
			)}

			{/* <div style={{ flex: 1 }} /> */}
		</Card>
	);
};
