import React, { useContext, useEffect } from "react";
import { CalendarComponent } from "./CalendarComponent";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";
import { permissionChecker } from "../../Helpers/permissionChecker";
import { useLocation, useNavigate } from "react-router-dom";

export const CalendarContent = () => {
	const { handleTitle, selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();

	const selectPermission = location.pathname === "/residents" ? 8 : 2; // We need to check different permission_id if url route is different

	const hasPermission = permissionChecker({
		selectedLocation,
		permission: selectPermission
	});

	if (!hasPermission) navigate("/locations");

	useEffect(() => {
		handleTitle(t("calendar.calendar_full_name"));
	}, []);

	return <CalendarComponent />;
};
