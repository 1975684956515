import {
	Button,
	Card,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const ContactForm = () => {
	const { t } = useTranslation();
	const handleChange = () => {
		console.log("testi");
	};

	return (
		<Grid item xs={6}>
			<Stack spacing={2}>
				<Typography>{t("contact.contact_title_label")}</Typography>
				<FormControl fullWidth>
					<Typography>
						{t("contact.contact_select_description")}
					</Typography>
					<br />
					<Card>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t("contact.contact_checkbox_new")}
									/>
								}
								label={t("contact.contact_checkbox_new")}
							/>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t(
											"contact.contact_checkbox_tologo_tracking"
										)}
									/>
								}
								label={t(
									"contact.contact_checkbox_tologo_tracking"
								)}
							/>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t(
											"contact.contact_checkbox_tologo_reservation"
										)}
									/>
								}
								label={t(
									"contact.contact_checkbox_tologo_reservation"
								)}
							/>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t(
											"contact.contact_checkbox_licence_plate_identifier"
										)}
									/>
								}
								label={t(
									"contact.contact_checkbox_licence_plate_identifier"
								)}
							/>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t(
											"contact.contact_checkbox_tologo_manual"
										)}
									/>
								}
								label={t(
									"contact.contact_checkbox_tologo_manual"
								)}
							/>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t(
											"contact.contact_checkbox_resident_guide"
										)}
									/>
								}
								label={t(
									"contact.contact_checkbox_resident_guide"
								)}
							/>
							<FormControlLabel
								control={
									<Checkbox
										// checked={}
										onChange={handleChange}
										name={t(
											"contact.contact_checkbox_other"
										)}
									/>
								}
								label={t("contact.contact_checkbox_other")}
							/>
						</FormGroup>
					</Card>
				</FormControl>

				<Typography>
					{t("contact.contact_checkbox_description")}
				</Typography>
				<TextField
					label={t(
						"contact.contact_checkbox_description_free_speech"
					)}
					fullWidth
				/>
				<Button variant="contained">{t("buttons.send")}</Button>
			</Stack>
		</Grid>
	);
};
