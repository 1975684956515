import React, { useContext, useEffect, useState } from "react";
import {
	Stack,
	Typography,
	Box,
	TextField,
	MenuItem,
	Collapse
} from "@mui/material";
import { ApiContext } from "../../../ApiContext";
import { UserContext } from "../../../UserContext";
import { useTranslation } from "react-i18next";
import { AlertBar } from "../../../Common/AlertBar";
import { isMobile } from "react-device-detect";
import { CustomizeButton } from "./Components/CustomizeButton";
import { FormEdit } from "./Components/FormEdit";
import { FormCancel } from "./Components/FormCancel";
import { FormSave } from "./Components/FormSave";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import BillingInformationDialog from "./Components/BillingInformationDialog";

import OPERATORS from "./Data/OperatorList.json";

export const BillingDetail = ({ data, refreshData }) => {
	const debug = false;

	const [editMode, setEditMode] = useState(false);
	const [newBusinessId, setNewBusinessId] = useState("");
	const [newCustomerName, setNewCustomerName] = useState("");
	const [newInvoiceOperator, setNewInvoiceOperator] = useState("");
	const [newInvoiceReference, setNewInvoiceReference] = useState("");
	const [newInvoiceAddress, setNewInvoiceAddress] = useState("");
	const [billingId, setBillingId] = useState("");
	const [showError, setShowError] = useState(false);

	const [contactPersonName, setContactPersonName] = useState("");
	const [contactPersonEmail, setContactPersonEmail] = useState("");

	const [showBillingDialog, setShowBillingDialog] = useState(false);
	const [enableAutoFetch, setEnableAutoFetch] = useState(false);
	const [hover, setHover] = useState(false);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const { selectedLocation } = useContext(UserContext);

	const checkBusinessId = (id) => {
		if (newCustomerName !== "") {
			// override if customer name is not empty and try to fetch data from database
			setEnableAutoFetch(true);
			return;
		}

		// regex check for business id format (7 numbers, 1 char)
		// control char is calculated by multiplying each number with weight and summing them
		// sum is divided by 11 and remainder is calculated
		// if remainder is 0 or 1, control char must be 0
		// otherwise control char is calculated by subtracting remainder from 11
		// if calculated control char matches control char in id, id is valid
		// otherwise id is invalid

		const regex = /^[0-9]{7}-[0-9A-F]$/;

		// console.log(id, regex.test(id));

		if (regex.test(id)) {
			const numbers = id.split("-")[0]; // get first 7 numbers
			const controlChar = id.split("-")[1]; // get last 1 char
			const weights = [7, 9, 10, 5, 8, 4, 2]; // weights for each number
			let sum = 0; // sum of numbers * weights

			for (let i = 0; i < numbers.length; i++) {
				// multiply each number with weight and sum them
				sum += numbers[i] * weights[i];
			}

			let remainder = sum % 11; // divide sum by 11 and get remainder

			if (remainder === 0 || remainder === 1) {
				if (controlChar === "0") {
					setEnableAutoFetch(true);
				} else {
					setEnableAutoFetch(false);
				}
			} else {
				let calculatedChar = 11 - remainder; // subtract remainder from 11
				if (
					calculatedChar < 10 &&
					calculatedChar === parseInt(controlChar)
				) {
					// if calculated char matches control char
					setEnableAutoFetch(true);
				} else {
					setEnableAutoFetch(false);
				}
			}
		} else {
			setEnableAutoFetch(false);
		}
	};

	const cancelAction = () => {
		// reset all fields to original values

		if (selectedLocation.billing !== undefined) {
			setNewBusinessId(data.billing.business_id || "");
			setNewCustomerName(data.billing.customer_name || "");
			setNewInvoiceOperator(data.billing.invoice_operator || "");
			setNewInvoiceReference(data.billing.invoice_reference || "");
			setNewInvoiceAddress(data.billing.invoice_address || "");
			setContactPersonEmail(data.billing.contact_email || "");
			setContactPersonName(data.billing.contact_name || "");
		}
		setEditMode(!editMode);
	};

	const handleSave = () => {
		// save changes to database

		if (billingId === "") {
			cancelAction();
			setShowError(true);
			return;
		}

		ApiCall("PUT", `location/${data.location_id}/billing`, {
			business_id: newBusinessId,
			customer_name: newCustomerName,
			invoice_reference: newInvoiceReference,
			invoice_operator: newInvoiceOperator,
			invoice_address: newInvoiceAddress,
			billing_id: billingId,
			contact_name: contactPersonName,
			contact_email: contactPersonEmail
		})
			.then(() => {
				setEditMode(!editMode);
				refreshData();
				window.location.reload(false);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	const findBrokerData = (props) => {
		// backup method to find broker data manually if automatic fetch fails for some reason

		const check = OPERATORS.find((entry) => entry.broker_id === props);

		if (check) setNewInvoiceOperator(check.broker_id);
	};

	function handleAutomaticBillingData(data) {
		setNewCustomerName(data.customer_name);
		setNewBusinessId(data.business_id);
		setNewInvoiceOperator(data.invoice_operator);
		setNewInvoiceAddress(data.invoice_address);
		setShowBillingDialog(false);
	}

	useEffect(() => {
		// set initial values for fields

		setShowError(false);
		setEditMode(false);
		setNewBusinessId("");
		setNewCustomerName("");
		setNewInvoiceReference("");
		setNewInvoiceOperator("");
		setNewInvoiceAddress("");
		setBillingId("");
		setContactPersonEmail("");
		setContactPersonName("");

		if (selectedLocation.billing !== undefined) {
			// set values from database

			setNewBusinessId(data.billing.business_id || "");
			setNewCustomerName(data.billing.customer_name || "");
			setNewInvoiceReference(data.billing.invoice_reference || "");
			setNewInvoiceOperator(data.billing.invoice_operator || "");
			setNewInvoiceAddress(data.billing.invoice_address || "");
			setBillingId(data.billing.billing_id || "");
			setContactPersonEmail(data.billing.contact_email || "");
			setContactPersonName(data.billing.contact_name || "");
		}
	}, [selectedLocation]);

	useEffect(() => {
		checkBusinessId(newBusinessId);
	}, [newBusinessId]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				padding: "15px",
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px",
				borderRadius: "4px"
			}}
		>
			<div
				style={{
					width: "100%",

					flexDirection: "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start"
				}}
			>
				<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
					<Typography variant="form_text_label">
						{t("billing.form_billing_details_label")}:
					</Typography>
				</div>

				<Stack
					spacing={1}
					sx={{
						width: "100%",
						paddingTop: "3px" // kohdistetaan laskutustiedot ja nimi-kenttä samalle tasolle
					}}
				>
					<Collapse
						in={
							editMode &&
							(newBusinessId !== "" || newCustomerName !== "")
						}
					>
						<AlertBar
							type="info"
							textContent={t("billing.billing_data_fetch_guide")}
							showAlert={true}
							whiteSpacePreLine={true}
						/>
						<div
							onClick={() =>
								setShowBillingDialog(!showBillingDialog)
							}
							onMouseEnter={() => {
								setHover(true);
							}}
							onMouseLeave={() => {
								setHover(false);
							}}
							style={{
								height: "50px",
								width: "100%",
								backgroundColor: !enableAutoFetch
									? ColorScheme.tolotechTransparentWhite
									: hover
										? ColorScheme.tolotechDarkBlue
										: ColorScheme.tolotechBlue,

								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								cursor: enableAutoFetch ? "pointer" : "default",
								transition: "background-color 0.2s ease"
							}}
						>
							<Typography
								variant="form_text"
								color={
									!enableAutoFetch && ColorScheme.brightGrey
								}
							>
								{t("billing.get_billing_data_automatically")}
							</Typography>
						</div>
						<BillingInformationDialog
							businessId={newBusinessId}
							customerName={newCustomerName}
							passBillingData={handleAutomaticBillingData}
							open={showBillingDialog}
							setOpen={setShowBillingDialog}
						></BillingInformationDialog>
					</Collapse>

					<div style={{ height: "20px" }} />

					{editMode ? (
						<FormEdit
							title={t("billing.form_client_name_label")}
							value={newCustomerName}
							changeData={(value) => setNewCustomerName(value)}
							isRequired={true}
						/>
					) : (
						<Typography variant="form_text">
							{newCustomerName}
						</Typography>
					)}

					{editMode ? (
						<FormEdit
							title={t("billing.form_business_code_label")}
							value={newBusinessId}
							changeData={(value) => setNewBusinessId(value)}
							onlyNumeric={true}
							type={"tel"}
							isRequired={true}
						/>
					) : (
						<Typography variant="form_text">
							{newBusinessId}
						</Typography>
					)}

					{editMode ? (
						<>
							<Typography>
								{t("location.form_select_broker")}
							</Typography>
							<TextField
								select
								onChange={(selected) => {
									findBrokerData(selected.target.value);
								}}
								// tarvii rajata maxWidth
							>
								{OPERATORS.sort((a, b) =>
									a.name.localeCompare(b.name)
								).map((entry, index) => (
									<MenuItem
										key={index}
										value={entry.broker_id}
									>
										{entry.broker_id} - {entry.name}
									</MenuItem>
								))}
							</TextField>
							<FormEdit
								title={t(
									"billing.form_einvoicing_operator_label"
								)}
								value={newInvoiceOperator}
								changeData={(value) =>
									setNewInvoiceOperator(value)
								}
								isRequired={true}
							/>
						</>
					) : (
						<Typography variant="form_text">
							{newInvoiceOperator}
						</Typography>
					)}
					{editMode ? (
						<FormEdit
							title={t("billing.form_einvoicing_address_label")}
							value={newInvoiceAddress}
							changeData={(value) => setNewInvoiceAddress(value)}
							isRequired={true}
						/>
					) : (
						<Typography variant="form_text">
							{newInvoiceAddress}
						</Typography>
					)}
					{editMode ? (
						<FormEdit
							isRequired
							title={t("billing.form_einvoicing_reference_label")}
							value={newInvoiceReference}
							changeData={(value) =>
								setNewInvoiceReference(value)
							}
						/>
					) : (
						<Typography variant="form_text">
							{newInvoiceReference}
						</Typography>
					)}
					{editMode ? (
						<FormEdit
							isRequired
							title={t("billing.form_business_contact_person")}
							value={contactPersonName}
							changeData={(value) => setContactPersonName(value)}
						/>
					) : (
						<Typography variant="form_text">
							{contactPersonName}
						</Typography>
					)}
					{editMode ? (
						<FormEdit
							isRequired
							title={t("billing.form_business_contact_email")}
							value={contactPersonEmail}
							changeData={(value) => setContactPersonEmail(value)}
						/>
					) : (
						<Typography variant="form_text">
							{contactPersonEmail}
						</Typography>
					)}
					{editMode && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<FormSave handleSave={handleSave} />
							<FormCancel cancelAction={cancelAction} />
						</Box>
					)}

					{(newBusinessId === "" ||
						newCustomerName === "" ||
						newInvoiceReference === "" ||
						newInvoiceOperator === "" ||
						newInvoiceAddress === "" ||
						billingId === "" ||
						contactPersonName === "" ||
						contactPersonEmail === "") &&
						!editMode && (
							<Typography>
								{t("billing.no_billing_info")}
							</Typography>
						)}

					<AlertBar
						showAlert={showError}
						type="warning"
						textContent={t("billing.id_not_found")}
					/>
				</Stack>
			</div>
			{!editMode && (
				<CustomizeButton
					changeEditMode={() => setEditMode(!editMode)}
				/>
			)}
		</Stack>
	);
};
