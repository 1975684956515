import { Stack, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";

import { ReactComponent as UserIcon } from "../../Assets/Icons/UserIcon.svg";

import { ManagePassword } from "./ManagePassword";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { ColorScheme } from "../../Themes/ColorScheme";

export const SettingContent = (props) => {
	const [userData /* setUserData */] = useState([]);
	const { t } = useTranslation();

	const deleteAllCookies = () => {
		props.handleClose();

		let cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++)
			// let cookie = cookies[i];
			// let eqPos = cookie.indexOf("=");
			// let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie =
				"webapp-token=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
	};

	return (
		<Stack
			spacing={2}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
				// marginTop: "20px"
			}}
		>
			{isMobile && (
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "15px"
					}}
				>
					<UserIcon />
					<div>
						<Typography
							style={{
								// marginRight: "20px",
								fontSize: "16px",
								fontWeight: "700"
							}}
						>
							{userData.name}
						</Typography>

						<Typography
							style={{
								// marginRight: "20px",
								fontSize: "12px",
								color: "rgb(200,200,200)"
							}}
						>
							{userData.email}
						</Typography>
					</div>
				</div>
			)}

			<ManagePassword />
			<Link
				style={{
					color: "#000000",
					textDecoration: "none",
					width: "95%",
					transition: ".3s all"
				}}
				to={"/login"}
				onClick={() => deleteAllCookies()}
			>
				<Stack
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						paddingTop: "15px",
						paddingBottom: "15px",
						transition: ".3s",
						scale: "1.05",
						border: "2px solid " + ColorScheme.tolotechBlue,
						borderRadius: "5px"
					}}
				>
					<Grid item xs={6}>
						<Typography
							style={{
								fontSize: 12,
								color: ColorScheme.tolotechBlue
							}}
						>
							{t("login.logout")}
						</Typography>
					</Grid>
				</Stack>
			</Link>
		</Stack>
	);
};
