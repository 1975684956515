import { ReactComponent as PlaceholderPlugIcon } from "../../../Assets/Icons/ElectricIcon.svg";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const PowerOutput = ({
	maxPower,
	strokeColor,
	strokeWidth,
	width,
	height
}) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				textAlign: "center",
				position: "relative",
				width: "25%"
			}}
		>
			<PlaceholderPlugIcon
				stroke={strokeColor}
				width={width}
				height={height}
				strokeWidth={strokeWidth / 2}
				style={{
					position: "relative",
					zIndex: 2
				}}
			/>
			<Typography
				style={{
					fontSize: "12px",
					fontWeight: "400",
					color: "grey"
				}}
			>
				{maxPower + " " + t("charging.kw_short")}
			</Typography>
		</div>
	);
};
