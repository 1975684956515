import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Collapse, Stack } from "@mui/material";
import { ApiContext } from "../ApiContext";
import { useTranslation } from "react-i18next";
import { SaveAnimationButton } from "../Common/Inputs/SaveAnimationButton";
import { UserAdd } from "../Common/UserAdd";
import { UserListHandler } from "../Common/UserListHandler";
import { AlertBar } from "../Common/AlertBar";

export const AddUserCampaign = ({ data, handleRefresh }) => {
	const debug = false;

	const { t } = useTranslation();
	const [userContact, setUserContact] = useState("");

	const [userList, setUserList] = useState([]);
	const [showList, setShowList] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);

	const [showFailed, setShowFailed] = useState(false);
	const [listFailed, setListFailed] = useState([]);

	const [showError, setShowError] = useState(null);

	const { ApiCall } = useContext(ApiContext);

	const handleSuccess = () => {
		setLoadingButton(false);
		setUserContact("");
		handleRefresh();
	};

	const addAnotherUser = () => {
		setUserList((prevState) => [...prevState, userContact]);
		setShowList(true);
		setUserContact("");
	};

	const removeUser = (user) => {
		const filtered = userList.filter((entry) => entry !== user);
		setUserList(filtered);
	};

	const removeFailedUser = (user) => {
		const filtered = listFailed.filter((entry) => entry !== user);
		setListFailed(filtered);
	};

	const handleErrors = (list) => {
		setShowList(false); // hide original mailing list
		setShowFailed(true); // display list of failed contacts
		setListFailed(list);
	};

	const handleSave = () => {
		setLoadingButton(true);

		const list = showFailed // if true we resend only previously failed entries (hopefully user managed to correct them)
			? listFailed
			: userList;

		ApiCall("POST", `client/campaign/${data.id}/users`, list)
			.then((response) => {
				if (response.length > 0) handleErrors(response);
				else handleSuccess(response);
				setLoadingButton(false);
				setShowError(null);
			})
			.catch((error) => {
				setLoadingButton(false);
				setShowError(error);
				if (debug) console.log(error);
			})
			.finally(() => {
				setUserList([]);
			});
	};

	const checkDisabled = () => userList.length > 0 || listFailed.length > 0;

	const editFailedList = (index, newValue) => {
		setListFailed((prevList) => {
			const updatedList = [...prevList];
			updatedList[index] = newValue;
			return updatedList;
		});
	};

	useEffect(() => {
		if (listFailed.length === 0) setShowFailed(false);
	}, [listFailed, showFailed]);

	return (
		<Box sx={{ height: "100%", boxSizing: "border-box" }}>
			<Stack
				spacing={2}
				sx={{
					height: "inherit"
				}}
			>
				{/* <DrawerTitle
					location={selectedLocation.name}
					title={t("campaign.add_new_user_to_campaign_label")}
				/> */}

				<UserAdd
					disableInput={showFailed}
					contact={userContact}
					handleContactChange={setUserContact}
					onlyEmail={false}
					placeholder={t("campaign.input_user_contact")}
					handleAnotherUser={addAnotherUser}
				/>

				<UserListHandler
					showList={showList}
					showFailed={showFailed}
					userList={userList}
					listFailed={listFailed}
					removeUser={removeUser}
					editFailedList={editFailedList}
					removeFailedUser={removeFailedUser}
				/>

				{showError !== null && (
					<Collapse in={true}>
						<AlertBar
							type={"error"}
							standardVariant={true}
							textContent={showError}
							whiteSpacePreLine
							showAlert={true}
						/>
					</Collapse>
				)}

				<div style={{ flex: 1 }} />

				<Button
					variant="contained"
					sx={{ width: "100%" }}
					disabled={!checkDisabled()}
					onClick={() => handleSave()}
				>
					<SaveAnimationButton loadingButton={loadingButton} />
				</Button>
			</Stack>
		</Box>
	);
};
