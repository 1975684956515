import { Stack, Typography, Box } from "@mui/material";
import React, { useContext } from "react";
import { CampaignCard } from "./Campaign";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export const ActiveCampaigns = ({
	data,
	passRefresh,
	openDrawer,
	passDrawerData
}) => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					gap: "15px"
				}}
			>
				{!isMobile && data.length !== 0 && (
					<Stack
						direction="row"
						color={"black"}
						sx={{
							alignItems: "end",
							paddingX: "20px"
							// paddingY: "20px"
						}}
					>
						<Stack sx={{ flex: 2 }}>
							<Typography
								sx={{
									fontSize: "10px",
									fontWeight: "700",
									color: "white"
								}}
							>
								{t("campaign.name_label_short")}
							</Typography>
						</Stack>
						<Stack sx={{ flex: 3.8 }}>
							<Typography
								sx={{ color: "white", fontSize: "10px" }}
							>
								{t("campaign.description_label_short")}
							</Typography>
						</Stack>
						<Stack
							sx={{
								flex: 1
							}}
						>
							<Typography
								sx={{ fontSize: "10px" }}
								color={"white"}
							>
								{t("campaign.campaign_start_time_label_short")}
							</Typography>
						</Stack>
						<Stack sx={{ flex: 1 }}>
							<Typography
								sx={{ color: "white", fontSize: "10px" }}
							>
								{t("campaign.campaign_end_time_label_short")}
							</Typography>
						</Stack>

						<Stack sx={{ flex: 1 }}>
							<Typography
								sx={{ color: "white", fontSize: "10px" }}
							>
								{t("campaign.campaign_type_label")}
							</Typography>
						</Stack>
						<Stack sx={{ flex: 1.5 }}>
							<Typography
								sx={{ color: "white", fontSize: "10px" }}
							>
								{t("campaign.campaign_use_limit_label")}
							</Typography>
						</Stack>
						<Stack sx={{ flex: 1.2 }}>
							<Typography
								sx={{ color: "white", fontSize: "10px" }}
							>
								{t("campaign.campaign_code_label")}
							</Typography>
						</Stack>
						<Stack sx={{ flex: 0.5 }}></Stack>
						<Stack sx={{ flex: 0.1 }}></Stack>
					</Stack>
				)}

				{data.length !== 0 ? (
					data.map((client, i) => (
						<CampaignCard
							data={client}
							key={i}
							location={selectedLocation}
							triggerRefresh={passRefresh}
							openDrawer={openDrawer}
							passDrawerData={passDrawerData}
						/>
					))
				) : (
					<Typography
						sx={{
							fontWeight: "700",
							fontSize: "14px",
							color: "rgba(255,255,255,0.5)",
							paddingLeft: "5px"
						}}
					>
						{t("campaign.campaigns_not_found")}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
