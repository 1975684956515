import React, { useEffect, useRef, useState } from "react";
import {
	Stack,
	Dialog,
	CardContent,
	Box,
	Typography,
	IconButton
} from "@mui/material";

import { DoorUserEdit } from "./DoorUserEdit";
import { ConfirmAction } from "../../../Common/ConfirmAction";
import { TextComponent } from "./TextComponent";

import TooltipWrapper from "../../../Common/TooltipWrapper";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { ReactComponent as ToloGoUserIcon } from "../../../../Assets/Icons/ToloGoLogoIcon.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/Icons/EditIcon.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../Assets/Icons/Checkmark.svg";
import { ReactComponent as EndingSoon } from "../../../../Assets/Icons/EndingSoon.svg";
import { ReactComponent as Ended } from "../../../../Assets/Icons/Ended.svg";
import { ReactComponent as PendingIcon } from "../../../../Assets/Icons/Pending.svg";
import { ReactComponent as UserIcon } from "../../../../Assets/Icons/UserIcon.svg";
import { isDesktop } from "react-device-detect";
import { DeleteButton } from "../../../KeyManagement/Buttons/DeleteButton";
import { useTranslation } from "react-i18next";

function getStatusProperty(start, end, property, restricted) {
	const { t } = useTranslation();
	const checkStatus = () => {
		// todo improve this and make it reusable component

		const now = new Date().getTime();
		const startDate = new Date(start).getTime();
		const endDate = new Date(end).getTime();

		if (now < startDate)
			return 4; // starting soon
		else if (startDate > now) {
			return 1; // ending soon
		} else if (endDate < now) {
			return 2; // ended
		} else {
			return 0; // ongoing
		}
	};

	if (property === "main") {
		if (!restricted) return "#75A85D";
		else
			switch (checkStatus()) {
				case 0:
					return "#75A85D";
				case 1:
					return "#ffbc70";
				case 2:
					return "#A85D5D";
				case 3:
					return "#516785";
				default:
					return "#516785";
			}
	} else if (property === "secondary") {
		if (!restricted) return "#DCE9D6";
		else
			switch (checkStatus()) {
				case 0:
					return "#DCE9D6";
				case 1:
					return "#ffe9d1";
				case 2:
					return "#E9D6D6";
				case 3:
					return "#D4D9E0";
				case 4:
					return "#EFCF7C";
				default:
					return "#D4D9E0";
			}
	} else if (property === "icon") {
		if (!restricted)
			return (
				<CheckmarkIcon
					stroke={"#75A85D"}
					strokeWidth={2}
					width={"20px"}
					height={"20px"}
				/>
			);
		else
			switch (checkStatus()) {
				case 0:
					return (
						<CheckmarkIcon
							stroke={"#75A85D"}
							strokeWidth={2}
							width={"20px"}
							height={"20px"}
						/>
					);
				case 1:
					return (
						<EndingSoon
							stroke={"#516785"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
				case 2:
					return (
						<Ended
							stroke={"#A85D5D"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
				case 3:
				case 4:
					return (
						<PendingIcon
							stroke={"#A85D5D"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
				default:
					return (
						<PendingIcon
							stroke={"#A85D5D"}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					);
			}
	} else if (property === "text") {
		if (!restricted) return t("key.ongoing");
		else
			switch (checkStatus()) {
				case 0:
					return !isDesktop ? "" : t("key.ongoing");
				case 1:
					return !isDesktop ? "" : t("key.ends_soon");
				case 2:
					return !isDesktop ? "" : t("key.not_valid");
				case 3:
				case 4:
					return !isDesktop ? "" : t("key.pending");

				default:
					return !isDesktop ? "" : t("key.ongoing");
			}
	}
}

export const DoorUserDesktop = ({ user, handleRemove /* fetchNewData */ }) => {
	const restricted = user.restrict_times;

	const { t } = useTranslation();
	const [, /* toggle */ setToggle] = useState(false);

	// todo - use or remove unused code and states

	const [, /* isEmailOverflowing */ setIsEmailOverflowing] = useState(false);
	const [, /* isDescriptionOverflowing */ setIsDescriptionOverflowing] =
		useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	const [showStatusText /* setShowStatusText */] = useState(false);

	const overflowEmail = useRef(null);
	const overflowDescription = useRef(null);

	/* const timestampFomat = (timestamp, options) =>
		format(new Date(timestamp), options);

	const handleClick = () => {
		setToggle(!toggle);
		if (toggle) fetchNewData(); // fetch only when closing dialog
	}; */

	const overflowCheck = () => {
		const handleResize = () => {
			if (overflowEmail.current)
				setIsEmailOverflowing(
					overflowEmail.current.offsetWidth <
						overflowEmail.current.scrollWidth
				);

			if (overflowDescription.current)
				setIsDescriptionOverflowing(
					overflowDescription.current.offsetWidth <
						overflowDescription.current.scrollWidth
				);
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	};

	useEffect(() => {
		overflowCheck();
	}, [user, overflowEmail, overflowDescription]);

	useEffect(() => {
		if (overflowEmail.current)
			setIsEmailOverflowing(
				overflowEmail.current.offsetWidth <
					overflowEmail.current.scrollWidth
			);

		if (overflowDescription.current)
			setIsDescriptionOverflowing(
				overflowDescription.current.offsetWidth <
					overflowDescription.current.scrollWidth
			);
	}, []);

	return (
		<div style={{ position: "relative" }}>
			<Dialog open={openDialog} onClose={() => setToggle(false)}>
				<DoorUserEdit
					data={user}
					handleClick={() => setOpenDialog(false)}
				/>
			</Dialog>

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				sx={{ padding: "20px", gap: "40px" }}
			>
				<CardContent
					sx={{
						width: "calc(100% - 60px)",
						height: "100%",
						alignContent: "center",
						alignItems: "center",
						justifyContent: "center",
						padding: 0
					}}
				>
					<Stack spacing={1}>
						<Stack direction="row" gap="10px">
							<div>
								{user.email !== "" ? (
									<ToloGoUserIcon
										height={"50px"}
										width={"50px"}
									/>
								) : (
									<div
										style={{
											display: "flex",
											width: "50px",
											height: "50px",
											backgroundColor: "#fff",
											borderRadius: "4px",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<UserIcon stroke="black" />
									</div>
								)}
							</div>
							<Stack
								direction="column"
								justifyContent="space-between"
								alignSelf="center"
								sx={{ width: "100%" }}
							>
								<Stack>
									{user?.name && user.name !== "" && (
										<TextComponent
											fontSize={"16px"}
											fontWeight={"700"}
											text={user?.name || ""}
										/>
									)}

									{user?.email && user.email !== "" && (
										<TextComponent
											fontSize={
												user.name === undefined ||
												user.name === ""
													? "16px"
													: "12px"
											}
											color={
												user.name === undefined ||
												user.name === ""
													? undefined
													: "rgba(0,0,0,0.5)"
											}
											fontWeight={"700"}
											text={user?.email || ""}
										/>
									)}

									{user?.phone === null ||
										(user?.phone !== "" && (
											<TextComponent
												fontSize={
													user.name === undefined ||
													user.name === ""
														? "16px"
														: "12px"
												}
												color={
													user.name === undefined ||
													user.name === ""
														? undefined
														: "rgba(0,0,0,0.5)"
												}
												fontWeight={"700"}
												text={user?.phone || ""}
											/>
										))}

									{user?.license_plate === null ||
										(user?.license_plate === "" && (
											<TextComponent
												fontSize={"12px"}
												color={"rgba(0,0,0,0.5)"}
												fontWeight={"700"}
												text={user?.license_plate || ""}
											/>
										))}

									{!isDesktop && user.pin_code !== "" && (
										<TextComponent
											fontSize={"12px"}
											color={"rgba(0,0,0,0.5)"}
											fontWeight={"700"}
											text={
												t("doors.pin_code_label") +
												": " +
												user.pin_code
											}
										/>
									)}
								</Stack>
							</Stack>
							<Stack
								sx={{
									justifyContent: "center",
									alignItems: "end"
								}}
							>
								{isDesktop &&
									user.pin_code !== "" &&
									user.pin_code !== null &&
									user.pin_code !== undefined && (
										<Box
											sx={{
												alignItems: "center",
												// padding: "5px",
												borderRadius: "4px",
												px: "8px",
												py: "3px",
												backgroundColor:
													ColorScheme.tolotechBlue,
												display: "flex",
												justifyContent: "center",
												width: "80px",
												alignSelf: "end"
											}}
										>
											<Typography
												sx={{
													fontSize: "14px",
													fontWeight: "700",
													color: "white"
												}}
											>
												{t("doors.pin_code_label") +
													": " +
													user.pin_code}
											</Typography>
										</Box>
									)}
							</Stack>
						</Stack>
					</Stack>
				</CardContent>

				<div
					className="key-management-status"
					style={{
						maxWidth: "120px",
						minWidth: "40px",
						position: "absolute",
						right: "20px",
						backgroundColor: getStatusProperty(
							user.access_starts_at,
							user.access_ends_at,
							"secondary",
							restricted
						)
					}}
				>
					<TooltipWrapper
						hide={!showStatusText}
						title={getStatusProperty(
							user.access_starts_at,
							user.access_ends_at,
							"text",
							restricted
						)}
						placement="top"
					>
						<div
							style={{
								minHeight: "40px",
								left: "0px",
								minWidth: "40px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: getStatusProperty(
									user.access_starts_at,
									user.access_ends_at,
									"secondary",
									restricted
								),
								position: "absolute",
								borderRadius: "20px",
								zIndex: 999
							}}
						>
							{getStatusProperty(
								user.access_starts_at,
								user.access_ends_at,
								"icon",
								restricted
							)}
						</div>
					</TooltipWrapper>

					<div style={{ flex: 1 }} />
					<div
						style={{
							width: "1px",
							height: "100%",
							backgroundColor: "rgba(0,0,0,0.02)"
						}}
					/>
					<IconButton
						sx={{ position: "absolute", right: "33px" }}
						onClick={() => {
							setOpenDialog(true);
						}}
					>
						<EditIcon
							stroke={ColorScheme.tolotechBlue}
							strokeWidth={2}
							width={"24px"}
							height={"24px"}
						/>
					</IconButton>
					<ConfirmAction
						hideTooltip={true}
						hasChild
						handleConfirmAction={() => handleRemove(user)}
						handleTitle={t("buttons.remove_user_access")}
					>
						<div
							style={{
								width: "36px",
								height: "36px"
							}}
						>
							<DeleteButton />
						</div>
					</ConfirmAction>
				</div>
			</Stack>
		</div>
	);
};
