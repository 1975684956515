import React from "react";
import { AppContainer } from "./Components/AppContainer";
import { UserProvider } from "./Components/UserContext";
import { SnackProvider } from "./Components/SnackContext";
import { ApiProvider } from "./Components/ApiContext";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./Helpers/ScrollToTop";

export const App = () => (
	<SnackProvider>
		<UserProvider>
			<BrowserRouter>
				<ApiProvider>
					<ScrollToTop />
					<AppContainer />
				</ApiProvider>
			</BrowserRouter>
		</UserProvider>
	</SnackProvider>
);
