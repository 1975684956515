import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const FormSave = ({ check, handleSave }) => {
	const { t } = useTranslation();

	return (
		<Button
			sx={{
				backgroundColor: "transparent",
				boxShadow: "none",
				padding: "2px",
				width: "100px",
				"&:hover": {
					backgroundColor: "transparent",
					boxShadow: "none",
					border: "1px solid white",
					padding: "2px"
				}
			}}
			variant="contained"
			disabled={check}
			onClick={() => handleSave()}
		>
			<Typography>{t("buttons.save")}</Typography>
		</Button>
	);
};
