import { Box, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { SortingOptions } from "./SortingOptions";
import { KeyUserList } from "./KeyUserList";
import { ApiContext } from "../../ApiContext";
import { Spinner } from "../../Common/Spinner";
import { ColorScheme } from "../../../Themes/ColorScheme";

import { ReactComponent as Arrow } from "../../../Assets/Icons/arrowDown.svg";
import { useTranslation } from "react-i18next";

const ONE_PAGE_LIMTI = 20;

const styles = {
	button: {
		height: "40px",
		width: "40px",
		backgroundColor: "#F1F1F1",
		marginTop: "20px",
		borderRadius: "4px",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		cursor: "pointer"
	}
};

export const KeyAccessList = ({ data, handleRefresh }) => {
	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const [search, setSearch] = useState("");

	const [page, setPage] = useState(1);
	const [showNextPageButton, setShowNextPageButton] = useState(false);
	const [showPreviousPageButton, setShowPreviousPageButton] = useState(false);

	const [loading, setLoading] = useState(true);
	const [content, setContent] = useState([]);
	const [boxHeight, setBoxHeight] = useState(80);

	const [readyToFetch, setReadyToFetch] = useState(false);

	const [sort, setSort] = useState("user_name ASC");

	const handleRemove = (entry) => {
		setLoading(true);

		ApiCall("DELETE", "client/residents", {
			reservable_id: data.id,
			user_ids: [entry.user_id]
		})
			.then(() => {
				handleRefresh();
			})
			.catch((error) => console.log(error))
			.finally(setLoading(false));
	};

	function fetchUserData() {
		setContent([]);
		ApiCall(
			"GET",
			`client/residents?reservable=${data.id}&search=${search}&page=${page}&sort=${sort}`
		)
			.then((res) => {
				let temp = [...content];
				res.map((entry) => {
					temp.push(entry);
				});
				if (res.length >= ONE_PAGE_LIMTI) {
					setShowNextPageButton(true);
				} else {
					setShowNextPageButton(false);
				}
				setContent(temp);
				setReadyToFetch(false);
				setLoading(false);
			})
			.catch(() => {
				// console.log(error);
				setLoading(false);
			});
	}

	useEffect(() => {
		if (readyToFetch) {
			fetchUserData();
		}
	}, [readyToFetch]);

	const handleChange = (text) => {
		const onlyLowerCase = text.target.value.toLowerCase();
		setSearch(onlyLowerCase);
	};

	useEffect(() => {
		if (page == null) {
			setPage(1);
			return;
		}
		setPage(null);
	}, [search, data, sort]);

	useEffect(() => {
		if (page === null) {
			setPage(1);
			return;
		}
		if (page > 1) {
			setShowPreviousPageButton(true);
		} else {
			setShowPreviousPageButton(false);
		}
		setLoading(true);
		setContent([]);
		const delay = 500;
		const delayDebounceFn = setTimeout(() => {
			setReadyToFetch(true);
		}, delay);

		return () => clearTimeout(delayDebounceFn);
	}, [page]);

	useEffect(() => {
		const boxHeightT =
			81 * content.length +
			2 +
			(loading ||
			showNextPageButton ||
			showPreviousPageButton ||
			content.length === 0
				? 83
				: 0);
		setBoxHeight(boxHeightT);
	}, [loading, content]);

	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
					marginBottom: "20px"
				}}
			>
				<Typography fontSize="24px">Käyttöoikeudet</Typography>
				<Typography fontSize="20px">
					{data.user_count || "0"} / {data.user_limit}
				</Typography>
			</div>
			<TextField
				fullWidth
				variant="filled"
				label={t("textfield_labels.search")}
				InputLabelProps={{ style: { color: "grey" } }}
				inputProps={{
					style: {
						color: "black",
						backgroundColor: "rgb(240,240,240)",
						borderRadius: "4px 4px 0px 0px",
						border: "0px"
					}
				}}
				onChange={handleChange}
			/>

			<SortingOptions sort={sort} setSort={setSort} />
			<Box
				sx={{
					height: boxHeight + "px",
					backgroundColor: "white",
					alignItems: "center",
					justifyContent: "center",
					display: "flex",
					borderBottomLeftRadius: "4px",
					borderBottomRightRadius: "4px",
					width: "100%",
					transition: "all .2s ease-out",
					overflow: "hidden",
					flexDirection: "column"
				}}
			>
				{content.length === 0 && !loading && (
					<Typography sx={{ color: "black" }}>
						{search === ""
							? t("key.no_users_yet")
							: t("key.no_users_found")}
					</Typography>
				)}
				<div
					style={{
						backgroundColor: ColorScheme.tolotechWhite,
						borderBottomLeftRadius: "4px",
						borderBottomRightRadius: "4px",
						top: "100px",
						width: "100%"
					}}
				>
					{content.map((entry, i) => (
						<KeyUserList
							handleRefresh={handleRefresh}
							reservable={data}
							key={i}
							entry={entry}
							i={i}
							isLast={i === content.length - 1}
							handleRemove={handleRemove}
						/>
					))}
				</div>
				{loading && <Spinner small color={ColorScheme.tolotechBlue} />}
				{(showNextPageButton || showPreviousPageButton) && !loading && (
					<div
						style={{
							minHeight: "83px",
							flexDirection: "row",
							display: "flex",
							justifyContent: "space-around"
						}}
					>
						{
							<Box
								onClick={() =>
									showPreviousPageButton && setPage(page - 1)
								}
								sx={[
									styles.button,
									{
										opacity: showPreviousPageButton
											? 1
											: 0.5
									}
								]}
							>
								<div style={{ rotate: "90deg" }}>
									<Arrow stroke={"black"}></Arrow>
								</div>
							</Box>
						}
						<div
							style={{
								width: "100px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Typography sx={{ color: "black" }}>
								{"Sivu " + page}
							</Typography>
						</div>
						{
							<Box
								onClick={() =>
									showNextPageButton && setPage(page + 1)
								}
								sx={[
									styles.button,
									{
										opacity: showNextPageButton ? 1 : 0.5
									}
								]}
							>
								<div style={{ rotate: "270deg" }}>
									<Arrow stroke={"black"}></Arrow>
								</div>
							</Box>
						}
					</div>
				)}
			</Box>
		</div>
	);
};
