export const parseTimestamp = (timestamp, option) => {
	// option 1: full date + time
	// option 2: full date
	// option 3: full time
	// option 4: date + time without secons
	// option 5: date without year
	// option 6: time without seconds

	// console.log(timestamp, option);

	const dateToParse = new Date(timestamp);

	const getDate = dateToParse.getDate().toString().padStart(2, "0");
	const getMonth = (dateToParse.getMonth() + 1).toString().padStart(2, "0");
	const getFullYear = dateToParse.getFullYear();
	const getHours = dateToParse.getHours().toString().padStart(2, "0");
	const getMinutes = dateToParse.getMinutes().toString().padStart(2, "0");
	const getSeconds = dateToParse.getSeconds().toString().padStart(2, "0");

	switch (option) {
		case 1: // full date + time
			return `${getDate}.${getMonth}.${getFullYear} ${getHours}:${getMinutes}:${getSeconds}`;
		case 2: // full date
			return `${getDate}.${getMonth}.${getFullYear}`;
		case 3: // full time
			return `${getHours}:${getMinutes}:${getSeconds}`;
		case 4: // date + time
			return `${getDate}.${getMonth}.${getFullYear} ${getHours}:${getMinutes}`;
		case 5: // date without year
			return `${getDate}.${getMonth}`;
		case 6: // time without seconds
			return `${getHours}:${getMinutes}`;

		default:
			return ""; // invalid option
	}
};
