import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { TextfieldPicker } from "../Common/TextfieldPicker";

import { ReactComponent as CalendarIcon } from "../../Assets/Icons/CalendarIcon_custom_stroke.svg";
import { ReactComponent as ClockStartIcon } from "../../Assets/Icons/TimeStartIcon.svg";
import { ReactComponent as ClockEndIcon } from "../../Assets/Icons/TimeEndIcon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { Spinner } from "../Common/Spinner";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const CreateMeet = ({ handleDialogClose, passNewCourseMeetBody }) => {
	const { t } = useTranslation();
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [fromTime, setFromTime] = useState(new Date());
	const [toTime, setToTime] = useState(new Date());
	const [showLoader, setShowLoader] = useState(false);

	const handleSave = () => {
		// console.log("1", new Date(fromTime).toISOString(), new Date(toTime).toISOString());
		setShowLoader(true);
		const selectedDateObj = new Date(selectedDate);
		const fromTimeObj = new Date(fromTime);
		const toTimeObj = new Date(toTime);

		// set the year, month and date of the fromTime and toTime objects to the selectedDate
		// this is to ensure that the time objects are set to the same date as the selected date

		fromTimeObj.setFullYear(
			selectedDateObj.getFullYear(),
			selectedDateObj.getMonth(),
			selectedDateObj.getDate()
		);

		toTimeObj.setFullYear(
			selectedDateObj.getFullYear(),
			selectedDateObj.getMonth(),
			selectedDateObj.getDate()
		);

		passNewCourseMeetBody({
			from_time: fromTimeObj.toISOString(),
			to_time: toTimeObj.toISOString()
		});
		setShowLoader(false);
		handleDialogClose();
	};

	return (
		<Box
			sx={{
				width: "100%",
				maxWidth: "700px",
				minWidth: isDesktop ? "350px" : undefined,
				height: "100%",
				padding: "20px",
				boxSizing: "border-box"
			}}
		>
			<Box>
				<Typography variant="h6">
					{t("courses.new_course_meet_time_label")}
				</Typography>
			</Box>

			<div style={{ height: "30px" }} />

			<TextfieldPicker
				value={selectedDate}
				valueChange={setSelectedDate}
				noTime={true}
				label={t("courses.course_meet_date_label")}
				excludePast={false}
				hasProps={
					<CalendarIcon stroke={ColorScheme.defaultIconColor} />
				}
			/>
			<TextfieldPicker
				value={fromTime}
				valueChange={setFromTime}
				excludePast={false}
				noDate={true}
				label={t("courses.course_starting_day_label")}
				hasProps={
					<ClockStartIcon stroke={ColorScheme.defaultIconColor} />
				}
			/>
			<TextfieldPicker
				value={toTime}
				valueChange={setToTime}
				excludePast={false}
				noDate={true}
				label={t("courses.course_last_day_label")}
				hasProps={
					<ClockEndIcon stroke={ColorScheme.defaultIconColor} />
				}
			/>

			<Box
				sx={{
					flexDirection: "row",
					display: "flex",
					width: "100%",
					justifyContent: "space-between",
					gap: "10px",
					paddingTop: "20px"
				}}
			>
				<Button
					sx={{ width: "50%" }}
					variant="outlined"
					onClick={handleDialogClose}
				>
					{t("buttons.cancel")}
				</Button>

				<Button
					sx={{
						width: "50%",
						backgroundColor: showLoader ? "white" : "",
						border: showLoader
							? `1px solid ${ColorScheme.tolotechBlue}`
							: ""
					}}
					variant="drawerFormAccept"
					disabled={showLoader}
					onClick={() => handleSave()}
				>
					{showLoader ? (
						<Spinner hasText={t("spinner_text.wait")} />
					) : (
						t("buttons.save")
					)}
				</Button>
			</Box>
		</Box>
	);
};
