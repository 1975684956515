import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { TypeSelect } from "../../../Common/ReservationTimes";

import { TimeLimit } from "./TimeLimit";
import { UsageLimit } from "./UsageLimit";
import { TextFieldSelect } from "../../../Common/TextFieldSelect";
import TooltipWrapper from "../../../Common/TooltipWrapper";

import { ReactComponent as TaxIcon } from "../../../../Assets/Icons/PercentIcon.svg";
import { ReactComponent as MainIcon } from "../../../../Assets/Icons/MainIcon.svg";
import { ReactComponent as CurrencyIcon } from "../../../../Assets/Icons/WalletIcon.svg";
import {
	ReactComponent as AmountIcon,
	ReactComponent as BalanceIcon
} from "../../../../Assets/Icons/AmountIcon.svg";

import { TaxPercentages } from "../../../../Datafields/TaxPercentages";
import { ColorScheme } from "../../../../Themes/ColorScheme";
import { TextFieldInput2 } from "../../../Common/TextFieldInput2";

export const ProductPrice = ({ data, handleProductData }) => {
	const { t } = useTranslation();
	const [getType, setGetType] = useState(1);
	const [usageLimit, setUsageLimit] = useState(0);
	const [changePrice, setChangePrice] = useState(0);
	const [changeVat, setChangeVat] = useState(0);
	const [changeAmount, setChangeAmount] = useState(0);
	const [timeAmount, setTimeAmount] = useState(0);
	const [balanceAmount, setBalanceAmount] = useState(0);

	const switchCase = () => {
		switch (getType) {
			case 1:
				return (
					<UsageLimit
						passUsageLimit={usageLimit}
						handleChange={setUsageLimit}
					/>
				);
			case 2:
				return (
					<TimeLimit
						passDuration={timeAmount}
						passTimeData={setTimeAmount}
					/>
				);
			case 3:
				return null; // render only tax and price fields

			case 4:
				return (
					<TextFieldInput2
						endAdornment={"€"}
						title={t("product.balance_label")}
						text={balanceAmount}
						valueChange={setBalanceAmount}
						hasProps={<BalanceIcon stroke={"black"} height={28} />}
					/>
				);
			default:
				break;
		}
	};

	useEffect(() => {
		if (
			changeAmount !== null &&
			changeAmount !== undefined &&
			getType !== null &&
			changePrice !== null &&
			changePrice !== undefined &&
			changeVat !== null &&
			changeVat !== undefined
		) {
			handleProductData({
				type: getType,
				amount: changeAmount,
				limit: getType === 1 ? usageLimit : 0,
				price: changePrice,
				timeAmount: getType === 2 ? timeAmount : 0,
				vatAmount: changeVat,
				balanceAmount: getType === 4 ? balanceAmount : 0
			});
		}
	}, [
		usageLimit,
		changeAmount,
		timeAmount,
		changePrice,
		changeVat,
		getType,
		balanceAmount,
		handleProductData
	]);

	useEffect(() => {
		if (data) {
			if (balanceAmount === 0 && data?.balance_amount > 0) {
				// Only case where balance is set
				setGetType(4);
			} else if (
				changeAmount === 0 &&
				data?.max_amount > 0 &&
				(data?.duration === 0 || data?.duration === undefined) &&
				(data?.usage_count === 0 || data?.usage_count === undefined)
			) {
				setGetType(3);
			} else if (
				usageLimit === 0 &&
				data?.usage_count > 0 &&
				(data?.duration === 0 || data?.duration === undefined)
			) {
				setGetType(1);
			} else if (
				timeAmount === 0 &&
				data?.duration > 0 &&
				(data?.usage_count === 0 || data?.usage_count === undefined)
			) {
				setGetType(2);
			}

			console.log("product sell type unknown");
		}

		if (data) {
			setUsageLimit(data?.usage_count || 0);
			setChangePrice(data?.vat_price || 0);
			setChangeVat(data?.vat_percentage || 0);
			setChangeAmount(data?.max_amount || 0);
			setTimeAmount(data?.duration || 0);
			setBalanceAmount(data?.balance_amount || 0);
		}
	}, [data]);

	return (
		<>
			<TextFieldSelect
				options={TypeSelect}
				valueChange={setGetType}
				selected={getType}
				label={t("product.type_label")}
				hasProps={
					<TooltipWrapper>
						<MainIcon stroke={ColorScheme.defaultIconColor} />
					</TooltipWrapper>
				}
			/>

			{switchCase()}

			<Collapse in={getType !== null} collapsedSize={true}>
				<div
					style={{
						display: "flex",
						flexDirection: isDesktop ? "row" : "column",
						gap: isDesktop ? "20px" : "0px",
						paddingBottom: "10px"
					}}
				>
					<TextFieldInput2
						onlyNumbers={"tel"}
						text={changeAmount}
						title={t("product.product_amount_availability")}
						valueChange={setChangeAmount}
						/* removeIcon={screenWidth} */
						hasProps={
							<AmountIcon
								stroke={ColorScheme.defaultIconColor}
								strokeWidth={2}
								width={28}
							/>
						}
					/>

					<TextFieldInput2
						onlyNumbers={"tel"}
						text={changePrice}
						title={t("product.price_label")}
						valueChange={setChangePrice}
						/* removeIcon={screenWidth} */
						/* endAdornment={t("common.currency_text")} */
						hasProps={
							<CurrencyIcon
								stroke={ColorScheme.defaultIconColor}
								strokeWidth={2.5}
								width={26}
							/>
						}
					/>

					<TextFieldSelect
						valueChange={setChangeVat}
						selected={parseFloat(changeVat)}
						hasProps={
							<TaxIcon stroke={ColorScheme.defaultIconColor} />
						}
						label={t("product.tax_label")}
						options={TaxPercentages}
						defaultSelect={true}
						/* removeIcon={screenWidth} */
					/>
				</div>
			</Collapse>
		</>
	);
};
