import { ReactComponent as DefaultChargerIcon } from "../../../Assets/Icons/ChargingDeviceIcon.svg";
import { Typography } from "@mui/material";
import React from "react";

export const ChargerIcon = ({
	data,
	strokeColor,
	strokeWidth,
	iconWidth,
	iconHeight,
	svgIcon
}) => (
	<div
		style={{
			textAlign: "center",
			position: "relative",
			width: "25%"
		}}
	>
		{data.type.icon !== undefined && data.type.icon !== "" ? (
			<img
				width={iconWidth}
				alt="icon"
				src={`data:image/svg+xml;base64,${btoa(
					/* Ignore deprecated warning, only affects NodeJS */
					svgIcon
				)}`}
			/>
		) : (
			<DefaultChargerIcon
				fill={"black"}
				fillOpacity={1}
				fillRule="nonzero"
				stroke={strokeColor}
				width={iconWidth}
				heigth={iconHeight}
				strokeWidth={strokeWidth}
			/>
		)}
		<Typography
			style={{
				fontSize: "12px",
				fontWeight: "400",
				color: "grey"
			}}
		>
			{data.type.name}
		</Typography>
	</div>
);
