import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
// import { KeyTitle } from "./Components/KeyTitle";
import { Collapse, Typography } from "@mui/material";

import { KeyAddAccess } from "./Components/KeyAddAccess";
import { KeyAccessList } from "./Components/KeyAccessList";

import { isMobile } from "react-device-detect";
import { AlertBar } from "../Common/AlertBar";
import { KeyManagementMassAddition } from "./Components/KeyManagementMassAddition";
import { useTranslation } from "react-i18next";

export const KeyManagement = ({ data, refreshReservables }) => {
	const { t } = useTranslation();

	const [showAlert, setShowAlert] = useState(false);
	const [refreshNeeded, setRefreshNeeded] = useState(false);

	function AlertRemoved() {
		document.cookie = "tologo_kms=1";
		setShowAlert(false);
	}

	const handleRefresh = () => {
		setRefreshNeeded(!refreshNeeded);
		refreshReservables();
	};

	// This is used to show the alert bar only once
	useEffect(() => {
		let cookie = document.cookie.split("tologo_kms=")[1];
		if (cookie === undefined) {
			setShowAlert(true);
			return;
		}
		let kms = cookie.split(";")[0];
		if (kms !== "1") {
			setShowAlert(true);
		}
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%"
			}}
		>
			<Collapse in={showAlert}>
				<AlertBar
					type={"warning"}
					standardVariant={true}
					textContent={t("key_management.snack_bar_text")}
					showAlert={true}
					strongEnding={t("key_management.snack_bar_strong_text")}
					onCloseAction={AlertRemoved}
				/>
				<div style={{ height: "100px" }} />
			</Collapse>
			<Box
				display="flex"
				flexDirection={isMobile ? "column" : "row"}
				gap="40px"
				justifyContent="space-between"
				flexWrap="wrap"
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						width: "100%",
						maxWidth: "400px"
					}}
				>
					<Typography fontSize="24px">
						Lisää uusi käyttöoikeus
					</Typography>
					<div
						style={{
							borderRadius: "4px",
							overflow: "hidden"
							// padding: "15px",
						}}
					>
						<KeyAddAccess
							data={data}
							handleRefresh={handleRefresh}
						/>
					</div>
					<div
						style={{
							borderRadius: "4px",
							overflow: "hidden"
							// padding: "15px",
						}}
					>
						<KeyManagementMassAddition
							data={data}
							handleRefresh={handleRefresh}
						/>
					</div>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						width: isMobile ? "100%" : undefined,
						flexGrow: 1,
						boxSizing: "border-box"
					}}
				>
					<KeyAccessList
						data={data}
						refreshNeeded={refreshNeeded}
						handleRefresh={handleRefresh}
					/>
				</Box>
			</Box>
		</Box>
	);
};
