import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../Themes/ColorScheme";
import { ApiContext } from "../ApiContext";
import { ReactComponent as CheckmarkIcon } from "../../Assets/Icons/Checkmark.svg";
import { ReactComponent as CrossIcon } from "../../Assets/Icons/Cross.svg";

export const Invitation = ({ invite, refresh }) => {
	const { t } = useTranslation();

	const { ApiCall } = useContext(ApiContext);

	const accept = (manager_id) => {
		ApiCall("POST", `client/accept_invitation/${manager_id}`, null).then(
			() => {
				refresh();
				window.location.reload(false);
			}
		);
	};

	const decline = (manager_id) => {
		ApiCall("DELETE", `client/decline_invitation/${manager_id}`, null).then(
			() => {
				refresh();
				// window.location.reload(false);
			}
		);
	};

	return (
		<Grid
			item
			textAlign="left"
			sx={{
				color: "#000000",
				borderRadius: "4px",
				flexDirection: "row",
				display: "flex",
				justifyContent: "space-between",
				border: "1px solid " + ColorScheme.tolotechDarkBlue,
				padding: "10px",
				marginBottom: "10px"
			}}
		>
			<Stack>
				<Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
					{invite.location_name !== undefined && invite.location_name}
				</Typography>
				<Typography sx={{ fontSize: "10px", color: "#48526B" }}>
					{t("invites.management")}
				</Typography>
			</Stack>

			<Box
				sx={{
					alignItems: "flex-end",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<Typography sx={{ fontSize: "10px", color: "#48526B" }}>
					{moment(invite.created_at).format("DD.MM.YY HH:mm")}
				</Typography>
				<Stack
					style={{ justifyContent: "space-between" }}
					direction="row"
				>
					<Box
						onClick={() => accept(invite.manager_id)}
						sx={{
							width: "25px",
							height: "25px",
							borderRadius: "4px",
							backgroundColor: ColorScheme.tolotechBlue,
							border: "1px solid" + ColorScheme.tolotechBlue,
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							marginRight: "5px",
							cursor: "pointer"
						}}
					>
						<CheckmarkIcon
							/* width="15px"
							height="15px" */
							stroke={"white"}
						/>
					</Box>
					<Box
						onClick={() => decline(invite.manager_id)}
						style={{
							width: "25px",
							height: "25px",
							borderRadius: "4px",
							backgroundColor: "transparent",
							border: "1px solid" + ColorScheme.tolotechBlue,
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							cursor: "pointer"
						}}
					>
						<CrossIcon /* width="15px" height="15px" */ />
					</Box>
				</Stack>
			</Box>
		</Grid>
	);
};
