import React from "react";
import { ContactInfo } from "./ContactInfo";
import { ContactForm } from "./ContactForm";
import { Grid } from "@mui/material";

export const ContactContainer = () => (
	<Grid
		container
		columnSpacing={5}
		direction="row"
		justifyContent="space-around"
		sx={{ px: "10%", py: "2%" }}
	>
		<ContactForm />
		<ContactInfo />
	</Grid>
);
