import { useTranslation } from "react-i18next";

export default function TimeAgo(date) {
	const { t } = useTranslation();
	const now = new Date();
	const timeDifference = now - date;

	const millisecondsInMinute = 60 * 1000;
	const millisecondsInHour = 60 * millisecondsInMinute;
	const millisecondsInDay = 24 * millisecondsInHour;
	const millisecondsInMonth = 30 * millisecondsInDay;
	const millisecondsInYear = 365 * millisecondsInDay;

	if (timeDifference >= millisecondsInYear) {
		const yearsAgo = Math.floor(timeDifference / millisecondsInYear);
		return `${yearsAgo} ${t("time.years_ago")}`;
	} else if (timeDifference >= millisecondsInMonth) {
		const monthsAgo = Math.floor(timeDifference / millisecondsInMonth);
		return `${monthsAgo} ${t("time.months_ago")}`;
	} else if (timeDifference >= millisecondsInDay) {
		const daysAgo = Math.floor(timeDifference / millisecondsInDay);
		return `${daysAgo} ${t("time.days_ago")}`;
	} else if (timeDifference >= millisecondsInHour) {
		const hoursAgo = Math.floor(timeDifference / millisecondsInHour);
		return `${hoursAgo} ${t("time.hours_ago")}`;
	} else {
		const minutesAgo = Math.floor(timeDifference / millisecondsInMinute);
		return `${minutesAgo} ${t("time.minutes_ago")}`;
	}
}
