export const fi_lang = {
	report: {
		tax_separation: "Alv. erittely",
		sales_amount: "Myyntimäärä",
		sales_report: "Myyntiraportti",
		sales_separation: "Myyntierittely",
		e_sales_report: "Verkkomaksu",
		quick_functions: "Pikatoiminnot",
		pdf_row_title_sales_amount: "Tapahtumat",
		page_indicator: "Sivu ", // remember blank space at end.
		page_separator: " / ", // use blank space on both sides if you want clear separation.
		pdf_content_title: "Myyntiraportti",
		pdf_row_title_sale: "Myynti",
		pdf_row_title_time: "Aika",
		pdf_row_title_product: "Tuote",
		pdf_row_title_tax_acronym: "ALV-%",
		pdf_row_title_product_price_without_tax: "Veroton kpl hinta",
		pdf_row_title_product_price_with_tax: "Verollinen kpl hinta",
		pdf_row_title_price_with_tax: "Verollinen hinta",
		pdf_row_title_price_without_tax: "Veroton hinta",
		pdf_row_title_total: "Yhteensä",
		pdf_row_title_total_cash: "Käteismaksut yhteensä",
		pdf_row_title_payment_method: "Maksutapa",
		pdf_row_title_total_paytrail: "Verkkomaksut yhteensä",
		pdf_row_title_total_without_tax: "Ilman ALV.",
		pdf_row_title_sum: "Summa",
		pdf_exported_file_name: "myyntiraportti",
		export_report_label_reservations: "Tilavaraukset",
		export_report_label_products: "Tuotteet",
		export_report_label_courses: "Kurssit",
		export_report_label_charging: "Lataus",
		date_begin_label: "Alku",
		date_end_label: "Loppu",
		reservation_label: "Varaukset",
		usage_chart_label: "Käyttöaste",
		usage_chart_reserved_label: "Varatut",
		usage_chart_reservable_label: "Varaamatta", // unresolved bug in react-chartjs-2 library causes tooltips to cut off if tooltip width is bigger than the actual chart canvas render
		usage_chart_reservation_total_begin: "Varauksia: ",
		usage_chart_reservation_total_end: " kpl",
		usage_chart_menu_today: "Tänään",
		usage_chart_menu_current_month: "Tässä kuussa",
		usage_chart_menu_current_year: "Tänä vuonna",
		no_reservables_found:
			"Varattavia vuoroja ei löytynyt valitsemallasi aikavälillä."
	},
	billing: {
		id_not_found:
			"Tietoja ei voitu tallentaa. Kohteen laskutus ID puuttuu, ota yhteys ylläpitoon!",
		no_billing_info:
			"Lisää kohteen laskutustiedot avataksesi muut toiminnot!",
		form_billing_label: "Laskutus",
		form_billing_details_label: "Laskutus",
		form_client_name_label: "Nimi",
		form_einvoicing_address_label: "Verkkolaskuosoite",
		form_einvoicing_reference_label: "Viite",
		form_einvoicing_operator_label: "Verkkolaskuoperaattori",
		form_business_code_label: "Y-tunnus",
		form_business_contact_person: "Yhteyshenkilö",
		form_business_contact_email: "Sähköposti",
		get_billing_data_automatically: "Hae laskutustiedot",
		fetching_billing_data: "Haetaan laskutustietoja...",
		billing_data_fetched:
			"Antamallasi tiedoilla löytyi seuraavan yrityksen tiedot.\n\nJos tiedot ovat oikein, voit lisätä ne kohteen laskutustietoihin painamalla 'Vahvista' -painiketta.",
		billing_data_not_fetched:
			"Yrityksen tietoja ei löytynyt tai löytyi liian monta yritystä. Tarkenna hakua.",
		remember_to_fill_contact_information:
			"Yhteystietoja ei löytynyt. Muista täyttää vielä ne!",
		billing_data_fetch_guide:
			"Voit hakea laskutustiedot automaattisesti täyttämällä nimen ja/tai Y-tunnuksen ja painamalla 'Hae laskutustiedot' -nappia.\n\nVaihtoehtoisesti voit täyttää laskutustiedot manuaalisesti."
	},
	door_types: {
		door: "Ovi",
		garage_door: "Nosto-ovi",
		barrier: "Puomi"
	},
	messages: { out_of_messages: "Ei enempää viestejä." },
	menu: {
		return_back: "Takaisin kohteen tietoihin",
		select_location: "Valitse toinen kohde"
	},
	balances: {
		balance_label: "Saldo",
		balance_logs: "Saldotapahtumat",
		create_new_balance: "Lisää uusi saldo",
		loading_balances: "Ladataan saldotietoja...",
		expiration_date: "Viimeinen käyttöpäivä",
		no_users_yet: "Ei käyttäjiä",
		no_users_found: "Haulla ei löytynyt käyttäjiä",
		adjust_balance: "Lisää/vähennä saldoa",
		adjust_expiration_date: "Muuta viimeistä käyttöpäivää",
		input_balance_to_adjust: "Syötä määrä...",
		user_email: "Sähköposti",
		add_balance: "Lisää saldoa",
		clients_label: "Saldoasiakkaat",
		delete_balance: "Poista saldotili"
	},
	textfield_labels: {
		email: "Sähköposti",
		phone: "Puhelinnumero",
		price: "Hinta",
		start_date: "Voimassaolo alkaa",
		starts: "Alkaa",
		end_date: "Voimassaolo päättyy",
		ends: "Päättyy",
		start_time: "Alkaa",
		end_time: "Loppuu",
		input_email: "Syötä sähköposti...",
		input_phone: "Syötä puhelinnumero...",
		input_description: "Syötä lisätiedot...",
		input_license_plate: "Syötä rekisterinumero...",
		input_name: "Syötä nimi...",
		email_in_use: "Käyttäjä on jo lisätty",
		phone_in_use: "Puhelinnumerolla löytyi käyttäjä",
		search: "Haku...",
		no_valid_users_found: "Yhteystiedolla ei löytynyt käyttäjää!",
		tologo_user: "ToloGo-käyttäjä",
		other_user: "Puh/Rek. nro",
		name: "Nimi",
		license_plate: "Rekisterinumero",
		description: "Lisätiedot",
		add_phone_or_license_plate: "Syötä puhelinnumero tai rekisterinumero",
		phone_number_invalid: "Puhelinnumero ei kelpaa",
		license_plate_invalid: "Rekisterinumero ei kelpaa",
		add_name: "Syötä nimi",
		email_invalid: "Sähköposti ei kelpaa",
		add_email: "Syötä sähköpostiosoite"
	},
	gdpr: {
		notice: "Sivumme hyödyntää keksejä todentaaksemme rekisteröityneen käyttäjän.",
		close: "Sulje"
	},
	reservables: {
		// TODO lisää loput, huom. iso alkukirjain
		Golf: "Golf",
		Massage: "Hieronta",
		Shop: "Kauppa",
		Yoga: "Jooga",
		Bowling: "Keilaus",
		Badminton: "Sulkapallo",
		Sauna: "Sauna",
		Padel: "Padel",
		Tennis: "Tennis",
		Gym: "Kuntosali",
		Volleyball: "Lentopallo",
		Basketball: "Koripallo",
		Squash: "Squash",
		Other: "Muut",
		Pingpong: "Pingis",
		Pickleball: "Pickleball",
		Facilities: "Tilat",
		Facility: "Tila",
		Product: "Tuote",
		Rental: "Vuokra",
		Lataus: "Lataus", // ??
		Reservations: "Varaukset",
		Products: "Tuotteet",
		Courses: "Kurssit",
		Everything: "Kaikki"
	},
	dates: {
		monday_long: "Maanantai",
		monday_short: "Ma",
		tuesday_long: "Tiistai",
		tuesday_short: "Ti",
		wednesday_long: "Keskiviikko",
		wednesday_short: "Ke",
		thursday_long: "Torstai",
		thursday_short: "To",
		friday_long: "Perjantai",
		friday_short: "Pe",
		saturday_long: "Lauantai",
		saturday_short: "La",
		sunday_long: "Sunnuntai",
		sunday_short: "Su",

		january_long: "Tammikuu",
		january_short: "tammi",
		february_long: "Helmikuu",
		february_short: "helmi",
		march_long: "Maaliskuu",
		march_short: "maa",
		april_long: "Huhtikuu",
		april_short: "huhti",
		may_long: "Toukokuu",
		may_short: "tou",
		june_long: "Kesäkuu",
		june_short: "kesä",
		july_long: "Heinäkuu",
		july_short: "heinä",
		august_long: "Elokuu",
		august_short: "elo",
		september_long: "Syyskuu",
		september_short: "syys",
		october_long: "Lokakuu",
		october_short: "loka",
		november_long: "Marraskuu",
		november_short: "marras",
		december_long: "Joulukuu",
		december_short: "joulu"
	},
	campaign: {
		name_label: "Kampanjan nimi",
		name_label_short: "Nimi",
		add_new_user_to_campaign_label: "Lisää uusi käyttäjä kampanjaan",
		description_label: "Kampanjan kuvaus",
		description_label_short: "Lyhyt kuvaus",
		create_campaign_code_label: "Luo kampanjakoodi",
		amount_and_discount_type_label: "Alennus",
		percent_amount_label: "Prosenttimäärä",
		value_discount_label: "Euroa",
		percent_discount_label: "Prosenttia",
		campaign_target_label: "Kohde",
		campaign_type_label: "Tyyppi",
		campaign_use_limit_label: "Kokonaiskäyttöraja",
		campaign_user_limit_label: "Käyttöraja/asiakas",
		campaign_start_time_label: "Voimassa alkaen",
		campaign_start_time_label_short: "Alkaa",
		campaign_end_time_label: "Voimassa asti",
		campaign_end_time_label_short: "Päättyy",
		disable_on_upcoming_reservations:
			"Käytössä vain jos käyttäjällä ei ole tulevia varauksia",
		remove_user_from_campaign_label: "Poista käyttäjä kampanjasta",
		remove_user_from_list_label: "Poista listalta",
		edit_campaign_label: "Muokkaa kampanjaa",
		create_campaign_label: "Luo kampanja",
		user_email_label: "Sähköposti",
		input_user_email: "Syötä sähköposti...",
		input_user_contact: "Syötä sähköposti tai puhelinnumero...",
		user_contact_detail_label: "Sähköposti tai puhelinnumero",
		campaign_uses_left_label: "Käyttöjä jäljellä",
		campaign_code_label: "Koodi",
		delete_campaign: "Poista kampanja",
		campaigns_not_found: "Kampanjoita ei löytynyt",
		campaign_delete_label: "Poista",
		types: {
			other: "Muu",
			reservations: "Varaukset",
			products: "Tuotteet",
			courses: "Kurssit",
			all: "Kaikki"
		},
		failed_entries: "Seuraavien yhteystietojen lisäys epäonnistui!", // tähän parempi viesti?
		failed_entries_help:
			"Antamillasi yhteystiedoilla ei löytynyt yhtään rekisteröitynyttä ToloGo-käyttäjää. Tarkista ja korjaa yhteystiedot, jonka jälkeen voit yrittää uudestaan painamalla 'Tallenna' -nappia tai voit sulkea sivulehden, jolloin loppuja yhteystietoja ei lisätä."
	},
	charging: {
		not_found: "Yhtään latausasemaa ei löytynyt.",
		charger_type: "Liitintyyppi",
		price_label: "snt/kWh",
		max_power: "Max teho",
		kw_short: "kW",
		available: "Käytettävissä",
		unavailable: "Varattu",
		preparing: "Käynnistyy",
		status_unknown: "Tuntematon",
		last_online: "Viimeksi linjalla",
		price_per_kwh: "€/kWh",
		start: "Aseta näkyväksi",
		shutdown: "Piilota",
		offline: "Piilotettu",
		connector: "Liitin",
		charger_logs_description: "Näytä laturin lokitapahtumat",
		charger_logs_label: "Laturin lokitapahtumat",
		charger_settings_label: "Laturin asetukset",
		no_more_charger_logs: "Enempää lataustapahtumia ei löytynyt",
		type_1: "Type 1",
		type_2: "Type 2",
		type_3c: "Type 3c",
		type_4: "Type 4",
		ccs: "CCS",
		chademo: "CHAdeMO",
		combo: "Combo",
		tesla: "Tesla",
		ongoing_charging: "Lataus kesken",
		charger_rename: "Nimeä latausasema",
		charger_pricing: "Hinta",
		charger_tax: "Latauksen ALV."
	},
	common: {
		app_name: "tologo - hallintapaneeli",
		locations: "Kohteet",
		doors: "Ovet",
		calendar: "Tilat", // quickfix
		customers: "Asiakkaat",
		residents: "Avainlista",
		courses: "Kurssit",
		campaigns: "Kampanjat",
		messages: "Viestit",
		select: "Valitse...",
		text_no: "Ei",
		text_yes: "Kyllä",
		settings: "Asetukset",
		charging: "Lataus",
		main_page: "Päänäkymä",
		currency_symbol: "€",
		user_manuals: "Käyttöohjeet",
		support: "Tuki",
		page: "Sivu",
		balances: "Saldot",
		textfield_placeholder_text: "Kirjoita ...",
		loading: "Ladataan...",
		loading_failed: "Lataus epäonnistui",
		loading_succeeded: "Lataus onnistui",
		loading_more: "Ladataan lisää...",
		currency_text: "euroa"
	},
	input_labels: {
		user_input_email: "Sähköposti",
		to_date: "Mihin asti"
	},
	clients: {
		no_users_yet: "Ei käyttäjiä",
		no_more_users: "Ei enempää käyttäjiä",
		no_users_found: "Haulla ei löytynyt käyttäjiä",
		username: "Nimi",
		user_email: "Sähköposti",
		user_phone: "Puhelin",
		user_orders: "Tilauksia",
		page: "Sivu"
	},
	orders: {
		paid_label: "Maksettu",
		unpaid_label: "Maksamatta",
		mark_as_paid_label: "Merkitse maksetuksi",
		cancel_purchase_label: "Peruuta ostos",
		successful_cancel_label: "Peruutettu",
		order_contents_label: "Tilauksen yhteenveto",
		product_row_label: "Tuoterivit",
		total_label: "Yhteensä",
		other_label: "Muu",
		continuous_reservation_label: "Jatkuva varaus",
		single_time_reservation_label: "Kertavaraus",
		clients_label: "Asiakkaat",
		find_label: "Etsi",
		name_label: "Nimi",
		email_label: "Sähköposti",
		phone_label: "Puhelin",
		purchases_label: "Ostokset",
		space_label: "Tila", // ??
		customers_not_found: "Asiakkaita ei löytynyt",
		single_purchase_label: "osto",
		multiple_purchases_label: "ostoa",
		reservation_type_label: "Varauksen tyyppi",
		reservation_date_label: "Varauksen pvm",
		reservation_date_label_short: "PVM",
		reservation_timerange_label: "Varaus ajalle",
		purchase_state_label: "Ostoksen tila",
		purchase_refund_unsuccessful_alert:
			"Ostoksen peruuttaminen epäonnistui!", // todo tarviiko olla selvempi virheviesti?
		additional_products_only: "Tuoteosto"
	},

	calendar: {
		calendar_full_name: "Kalenteri",
		no_reservable_services_found:
			"Lisää kohteeseesi varattava tila, päästäksesi käyttämään kalenterinäkymää!",
		form_price_label: "Hinta",
		form_user_amount_label: "Käyttäjämäärä",
		time_label: "Aika",
		date_label: "Päivä",
		dates_label: "Päivämäärät",
		timing_label: "Ajankohta", // SP?
		price_label: "Hinta",
		lump_sum_label: "Kertakorvaus",
		is_lump_sum_tooltip:
			"Vakivuorot niputetaan yhdeksi ostokseksi. Kun ostos on maksettu, saa käyttäjä kulkuoikeudet vuoroilleen.",
		is_lump_sum_default_tooltip:
			"Voit halutessasi erotella jokaisen vakivuoron omaksi ostoksekseen laittamalla tämän valinnan pois päältä.",
		reservation_user_list_label: "Käyttäjät",
		remove_selection_tooltip: "Poista valinta",
		create_competition_day_label: "Luo uusi tilavaraus",
		select_reservables_label: "Valitse tilat",
		space_reservations_label: "Tilavaraukset",
		create_new_reservation: "Luo uusi varaus",
		modify_reservation: "Muokkaa varausta",
		reservation_time_hours_label: "tuntia",
		reservation_time_minutes_label: "minuuttia",
		reservation_time_days_label: "päivää",
		space_reservations_not_found: "Ei luotuja tilavarauksia.",
		create_new_continuous_reservation_label: "Luo uusi vakivuoro",
		continuous_reservations_label: "Vakivuorot",
		continuous_reservation_label: "Vakivuoro",
		reservable_facility_label: "Tilavaraus",
		course_reservation_label: "Kurssivaraus",
		continuous_reservations_not_found: "Ei vakivuoroja",
		selected_reservations_label: "Valitut vuorot",
		reservations_selected_label: "valittuna",
		single_reservation_label: "vuoro",
		multiple_reservation_label: "vuoroa",
		delete_selected_reservations_tooltip: "Poista valitut vuorot",
		reservation_is_not_empty_tooltip: "Varattua vuoroa ei voi poistaa",
		reservation_cancellation_label: "Varauksen peruuttaminen",
		edit_selected_reservations_description:
			"Muuta varattujen vuorojen tietoja",
		multiroom_reservation_label: "Linkitetty kenttävaraus",
		prevent_changing_multiple_timeslots:
			"Et voi muuttaa useamman vuoron ajankohtaa samaan aikaan.",
		date_start: "Aloitus",
		date_end: "Lopetus",
		reservation_is_full: "Varaus on täynnä",

		whole_day: "koko päivä",
		next_day: "Seuraava päivä",
		next_week: "Seuraava viikko",
		previous_day: "Edellinen päivä",
		previous_week: "Edellinen viikko",
		today: "Tänään",
		week: "viikko",
		month: "kuukausi",
		day: "päivä",
		agenda: "lista",
		date: "päivämäärä",
		time: "aika",
		event: "tapahtuma",
		tomorrow: "huomenna",
		yesterday: "eilen",
		show_more: "lisää",
		begin: "Alku",
		end: "Loppu",
		add_new_user: "Lisää uusi käyttäjä",
		chosen_group: "Valittu ryhmä",
		start_date_label: "Aloituspäivä",
		end_date_label: "Päättymispäivä",
		start_time_label: "Alkamisaika",
		end_time_label: "Päättymisaika",
		continuous_reservation_helper_text:
			"Määritä aikaväli, jolle haluat luoda vakiovuoron toistumaan valittuna päivänä. Luodessa vakiovuoroa, tyhjät vuorot poistetaan sen alta. Mikäli kyseiseen ajankohtaan on jo tehty varaus, se täytyy perua käsin.",
		exclude_reservation_helper_text:
			"Valitse päivät, jolloin haluat varata tilan. Tilavarauksen luonti poistaa kaikki tyhjät vuorot valitulta ajalta. Jos ajankohtaan on jo tehty varaus, se täytyy perua käsin."
	},
	discount: {
		other: "Muut",
		reservations: "Varaukset",
		products: "Tuotteet",
		courses: "Kurssit",
		everything: "Kaikki"
	},

	login: {
		login_title: "Kirjaudu sisään",
		user_name_label: "Käyttäjätunnus*",
		user_real_name: "Nimi",
		user_password_label: "Salasana*",
		user_password: "Syötä salasana",
		email_recovery_description:
			"Syötä sähköpostiosoitteesi, jotta saat käyttöösi uniikin palautuskoodin.",
		email_label: "Sähköpostiosoite",
		insert_email: "Syötä sähköpostiosoite",
		repeat_email: "",
		change_password: "Vaihda salasana",
		new_password: "Uusi salasana",
		repeat_password: "Toista uusi salasana",
		logout: "Kirjaudu ulos",
		account_activation_label: "Tilin vahvistus*",
		account_validation_code: "Syötä vahvistuskoodi",
		new_user_guide:
			"Syötä vielä nimesi ja luo itsellesi salasana, jotta voit alkaa käyttää ToloGota.",
		email_in_use: "Sähköposti on jo rekisteröity",
		modify_info: "Muokkaa tietoja",
		phone: "Puhelinnumero",
		license_plate: "Rekisterinumero",
		name: "Nimi",
		login_failed: "Kirjautuminen epäonnistui!"
	},
	location: {
		form_details_label: "Kohteen tiedot",
		form_location_type_label: "Kohdetyyppi",
		form_location_admins_label: "Hallinnoijat",
		form_location_admin_pending: "Odottaa hyväksyntää",
		form_location_users_label: "Käyttäjälista",
		form_name_label: "Nimi",
		form_address_label: "Osoite",
		form_description_label: "Kuvaus",
		form_coordinates_label: "Sijainti",
		form_map_guide_text: "Siirrä merkkiä kartalla haluamaasi kohtaan",
		form_contact_person_label: "Yhteyshenkilö",
		form_contact_label: "Yhteydenotto",
		form_phone_label: "Puhelinnumero",
		form_email_label: "Sähköposti",
		form_add_door_access: "Sähköposti tai puhelinnumero",
		form_tax_label: "Veroprosentti",
		form_opening_hours_label: "Aukioloajat",
		form_opening_hours_description: "Kohteen aukioloaika?",
		form_location_name_label: "Kohteen nimi",
		form_location_description_label: "Kohteen kuvaus",
		form_location_logo_label: "Logo",
		form_location_cover_image_label: "Kansikuva",
		form_location_other_images_label: "Muut kuvat",
		form_location_delete_image_label: "Poista kuva",
		form_location_invite_user_label: "Syötä sähköpostiosoite",
		customer_email_message_empty: "Ei viestiä",
		form_customer_email_message_label: "Viesti varausvahvistuksessa",
		customer_email_message_label: "Viesti varausvahvistuksessa",
		reservable_space_label: "Varattavat tilat",
		additional_reservables_label: "Lisäpalvelut",
		form_recipe_date_label: "Päivä",
		form_recipe_timeframe_label: "Aloitus - Lopetus",
		form_recipe_reservation_duration_label: "Vuoropituus",
		form_recipe_price_tooltip: "Varattavan vuoron hinta",
		form_recipe_price_label: "Hinta",
		form_recipe_user_amount_label: "Käyttäjämäärä",
		form_recipe_price_for_reservable_label: "Varattavan vuoron hinta",
		form_recipe_reservables_not_found: "Ei luotuja vuoroja!",
		form_recipe_products_not_found: "Ei tuotteita!",
		form_recipe_valid_indefinitely: "Voimassa toistaiseksi",
		form_recipe_expired: "Päättynyt",
		form_recipe_valid_until: "Päättyy",
		form_recipe_valid_from_date: "Alkaa",
		form_recipe_valid_until_date: "Päättyy",
		form_remove_from_list: "Poista listalta",
		form_remove_pricing_tooltip: "Poista hinnoittelu",
		form_character_count: "merkkimäärä",
		form_user_list_description:
			"Jos haluat, että kohde on näkyvissä suljetulle käyttäjäryhmälle, lisää käyttäjät listalle.",
		recipe_table_label: "Hinnoittelut",

		found_multiple_addresses_alert:
			"Kohteita löytyi useampia ylläolevalla osoitetiedolla! Määrittele osoitetiedot mahdollisimman tarkasti tai vahvista sijaintitieto.",
		create_new_recipe: "Luo uusi",
		show_location_tooltip: "Näytä sijainti käyttäjille",
		hide_location_tooltip: "Piilota sijainti käyttäjiltä",
		copy_web_booking_link_tooltip: "Kopioi linkki.",
		web_booking_link_copied_tooltip: "Linkki kopioitu leikepöydälle!",
		web_booking_label: "Web-varaus:",
		web_booking_link_text: "booking.tologo.fi/location/",
		web_booking_direct_class_link: "Suora lajilinkki:",
		withdrawal_time_label: "Peruutusraja",
		withdrawal_time_unit: "tuntia",
		send_notification_on_new_reservations:
			"Lähetä sähköposti-ilmoitus hallinnoijille uudesta ostoksesta",
		no_locations_found:
			"Käyttäjätunnuksellasi ei ole vielä käyttöoikeuksia kohteissa.",
		no_proper_response:
			"Yhteydessä odottamaton ongelma, yritä hetken päästä uudestaan.",
		add_new_user_label: "Lisää uusi käyttäjä sijainnin käyttäjälistalle",
		found_registered_email: "Sähköposti on jo lisätty listalle.",
		billing_info_missing: "Lisää ensin kohteen laskutustiedot!",
		contact_detail_description:
			"Nämä yhteystiedot näkyvät loppukäyttäjille.",
		form_placeholder_text: "Valitse...",
		form_select_broker:
			"Voit valita verkkolaskuoperaattorin listalta tai jättää valikon tyhjäksi ja täyttää verkkolaskuoperaattorin tiedot käsin alempana oleviin kenttiin.",
		reservable_type_select: "Tilatyyppi",
		show_additional_settings: "Näytä lisäasetukset",
		hide_additional_settings: "Piiloita lisäasetukset",
		email_not_valid:
			"Antamallasi sähköpostiosoitteella ei löytynyt käyttäjää. Tarkista yhteystieto ja yritä uudestaan.",
		add_from_list_click_here:
			"Lisää käyttäjiä listalta klikkaamalla tästä.",
		add_from_list_file_rows_not_recognized_row: "- riviä ei tunnistettu: ",
		validate_list_submit: "Lähetä",
		wait_animation_text: "Odota ...",
		add_from_list_file_name: "Tiedostonimi: ",
		add_from_list_file_size: "Tiedostokoko: ",
		add_from_list_file_size_unit: "KB",
		add_from_list_file_guide:
			"Lähetä tiedosto (.csv, .xslx, .numbers), joka sisältää sähköpostiosoitteet.",
		add_from_list_file_rows_recognized: "Läpikäytyjä rivejä: ",
		add_from_list_file_amount_to_be_added: "Lisättävät käyttäjät: ",
		add_from_list_file_rows_not_recognized_show: "Näytä lisäämättömät",
		add_singular_general_guide:
			"Lisää käyttäjän sähköposti alla olevaan kenttään",
		add_multiple_general_guide:
			"Lisää useampi käyttäjä suoraan käyttäjätaulukosta",
		add_from_list_unrecognized: "Kelvottomat rivit tiedostossa: ",
		add_from_list_invalid_emails:
			"Seuraavia yhteystietoja ei voitu lisätä: ",
		add_from_list_invalid_email_single:
			"Yhteystietoa ei voitu lisätä. Tarkista sähköpostiosoite!",
		add_single_email_title: "Yksittäin",
		add_from_list_group_title: "Ryhmälisäys",
		add_from_csv: "Lisää käyttäjälista laskentataulukosta.",
		add_from_csv_description: "Katso esimerkkitiedostot täältä: "
	},
	courses: {
		title: "Kurssit",
		edit_course_label: "Muokkaa kurssia",
		create_new_course_label: "Luo uusi kurssi",
		new_course_meet_time_label: "Lisää uusi aika",
		reservable_type_selection: "Kohdetyyppi",
		course_name_label: "Kurssin nimi",
		course_contact_person_label: "Kurssin vetäjä",
		course_contact_email_label: "Sähköposti",
		course_description_label: "Kurssin kuvaus",
		course_user_amount_label: "Osallistujamäärä",
		course_price_label: "Hinta",
		course_tax_label: "Vero-%",
		course_full_label: "Täynnä!",
		course_meet_date_label: "Päivä",
		course_starting_day_label: "Aloitus",
		course_last_day_label: "Lopetus",
		course_remove_meet_time: "Poista aika",
		course_user_email: "Sähköposti",
		course_remove_from_list: "Poista kurssi",
		course_remove_user_from_list: "Poista käyttäjä",
		course_add_new_user_label: "Lisää käyttäjä",
		courses_not_found: "Ei kursseja",
		course_used_reservables: "Kentät",
		found_registered_email: "Tämä sähköposti on jo rekisteröity kurssille.",
		failed_to_add_users_by_email:
			"Ole hyvä ja varmista yllä olevat tiedot, sillä käyttäjien lisääminen annetuilla sähköpostiosoitteilla ei onnistunut.",
		failed_to_add_user_by_email:
			"Ole hyvä ja varmista yllä olevan tiedon oikeinkirjoitus, sillä käyttäjän lisääminen annetulla sähköpostiosoitteella ei onnistunut.",
		following_users_will_be_added: "Seuraavat käyttäjät lisätään",
		course_users_registered: "Kurssille ilmoittautuneet"
	},
	contact: {
		contact_label: "Yhteystiedot",
		contact_title_label: "Lomake",
		contact_select_description:
			"Valinta 2: valitse asiat joista olet kiinnostunut",
		contact_checkbox_new: "Uusi ovi/kohde",
		contact_checkbox_tologo_tracking: "ToloGo-kulunvalvonta",
		contact_checkbox_tologo_reservation: "ToloGo-varaus",
		contact_checkbox_licence_plate_identifier: "Rekisterikilpitunnistus",
		contact_checkbox_tologo_manual: "ToloGo-käyttöohjeet",
		contact_checkbox_resident_guide: "Asukasohjeet",
		contact_checkbox_other: "Muu",
		contact_checkbox_description_free_speech:
			"Muu (näkyy vain, jos yllä oleva muu kenttä ruksittu)",
		contact_checkbox_free_speech: "Vapaa sana"
	},
	doors: {
		door_access_ongoing: "Kulkuoikeus on voimassa",

		not_found: "Ei ovia",
		door_name: "Oven nimi",
		add_new_time: "Lisää uusi aika",
		no_access_right: "Ei kulkuoikeuksia",
		remove_access_right: "Poista kulkuoikeus",
		location_devices: "Laitteet",
		location_doors: "Ovet",
		location_access_rights: "Kulkuoikeudet",
		pin_code_label: "PIN",
		location_name: "Paikan nimi",
		door_access_request_label: "Kulkuoikeuspyynnöt",
		location_online: "Käytössä",
		location_offline: "Pois käytöstä",
		restrict_access_rights: "Rajoita kulkuoikeutta",
		edit_access_right: "Muokkaa kulkuoikeutta",
		new_users_to_add: "Seuraavat käyttäjät lisätään",
		door_id_label: "Oven tunnus",
		door_closed_label: "Suljettu",
		door_open_full_label: "Auki 24h",
		form_door_closed_label: "Suljettu",
		form_door_open_full_label: "Auki 24h",
		restrict_opening_hours: "Rajoita aukioloaikoja",
		time_door_stay_open: "Oven auki pito sekunteina",
		uses_left: "Kulkukertoja jäljellä",
		cron_timing_label: "Varattavissa (pv)",
		access_delay_label: "Viiveaika (min)",
		access_advance_label: "Ennakko (min)",
		access_advance:
			"Tällä voit ajastaa kulkuoikeuden voimaantulon aiemmaksi kuin vuoron alku.",
		access_delay:
			"Viive kulkuoikeuden loppumiseen varatun vuoron loputtua.",
		cron_timing:
			"Tällä voit määritellä kuinka monta päivää etukäteen vuorot ovat varattavissa.",
		door_types: "Ovityyppi",
		new_email_warning:
			"Antamallasi sähköpostilla ei löytynyt käyttäjää. Paina tallenna nappulaa uudestaan, jos haluat käyttää annettua sähköpostia.",
		door_online: "Ovi näyttää toimivan normaalisti",
		door_offline:
			"Ovi näyttää olevan pois verkosta hetkellisesti. Mikäli ongelma jatkuu, otathan yhteyttä huoltoon.",
		exact_times_notify:
			"Alku- ja loppuaika ovat samat, joten kulkuoikeudella ei ole aikarajausta.",
		edit_door_data: "Muokkaa oven asetuksia",
		show_door_logs: "Oven kulkutapahtumat",
		door_users_list: "Käyttäjät",
		show_userlist: "Näytä käyttäjät",
		no_doorphone: "Puhelinnumeroa ei ole lisätty",
		door_users_page: "Sivu",
		no_search_results: "Haulla ei löytynyt yhtään käyttäjää",
		no_user_found_with_contact_info:
			"Antamallasi tiedolla ei löytynyt käyttäjää. Tarkista yhteystiedot ja yritä uudestaan.",
		access_right_has_ended: "Kulkuoikeus on loppunut",
		access_right_has_not_started: "Kulkuoikeus ei ole vielä alkanut",
		access_right_valid: "Kulkuoikeus on voimassa",
		door_settings_info:
			"Oven auki pito ajan oletusarvo on 5 sekuntia, jonka jälkeen ovi lukkiutuu automaattisesti.",
		access_right_restricted_by_time:
			"Tällä voit määrittää yleisen aikahaarukan kulkuoikeudelle. 00:00 - 00:00 ei rajoita kulkuoikeutta.",
		access_right_restricted_weekdays:
			"Voit rajoittaa kulkuoikeutta tiettyihin viikonpäiviin. Valitse viikonpäivät, jolloin kulkuoikeus on voimassa.",
		access_right_restriction_duration:
			"Voit rajoittaa kulkuoikeutta määrittämällä kulkuoikeuden keston. Aseta aloitus ja lopetus, jolloin kulkuoikeus on voimassa.",
		access_right_ending_soon: "Kulkuoikeus on kohta loppumassa",
		door_user_access_right_guide:
			"Voit tarkemmin rajata käyttäjän kulkuoikeutta painamalla 'Rajaa kulkuaika' -nappia. Kulkuoikeus on oletuksena voimassa 24/7 sen luonnista."
	},
	logs: {
		not_found: "Ei lokitapahtumia",
		no_more_logs: "Enempää kulkulokeja ei löytynyt",
		no_more_charging_logs: "Enempää latauslokeja ei löytynyt",
		show_access_logs: "Näytä kulkulokit"
	},
	buttons: {
		accept: "Hyväksy",
		reject: "Hylkää",
		continue: "Jatka",
		save: "Tallenna",
		clear: "Tyhjennä",
		invite: "Kutsu",
		delete: "Poista",
		edit: "Muokkaa",
		hide: "Piilota",
		close: "Sulje",
		confirm: "Vahvista",
		open: "Avaa",
		door_add: "Lisää uusi ovi",
		return_to_login: "Palaa kirjautumiseen",
		login: "Kirjaudu sisään",
		register: "Rekisteröidy",
		register_account: "Rekisteröi tunnus",
		forgot_account_details: "Unohditko salasanasi?",
		send: "Lähetä",
		ready: "Valmis",
		load_more: "Lataa lisää",
		previous_page: "Edellinen sivu",
		next_page: "Seuraava sivu",
		reservation_calendar: "Kalenteri",
		click_to_copy_phone: "Kopioi oven puhelinnumero klikkaamalla",
		copied: "Kopioitu!",
		create_new: "Luo uusi",
		create: "Luo",
		save_changes: "Tallenna", // + muutokset
		cancel: "Peruuta",
		locate_address: "Paikanna",
		add_new_pricing: "Lisää hinnoittelu",
		add_new_reservable: "Lisää varattava tila +",
		add_additional_reservable: "Lisää lisäpalvelu +",
		show_access_rights: "Näytä kulkuoikeudet",
		create_new_access_right: "Luo uusi kulkuoikeus",
		add_another_user: "Lisää käyttäjä",
		remove_all: "Poista kaikki",
		door_access_rights: "Kulkuoikeudet",
		log_events: "Lokit",
		participants: "Osallistujat",
		add_time: "Lisää aika +",
		save_users: "Tallenna käyttäjät",
		remove_from_list: "Poista listalta",
		add_user: "Lisää",
		add_users: "Lisää käyttäjiä",
		add_course: "Lisää uusi kurssi",
		add_new_course_time: "Lisää uusi",
		add_new_user_to_reservation: "Lisää käyttäjä",
		create_campaign: "Luo kampanja",
		remove_reservation: "Poista varaus",
		goto_location_page: "Siirry kohteen sivulle",
		remove_user_admin_rights:
			"Poista käyttäjän hallinnointioikeus kohteesta.",
		log_charges: "Lataustapahtumat",
		create_report: "Luo raportti",
		remove_reservable_space: "Poista tila",
		remove_time_restriction: "Poista aikarajaus",
		add_time_restriction: "Rajaa kulkuaika",
		remove_user_access: "Poista käyttäjän käyttöoikeus",
		reservable_space: "Varattava tila",
		key_management: "Avainlista",
		default_selection: "Valitse...",
		load_client_csv: "Lataa asiakaslista"
	},

	time: {
		minutes_ago: "minuuttia sitten",
		minutes: "minuuttia",
		hours_ago: "tuntia sitten",
		hours: "tuntia",
		days_ago: "päivää sitten",
		days: "päivää",
		weeks: "viikkoa",
		months: "kuukautta",
		months_ago: "kuukautta sitten",
		years: "vuotta",
		years_ago: "vuotta sitten",
		today: "Tänään",
		yesterday: "Eilen",
		minutes_label_short: "min",
		start_time_label: "Aloitus",
		end_time_label: "Lopetus",
		begin_label: "Alku",
		end_label: "Loppu",
		duration_label: "Kesto",
		input_date: "Päivämäärä",
		input_time: "Aika",
		input_date_time: "Päivämäärä ja aika"
	},
	reservations: {
		create_new_reservation_label: "Luo varaus",
		selected_time_label: "Valittu aika",
		price_label: "Hinta",
		customer_amount_label: "Asiakasmäärä",
		remove_user_tooltip: "Poista käyttäjä",
		remove_reservation_model: "Poista varausikkuna"
	},
	key_management: {
		key_management_end_time:
			"Voit jättää päättymisajan tyhjäksi, jolloin kulkuoikeus on voimassa toistaiseksi.",
		new_key_management: "Määritä uudelle avainhallintalistalle nimi.",
		snack_bar_text:
			"ToloGo-avainlista on yksityisiä turvallisuuspalveluja koskevan lain (LYTP, 1085/2015) mukainen hallintajärjestelmä. Voit hallinnoida asukas/käyttäjälistaa helposti — ",
		snack_bar_strong_text:
			"ilman kolmannen osapuolen avainhallintapalvelua.",
		add_tologo_user:
			"Lisää ToloGo-käyttäjä sähköpostiosoitteella. ToloGo-käyttäjä voi itse ylläpitää rekisteri- ja puhelinnumeroaan sovelluksessa. Jos sähköpostilla ei ole vielä luotu ToloGo-käyttäjää, lähetetään käyttäjän sähköpostiin rekisteröitymiskutsu.",
		add_non_tologo_user:
			"Lisää käyttäjä puhelinnumeroa tai rekisterinumeroa käyttäen. Käyttäjä ei saa sovellusavausta käyttöönsä eikä voi itse ylläpitää tietojaan sovelluksessa."
	},
	services: {
		service_form_label: "Tilatiedot",
		service_type: "Tilatyyppi",
		edit_reservable_space: "Muokkaa varattavaa tilaa",
		edit_additional_reservable: "Muokkaa lisäpalvelua",
		add_reservable_space: "Lisää varattava tila",
		edit_key_management_space: "Muokkaa avainhallinta tilaa",
		add_additional_reservable: "Lisää lisäpalvelu",
		select_sport: "Valitse laji",
		reservable_space_name_label: "Tilan nimi",
		additional_reservable_name_label: "Tuotteen nimi",
		additional_reservable_description_label: "Tuotteen kuvaus",
		reservation_duration_label: "Varauksen minimikesto",
		new_reservation: "Luo uusi vuoro",
		reservation_time_price: "Hinta/vuoro",
		max_users: "Max hlö.",
		group_reservation_label: "Ryhmävuoro",
		reservables_not_found: "Ei vuoroja",
		remove_user_from_reservation: "Poista käyttäjä vuorosta",
		add_new_pricing_description: "Lisää uusi hinnoittelumalli",
		new_pricing_model_added_alert:
			"Uusi hinnoittelu lisätty onnistuneesti!",
		new_pricing_model_failed_alert:
			"Jokin meni pieleen, tarkista tiedot ja yritä uudelleen!",
		additional_settings: "Lisäasetukset",
		/* service_type_info: "Määrittele uuden tilan tyyppi ...", */
		service_basic_info: "Määrittele tilan nimi ja lajityyppi jatkaaksesi.",
		service_timings_info:
			"Tässä voit määritellä tilan vuoroluontia koskevat asetukset. Varattavissa tiedolla voit määritellä kuinka monta päivää etukäteen vuorot ovat varattavissa. Ennakolla voit ajastaa kulkuoikeuden voimaantulon aiemmaksi kuin vuoron alku. Viiveajalla voit määrittää kulkuoikeuden loppumiseen varatun vuoron loputtua.",
		service_tax_info: "Veroprosentti ...",
		service_access_rights_info: "Käyttöoikeudet ...",
		service_key_access_rights_info:
			"Tällä voit määrittää avainhallintalistan käyttäjien kulkuoikeudet tiettyihin oviin.",
		service_key_amount_info:
			"Määritä avainhallintalistan maksimi käyttäjäraja.",
		textfield_placeholder_text: "Syötä tekstiä",
		recipe_tax_percentage: "Veroprosentti",
		new_recipe: "Luo uusi vuoro",
		edit_recipe: "Muokkaa vuoroa",
		valid_from: "Alkaa",
		valid_until: "Päättyy",
		from_time: "Alkaa",
		to_time: "Päättyy",
		service_type_info:
			"Määrittele uuden tilan tyyppi, joko varattava tila tai avainhallintalista.",
		recipe_info:
			"Anna ajanjakso, jolle vuorot luodaan, määrittelemällä aloitus- ja päättymispäivä. Kellonajalla määritellään vuorojen alkamis- ja päättymisajat valitulla ajanjaksolla."
	},
	recipes: {
		to_time: "Päättyy",
		from_time: "Alkaa",
		valid_until: "Päättyy",
		valid_from: "Alkaa"
	},
	product: {
		product_form_label: "Tuotetiedot",
		usage_limit_label: "Käyttömäärä",
		product_space_label: "Tila",
		product_name_label: "Nimi",
		product_time_label: "Aukioloaika",
		time_limit_label: "Aikamäärä",
		usage_term: "krt",
		availability_amount: "kpl",
		tax_label: "Vero-%",
		tax_acronym: "ALV",
		price_label: "Kappalehinta",
		type_label: "Tyyppi",
		product_time_limit_unit: "minuuttia",
		product_amount_availability: "Max/hlö",
		availability_label: "Saatavuus",
		balance_limit_label: "Saldosumma",
		balance_label: "Yksikkömäärä",
		product_description: "Tuotekuvaus",
		product_image: "Tuotekuva",
		product_access_rights: "Käyttöoikeudet",
		product_access_rights_for_group_description:
			"Valitse käyttäjäryhmät, joilla on oikeus ostaa tuote.",
		product_pricing_details: "Tuotteen myyntitiedot",
		product_basic_info:
			"Anna tuotteelle nimi ja määritä tuotteelle laji. Tällä tavoin tuotelistaukset priorisoivat lajiin liittyviä tuotteita ensin.",
		product_access_rights_info:
			"Kulkuoikeudet luodaan vain valituille oville. Jos haluat liittää muita laitteita tilaan, kuten valonohjauksen, lisää se laitteisiin.",
		product_access_rights_for_products_info:
			"Tällä voit määrittää tuotteelle käyttöoikeudet tiettyihin oviin.",
		product_pricing_info:
			"Määritä tuotteen tyyppi, jonka jälkeen voit määritellä tuotteen hinnan, saatavuuden ja veroprosentin.",
		product_image_info: "Tuotekuva ...",
		product_description_info:
			"Anna myytävälle tuotteelle lyhyt kuvaus. Tämä näkyy tuotelistauksessa. Maksimi merkkimäärä on 255."
	},
	invites: {
		management: "Hallinnointikutsu",
		management_invitations: "Hallinnointikutsut",
		access_right_request: "Kulkuoikeuspyynnöt",
		requests_not_found: "Ei odottavia pyyntöjä!",
		invitations_not_found: "Ei odottavia kutsuja!"
	},
	images: {
		add_new_image_label: "Lisää kuva",
		add_new_image_guide:
			"Lisää kuva klikkaamalla tästä tai vetämällä kuva tähän",
		how_to_add_new_image:
			"Voit lisätä uuden kuvan klikkaamalla tai vetämällä kuvatiedoston alla oleviin kenttiin. Logo ja bannerikuva korvataan antamallasi uudella kuvalla. Muita kuvia voit lisätä haluamasi määrän ja tarvittaessa poistaa niitä.",
		change_image_label: "Vaihda tai lisää kuva klikkaamalla tästä"
	},
	warning: {
		device_not_supported:
			"ToloGo-hallinnointisovellus ei toimi vielä mobiilialustoilla.",
		nothing_was_found: "Täällä ei ole vielä mitään!",
		missing_data: "Tarvittava tieto puuttuu!",
		user_deleted: "Käyttäjä poistettu",
		failed_to_invite_new_user: "Käyttäjän lisääminen epäonnistui"
	},
	spinner_text: {
		wait: "Odota...",
		loading: "Ladataan..."
	},
	key: {
		named_space: "Avainlistan nimi",
		user_limit: "Käyttäjäraja",
		no_users_yet:
			"Avainlistakohteeseen ei ole vielä lisätty yhtään käyttäjää",
		user_name: "Nimi",
		user_email: "Sähköposti",
		user_email_mobile: "Email",
		user_phone: "Puhelinnumero",
		user_phone_mobile: "Puhelin",
		user_license_plate: "Rekisterinumero",
		user_license_plate_mobile: "Rek.num",
		user_description: "Lisätiedot",
		optional_time_restrictions: "Rajaa voimassaoloaikaa",
		user_has_no_access_rights: "Käyttäjällä ei ole kulkuoikeuksia*",
		user_has_no_access_rights_guide:
			"Kulkuoikeus on mahdollisesti luotu ennen kuin pitkäaikaisvaraukseen on liitetty ovia tai kenttiä.",
		effective_time_duration: "Päättymisaika" /* "Voimassaoloaika" */,
		no_end_date: "Ei päättymisaikaa",
		no_users_found: "Käyttäjiä ei löytynyt",
		pending: "Odottaa vahvistusta.",
		pending_short: "Odottaa",
		not_started: "Ei vielä alkanut",
		ended: "Päättynyt",
		ongoing: "Voimassa",
		ends_soon: "Päättyy pian",
		not_valid: "Ei voimassa",
		status: "Tila",
		add_from_csv: "Lisää käyttäjälista laskentataulukosta.",
		add_from_csv_description: "Katso esimerkkitiedostot täältä: ",
		valid_rows: "Kelvolliset Käyttäjät",
		invalid_rows: "Kelvottomat rivit tiedostossa",
		add_users: "Lisää käyttäjät",
		upload_csv: "Valitse tiedosto",
		no_valid_rows_in_file: "Tiedostossa ei ole kelvollisia rivejä",
		no_space_for_all_users:
			"Ryhmässä ei ole tilaa kaikille käyttäjille. Poista vanhoja käyttäjiä tai lisää tilaa sijainnin asetuksista.",
		no_space_for_new_users:
			"Ryhmässä ei ole tilaa uudelle käyttäjälle. Poista vanhoja käyttäjiä tai lisää tilaa sijainnin asetuksista.",
		download_example_file: "Katso esimerkkitiedosto täältä: ",
		from_here: "täältä",
		selected_file_not_csv:
			"Valittu tiedosto ei ole tuettua muotoa. Valitse Excel- Numbers, tai CSV-tiedosto."
	},
	server_messages: {
		default: "Jokin meni pieleen", // ***** Default error message *****
		default_success: "", // ***** Default success message *****
		client_remove_door_permission: {
			200: "Kulkuoikeus poistettu onnistuneesti!",
			201: "Kulkuoikeus poistettu onnistuneesti!",
			204: "Kulkuoikeus poistettu onnistuneesti!"
		},
		login: {
			200: "Kirjauduit onnistuneesti sisään!",
			401: "Kirjaudu sisään", // Unauthorized, need to relogin
			403: "Jokin meni pieleen, yritä uudestaan!",
			500: "Jokin meni pieleen, yritä uudestaan!"
		},
		password_reset: { 200: "Salasana vaihdettu onnistuneesti." },
		code_login: { 500: "Antamasi koodi ei toiminut." },
		user_register: { 500: "Ei voitu lisätä." },

		user_update: {
			200: "Käyttäjätiedot tallennettu!",
			500: "Ei voitu muokata."
		},
		client_invite: {
			200: "Käyttäjä lisätty hallinnoijaksi!",
			500: "Tarkista sähköpostiosoite."
		},
		client_remove: { 200: "", 500: "" },

		location: { 500: "Ei voitu lisätä." },
		types: { 500: "Ei voitu hakea." },

		book_reservation: { 500: "Ei voitu lisätä." },
		add_continuous: { 200: "", 500: "" },
		new_reservable: { 500: "Ei voitu tallentaa." },
		get_reservations: { 200: "", 500: "" },
		reservation_remove: {
			200: "Käyttäjän hallinnointioikeus poistettu!",
			500: "Jokin meni pieleen, yritä uudestaan!"
		},
		update_reservable: { 500: "Ei voitu päivittää." },
		remove_reservable: { 500: "Ei voitu poistaa." },
		short_reservable_times: {
			500: "Ei voitu hakea."
		},
		get_reservables: { 500: "Ei voitu hakea." },
		get_purchaseable_types: { 200: "", 500: "Ei voitu hakea." },

		get_locations: { 200: "", 500: "Ei voitu hakea." },
		get_location_doors: {
			500: "Ei voitu hakea."
		},
		get_location_clients: { 500: "Ei voitu hakea." },

		new_product: { 500: "Ei voitu tallentaa." },
		update_product: { 500: "Ei voitu päivittää." },
		get_products: {
			500: "Ei voitu hakea."
		},

		courses: {
			200: "",
			500: "Ei voitu hakea."
		},
		add_course: {
			200: "Kurssi lisätty onnistuneesti!",
			500: "Ei voitu lisätä."
		},
		update_course: {
			200: "Kurssi päivitetty onnistuneesti!",
			500: "Ei voitu muokata."
		},
		remove_course: {
			200: "Kurssi poistettu!",
			500: "Poisto epäonnistui, yritä uudelleen!"
		},
		course_users: { 500: "Ei voida hakea." },
		add_door_permission: {
			200: "Käyttäjä lisättiin onnistuneesti!",
			500: "Ei voitu lisätä.",
			550: "Sähköpostikutsua ei voitu lähettää antamaasi sähköpostiin!"
		},
		get_door_users: {
			500: "Virhe! Yritä uudestaan!"
		},
		remove_door_permission: {
			200: "Käyttäjän oikeudet poistettiin onnistuneesti!",
			500: "Ei voitu poistaa."
		},

		location_update: {
			hide: "Kohde on piilotettu.",
			unhide: "Kohde näkyy julkisesti.",
			200: "",
			500: "Ei voitu muokata."
		},

		get_images: { 500: "Ei voitu hakea." },
		upload: {
			201: "Kuva lähetetty onnistuneesti!",
			500: "Kuvaa ei voitu lisätä!",
			maxFileSize: "Kuva liian iso!",
			acceptType: "Vain .jpg, .jpeg ja .png sallittu!",
			maxNumber: "",
			resolution: "Kuvan resoluutio liian iso!"
		},

		get_orders: { 200: "", 500: "Ei voitu hakea." },
		get_customers: { 500: "Ei voitu hakea." },

		logs: { 500: "Ei voitu hakea." },
		/* Add new POST routes below, GET errors under general */
		general: {
			403: "Sijainnilla ei ole oikeuksia suorittaa toimintoa loppuun.",
			0: "Palvelimella tehdään huoltotöitä",
			500: "Jokin meni vikaan.",
			200: "Onnistui!"
		},
		door_update: {
			200: "",
			500: "Jokin meni pieleen",
			403: "Sinulla ei ole oikeuksia suorittaa toimintoa loppuun.",
			400: "Tarkista syöttämäsi tiedot"
		},
		client_course: {
			200: "",
			201: "Uusi kurssi luotiin onnistuneesti.",
			422: "Tarkista syöttämäsi tiedot"
		},
		location_add_door: {
			500: "Ei voitu lisätä.",
			403: "Sinulla ei ole oikeutta suorittaa toimintoa.",
			422: "Tarkista syöttämäsi tiedot."
		},
		client_new_reservable: {
			403: "Sijainnilla ei ole oikeuksia suorittaa toimintoa loppuun."
		},
		client_product: {
			403: "Sijainnilla ei ole oikeuksia suorittaa toimintoa loppuun."
		},
		client_excluded_times_add: {
			400: "Virhe! Varausta ei voida lisätä päällekkäisen vuoron takia.",
			500: "Varmista ettei tilavarauksen alle jää varattuja vuoroja. Poista vuorojen varaukset ennen tilavarauksen lisäämistä"
		},
		client_reservations: {
			500: "Varmista ettei varauksen alle jää varattuja vuoroja. Poista vuorojen varaukset ennen tilavarauksen lisäämistä"
		},
		client_update_reservable: {
			200: "Tilaa muokattu onnistuneesti."
		},
		client_residents: {
			400: "Jokin meni pieleen, yritä uudestaan!",
			403: "Jokin meni pieleen, yritä uudestaan!",
			422: "Jokin meni pieleen, yritä uudestaan!"
		}
	},
	server_error_responses: {
		default: "Jokin meni pieleen, yritä uudestaan!", // incase there is no translation for the error code, this will be shown with original error response message which is in english
		unauthorized: "Sinulla ei ole oikeuksia suorittaa toimintoa loppuun.",
		times_collide_with_another_reservable_time:
			"Aika menee päällekkäin toisen vuoron kanssa",
		times_collide_with_another_reservable_time_guide: ""
	},

	user_manuals: {
		user_manuals:
			"Käyttöohjeita ToloGo:n mobiili- ja web-sovelluksen käyttöön",
		tologo_introduction: "ToloGo:n käyttöönotto",
		tologo_access: "Kulkeminen ToloGo:lla",
		tologo_booking: "Varaaminen ToloGo:lla",
		tologo_charging: "Lataaminen ToloGo:lla",
		admin_introduction: "ToloGo-adminin käyttöönotto",
		admin_access: "Kulunvalvontakohteen hallinnointi",
		admin_booking: "Varaamiskohteen hallinnointi",
		admin_charging: "Latauskohteen hallinnointi",
		admin_key_management: "Avainlistakohteen hallinnointi",
		in_finnish: "Suomeksi",
		in_english: "In English",
		FAQ: "Usein kysytyt kysymykset"
	}
};
