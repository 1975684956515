import React, { useEffect, useContext, useState } from "react";
import { Box, Stack, FormControl, Button, Collapse } from "@mui/material";
import { UserContext } from "../UserContext";

import moment from "moment";
import { Locale } from "../Calendar/Config/Locale";

import { ImageUpload } from "../Common/ImageUpload";
import { ReactComponent as TrashIcon } from "../../Assets/Icons/TrashDeleteIcon.svg";
import { MultiChip } from "../Common/MultiChip";
import { ApiContext } from "../ApiContext";

import { CourseMeetView } from "./CourseMeetView";
import { Spinner } from "../Common/Spinner";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop, isMobile } from "react-device-detect";

import { TextFieldSelect } from "../Common/TextFieldSelect";

import { ReactComponent as ServiceClassIcon } from "../../Assets/Icons/MainIcon.svg";
import { ReactComponent as DescriptionIcon } from "../../Assets/Icons/PenIcon.svg";
import { ReactComponent as CourseAdminIcon } from "../../Assets/Icons/UserIcon.svg";
import { ReactComponent as AdminEmailIcon } from "../../Assets/Icons/EnvelopeIcon.svg";
import { ReactComponent as CourseNameIcon } from "../../Assets/Icons/EditIcon.svg";
import { ReactComponent as TaxPercentIcon } from "../../Assets/Icons/PercentIcon.svg";
import { ReactComponent as PriceIcon } from "../../Assets/Icons/WalletIcon.svg";
import { ReactComponent as UsersIcon } from "../../Assets/Icons/UserGroupIcon.svg";

import { TextFieldInput2 } from "../Common/TextFieldInput2";
import { TaxPercentages } from "../../Datafields/TaxPercentages";
import { AlertBar } from "../Common/AlertBar";
import { ConfirmAction } from "../Common/ConfirmAction";
import { useTranslation } from "react-i18next";

export const AddCourse = ({ handleDrawerClose, location, data }) => {
	const debug = false;
	const { t } = useTranslation();

	const [reservableTypes, setReservableTypes] = useState([]);
	const [selectType, setSelectType] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [contactPersonEmail, setContactPersonEmail] = useState("");
	const [price, setPrice] = useState("");
	const [vat, setVat] = useState("");
	const [userLimit, setUserLimit] = useState("");
	const [description, setDescription] = useState("");
	const [courseName, setCourseName] = useState("");
	const [courseImage, setCourseImage] = useState("");
	const [updateMode, setUpdateMode] = useState(false);
	const [courseId, setCourseId] = useState("");
	const [reservableList, setReservableList] = useState([]);
	const [selectedFields, setSelectedFields] = useState([]);
	const [courseMeets, setCourseMeets] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [firstTime, setFirstTime] = useState(true);

	const [showError, setShowError] = useState(null);

	const [phase, setPhase] = useState(0);

	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);
	moment.updateLocale("fi", Locale);

	const handleCourseImage = (props) => {
		setCourseImage(props);
	};

	const handleSuccess = () => {
		handleDrawerClose();
	};

	/* const handleTaxChange = (value) => {
		(value);
	}; */

	const handleRemove = () => {
		ApiCall("DELETE", `client/course/${courseId}`).then((response) => {
			if (debug) console.log(response);
			handleSuccess(response);
		});
	};

	const apiRoute = updateMode ? `client/course/${courseId}` : "client/course";

	const AxiosBody = {
		contact_email: contactPersonEmail,
		contact_name: contactPerson,

		course_dates: courseMeets.map((entry) => ({
			from_time: new Date(entry.from_time).toISOString(),
			to_time: new Date(entry.to_time).toISOString(),
			reservables: selectedFields.map((reservable) => ({
				short_reservable_id: reservable.id
			}))
		})),
		description,
		logo_image: {
			id: updateMode ? data.logo_image.id : 0,
			image: courseImage,
			image_type: 6,
			object_id: updateMode ? data.logo_image.object_id : 0
		},
		name: courseName,
		service_class_id: Number(selectType),
		user_limit: Number(userLimit),
		vat_percentage: Number(vat),
		vat_price: Number(price)
	};

	const HandleSelect = updateMode
		? () => handleCourseUpdate()
		: () => handleNewCourse();

	const handleFieldChange = (props) => {
		setSelectedFields(props);
	};

	const handleSelectChange = (value) => {
		setSelectType(value);
	};

	const handleNewData = (props) => {
		setCourseMeets(props);
	};

	const validateEmail = () => {
		if (firstTime) return true;
		const re = /\S+@\S+\.\S+/;
		return re.test(contactPersonEmail);
	};

	const handleNewCourse = () => {
		setShowLoader(true);
		AxiosBody.location_id = selectedLocation.location_id;
		ApiCall("POST", apiRoute, AxiosBody)
			.then((response) => {
				if (debug) console.log(response);
				handleSuccess(response);
				setShowLoader(false);
				setShowError(null);
			})
			.catch((response) => {
				if (debug) console.log(response);
				setShowLoader(false);
				setShowError(response);
			});
	};

	const handleCourseUpdate = () => {
		setShowLoader(true);
		ApiCall("PUT", apiRoute, AxiosBody)
			.then((response) => {
				if (debug) console.log(response);
				handleSuccess(response);
				setShowLoader(false);
				setShowError(null);
			})
			.catch((e) => {
				if (debug) console.log(e);
				setShowLoader(false);
				setShowError(e);
			});
	};

	const fetchReservables = () => {
		ApiCall("GET", "client/get_purchaseable_types")
			.then((response) => {
				if (debug) console.log(response);
				setReservableTypes(response);
			})
			.catch((response) => {
				if (debug) console.log(response);
			});
	};

	useEffect(() => {
		if (data !== undefined /* && contactPersonEmail === "" */) {
			setSelectType(data.service_class_id);
			setContactPerson(data.contact_name);
			setPrice(data.vat_price);
			setVat(data.vat_percentage);
			setUserLimit(data.user_limit);
			setCourseName(data.name);
			setContactPersonEmail(data.contact_email);
			setFirstTime(false);
			setDescription(data.description);
			if (data.course_dates.length > 0)
				setSelectedFields(
					data.course_dates[0].reservables.map((entry) => ({
						id: entry.short_reservable_id
					}))
				); // ToDo: mappaa course_dates erikseen, jos kurssin eri tapaamiset on eri kentillä! Tätä valintaa ei ole vielä mahdollista tehdä nykyisessä UIssa.

			setCourseId(data.id);
			setCourseImage(data.logo_image.image);
			setUpdateMode(true);
		}
	}, [reservableTypes]);

	useEffect(() => {
		ApiCall(
			"GET",
			`location/get_reservables/${selectedLocation.location_id}`
		).then((res) => {
			setReservableList(res);
		});
	}, [selectType]);

	useEffect(() => {
		fetchReservables();
	}, [location]);

	useEffect(() => {
		if (contactPersonEmail !== "") setFirstTime(false); // no need to show error message on first render on empty form
	}, [contactPersonEmail]);

	useEffect(() => {
		if (data !== undefined) setPhase(5);
	}, []);

	useEffect(() => {
		if (courseName) setPhase(1);
		if (selectType !== "" && selectType !== undefined) setPhase(2);
		if (
			contactPerson !== "" &&
			validateEmail(contactPersonEmail) &&
			contactPersonEmail !== "" &&
			contactPersonEmail.length > 1
		)
			setPhase(3); // prevents next phase reveal happen too early while typing
		if (description !== "") setPhase(4);
		if (userLimit !== "") setPhase(5);
	}, [
		courseName,
		selectType,
		contactPerson,
		contactPersonEmail,
		description,
		userLimit,
		price,
		vat,
		courseMeets
	]);

	return (
		<Box
			sx={{
				backgroundColor: "#FFFFFF",
				width: isMobile ? "85dvw" : "100%",
				margin: "auto"
			}}
		>
			<Stack
				spacing={1}
				sx={{
					height: "100%",
					display: "flex",
					flexFlow: "column"

					// width: "100%"
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						padding: "10px"
					}}
				>
					{data && (
						<ConfirmAction
							hasChild={true}
							handleTitle={t("courses.course_remove_from_list")}
							handleConfirmAction={() => handleRemove()}
						>
							<TrashIcon
								stroke={ColorScheme.red}
								height={"28px"}
								width={"28px"}
							/>
						</ConfirmAction>
					)}
				</div>

				<FormControl fullWidth>
					<Stack gap={"10px"}>
						<Collapse in={phase >= 0} collapsedSize={true}>
							<TextFieldInput2
								text={courseName}
								title={t("courses.course_name_label")}
								valueChange={setCourseName}
								hasProps={
									<CourseNameIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</Collapse>

						<Collapse in={phase >= 1} collapsedSize={true}>
							<TextFieldSelect
								options={reservableTypes}
								selected={selectType}
								valueChange={handleSelectChange}
								hasProps={
									<ServiceClassIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
								label={t("courses.reservable_type_selection")}
							/>
						</Collapse>

						{/* <SelectList
										data={reservableTypes}
										current={selectType}
										handleType={handleSelectChange}
									/> */}

						<Collapse in={phase >= 2} collapsedSize={true}>
							<ImageUpload
								imageType={"course"}
								LocationId={location}
								isCourseLogo={true}
								oldImage={courseImage !== null && courseImage}
								passCourseImage={handleCourseImage}
							/>
							<div style={{ height: "10px" }} />
						</Collapse>

						{/* <Typography variant="form_bold">
								{t("courses.course_name_label")}
							</Typography>
						
							<TextField
								value={courseName}
								sx={theme.formStyles.formInput}
								onChange={(text) =>
									setCourseName(text.target.value)
								}
							/> */}

						<Collapse in={phase >= 2} collapsedSize={true}>
							<div
								style={{
									display: "flex",
									flexDirection: isDesktop ? "row" : "column",
									gap: isDesktop ? "20px" : "10px"
								}}
							>
								<TextFieldInput2
									text={contactPerson}
									title={t(
										"courses.course_contact_person_label"
									)}
									valueChange={setContactPerson}
									hasProps={
										<CourseAdminIcon
											stroke={
												ColorScheme.defaultIconColor
											}
											strokeWidth={1.5}
										/>
									}
								/>
								{/* <Typography variant="form_bold">
									{t("courses.course_contact_person_label")}
								</Typography>
								<TextFieldInput
									text={contactPerson}
									valueChange={setContactPerson}
									styled={true}
								/> */}

								<TextFieldInput2
									text={contactPersonEmail}
									title={t(
										"courses.course_contact_email_label"
									)}
									valueChange={setContactPersonEmail}
									checkValue={!validateEmail()}
									hasProps={
										<AdminEmailIcon
											stroke={
												ColorScheme.defaultIconColor
											}
										/>
									}
								/>
								{/* <Typography variant="form_bold">
									{t("courses.course_contact_email_label")}
								</Typography>
								<TextFieldInput
									text={contactPersonEmail}
									valueChange={setContactPersonEmail}
									checkValue={!validateEmail()}
									styled={true}
								/> */}
							</div>
						</Collapse>

						<Collapse in={phase >= 3} collapsedSize={true}>
							<TextFieldInput2
								rowAmount={5}
								hasCharLimit={500}
								charCount={true}
								text={description}
								title={t("courses.course_description_label")}
								valueChange={setDescription}
								hasProps={
									<DescriptionIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</Collapse>

						{phase >= 3 && (
							<div /* temp fix for gap separation */ />
						)}
						{/* <Typography variant="form_bold">
								{t("courses.course_description_label")}
							</Typography>

							<TextFieldInput
								text={description}
								isMultiline={true}
								valueChange={setDescription}
								styled={true}
							/> */}

						<Collapse in={phase >= 4} collapsedSize={true}>
							<TextFieldInput2
								text={userLimit}
								onlyNumbers={true}
								valueChange={setUserLimit}
								title={t("courses.course_user_amount_label")}
								hasProps={
									<UsersIcon
										stroke={ColorScheme.defaultIconColor}
									/>
								}
							/>
						</Collapse>

						{/* <Typography variant="form_bold">
								{t("courses.course_user_amount_label")}
							</Typography>

							<TextFieldInput
								text={userLimit}
								inputType="tel"
								valueChange={setUserLimit}
								styled={true}
							/> */}

						<Collapse in={phase >= 5} collapsedSize={true}>
							<div
								style={{
									display: "flex",
									flexDirection: isDesktop ? "row" : "column",
									gap: isDesktop ? "20px" : "10px"
								}}
							>
								<TextFieldInput2
									text={price}
									title={t("courses.course_price_label")}
									onlyNumbers={true}
									endAdornment={t("common.currency_symbol")}
									valueChange={setPrice}
									hasProps={
										<PriceIcon
											stroke={
												ColorScheme.defaultIconColor
											}
											strokeWidth={2.5}
											height={26}
										/>
									}
								/>
								{/* <Typography variant="form_bold">
									{t("courses.course_price_label")}
								</Typography>

								<TextFieldInput
									text={price}
									inputType="tel"
									valueChange={setPrice}
									hasProps={t("common.currency_symbol")}
									styled={true}
								/> */}

								<TextFieldSelect
									options={TaxPercentages}
									label={t("courses.course_tax_label")}
									selected={vat}
									valueChange={setVat}
									hasProps={
										<TaxPercentIcon
											stroke={
												ColorScheme.defaultIconColor
											}
										/>
									}
								/>
								{/* <Typography variant="form_bold">
									{t("courses.course_tax_label")}
								</Typography>

								<TaxPercent
									hideLabel={true}
									handleChange={setVat}
									current={vat}
								/> */}
							</div>
						</Collapse>

						<Collapse in={phase >= 5} collapsedSize={true}>
							<MultiChip
								getLabel={t("courses.course_used_reservables")}
								data={reservableList}
								current={selectedFields}
								passSelectedData={handleFieldChange}
								isReservable={true}
							/>
						</Collapse>

						<Collapse in={phase >= 5} collapsedSize={true}>
							<div style={{ height: "20px" }} />
							<CourseMeetView
								oldData={
									data !== undefined ? data.course_dates : []
								}
								passNewData={handleNewData}
							/>
						</Collapse>
					</Stack>
				</FormControl>

				<Collapse in={showError !== null}>
					<div
						style={{
							paddingTop: "20px"
						}}
					>
						<AlertBar
							type="error"
							showAlert
							textContent={showError}
						/>
					</div>
				</Collapse>

				<div style={{ flex: 1, minHeight: "25px" }} />

				<Box
					sx={{
						flexDirection: "row",
						display: "flex",
						width: "100%",
						justifyContent: "space-between",
						gap: "10px"
					}}
				>
					<Button
						sx={{ width: "50%" }}
						variant="outlined"
						onClick={() => handleDrawerClose()}
					>
						{t("buttons.cancel")}
					</Button>

					<Button
						sx={{
							width: "50%",
							backgroundColor: showLoader ? "white" : "",
							border: showLoader
								? `1px solid ${ColorScheme.tolotechBlue}`
								: ""
						}}
						variant="drawerFormAccept"
						disabled={showLoader}
						onClick={() => {
							HandleSelect();
						}}
					>
						{showLoader ? (
							<Spinner
								hasText={t("spinner_text.wait")}
								small={true}
								color={"black"}
								hasColor={ColorScheme.tolotechBlue}
							/>
						) : (
							t("buttons.save")
						)}
					</Button>
				</Box>
			</Stack>
			<div style={{ height: "25px" }} />
		</Box>
	);
};
