import React from "react";
// import { useTranslation } from "react-i18next";
// import { ReactComponent as ArrowSide } from "../../Assets/Icons/arrowSide.svg";
import { ReactComponent as ArrowBack } from "../../Assets/Icons/arrowBack.svg";
import { ColorScheme } from "../../Themes/ColorScheme";

export const CloseButton = ({ closeDrawer }) => {
	const iconColor = "white";
	const strokeWidth = 3.5;
	const iconWidth = "45px";
	const iconHeight = "35px";

	return (
		/* const { t } = useTranslation(); */
		<div
			onClick={(event) => closeDrawer(event)}
			style={{
				display: "flex",
				border: "1px solid #1C568F",
				"&:hover": {
					backgroundColor: ColorScheme.tolotechDarkBlue
				},

				fontSize: "12px",
				textTransform: "none",
				width: "59px",
				height: "48px",
				backgroundColor: "#264166",
				borderRadius: "4px",
				alignItems: "center",
				justifyContent: "center"
			}}
		>
			{/* <Typography>{t("buttons.close")}</Typography> */}
			{/* <ArrowSide /> */}
			<ArrowBack
				stroke={iconColor}
				strokeWidth={strokeWidth}
				height={iconHeight}
				width={iconWidth}
			/>
		</div>
	);
};
