import React, { useContext, useEffect, useState } from "react";
// import CustomizeIcon from "../../../Assets/Icons/CustomizeIcon.svg";
import { Typography, Box, Collapse } from "@mui/material";
import moment from "moment";
import { Locale } from "../../Calendar/Config/Locale";
import { Spinner } from "../../Common/Spinner";
import { ApiContext } from "../../ApiContext";
import { useTranslation } from "react-i18next";
import { DoorUserDesktop } from "./Cards/DoorUserDesktop";
import { ReactComponent as Arrow } from "../../../Assets/Icons/arrowDown.svg";
import { ColorScheme } from "../../../Themes/ColorScheme";

export const ListDoorUsers = ({ info, search }) => {
	moment.updateLocale("fi", Locale);

	const debug = false;

	const [doorUsers, setDoorUsers] = useState([]);
	const [loading, setLoading] = useState(true);

	const [refetch, setRefetch] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);

	const [pageNumber, setPageNumber] = useState(1);
	const [totalPages /* setTotalPages */] = useState(
		Math.ceil(info.user_count / 10) || 0
	);

	const [newSearch, setNewSearch] = useState(false); // testing collapse animation
	/* const [totalDoorUsers, setTotalDoorUsers] = useState(0); */

	const [leftButtonHovered, setLeftButtonHovered] = useState(false);
	const [rightButtonHovered, setRightButtonHovered] = useState(false);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const handlePageChange = (newPageNumber) => {
		setPageNumber(newPageNumber);
	};

	const fetchDoorUsers = () => {
		ApiCall(
			"GET",
			`client/get_door_users/${info.door_id}?page=${pageNumber}&search=${search}`
		)
			.then((users) => {
				setDoorUsers(users);
				setLoading(false);

				if (users.length === 0 && search !== "" && pageNumber > 1)
					setPageNumber(pageNumber - 1); // go back to previous page if no results found
				if (users.length > 0) setNewSearch(false);
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoading(false);
			})
			.finally(() => {
				setFirstLoad(false);
			});
	};

	/* const fetchTotalPages = () => {
		ApiCall(
			"GET",
			`client/get_door_users/${info.door_id}?page=0&search=${search}`
		)
			.then((users) => {
				setTotalPages(Math.ceil(users.length / 10));
				setLoadingPages(false);
			})
			.catch((error) => {
				if (debug) console.log(error);
				setLoadingPages(false);
			});
	}; */

	const handleFetch = () => {
		fetchDoorUsers();
	};

	const handleRemove = (props) => {
		ApiCall("POST", "client/remove_door_permission", {
			id: props.id.toString()
		})
			.then(() => {
				fetchDoorUsers();
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		setLoading(true);
		fetchDoorUsers();
		setRefetch(false);
		/* if (refetch || totalPages === 0) fetchTotalPages(); // fetches total pages only once at page load or after new search */
	}, [pageNumber, refetch === true]);

	useEffect(() => {
		setLoading(true);

		if (firstLoad) {
			setNewSearch(false);
			return;
		}

		if (doorUsers.length === 0) setLoading(true);
		else setLoading(false);

		/* if (!newSearch && doorUsers.length > 0) setNewSearch(true); */

		const debounceTimerForSearch = setTimeout(() => {
			setNewSearch(true);
		}, 200);

		const debounceTimer = setTimeout(() => {
			setPageNumber(1);
			setDoorUsers([]);
			setRefetch(true);
			/* console.log(""); */
		}, 300);

		return () => {
			clearTimeout(debounceTimerForSearch);
			clearTimeout(debounceTimer);
		};
	}, [search]);

	useEffect(() => {
		setLoading(true);
	}, [search]);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
			{/* 	{!loading && doorUsers.length > 0 && ( */}
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					boxSizing: "border-box",
					borderRadius: "4px",
					width: "100%",
					minHeight: "64px",
					margin: "auto",
					border: "1px solid " + ColorScheme.textFieldBorder,
					backgroundColor: "#FAFAFA",
					marginTop: "20px",
					transition: "height 0.3s ease-in-out"
				}}
			>
				<Collapse
					in={!loading && !newSearch}
					easing="ease-out"
					timeout={{ enter: 500, exit: 300 }}
				>
					{doorUsers.map((user, index) => {
						if (user === undefined) return null;
						const splitWeekdays = user.weekdays.split("");

						return (
							<React.Fragment key={user.id}>
								<Box>
									{/* {isMobile ? (
										<DoorUserMobile
											splitWeekdays={splitWeekdays}
											user={user}
											handleRemove={handleRemove}
											fetchNewData={handleFetch}
										/>
									) : ( */}
									<DoorUserDesktop
										splitWeekdays={splitWeekdays}
										user={user}
										handleRemove={handleRemove}
										fetchNewData={handleFetch}
									/>
									{/* )} */}
								</Box>

								{index !== doorUsers.length - 1 && (
									<Box
										sx={{
											width: "100%",
											height: "1px",
											backgroundColor: "rgba(0,0,0,0.1)"
										}}
									/>
								)}
							</React.Fragment>
						);
					})}
				</Collapse>

				<div
					style={{
						height: "64px",
						display: doorUsers.length > 0 ? "none" : "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<div
						style={{
							display:
								loading || (newSearch && loading)
									? "flex"
									: "none"
						}}
					>
						<Spinner color={ColorScheme.tolotechDarkBlue} />
					</div>

					<div
						style={{
							display:
								!loading &&
								doorUsers.length === 0 /*  && !newSearch */
									? "flex"
									: "none",
							alignItems: "center",
							padding: "20px"
						}}
					>
						<Typography
							sx={{
								fontSize: "14px",
								fontWeight: "700",
								color: "rgba(0,0,0,0.5)"
							}}
						>
							{search === ""
								? t("doors.no_access_right")
								: t("doors.no_search_results")}
						</Typography>
					</div>
				</div>
			</Box>
			{/* )} */}

			{/* {doorUsers.length !== 0 && doorUsers.length % 10 === 0 ? ( */}

			<Box
				sx={{
					display:
						newSearch || doorUsers.length === 0 ? "none" : "flex",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "20px",
					gap: "10px",
					transform: "fade 0.3s ease-in-out"
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						transform: "rotate(90deg)",
						cursor: "pointer",
						height: "25px",
						width: "25px",
						alignContent: "center",
						justifyContent: "center",

						...(leftButtonHovered && {
							backgroundColor: ColorScheme.lighterGrey,
							borderRadius: "50%",
							height: "25px",
							width: "25px"
						})
					}}
					onMouseOver={() => {
						if (pageNumber === 1) return;
						setLeftButtonHovered(true);
					}}
					onMouseOut={() => {
						setLeftButtonHovered(false);
					}}
				>
					<Arrow
						style={{ transition: "stroke 0.3s ease-in-out" }}
						stroke={
							pageNumber === 1
								? ColorScheme.lighterGrey
								: ColorScheme.tolotechBlue
						}
						onClick={() =>
							pageNumber === 1
								? undefined
								: handlePageChange(pageNumber - 1)
						}
					/>
				</div>
				{search !== "" ? (
					<Typography>{pageNumber}</Typography>
				) : (
					<Typography
						sx={{
							margin: "0 20px",
							wordSpacing: "15px"
						}}
					>
						{t("doors.door_users_page")}{" "}
						<select
							value={pageNumber}
							onChange={(event) =>
								setPageNumber(Number(event.target.value))
							}
							style={{
								verticalAlign: "middle",
								cursor: "pointer",
								padding: "5px",
								border: 0,
								borderBottom: "1px solid black",
								appearance: "none", // hide dropdown arrow
								fontFamily: "inherit", // use the same font as the parent element
								fontSize: "inherit" // use the same font size as the parent element
							}}
						>
							{Array.from(
								{ length: totalPages },
								(_, i) => i + 1
							).map((page) => (
								<option
									key={page}
									value={page}
									style={{ textAlign: "center" }}
								>
									{page}
								</option>
							))}
						</select>{" "}
						/ {totalPages}
					</Typography>
				)}

				<div
					style={{
						display: "flex",
						alignItems: "center",
						transform: "rotate(-90deg)",
						cursor: "pointer",
						height: "25px",
						width: "25px",
						alignContent: "center",
						justifyContent: "center",

						...(rightButtonHovered && {
							backgroundColor: ColorScheme.lighterGrey,
							borderRadius: "50%",
							height: "25px",
							width: "25px"
						})
					}}
					onMouseOver={() => {
						if (doorUsers.length < 10 || pageNumber === totalPages)
							return;
						setRightButtonHovered(true);
					}}
					onMouseOut={() => {
						setRightButtonHovered(false);
					}}
				>
					<Arrow
						stroke={
							doorUsers.length < 10 || pageNumber === totalPages
								? ColorScheme.lighterGrey
								: ColorScheme.tolotechBlue
						}
						onClick={() =>
							doorUsers.length < 10 || pageNumber === totalPages
								? undefined
								: handlePageChange(pageNumber + 1)
						}
					/>
				</div>
			</Box>
		</div>
	);
};
