import { Stack, Typography, Box } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { ColorScheme } from "../../Themes/ColorScheme";

export const ClientOrderCard = ({ data, setSelectedOrder, closeDialog }) => {
	// console.log(data);
	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();

	/* const [content, setContent] = useState({}); */
	const [name, setName] = useState("");
	/* const [continuous, setContinuous] = useState(false); */

	/* const passOrder = (event) => {
		event.stopPropagation();
		event.preventDefault();
		handleOrder({ event, order: data, user });
	}; */

	useEffect(() => {
		data.order_contents.map((content) => {
			let array;
			if (content.content_type === 2) {
				array = content.name.split(", ");
				/* setContent(content); */
				if (array.length === 2) {
					setName(array[0]);
					/* setContinuous(false); */
				} else {
					setName(array[1]);
					/* setContinuous(true); */
				}
			} else {
				setName(t("orders.other_label"));
			}
		});
	}, []);

	return (
		selectedLocation !== undefined && (
			<>
				<Stack
					onClick={() => {
						setSelectedOrder(data);
						closeDialog(null);
					}}
					sx={{
						backgroundColor: "white",
						flexDirection: "row",
						display: "flex",
						color: "black",
						paddingX: 0,
						paddingY: "10px",
						borderRadius: "2px",
						marginBottom: "2px",
						alignItems: "center",
						border: "1px solid white",
						minWidth: "250px",
						":hover": {
							backgroundColor: "#FAFAFA"
						},
						cursor: "pointer"
					}}
				>
					{!isMobile && (
						<Stack sx={{ flex: 1 }}>
							<Typography
								sx={{
									fontSize: "10px",
									color: "rgba(0,0,0,0.4)",
									fontWeight: "700"
									// minWidth: isMobile ? undefined : "80px"
								}}
							>
								{moment(data.created_at).format(
									"DD.MM.YY, HH:mm"
								)}
							</Typography>
						</Stack>
					)}
					{!isMobile ? (
						<>
							<Stack sx={{ flex: 0.5 }}>
								<Typography
									sx={{
										fontSize: "10px"
									}}
								>
									#{data.order_id}
								</Typography>
							</Stack>

							<Stack sx={{ flex: 1.5 }}>
								<Typography
									sx={{
										fontSize: "10px",
										fontWeight: "700"
									}}
								>
									{name}
								</Typography>
							</Stack>
						</>
					) : (
						<>
							<Stack sx={{ flex: 1.5 }}>
								<Typography
									sx={{
										fontSize: "10px",
										color: "rgba(0,0,0,0.4)",
										fontWeight: "700"
										// minWidth: isMobile ? undefined : "80px"
									}}
								>
									{moment(data.created_at).format(
										"DD.MM.YY, HH:mm"
									)}
								</Typography>
								<Typography
									sx={{
										fontSize: "10px",
										fontWeight: "700"
									}}
								>
									{name}
								</Typography>
							</Stack>
						</>
					)}

					{!isMobile ? (
						<Stack sx={{ flex: isMobile ? 1 : 1.5 }}>
							<Typography
								sx={{
									fontSize: "10px",
									fontWeight: "700"
								}}
							>
								{data.total_vat_price.toFixed(2) +
									" " +
									t("common.currency_symbol")}
							</Typography>
						</Stack>
					) : (
						<Stack
							sx={{
								backgroundColor: data.order_succesful
									? "green"
									: "red",
								padding: "4px",
								borderRadius: "20px",
								width: "70px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<Typography
								sx={{
									fontSize: "10px",
									fontWeight: "700",
									color: ColorScheme.tolotechWhite
								}}
							>
								{data.total_vat_price.toFixed(2) +
									" " +
									t("common.currency_symbol")}
							</Typography>
						</Stack>
					)}

					{!isMobile && (
						<Box
							sx={{
								flex: 1,
								flexDirection: "row",
								display: "flex",
								justifyContent: "center"
							}}
						>
							{data.order_succesful &&
								data.refund_status !== 1 && (
									<Box
										sx={{
											width: "90px",
											// px: "10px",
											backgroundColor: "green",
											borderRadius: 10,
											alignItems: "center",
											justifyContent: "center",
											// paddingX: "25px",
											paddingY: "3px"
										}}
									>
										<Typography
											sx={{
												fontSize: "10px",
												color: "white",
												textAlign: "center"
											}}
										>
											{t("orders.paid_label")}
										</Typography>
									</Box>
								)}
							{data.refund_status === 1 && (
								<Box
									sx={{
										width: "90px",
										/* px: "10px", */
										backgroundColor: "gray",
										borderRadius: 10,
										alignItems: "center",
										justifyContent: "center",
										/* paddingX: "20px", */
										paddingY: "3px"
									}}
								>
									<Typography
										sx={{
											fontSize: "10px",
											color: "white",
											textAlign: "center"
										}}
									>
										{t("orders.successful_cancel_label")}
									</Typography>
								</Box>
							)}
							{data.refund_status !== 1 &&
								!data.order_succesful && (
									<Box
										sx={{
											width: "90px",
											/* px: "10px", */
											backgroundColor: "#DC5151",
											borderRadius: 10,
											alignItems: "center",
											justifyContent: "center",
											/* paddingX: "15px", */
											paddingY: "3px"
										}}
									>
										<Typography
											sx={{
												fontSize: "10px",
												color: "white",
												textAlign: "center"
											}}
										>
											{t("orders.unpaid_label")}
										</Typography>
									</Box>
								)}
						</Box>
					)}
				</Stack>
			</>
		)
	);
};
