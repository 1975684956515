import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Locale } from "../Calendar/Config/Locale";
import timeDifferenceInHoursAndMinutes from "../../Helpers/TimeDifferenceInHoursAndMinutes";
import TooltipWrapper from "../Common/TooltipWrapper";
import FormatDateTime from "../../Helpers/FormatDateTime";
import { isDesktop } from "react-device-detect";

export const LogsDesktop = ({ data }) => {
	moment.updateLocale("fi", Locale);

	const { t } = useTranslation();

	/* console.log((data.stop_meter - data.start_meter) / 1000); */

	return (
		<Box
			sx={{
				maxWidth: "100%",
				flexDirection: "row",
				padding: "15px",
				justifyContent: "space-between",
				display: "flex",
				alignItems: "center"
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "flex-start",
					flexDirection: "column"
				}}
			>
				<Typography
					sx={{
						fontSize: "20px",
						fontWeight: "regular"
					}}
				>
					{data.user?.name || "Poistettu käyttäjä"}
				</Typography>
				<Typography
					sx={{
						color: "rgba(0,0,0,0.5)",
						fontWeight: "regular",
						fontSize: "12px"
						/* maxWidth:
					"clamp(100px, 100%, 200px)",
				overflow: "hidden",
				textOverflow: "ellipsis",
				display: "inline-block",
				wordWrap: "break-all" */
					}}
				>
					{data.user?.email || ""}
				</Typography>
			</Box>

			<Stack
				direction="row"
				spacing={2}
				style={{
					alignItems: "center",
					/* border: "1px solid black", */
					borderRadius: "25px",
					justifyContent: "center"
				}}
			>
				{new Date(data.start_time).getTime() >
				new Date(data.end_time).getTime() ? (
					<Typography sx={{ padding: "10px" }}>
						{t("charging.ongoing_charging")}
					</Typography>
				) : (
					<Box
						sx={{
							display: "flex",
							border:
								"2px solid" +
								((data.stop_meter - data.start_meter) / 1000 ===
								0
									? "#D4D9E0"
									: (data.stop_meter - data.start_meter) /
												1000 <=
										  20
										? "#FEE49F"
										: "#DCE9D6"),
							borderRadius: "25px",
							justifyContent: "space-between",
							alignItems: "center",
							flexDirection: "row-reverse",
							overflow: "hidden"
						}}
					>
						<Box
							sx={{
								display: "flex",
								minWidth: isDesktop ? "100px" : "50px",
								height: "100%",
								borderRadius: "20px",
								py: "3px",
								px: "6px",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								backgroundColor:
									(data.stop_meter - data.start_meter) /
										1000 ===
									0
										? "#D4D9E0"
										: (data.stop_meter - data.start_meter) /
													1000 <=
											  20
											? "#FEE49F"
											: "#DCE9D6"
							}}
						>
							<Typography
								style={{
									fontSize: 16,
									fontWeight: "bold",
									color:
										(data.stop_meter - data.start_meter) /
											1000 ===
										0
											? "#516785"
											: (data.stop_meter -
														data.start_meter) /
														1000 <=
												  20
												? "#A85D5D"
												: "#75A85D",
									textAlign: "center"
								}}
							>
								{Math.round(
									(data.stop_meter - data.start_meter) / 10
								) / 100}{" "}
								kWh
							</Typography>
						</Box>
						<TooltipWrapper
							ignoreStringRestriction={true}
							title={
								<>
									{FormatDateTime(new Date(data.start_time))}
									<Typography>-</Typography>
									{FormatDateTime(new Date(data.end_time))}
								</>
							}
						>
							<div
								style={{
									minWidth: isDesktop ? "100px" : "60px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}
							>
								{/* <TimerIcon stroke="black" /> */}
								<Typography
									style={{
										color: "black",
										fontSize: "14px",
										fontWeight: "bold"
									}}
								>
									{timeDifferenceInHoursAndMinutes(
										new Date(data.start_time),
										new Date(data.end_time)
									)}
								</Typography>
							</div>
						</TooltipWrapper>
					</Box>
				)}
			</Stack>
		</Box>
	);
};
