import * as XLSX from "xlsx/xlsx.mjs";

export function downloadExcelFromJson(headers, jsonData, fileName) {
	// Map the JSON data to new objects where the keys are the provided headers.
	const dataWithNewHeaders = jsonData.map((obj) => {
		let newObj = {};
		headers.forEach((header, index) => {
			newObj[header] = Object.values(obj)[index];
		});
		return newObj;
	});

	const worksheet = XLSX.utils.json_to_sheet(dataWithNewHeaders);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

	// Generate XLSX file and send to client.
	const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
	const blob = new Blob([wbout], { type: "application/octet-stream" });

	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = fileName + ".xlsx";
	a.click();
	window.URL.revokeObjectURL(url);
}
