import { Button, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import LandingAnimation from "../../Helpers/LandingAnimation";

export const TopContent = ({ getDrawerPath }) => {
	const { t } = useTranslation();
	const openDrawer = () => {
		getDrawerPath("NewCampaign");
	};

	return (
		<Stack
			direction="row"
			justifyContent={isMobile ? "center" : "flex-end"}
			sx={{ width: "100%" }}
		>
			<LandingAnimation>
				<Button
					variant="contained"
					onClick={() => openDrawer()}
					sx={{ width: isMobile ? "100%" : "200px" }}
				>
					{t("buttons.create_campaign")}
				</Button>
			</LandingAnimation>
		</Stack>
	);
};
