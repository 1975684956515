const areaCodesText = `Austria - AT - AUT - +43
Belgium - BE - BEL - +32
Bulgaria - BG - BGR - +359
Croatia - HR - HRV - +385
Cyprus - CY - CYP - +357
Czech Republic - CZ - CZE - +420
Denmark - DK - DNK - +45
Estonia - EE - EST - +372
Finland - FI - FIN - +358
France - FR - FRA - +33
Germany - DE - DEU - +49
Greece - GR - GRC - +30
Hungary - HU - HUN - +36
Ireland - IE - IRL - +353
Italy - IT - ITA - +39
Latvia - LV - LVA - +371
Lithuania - LT - LTU - +370
Luxembourg - LU - LUX - +352
Malta - MT - MLT - +356
Netherlands - NL - NLD - +31
Poland - PL - POL - +48
Portugal - PT - PRT - +351
Romania - RO - ROU - +40
Slovakia - SK - SVK - +421
Slovenia - SI - SVN - +386
Spain - ES - ESP - +34
Sweden - SE - SWE - +46`;

export const areaCodesArray = areaCodesText.split("\n").map((areaCode) => {
	const [country, , , code] = areaCode.split(" - ");
	return { country, code: `${code}` };
});
