import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { Locale } from "./Config/Locale";
import { Header } from "./Components/Header";

import "./Config/theme.css";

import { UserContext } from "../UserContext";
import { MuiDrawer } from "../Common/MuiDrawer";
import { ColorScheme } from "../../Themes/ColorScheme";
import { Toolbar } from "./Components/Toolbar";
import { ApiContext } from "../ApiContext";
import { BackdropLoading } from "../Common/BackdropLoading";
import { Typography, Box, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { LongReservationsView } from "./LongReservationsView";
// import { ListGrouping } from "./Services/ListGrouping";
import { isMobile } from "react-device-detect";
import { ListGroupingSlider } from "./Services/ListGroupingSlider";
import { KeyManagement } from "../KeyManagement/KeyManagement";
import { Event } from "./Components/Event";
import { fi } from "date-fns/locale";
import { format, getWeek, getDay, parse, startOfWeek } from "date-fns";
import LandingAnimation from "../../Helpers/LandingAnimation";
import { DoorList } from "./DoorList";

export const CalendarComponent = () => {
	const debug = false;

	const locales = {
		"fi-FI": fi,
		options: { weekStartsOn: 1 }
	};

	const localizer = dateFnsLocalizer({
		format,
		parse,
		startOfWeek,
		getDay,
		locales
	});

	const { selectedLocation } = useContext(UserContext);
	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const navigate = useNavigate();

	// Empty slots
	const [emptyData, setEmptySlots] = useState([]);
	// Event slot
	const [eventSlot, setEventSlot] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [switchList, setSwitchList] = useState(false);
	const [contentType, setContentType] = useState("");
	const [selectedService, setSelectedService] = useState(null);
	const [saveWeekView, setSaveWeekView] = useState(null);
	const [reservableList, setReservableList] = useState([]);

	// Rage solution to prevent calendar flashing
	/* const [firstLaunchDone, setFirstLaunchDone] = useState(false); */

	const selectedStart =
		selectedService !== "" &&
		selectedService?.open_hours !== undefined &&
		selectedService?.open_hours?.from_time;

	const selectedEnd =
		selectedService !== "" &&
		selectedService?.open_hours !== undefined &&
		selectedService?.open_hours?.to_time;

	const getMinEnd = new Date(selectedEnd).getMinutes();
	const getEnd = new Date(selectedEnd).getHours();

	/* const minStart = new Date(0, 0, 0, 0, 0, 0); */

	const customStart =
		selectedService === "" ||
			new Date(selectedEnd).toISOString() ===
			new Date(selectedStart).toISOString() ||
			!selectedStart
			? new Date(0, 0, 0, 0, 0, 0)
			: new Date(
				0,
				0,
				0,
				new Date(selectedStart).getHours(),
				new Date(selectedStart).getMinutes(),
				0
			);

	/* const maxEnd = new Date(0, 0, 0, 23, 59, 0); */

	// console.log(selectedEnd);

	const customEnd =
		selectedService === "" ||
			new Date(selectedEnd).toISOString() ===
			new Date(selectedStart).toISOString() ||
			!selectedEnd
			? new Date(0, 0, 0, 23, 59, 0)
			: new Date(
				0,
				0,
				0,
				getEnd === "0"
					? 23
					: Number(getEnd) < new Date(customStart).getHours()
						? 23
						: getEnd,
				getMinEnd,
				0
			);

	const selectedYear =
		saveWeekView !== null
			? new Date(saveWeekView).getFullYear()
			: new Date().getFullYear(); // get proper year for first load
	const currentWeek = getWeek(new Date());
	const selectedWeek = getWeek(saveWeekView);
	const ifSunday = getDay(saveWeekView) === 0;
	const selectedDate =
		(saveWeekView && format(saveWeekView, "yyyy-MM-dd")) ||
		format(new Date(), "yyyy-MM-dd");

	const eventsBody = {
		title: "",
		start: "",
		end: "",
		allDay: false,
		created_at: "",
		price: "",
		reservation_id: "",
		user_reservations: [],
		serviceId: "",
		user_limit: "",
		vatPrice: ""
	};

	const [listEvents, setListEvents] = useState(eventsBody);

	const generateList = () => {
		setSwitchList(true);
	};

	const handleRedirect = () => {
		navigate(`/locations/${selectedLocation.id}`);
	};

	const checkForContent = () => {
		if (emptyData.length > 0) return emptyData;
		else if (eventSlot.length > 0) return eventSlot;
		else return [];
	};

	const eventPropGetter = useCallback(
		(event /* start, end, isSelected */) => {
			// if (isSelected) console.log("Selected");
			// Varattu vuoro

			let style = {
				backgroundColor: ColorScheme.tolotechBlue,
				borderRadius: "4px",
				textAlign: "center",
				border: "0px",
				fontSize: "12px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				marginTop: "2px",
				height: "100%",
				/* transform: "scale(0.9)" */

				boxSizing: "border-box"
			};

			// Vapaa yksilövuoro
			if (event.title.includes(t("common.currency_symbol")))
				style.backgroundColor = "#ABABAB";

			// Ryhmävuoro
			if (event.userLimit > 1)
				style.backgroundColor = ColorScheme.tolotechLightBlue;

			// Ryhmävuoro jossa asiakkaita
			if (event.userLimit > 1 && event.users.length >= 1)
				style.backgroundColor = ColorScheme.green;

			// täysi ryhmävuoro
			if (event.userLimit > 1 && event.userLimit === event.users.length)
				style.backgroundColor = ColorScheme.tolotechDarkBlue;

			// Vakivuoro
			if (
				event.userLimit === 1 &&
				event.users.length === 1 &&
				event.userReservations[0].content_type === 2
			)
				style.backgroundColor = ColorScheme.purple;
			// Tilavaraus
			if (
				event.userLimit === 1 &&
				event.users.length === 1 &&
				event.userReservations[0].content_type === 5
			)
				style.backgroundColor = ColorScheme.softRed;

			// Kurssivaraus
			if (
				event.userLimit === 1 &&
				event.users.length === 1 &&
				event.userReservations[0].content_type === 4
			)
				style.backgroundColor = ColorScheme.yellow;

			// API-varaus
			if (
				event.userLimit === 1 &&
				event.users.length === 1 &&
				event.userReservations[0].content_type === 6
			)
				style.backgroundColor = ColorScheme.green;

			// Jaetun kentän varaus
			if (
				event.userLimit === 1 &&
				event.users.length === 1 &&
				event.userReservations[0].content_type === 8
			)
				style.backgroundColor = ColorScheme.softBlue;

			if (
				eventSlot.length > 0 &&
				eventSlot.findIndex((e) => e.id === event.id) !== -1
			)
				style.border = "4px solid " + ColorScheme.tolotechBlue;

			if (
				eventSlot.length > 0 &&
				eventSlot.findIndex((e) => e.id === event.id) === -1 &&
				eventSlot[0].users.length > 0
			)
				style.opacity = 0.2;

			if (
				eventSlot.length > 0 &&
				eventSlot.findIndex((e) => e.id === event.id) === -1 &&
				eventSlot[0].users.length === 0 &&
				(event.users.length !== 0 || event.userLimit !== 1)
			)
				style.opacity = 0.2;

			return {
				style
			};
		},
		[emptyData, eventSlot]
	);

	const handleEventTitle = (data) => {
		if (
			data.user_limit === 1 &&
			data.user_reservations.length === 1 &&
			data.user_reservations[0].content_type === 8
		)
			return t("calendar.multiroom_reservation_label");
		else if (
			data.user_limit === 1 &&
			data.user_reservations.length === 1 &&
			data.user_reservations[0].content_type === 2
		)
			return (
				t("calendar.continuous_reservation_label") +
				" " +
				data.user_reservations[0].user.name
			);
		else if (
			data.user_limit === 1 &&
			data.user_reservations.length === 1 &&
			data.user_reservations[0].content_type === 5
		)
			return t("calendar.reservable_facility_label");
		else if (
			data.user_limit === 1 &&
			data.user_reservations.length === 1 &&
			data.user_reservations[0].content_type === 4
		)
			return t("calendar.course_reservation_label");
		else if (
			data.user_limit === 1 &&
			data.user_reservations.length === 1 &&
			data.user_reservations[0].content_type === 6
		)
			return "API " + data.user_reservations[0].user.name;
		else if (
			data.user_reservations !== undefined &&
			data.user_limit === 1 &&
			data.user_reservations[0] !== undefined &&
			data.user_reservations[0].user !== undefined &&
			data.user_reservations[0].user !== null
		)
			return data.user_reservations[0].user.name !== ""
				? data.user_reservations[0].user.name
				: data.user_reservations[0].user.email;
		else if (data.user_limit > 1)
			return data.user_reservations !== undefined
				? data.user_reservations.length + "/" + data.user_limit
				: "0" + "/" + data.user_limit;
		else return data.vat_price + " " + t("common.currency_symbol");
	};

	const handleEventList = (events) => {
		const newState = events.map((event) => ({
			id: event.reservation_id,
			title: handleEventTitle(event),
			start: new Date(event.from_time),
			end: new Date(event.to_time),
			serviceId: event.reservable_id,
			/* price: Number(event.price),*/
			vatPrice: Number(event.vat_price).toFixed(2),
			allDay: false,
			userLimit: event.user_limit,
			userReservations: event.user_reservations,
			users:
				event.user_reservations !== undefined
					? event.user_reservations.map((i) => {
						if (i.user !== undefined) return i.user;
					})
					: []
		}));

		setListEvents(newState);

		setIsLoading(false);

		/* setFirstLaunchDone(true); */
	};

	const getReservations = () => {
		if (
			selectedService !== null &&
			selectedService !== undefined &&
			selectedService !== "" &&
			selectedService?.type !== 3
		) {
			setIsLoading(true);

			const getWeek = isNaN(selectedWeek) ? currentWeek : selectedWeek;

			const checkForSunday = ifSunday ? getWeek - 1 : getWeek;

			ApiCall(
				"GET",
				`client/reservations/${selectedLocation.location_id}?reservable=${selectedService.id}&year=${selectedYear}&week=${checkForSunday}${isMobile ? "&date=" + selectedDate : ""}`, // fix for sunday 0 bug causing calendar to fetch next mon-sun instead showing sunday correctly in day calendar view
				null
			)
				.then((response) => {
					if (debug) console.log(response);
					handleEventList(response);
				})
				.catch((error) => {
					setIsLoading(false);
					setListEvents(eventsBody); // reset state

					if (debug) console.log(error);
				});
		} else {
			setIsLoading(false);
			/* setFirstLaunchDone(true); */
		}
	};

	const closeDrawer = () => {
		setContentType("");
		setEmptySlots([]);
		setEventSlot([]);
	};

	const resize = () => {
		if (checkForContent() !== null && checkForContent().length > 0)
			return isMobile ? "40%" : "calc(100% - 200px)";
		// this needs to be improved among mui drawer width calculation when screen is resized
		else return "100%";
	};

	const handleSelectedSlots = (values) => {
		// console.log(values);
		setContentType("selectedSlots");
		setEmptySlots([values]);
	};

	const handleModalOpen = (data) => {
		// console.log(data);
		setContentType("calendar");

		if (Object.prototype.hasOwnProperty.call(data, "id"))
			if (eventSlot.length === 0) {
				setEventSlot([data]);
			} else if (
				eventSlot.length > 0 &&
				eventSlot.find((entry) => entry.id === data.id)
			) {
				setEventSlot((prevState) =>
					prevState.filter((remove) => remove.id !== data.id)
				);
			} else {
				if (eventSlot.length === 1 && eventSlot[0].users.length > 0)
					return;
				if (
					eventSlot.length > 0 &&
					eventSlot[0].users.length === 0 &&
					data.users.length > 0
				)
					return;
				setEventSlot((prevState) => [...prevState, data]);
			}
		else return;
	};

	const components = useMemo(() => ({
		toolbar: (props) => (
			<Toolbar
				props={props}
				passRefreshReservation={getReservations}
				drawerOpen={emptyData.length > 0 || eventSlot.length > 0}
			/>
		),
		header: /* emptyData.length > 0 || eventSlot.length > 0 ? undefined : */ Header,
		/* timeGutterHeader: TimeSlotWrapper */
		week: {
			event: (props) => <Event props={props} />
		}
	}));

	const getReservables = () => {
		setReservableList([]);
		setIsLoading(true);

		ApiCall(
			"GET",
			`client/reservables/${selectedLocation.location_id}` /* ?category=${switchType()} */
			/* `location/get_reservables/${
				selectedLocation.location_id
			}?category=${switchType()}` */ // ?type=${service.id}
		)
			.then((response) => {
				// REMOVE ALL THIS EXTRA CODE when server checks location permission before returning reservable data

				const perm2 = selectedLocation.location_permissions.some(
					(perm) => perm.permission_id === 2
				);

				const perm8 = selectedLocation.location_permissions.some(
					(perm) => perm.permission_id === 8
				);

				if (!perm8)
					response = response.filter((entry) => entry.type !== 3); // exclude type 3 for key management

				if (!perm2)
					response = response.filter((entry) => entry.type !== 0); // exclude type 0 for short reservable

				// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
				setReservableList(response);

				/* if (response.length === 0) setFirstLaunchDone(true); */
			})
			.catch((error) => {
				if (debug) console.log(error);
			})
			.finally(() => setIsLoading(false));
	};

	// Child components can refresh reservables by calling this function
	function refreshReservables() {
		setReservableList([]);
		getReservables();
	}

	useEffect(() => {
		if (listEvents.title !== "") generateList();
	}, [listEvents]);

	useEffect(() => {
		if (selectedService === null && reservableList.length > 0) {
			setSelectedService(reservableList[0]);
		} else if (reservableList.length > 0) {
			setSelectedService(
				reservableList.find((entry) => entry.id === selectedService?.id)
			);
		}
	}, [reservableList]);

	useEffect(() => {
		if (
			emptyData.length === 0 &&
			eventSlot.length === 0 &&
			selectedService !== null
		)
			getReservations();
	}, [
		emptyData.length,
		eventSlot.length,
		selectedService,
		selectedWeek,
		selectedLocation
	]);

	useEffect(() => {
		if (selectedLocation.location_id !== undefined) {
			setIsLoading(true);
			getReservables();
		}
	}, [selectedLocation]);

	useEffect(() => {
		setIsLoading(true);
	}, []);

	useEffect(() => {
		if (isMobile) getReservations();
	}, [selectedDate]);

	return (
		<div
			style={{
				width: isMobile ? undefined : resize(),
				transition: "width 0.2s ease-out",
				paddingBottom: "50px"
			}}
		>
			<MuiDrawer
				open={emptyData.length > 0 || eventSlot.length > 0}
				handleDrawerClose={closeDrawer}
				info={selectedService}
				content={checkForContent()}
				contentType={contentType}
				passRefreshReservation={getReservations}
			/>

			{true && (
				<div
					style={{
						display: "flex",
						height: "100%",
						minWidth: "100%"
					}}
				>
					{Object.prototype.hasOwnProperty.call(
						selectedLocation,
						"service_classes"
					) &&
						(selectedLocation.location_permissions.find(
							(obj) => obj.permission_id === 2
						) !== undefined ||
							(selectedLocation.location_permissions.find(
								(obj) => obj.permission_id === 8
							) !== undefined &&
								reservableList.length > 0) ||
							selectedLocation.service_classes.length !== 0) /* &&
					reservableList.length > 0 */ ? (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									width: "100%"
								}}
							>
								{emptyData.length === 0 &&
									eventSlot.length === 0 &&
									reservableList.length > 0 && (
										/* hides service select if drawer is open */
										<LandingAnimation>
											<ListGroupingSlider
												passSelectedService={
													setSelectedService
												}
												selectedService={selectedService}
												reservables={reservableList}
											/>
										</LandingAnimation>
									)}
								{!isMobile && selectedService?.type === 3 && <DoorList selectedService={selectedService} />}

								{selectedService !== null &&
									selectedService?.type !== 3 && (
										<LandingAnimation>
											<Calendar
												culture={"fi-FI"}
												localizer={localizer}
												messages={Locale}
												formats={Locale}
												events={
													switchList ? listEvents : []
												}
												step={
													selectedService !== "" &&
														selectedService?.open_hours
															?.minimum_reservation !==
														null &&
														selectedService?.open_hours
															?.minimum_reservation !== 0
														? selectedService
															?.open_hours
															?.minimum_reservation
														: 60
												} // todo minimisteppi vähintään 30min tai enemmän?
												timeslots={1} // slots between timerows
												startAccessor="start"
												endAccessor={({ end }) =>
													new Date(end.getTime())
												}
												defaultView={
													isMobile
														? "day"
														: "week" /* "month" */
												}
												views={
													isMobile
														? ["day"]
														: ["week" /* "month" */]
												}
												dayLayoutAlgorithm="no-overlap"
												longPressThreshold={250}
												components={components}
												min={customStart}
												max={customEnd}
												selectable={
													emptyData.length < 1 &&
													eventSlot.length < 1
												} // ToDo useamman vuoron varausmahdollisuus hiirellä maalaamalla, jos
												onSelectEvent={(event) => {
													handleModalOpen(event);
												}} // todo data tästä pitäisi saada päivitettyä, jotta tieto vaihtuisi drawereissa tai sitten käsitellään dataa piilottamalla ja lisäämällä stateen käyttäjiä
												showMultiDayTimes={true}
												onSelectSlot={(slotInfo) => {
													handleSelectedSlots(slotInfo);
												}}
												eventPropGetter={eventPropGetter}
												onNavigate={(date) => {
													setSaveWeekView(date);
												}}
												defaultDate={
													saveWeekView !== null &&
													new Date(
														saveWeekView.getFullYear(),
														saveWeekView.getMonth(),
														saveWeekView.getDate(),
														0,
														0,
														0
													)
												}
												style={{
													minHeight:
														"-all-available-space",
													/* paddingLeft: isDesktop ? "3%" : "5%",
													paddingRight: isDesktop ? "3%" : "5%", */
													paddingBottom: "50px",
													width: resize(),
													// height: "1000px", // <-- uncomment for month view
													gap: "10px"
												}}
											/>
										</LandingAnimation>
									)}
								{selectedService !== null &&
									selectedService?.type === 3 && (
										<LandingAnimation>
											<Box
												sx={{
													/* height: "100%", */
													display: "flex",
													paddingBottom: "50px",
													width: resize(),
													// height: "1000px", // <-- uncomment for month view
													gap: "10px"
												}}
											>
												<KeyManagement
													refreshReservables={
														refreshReservables
													}
													data={selectedService}
												/>
											</Box>
										</LandingAnimation>
									)}
							</div>
						) : (
							<LandingAnimation>
								<Box
									sx={{
										width: "100%",
										height: "400px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}
								>
									<Stack
										spacing={2}
										alignSelf="center"
										sx={{
											width: "35%",
											top: "50%"
										}}
									>
										<Typography
											variant="h6"
											sx={{ textAlign: "center" }}
										>
											{t(
												"calendar.no_reservable_services_found"
											)}
										</Typography>
										<Button
											variant="drawerFormAccept"
											onClick={() => handleRedirect()}
										>
											{t("buttons.goto_location_page")}
										</Button>
									</Stack>
								</Box>
							</LandingAnimation>
						)}
				</div>
			)}
			{/* !firstLaunchDone && (
				<div
					style={{
						position: "absolute",
						backgroundColor: ColorScheme.bg,
						height: "100vh",
						width: "100vw",
						top: 0,
						left: isMobile ? "0px" : "200px"
					}}
				/>
			)*/}
			{isLoading && <BackdropLoading />}
		</div>
	);
};
