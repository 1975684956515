export const TimeSlots = [
	// todo translate linking
	/* {
		value: 5,
		title: "5 minuuttia"
	},
	{
		value: 10,
		title: "10 minuuttia"
	}, */
	{
		value: 15,
		title: "15 minuuttia"
	},
	{
		value: 30,
		title: "30 minuuttia"
	},
	{
		value: 45,
		title: "45 minuuttia"
	},
	{
		value: 60,
		title: "60 minuuttia"
	},
	{
		value: 75,
		title: "75 minuuttia"
	},
	{
		value: 90,
		title: "90 minuuttia"
	},
	{
		value: 120,
		title: "2 tuntia"
	},
	{
		value: 180,
		title: "3 tuntia"
	},
	{
		value: 240,
		title: "4 tuntia"
	},
	{
		value: 300,
		title: "5 tuntia"
	},
	{
		value: 360,
		title: "6 tuntia"
	}
];

export const TypeSelect = [
	{
		value: 3,
		title: "Kertakulutushyödyke"
	},
	{
		value: 1,
		title: "Käyttömäärällinen"
	},
	{
		value: 2,
		title: "Aikarajallinen"
	},
	{ value: 4, title: "Saldo" }
];

export const UnitSelect = [
	{
		value: 1,
		title: "minuuttia"
	},
	{
		value: 2,
		title: "tuntia"
	},
	{
		value: 3,
		title: "päivää"
	},
	{
		value: 4,
		title: "viikkoa"
	},
	{
		value: 5,
		title: "kuukautta"
	},
	{
		value: 6,
		title: "vuotta"
	}
];
