import { Box, Typography } from "@mui/material";
import React from "react";
import { ColorScheme } from "../../Themes/ColorScheme";
import { weekdays } from "../../Datafields/WeekdayList";

/**
 * Improved version of the selectWeekday component.
 * A component that still renders a selectable list of weekdays.
 * @param {Object} props - The component props.
 * @param {Array} props.days - The selected days, either an array of weekdays or a single string of weekday.
 * @param {Function} props.weekdayClicked - The function to call when a weekday is clicked.
 * @param {Boolean} props.singleSelect - Whether only one day can be selected at a time. If true props.days has to be a string.
 * @returns {JSX.Element} - The WeekdaySelect component.
 */

export const WeekdaySelect = ({ days, weekdayClicked, singleSelect }) => (
	<Box
		sx={{
			flexDirection: "row",
			display: "flex",
			alignItems: "center",
			width: "100%",
			justifyContent: "space-between",
			my: "20px"
		}}
	>
		{weekdays().map(({ short, weekday }) => {
			const isSelected = () =>
				days === null
					? null
					: singleSelect
						? days === weekday
						: days?.some((d) => Number(d) === weekday);

			return (
				<Box
					key={weekday}
					onClick={() => weekdayClicked(weekday)}
					sx={{
						height: 40,
						width: 40,
						overflow: "hidden",
						backgroundColor: isSelected()
							? ColorScheme.tolotechBlue
							: "",
						border: !isSelected()
							? `1px solid ${ColorScheme.tolotechBlue}`
							: `1px solid ${ColorScheme.tolotechDarkBlue}`,
						borderRadius: 2,
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
						opacity: singleSelect && !isSelected() ? 0.5 : 1,
						cursor: "pointer",
						pointerEvents: "auto",
						boxShadow: isSelected()
							? "0px 0px 30px 2px rgba(0,0,0,0.07)"
							: ""
					}}
				>
					<Typography
						sx={{
							fontSize: "16px",
							fontWeight: "700",
							color: isSelected()
								? ColorScheme.tolotechWhite
								: ColorScheme.tolotechBlue
							/* isSelected()
												? ColorScheme.tolotechWhite
												: ColorScheme.tolotechGray */
						}}
					>
						{short}
					</Typography>
				</Box>
			);
		})}
	</Box>
);
