import React, { useContext, useState } from "react";
import { Button } from "@mui/material";
import { addYears, endOfDay } from "date-fns"; // Import the necessary date-fns functions
import { UserContext } from "../UserContext";
import { ApiContext } from "../ApiContext";
import { AlertBar } from "../Common/AlertBar";
import { TextFieldInput2 } from "../Common/TextFieldInput2";
import { TextfieldPicker } from "../Common/TextfieldPicker";

import { ReactComponent as CalendarIcon } from "../../Assets/Icons/CalendarIcon_custom_stroke.svg";
import { ReactComponent as WalletIcon } from "../../Assets/Icons/WalletIcon.svg";
import { ReactComponent as EmailIcon } from "../../Assets/Icons/EnvelopeIcon.svg";
import { ColorScheme } from "../../Themes/ColorScheme";
import { useTranslation } from "react-i18next";

export const NewBalance = ({ handleDrawerClose }) => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);
	const [balance, setBalance] = useState(0);
	const [userEmail, setUserEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const [expirationDate, setExpirationDate] = useState(
		addYears(new Date(), 1) // Replace moment().add(1, "year").endOf("day") with addYears(new Date(), 1)
	);

	const closeDrawer = () => {
		setBalance(0);
		setUserEmail("");
		setExpirationDate(addYears(new Date(), 1)); // Replace moment().add(1, "year").endOf("day") with addYears(new Date(), 1)
		setErrorMessage("");
		handleDrawerClose();
	};

	const handleNewBalanceAccount = () => {
		ApiCall("POST", "client/balance", {
			email: userEmail,
			amount: Number(balance),
			expires_at: endOfDay(expirationDate).toISOString(), // Replace new Date(expirationDate).toISOString() with endOfDay(expirationDate).toISOString()
			type: 5,
			location_id: selectedLocation.location_id
		})
			.then(() => {
				setErrorMessage("");
				closeDrawer();
			})
			.catch((err) => {
				setErrorMessage(err);
			});
	};

	const handleBalanceChange = (event) => {
		setBalance(event);
	};

	const handleUserEmailChange = (event) => {
		setUserEmail(event);
	};

	/* const handleExpirationDateChange = (event) => {
		setExpirationDate(event);
	}; */

	const validateEmail = () => {
		const re = /\S+@\S+\.\S+/;
		return re.test(userEmail);
	};

	const checkValues = balance === "" || balance === 0 || !validateEmail();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "20px",
				height: "100%"
			}}
		>
			{/* <DrawerTitle title={t("balances.create_new_balance")} /> */}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px"
				}}
			>
				<TextFieldInput2
					title={t("balances.user_email")}
					text={userEmail}
					valueChange={handleUserEmailChange}
					isRequired={true}
					hasProps={
						<EmailIcon stroke={ColorScheme.defaultIconColor} />
					}
				/>

				<TextFieldInput2
					text={balance}
					title={t("balances.add_balance")}
					valueChange={handleBalanceChange}
					isRequired={true}
					hasProps={
						<WalletIcon
							stroke={ColorScheme.defaultIconColor}
							height={26}
							strokeWidth={2.5}
						/>
					}
					endAdornment={t("common.currency_text")}
				/>

				<TextfieldPicker
					value={expirationDate}
					valueChange={setExpirationDate}
					noTime={true}
					label={t("balances.expiration_date")}
					excludePast={true}
					hasProps={
						<CalendarIcon stroke={ColorScheme.defaultIconColor} />
					}
				/>
			</div>

			<AlertBar
				textContent={errorMessage}
				showAlert={errorMessage !== ""}
				type="error"
				message={t("balances.new_balance_info")}
			/>

			<div style={{ flex: 1 }} />

			<Button
				disabled={checkValues}
				variant="contained"
				sx={{ width: "100%" }}
				onClick={handleNewBalanceAccount}
			>
				{t("buttons.save")}
			</Button>
		</div>
	);
};
