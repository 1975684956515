import React, { useContext } from "react";
import { Box, Stack, Typography } from "@mui/material";

import { ConfirmAction } from "../../Common/ConfirmAction";
import { UserContext } from "../../UserContext";
import { ApiContext } from "../../ApiContext";
import { useTranslation } from "react-i18next";
import { ColorScheme } from "../../../Themes/ColorScheme";

export const ClientCard = ({ locationData, user, refresh }) => {
	const debug = false;

	const { username } = useContext(UserContext);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const handleUserRemove = () => {
		ApiCall("POST", "client/remove", {
			location_id: locationData,
			manager_id: user.manager_id
		})
			.then(() => refresh())
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	return (
		<Stack
			sx={{
				padding: "15px",
				overflow: "auto",
				backgroundColor: "white",
				borderRadius: "4px",
				marginBottom: "10px",
				flexDirection: "row",
				justifyContent: "space-between",
				display: "flex"
			}}
		>
			<Box sx={{ flexDirection: "column", display: "flex" }}>
				<Typography
					style={{
						fontSize: "12px",
						color: "black",
						fontWeight: "700"
					}}
				>
					{user.name}
				</Typography>
				<Typography
					style={{
						fontSize: "10px",
						color: ColorScheme.textFieldInputText,
						fontWeight: "300"
					}}
				>
					{user.email}
				</Typography>
				{!user?.accepted_invitation && (
					<Typography
						style={{
							fontSize: "10px",
							color: ColorScheme.softRed,
							fontWeight: "300"
						}}
					>
						{t("location.form_location_admin_pending")}
					</Typography>
				)}
			</Box>
			{username !== user.email && (
				<ConfirmAction
					handleTitle={t("buttons.remove_user_admin_rights")}
					isIconButton={true}
					handleConfirmAction={handleUserRemove}
				/>
			)}
		</Stack>
	);
};
