import {
	Stack,
	TextField,
	Typography,
	Button,
	CircularProgress
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../ApiContext";
import { SubmitButton } from "./SubmitButton";

export const ForgotPassword = ({ switchComponent, passUserEmail }) => {
	const [userEmail, setUserEmail] = useState("");
	const [resettingPassword, setResettingPassword] = useState(false);

	const { ApiCall } = useContext(ApiContext);
	const { t } = useTranslation();

	const restoreAccount = (e) => {
		setResettingPassword(true);
		e.preventDefault();

		ApiCall("POST", "user/reset_password", {
			email: userEmail
		})
			.then(() => {
				switchComponent("validation");
				passUserEmail(userEmail);
				setResettingPassword(false);
			})
			.catch(() => {
				setUserEmail("");
				setResettingPassword(false);
			});
	};

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	return (
		<form onSubmit={restoreAccount}>
			<Typography align="center" style={{ margin: 30 }} color={"white"}>
				{t("login.email_recovery_description")}
			</Typography>
			<Stack spacing={3}>
				<Typography sx={{ color: "#FFFFFF", fontSize: "14px" }}>
					{t("login.email_label")}*
				</Typography>

				<TextField
					placeholder={t("login.insert_email")}
					sx={{
						backgroundColor: "#FAFAFA",
						borderRadius: "5px"
					}}
					id="username"
					variant="outlined"
					margin="dense"
					fullWidth={true}
					size="small"
					onChange={(e) => setUserEmail(e.target.value)}
				/>

				<Stack
					style={{ justifyContent: "center", alignItems: "center" }}
				>
					{!resettingPassword ? (
						<Stack spacing={3} style={{ width: "100%" }}>
							<SubmitButton
								text={t("buttons.continue")}
								disabled={!validateEmail(userEmail)}
							/>
							<Button
								variant="standard"
								onClick={() => switchComponent("login")}
								sx={{
									backgroundColor: "rgba(255, 255, 255, 0.2)",
									color: "#FFFFFF",
									fontSize: "10px",
									width: 1,
									borderRadius: "4px",
									height: "44px"
								}}
							>
								{t("buttons.return_to_login")}
							</Button>
						</Stack>
					) : (
						<CircularProgress color="info" />
					)}
				</Stack>
			</Stack>
		</form>
	);
};
