import { Button, Popover } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ConfirmRemove = ({ doorId, handleRemove }) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);

	const openPopover = Boolean(anchorEl);
	const id = open ? "confirm-remove" : undefined;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button variant="contained" onClick={(event) => handleClick(event)}>
				{t("buttons.delete")}
			</Button>
			<Popover
				id={id}
				open={openPopover}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<Button onClick={() => handleRemove(doorId)}>Vahvista</Button>
				<Button onClick={() => handleClose()}>
					{t("buttons.cancel")}
				</Button>
			</Popover>
		</>
	);
};
