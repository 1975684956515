import { Typography } from "@mui/material";
import React from "react";

import { ReactComponent as Wallet } from "../../../Assets/Icons/WalletIcon.svg";
import { useTranslation } from "react-i18next";

export const WalletIcon = ({
	iconWidth,
	iconHeigth,
	strokeColor,
	strokeWidth,
	basePrice
}) => {
	const { t } = useTranslation();

	return (
		<div
			style={{
				textAlign: "center",
				position: "relative",
				width: "25%"
			}}
		>
			<Wallet
				stroke={strokeColor}
				width={iconWidth}
				heigth={iconHeigth}
				strokeWidth={strokeWidth * 1.2}
			/>
			<Typography
				style={{
					fontSize: "12px",
					fontWeight: "400",
					color: "grey"
				}}
			>
				{basePrice / 100 + " " + t("charging.price_per_kwh")}
			</Typography>
		</div>
	);
};
