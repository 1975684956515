import { Card } from "@mui/material";
import React, { useContext } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { UserContext } from "../../UserContext";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const SalesWidget = ({ data }) => {
	const { t } = useTranslation();
	const { selectedLocation } = useContext(UserContext);

	// checks available language translations from selectedLocation
	const languageKeys = Object.keys(
		selectedLocation.service_classes[0]
	).filter((key) => key.startsWith("name_"));

	const options = {
		plugins: {
			legend: {
				display: true
			}
			/* tooltip: {
				titleFont: {
					size: 36
				},
				bodyFont: {
					size: 32
				}
			} */
		},
		scale: {
			ticks: {
				precision: 0
			}
		},
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			x: {
				stacked: true
			},
			y: {
				stacked: true,
				beginAtZero: true
			}
		}
	};

	const labels = [
		t("dates.january_long"),
		t("dates.february_long"),
		t("dates.march_long"),
		t("dates.april_long"),
		t("dates.may_long"),
		t("dates.june_long"),
		t("dates.july_long"),
		t("dates.august_long"),
		t("dates.september_long"),
		t("dates.october_long"),
		t("dates.november_long"),
		t("dates.december_long")
	];

	const parseReservables = [...new Set(data.map((entry) => entry.class))]; // find unique classes

	const parseMonthData = (reservable, month) =>
		data
			.filter(
				(entry) =>
					entry.class === reservable &&
					new Date(entry.date).getMonth() === month
			)
			.map((found) => found.reservations)
			.reduce((last, sum) => last + sum, 0);

	const dataset = {
		labels,
		datasets: parseReservables
			.map((reservable) => [
				{
					label: reservable,
					data: labels.map((_, index) =>
						parseMonthData(reservable, index)
					),

					backgroundColor: selectedLocation.service_classes
						.filter((entry) =>
							languageKeys.some(
								(key) =>
									entry[key] &&
									entry[key].includes(reservable)
							)
						)
						.map((found) => found.color)
				}
			])
			.flat()
	};

	return (
		<Card
			elevation={0}
			sx={{
				maxWidth: "100%",
				padding: isMobile ? "5px" : "55px",
				minHeight: isMobile ? "400px" : "350px",
				maxHeight: isMobile ? "400px" : "750px",
				borderRadius: "4px"
			}}
		>
			<Bar options={options} data={dataset} />
		</Card>
	);
};
