import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../../ApiContext";
import { MultiChip } from "../../../Common/MultiChip";
import { useTranslation } from "react-i18next";

import { Spinner } from "../../../Common/Spinner";
import { theme } from "../../../../Themes/Theme";
import { UserContext } from "../../../UserContext";

export const ServiceDoors = ({
	location,
	oldData,
	passSelectedData,
	isEquipment
}) => {
	const debug = false;

	const [locationDoors, setLocationDoors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const { selectedLocation } = useContext(UserContext);

	const checkPerm1 = selectedLocation.location_permissions.some(
		(perm) => perm.permission_id === 1
	); // 1 access right / doors

	const checkPerm8 = selectedLocation.location_permissions.some(
		(perm) => perm.permission_id === 8
	); // 8 key management

	if (!checkPerm1 && !checkPerm8) return null; // if no permissions, don't render this component

	const fetchDoors = () => {
		setIsLoading(true);

		ApiCall("GET", `location/doors/${location}`, null)
			.then((response) => {
				setLocationDoors(response);
			})
			.catch((error) => {
				if (debug) console.log(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchDoors();
	}, []);

	return isLoading ? (
		<>
			<div style={{ height: "15px" }} />
			<Spinner small />
		</>
	) : (
		locationDoors !== [] && locationDoors.length > 0 && (
			<div style={theme.formStyles.formLayout}>
				{/* <div style={theme.formStyles.label_with_help}>
					<Typography variant="form_bold">
						{isEquipment
							? t("doors.location_devices")
							: t("doors.location_access_rights")}
					</Typography>
					<QuestionTooltip
						title={isEquipment ? "devices" : "doorAccess"}
					/>
				</div> */}

				<MultiChip
					data={locationDoors}
					passSelectedData={passSelectedData}
					current={oldData}
					isEquipment={isEquipment}
					getLabel={
						isEquipment
							? t("doors.location_devices")
							: t("doors.location_access_rights")
					}
				/>
			</div>
		)
	);
};
