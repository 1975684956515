import { Typography, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../../UserContext";
import ArrowDownIcon from "../../../Assets/Icons/ArrowDownIcon.svg";
import { useLocation, useNavigate, matchPath, Link } from "react-router-dom";
import { ApiContext } from "../../ApiContext";
import { PlaceholderSkeleton } from "../../Common/PlaceholderSkeleton";
import { isMobile } from "react-device-detect";
import { Stack } from "@mui/system";
import TooltipWrapper from "../../Common/TooltipWrapper";
import { useTranslation } from "react-i18next";

export const LocationSelector = () => {
	const debug = false;
	const { t } = useTranslation();
	const { setSelectedLocation, selectedLocation, user } =
		useContext(UserContext);
	const { ApiCall } = useContext(ApiContext);
	const [locations, setLocations] = useState([]);
	const [noLocations, setNoLocations] = useState(false);
	const [newUser, setNewUser] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	const getLocations = () => {
		if (user.name === "") {
			setNewUser(true);
			return;
		} else {
			setNewUser(false);
		}

		ApiCall("GET", "client/locations", null, null)
			.then((res) => {
				if (debug) console.log(res);
				if (res.length < 1) setNoLocations(true);
				setLocations(res);
			})
			.catch((err) => {
				if (debug) console.log(err);
			});
	};

	const match = matchPath(
		{
			path: "/locations/:id",
			exact: true,
			strict: true
		},
		location.pathname
	);

	const handleOnClick = (loc) => {
		setSelectedLocation(loc);

		getLocations();

		if (
			(match !== null &&
				location.pathname !== `/locations/${loc.location_id}`) ||
			location.pathname === "/locations"
		)
			navigate(`/locations/${loc.location_id}`);
	};

	useEffect(() => {
		if (
			!Object.prototype.hasOwnProperty.call(
				selectedLocation,
				"location_id"
			) &&
			selectedLocation.length !== 0 &&
			selectedLocation.location_id === undefined &&
			locations.length > 0
		) {
			// check if location is in the list, if not, navigate to locations page

			const checkLocation = locations.find((loc) =>
				location.pathname.split("/").includes(String(loc.location_id))
			);

			if (checkLocation) {
				setSelectedLocation(checkLocation);
			} else {
				setSelectedLocation(locations[0]);
			}
		}
	}, [locations]);

	useEffect(() => {
		// clear states if user changes
		setLocations([]);
		setSelectedLocation({});
	}, [user]);

	useEffect(() => {
		if (locations.length === 0 || location.state === undefined)
			getLocations();
	}, [selectedLocation]);

	return (
		<div
			style={{
				flexDirection: "row",
				display: "flex",
				alignItems: "center",
				width: "max-content"
			}}
		>
			{selectedLocation.name !== undefined ? (
				<>
					{/* <Typography style={{ fontSize: "16px", color: "#4796E3" }}>
						{selectedLocation !== null &&
						selectedLocation !== undefined
							? selectedLocation.name
							: ""}

						<img
							style={{
								width: "15px",
								height: "15px",
								marginLeft: "10px"
							}}
							src={ArrowDownIcon}
						/>
					</Typography>
 */}
					<Stack
						direction="row"
						justifyContent="space-between"
						alignContent="center"
					>
						<Link to={`/locations/${selectedLocation.location_id}`}>
							<TooltipWrapper title={t("menu.return_back")}>
								<Typography
									sx={{
										color: "white"
									}}
								>
									{selectedLocation.name}
								</Typography>
							</TooltipWrapper>
						</Link>

						<div>
							<Select
								labelid="select-type-label"
								id="select-type"
								value={
									selectedLocation === undefined ||
									selectedLocation === null ||
									selectedLocation === ""
										? ""
										: selectedLocation.location_id
								}
								sx={{
									backgroundColor: "transparent",
									borderRadius: "0px",
									border: "hidden",

									/* opacity: 0, */
									position: "absolute",
									width: isMobile ? undefined : "400px",
									boxShadow: "none",
									".MuiOutlinedInput-notchedOutline": {
										border: 0
									},

									"&.Mui-focused .MuiOutlinedInput-notchedOutline":
										{
											border: 0
										},
									"&:hover .MuiOutlinedInput-notchedOutline":
										{
											border: 0
										},
									".MuiSelect-select": { padding: "0px" }
								}}
								renderValue={() => (
									<TooltipWrapper
										title={t("menu.select_location")}
									>
										<img
											style={{
												width: "15px",
												height: "15px",
												marginLeft: "10px",
												marginTop: "5px"
											}}
											src={ArrowDownIcon}
										/>
									</TooltipWrapper>
								)}
								inputProps={{
									IconComponent: () => null /* (
								<img
									style={{
										width: "15px",
										height: "15px",
										marginLeft: "10px"
									}}
									src={ArrowDownIcon}
								/>
							) */
								}}
							>
								{locations
									.sort((a, b) =>
										a.name.localeCompare(b.name)
									)
									.map((loc) => (
										<MenuItem
											value={loc.location_id}
											key={loc.location_id}
											onClick={() => handleOnClick(loc)}
										>
											{loc.name}
										</MenuItem>
									))}
							</Select>
						</div>
					</Stack>
				</>
			) : (
				!noLocations ||
				(!newUser && <PlaceholderSkeleton variant="text" />)
			)}
		</div>
	);
};
