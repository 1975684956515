// i18n.js

import { createInstance } from "i18next";
import { initReactI18next } from "react-i18next";
import { en_lang } from "./en";
import { fi_lang } from "./fi";

const i18nInstance = createInstance();

i18nInstance.use(initReactI18next).init({
	interpolation: {
		escapeValue: false
	},
	lng: "fi",
	debug: true,
	ns: ["translation"],
	defaultNS: "translation",
	resources: {
		fi: { translation: fi_lang },
		en: { translation: en_lang }
	}
});

export default i18nInstance;
