import React, { useContext, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import { ColorScheme } from "../../../../Themes/ColorScheme";
import { ApiContext } from "../../../ApiContext";
import { UserContext } from "../../../UserContext";
import { Spinner } from "../../../Common/Spinner";
import { useTranslation } from "react-i18next";

export const InviteForm = ({ locationId, refresh }) => {
	const debug = false;

	const [inviteInput, setInviteInput] = useState("");

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);
	const [loading, setLoading] = useState(false);
	const { selectedLocation } = useContext(UserContext);

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/;
		return !re.test(email);
	};

	const handleInvite = () => {
		setLoading(true);
		console.log(inviteInput);
		ApiCall("POST", `client/invite/${locationId}`, { email: inviteInput })
			.then(() => {
				refresh();
				setInviteInput("");
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				if (debug) console.log(error);
			});
	};

	useEffect(() => {
		setInviteInput("");
	}, [selectedLocation]);

	return (
		<Box
			sx={{
				height: "44px",
				maxHeight: "44px",
				minWidth: "160px",
				flexDirection: "row",
				display: "flex",
				justifyContent: "space-between",
				marginBottom: "20px",
				alignItems: "center"
			}}
		>
			<input
				style={{
					width: "100%",
					height: "100%",
					backgroundColor: ColorScheme.tolotechDarkBlue,
					border: "0px solid white",
					fontFamily: "Sofia Sans",
					color: "white",
					paddingLeft: "10px",
					fontSize: "12px",
					borderRadius: "4px"
				}}
				size={"small"}
				placeholder={t("location.form_location_invite_user_label")}
				value={inviteInput}
				onChange={(text) => setInviteInput(text.target.value)}
			/>
			<div
				onClick={() => !validateEmail(inviteInput) && handleInvite()}
				style={{
					width: "80px",
					height: "46px",
					backgroundColor: ColorScheme.tolotechBlue,
					borderRadius: "0px",
					marginLeft: "10px",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
					opacity: validateEmail(inviteInput) && 0.6,
					cursor: !validateEmail(inviteInput) && "pointer"
				}}
			>
				{loading ? (
					<Spinner />
				) : (
					<Typography sx={{ fontSize: "14px" }}>
						{t("buttons.invite")}
					</Typography>
				)}
			</div>
		</Box>
	);
};
