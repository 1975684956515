import { Stack } from "@mui/material";

import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../UserContext";
import { ListMessages } from "./ListMessages";
import { ListNotifications } from "./ListNotifications";
import { ListRequests } from "./ListRequests";

export const MessageContent = () => {
	const { handleTitle } = useContext(UserContext);
	const { t } = useTranslation();

	useEffect(() => {
		handleTitle(t("common.messages"));
	}, []);

	return (
		<Stack
			sx={{ alignItems: "center", display: "flex" }}
			direction="column"
		>
			<ListNotifications />
			<ListRequests />
			<ListMessages />
		</Stack>
	);
};
