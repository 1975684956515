import { Box, IconButton, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { DeleteButton } from "../Buttons/DeleteButton";
import { ConfirmAction } from "../../Common/ConfirmAction";
import { ColorScheme } from "../../../Themes/ColorScheme";
import TooltipWrapper from "../../Common/TooltipWrapper";
import { isDesktop, isIPad13, isMobile } from "react-device-detect";

import { ReactComponent as PendingIcon } from "../../../Assets/Icons/Pending.svg";
import { ReactComponent as CheckmarkIcon } from "../../../Assets/Icons/Checkmark.svg";
import { ReactComponent as EndingSoon } from "../../../Assets/Icons/EndingSoon.svg";
import { ReactComponent as Ended } from "../../../Assets/Icons/Ended.svg";
import { ReactComponent as EditIcon } from "../../../Assets/Icons/EditIcon.svg";

import { ModifyDialog } from "./EditDialog";
import { useTranslation } from "react-i18next";

function getStatusProperty(status, property) {
	const { t } = useTranslation();

	if (property === "main") {
		switch (status) {
			case 0:
				return "#75A85D";
			case 1:
				return "#ffbc70";
			case 2:
				return "#A85D5D";
			case 3:
				return "#516785";
			default:
				return "#516785";
		}
	} else if (property === "secondary") {
		switch (status) {
			case 0:
				return "#DCE9D6";
			case 1:
				return "#ffe9d1";
			case 2:
				return "#E9D6D6";
			case 3:
				return "#D4D9E0";
			default:
				return "#D4D9E0";
		}
	} else if (property === "icon") {
		switch (status) {
			case 0:
				return (
					<CheckmarkIcon
						stroke={"#75A85D"}
						strokeWidth={2}
						width={"20px"}
						height={"20px"}
					/>
				);
			case 1:
				return (
					<EndingSoon
						stroke={"#ffbc70"}
						strokeWidth={2}
						width={"24px"}
						height={"24px"}
					/>
				);
			case 2:
				return (
					<Ended
						stroke={"#A85D5D"}
						strokeWidth={2}
						width={"24px"}
						height={"24px"}
					/>
				);
			case 3:
				return (
					<PendingIcon
						stroke={"#516785"}
						strokeWidth={2}
						width={"24px"}
						height={"24px"}
					/>
				);
			default:
				return (
					<PendingIcon
						stroke={"#516785"}
						strokeWidth={2}
						width={"24px"}
						height={"24px"}
					/>
				);
		}
	} else if (property === "text") {
		switch (status) {
			case 0:
				return isMobile ? "" : t("key.ongoing");
			case 1:
				return isMobile ? "" : t("key.ends_soon");
			case 2:
				return isMobile ? "" : t("key.not_valid");
			case 3:
				return isMobile ? "" : t("key.pending");
			default:
				return isMobile ? "" : t("key.ongoing");
		}
	}
}

export const KeyUserList = ({
	entry,
	isLast,
	handleRemove,
	reservable,
	handleRefresh
}) => {
	const [isEmailOverflowing, setIsEmailOverflowing] = useState(false);
	const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
		useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	const [showStatusText, setShowStatusText] = useState(false);
	const [enableButton, setEnableButton] = useState(false);

	const overflowEmail = useRef(null);
	const overflowDescription = useRef(null);
	const { t } = useTranslation();

	const overflowCheck = () => {
		const handleResize = () => {
			if (overflowEmail.current)
				setIsEmailOverflowing(
					overflowEmail.current.offsetWidth <
						overflowEmail.current.scrollWidth
				);

			if (overflowDescription.current)
				setIsDescriptionOverflowing(
					overflowDescription.current.offsetWidth <
						overflowDescription.current.scrollWidth
				);
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	};

	useEffect(() => {
		overflowCheck();
	}, [entry, overflowEmail, overflowDescription]);

	useEffect(() => {
		if (overflowEmail.current)
			setIsEmailOverflowing(
				overflowEmail.current.offsetWidth <
					overflowEmail.current.scrollWidth
			);

		if (overflowDescription.current)
			setIsDescriptionOverflowing(
				overflowDescription.current.offsetWidth <
					overflowDescription.current.scrollWidth
			);
	}, []);

	return (
		<div
			key={entry.id}
			style={{
				alignItems: "center",
				borderBottom: !isLast && "1px solid rgba(0,0,0,0.1)",
				width: "100%"
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					height: "80px",
					boxSizing: "border-box"
				}}
			>
				{isMobile && !isIPad13 ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "50%",
							padding: "10px",
							boxSizing: "border-box"
						}}
					>
						<Typography
							color={"black"}
							fontSize="14px"
							fontWeight={700}
						>
							{entry.user_name}
						</Typography>
						<Typography
							fontSize={10}
							color={"black"}
							lineHeight={1.1}
						>
							{new Date(entry.from).getTime() >
							new Date().getTime()
								? new Date(entry.from).toLocaleDateString()
								: ""}
							{new Date(entry.from).getTime() >
								new Date().getTime() && " - "}
							{new Date(entry.end).getFullYear() !== 2038
								? new Date(entry.end).toLocaleDateString()
								: t("key.no_end_date")}
						</Typography>

						<Typography
							ref={overflowEmail}
							color={"black"}
							fontSize="12px"
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								maxWidth: "100%"
							}}
						>
							{entry.description}
						</Typography>
					</div>
				) : (
					<>
						<div
							style={{
								width: "calc(100% / 5)",
								paddingLeft: "20px",
								paddingRight: "20px",
								boxSizing: "border-box"
							}}
						>
							<Typography
								color={"black"}
								fontSize="16px"
								fontWeight={700}
							>
								{entry.user_name}
							</Typography>

							<TooltipWrapper
								title={
									isEmailOverflowing ? entry.email : undefined
								}
							>
								<Box
									style={{
										flexDirection: "row",
										display: "flex",
										alignItems: "center"
									}}
								>
									{/* entry.email !== "" && (<img style={{ height: "12px", width: "12px", marginRight: "7px" }} src={ToloBall}></img>) */}
									<Typography
										ref={overflowEmail}
										color={"grey"}
										fontSize="12px"
										fontWeight={700}
										noWrap
										style={{
											textOverflow: "ellipsis",
											overflow: "hidden",
											maxWidth: "100%"
										}}
									>
										{entry.email}
									</Typography>
								</Box>
							</TooltipWrapper>
						</div>

						<div
							style={{
								display: "flex",
								width: "calc(100% / 5)",
								maxWidth: "350px",
								height: "100%",
								paddingLeft: "20px",
								paddingRight: "20px",
								boxSizing: "border-box",
								overflow: "hidden",
								alignItems: "center"
							}}
						>
							<TooltipWrapper
								title={
									isDescriptionOverflowing
										? entry.description
										: undefined
								}
							>
								<Typography
									fontSize={14}
									color={"black"}
									ref={overflowDescription}
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
										maxWidth: "100%"
									}}
								>
									{entry.description}
								</Typography>
							</TooltipWrapper>
						</div>

						<div
							style={{
								width: "calc(100% / 5)",
								paddingLeft: "20px",
								paddingRight: "20px",
								boxSizing: "border-box"
							}}
						>
							<Typography fontSize={14} color={"black"}>
								{entry.phone}
							</Typography>
							<div
								style={{
									flexDirection: "row",
									display: "flex"
								}}
							>
								<Typography fontSize={14} color={"black"}>
									{entry.license_plate}
								</Typography>
							</div>
						</div>
						<div
							style={{
								width: "calc(100% / 5)",
								paddingLeft: "20px",
								paddingRight: "20px",
								boxSizing: "border-box",
								textAlign: "left"
							}}
						>
							<Typography
								fontSize={14}
								color={"black"}
								lineHeight={1.1}
							>
								{new Date(entry.from).getTime() >
								new Date().getTime()
									? new Date(entry.from).toLocaleDateString()
									: ""}
								{new Date(entry.from).getTime() >
									new Date().getTime() && " - "}
								{new Date(entry.end).getFullYear() !== 2038
									? new Date(
											entry.end
										).toLocaleDateString() == "1.1.1"
										? t("key.no_end_date")
										: new Date(
												entry.end
											).toLocaleDateString()
									: t("key.no_end_date")}
							</Typography>
						</div>
					</>
				)}

				<div
					style={{
						width: isMobile ? "50%" : "calc(100% / 5)",

						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "flex-end",
						paddingLeft: "20px",
						paddingRight: "20px",
						boxSizing: "border-box"
					}}
				>
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							position: "relative",
							alignItems: "center"
						}}
					>
						{entry.pin !== null && !entry.pending && (
							<div
								style={{
									backgroundColor:
										entry.pin !== "" &&
										ColorScheme.tolotechBlue,
									borderRadius: "4px",
									paddingLeft: "10px",
									paddingRight: "10px",
									paddingTop: "5px",
									paddingBottom: "5px",
									minWidth: "55px",
									textAlign: "center",
									position: "absolute",
									right: "50px",
									zIndex: 0
								}}
							>
								{entry.pin !== "" && (
									<Typography sx={{ fontSize: 12 }}>
										{t("doors.pin_code_label")}: {entry.pin}
									</Typography>
								)}
							</div>
						)}

						<div
							onMouseEnter={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setTimeout(() => {
									setShowStatusText(true);
								}, 1000);
								setTimeout(() => {
									setEnableButton(true);
								}, 50);
							}}
							onMouseLeave={() => {
								setShowStatusText(false);
								setEnableButton(false);
							}}
							className="key-management-status"
							style={{
								backgroundColor: getStatusProperty(
									entry.access_status,
									"secondary"
								)
							}}
						>
							<TooltipWrapper
								hide={!showStatusText}
								title={getStatusProperty(
									entry.access_status,
									"text"
								)}
								placement="top"
							>
								<div
									style={{
										minHeight: "40px",
										left: "0px",
										minWidth: "40px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: getStatusProperty(
											entry.access_status,
											"secondary"
										),
										position: "absolute",
										borderRadius: "20px",
										zIndex: 999
									}}
								>
									{getStatusProperty(
										entry.access_status,
										"icon"
									)}
								</div>
							</TooltipWrapper>

							<div style={{ flex: 1 }}></div>
							<div
								style={{
									width: "1px",
									height: "100%",
									backgroundColor: "rgba(0,0,0,0.02)"
								}}
							></div>
							<IconButton
								sx={{ position: "absolute", right: "40px" }}
								onClick={() =>
									enableButton
										? setOpenDialog(true)
										: undefined
								}
							>
								<EditIcon
									stroke={ColorScheme.tolotechBlue}
									strokeWidth={2}
									width={"24px"}
									height={"24px"}
								/>
							</IconButton>
							{(enableButton || isDesktop) && (
								<ConfirmAction
									hideTooltip={true}
									hasChild
									handleConfirmAction={() =>
										showStatusText && handleRemove(entry)
									}
									handleTitle={t(
										"buttons.remove_user_access"
									)}
								>
									<div
										style={{
											width: "36px",
											height: "36px"
										}}
									>
										<DeleteButton />
									</div>
								</ConfirmAction>
							)}
						</div>

						<ModifyDialog
							handleRefresh={handleRefresh}
							reservable={reservable}
							dialogOpen={openDialog}
							closeDialog={setOpenDialog}
							setDialogOpen={setOpenDialog}
							user={entry}
						/>

						{/* <ConfirmAction
							hasChild
							handleConfirmAction={() => handleRemove(entry)}
							handleTitle={t("buttons.remove_user_access")}
						>
							<div
								style={{
									width: "36px",
									height: "36px"
								}}
							>
								<DeleteButton />
							</div>
						</ConfirmAction>
							*/}
					</div>
				</div>
			</div>
		</div>
	);
};
