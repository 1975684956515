import React from "react";
import { Typography } from "@mui/material";
import { SortingArrow } from "../Common/IconComponents/SortingArrow";
import { ColorScheme } from "../../Themes/ColorScheme";
import { isDesktop, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const BalanceSortingOptions = ({
	sort,
	setSort,
	descending,
	setDescending
}) => {
	const { t } = useTranslation();
	// Define more options if needed or comment out to remove, id has to match server response

	const sortingOptions = [
		{ label: t("orders.name_label"), id: "name" },
		{ label: t("orders.email_label"), id: "email" },
		/* {
			label: isMobile ? t("key.user_email_mobile") : t("key.user_email"),
			id: "email"
		}, */
		{
			label: t("balances.balance_label"),
			id: "amount"
		},
		{
			label: t("balances.expiration_date"),
			id: "date" // we check and sort only by end date here. Need to improve this, if we want to sort by start and end date and then by time from single function, which may be a bit confusing to user without proper indicators which value is being sorted and how
		},
		{ label: "", id: "actions", hideForMobile: true }
	];

	function changeSort(id) {
		if (sort === id) {
			setDescending(!descending);
		} else {
			setSort(id);
			setDescending(false);
		}
	}

	function isSorted(id) {
		return sort.split(" ")[0] === id;
	}

	return (
		<div
			style={{
				display: "flex",
				flexWrap: isMobile ? "wrap" : "nowrap",
				justifyContent: "space-between",
				/* width: "100%", */
				boxSizing: "border-box",
				backgroundColor: ColorScheme.tolotechBlue
			}}
		>
			{sortingOptions.map((option) => {
				if (option.hideForMobile && !isDesktop) return null;
				return (
					<div
						key={option.id}
						style={{
							display: "flex",
							width: isMobile
								? "50%"
								: option.id === "actions"
									? "130px"
									: "calc(100% / 4)",
							padding: "20px",
							/* paddingLeft: "20px", */
							borderRight:
								"1px solid" +
								ColorScheme.tolotechTransparentWhite,
							...(isMobile && {
								borderBottom:
									"1px solid" +
									ColorScheme.tolotechTransparentWhite
							}),
							alignItems: "center",
							cursor: "pointer",
							boxSizing: "border-box"
						}}
						onClick={() =>
							option.id === "actions"
								? undefined
								: changeSort(option.id)
						}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<Typography
								fontSize="13px"
								sx={{ wordBreak: "break-word" }}
							>
								{option.label}
							</Typography>
							{isSorted(option.id) ? (
								<div style={{ width: "16px", height: "19px" }}>
									<SortingArrow order={!descending} />
								</div>
							) : (
								<div style={{ width: "16px" }} />
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};
