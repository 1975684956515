import React from "react";
import { Popover } from "@mui/material";
import { Box } from "@mui/system";
import { SettingContent } from "../Settings/SettingContent";
import { MessageContent } from "../Messages/MessageContent";

export const PopoverBase = ({ open, handleClose, anchorEl, content }) => {
	const contentSwitch = () => {
		switch (content) {
			case "settings":
				return <SettingContent open={open} handleClose={handleClose} />;

			case "messages":
				return <MessageContent />;

			default:
				break;
		}
	};

	return (
		<Popover
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			anchorReference="anchorEl"
			transformOrigin={{
				vertical: "top",
				horizontal: "left"
			}}
		>
			<Box
				sx={{
					padding: "20px",
					minWidth:
						"250px" /*  karvalakkiratkaisu siihen ettei kontsa työnnä avattavaa elementtiä ruudun ulkopuolelle */
				}}
			>
				{contentSwitch()}
			</Box>
		</Popover>
	);
};
