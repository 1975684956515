export const DayNames = (props) => {
	switch (props) {
		case 1:
			return "Ma";
		case 2:
			return "Ti";
		case 3:
			return "Ke";
		case 4:
			return "To";
		case 5:
			return "Pe";
		case 6:
			return "La";
		case 0:
			return "Su";

		default:
			break;
	}
};
