import { Typography } from "@mui/material";
import React from "react";
import { isDesktop } from "react-device-detect";

/* import { AlertBar } from "../Common/AlertBar"; */

export const UserOverview = ({ data, balance }) => (
	/* const past30Days = data?.balances[0].transactions.filter((transaction) => {
		const today = new Date();
		const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
		const transactionDate = new Date(transaction.created_at);
		return transactionDate > thirtyDaysAgo;
	}).length; */

	<div
		style={{
			width: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			gap: "20px"
		}}
	>
		<div style={{ display: "flex", flexDirection: "row" }}>
			<div style={{ width: "50%" }}>
				<Typography
					sx={{
						color: "black",
						fontSize: "24px",
						fontWeight: "700"
					}}
				>
					{data?.name}
				</Typography>
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: data?.name === "" ? "column" : "row",
						flexWrap: "wrap",
						alignItems: data?.name === "" ? "start" : "center"
					}}
				>
					<Typography
						sx={{
							color: "black",
							fontSize:
								(data?.name === "" && isDesktop) ||
								(data?.name === "" &&
									!isDesktop &&
									String(data?.email).split("@")[0].length <
										10)
									? "24px"
									: "14px",
							fontWeight: data?.name === "" ? "700" : "300",
							lineHeight: data?.name === "" ? undefined : "1"
						}}
					>
						{String(data?.email).split("@")[0]}
					</Typography>
					<Typography
						sx={{
							color: "black",
							fontSize: "14px",
							fontWeight: "300"
						}}
					>
						{"@" + String(data?.email).split("@")[1]}
					</Typography>
				</div>
			</div>
			<div
				style={{
					width: "100%",
					textAlign: "right"
				}}
			>
				<Typography
					sx={{
						color: "black",
						fontSize: "24px",
						fontWeight: "300"
					}}
				>
					{Number(balance).toFixed(2)} €
				</Typography>

				{/* <div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							marginTop: "20px",
							gap: "10px"
						}}
					>
						<Button variant="contained">dast</Button>
						<Button variant="contained">dast</Button>
					</div> */}
			</div>
		</div>

		{/* <AlertBar
				showAlert
				type="info"
				variant="outlined"
				whiteSpacePreLine
				fullWidth
				textContent={
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<Typography
							fontSize="12px"
							fontWeight="600"
							width="80%"
							flexGrow="1"
						>
							Tapahtumia viimeisen 30 päivän aikana
						</Typography>

						<Typography
							fontSize="12px"
							fontWeight="700"
							width="20%"
							textAlign="right"
						>
							{past30Days}
						</Typography>
					</div>
				}
			/> */}
	</div>
);
