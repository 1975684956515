import React, { useEffect, useState } from "react";
import { Alert, Collapse, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ValidationForm } from "./ValidationForm";
import { LoginFormField } from "./LoginFormField";
import { RegisterForm } from "./RegisterForm";

// import backgroundImage from "../../Assets/Images/backgroundImage1.png";

import logoImage from "../../Assets/Images/Tolotech_logo_white_new.png";
import { ForgotPassword } from "./ForgotPassword";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";

const styles = {
	paperContainer: {
		/* backgroundImage: `url(${backgroundImage})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover", */
		background: "linear-gradient(to bottom, #3498db,rgba(15, 26, 41, 1))", // from blueish to purpleish
		minHeight: "100%",
		position: "fixed",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		boxShadow: "inset 0px 0px 0px 1500px rgba(6, 41, 67, 0.7)"
	}
};

export const AdminLogin = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [showLogin, setShowLogin] = useState(true);
	const [showValidation, setShowValidation] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [showRegistration, setShowRegistration] = useState(false);
	const [showGDPR, setShowGDPR] = useState(true);
	const [userEmail, passUserEmail] = useState("");

	const switchComponent = (component) => {
		setShowRegistration(component === "registration");
		setShowLogin(component === "login");
		setShowValidation(component === "validation");
		setShowForgotPassword(component === "forgot");
	};

	useEffect(() => {
		const token = document.cookie.split("webapp-token=")[1];
		if (token !== undefined) {
			navigate("/");
		}
	}, []);

	return (
		<>
			<div style={styles.paperContainer} />

			<Box
				sx={{
					height: "100%",
					width: isMobile ? undefined : "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					overflow: "auto",
					position: "absolute"
				}}
			>
				<div style={{ flex: 1 }} />

				<Grid
					container
					spacing={2}
					direction="column"
					justifyContent="center"
					alignItems="center"
					sx={{
						position: "fixed",
						left: "50%",
						top: "50%",
						transform: "translate(-50%, -50%)", // Center horizontally and vertically
						marginLeft: "0px", // style override
						width: isMobile ? "85%" : "450px",
						maxWidth: "450px",
						// height: "max-content",
						padding: isMobile ? "20px" : "40px",
						border: "1px solid #717171",
						borderRadius: "14px",
						backgroundColor: "rgba(196, 196, 196, 0.05)",
						zIndex: 998
					}}
				>
					<Grid sx={{ padding: "25px" }}>
						<img
							src={logoImage}
							style={{
								display: "block",
								marginLeft: "auto",
								marginRight: "auto",
								height: "80px"
							}}
						/>
					</Grid>

					{showLogin && (
						<LoginFormField switchComponent={switchComponent} />
					)}
					{showValidation && (
						<ValidationForm
							switchComponent={switchComponent}
							userEmail={userEmail}
						/>
					)}
					{showRegistration && (
						<RegisterForm
							switchComponent={switchComponent}
							passUserEmail={passUserEmail}
						/>
					)}
					{showForgotPassword && (
						<ForgotPassword
							switchComponent={switchComponent}
							passUserEmail={passUserEmail}
						/>
					)}
				</Grid>

				<div style={{ flex: 1 }} />

				<Collapse
					in={showGDPR}
					sx={{
						display: "relative",
						zIndex: showGDPR ? 999 : 0,
						marginBottom: "50px",
						overflow: "none"
					}}
				>
					<Alert
						severity="info"
						variant="filled"
						onClose={() => setShowGDPR(!showGDPR)}
						closeText={t("gdpr.close")}
						sx={{
							position: "fixed",
							width: isMobile ? "80%" : "40%",
							left: "50%",
							bottom: "5%",
							transform: "translateX(-50%)"
						}}
					>
						<Typography>{t("gdpr.notice")}</Typography>
					</Alert>
				</Collapse>
			</Box>
		</>
	);
};
