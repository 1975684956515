import React, { useContext, useEffect, useState } from "react";
import { Stack, Typography, Box } from "@mui/material";

import { ApiContext } from "../../../ApiContext";
import { UserContext } from "../../../UserContext";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { FormSave } from "./Components/FormSave";
import { FormCancel } from "./Components/FormCancel";
import { FormEdit } from "./Components/FormEdit";
import { CustomizeButton } from "./Components/CustomizeButton";

export const ContactDetail = ({ data, refreshData }) => {
	const debug = false;

	const [editMode, setEditMode] = useState(false);
	const [newEmail, setNewEmail] = useState("");
	const [newPhone, setNewPhone] = useState("");
	const [newName, setNewName] = useState("");

	const { t } = useTranslation();
	const { ApiCall } = useContext(ApiContext);

	const { selectedLocation } = useContext(UserContext);

	const cancelAction = () => {
		setNewEmail(data.contact_email);
		setNewPhone(data.contact_phone);
		setNewName(data.contact_name);
		setEditMode(!editMode);
	};

	const handleSave = () => {
		ApiCall("PUT", `location/update/${data.location_id}`, {
			contact_email: newEmail,
			contact_name: newName,
			contact_phone: newPhone
		})
			.then(() => {
				setEditMode(!editMode);
				refreshData(data.location_id);
			})
			.catch((error) => {
				if (debug) console.log(error);
			});
	};

	/* useEffect(() => {
		if (newEmail === "") setNewEmail(data.contact_email);
		if (newPhone === "") setNewPhone(data.contact_phone);
		if (newName === "") setNewName(data.contact_name);
	}, []); */

	const checkStates = () => {
		if (newEmail === "" || newPhone === "" || newName === "") return true;
		else return false;
	};

	useEffect(() => {
		setEditMode(false);
		setNewEmail(data.contact_email);
		setNewPhone(data.contact_phone);
		setNewName(data.contact_name);
	}, [selectedLocation]);

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				padding: "15px",
				border: editMode ? "1px dashed rgba(255,255,255,0.5)" : "0px",
				borderRadius: "4px"
			}}
		>
			<div
				style={{
					flexDirection: "row",
					display: "flex",
					flex: 1,
					justifyContent: "flex-start"
				}}
			>
				<div style={{ minWidth: isMobile ? "100px" : "150px" }}>
					<Typography variant="form_text_label">
						{t("location.form_contact_label")}:
					</Typography>
				</div>
				<Stack
					spacing={1}
					sx={{
						width: "100%" /* paddingTop: "3px" */
					}}
				>
					{/* due to different line heights on same row we have to adjust right element few pixels lower so it doesn't look so wonky */}
					<Typography fontSize="12px" lineHeight="26px">
						{t("location.contact_detail_description")}
					</Typography>
					{editMode ? (
						<FormEdit
							title={t("location.form_email_label")}
							value={newEmail}
							changeData={(value) => setNewEmail(value)}
							type={"text"}
							isRequired={true}
						/>
					) : (
						<Typography variant="form_text">{newEmail}</Typography>
					)}
					{editMode ? (
						<FormEdit
							title={t("location.form_phone_label")}
							value={newPhone}
							changeData={(value) => setNewPhone(value)}
							type={"tel"}
							onlyNumeric
							isRequired={true}
						/>
					) : (
						<Typography variant="form_text">{newPhone}</Typography>
					)}
					{editMode ? (
						<FormEdit
							title={t("location.form_contact_person_label")}
							value={newName}
							changeData={(value) => setNewName(value)}
							type={"text"}
							isRequired={true}
						/>
					) : (
						<Typography variant="form_text">{newName}</Typography>
					)}
					{editMode && (
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "flex-end"
							}}
						>
							<FormSave
								check={checkStates()}
								handleSave={handleSave}
							/>
							<FormCancel cancelAction={cancelAction} />
						</Box>
					)}
				</Stack>
			</div>
			{!editMode && (
				<CustomizeButton
					changeEditMode={() => setEditMode(!editMode)}
				/>
			)}
		</Stack>
	);
};
